import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { PaService } from '@app/Modules/PriceAgreement/Services/pa.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements AgRendererComponent, OnDestroy {
  params: any;
  data: any;
  customerNumber: number;
  customerName: string;
  street: string;
  city: string;
  district: string;
  country: string;
  zipcode: string;
  overlaySubscription: Subscription;
  cellValue: string;
  subscription: Subscription = new Subscription();

  constructor(private _paService: PaService, private _http: HttpService) { }

  agInit(params: any): void {
    this.params = params;
    if (params.column.colDef.headerName === "agGrid.agGridColumns.customerNameLabel"
      && params.column.colDef.field === 'customerName' && (params.context.gridService.gridName != "myNotifications" && params.context.gridService.region != "EMEA")) {
      let fullNameList = [];
      let customerName: any = params.data ? params.data.customerName : null;
      if (customerName) {
        fullNameList.push(customerName.name1 || '');
        // fullNameList.push(customerName.name2 || '');
        // fullNameList.push(customerName.name3 || '');
        // fullNameList.push(customerName.name4 || '');
      }
      this.cellValue = fullNameList && fullNameList.length ? fullNameList.join(' ') : '';
    }
    else if (params.column.colDef.headerName === "agGrid.agGridColumns.customerNameLabel"
      && params.column.colDef.field === 'fullName') {
      let fullNameList = [];
      let customerName: any = params.data ? params.data.fullName : null;
      if (customerName) {
        fullNameList.push(customerName.name1 || '');
        // fullNameList.push(customerName.name2 || '');
        // fullNameList.push(customerName.name3 || '');
        // fullNameList.push(customerName.name4 || '');
      }
      this.cellValue = fullNameList && fullNameList.length ? fullNameList.join(' ') : '';
    }
    else
      this.cellValue = params.value;

  }

  refresh(params: any): boolean {
    return false;
  }

  showCustomerInfoTooltip(event, overlaypanel: OverlayPanel) {
    this.customerNumber = this.params.data.customerNumber;
    this.overlaySubscription = this._http.getCustomerInfo(this.customerNumber)
      .subscribe((data) => {
        if (data['customerInformation'] && data['customerInformation'].length > 0) {
          this.customerName = data['customerInformation'][0]['customer_Name_1'];
          this.street = data['customerInformation'][0]['street1'];
          this.city = data['customerInformation'][0]['city'];
          this.district = data['customerInformation'][0]['district_1'];
          this.country = data['customerInformation'][0]['country'];
          this.zipcode = data['customerInformation'][0]['zip_Code'];
          overlaypanel.show(event);
        }
      }, (err) => {
        console.log("Error");
      })
    this.subscription.add(this.overlaySubscription);
  }

  hideCustomerInfoTooltip(overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    if (this.overlaySubscription)
      this.overlaySubscription.unsubscribe();
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }

}
