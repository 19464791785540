import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from "ag-grid-angular";

@Component({
  selector: 'app-custom-no-rows-overlay',
  templateUrl: './custom-no-rows-overlay.component.html',
  styleUrls: ['./custom-no-rows-overlay.component.scss']
})
export class CustomNoRowsOverlayComponent implements INoRowsOverlayAngularComp {

  constructor() { }

  agInit(params): void { 
  }

}
