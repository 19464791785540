import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RegionCheckService {
    endPoints: any;
    region: any;
    config: any;
    constructor() { }

    set userRegion(region) {
        this.region = region;
    }
    get userRegion() {
        return this.region;
    }

    set devEndPoints(endPoints) {
        this.endPoints = endPoints;
    }
    get devEndPoints() {
        return this.endPoints;
    }
}
