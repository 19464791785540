import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { SPA_LEGACY_MENU_HOST } from '@env/environment';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sfdc-pa-details',
  templateUrl: './sfdc-pa-details.component.html',
  styleUrls: ['./sfdc-pa-details.component.scss']
})
export class SfdcPaDetailsComponent implements AgRendererComponent, OnDestroy {
  cvtView: boolean;

  constructor(
    private gridService: CpAgGridService,
    private _globalAppService: GlobalAppService,
    private router: Router
  ) {

  }
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClick() {
    this.navigateToPaDetails();
  }

  navigateToPaDetails() {
    let qsParams = this.getQsParams();
    let isCVT: string = "CVTNo";
    if (this._globalAppService.isCVTView()) {
      this.cvtView = this._globalAppService.isCVTView();
      isCVT = "CVTYes"
    }
    else {
      isCVT = "CVTNo"
    }

    if (this.params.data.customerNumber == null) {
      this.params.data.customerNumber = 'All';
    }
    let contractNumber = this.params.data.contractNumber;

    // this.router.navigateByUrl(SPA_LEGACY_MENU_HOST + contractNumber + '/' + this.params.data.customerNumber + '/' + this.params.data.statusId + '/' + isCVT + qsParams)
    window.open(SPA_LEGACY_MENU_HOST + contractNumber + '/' + this.params.data.customerNumber + '/' + this.params.data.statusId + '/' + isCVT + qsParams, '_blank')

  }

  getQsParams(): string {
    let qsList: Array<any> = new Array<any>();
    let qsParams: string;
    let qsTileType: string = this.params.data.tileType ? ('tileType=' + this.params.data.tileType) : "";
    if (qsTileType)
      qsList.push(qsTileType);

    let fullNameList = [];
    let oCustomerName: any = this.params.data.customerName;
    if (oCustomerName) {
      fullNameList.push(oCustomerName.name1 || '');
    }
    let qsCustomerName: string = fullNameList && fullNameList.length ? ('custName=' + fullNameList.join(' ')) : '';
    qsList.push(qsCustomerName);

    qsParams = qsList && qsList.length > 0 ? '?' + qsList.join('&') : '';

    return qsParams;
  }

  ngOnDestroy() {

  }


}


