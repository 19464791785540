import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
var InvoiceDefaultCustomerNumberComponent = /** @class */ (function () {
    function InvoiceDefaultCustomerNumberComponent(appServ) {
        this.appServ = appServ;
    }
    InvoiceDefaultCustomerNumberComponent.prototype.agInit = function (params) {
        var _this = this;
        if (this.appServ.userProfileInfo && this.appServ.userProfileInfo["defaultCustomerInfo"] && this.appServ.userProfileInfo["defaultCustomerInfo"].length) {
            this.customerNumber = this.appServ.userProfileInfo["defaultCustomerInfo"][0].customerNumber;
        }
        else {
            this.appServ.getuserProfileData().subscribe(function (data) {
                if (data["defaultCustomerInfo"] && data['defaultCustomerInfo'].length) {
                    _this.customerNumber = data["defaultCustomerInfo"][0].customerNumber;
                }
            });
        }
    };
    InvoiceDefaultCustomerNumberComponent.prototype.setCustomerNumber = function () {
    };
    InvoiceDefaultCustomerNumberComponent.prototype.refresh = function (params) {
        return false;
    };
    InvoiceDefaultCustomerNumberComponent.prototype.ngOnDestroy = function () {
        // Required for unsubscribing and destroying the component  
    };
    InvoiceDefaultCustomerNumberComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [AppService])
    ], InvoiceDefaultCustomerNumberComponent);
    return InvoiceDefaultCustomerNumberComponent;
}());
export { InvoiceDefaultCustomerNumberComponent };
