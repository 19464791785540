<ng-container>
    <span class="custom-table__row-menu">
    <i class="fas fa-ellipsis-h ellipsisbtn" [ngClass]="{'ellipseSelect' : ellipseSelect}" (click)="contextmenuToggle(contextmenu, $event)"></i>
  </span>
</ng-container>
<p-overlayPanel appendTo="body" styleClass="contextMenu" #contextmenu (onHide)="onContextMenuHide()" (onShow)="onContextMenuShow()">
    <span class="custom-table__popup__info custom-table__row-menu">
    <ul class="custom-table__row-menu__content">
      <li *ngIf="params?.data?.lineErrors != 0">
        <a href="javascript:void(0)" (click)="editLine($event, contextmenu)">
          <i class="far fa-edit"></i>
          {{'chargeback.kcFile.contextMenu.editLine' | translate}}
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="deleteLine($event, contextmenu)">
          <i class="fa fa-times-circle-o textRed"></i>
          {{'chargeback.kcFile.contextMenu.deleteLine' | translate}}
        </a>
      </li>
    </ul>
  </span>
</p-overlayPanel>