<div>
  <cp-sort-component #sortComponent [colId]="colId" [orgColId]="orgColId" (onSortRequested)="onSortRequested($event)">
  </cp-sort-component>
  <div class="form-group custom-table__thead-popup__search">
    <label class="hidden-text-for-accessibility" for="panamesearch">PA Name Search</label>
    <input type="text" tabindex="1" #input id="panamesearch"
      [ngModel]="text" class="form-control" placeholder='{{ showGreaterThanEquelSign ? ">= " : ""}}{{ "agGrid.filterSearch" | translate }}'
      (keypress)="numberOnly($event)" maxlength="maxLength" (ngModelChange)="onChange($event)"
      (keyup.enter)="onApplyClick($event, input.value)" autofocus>
    <i class="custom-table__thead-popup__search__button fas fa-search" aria-hidden="true"
      (keydown.enter)="onApplyClick($event, input.value)" (click)="onApplyClick($event, input.value)"></i>
    <!-- <p-button tabindex="2" label="Apply" class="btn-filters" [disabled]="!isTextProvided"
      ></p-button> -->
  </div>
</div>