import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import {
  ribbonMenuInternalHelp, ribbonMenuInternalPU, ribbonMenuExternal, ribbonMenuExternalPUCustomer,
  ribbonMenuInternalCVT, ribbonMenuGlobal
} from '@app/conf/dataConfigurations';
import { PaService } from '@app/Modules/PriceAgreement/Services/pa.service';
import { Router } from '@angular/router';
import { APP_LEGACY_MENU_HOST, cookieSIMID, globalUser } from '@env/environment';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { OktaProfile } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/app-modules/welcome/', '.json');
}

@Component({
  selector: 'helpcomp',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})

export class HelpComponent implements OnInit {
  menus: Array<any> = [];
  menuRegionConfig: any;
  customerViewPUMenu: Array<any> = [];
  ExternalViewPUmenu: Array<any> = [];
  InternalViewHelpmenu: Array<any> = [];
  activeMenu: string;
  submenus: Array<any> = [];
  ShowSubmenu: boolean = false;
  mobilemenu: boolean = false;
  mobilemenuActive: any;
  cvtView: boolean = false;
  isInternalUser: boolean = false;
  activeSubmenu: string;
  menuPlaceHolder: Array<any> = [];
  oktaProfile: OktaProfile;
  region: string;

  constructor(private translateServ: TranslateService, private languageServ: LanguageSelectionService,
    private _paServ: PaService, private router: Router, private appServ: AppService,
    private _globalAppService: GlobalAppService) {

  }

  ngOnInit() {
    this.region = this._globalAppService.getDefaultRegionString();
    this.menuRegionConfig = {
      'ribbonMenuInternalHelp': ribbonMenuInternalHelp,
      'ribbonMenuInternalPU': ribbonMenuInternalPU[this.region],
      'ribbonMenuExternal': ribbonMenuExternal['NA'],
      'ribbonMenuExternalPUCustomer': ribbonMenuExternalPUCustomer,
      'ribbonMenuInternalCVT': ribbonMenuInternalCVT['NA'],
      'ribbonMenuGlobal': ribbonMenuGlobal['NA']
    }

    let me = this;
    if (this.appServ.isGlobalUser)
      this.menuPlaceHolder = Object.assign(ribbonMenuGlobal);
    else
      this.menuPlaceHolder = Object.assign(ribbonMenuInternalCVT);
    if (this.appServ.AvailableOKtaProfile) {
      this.isInternalUser = this.appServ.isInternalUser;
      this.oktaProfile = this.appServ.AvailableOKtaProfile;
    }
    else {
      this.appServ.getOktaProfile().subscribe((data) => {
        this.oktaProfile = data;
        if (data.userType == "C" || data.userType == "E") {
          this.isInternalUser = true;
          this.isInternalMenu();
        }
      });
    }
    this.activeSubmenu = '';
    this.appServ.getActiveSubNav().subscribe(
      (d) => {
        me.activeMenu = d.id;
        if (d.activeSubMenu) {
          me.activeMenu = d.id;
          me.activeSubmenu = d.activeSubMenu.id;
          let mainMenuHolder = this.menuPlaceHolder.find(item => this.activeMenu == item.id);
          this.ShowSubmenu = true;
          if (mainMenuHolder && mainMenuHolder.menu) {
            this.submenus = mainMenuHolder.menu;
          }
        }
        else {
          me.activeMenu = d.id;
          this.ShowSubmenu = false;
        }
      });


    this.isInternalMenu();

    this.activeMenuClick(this.mobilemenuActive);

    this.appServ.setCVTView(this.cvtView);
    this.languageServ.currentSelected.subscribe((data) => {
      this.translateServ.setDefaultLang(data.regionCode);
    });

  }

  isInternalMenu() {
    if (this.isInternalUser) {
      if (this._globalAppService.isCVTView()) {
        this.cvtView = this._globalAppService.isCVTView();
      }

      this._globalAppService.isCVTViewSubject().subscribe((d) => {
        this.cvtView = d;
      });
      this.customerViewPUMenu = ribbonMenuExternalPUCustomer;
      this.InternalViewHelpmenu = ribbonMenuInternalHelp;
      this.ExternalViewPUmenu = ribbonMenuInternalPU[this.region];
      this.mobilemenuActive = this.mobilemenuActive[0];

    } else {
      this.menus = this.appServ.getCookie(cookieSIMID) && (this.oktaProfile && this.appServ.isGlobalUser) ? this.menuRegionConfig['ribbonMenuGlobal'] : this.menuRegionConfig['ribbonMenuExternal'];
      this.mobilemenuActive = this.menus.filter(item => this.activeMenu == item.id);
      this.mobilemenuActive = this.mobilemenuActive[0];
    }
  }

  activeMenuClick(menu) {
    this.appServ.updateNavStatus(menu);
    this.mobilemenuActive = menu;
    if (menu.uri.indexOf('UI/') > -1) {
      window.open(APP_LEGACY_MENU_HOST + menu.uri, "_self")
    } else {
      if (menu.hasChild) {
        this.ShowSubmenu = true;
        this.submenus = menu.menu;
        //my searches is first menu so assigning id here for hightlight
        if (menu.id == 3) {
          if (this.appServ.isGlobalUser)
            this.activeSubmenu = '302';
          else
            this.activeSubmenu = '301';
        } else {
          this.activeSubmenu = '';
        }
      }
      else {
        this.mobilemenu = !this.mobilemenu;
        this.ShowSubmenu = false;
        this.submenus = [];
      }
    }
  }

  submenuclick(submenu) {
    this.appServ.updateNavStatus({ id: submenu.id });
  }

  switchView(viewlabel: string) {
    this.cvtView = (viewlabel == 'cvt' ? true : false);
    this.appServ.setCVTView(this.cvtView);
    this.router.navigate(["/portalusage"],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
    this.appServ.getMenu().subscribe(d => {
      let currentMenu = d.find(x => x.id == "1");
      this.appServ.updateNavStatus(currentMenu);
    });
    this.ShowSubmenu = false;
  }
  showMobileDropMenu() {
    this.mobilemenu = !this.mobilemenu;
  }
}
