/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sortonly.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../sort-component/sort.component.ngfactory";
import * as i3 from "../sort-component/sort.component";
import * as i4 from "../../../services/cp-ag-grid-service";
import * as i5 from "./sortonly.component";
var styles_SortonlyComponent = [i0.styles];
var RenderType_SortonlyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SortonlyComponent, data: {} });
export { RenderType_SortonlyComponent as RenderType_SortonlyComponent };
export function View_SortonlyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "cp-sort-component", [], null, [[null, "onSortRequested"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSortRequested" === en)) {
        var pd_0 = (_co.onSortRequested($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SortComponent_0, i2.RenderType_SortComponent)), i1.ɵdid(2, 114688, [["sortComponent", 4]], 0, i3.SortComponent, [i4.CpAgGridService], { colId: [0, "colId"], orgColId: [1, "orgColId"] }, { onSortRequested: "onSortRequested" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colId; var currVal_1 = _co.orgColId; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_SortonlyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sortonly", [], null, null, null, View_SortonlyComponent_0, RenderType_SortonlyComponent)), i1.ɵdid(1, 4243456, null, 0, i5.SortonlyComponent, [i4.CpAgGridService], null, null)], null, null); }
var SortonlyComponentNgFactory = i1.ɵccf("app-sortonly", i5.SortonlyComponent, View_SortonlyComponent_Host_0, {}, {}, []);
export { SortonlyComponentNgFactory as SortonlyComponentNgFactory };
