import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-selected-customer-eu',
  templateUrl: './selected-customer-eu.component.html',
  styleUrls: ['./selected-customer-eu.component.scss']
})
export class SelectedCustomerEUComponent implements OnInit {
  @Input() selectedCustomerEU: any;
  @Output() removeValue = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
  }

  removeCustomerEU(customerEU){
    this.removeValue.emit(customerEU);
  }

}
