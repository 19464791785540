import * as tslib_1 from "tslib";
import { ErrorHandler, Injector } from '@angular/core';
import { MonitoringService } from './monitoring.service';
import { environment } from '@env/environment';
var MonitoringErrorHandler = /** @class */ (function (_super) {
    tslib_1.__extends(MonitoringErrorHandler, _super);
    function MonitoringErrorHandler(injector) {
        var _this = _super.call(this) || this;
        _this.injector = injector;
        return _this;
    }
    MonitoringErrorHandler.prototype.handleError = function (error) {
        console.log("error:::handler::", error);
        if (environment.production) {
            var monitoringService = this.injector.get(MonitoringService);
            monitoringService.logError(error);
            _super.prototype.handleError.call(this, error);
        }
    };
    return MonitoringErrorHandler;
}(ErrorHandler));
export { MonitoringErrorHandler };
