import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CouponDetailsService {

  selectedCustEU: any;
  selectedCustEUSub: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  detailsPageQueryData: any = {};
  detailsPageQueryDataSub: BehaviorSubject<any> = new BehaviorSubject<any>(this.detailsPageQueryData);
  downloadLineLevelDetailsSub: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor() { }

  setSelectedCustEU(eu: any) {
    this.selectedCustEU = eu;
    this.selectedCustEUSub.next(eu);
  }

  getSelectedCustEU(): Observable<any> {
    return this.selectedCustEUSub.asObservable();
  }

  setDetailsPageQueryData(obj) {
    this.detailsPageQueryData = Object.assign(this.detailsPageQueryData, obj);
    this.detailsPageQueryDataSub.next(this.detailsPageQueryData);
  }

  getDetailsPageQueryData() {
    return this.detailsPageQueryDataSub.asObservable();
  }


}


@Pipe({ name: 'replaceString' })
export class ReplaceString implements PipeTransform {
  transform(str: string, replaceTo: string, replaceWith: any): any {
    return str.replace(replaceTo, replaceWith);
  }
}