import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { globalUser } from '@env/environment';
import { OktaAuthService } from '@okta/okta-angular';

@Injectable()
export class ManageUserRedirect implements CanActivate {
  constructor(private _oktaAuth: OktaAuthService, private appService: AppService, private router: Router, private _HTTPService: HttpService) { }

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
    // console.log('auth gaurd --> ',this._oktaAuth);
    if (routerState.url.indexOf('/pa/details/') != -1 || 
        routerState.url.indexOf('/chargeback/coupon/details') != -1 || 
        routerState.url.indexOf('/meetcomps/meetcompsDetails/details') != -1 ||
        routerState.url.indexOf('/invoice/dashboard') != -1 ||
        routerState.url.indexOf('/reports/dashboard') != -1){
      window.sessionStorage.setItem("paDetailsURL", routerState.url);
    }
    else if(routerState.url.indexOf('/cb-pricing-terms') != -1){
      localStorage.setItem("cb-pricing-terms-url", routerState.url);
    }



    const loadComponent = new Observable<boolean>((observer) => {
      this._oktaAuth.isAuthenticated().then((okta_authenticated: boolean) => {
        if (okta_authenticated) {
          // console.log("AvailableOKtaProfile -->", this.appService.AvailableOKtaProfile);
          if (this.appService.AvailableOKtaProfile) {
            this.profileScan(this.appService.AvailableOKtaProfile).subscribe((scanResult) => {
              if (scanResult) {
                return observer.next(true);
              }
              else {
                this.router.navigateByUrl('welcome');
                return observer.next(false);
              }

            });
          }
          else {
            this.appService.getOktaProfile().subscribe((userProfile) => {
              this.profileScan(userProfile).subscribe((scanResult) => {
                if (scanResult) {
                  return observer.next(true);
                }
                else {
                  this.router.navigateByUrl('welcome');
                  return observer.next(false);
                }

              });
            });
          }
        }
        else {
          return observer.next(false);
        }
      });
    });
    return loadComponent;
  }

  profileScan(userProfile: any): Observable<boolean> {
    const scanResult = new Observable<boolean>((observer) => {
      
      if (userProfile.sAMAccountName) {
          this._HTTPService.getWelcomeFlag(userProfile.sAMAccountName).subscribe((obj: any) => {
            if (obj.showWelcomeMessage) {
              return observer.next(false);
            }
            else {
              return observer.next(true);
            }
          });
        }
     
    });
    return scanResult;
  }
}

