import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class MyProfileService {

  private selectedLocationSubject = new Subject<any>();
  private closePopUpSubject = new Subject<any>();
  private orderPrefSaveSuccessSubject = new Subject<boolean>();
  private defaultLocationSubject = new Subject<any>();
  private newLocationAddedSubject = new Subject<any>();
  reloadDataAfterLangChange = new Subject<any>();
  prefernceClickedSubject = new Subject<any>();
  setExternalGridReadySubject = new Subject<any>();
  submitDefaultProfileSubject = new Subject<boolean>();
  submitLocationsSubject = new Subject<boolean>();
  newlyAddedLocation: any;
  prefernceData: any;
  totalNumberOfLocations = [];
  numberOfDefaultLocSelected = [];
  preveiousSelectedLocation: any;
  numberOfSelectedPAs = [];
  numberOfSelectedMCs = [];
  numberOfSelectedCBs = [];
  numberOfSelectedINs = [];
  numberOfSelectedODs = [];
  totalNumberOfPAs = [];
  sortByChanged : Subject<any> = new Subject<any>();

  updateSortBy(sortBy: any) {
    //console.log("set save subcription", sortBy);
    this.sortByChanged.next(sortBy);
  }

  getSortByValue(): Observable<any> {
    return this.sortByChanged.asObservable();
  }

  submitDefaultProfile(locations: boolean) {
    //console.log("set save subcription", locations);
    this.submitDefaultProfileSubject.next(locations);
  }

  submitDefaultProfileObservable(): Observable<boolean> {
    return this.submitDefaultProfileSubject.asObservable();
  }

  submitLocations(locations: boolean) {
    this.submitLocationsSubject.next(locations);
  }

  submitLocationsObservable(): Observable<boolean> {
    return this.submitLocationsSubject.asObservable();
  }

  setSelectedLocations(locations: any) {
    this.selectedLocationSubject.next(locations);
  }

  getSelectedLocations() {
    return this.selectedLocationSubject;
  }

  setClosePopEvent() {
    this.closePopUpSubject.next(true);

  }

  getClosePopEvent() {
    return this.closePopUpSubject;
  }

  setDefaultLocation(location: any) {
    this.defaultLocationSubject.next(location);
  }

  getDefaultLocation() {
    return this.defaultLocationSubject;
  }

  setnewLocationAddedOrRemoved(isRemovedOrAdded) {
    this.newLocationAddedSubject.next(isRemovedOrAdded);
  }

  getnewLocationAddedOrRemoved() {
    return this.newLocationAddedSubject;
  }

  setNewlyAddedLocation(value: any) {
    this.newlyAddedLocation = value;
  }

  getNewlyAddedLocation() {
    return this.newlyAddedLocation;
  }

  setUserPrefernceData(data) {
    this.prefernceData = data;
  }

  getUserPrefernceData() {
    return this.prefernceData;
  }

  setTotalNumberOfLocations(locations, command = 'Add') {
    if (command == 'Add') {
      if (this.totalNumberOfLocations.indexOf(locations) == -1) {
        this.totalNumberOfLocations.push(locations);
      }
    }
    else if (command == 'Remove') {
      let indexToRemove = this.totalNumberOfLocations.indexOf(locations);
      if (indexToRemove > -1) {
        this.totalNumberOfLocations.splice(indexToRemove, 1);
      }
    } else if (command == 'Reset') {
      this.totalNumberOfLocations = [];
    }

  }

  getTotalNumberOfLocations() {
    return this.totalNumberOfLocations.length;
  }

  setnumberOfDefaultLocSelected(selectedLocations, command = 'Add') {
    if (command == 'Add') {
      if (this.numberOfDefaultLocSelected.indexOf(selectedLocations) == -1) {
        this.numberOfDefaultLocSelected.push(selectedLocations);
      }
    } else if (command == 'Remove') {
      let indexToRemove = this.numberOfDefaultLocSelected.indexOf(selectedLocations);
      if (indexToRemove > -1) {
        this.numberOfDefaultLocSelected.splice(indexToRemove, 1);
      }
    } else if (command == 'Reset') {
      this.numberOfDefaultLocSelected = [];
    }

  }

  getnumberOfDefaultLocSelected() {
    return this.numberOfDefaultLocSelected.length;
  }

  // =======================================
  setTotalNumberOfPAs(totalPAs, command = 'Add') {
    if (command == 'Add') {
      if (this.totalNumberOfPAs.indexOf(totalPAs) == -1) {
        this.totalNumberOfPAs.push(totalPAs);
      }
    } else if (command == 'Reset') {
      this.totalNumberOfPAs = [];
    }
  }

  getTotalNumberOfPAs() {
    return this.totalNumberOfPAs.length;
  }

  //================== PA ==================================
  setnumberOfSelectedPA(selectedPA, command = 'Add') {
    if (command == 'Add') {
      if (this.numberOfSelectedPAs.indexOf(selectedPA) == -1) {
        this.numberOfSelectedPAs.push(selectedPA);
      }
    } else if (command == 'Remove') {
      let indexToRemove = this.numberOfSelectedPAs.indexOf(selectedPA);
      if (indexToRemove > -1) {
        this.numberOfSelectedPAs.splice(indexToRemove, 1);
      }
    } else if (command == 'Reset') {
      this.numberOfSelectedPAs = [];
    }
  }

  getnumberOfSelectedPA() {
    return this.numberOfSelectedPAs.length;
  }
  //===========================

  //================== MeetComp ==================================
  setnumberOfSelectedMC(selectedMC, command = 'Add') {
    if (command == 'Add') {
      if (this.numberOfSelectedMCs.indexOf(selectedMC) == -1) {
        this.numberOfSelectedMCs.push(selectedMC);
      }
    } else if (command == 'Remove') {
      let indexToRemove = this.numberOfSelectedMCs.indexOf(selectedMC);
      if (indexToRemove > -1) {
        this.numberOfSelectedMCs.splice(indexToRemove, 1);
      }
    } else if (command == 'Reset') {
      this.numberOfSelectedMCs = [];
    }
  }
  
  getnumberOfSelectedMC() {
    return this.numberOfSelectedMCs.length;
  }
  //===========================

  //================== Charge Back ==================================
  setnumberOfSelectedCB(selectedCB, command = 'Add') {
    if (command == 'Add') {
      if (this.numberOfSelectedCBs.indexOf(selectedCB) == -1) {
        this.numberOfSelectedCBs.push(selectedCB);
      }
    } else if (command == 'Remove') {
      let indexToRemove = this.numberOfSelectedCBs.indexOf(selectedCB);
      if (indexToRemove > -1) {
        this.numberOfSelectedCBs.splice(indexToRemove, 1);
      }
    } else if (command == 'Reset') {
      this.numberOfSelectedCBs = [];
    }
  }

  getnumberOfSelectedCB() {
    return this.numberOfSelectedCBs.length;
  }
  //===========================

  //================== Invoices ==================================
  setnumberOfSelectedIN(selectedIN, command = 'Add') {
    if (command == 'Add') {
      if (this.numberOfSelectedINs.indexOf(selectedIN) == -1) {
        this.numberOfSelectedINs.push(selectedIN);
      }
    } else if (command == 'Remove') {
      let indexToRemove = this.numberOfSelectedINs.indexOf(selectedIN);
      if (indexToRemove > -1) {
        this.numberOfSelectedINs.splice(indexToRemove, 1);
      }
    } else if (command == 'Reset') {
      this.numberOfSelectedINs = [];
    }
  }

  getnumberOfSelectedIN() {
    return this.numberOfSelectedINs.length;
  }
  //===========================

  //================== Orders ==================================
  setnumberOfSelectedOD(selectedOD, command = 'Add') {
    if (command == 'Add') {
      if (this.numberOfSelectedODs.indexOf(selectedOD) == -1) {
        this.numberOfSelectedODs.push(selectedOD);
      }
    } else if (command == 'Remove') {
      let indexToRemove = this.numberOfSelectedODs.indexOf(selectedOD);
      if (indexToRemove > -1) {
        this.numberOfSelectedODs.splice(indexToRemove, 1);
      }
    } else if (command == 'Reset') {
      this.numberOfSelectedODs = [];
    }
  }

  getnumberOfSelectedOD() {
    return this.numberOfSelectedODs.length;
  }
  //===========================

  setPreveiousSelectedLoc(location) {
    this.preveiousSelectedLocation = location;
  }

  getPreveiousSelectedLoc() {
    return this.preveiousSelectedLocation;
  }

  setReloadDataAfterLangChange(status) {
    this.reloadDataAfterLangChange.next(status);
  }

  getReloadDataAfterLangChange(): Observable<any> {
    return this.reloadDataAfterLangChange.asObservable();
  }

  setPrefernceClicked(data) {
    this.prefernceClickedSubject.next(data)
  }

  getPrefernceClicked() {
    return this.prefernceClickedSubject.asObservable();
  }

  setExternalGridReady(status){
    this.setExternalGridReadySubject.next(status);
  }

  getExternalGridReady(): Observable<any>{
    return this.setExternalGridReadySubject.asObservable();
  }

  setPrefSaveSuccessEvent() {
    this.orderPrefSaveSuccessSubject.next(true);
  }

  getPrefSaveSuccessEvent(): Observable<any> {
    return this.orderPrefSaveSuccessSubject.asObservable();
  }

}
