<div [ngClass]="(params.column.colId == '_myprofilePA') || 
(params.column.colId == '_myprofileMC') || (params.column.colId == '_myprofileCB') || 
(params.column.colId == '_myprofileIN') || (params.column.colId == '_myprofileOD') || 
(params.column.colId == '_addtopref')?'customHeaderWrapper-PA':'customHeaderWrapper'">
    <span class="customHeaderHamburgerIcon">
        <span *ngIf="params.enableMenu" #menuButton (click)="onMenuClicked($event)">
            <i class="fa fa-bars"></i>
        </span>
    </span>
    <div class="pa-heading" *ngIf="(params.column.colId == '_myprofilePA') || 
    (params.column.colId == '_myprofileMC') || (params.column.colId == '_myprofileCB') || 
    (params.column.colId == '_myprofileIN') || (params.column.colId == '_myprofileOD') || 
    (params.column.colId == '_addtopref')">{{params.displayName | translate}}
        <span *ngIf="params.column.colId == '_addtopref'" class="preference-tolltip">
            <i class="far fa-question-circle" pTooltip="{{ 'myProfile.MyLocation.prefrenceTooltip' | translate }}" tooltipPosition="top"></i>
        </span>
        <span *ngIf="params.column.colId == '_myprofileOD' && region == 'NA'" class="order-poppup-trigger" (click)="openOrderPreferencePopup()">
            <i class="far fa-window-restore" aria-hidden="true"></i>
        </span>
    </div>

    <span [className]="(params.column.colId == '_cpCheckbox' || params.column.colId == '_cpCheckbox_1') ? 
        'customCheckbox' : ((params.column.colId == '_myprofilePA') || 
        (params.column.colId == '_myprofileMC') || (params.column.colId == '_myprofileCB') || 
        (params.column.colId == '_myprofileIN') || (params.column.colId == '_myprofileOD') || 
        (params.column.colId == '_addtopref') ? 'customHeaderLabel-PA' : 'customHeaderLabel' )">
        <div *ngIf="params.column.colId == '_cpCheckbox' || params.column.colId == '_cpCheckbox_1'"
            class="custom-control header-checkbox">
            <input type="checkbox" class="custom-control-input" [id]="checkboxName" (change)="selectAllNodes($event)">
            <label class="custom-control-label" [for]="checkboxName" [ngClass]="checkboxStatus">&nbsp;</label>
        </div>

        <!-- below checkbox is for my profile add to preference   -->
        <div *ngIf="params.column.colId == '_addtopref'" class="custom-control">
            <input type="checkbox" class="custom-control-input" [checked]="addToPrefCheckBoxStatus" 
            [id]="'addToPrefCheckBoxHeader'" (change)="selectAllNodesAddToPref($event)"
            [ngClass]="{'partial-selected-checkbox':partialCheckBoxStatus}">
            <label class="custom-control-label" [for]="'addToPrefCheckBoxHeader'" #selectAllAddToPref>&nbsp;</label>
        </div>

        <!-- below checkbox is for my profile Price Agreement  -->
        <div *ngIf="params.column.colId == '_myprofilePA'" class="custom-control">
            <input type="checkbox" class="custom-control-input" [checked]="headerCheckBoxStatusPA" [id]="'PACheckBoxHeader'"
                (change)="selectAllNodesMyProfilePA($event)">
            <label class="custom-control-label" 
                [for]="'PACheckBoxHeader'" >&nbsp;</label>
        </div>

        <!-- below checkbox is for my profile Meet comp  -->
        <div *ngIf="params.column.colId == '_myprofileMC'" class="custom-control">
            <input type="checkbox" class="custom-control-input" [checked]="headerCheckBoxStatusMC" [id]="'MCCheckBoxHeader'"
                (change)="selectAllNodesMyProfileMC($event)">
            <label class="custom-control-label" 
                [for]="'MCCheckBoxHeader'" >&nbsp;</label>
        </div>

        <!-- below checkbox is for my profile Charge Back  -->
        <div *ngIf="params.column.colId == '_myprofileCB'" class="custom-control">
            <input type="checkbox" class="custom-control-input" [checked]="headerCheckBoxStatusCB" [id]="'CBCheckBoxHeader'"
                (change)="selectAllNodesMyProfileCB($event)">
            <label class="custom-control-label" 
                [for]="'CBCheckBoxHeader'" >&nbsp;</label>
        </div>

        <!-- below checkbox is for my profile Invoices -->
        <div *ngIf="params.column.colId == '_myprofileIN'" class="custom-control">
            <input type="checkbox" class="custom-control-input" [checked]="headerCheckBoxStatusIN" [id]="'INCheckBoxHeader'"
                (change)="selectAllNodesMyProfileIN($event)">
            <label class="custom-control-label" 
                [for]="'INCheckBoxHeader'" >&nbsp;</label>
        </div>

        <!-- below checkbox is for my profile Orders -->
        <div *ngIf="params.column.colId == '_myprofileOD'" class="custom-control">
            <input type="checkbox" class="custom-control-input" [checked]="headerCheckBoxStatusOD" [id]="'ODCheckBoxHeader'"
                (change)="selectAllNodesMyProfileOD($event)">
            <label class="custom-control-label" 
                [for]="'ODCheckBoxHeader'" >&nbsp;</label>
        </div>

        

    <span class="non-add-to-pa headingText {{params.column.colId}}" *ngIf="(params.column.colId != '_myprofilePA') && 
        (params.column.colId != '_myprofileMC') && (params.column.colId != '_myprofileCB') &&
        (params.column.colId != '_myprofileIN') && (params.column.colId != '_myprofileOD') && (params.column.colId != '_addtopref')" [innerHTML]='params.displayName | translate'>
    </span>

    <!--Added for efile manage error grid column header questionmark hover tool tip-->
    <span *ngIf="showEfileErrorColumnToolTip[params.column.colId] && region=='EMEA'" class="tooltip-hover" [ngClass]="tolltipHoverClass">
        <i class="far fa-question-circle" pTooltip="{{'agGrid.agGridColumnHeaderTooltip.'+params.column.colId | translate }}" tooltipPosition="top"></i>
    </span>
    <!--Added for efile manage error grid column header  --- END -- -->

    <!--Added for invoice price column header questionmark hover tool tip-->
    <span *ngIf="shownIvoicePriceColumnToolTip[params.column.colId]" class="tooltip-hover" [ngClass]="tolltipHoverClass">
        <i class="far fa-question-circle" pTooltip="{{'agGrid.agGridHeadingTooltip.'+params.column.colId | translate }}" tooltipPosition="top"></i>
    </span>
    <!--Added for invoice price grid column header  --- END -- -->

    <!-- Added for Chargeback grid Tax field -->
    <span *ngIf="params.column.colId == 'tax'" class="preference-tolltip">
            <i class="far fa-question-circle" pTooltip="{{ 'agGrid.agGridHeadingTooltip.taxFieldHeaderTooltip' | translate }}" tooltipPosition="top"></i>
        </span>
    <!-- Added for Chargeback grid Tax field---END -->

    <!-- Added for Chargeback Results Requested, Issued and Claim Reason field -->
    <span *ngIf="params.displayName == 'agGrid.agGridColumns.requestedLabel'" class="preference-tolltip">
        <i class="far fa-question-circle" pTooltip="{{ 'agGrid.agGridHeadingTooltip.taxFieldHeaderTooltip' | translate }}" tooltipPosition="top"></i>
    </span>

    <span *ngIf="params.displayName == 'agGrid.agGridColumns.netIssuedLabel'" class="preference-tolltip">
        <i class="far fa-question-circle" pTooltip="{{ 'agGrid.agGridHeadingTooltip.taxFieldHeaderTooltip' | translate }}" tooltipPosition="top"></i>
    </span>

    <span *ngIf="params.displayName == 'agGrid.agGridColumns.rvRbLabel'" class="preference-tolltip">
        <i class="far fa-question-circle" pTooltip="{{ 'agGrid.agGridHeadingTooltip.taxFieldHeaderTooltip' | translate }}" tooltipPosition="top"></i>
    </span>

    <!-- Added for Chargeback Summary Coupon Details grid Requested and Issued field -->
    <span *ngIf="params.displayName == 'agGrid.agGridColumns.requestedCBLabel'" class="preference-tolltip">
            <i class="far fa-question-circle" pTooltip="{{ 'agGrid.agGridHeadingTooltip.taxFieldHeaderTooltip' | translate }}" tooltipPosition="top"></i>
        </span>

    <span *ngIf="params.displayName == 'agGrid.agGridColumns.issuedLabel'" class="preference-tolltip">
            <i class="far fa-question-circle" pTooltip="{{ 'agGrid.agGridHeadingTooltip.taxFieldHeaderTooltip' | translate }}" tooltipPosition="top"></i>
        </span>
    <!-- Added for Chargeback Summary Coupon Details grid Requested and Issued field---END -->

    <!-- Added tooltip for advanced invoice price grid columns -->
    <!-- <span *ngIf="params.displayName == 'agGrid.agGridColumns.lastRefreshLabel'" class="preference-tolltip advancedInvoice">
        <i class="far fa-question-circle" pTooltip="{{ 'agGrid.agGridHeadingTooltip.lastRefresh' | translate }}" tooltipPosition="top"></i>
    </span>

    <span *ngIf="params.displayName == 'agGrid.agGridColumns.nextAvailableLabel'" class="preference-tolltip advancedInvoice">
        <i class="far fa-question-circle" pTooltip="{{ 'agGrid.agGridHeadingTooltip.nextAvailable' | translate }}" tooltipPosition="top"></i>
    </span>

    <span *ngIf="params.displayName == 'agGrid.agGridColumns.refreshStatusLabel'" class="preference-tolltip advancedInvoice">
        <i class="far fa-question-circle" pTooltip="{{ 'agGrid.agGridHeadingTooltip.refreshStatus' | translate }}" tooltipPosition="top"></i>
    </span> -->
    <!-- Added tooltip for advanced invoice price grid columns---END -->
    <!-- below header is for my location grid for internal user -->
    <div *ngIf="params.column.colId == 'name' && isInternalUser && isMyProfileInternal">
        <div class="custom-control float-left actionBtnWrapper">
            <span class="custom-table__row-menu">
                    <div class="actionBtn" (click)="actionBtnMenu.toggle($event)">{{ 'myProfile.MyLocation.actionsLabel' | translate }}</div>
                </span>
            <p-overlayPanel appendTo="body" styleClass="contextMenu actionBtnMenuOverlay" #actionBtnMenu hideEvent="mousedown">
                <span class="custom-table__popup__info custom-table__row-menu">
                        <ul class="custom-table__row-menu__content actionBtnWrapper">
                            <li class="action-items" (click)="removeSelectedLocations()">{{ 'myProfile.MyLocation.removeSelectedLabel' | translate }}</li>
                            <li  class="action-items" (click)="showChangeGroupModal(true)">{{ 'myProfile.MyLocation.changeGroupLabel' | translate }}</li>
                        </ul>
                    </span>
            </p-overlayPanel>
        </div>
        <div class="custom-control float-left"><button class="btn btn-primary btn-primary--icon-left" (click)="displayLocationmodal()"><i class="fas fa-user-plus btn-primary--small"></i> {{ 'myProfile.MyLocation.addLocationFavButton' | translate }}</button></div>
    </div>
    <!-- Tooltip for kcOwner column -->
    <span *ngIf="params.column.colId == 'kcOwner'" class="firm-date-tolltip">
        <i class="far fa-question-circle" pTooltip="{{ 'agGrid.agGridHeadingTooltip.kcOwnerTooltip' | translate }}" tooltipPosition="top"></i>
    </span>

    <!-- Tooltip for valid to column -->
    <span *ngIf="params.column.colId == 'firmEndDate'" class="firm-date-tolltip">
        <i class="far fa-question-circle" pTooltip="{{ 'agGrid.agGridHeadingTooltip.UFNTooltip' | translate }}" tooltipPosition="top"></i>
    </span>

    <!-- Tooltip for volume column -->
    <span *ngIf="params.column.colId == 'volume'" class="firm-date-tolltip">
        <i class="far fa-question-circle" pTooltip="{{ 'agGrid.agGridHeadingTooltip.volumeTooltip' | translate }}" tooltipPosition="top"></i>
    </span>

    <!-- {{ params.displayName | translate}} -->
    <span *ngIf="params.enableSorting" (click)="onSortRequested('desc', $event)" [ngClass]="ascSort" class="customSortDownLabel"><i class="ag-icon ag-icon-asc"></i></span>
    <span *ngIf="params.enableSorting" (click)="onSortRequested('asc', $event)" [ngClass]="descSort" class="customSortUpLabel"><i class="ag-icon ag-icon-desc"></i></span>
    <span class="ag-icon ag-icon-filter ag-cell-icon-filter" unselectable="on"></span>

    <!-- below tooltip icon and tooltip content is for Efile search result customer no. column -->
    <span *ngIf="params.column.colId == 'customerNumber_CB'" class="tooltip-hover" [ngClass]="tolltipHoverClass">
        <i class="far fa-question-circle" pTooltip="This is customer Number tooltip Content" tooltipPosition="top"></i>
    </span>


    </span>


    <!--Added for advanced invoice grid favorites column-->
    <span *ngIf="params.column.colId == 'heartIconColumn'">
        <span *ngIf="!isAllFavorite && !isGlobalUser" class="blueText" (click)="addRemoveFav(true)"><i class="far fa-heart"></i></span>
    <span *ngIf="isAllFavorite && !isGlobalUser" class="blueText" (click)="addRemoveFav(false)"><i class="fas fa-heart"></i></span>
    <span *ngIf="!isAllFavorite && isGlobalUser" class="blueTextNoClick"><i class="far fa-heart"></i></span>
    <span *ngIf="isAllFavorite && isGlobalUser" class="blueTextNoClick"><i class="fas fa-heart"></i></span>
    </span>


</div>

<p-toast key="locationError" position="center" [style]="{ marginTop : '100px', width: '400px' }" [closable]="true" [baseZIndex]="500" [autoZIndex]="100" [hideTransitionOptions]="'250ms'" [preventOpenDuplicates]="true">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center">
            <h3>{{message.summary | translate}}</h3>
            <p>{{message.detail | translate}}</p>
        </div>
    </ng-template>
</p-toast>