import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';

@Injectable({
  providedIn: 'root'
})

export class PaService {
  private _searchCriteria = {};
  public dataSource: any;
  public searchRequestState: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private custInfoDataHolder: Array<any> = [];
  private locationTreeData = new Subject<any>();
  private locationSelectedTreeData: any;

  public searchRequestState$: Observable<number> = this.searchRequestState.asObservable();
  private _favoriteSearch = new Subject<any>();
  public recordCount: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  public recordCount$: Observable<number> = this.recordCount.asObservable();
  public allData: any;
  public resultSetData: Array<any>;
  public _isFilterOn: boolean = false;
  private _gridApiContext: any;
  paSearchRequest: any;
  pageSize: number = 50;
  _isSortOn: boolean;
  public PAType: string = "";
  public customerIds: Array<any> = [];
  public customerCVTIds: Array<any> = [];
  stateList: Array<any> = [];

  gridLoaded: Subject<boolean> = new Subject<boolean>();
  mySearchesNavigation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  windowResizeSubject: Subject<boolean> = new Subject<boolean>();
  _locationCount: number;

  public locationData: any;

  constructor(private _http: HttpService, private appService: AppService) {

  }

  setCustomerIds(CustomerData) {
    this.customerIds = CustomerData;
  }
  getCustomerIds(): Array<any> {
    return this.customerIds;
  }
  setLocationForEU(locData){
    this.locationData = locData;
  }
  getLocationForEU(): Array<any> {
    return this.locationData;
  }
  setCustInfoDataHolder(CustomerData) {
    this.custInfoDataHolder = CustomerData;
  }
  getCustInfoDataHolder(): Array<any> {
    return this.custInfoDataHolder;
  }
  setLocationCount(value) {
    this._locationCount = value;
  }
  getLocationCount(): number {
    return this._locationCount;
  }
  setCVTCustomerIds(CustomerData) {
    this.customerCVTIds = CustomerData;
  }
  getCVTCustomerIds(): Array<any> {
    return this.customerCVTIds;
  }

  setStateList(CustomerData) {
    this.stateList = CustomerData;
  }
  getStateList(): Array<any> {
    return this.stateList;
  }

  setPAType(PATypeData) {
    this.PAType = PATypeData;
  }
  getPAType() {
    return this.PAType;
  }

  loadPatype(cvtView : boolean = false) {
    this._http.getPATYpe(cvtView).subscribe(data => {
      this.appService.setPAtype(data);
    })
  }

  setLocationData(tree) {
    this.locationTreeData = tree;
  }
  getLocationData() {
    return this.locationTreeData;
  }

  setLocationSelectedData(tree) {
    this.locationSelectedTreeData = tree;
  }
  getLocationSelectedData() {
    return this.locationSelectedTreeData;
  }

  setGridLoaded(value) {
    return this.gridLoaded.next(value);
  }

  getGridLoaded() {
    return this.gridLoaded.asObservable();
  }

  setGridApiContext(gridContext: any): void {
    this._gridApiContext = gridContext;
  }

  getsearchData() {
    return this._searchCriteria;
  }
  initPASearch(searchObj) {
    this._searchCriteria = searchObj;
  }

  getSearchRequest(): Observable<any> {
    return this.searchRequestState.asObservable();
  }

  changeSearchRequest(searchObject) {
    this.appService.changeSearchRequest(searchObject);
    this.searchRequestState.next(searchObject);
  }

  getResultSet(): Array<any> {
    return this.resultSetData;
  }

  setResultSet(value: Array<any>) {
    if (value) {
      this.resultSetData = value.map((item) => {
        return item;
      });
    }
  }

  getFilterOn(): boolean {
    return this._isFilterOn;
  }

  setFilterOn(value: boolean) {
    this._isFilterOn = value;
  }

  getSortOn(): boolean {
    return this._isSortOn;
  }

  setSortOn(value: boolean) {
    this._isSortOn = value;
  }
  initializCountZero() {
    this.recordCount.next(0);
  }

  setMySearchesNavigation(value: boolean) {
    this.mySearchesNavigation.next(value);
  }

  getMySearchesNavigation(): Observable<boolean> {
    return this.mySearchesNavigation.asObservable();
  }

  setWindowResizeInPADetails(value: boolean) {
    this.windowResizeSubject.next(value);
  }

  getWindowResizeInPADetails(): Observable<boolean> {
    return this.windowResizeSubject.asObservable();
  }

  // getDataSourceActiveFuture() {
  //   let me = this;
  //   // if (!this.dataSource) {
  //   this.dataSource = {
  //     rowCount: null,
  //     getRows: (params) => {
  //       me._gridApiContext.showLoadingOverlay();


  //       if (!me.getFilterOn() && !(params.sortModel && params.sortModel.length > 0)) {
  //         //this.paSearchRequest = Object.assign({}, this._searchCriteria);
  //         //this.paSearchRequest.currentPage = (((params.startRow / this.paSearchRequest.pageSize) + 1) || 1);
  //         let currentPage = (((params.startRow / this.pageSize) + 1) || 1);
  //         me._http.getGridCoulmnData('1')
  //           .subscribe(data => {
  //             let lastRow = -1;
  //             if (data.count <= this.pageSize)
  //               lastRow = data.count;
  //             else {
  //               if (currentPage * this.pageSize < data.count)
  //                 lastRow = -1;
  //               else
  //                 lastRow = data.count;
  //             }


  //             me.allData = data.result;
  //             me.setResultSet(data.result);

  //             me._gridApiContext.hideOverlay();

  //             params.successCallback(data.result, lastRow);
  //             me._gridApiContext.sizeColumnsToFit();
  //             me.recordCount.next(data.count);
  //           });


  //       }
  //       else {
  //         let dataAfterSortingAndFiltering = me.sortAndFilter(me.allData, params.sortModel, params.filterModel);
  //         let rowsThisPage = dataAfterSortingAndFiltering.slice(params.startRow, params.endRow);
  //         let lastRow = -1;
  //         if (dataAfterSortingAndFiltering.length <= params.endRow) {
  //           lastRow = dataAfterSortingAndFiltering.length;
  //         }
  //         if (params.filterModel && Object.entries(params.filterModel).every(fm => fm[1]["value"] === null)) {
  //           lastRow = -1;
  //           this.setFilterOn(false);
  //         }
  //         params.successCallback(rowsThisPage, lastRow);
  //         me._gridApiContext.hideOverlay();
  //         me._gridApiContext.sizeColumnsToFit();
  //       }
  //     }

  //   }
  //   // };

  //   return this.dataSource;
  // }

  // getDataSourceRecentlyChanged() {
  //   let me = this;
  //   // if (!this.dataSource) {
  //   this.dataSource = {
  //     rowCount: null,
  //     getRows: (params) => {
  //       me._gridApiContext.showLoadingOverlay();

  //       if (!me.getFilterOn() && !(params.sortModel && params.sortModel.length > 0)) {
  //         //this.paSearchRequest = Object.assign({}, this._searchCriteria);
  //         //this.paSearchRequest.currentPage = (((params.startRow / this.paSearchRequest.pageSize) + 1) || 1);
  //         let currentPage = (((params.startRow / this.pageSize) + 1) || 1);

  //         me._http.getGridCoulmnData('2')
  //           .subscribe(data => {
  //             let lastRow = -1;
  //             if (data.count <= this.pageSize)
  //               lastRow = data.count;
  //             else {
  //               if (currentPage * this.pageSize < data.count)
  //                 lastRow = -1;
  //               else
  //                 lastRow = data.count;
  //             }

  //             me.allData = data.result;
  //             me.setResultSet(data.result);

  //             me._gridApiContext.hideOverlay();

  //             params.successCallback(data.result, lastRow);
  //             me._gridApiContext.sizeColumnsToFit();
  //             me.recordCount.next(data.count);
  //           });
  //       }
  //       else {
  //         let dataAfterSortingAndFiltering = me.sortAndFilter(me.allData, params.sortModel, params.filterModel);
  //         let rowsThisPage = dataAfterSortingAndFiltering.slice(params.startRow, params.endRow);
  //         let lastRow = -1;
  //         if (dataAfterSortingAndFiltering.length <= params.endRow) {
  //           lastRow = dataAfterSortingAndFiltering.length;
  //         }
  //         if (params.filterModel && Object.entries(params.filterModel).every(fm => fm[1]["value"] === null)) {
  //           lastRow = -1;
  //           this.setFilterOn(false);
  //         }
  //         params.successCallback(rowsThisPage, lastRow);
  //         me._gridApiContext.hideOverlay();
  //         me._gridApiContext.sizeColumnsToFit();
  //       }
  //     }
  //   }
  //   // };

  //   return this.dataSource;
  // }

  // getDataSourceExpiringSoon() {
  //   let me = this;
  //   // if (!this.dataSource) {
  //   this.dataSource = {
  //     rowCount: null,
  //     getRows: (params) => {
  //       me._gridApiContext.showLoadingOverlay();

  //       if (!me.getFilterOn() && !(params.sortModel && params.sortModel.length > 0)) {
  //         //this.paSearchRequest = Object.assign({}, this._searchCriteria);
  //         //this.paSearchRequest.currentPage = (((params.startRow / this.paSearchRequest.pageSize) + 1) || 1);
  //         let currentPage = (((params.startRow / this.pageSize) + 1) || 1);

  //         me._http.getGridCoulmnData('3')
  //           .subscribe(data => {
  //             let lastRow = -1;
  //             if (data.count <= this.pageSize)
  //               lastRow = data.count;
  //             else {
  //               if (currentPage * this.pageSize < data.count)
  //                 lastRow = -1;
  //               else
  //                 lastRow = data.count;
  //             }

  //             me.allData = data.result;
  //             me.setResultSet(data.result);

  //             me._gridApiContext.hideOverlay();


  //             params.successCallback(data.result, lastRow);
  //             me._gridApiContext.sizeColumnsToFit();
  //             me.recordCount.next(data.count);
  //           });
  //       }
  //       else {
  //         let dataAfterSortingAndFiltering = me.sortAndFilter(me.allData, params.sortModel, params.filterModel);
  //         let rowsThisPage = dataAfterSortingAndFiltering.slice(params.startRow, params.endRow);
  //         let lastRow = -1;
  //         if (dataAfterSortingAndFiltering.length <= params.endRow) {
  //           lastRow = dataAfterSortingAndFiltering.length;
  //         }
  //         if (params.filterModel && Object.entries(params.filterModel).every(fm => fm[1]["value"] === null)) {
  //           lastRow = -1;
  //           this.setFilterOn(false);
  //         }
  //         params.successCallback(rowsThisPage, lastRow);
  //         me._gridApiContext.hideOverlay();
  //         me._gridApiContext.sizeColumnsToFit();
  //       }
  //     }
  //   }
  //   // };

  //   return this.dataSource;
  // }

  // getDataSourceRecentlyExpired() {
  //   let me = this;
  //   //if (!this.dataSource) {
  //   this.dataSource = {
  //     rowCount: null,
  //     getRows: (params) => {
  //       me._gridApiContext.showLoadingOverlay();

  //       if (!me.getFilterOn() && !(params.sortModel && params.sortModel.length > 0)) {
  //         //this.paSearchRequest = Object.assign({}, this._searchCriteria);
  //         //this.paSearchRequest.currentPage = (((params.startRow / this.paSearchRequest.pageSize) + 1) || 1);
  //         let currentPage = (((params.startRow / this.pageSize) + 1) || 1);

  //         me._http.getGridCoulmnData('4')
  //           .subscribe(data => {
  //             let lastRow = -1;
  //             if (data.count <= this.pageSize)
  //               lastRow = data.count;
  //             else {
  //               if (currentPage * this.pageSize < data.count)
  //                 lastRow = -1;
  //               else
  //                 lastRow = data.count;
  //             }

  //             me.allData = data.result;
  //             me.setResultSet(data.result);

  //             me._gridApiContext.hideOverlay();

  //             params.successCallback(data.result, lastRow);
  //             me._gridApiContext.sizeColumnsToFit();
  //             me.recordCount.next(data.count);
  //           });
  //       }
  //       else {
  //         let dataAfterSortingAndFiltering = me.sortAndFilter(me.allData, params.sortModel, params.filterModel);
  //         let rowsThisPage = dataAfterSortingAndFiltering.slice(params.startRow, params.endRow);
  //         let lastRow = -1;
  //         if (dataAfterSortingAndFiltering.length <= params.endRow) {
  //           lastRow = dataAfterSortingAndFiltering.length;
  //         }
  //         if (params.filterModel && Object.entries(params.filterModel).every(fm => fm[1]["value"] === null)) {
  //           lastRow = -1;
  //           this.setFilterOn(false);
  //         }
  //         params.successCallback(rowsThisPage, lastRow);
  //         me._gridApiContext.hideOverlay();
  //         me._gridApiContext.sizeColumnsToFit();
  //       }
  //     }
  //   }
  //   //};

  //   return this.dataSource;
  // }

  // getDataSourceMyPAFavorites() {
  //   let me = this;
  //   // if (!this.dataSource) {
  //   this.dataSource = {
  //     rowCount: null,
  //     getRows: (params) => {
  //       me._gridApiContext.showLoadingOverlay();

  //       if (!me.getFilterOn() && !(params.sortModel && params.sortModel.length > 0)) {
  //         //this.paSearchRequest = Object.assign({}, this._searchCriteria);
  //         //this.paSearchRequest.currentPage = (((params.startRow / this.paSearchRequest.pageSize) + 1) || 1);
  //         let currentPage = (((params.startRow / this.pageSize) + 1) || 1);

  //         me._http.getGridCoulmnData('5')
  //           .subscribe(data => {
  //             let lastRow = -1;
  //             if (data.count <= this.pageSize)
  //               lastRow = data.count;
  //             else {
  //               if (currentPage * this.pageSize < data.count)
  //                 lastRow = -1;
  //               else
  //                 lastRow = data.count;
  //             }

  //             me.allData = data.result;
  //             me.setResultSet(data.result);

  //             me._gridApiContext.hideOverlay();

  //             params.successCallback(data.result, lastRow);
  //             me._gridApiContext.sizeColumnsToFit();
  //             me.recordCount.next(data.count);
  //           });
  //       }
  //       else {
  //         let dataAfterSortingAndFiltering = me.sortAndFilter(me.allData, params.sortModel, params.filterModel);
  //         let rowsThisPage = dataAfterSortingAndFiltering.slice(params.startRow, params.endRow);
  //         let lastRow = -1;
  //         if (dataAfterSortingAndFiltering.length <= params.endRow) {
  //           lastRow = dataAfterSortingAndFiltering.length;
  //         }
  //         if (params.filterModel && Object.entries(params.filterModel).every(fm => fm[1]["value"] === null)) {
  //           lastRow = -1;
  //           this.setFilterOn(false);
  //         }
  //         params.successCallback(rowsThisPage, lastRow);
  //         me._gridApiContext.hideOverlay();
  //         me._gridApiContext.sizeColumnsToFit();
  //       }
  //     }
  //   }
  //   // };

  //   return this.dataSource;
  // }

  sortAndFilter(allOfTheData, sortModel, filterModel) {
    return this.sortData(sortModel, this.filterData(filterModel, allOfTheData));
  }

  sortData(sortModel, data) {
    let sortPresent = sortModel && sortModel.length > 0;
    if (!sortPresent) {
      return data;
    }
    let resultOfSort = data.slice();
    resultOfSort.sort(function (a, b) {
      for (let k = 0; k < sortModel.length; k++) {
        let sortColModel = sortModel[k];
        let valueA = a[sortColModel.colId];
        let valueB = b[sortColModel.colId];
        if (valueA == valueB) {
          continue;
        }
        let sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
        if (valueA > valueB) {
          return sortDirection;
        } else {
          return sortDirection * -1;
        }
      }
      return 0;
    });
    return resultOfSort;
  }

  filterData(filterModel, data) {
    let filterPresent = filterModel && Object.keys(filterModel).length > 0;
    if (!filterPresent) {
      return data;
    }
    let resultOfFilter = [];
    for (let i = 0; i < data.length; i++) {
      let item = data[i];

      if (filterModel.customerNumber && filterModel.customerNumber.value && filterModel.customerNumber.value !== "") {
        if (item.customerNumber.indexOf(filterModel.customerNumber.value) < 0) {
          continue;
        }
      }

      if (filterModel.customerName && filterModel.customerName.value && filterModel.customerName.value !== "") {
        if (item.customerName.toLowerCase().indexOf(filterModel.customerName.value.toLowerCase()) < 0) {
          continue;
        }
      }

      if (filterModel.contractName && filterModel.contractName.value && filterModel.contractName.value !== "") {
        if (item.contractName.toLowerCase().indexOf(filterModel.contractName.value.toLowerCase()) < 0) {
          continue;
        }
      }

      if (filterModel.contractNumber && filterModel.contractNumber.value && filterModel.contractNumber.value !== "") {
        if (item.contractNumber.indexOf(filterModel.contractNumber.value) < 0) {
          continue;
        }
      }

      let hasTypeFilterPass: boolean;
      if (filterModel.type) {
        let selectedTypes = filterModel.type.value.filter(filter => {
          return filter.selected;
        });
        if (selectedTypes && selectedTypes.length > 0) {
          hasTypeFilterPass = selectedTypes.some(filter => {
            return (
              item.type
                .toString()
                .toLowerCase() === filter.text.toLowerCase()
            );
          });
        }
        // else {
        //   hasTypeFilterPass = true;
        // }

        if (!hasTypeFilterPass)
          continue;
      }

      if (filterModel.startDate && filterModel.startDate.value && filterModel.startDate.value !== "") {
        let startDateFilterPass: boolean;
        if (item.startDate) {
          let startDate = new Date(item.startDate).setHours(0, 0, 0, 0);
          let filterDate = filterModel.startDate.value.setHours(0, 0, 0, 0)
          if (filterDate === startDate) {
            startDateFilterPass = true
          }
        }
        if (!startDateFilterPass)
          continue;
      }

      if (filterModel.endDate && filterModel.endDate.value && filterModel.endDate.value !== "") {
        let endDateFilterPass: boolean;
        if (item.endDate) {
          let endDate = new Date(item.endDate).setHours(0, 0, 0, 0);
          let filterDate = filterModel.endDate.value.setHours(0, 0, 0, 0);
          if (filterDate === endDate) {
            endDateFilterPass = true
          }
        }
        if (!endDateFilterPass)
          continue;
      }

      if (filterModel.lastChanged && filterModel.lastChanged.value && filterModel.lastChanged.value.from && filterModel.lastChanged.value.to && filterModel.lastChanged.value.from !== "" && filterModel.lastChanged.value.to !== "") {
        let haslastChangedFilterPass = false;
        if (item.lastChanged) {
          let lastChanged = new Date(item.lastChanged).setHours(0, 0, 0, 0);
          let filterFromDate = filterModel.lastChanged.value.from.setHours(0, 0, 0, 0)
          let filterToDate = filterModel.lastChanged.value.to.setHours(0, 0, 0, 0)
          if (lastChanged >= filterFromDate && lastChanged <= filterToDate) {
            haslastChangedFilterPass = true;
          }
        }
        else {
          continue;
        }
        if (!haslastChangedFilterPass)
          continue;

      }

      resultOfFilter.push(item);
    }
    return resultOfFilter;
  }

  markSearchFavorite(searchItem: any) {
    // console.log("markSearchFavorite", searchItem);
    this._favoriteSearch.next(searchItem);
  }

  getMarkSearchFavorite() {
    // console.log("getMarkSearchFavorite", this._favoriteSearch);
    return this._favoriteSearch;
  }


  getSortMenus(params): any {
    return [
      {
        name: "Ascending",
        action: function () {
          params.context.isSorted = true;
          // params.context.sortHandler(params, 'asc');
          params.context.paServ.sortHandler(params, 'asc');
        },
        icon: '<i class="fas fa-sort-alpha-down"></i>',
      },
      {
        name: "Descending",
        action: function () {
          params.context.isSorted = true;
          // params.context.sortHandler(params, 'desc');
          params.context.paServ.sortHandler(params, 'desc');
        },
        icon: '<i class="fas fa-sort-alpha-down-alt"></i>'
      },
      {
        name: "Clear Sort",
        action: function () {
          params.context.isSorted = false;
          // params.context.sortHandler(params, null);
          params.context.paServ.sortHandler(params, null);
        },
        icon: '<img src="/assets/images/reset.png" style="width: 12px;"/>'
      },
    ];
  }

  sortHandler(params, sortOrder) {
    if (sortOrder)
      this._gridApiContext.setSortModel([{ sort: sortOrder, colId: params.column.colId }]);
    else
      this._gridApiContext.setSortModel(null);
  }

  loadCustomerLocations(results) {
    console.log("@@ DataToProcess: ", results);
    results = results.map(d => {
      let row: any = Object.assign({}, d);
      row.CustomerNumber = d.customerNumber;
      row.CustomerName = d.name;
      row.City = d.city;
      row.State = d.state;
      return row;
    });
    for (let ctr = 0; ctr <= results.length; ctr++) {
      this.recursiveProcessNode(results[ctr], ctr, results)
    }
    let temp = results.filter((n) => {
      return n.tree;
    });
    console.log("@@ Temo: ", temp);
    // return temp.map(d => {
    //   let row: any = Object.assign({}, d);
    //   row.CustomerNumber = d.customerNumber;
    //   row.CustomerName = d.name;
    //   row.City = d.city;
    //   row.State = d.state;
    //   // let allDataList: Array<string> = new Array<string>();
    //   // if (row.name)
    //   //   allDataList.push(row.name);
    //   // if (row.customerNumber)
    //   //   allDataList.push('(' + row.customerNumber + ')');
    //   // if (row.city)
    //   //   allDataList.push(row.city);
    //   // if (row.state)
    //   //   allDataList.push(row.state);
    //   // if (allDataList && allDataList.length)
    //   //   row.label = allDataList.join(', ');
    //   // row.data = row.customerNumber;
    //   return row;
    // });
    console.log("gfgcdfgcdf", temp);
    return temp;
  }

  // recursiveProcessNode(CURRENT_NODE, CURRENT_NODE_INDEX, ALL_DATA) {
  //   if (CURRENT_NODE && CURRENT_NODE['parentCustomer']) {
  //     let PREV_NODE_INDEX = CURRENT_NODE_INDEX === 0 ? -1 : CURRENT_NODE_INDEX - 1;
  //     if (PREV_NODE_INDEX >= 0) {
  //       let PREV_NODE = ALL_DATA[PREV_NODE_INDEX];
  //       if (PREV_NODE['customerNumber'] === CURRENT_NODE['parentCustomer']) {
  //         if (!PREV_NODE.children) {
  //           Object.assign(PREV_NODE, { 'children': [] });
  //         }
  //         let currentNode: any = Object.assign({}, CURRENT_NODE);
  //         currentNode.CustomerNumber = CURRENT_NODE.customerNumber;
  //         currentNode.CustomerName = CURRENT_NODE.name;
  //         PREV_NODE.children.push(currentNode);
  //       }
  //       else {
  //         this.recursiveProcessNode(CURRENT_NODE, PREV_NODE_INDEX, ALL_DATA);
  //       }
  //     }
  //   }
  //   else {
  //     if (CURRENT_NODE && !CURRENT_NODE.hasOwnProperty('tree')) {
  //       Object.assign(CURRENT_NODE, { 'tree': true });
  //     }
  //   }
  // }

  recursiveProcessNode(CURRENT_NODE, CURRENT_NODE_INDEX, ALL_DATA) {
    if (CURRENT_NODE && CURRENT_NODE['parentCustomer']) {
      let PREV_NODE_INDEX = CURRENT_NODE_INDEX === 0 ? -1 : CURRENT_NODE_INDEX - 1;
      if (PREV_NODE_INDEX >= 0) {
        let PREV_NODE = ALL_DATA[PREV_NODE_INDEX];
        if (PREV_NODE['customerNumber'] === CURRENT_NODE['parentCustomer']) {
          if (!PREV_NODE.children) {
            Object.assign(PREV_NODE, { 'children': [] });
          }
          PREV_NODE.children.push(CURRENT_NODE);
        }
        else {
          this.recursiveProcessNode(CURRENT_NODE, PREV_NODE_INDEX, ALL_DATA);
        }
      }
    }
    else {
      if (CURRENT_NODE && !CURRENT_NODE.hasOwnProperty('tree')) {
        Object.assign(CURRENT_NODE, { 'tree': true });
      }
    }
  }
}