/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./module-type-column.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./module-type-column.component";
var styles_ModuleTypeColumnComponent = [i0.styles];
var RenderType_ModuleTypeColumnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModuleTypeColumnComponent, data: {} });
export { RenderType_ModuleTypeColumnComponent as RenderType_ModuleTypeColumnComponent };
export function View_ModuleTypeColumnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.type)); _ck(_v, 0, 0, currVal_0); }); }
export function View_ModuleTypeColumnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-module-type-column", [], null, null, null, View_ModuleTypeColumnComponent_0, RenderType_ModuleTypeColumnComponent)), i1.ɵdid(1, 180224, null, 0, i3.ModuleTypeColumnComponent, [], null, null)], null, null); }
var ModuleTypeColumnComponentNgFactory = i1.ɵccf("app-module-type-column", i3.ModuleTypeColumnComponent, View_ModuleTypeColumnComponent_Host_0, {}, {}, []);
export { ModuleTypeColumnComponentNgFactory as ModuleTypeColumnComponentNgFactory };
