import { Component } from '@angular/core';
import { createProductCodeLink } from "@app/conf/appLink";
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-productcode-hyperlink',
  templateUrl: './productcode-hyperlink.component.html',
  styleUrls: ['./productcode-hyperlink.component.scss']
})
export class ProductcodeHyperlinkComponent implements AgRendererComponent {

  constructor() { }
  params: any;
  encryptedproductid: any;

  agInit(params: any): void {
    this.params = params;
    if (params && params.data)
      this.encryptedproductid = params.data.encryptedproductid;

  }

  refresh(params: any): boolean {
    return false;
  }
  onLinkClick(event) {
    let redirectUrl: string = createProductCodeLink.createProductCodeURI;
    redirectUrl = redirectUrl.replace("####", this.encryptedproductid);
    window.open(redirectUrl, '_blank');
  }

}
