export const currencyArray = [
  {
    'countryName': 'Australia',
    'code': 'AU',
    'separator': ',',
    'decimal': '.',
    'symbol': '$'
  },
  {
    'countryName': 'Bangladesh',
    'code': 'BD',
    'separator': ',',
    'decimal': '.',
    'symbol': '৳'
  },
  {
    'countryName': 'Botswana',
    'code': 'BW',
    'separator': ',',
    'decimal': '.',
    'symbol': 'P'
  },
  {
    'countryName': 'Cambodia',
    'code': 'KH',
    'separator': ',',
    'decimal': '.',
    'symbol': '៛'
  },
  {
    'countryName': 'Canada',
    'code': 'EN-CA',
    'separator': ',',
    'decimal': '.',
    'symbol': '$'
  },
  {
    'countryName': 'China',
    'code': 'CN',
    'separator': ',',
    'decimal': '.',
    'symbol': '¥'
  },
  {
    'countryName': 'Hong Kong',
    'code': 'HK',
    'separator': ',',
    'decimal': '.',
    'symbol': '$'
  },
  {
    'countryName': 'Cyprus',
    'code': 'CY',
    'separator': ',',
    'decimal': '.',
    'symbol': '€'
  },
  {
    'countryName': 'Egypt',
    'code': 'EG',
    'separator': ',',
    'decimal': '.',
    'symbol': '£'
  },
  {
    'countryName': 'El Salvador',
    'code': 'SV',
    'separator': ',',
    'decimal': '.',
    'symbol': '₡'
  },
  {
    'countryName': 'Ethiopia',
    'code': 'ET',
    'separator': ',',
    'decimal': '.',
    'symbol': 'ብር'
  },
  {
    'countryName': 'Ghana',
    'code': 'GH',
    'separator': ',',
    'decimal': '.',
    'symbol': '₵'
  },
  {
    'countryName': 'Great Britain',
    'code': 'GB',
    'separator': ',',
    'decimal': '.',
    'symbol': '$'
  },
  {
    'countryName': 'Guatemala',
    'code': 'GT',
    'separator': ',',
    'decimal': '.',
    'symbol': 'Q'
  },
  {
    'countryName': 'Guyana',
    'code': 'GY',
    'separator': ',',
    'decimal': '.',
    'symbol': '$'
  },
  {
    'countryName': 'Honduras',
    'code': 'HN',
    'separator': ',',
    'decimal': '.',
    'symbol': 'L'
  },
  {
    'countryName': 'India',
    'code': 'IN',
    'separator': ',',
    'decimal': '.',
    'symbol': '₹'
  },
  {
    'countryName': 'Ireland',
    'code': 'IE',
    'separator': ',',
    'decimal': '.',
    'symbol': '€'
  },
  {
    'countryName': 'Israel',
    'code': 'IL',
    'separator': ',',
    'decimal': '.',
    'symbol': '₪'
  },
  {
    'countryName': 'Jamaica',
    'code': 'JM',
    'separator': ',',
    'decimal': '.',
    'symbol': '$'
  },
  {
    'countryName': 'Japan',
    'code': 'JP',
    'separator': ',',
    'decimal': '.',
    'symbol': '¥'
  },
  {
    'countryName': 'Jordan',
    'code': 'JO',
    'separator': ',',
    'decimal': '.',
    'symbol': 'د.ا'
  },
  {
    'countryName': 'Kenya',
    'code': 'KE',
    'separator': ',',
    'decimal': '.',
    'symbol': 'Sh'
  },
  {
    'countryName': 'Libya',
    'code': 'LY',
    'separator': ',',
    'decimal': '.',
    'symbol': 'ل.د'
  },
  {
    'countryName': 'Liechtenstein',
    'code': 'LI',
    'separator': ',',
    'decimal': '.',
    'symbol': 'CHF'
  },
  {
    'countryName': 'Luxembourg',
    'code': 'LU',
    'separator': ',',
    'decimal': '.',
    'symbol': '€'
  },
  {
    'countryName': 'Malaysia',
    'code': 'MY',
    'separator': ',',
    'decimal': '.',
    'symbol': 'RM'
  },
  {
    'countryName': 'Maldives',
    'code': 'MV',
    'separator': ',',
    'decimal': '.',
    'symbol': 'ރ.'
  },
  {
    'countryName': 'Malta',
    'code': 'MT',
    'separator': ',',
    'decimal': '.',
    'symbol': '€'
  },
  {
    'countryName': 'Mexico',
    'code': 'MX',
    'separator': ',',
    'decimal': '.',
    'symbol': '$'
  },
  {
    'countryName': 'Myanmar',
    'code': 'MM',
    'separator': ',',
    'decimal': '.',
    'symbol': 'K'
  },
  {
    'countryName': 'Nepal',
    'code': 'NP',
    'separator': ',',
    'decimal': '.',
    'symbol': '₨'
  },
  {
    'countryName': 'New Zealand',
    'code': 'NZ',
    'separator': ',',
    'decimal': '.',
    'symbol': '$'
  },
  {
    'countryName': 'Nicaragua',
    'code': 'NI',
    'separator': ',',
    'decimal': '.',
    'symbol': 'C$'
  },
  {
    'countryName': 'Nigeria',
    'code': 'NG',
    'separator': ',',
    'decimal': '.',
    'symbol': '₦'
  },
  {
    'countryName': 'Pakistan',
    'code': 'PK',
    'separator': ',',
    'decimal': '.',
    'symbol': '₨'
  },
  {
    'countryName': 'Panama',
    'code': 'PA',
    'separator': ',',
    'decimal': '.',
    'symbol': 'B/.'
  },
  {
    'countryName': 'Puerto Rico',
    'code': 'PR',
    'separator': ',',
    'decimal': '.',
    'symbol': '$'
  },
  {
    'countryName': 'Qatar',
    'code': 'QA',
    'separator': ',',
    'decimal': '.',
    'symbol': 'ر.ق'
  },
  {
    'countryName': 'Saudi Arabia',
    'code': 'SA',
    'separator': ',',
    'decimal': '.',
    'symbol': 'ر.س'
  },
  {
    'countryName': 'Singapore',
    'code': 'SG',
    'separator': ',',
    'decimal': '.',
    'symbol': '$'
  },
  {
    'countryName': 'Somalia',
    'code': 'SO',
    'separator': ',',
    'decimal': '.',
    'symbol': 'Sh'
  },
  {
    'countryName': 'Sri Lanka',
    'code': 'LK',
    'separator': ',',
    'decimal': '.',
    'symbol': 'Rs'
  },
  {
    'countryName': 'Switzerland',
    'code': 'CH',
    'separator': ',',
    'decimal': '.',
    'symbol': '₣'
  },
  {
    'countryName': 'Thailand',
    'code': 'TH',
    'separator': ',',
    'decimal': '.',
    'symbol': '฿'
  },
  {
    'countryName': 'Uganda',
    'code': 'UG',
    'separator': ',',
    'decimal': '.',
    'symbol': 'Sh'
  },
  {
    'countryName': 'USA',
    'code': 'US',
    'separator': ',',
    'decimal': '.',
    'symbol': '$'
  },
  {
    'countryName': 'Zimbabwe',
    'code': 'ZW',
    'separator': ',',
    'decimal': '.',
    'symbol': '$'
  },
  {
    'countryName': 'Albania',
    'code': 'AL',
    'separator': '.',
    'decimal': ',',
    'symbol': 'L'
  },
  {
    'countryName': 'Algeria',
    'code': 'DZ',
    'separator': '.',
    'decimal': ',',
    'symbol': 'د.ج'
  },
  {
    'countryName': 'Andorra',
    'code': 'AD',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Angola',
    'code': 'AO',
    'separator': '.',
    'decimal': ',',
    'symbol': 'Kz'
  },
  {
    'countryName': 'Argentina',
    'code': 'AR',
    'separator': '.',
    'decimal': ',',
    'symbol': '$'
  },
  {
    'countryName': 'Armenia',
    'code': 'AM',
    'separator': '.',
    'decimal': ',',
    'symbol': 'Դ'
  },
  {
    'countryName': 'Austria',
    'code': 'AT',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Azerbaijan',
    'code': 'AZ',
    'separator': '.',
    'decimal': ',',
    'symbol': 'ман'
  },
  {
    'countryName': 'Belarus',
    'code': 'BY',
    'separator': '.',
    'decimal': ',',
    'symbol': 'Br'
  },
  {
    'countryName': 'Belgium',
    'code': 'BE',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Brazil',
    'code': 'BR',
    'separator': '.',
    'decimal': ',',
    'symbol': 'R$'
  },
  {
    'countryName': 'Bulgaria',
    'code': 'BG',
    'separator': '.',
    'decimal': ',',
    'symbol': 'лв'
  },
  {
    'countryName': 'Cameroon',
    'code': 'CM',
    'separator': '.',
    'decimal': ',',
    'symbol': '₣'
  },
  {
    'countryName': 'Canada',
    'code': 'FR-CA',
    'separator': '.',
    'decimal': ',',
    'symbol': '$'
  },
  {
    'countryName': 'Chile',
    'code': 'CL',
    'separator': '.',
    'decimal': ',',
    'symbol': '$'
  },
  {
    'countryName': 'Colombia',
    'code': 'CO',
    'separator': '.',
    'decimal': ',',
    'symbol': '$'
  },
  {
    'countryName': 'Costa Rica',
    'code': 'CR',
    'separator': '.',
    'decimal': ',',
    'symbol': '₡'
  },
  {
    'countryName': 'Croatia',
    'code': 'HR',
    'separator': '.',
    'decimal': ',',
    'symbol': 'Kn'
  },
  {
    'countryName': 'Cuba',
    'code': 'CU',
    'separator': '.',
    'decimal': ',',
    'symbol': '$'
  },
  {
    'countryName': 'Denmark',
    'code': 'DK',
    'separator': '.',
    'decimal': ',',
    'symbol': 'kr'
  },
  {
    'countryName': 'Ecuador',
    'code': 'EC',
    'separator': '.',
    'decimal': ',',
    'symbol': 'S/'
  },
  {
    'countryName': 'Estonia',
    'code': 'EE',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Finland',
    'code': 'FI',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'France',
    'code': 'FR',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Germany',
    'code': 'DE',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Georgia',
    'code': 'GE',
    'separator': '.',
    'decimal': ',',
    'symbol': 'ლ'
  },
  {
    'countryName': 'Greece',
    'code': 'GR',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Greenland',
    'code': 'GL',
    'separator': '.',
    'decimal': ',',
    'symbol': 'kr'
  },
  {
    'countryName': 'Hungary',
    'code': 'HU',
    'separator': '.',
    'decimal': ',',
    'symbol': 'Ft'
  },
  {
    'countryName': 'Iceland',
    'code': 'IS',
    'separator': '.',
    'decimal': ',',
    'symbol': 'Kr'
  },
  {
    'countryName': 'Indonesia',
    'code': 'ID',
    'separator': '.',
    'decimal': ',',
    'symbol': 'Rp'
  },
  {
    'countryName': 'Italy',
    'code': 'IT',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Kazakhstan',
    'code': 'KZ',
    'separator': '.',
    'decimal': ',',
    'symbol': '〒'
  },
  {
    'countryName': 'Kyrgyzstan',
    'code': 'KG',
    'separator': '.',
    'decimal': ',',
    'symbol': 'KGS'
  },
  {
    'countryName': 'Latvia',
    'code': 'LV',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Lebanon',
    'code': 'LB',
    'separator': '.',
    'decimal': ',',
    'symbol': 'ل.ل'
  },
  {
    'countryName': 'Lithuania',
    'code': 'LT',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Luxembourg',
    'code': 'LU',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Mongolia',
    'code': 'MN',
    'separator': '.',
    'decimal': ',',
    'symbol': '₮'
  },
  {
    'countryName': 'Morocco',
    'code': 'MA',
    'separator': '.',
    'decimal': ',',
    'symbol': 'د.م.'
  },
  {
    'countryName': 'Mozambique',
    'code': 'MZ',
    'separator': '.',
    'decimal': ',',
    'symbol': 'MTn'
  },
  {
    'countryName': 'Namibia',
    'code': 'NA',
    'separator': '.',
    'decimal': ',',
    'symbol': '$'
  },
  {
    'countryName': 'North Macedonia',
    'code': 'MK',
    'separator': '.',
    'decimal': ',',
    'symbol': 'Ден'
  },
  {
    'countryName': 'Norway',
    'code': 'NO',
    'separator': '.',
    'decimal': ',',
    'symbol': 'kr'
  },
  {
    'countryName': 'Paraguay',
    'code': 'PY',
    'separator': '.',
    'decimal': ',',
    'symbol': '₲'
  },
  {
    'countryName': 'Peru',
    'code': 'PE',
    'separator': '.',
    'decimal': ',',
    'symbol': 'S/.'
  },
  {
    'countryName': 'Poland',
    'code': 'PL',
    'separator': '.',
    'decimal': ',',
    'symbol': 'zł'
  },
  {
    'countryName': 'Portugal',
    'code': 'PT',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Romania',
    'code': 'RO',
    'separator': '.',
    'decimal': ',',
    'symbol': 'L'
  },
  {
    'countryName': 'Serbia',
    'code': 'RS',
    'separator': '.',
    'decimal': ',',
    'symbol': 'din'
  },
  {
    'countryName': 'Slovakia',
    'code': 'SK',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Slovenia',
    'code': 'SI',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Somalia',
    'code': 'SO',
    'separator': '.',
    'decimal': ',',
    'symbol': 'Sh'
  },
  {
    'countryName': 'South Africa',
    'code': 'ZA',
    'separator': '.',
    'decimal': ',',
    'symbol': 'R'
  },
  {
    'countryName': 'Spain',
    'code': 'ES',
    'separator': '.',
    'decimal': ',',
    'symbol': '€'
  },
  {
    'countryName': 'Suriname',
    'code': 'SR',
    'separator': '.',
    'decimal': ',',
    'symbol': '$'
  },
  {
    'countryName': 'Sweden',
    'code': 'SE',
    'separator': '.',
    'decimal': ',',
    'symbol': 'kr'
  },
  {
    'countryName': 'Switzerland',
    'code': 'CH',
    'separator': '.',
    'decimal': ',',
    'symbol': '₣'
  },
  {
    'countryName': 'Tunisia',
    'code': 'TN',
    'separator': '.',
    'decimal': ',',
    'symbol': 'د.ت'
  },
  {
    'countryName': 'Turkey',
    'code': 'TR',
    'separator': '.',
    'decimal': ',',
    'symbol': '₤'
  },
  {
    'countryName': 'Turkmenistan',
    'code': 'TM',
    'separator': '.',
    'decimal': ',',
    'symbol': 'm'
  },
  {
    'countryName': 'Ukraine',
    'code': 'UA',
    'separator': '.',
    'decimal': ',',
    'symbol': '₴'
  },
  {
    'countryName': 'Uruguay',
    'code': 'UY',
    'separator': '.',
    'decimal': ',',
    'symbol': '$'
  },
  {
    'countryName': 'Uzbekistan',
    'code': 'UZ',
    'separator': '.',
    'decimal': ',',
    'symbol': 'so'
  }
]



