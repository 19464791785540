import { PipeTransform } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var CouponDetailsService = /** @class */ (function () {
    function CouponDetailsService() {
        this.selectedCustEUSub = new BehaviorSubject(null);
        this.detailsPageQueryData = {};
        this.detailsPageQueryDataSub = new BehaviorSubject(this.detailsPageQueryData);
        this.downloadLineLevelDetailsSub = new BehaviorSubject(null);
    }
    CouponDetailsService.prototype.setSelectedCustEU = function (eu) {
        this.selectedCustEU = eu;
        this.selectedCustEUSub.next(eu);
    };
    CouponDetailsService.prototype.getSelectedCustEU = function () {
        return this.selectedCustEUSub.asObservable();
    };
    CouponDetailsService.prototype.setDetailsPageQueryData = function (obj) {
        this.detailsPageQueryData = Object.assign(this.detailsPageQueryData, obj);
        this.detailsPageQueryDataSub.next(this.detailsPageQueryData);
    };
    CouponDetailsService.prototype.getDetailsPageQueryData = function () {
        return this.detailsPageQueryDataSub.asObservable();
    };
    CouponDetailsService.ngInjectableDef = i0.defineInjectable({ factory: function CouponDetailsService_Factory() { return new CouponDetailsService(); }, token: CouponDetailsService, providedIn: "root" });
    return CouponDetailsService;
}());
export { CouponDetailsService };
var ReplaceString = /** @class */ (function () {
    function ReplaceString() {
    }
    ReplaceString.prototype.transform = function (str, replaceTo, replaceWith) {
        return str.replace(replaceTo, replaceWith);
    };
    return ReplaceString;
}());
export { ReplaceString };
