<div class="location-prefernces" *ngIf="currentNode.data && currentNode.data.name && currentNode.colDef.appFeatureId == 1">
    <input type="checkbox" [checked]="checkStatusPA" (change)="checkboxClick($event)" class="custom-control-input external-PA" [id]="checkboxNamePA">
    <label class="custom-control-label pa-label" [for]="checkboxNamePA" [ngClass]="checkboxStatus">&nbsp;</label>
</div>

<div class="location-prefernces" *ngIf="currentNode.data && currentNode.data.name && currentNode.colDef.appFeatureId == 2">
    <input type="checkbox" [checked]="checkStatusMC" (change)="checkboxClick($event)" class="custom-control-input external-MC" [id]="checkboxNameMC">
    <label class="custom-control-label pa-label" [for]="checkboxNameMC" [ngClass]="checkboxStatus">&nbsp;</label>
</div>

<div class="location-prefernces" *ngIf="currentNode.data && currentNode.data.name && currentNode.colDef.appFeatureId == 3">
    <input type="checkbox" [checked]="checkStatusCB" (change)="checkboxClick($event)" class="custom-control-input external-CB" [id]="checkboxNameCB">
    <label class="custom-control-label pa-label" [for]="checkboxNameCB" [ngClass]="checkboxStatus">&nbsp;</label>
</div>

<div class="location-prefernces" *ngIf="currentNode.data && currentNode.data.name && currentNode.colDef.appFeatureId == 4">
    <input type="checkbox" [checked]="checkStatusIN" (change)="checkboxClick($event)" class="custom-control-input external-IN" [id]="checkboxNameIN">
    <label class="custom-control-label pa-label" [for]="checkboxNameIN" [ngClass]="checkboxStatus">&nbsp;</label>
</div>

<div class="location-prefernces" *ngIf="currentNode.data && currentNode.data.name && currentNode.colDef.appFeatureId == 5">
    <input type="checkbox" [checked]="checkStatusOD" (change)="checkboxClick($event)" class="custom-control-input external-OD" [id]="checkboxNameOD">
    <label class="custom-control-label pa-label" [for]="checkboxNameOD" [ngClass]="checkboxStatus">&nbsp;</label>
</div>