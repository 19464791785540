import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { FreetextSearchComponent } from '@app/Modules/cp-grid/grid-features/filters/freetext-search/freetext-search.component';
import { CustomNoRowsOverlayComponent } from '@app/Modules/cp-grid/components/custom-no-rows-overlay/custom-no-rows-overlay.component';
import { CustomLoadingOverlayComponent } from '@app/Modules/cp-grid/components/custom-loading-overlay/custom-loading-overlay.component';
import { environment, cookieSIMID, APP_LEGACY_MENU_HOST } from '@env/environment';
import { RegionWiseColDefination } from "@app/conf/col-def-price-agreement/region-wise-col-conf";
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CpColumnHeaderComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/column-header/cp-column-header.component';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { TranslateService } from '@ngx-translate/core';
import { DateSelectorComponent } from '@app/Modules/cp-grid/grid-features/filters/date-selector/date-selector.component';
import { DateSelectorUFNComponent } from '@app/Modules/cp-grid/grid-features/filters/date-selector-ufn/date-selectorufn.component';
import { globalUserLink } from '@app/conf/appLink';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { MessageService } from 'primeng/api';
var DelegatedUserSearchResultComponent = /** @class */ (function () {
    function DelegatedUserSearchResultComponent(gridService, globalAppService, appService, router, activatedRoute, httpServ, translateServ, messageService) {
        this.gridService = gridService;
        this.globalAppService = globalAppService;
        this.appService = appService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.httpServ = httpServ;
        this.translateServ = translateServ;
        this.messageService = messageService;
        this.rowData = [];
        this.gridOptions = {};
        this.subscription = new Subscription();
        this.showRowSelectionMsg = false;
    }
    DelegatedUserSearchResultComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translateServ.get(['calender'])
            .subscribe(function (translations) {
            var calenderData = translations['calender'];
            _this.gridDateFormat = calenderData.gridDateFormat;
        });
        this.setGridAttributes();
        this.loadRegionWiseColumnDefination();
    };
    DelegatedUserSearchResultComponent.prototype.loadRegionWiseColumnDefination = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var colDefObj, delegatedUserSearchColDef;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.region = this.globalAppService.getDefaultRegionString();
                        colDefObj = new RegionWiseColDefination();
                        return [4 /*yield*/, colDefObj.getRegionWiseColDef(this.region)];
                    case 1:
                        delegatedUserSearchColDef = (_a.sent()).delegatedUserSearchColDef;
                        this.delegatedUserSearchColDef = delegatedUserSearchColDef;
                        this.setColumnDefinitions();
                        return [2 /*return*/];
                }
            });
        });
    };
    DelegatedUserSearchResultComponent.prototype.setColumnDefinitions = function () {
        var _this = this;
        this.subscription.add(this.globalAppService.getDefaultRegion().subscribe(function (region) {
            var columnDefs;
            switch (region) {
                case 'LAO':
                case 'NA':
                case 'EMEA':
                    columnDefs = _this.delegatedUserSearchColDef;
                    break;
            }
            // this.columnDefs = this.gridService.mapColDefs(columnDefs);
            _this.columnDefs = _this.gridService.mapColDefs(columnDefs).map(function (cd) {
                var modifiedColDef = Object.assign({}, cd);
                if (modifiedColDef.headerName === 'agGrid.agGridColumns.emailLabel') {
                    modifiedColDef.width = 300;
                    modifiedColDef.suppressSizeToFit = true;
                }
                return modifiedColDef;
            });
            _this.gridService.columnDefs = _this.columnDefs;
            _this.defaultColDef = _this.gridService.getDefaultColDef();
        }));
    };
    DelegatedUserSearchResultComponent.prototype.validateUserRoles = function () {
        var _this = this;
        if (this.gridApi && this.gridApi.getSelectedRows() && this.gridApi.getSelectedRows()[0]) {
            localStorage.setItem("temp-pre-impersonated-user", this.gridApi.getSelectedRows()[0].userId);
            this.httpServ.getUserDetails().subscribe(function (userDetails) {
                if (userDetails.ErrorType) {
                    _this.messageService.add({ key: 'noEBAM', severity: 'Error', summary: 'No Roles in EBAM', detail: 'User does not have any roles assigned in EBAM' });
                }
                else {
                    _this.applySelection();
                }
            }, function (onError) {
                _this.messageService.add({ key: 'noEBAM', severity: 'Error', summary: 'No Roles in EBAM', detail: 'User does not have any roles assigned in EBAM' });
            }, function () {
                localStorage.removeItem('temp-pre-impersonated-user');
            });
        }
        else {
            console.log("No User Selected To Imporsonate");
        }
    };
    DelegatedUserSearchResultComponent.prototype.applySelection = function () {
        var _this = this;
        this.appService.setLoadingStatus(true);
        var userId, email, name, phone;
        if (this.gridApi && this.gridApi.getSelectedRows() && this.gridApi.getSelectedRows()[0]) {
            userId = this.gridApi.getSelectedRows()[0].userId;
            email = this.gridApi.getSelectedRows()[0].email;
            name = this.gridApi.getSelectedRows()[0].firstName + " " + this.gridApi.getSelectedRows()[0].lastName;
            phone = this.gridApi.getSelectedRows()[0].phone;
        }
        this.httpServ.getImpersonatedUserInfo(userId).subscribe(function (userData) {
            if (userData && userData.roles && userData.regions) {
                window.localStorage.setItem("impersonatedUserRoles", userData.roles.join());
                window.localStorage.setItem("impersonatedUserRegions", userData.regions.join());
            }
            _this.appService.setLoadingStatus(false);
            _this.region = _this.globalAppService.getDefaultRegionString();
            var auditRequest = {
                "globalUserId": _this.appService.getuserId(true),
                "externalUserId": userId,
                "action": 1
            };
            if (_this.region === 'NA') {
                _this.appService.setUserSimulated(true);
                var qsList_1 = [
                    'userid=' + userId,
                    "useremail=" + email
                ];
                document.cookie = cookieSIMID + "=" + userId + "~^" + name + "~*" + email + "~&" + phone + ";path=/";
                _this.httpServ.globalUserAuditLog(auditRequest).subscribe(function (response) {
                    window.open(globalUserLink + qsList_1.join("&"), '_self');
                    // window.open("http://localhost:34025","_self");
                });
            }
            else {
                document.cookie = cookieSIMID + "=" + userId + "~^" + name + "~*" + email + "~&" + phone + ";path=/";
                // this.router.navigate([{ outlets: { delegatedResults: null } }], { relativeTo: this.activatedRoute }).then(() => {
                var navigateUrl_1 = APP_LEGACY_MENU_HOST;
                _this.httpServ.globalUserAuditLog(auditRequest).subscribe(function (response) {
                    window.location.href = navigateUrl_1;
                });
                // });
            }
        }, function () {
            _this.appService.setLoadingStatus(false);
        }, function () {
            _this.appService.setLoadingStatus(false);
        });
    };
    DelegatedUserSearchResultComponent.prototype.onGridReady = function (params) {
        this.gridService.onGridReady(params);
        this.gridApi = this.gridService.gridApi;
        this.setSearchRequestListener();
        this.setRecordCountListener();
    };
    DelegatedUserSearchResultComponent.prototype.onFilterChanged = function (event) {
        var filterModel = event.api.getFilterModel();
        this.paginationPageSize = this.gridService.clientPageSize;
        this.gridService.onFilterChanged();
        this.allFilters = this.gridService.allFilters;
    };
    DelegatedUserSearchResultComponent.prototype.onRemoveFilter = function (event, key) {
        // this.gridApi.deselectAll();
        this.gridService.removeFilters(key);
    };
    DelegatedUserSearchResultComponent.prototype.onColumnResized = function (event) {
    };
    DelegatedUserSearchResultComponent.prototype.onPageSizeChanged = function (event) {
        this.paginationPageSize = this.gridService.clientPageSize;
        if (this.paginationPageSize <= this.recordCount) {
            this.reinstatePage(this.globalAppService.getDelegatedUserSearchRequest(), false);
        }
        else {
            this.paginationPageSize = this.recordCount;
            this.gridApi.paginationSetPageSize(this.paginationPageSize);
        }
    };
    DelegatedUserSearchResultComponent.prototype.setSearchRequestListener = function () {
        var _this = this;
        var subscription = this.globalAppService.getDelegatedUserSearch().subscribe(function (searchRequest) {
            if (searchRequest) {
                _this.searchRequestObj = searchRequest;
                _this.showGrid = false;
                _this.showRowSelectionMsg = false;
                _this.paginationPageSize = _this.gridService.clientPageSize;
                _this.reinstatePage(searchRequest);
                _this.gridService.setReloadGrid(true);
            }
        });
        this.subscription.add(subscription);
    };
    DelegatedUserSearchResultComponent.prototype.setRecordCountListener = function () {
        var _this = this;
        this.subscription.add(this.gridService.getRecordCount().subscribe(function (recordCount) {
            _this.recordCount = recordCount;
            if (recordCount) {
                _this.paginationPageSize = _this.recordCount > _this.paginationPageSize ? _this.gridService.clientPageSize : _this.recordCount;
            }
        }));
    };
    DelegatedUserSearchResultComponent.prototype.reinstatePage = function (searchRequest, clearState) {
        if (clearState === void 0) { clearState = true; }
        if (clearState) {
            this.recordCount = 0;
            var isFilterApplied = this.allFilters && this.allFilters.length > 0;
            if (isFilterApplied)
                this.gridService.clearFilterState();
            this.allFilters = this.gridService.allFilters;
            if (this.gridService.isSorted)
                this.gridService.clearSortState();
            if (!this.gridService.isSorted && !isFilterApplied) {
                this.registerDataSource(searchRequest);
            }
        }
        else {
            this.registerDataSource(searchRequest);
        }
        this.gridService.sizeColumnsToFit();
    };
    DelegatedUserSearchResultComponent.prototype.registerDataSource = function (searchRequest) {
        this.setPageContext(searchRequest);
        this.gridApi.setDatasource(this.gridService.getDataSource());
    };
    DelegatedUserSearchResultComponent.prototype.setPageContext = function (searchRequest) {
        var pageContext = {
            // url: environment.searchDelegatedUsers.url,
            url: this.httpServ.getRegionSpecificURL(environment.searchDelegatedUsers),
            verb: 'POST',
            pageName: 'delegatedSearchUsers',
            request: searchRequest,
            dateFormat: this.gridDateFormat
        };
        this.gridService.pageContext = pageContext;
    };
    DelegatedUserSearchResultComponent.prototype.setGridAttributes = function () {
        this.rowHeight = 36;
        this.gridService.gridRowModelType = this.gridService.getRowModelType();
        this.rowModelType = this.gridService.getRowModelType();
        this.paginationPageSize = this.gridService.clientPageSize;
        this.cacheOverflowSize = this.gridService.getCacheOverflowSize();
        this.maxConcurrentDatasourceRequests = this.gridService.getMaxConcurrentDatasourceRequests();
        this.infiniteInitialRowCount = this.gridService.getInfiniteInitialRowCount();
        this.maxBlocksInCache = this.gridService.getMaxBlocksInCache();
        this.gridOptions.context = this;
        this.gridOptions.getContextMenuItems = function () {
            return ['copy'];
        };
        this.processCellForClipboard = function (params) {
            if (params.column.colDef.filter == "dateSelector" || params.column.colDef.filter == "dateSelectorUFN" || params.column.colDef.filter == "dateRangeSelector") {
                if (params.value) {
                    return (params.value.indexOf('9999') > -1) ? 'UFN' : new Date(params.value).toLocaleDateString('en-US', { year: "numeric", month: "2-digit", day: "2-digit" });
                }
                else
                    return '';
            }
            else {
                return params.value;
            }
        };
        this.frameworkComponents = {
            freeTextFilter: FreetextSearchComponent,
            dateSelector: DateSelectorComponent,
            dateSelectorUFN: DateSelectorUFNComponent,
            customNoRowsOverlay: CustomNoRowsOverlayComponent,
            agColumnHeader: CpColumnHeaderComponent,
            loadingOverlayComponent: CustomLoadingOverlayComponent
        };
        this.noRowsOverlayComponent = "customNoRowsOverlay";
        this.loadingOverlayComponent = 'loadingOverlayComponent';
        this.rowClassRules = this.gridService.gridRowClass();
    };
    DelegatedUserSearchResultComponent.prototype.onRowSelected = function (event) {
        if (event.api.getSelectedNodes() && event.api.getSelectedNodes()[0])
            this.showRowSelectionMsg = true;
        else
            this.showRowSelectionMsg = false;
    };
    DelegatedUserSearchResultComponent.prototype.exportExcelView = function () {
        var _this = this;
        this.appService.setLoadingStatus(true);
        var downloadObj = Object.assign({}, this.gridService.oRequest, { isGlobalSearchPage: "true" });
        this.httpServ.exportPortalUsage(downloadObj).subscribe(function (res) {
            if (res && res.fileUrl) {
                var opener_1 = window.open(res.fileUrl, '_blank');
                // setTimeout(() => { opener.close() }, 500);
            }
            else
                _this.messageService.add({ detail: 'An unexpected error occurred. Please try again.', summary: 'Error', severity: 'error' });
        }, function (e) {
            _this.messageService.add({ detail: 'An unexpected error occurred. Please try again.', summary: 'Error', severity: 'error' });
        }, function () {
            _this.appService.setLoadingStatus(false);
        });
    };
    DelegatedUserSearchResultComponent.prototype.ngOnDestroy = function () {
    };
    DelegatedUserSearchResultComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [CpAgGridService,
            GlobalAppService,
            AppService,
            Router,
            ActivatedRoute,
            HttpService,
            TranslateService,
            MessageService])
    ], DelegatedUserSearchResultComponent);
    return DelegatedUserSearchResultComponent;
}());
export { DelegatedUserSearchResultComponent };
