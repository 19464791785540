import { Component, OnDestroy, ViewChild, ChangeDetectorRef, ViewRef } from '@angular/core';
import {
  IDoesFilterPassParams,
  IFilterParams,
  RowNode,
} from 'ag-grid-community';

import { IFilterAngularComp } from 'ag-grid-angular';

import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { TranslateService } from '@ngx-translate/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { Calendar } from 'primeng/calendar';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';

@AutoUnsubscribe()
@Component({
  selector: 'date-selectorufn',
  templateUrl: './date-selectorufn.component.html',
  styleUrls: ['./date-selectorufn.component.scss']
})
export class DateSelectorUFNComponent implements IFilterAngularComp, OnDestroy {

  public params: IFilterParams;
  public valueGetter: (rowNode: RowNode) => any;
  maxDateValue: Date = new Date();
  calenderData: any;
  txtDate: Date;
  txtDateRadio: any = false;
  filterModel: any = { type: "date-selectorufn", value: this.txtDate };
  public colId: string;
  isDateSelected: boolean = false;
  isapplyClicked: boolean;
  guiParams: any;
  subscription: Subscription = new Subscription();
  orgColId: string;
  dateFormat: any;
  primengDateFormat: any;
  region: any;
  @ViewChild('calendarElement') calendarElement: Calendar;

  constructor(
    private translateServ: TranslateService,
    private cd: ChangeDetectorRef,
    private appServ: AppService,
    private languageServ: LanguageSelectionService,
    private globalAppService: GlobalAppService,
    private gridService: CpAgGridService) {
  }

  agInit(params: IFilterParams): void {
    this.params = params;
    this.region = this.globalAppService.getDefaultRegionString();
    this.orgColId = params.column.getColId();
    this.colId = params.colDef.field;
    this.valueGetter = params.valueGetter;
    this.colId = this.colId.indexOf('_') !== -1 ? this.colId.substring(0, this.colId.indexOf('_')) : this.colId;
    if (this.colId !== 'endDate' && this.colId !== 'customerEndDate' && this.colId !== 'availableUntil') {
      if(this.region == 'NA' || this.colId == 'firmEndDate'){
        this.maxDateValue = null;
      }else{
        this.maxDateValue.setDate(this.maxDateValue.getDate());
      }
    }
    else
      this.maxDateValue = null;

    this.subscription.add(this.languageServ.currentSelected.subscribe((data) => {
      this.subscription.add(this.translateServ.get(['calender'])
        .subscribe(translations => {
          this.calenderData = translations['calender'];
          this.dateFormat = this.calenderData.format;
          this.primengDateFormat = this.calenderData.dateFormat.toLowerCase();
        }));
    }));

    this.params.api.setPopupParent(document.querySelector('body'));
  }

  getModel(): any {
    if(this.isapplyClicked){
      this.filterModel.value = this.txtDate;
    } else if(!this.txtDate){
      this.filterModel.value = null;
    }
    console.log("@@@in GetModel: ", this.filterModel);
    return this.filterModel;
  }

  setModel(model: any): void {
    if (model && model.value) {
      this.txtDate = this.filterModel.value = model.value;
    }
    else {
      this.txtDate = null;
      this.filterModel.value = "";
    }
  }

  isFilterActive(): boolean {
    return this.txtDate && this.txtDate instanceof Date;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return true;
  }

  // noinspection JSMethodCanBeStatic
  componentMethod(message: string): void {
    alert(`Alert from DateSelectorComponent ${message}`);
  }

  onDateSelected(event): void {
    this.isDateSelected = true;
  }

  onApplyClick(event, cpContainer) {
    this.isapplyClicked = true; 
    if (this.txtDateRadio) {
      this.txtDate = new Date('12/31/9999');
      this.isDateSelected = true;
    }

    if (this.isDateSelected) {
      this.params.filterChangedCallback();
      this.guiParams.hidePopup();
      this.isDateSelected = false;
    }
    cpContainer.classList.remove('date_range--popup')
  }

  OnCalClosed(cpContainer){
    cpContainer.classList.remove('date_range--popup')
  }
  
  onRemoveFilter() {
    this.txtDate = null;
    this.txtDateRadio = false;
    this.gridService.removeFilters(this.params.column.getColId());
    this.guiParams.hidePopup();
  }

  afterGuiAttached(params) {
    if (this.calendarElement && this.calendarElement.inputfieldViewChild)
      this.calendarElement.inputfieldViewChild.nativeElement.placeholder = this.dateFormat;
    this.guiParams = params;

    this.subscription.add(this.languageServ.currentSelected.subscribe((data) => {
      this.subscription.add(this.translateServ.get(['calender'])
        .subscribe(translations => {
          this.calenderData = translations['calender'];
          this.dateFormat = this.calenderData.format;
          this.primengDateFormat = this.calenderData.dateFormat.toLowerCase();
        }));
    }));
    if(!this.txtDate){
      this.txtDateRadio = false;
      if(this.appServ.isIEBrowser()){
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      }
    }
    
    if(!this.isDateSelected && !this.txtDateRadio && !this.isapplyClicked){
      this.txtDate = null;
    }
    if(this.txtDate){
      this.isDateSelected = true;
    }
    this.isapplyClicked = false;
  }

  onSortRequested(isSortedRequest) {
    if (!!isSortedRequest) {
      this.guiParams.hidePopup();
    }
  }

  onCalFocus(event) {
    this.txtDateRadio = false;
    let UFNDate = new Date('12/31/9999').getTime();
    let selectedDate = this.txtDate ? this.txtDate.getTime() : null;
    if(selectedDate == UFNDate){
      this.txtDate = null;
    }
    event.classList.add('date_range--popup');
    if(this.appServ.isIEBrowser()){
      if (this.cd && !(this.cd as ViewRef).destroyed) {
        this.cd.detectChanges();
      }
    }
  }

  onUFNChecboxClicked(event){
    if(event.target.checked){
      this.txtDate = new Date('12/31/9999');
      this.isDateSelected = true;
    } else {
      this.txtDate = null;
      this.isDateSelected = false;
    }
    /** To render UI quickly in IE */
    if(this.appServ.isIEBrowser()){
      if (this.cd && !(this.cd as ViewRef).destroyed) {
        this.cd.detectChanges();
      }
    }
  }

  ngOnDestroy() {
    if(!this.isapplyClicked){
      this.txtDate = null;
    }
    // Required for unsubscribing and destroying the component  
  }
}
