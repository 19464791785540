<ng-container>
  <span class="custom-table__row-menu">
    <i class="fas fa-ellipsis-h ellipsisbtn" [ngClass]="{'ellipseSelect' : ellipseSelect}" (click)="contextmenuToggle(contextmenu, $event)"></i>
  </span>
</ng-container>
<p-overlayPanel appendTo="body" styleClass="contextMenu" #contextmenu (onHide)="onContextMenuHide()" (onShow)="onContextMenuShow()">
  <span class="custom-table__popup__info custom-table__row-menu">
    <ul class="custom-table__row-menu__content">
      <li>
        <a href="javascript:void(0)" (click)="openMeetCompDetails()">
          <i class="far fa-list-alt"></i>
          {{'meetcomps.contextMenu.meetCompDetails' | translate}}
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="openDownloadDialog()">
          <i class="far fa-arrow-alt-circle-down"></i>
          {{'meetcomps.contextMenu.downloadAgreement' | translate}}
        </a>
      </li>
    </ul>
  </span>
</p-overlayPanel>


<p-toast key="efileDownload" position="center" [style]="{ marginTop : '100px', width: '400px' }" [closable]="true"
  [baseZIndex]="500" [autoZIndex]="100" [hideTransitionOptions]="'250ms'">
  <ng-template let-message pTemplate="message">
    <div style="text-align: center; padding-left: 20px;">
      <h3>{{message.summary | translate}}</h3>
      <p>{{message.detail | translate}}</p>
    </div>
  </ng-template>
</p-toast>




