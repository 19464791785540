/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./efile-errorcell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/tooltip/tooltip";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "./efile-errorcell.component";
var styles_EfileErrorcellComponent = [i0.styles];
var RenderType_EfileErrorcellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EfileErrorcellComponent, data: {} });
export { RenderType_EfileErrorcellComponent as RenderType_EfileErrorcellComponent };
function View_EfileErrorcellComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "emptySpan"], ["tooltipPosition", "top"]], [[8, "title", 0]], null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.Tooltip, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], escape: [1, "escape"], text: [2, "text"] }, null), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = "top"; var currVal_2 = false; var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 2).transform(_co.tooltipText)), ""); _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.params.value, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_EfileErrorcellComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "tooltip-hover tooltip-hover ellipsis"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["tooltipPosition", "top"]], [[8, "title", 0]], null, null, null, null)), i1.ɵdid(2, 4341760, null, 0, i2.Tooltip, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], escape: [1, "escape"], text: [2, "text"] }, null), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileErrorcellComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "top"; var currVal_2 = false; var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 2, i1.ɵnov(_v, 3).transform(_co.tooltipText)), ""); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = _co.tooltipText; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.params.value, ""); _ck(_v, 1, 0, currVal_0); var currVal_4 = _co.params.value; _ck(_v, 4, 0, currVal_4); }); }
export function View_EfileErrorcellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "errorcell", [], null, null, null, View_EfileErrorcellComponent_0, RenderType_EfileErrorcellComponent)), i1.ɵdid(1, 49152, null, 0, i5.EfileErrorcellComponent, [], null, null)], null, null); }
var EfileErrorcellComponentNgFactory = i1.ɵccf("errorcell", i5.EfileErrorcellComponent, View_EfileErrorcellComponent_Host_0, {}, {}, []);
export { EfileErrorcellComponentNgFactory as EfileErrorcellComponentNgFactory };
