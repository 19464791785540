<div class="body-wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="content-area__white-box">
                    <div class="modal__language-selector">
                        <ul class=" unlisted inline ">
                            <li *ngIf="!hideEnglishTab" [ngClass]="{ 'current-language' : isEnglishActive }" (click)="onEnglishClick()">
                                English
                            </li>
                            <li [ngClass]="{ 'current-language' : !isEnglishActive || hideEnglishTab }" (click)="onUserLanguageClick()">
                                {{userLanguageName}}
                            </li>
                        </ul>
                    </div>
                    <div class="info-content" [innerHTML]="policyContent">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- content area end -->
</div>
<!-- body wrapper end -->