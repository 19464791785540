<div class="body-wrapper">
   <header>
      <div class="container-fluid">
         <div class="row">
            <div class="col-12">
               <div class="header">
                  <div class="header__left">
                     <a class="header__left__logo" routerLink="/"><img src="/assets/images/KCP-logo.png"></a>
                     <h1 class="header__left__heading">
                        <span class="bold-heading">Customer Portal</span>
                     </h1>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </header>

   <div class="content-area">
      <!-- content area start -->
      <div class="container-fluid">
         <div class="row justify-content-md-center">
            <div class="select-region col-12 col-md-6">
               <p class="select-region__heading">Please select your region</p>
               <div class="region-selector">
                  <div class="row">
                     <div class="col-12 col-md-4 center-block">
                        <div class="form-group">
                           <label for="continent" class="hidden-text-for-accessibility">Select Region</label>
                           <p-dropdown name="continent" [options]="regions" [autoWidth]="true"
                              styleClass="form-control custom-select SumoSelect" id="continent"
                              dropdownIcon="select__Arrow" [showClear]="false" [(ngModel)]="selectedRegion">
                           </p-dropdown>
                        </div>
                     </div>
                  </div>
               </div>
               <a href="javascript:void(0)" class="btn btn-primary btn-primary--icon-right"
                  (click)="onContinueClick($event)">Continue <i class="far fa-arrow-alt-circle-right"></i></a>
            </div>
         </div>
      </div>
   </div> <!-- content area end -->
</div> <!-- body wrapper end -->