/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./productcode-hyperlink.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./productcode-hyperlink.component";
var styles_ProductcodeHyperlinkComponent = [i0.styles];
var RenderType_ProductcodeHyperlinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductcodeHyperlinkComponent, data: {} });
export { RenderType_ProductcodeHyperlinkComponent as RenderType_ProductcodeHyperlinkComponent };
export function View_ProductcodeHyperlinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLinkClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 1, 0, currVal_0); }); }
export function View_ProductcodeHyperlinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-productcode-hyperlink", [], null, null, null, View_ProductcodeHyperlinkComponent_0, RenderType_ProductcodeHyperlinkComponent)), i1.ɵdid(1, 49152, null, 0, i2.ProductcodeHyperlinkComponent, [], null, null)], null, null); }
var ProductcodeHyperlinkComponentNgFactory = i1.ɵccf("app-productcode-hyperlink", i2.ProductcodeHyperlinkComponent, View_ProductcodeHyperlinkComponent_Host_0, {}, {}, []);
export { ProductcodeHyperlinkComponentNgFactory as ProductcodeHyperlinkComponentNgFactory };
