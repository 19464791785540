/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hyperlink-and-click.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./hyperlink-and-click.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../../Shared/Services/http.service";
import * as i5 from "../../../services/cp-ag-grid-service";
import * as i6 from "../../../../Shared/Services/app.service";
import * as i7 from "../../../../Shared/Services/global-app.service";
import * as i8 from "../../../../Shared/Services/SingnalR.master.service";
var styles_HyperlinkAndClickComponent = [i0.styles];
var RenderType_HyperlinkAndClickComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HyperlinkAndClickComponent, data: {} });
export { RenderType_HyperlinkAndClickComponent as RenderType_HyperlinkAndClickComponent };
export function View_HyperlinkAndClickComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLinkClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 2, 0, currVal_0); }); }
export function View_HyperlinkAndClickComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "hyperlink-and-click", [], null, null, null, View_HyperlinkAndClickComponent_0, RenderType_HyperlinkAndClickComponent)), i1.ɵdid(1, 245760, null, 0, i2.HyperlinkAndClickComponent, [i3.Router, i3.ActivatedRoute, i4.HttpService, i5.CpAgGridService, i6.AppService, i7.GlobalAppService, i8.SignalRMaster], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HyperlinkAndClickComponentNgFactory = i1.ɵccf("hyperlink-and-click", i2.HyperlinkAndClickComponent, View_HyperlinkAndClickComponent_Host_0, {}, {}, []);
export { HyperlinkAndClickComponentNgFactory as HyperlinkAndClickComponentNgFactory };
