import { Injectable } from '@angular/core';
import { customerList, ribbonMenuInvoice } from '@app/conf/dataConfigurations';
import { InvoiceCustomerSearch } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoicePriceService {
  stateList: any;
  loadPatype(cvtView: boolean) {
    throw new Error('Method not implemented.');
  }

  public searchRequestState: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  gridLoaded: Subject<boolean> = new Subject<boolean>();
  private showErrorMessage: Subject<boolean> = new Subject<boolean>();
  private reloadDownloadAllPage: Subject<boolean> = new Subject<boolean>();
  private menu : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _favoriteSearch = new Subject<any>();
  public InvoiceSearchRequestState: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _invoiceSearchCriteria = {};
  public recordcountForMCDownload: number;
  mySearchesNavigation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private showProcessingPopup: Subject<any> = new Subject<any>();
  private isRedrawRow: Subject<any> = new Subject<any>();
  private showEmailPopup: Subject<any> = new Subject<any>();
  private updateCategoryStatus: Subject<any> = new Subject<any>();
  private status: Subject<any> = new Subject<any>();
  private updateFavHeaderStatus: Subject<boolean> = new Subject<boolean>();
  customerList: any;
  // customerList: Subject<any> = new Subject<any>();

  constructor(
    private appServ: AppService,
    private globalAppService: GlobalAppService
  ) {
      this.setInvoiceMenu();
   }

  initInvoiceSearch(searchObj: InvoiceCustomerSearch) {
    this._invoiceSearchCriteria = searchObj;
  }

  getSearchData() {
    return this._invoiceSearchCriteria;
  }

  getSearchRequest(): Observable<any> {
    return this.searchRequestState.asObservable();
  }

  changeSearchRequest(searchObject: InvoiceCustomerSearch) {
    this.appServ.changeSearchRequest(searchObject);
    this.searchRequestState.next(searchObject);
  }

  setGridLoaded(value: boolean) {
    return this.gridLoaded.next(value);
  }

  getGridLoaded() {
    return this.gridLoaded.asObservable();
  }

  setErrorMsg(showError: boolean){
    this.showErrorMessage.next(showError);
  }

  getErrorMsg(): Observable<any> {
    return this.showErrorMessage.asObservable();
  }

  setReloadDownloadAllPage(isReload: boolean){
    this.reloadDownloadAllPage.next(isReload);
  }

  getReloadDownloadAllPage(): Observable<any> {
    return this.reloadDownloadAllPage.asObservable();
  }

  setInvoiceMenu(){
    this.globalAppService.getDefaultRegion().subscribe(region => {
      this.menu.next(ribbonMenuInvoice[region]);
      return this.menu;
    })
  }

  getInvoiceMenu(): Observable<any> {
    return this.menu;
  }

  getMarkSearchFavorite() {
    // console.log("getMarkSearchFavorite", this._favoriteSearch);
    return this._favoriteSearch;
  }

  markSearchFavorite(searchItem: any) {
    // console.log("markSearchFavorite", searchItem);
    this._favoriteSearch.next(searchItem);
  }
  
  setRecordCountForMCDownload(count: number){
    this.recordcountForMCDownload = count;
  }

  setMySearchesNavigation(value: boolean) {
    this.mySearchesNavigation.next(value);
  }

  getMySearchesNavigation(): Observable<boolean> {
    return this.mySearchesNavigation.asObservable();
  }

  setStateList(CustomerData: any) {
    this.stateList = CustomerData;
  }

  getStateList(): Array<any> {
    return this.stateList;
  }

  setProcessingPopupVisible(categoryData: { customerNumber: any; categoryList: any; }){
    this.showProcessingPopup.next(categoryData);
  }

  getProcessingPopupVisible(): Observable<any> {
    return this.showProcessingPopup.asObservable();
  }

  setRedrawAdvancedGridRow(favData: any){
    this.isRedrawRow.next(favData);
  }

  getRedrawAdvancedGridRow(): Observable<any> {
    return this.isRedrawRow.asObservable();
  }

  setEmailPopupVisible(emailData: any){
    this.showEmailPopup.next(emailData);
  }

  getEmailPopupVisible(): Observable<any> {
    return this.showEmailPopup.asObservable();
  }

  setUpdateRefreshStatusRequest(statusData: any){
    this.updateCategoryStatus.next(statusData);
  }

  getUpdateRefreshStatusRequest(): Observable<any> {
    return this.updateCategoryStatus.asObservable();
  }

  setUpdateFavColHeader(updateStatus: boolean) {
    this.updateFavHeaderStatus.next(updateStatus);
  }

  getUpdateFavColHeader(): Observable<boolean> {
    return this.updateFavHeaderStatus.asObservable();
  }

  // setFavoritecustomerList(customerNumbers){
  //   this.customerList.next(customerNumbers);
  // }

  // getFavoritecustomerList(): Observable<any>{
  //   return this.customerList.asObservable();
  // }

  set favoritecustomerList(customerNumbers){
    this.customerList = customerNumbers;
  }

  get favoritecustomerList(){
    return this.customerList;
  }
  saveFavoriteSearch(searchItem: any) {
    // console.log("markSearchFavorite", searchItem);
    this._favoriteSearch.next(searchItem);
  }

  setStatus(statusData: any){
    this.status.next(statusData);
  }

  getStatus(): Observable<any> {
    return this.status.asObservable();
  }
  
}
