import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
var DelegatedUserHyperlinkComponent = /** @class */ (function () {
    function DelegatedUserHyperlinkComponent(_appSer) {
        this._appSer = _appSer;
    }
    DelegatedUserHyperlinkComponent.prototype.ngOnInit = function () {
    };
    DelegatedUserHyperlinkComponent.prototype.agInit = function (params) {
        this.isGlobalUser = this._appSer.isGlobalUser;
        this.params = params;
        if (this.params) {
            this.isGroupAdmin = this.params.data ? this.params.data.isGroupAdmin : false;
            this.isNonAssociatedUser = this.params.data ? this.params.data.isNonAssociatedUser : false;
        }
    };
    DelegatedUserHyperlinkComponent.prototype.refresh = function (params) {
        return false;
    };
    DelegatedUserHyperlinkComponent.prototype.ngOnDestroy = function () {
    };
    DelegatedUserHyperlinkComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [AppService])
    ], DelegatedUserHyperlinkComponent);
    return DelegatedUserHyperlinkComponent;
}());
export { DelegatedUserHyperlinkComponent };
