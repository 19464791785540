import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { HttpService } from './../../../../Shared/Services/http.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { ActivatedRoute, Router } from '@angular/router';

@AutoUnsubscribe()
@Component({
  selector: 'app-recent-download-document',
  templateUrl: './recent-download-document.component.html',
  styleUrls: ['./recent-download-document.component.scss']
})

export class RecentDownloadDocumentComponent implements AgRendererComponent, OnDestroy {

  constructor(private httpService: HttpService,
              private appServ: AppService, 
              private route: ActivatedRoute,
              private router: Router) { }
  params: any;
  documentId: any;
  subscription: Subscription = new Subscription();

  agInit(params: any): void {

    this.params = params;
    this.documentId = params.data ? params.data.id : "";
  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClick(event) {
    if (event.srcElement.id) {
      this.documentId = event.srcElement.id;
      this.subscription.add(this.httpService.downloadSelectedRecentDownload(this.documentId, this.appServ.isGlobalUser).subscribe((res) => {
        if (res && res.fileUrl) {
          let downloadResource = res.fileUrl;
          this.router.navigate([{ outlets: { dialogs: null } }]).then(() => {
            this.router.navigate([{ outlets: { dialogs: ['downloadLinkModal'] } }], { relativeTo: this.route.parent, state: { url: downloadResource, title: '' } });
          });
        }
      }));
    }
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }
}
