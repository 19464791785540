import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { of } from 'rxjs';
import { GlobalAppService } from './global-app.service';
import { timeout, catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global-app.service";
var HttpMeetCompService = /** @class */ (function () {
    function HttpMeetCompService(http, _globalAppService) {
        this.http = http;
        this._globalAppService = _globalAppService;
    }
    //fetch region specific url from environment.ts
    HttpMeetCompService.prototype.getRegionSpecificURL = function (item) {
        var regionURL = "";
        var region = localStorage.getItem('default-region') || this._globalAppService.getDefaultRegionString() || this._globalAppService.selectedRegion;
        if (region) {
            regionURL = "url_" + region.toUpperCase();
        }
        else {
            regionURL = "url_NA";
        }
        return item[regionURL];
    };
    HttpMeetCompService.prototype.makeHTTPCall = function (method, endPoint, additionalHeaders, requestParams) {
        if (additionalHeaders === void 0) { additionalHeaders = null; }
        if (requestParams === void 0) { requestParams = null; }
        var requestBody = {};
        if (additionalHeaders) {
            requestBody = Object.assign({}, requestBody, { headers: additionalHeaders });
        }
        if (requestParams) {
            requestBody = Object.assign({}, requestBody, { body: requestParams });
        }
        return this.http.request(method, endPoint, requestBody);
    };
    /**
     * Method to call Search API, based on provided search fields entries.
     * @param searchObj
     */
    HttpMeetCompService.prototype.mcGetCustomerDetailsGrid = function (requestObj) {
        return this.makeHTTPCall(environment.mcGetCustomerDetailsGrid.method, this.getRegionSpecificURL(environment.mcGetCustomerDetailsGrid), null, requestObj);
    };
    HttpMeetCompService.prototype.mcGetMeetCompDetail = function (requestObj) {
        return this.makeHTTPCall(environment.mcGetMeetCompDetail.method, this.getRegionSpecificURL(environment.mcGetMeetCompDetail), null, requestObj);
    };
    HttpMeetCompService.prototype.exportMCCustomerDetailsGrid = function (requestObj) {
        return this.makeHTTPCall(environment.exportMCCustomerDetailsGrid.method, this.getRegionSpecificURL(environment.exportMCCustomerDetailsGrid), null, requestObj);
    };
    HttpMeetCompService.prototype.mcGetProductsDetailsGrid = function (requestObj) {
        return this.makeHTTPCall(environment.mcGetProductsDetailsGrid.method, this.getRegionSpecificURL(environment.mcGetProductsDetailsGrid), null, requestObj);
    };
    HttpMeetCompService.prototype.exportMCProductsDetailsGrid = function (requestObj) {
        return this.makeHTTPCall(environment.exportMCProductsDetailsGrid.method, this.getRegionSpecificURL(environment.exportMCProductsDetailsGrid), null, requestObj);
    };
    HttpMeetCompService.prototype.exportMCSearchGrid = function (requestObj) {
        return this.makeHTTPCall(environment.mcExportSearchGrid.method, this.getRegionSpecificURL(environment.mcExportSearchGrid), null, requestObj);
    };
    HttpMeetCompService.prototype.mcDownloadMCDetails = function (requestObj) {
        return this.makeHTTPCall(environment.mcDownloadMCDetails.method, this.getRegionSpecificURL(environment.mcDownloadMCDetails), null, requestObj);
    };
    HttpMeetCompService.prototype.mcGetDistinctFilterForGrid = function (requestObj, gridName) {
        switch (gridName) {
            case "mcCustomerDetailsSection":
            case "mcCustomerDetails":
                return this.makeHTTPCall(environment.mcGetDistinctFilterCustomerDetailsGrid.method, this.getRegionSpecificURL(environment.mcGetDistinctFilterCustomerDetailsGrid), null, requestObj);
            case "mcSearchResult":
                return this.makeHTTPCall(environment.mcGetDistinctFilterSearchGrid.method, this.getRegionSpecificURL(environment.mcGetDistinctFilterSearchGrid), null, requestObj);
            case "mcProductsDetailsSection":
                return this.makeHTTPCall(environment.mcGetDistinctFilterProductsDetailsGrid.method, this.getRegionSpecificURL(environment.mcGetDistinctFilterProductsDetailsGrid), null, requestObj);
            default:
                return of(false);
        }
    };
    HttpMeetCompService.prototype.downloadMCDetails = function (request, isPDFDownload) {
        var url = isPDFDownload ? this.getRegionSpecificURL(environment.mcDownloadMCDetails) : this.getRegionSpecificURL(environment.mcDownloadMCDetailsExcelFile);
        return this.makeHTTPCall(environment.downloadPADetails.method, url, null, request).pipe(timeout(300000), catchError(function (err) {
            return of(null);
        }), map(function (response) {
            if (response) {
                return response;
            }
        }));
    };
    HttpMeetCompService.prototype.downloadMCData = function (request) {
        // return this.http.post(environment.download.url, request).pipe(timeout(300000),
        return this.makeHTTPCall(environment.DownloadMCAsync.method, this.getRegionSpecificURL(environment.DownloadMCAsync), null, request).pipe(timeout(300000), catchError(function (err) {
            return of(null);
        }), map(function (response) {
            if (response) {
                return response;
            }
        }));
    };
    HttpMeetCompService.ngInjectableDef = i0.defineInjectable({ factory: function HttpMeetCompService_Factory() { return new HttpMeetCompService(i0.inject(i1.HttpClient), i0.inject(i2.GlobalAppService)); }, token: HttpMeetCompService, providedIn: "root" });
    return HttpMeetCompService;
}());
export { HttpMeetCompService };
