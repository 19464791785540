import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { contactsLink } from "@app/conf/appLink";
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { KCPContent } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { HttpService } from '@app/Modules/Shared/Services/http.service';

@AutoUnsubscribe()
@Component({
  selector: 'contactusRightSidebar',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class contactusRightSidebarComponent implements OnInit, OnDestroy {

  mailtolink: any;
  subscription: Subscription = new Subscription();
  contactRequest: KCPContent;
  // countries:Array<any>;
  // others: Array<any>;
  // chargebacks: any;
  // chargebacksKey: string;
  region: string;
  country: string;
  language: string;
  allData: any;
  allCountryId: any;
  salesOrg: number;

  TemplateConfig: any = {
    "Customer_Care": {
      "display": true
    },
    "Customer_Service": {
      "display": true
    }
  }
  isCountryUSAorCa: boolean = false;

  constructor(
    private translateServ: TranslateService,
    private languageServ: LanguageSelectionService,
    private _appService: AppService,
    private _globalAppService: GlobalAppService,
    private httpServ: HttpService
  ) { }

  ngOnInit() {
    // this.subscription.add(this.languageServ.currentSelected.subscribe((language) => {
    //   this.translateServ.setDefaultLang(language.regionCode);
    // }));

    this.mailtolink = contactsLink.emailLink;
    this.loadcomponentConfig();

    let selectedCountryLang = this._globalAppService.getSelectedCountryLang();
    this.region = this._globalAppService.getDefaultRegionString();
    this.country = selectedCountryLang.country || this._globalAppService.getDefaultCountryString();
    this.language = selectedCountryLang.language || this._globalAppService.getDefaultLanguageString();
    this.createContactLinks(this.region);
    this.languageServ.getlanguage().subscribe(data => {
      let selectedCountryLang = this._globalAppService.getSelectedCountryLang();
      this.country = selectedCountryLang.country;
      this.language = selectedCountryLang.language;
      this.salesOrg = selectedCountryLang.salesOrg;
      this.createContactLinks(this.region);
    });
  }

  createContactLinks(region) {
    let contactRequest: KCPContent = this.createContactRequest(region);
    this.httpServ.getKCPContent(contactRequest).subscribe(data => {
      console.log("Ranjith contact data", data);
      if (data && data.result && data.result.length) {
        this.allData = JSON.parse(data.result[0].data);
        this.allCountryId = data.result[0].countryId;
        if(this.allData && this.allData.contactUs && this.allData.contactUs.customerService && this.allData.contactUs.customerService.country){
          let country = this.allData.contactUs.customerService.country;
          if( country.toLowerCase() === 'usa' || country.toLowerCase() === 'canada' ){
            this.isCountryUSAorCa = true;
          }
        }
      }
    });
  }

  createContactRequest(region): KCPContent {
    this.contactRequest = new KCPContent();
    this.contactRequest.regionId = region;
    this.contactRequest.countryId = this.country;
    this.contactRequest.languageCode = this.language;
    // this.contactRequest.salesOrgId = +this._globalAppService.getSalesOrg();
    // this.contactRequest.salesOrgId = 7042;
    this.contactRequest.salesOrgId = this.salesOrg;
    this.contactRequest.contentTypes = this.contentTypeRequest();
    return this.contactRequest;
  }
  contentTypeRequest() {
    let contentTypeArr = [];
    let myObj = Object.assign({});
    myObj.contentType = 'Contacts';
    contentTypeArr.push(myObj);
    return contentTypeArr;
  }
  loadcomponentConfig() {
    this._globalAppService.getDefaultRegion().subscribe(async (data) => {
      if (data) {
        let { HomeContactSectionConfig } = await this._appService.dynamicImport(data);
        Object.assign(this.TemplateConfig, HomeContactSectionConfig);
      }
    })
  }

  ngOnDestroy() {
    //Destroying component
  }
}
