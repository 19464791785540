/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./favorite.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./favorite.component";
import * as i4 from "../../../../Shared/Services/http-invoice-price-new.service";
import * as i5 from "../../../../Shared/Services/app.service";
import * as i6 from "../../../../in-voice-new/Services/invoice-price.service";
var styles_FavoriteComponent = [i0.styles];
var RenderType_FavoriteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FavoriteComponent, data: {} });
export { RenderType_FavoriteComponent as RenderType_FavoriteComponent };
function View_FavoriteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "blueText"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setUnsetFavourite(_co.isFavorite) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "fa-heart"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fa-heart"; var currVal_1 = (_co.isFavorite ? "fas" : "far"); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_FavoriteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FavoriteComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(2, null, ["\n", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isGlobalUser; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.params.value; _ck(_v, 2, 0, currVal_1); }); }
export function View_FavoriteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "favorite", [], null, null, null, View_FavoriteComponent_0, RenderType_FavoriteComponent)), i1.ɵdid(1, 245760, null, 0, i3.FavoriteComponent, [i4.HttpInvoicePriceNewService, i5.AppService, i6.InvoicePriceService, i5.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FavoriteComponentNgFactory = i1.ɵccf("favorite", i3.FavoriteComponent, View_FavoriteComponent_Host_0, {}, {}, []);
export { FavoriteComponentNgFactory as FavoriteComponentNgFactory };
