/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customcheckbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./customcheckbox.component";
import * as i3 from "../../../services/cp-ag-grid-service";
var styles_CustomcheckboxComponent = [i0.styles];
var RenderType_CustomcheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomcheckboxComponent, data: {} });
export { RenderType_CustomcheckboxComponent as RenderType_CustomcheckboxComponent };
export function View_CustomcheckboxComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_CustomcheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customcheckbox", [], null, null, null, View_CustomcheckboxComponent_0, RenderType_CustomcheckboxComponent)), i1.ɵdid(1, 114688, null, 0, i2.CustomcheckboxComponent, [i3.CpAgGridService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomcheckboxComponentNgFactory = i1.ɵccf("app-customcheckbox", i2.CustomcheckboxComponent, View_CustomcheckboxComponent_Host_0, {}, {}, []);
export { CustomcheckboxComponentNgFactory as CustomcheckboxComponentNgFactory };
