/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overflow-ellipsestext.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./overflow-ellipsestext.component";
var styles_OverflowEllipsestextComponent = [i0.styles];
var RenderType_OverflowEllipsestextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverflowEllipsestextComponent, data: {} });
export { RenderType_OverflowEllipsestextComponent as RenderType_OverflowEllipsestextComponent };
export function View_OverflowEllipsestextComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.params.value, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.params.value; _ck(_v, 1, 0, currVal_1); }); }
export function View_OverflowEllipsestextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-overflow-ellipsestext", [], null, null, null, View_OverflowEllipsestextComponent_0, RenderType_OverflowEllipsestextComponent)), i1.ɵdid(1, 49152, null, 0, i2.OverflowEllipsestextComponent, [], null, null)], null, null); }
var OverflowEllipsestextComponentNgFactory = i1.ɵccf("app-overflow-ellipsestext", i2.OverflowEllipsestextComponent, View_OverflowEllipsestextComponent_Host_0, {}, {}, []);
export { OverflowEllipsestextComponentNgFactory as OverflowEllipsestextComponentNgFactory };
