import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class LanguageSelectionService {
  private lang: Subject<any> = new Subject<any>();
  constructor(private translateServ: TranslateService) { }

  private language = new BehaviorSubject({ imagename: '226-united-states.svg', regionCode: 'en' });
  currentSelected = this.language.asObservable();


  updatelanguage(updatedLang: any) {
    this.language.next(updatedLang);
  }

  getlanguage(): Observable<any> {
    return this.lang;
  }

  setlanguage(updatedLang: any) {
    this.lang.next(updatedLang);
  }

  getLanguageCode(region: string, language: string) {
    //console.log("region,  language: ",region,  language)
    if (region == "EMEA") {
      let emeaSupportedLanguages = ["en", "ru", "de", "it","nl","pl","cs","pt","es","fr"];
      if (language && language.indexOf("-")) {
        let lang = language.substring(0, language.indexOf("-"));
        let finalLang = emeaSupportedLanguages.indexOf(lang) > -1 ? lang : "en";        
        return finalLang;
      } else {
        return 'en';
      }
    }
    else {
      return language;
    }
  }
}
