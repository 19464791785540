<div class="full-width-error-line">
    <span *ngIf="isGroup">{{params.value}}</span>
    <span *ngIf="isGroup" class="error-holder">
      <i class="fas fa-exclamation-circle"></i> 
      {{errorDescription}}
    </span>


    <span class="footer-title-holder" *ngIf="params.colDef.field == 'id'">
    {{getLabel(params) | translate}}
  </span>
</div>