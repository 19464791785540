import { Component, ViewChild, ViewContainerRef } from "@angular/core";
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode } from "ag-grid-community";
import { IFilterAngularComp } from "ag-grid-angular";
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';

@Component({
  selector: 'free-text-filter',
  templateUrl: './freetext-search.component.html',
  styleUrls: ['./freetext-search.component.scss']
})

export class FreetextSearchComponent implements IFilterAngularComp {

  public params: IFilterParams;
  public valueGetter: (rowNode: RowNode) => any;
  public text: string = '';
  public colId: string;
  isNumeric: boolean;
  isPositivNegativeDecimal: boolean = false;
  maxLength: number;
  isTextProvided: boolean;
  guiParams: any;

  // textControl = new FormControl();

  filterModel: any = { type: "free-text", value: this.text };

  @ViewChild('input', { read: ViewContainerRef }) public txtSearchInput;
  orgColId: string;
  showGreaterThanEquelSign: boolean = false;

  constructor(private gridService: CpAgGridService) {
  }

  agInit(params: IFilterParams): void {
    this.params = params;
    this.orgColId = params.column.getColId();
    this.colId = params.colDef.field;
    this.setColumnSematics();
    this.params.api.setPopupParent(document.querySelector('body'));
  }

  setColumnSematics() {
    switch (this.colId) {
      case 'contractNumber':
      case 'legacyPANumber':
        this.maxLength = 10;
        this.isNumeric = true;
        break;
      case 'customerNumber':
        this.isNumeric = true;
        break;
      case 'lines':
      case 'errors':
      case 'ActiveLines':
        this.showGreaterThanEquelSign = true;
        this.isNumeric = true;
        break;
      case 'requested':
      case 'issued':
        this.showGreaterThanEquelSign = true;
        this.isPositivNegativeDecimal = true;
        this.isNumeric = false;
        break;
      default:
        this.isNumeric = false;
        break;
    }
  }

  isFilterActive(): boolean {
    return this.text !== null && this.text !== undefined && this.text !== '';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return this.valueGetter(params.node).toString().toLowerCase().indexOf(this.text.toLowerCase()) >= 0;
  }

  getModel(): any {
    this.filterModel.value = this.text;
    return this.filterModel;
  }

  setModel(model: any): void {
    if (model) {
      this.filterModel = new Object();
      this.filterModel.type = "free-text";
      this.filterModel.value = model ? model.value : "";
      this.text = model.value;
    }
  }

  ngAfterViewInit(params: IAfterGuiAttachedParams): void {
    window.setTimeout(() => {
      this.txtSearchInput.element.nativeElement.focus();
    })
  }

  // noinspection JSMethodCanBeStatic
  componentMethod(message: string): void {
    alert(`Alert from FreetextSearchComponent ${message}`);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (this.isNumeric) {
      if (charCode > 31 && charCode != 44 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    } else if (this.isPositivNegativeDecimal) {
      let value = event.target.value || "";
      let dotcontains = value.indexOf(".") != -1;
      let minusSign = value.indexOf("-") != -1 || event.target.selectionStart > 0;
      console.log("minusSign :: ", minusSign, charCode)
      if (minusSign)
        if (charCode == 45) return false;

      if (charCode == 45) return true;

      if (dotcontains)
        if (charCode == 46) return false;
      if (charCode == 46) return true;
      if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
      return true;
    }
    else
      return true;
  }

  onChange(newValue) {
    this.isTextProvided = newValue && newValue !== '';
  }

  onApplyClick(event, newValue): void {
    if (this.text !== newValue) {
      this.text = newValue.trim();
      this.params.filterChangedCallback();
    }
    this.guiParams.hidePopup();
  }

  afterGuiAttached(params) {
    this.txtSearchInput.element.nativeElement.focus();
    this.guiParams = params;
  }

  onSortRequested(isSortedRequest) {
    if (!!isSortedRequest) {
      this.guiParams.hidePopup();
    }
  }
}
