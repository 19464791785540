/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list-row.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./list-row.component";
var styles_ListRowComponent = [i0.styles];
var RenderType_ListRowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListRowComponent, data: {} });
export { RenderType_ListRowComponent as RenderType_ListRowComponent };
export function View_ListRowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "dataRow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "label", [["class", "custom-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["name", "rowObj.customerEU"], ["type", "checkbox"]], [[8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onCustomerSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "checkmark"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rowObj.customerEU; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.rowObj.checked; _ck(_v, 4, 0, currVal_1); }); }
export function View_ListRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list-row", [], null, null, null, View_ListRowComponent_0, RenderType_ListRowComponent)), i1.ɵdid(1, 114688, null, 0, i2.ListRowComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListRowComponentNgFactory = i1.ɵccf("app-list-row", i2.ListRowComponent, View_ListRowComponent_Host_0, { rowObj: "rowObj" }, { checkBoxChanged: "checkBoxChanged" }, []);
export { ListRowComponentNgFactory as ListRowComponentNgFactory };
