<ng-container>
  <span class="custom-table__row-menu">
    <i class="fas fa-ellipsis-h ellipsisbtn" [ngClass]="{'ellipseSelect' : ellipseSelect}" (click)="contextmenuToggle(contextmenu, $event)"></i>
  </span>
</ng-container>
<p-overlayPanel appendTo="body" styleClass="contextMenu" #contextmenu (onHide)="onContextMenuHide()" (onShow)="onContextMenuShow()">
  <span class="custom-table__popup__info custom-table__row-menu">
    <ul class="custom-table__row-menu__content">
      <li *ngIf="!isEuAdminModule && ((isInternalUser && !cvtView) || !isInternalUser)"
        (click)="handleDownloadExcel($event)($event)"><a href="javascript:void(0)"><i
            class="far fa-arrow-alt-circle-down"></i><span>{{ "agGrid.agGridColumns.contextMenu.downloadExcelLabel" | translate }}</span></a>
      </li>
      <li *ngIf="!isEuAdminModule && ((isInternalUser && !cvtView) || !isInternalUser)"
        (click)="handlePdfDownload($event)"><a href="javascript:void(0)"><i
            class="far fa-arrow-alt-circle-down"></i><span>{{ "agGrid.agGridColumns.contextMenu.downloadPDFLabel" | translate }}</span></a>
      </li>
      <li *ngIf="(!isInternalUser && !isStatusTypeExpiredOrFuture && !isEndUserAdminPAType && region=='NA')"
        (click)="createEndUserLink()"><a href="javascript:void(0)"><i
            class="fas fa-user-plus"></i><span>{{ "agGrid.agGridColumns.contextMenu.createEndUser" | translate }}</span></a>
      </li>
      <li *ngIf="!isEuAdminModule && !isRebateModule" (click)="onLinkClick()"><a href="javascript:void(0)"><i
            class="far fa-arrow-alt-circle-right"></i><span>{{ "agGrid.agGridColumns.contextMenu.viewPADetails" | translate }}</span></a>
      </li>
      <li *ngIf="isInternalUser && region=='EMEA' && !isRebateModule" (click)="onModifyPAClick()"><a href="javascript:void(0)"><i
        class="far fa-arrow-alt-circle-right"></i><span>{{"agGrid.agGridColumns.modifyPALabel" | translate }}</span></a>
      </li>
    </ul>
  </span>
</p-overlayPanel>