import { Component, OnInit } from '@angular/core';
import { GlobalAppService } from '../../Services/global-app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-region',
  templateUrl: './select-region.component.html',
  styleUrls: ['./select-region.component.scss']
})

export class SelectRegionComponent implements OnInit {

  selectedRegion: any;
  regions: any

  constructor(
    private globalAppService: GlobalAppService,
    private router: Router) {
     }

  ngOnInit() {
    this.globalAppService.getRegions().subscribe((regions) => {
      if (regions && regions.length) {
        this.regions = regions.map(r => {
          return { label: r, value: r }
        });
        this.selectedRegion = this.regions[0].value;
      }
    })
  }

  onContinueClick(event) {
    this.globalAppService.setDefaultRegion(this.selectedRegion);
    localStorage.setItem('default-region',this.selectedRegion);
    this.globalAppService.setAppStart(true);
    // this.router.navigate(['landing']);
  }

}

