import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cp-init',
  templateUrl: './cp-init.component.html',
  styleUrls: ['./cp-init.component.scss']
})
export class CpInitComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    
  }
  
}
