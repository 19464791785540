/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hyperlink.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./hyperlink.component";
var styles_HyperlinkComponent = [i0.styles];
var RenderType_HyperlinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HyperlinkComponent, data: {} });
export { RenderType_HyperlinkComponent as RenderType_HyperlinkComponent };
export function View_HyperlinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLinkClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Modify PA"]))], null, null); }
export function View_HyperlinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hyperlink", [], null, null, null, View_HyperlinkComponent_0, RenderType_HyperlinkComponent)), i1.ɵdid(1, 49152, null, 0, i2.HyperlinkComponent, [], null, null)], null, null); }
var HyperlinkComponentNgFactory = i1.ɵccf("app-hyperlink", i2.HyperlinkComponent, View_HyperlinkComponent_Host_0, {}, {}, []);
export { HyperlinkComponentNgFactory as HyperlinkComponentNgFactory };
