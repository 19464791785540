import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
var PaSplitDetailsComponent = /** @class */ (function () {
    function PaSplitDetailsComponent(activatedRoute, router) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.display = false;
    }
    PaSplitDetailsComponent.prototype.agInit = function (params) {
        this.params = params;
    };
    PaSplitDetailsComponent.prototype.refresh = function (params) {
        return false;
    };
    PaSplitDetailsComponent.prototype.show = function () {
        var _this = this;
        this.router.navigate([{ outlets: { dialogs: null } }]).then(function () {
            _this.router.navigate([{ outlets: { dialogs: ['splitViewModal'] } }], { relativeTo: _this.activatedRoute.parent, state: { data: _this.params.data } });
        });
    };
    PaSplitDetailsComponent.prototype.ngOnDestroy = function () {
        // Required for unsubscribing and destroying the component  
    };
    PaSplitDetailsComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [ActivatedRoute,
            Router])
    ], PaSplitDetailsComponent);
    return PaSplitDetailsComponent;
}());
export { PaSplitDetailsComponent };
