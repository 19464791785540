import { Observable, Subject } from 'rxjs';
import * as i0 from "@angular/core";
var InvoicePriceService = /** @class */ (function () {
    function InvoicePriceService() {
        this.updateFavHeaderStatus = new Subject();
        this.isRedrawRow = new Subject();
        this.showProcessingPopup = new Subject();
        this.showEmailPopup = new Subject();
        this.reloadDownloadAllPage = new Subject();
        this.updateCategoryStatus = new Subject();
        this.showErrorMessage = new Subject();
    }
    InvoicePriceService.prototype.setUpdateFavColHeader = function (updateStatus) {
        this.updateFavHeaderStatus.next(updateStatus);
    };
    InvoicePriceService.prototype.getUpdateFavColHeader = function () {
        return this.updateFavHeaderStatus.asObservable();
    };
    InvoicePriceService.prototype.setRedrawAdvancedGridRow = function (favData) {
        this.isRedrawRow.next(favData);
    };
    InvoicePriceService.prototype.getRedrawAdvancedGridRow = function () {
        return this.isRedrawRow.asObservable();
    };
    InvoicePriceService.prototype.setProcessingPopupVisible = function (categoryData) {
        this.showProcessingPopup.next(categoryData);
    };
    InvoicePriceService.prototype.getProcessingPopupVisible = function () {
        return this.showProcessingPopup.asObservable();
    };
    InvoicePriceService.prototype.setEmailPopupVisible = function (emailData) {
        this.showEmailPopup.next(emailData);
    };
    InvoicePriceService.prototype.getEmailPopupVisible = function () {
        return this.showEmailPopup.asObservable();
    };
    InvoicePriceService.prototype.setReloadDownloadAllPage = function (isReload) {
        this.reloadDownloadAllPage.next(isReload);
    };
    InvoicePriceService.prototype.getReloadDownloadAllPage = function () {
        return this.reloadDownloadAllPage.asObservable();
    };
    InvoicePriceService.prototype.setUpdateRefreshStatusRequest = function (statusData) {
        this.updateCategoryStatus.next(statusData);
    };
    InvoicePriceService.prototype.getUpdateRefreshStatusRequest = function () {
        return this.updateCategoryStatus.asObservable();
    };
    InvoicePriceService.prototype.setErrorMsg = function (showError) {
        this.showErrorMessage.next(showError);
    };
    InvoicePriceService.prototype.getErrorMsg = function () {
        return this.showErrorMessage.asObservable();
    };
    InvoicePriceService.ngInjectableDef = i0.defineInjectable({ factory: function InvoicePriceService_Factory() { return new InvoicePriceService(); }, token: InvoicePriceService, providedIn: "root" });
    return InvoicePriceService;
}());
export { InvoicePriceService };
