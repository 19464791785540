<div class="custom-table-footer" [hidden]="!totalCount">
  <div class="row">
    <div class="col-5">
      <div class="form-group results-per-page-container">
        <label class="pagination__customer__label" for="resultPerPage">{{ 'paginationFooter.grid.resultsPerPage' | translate }}</label>
        <p-dropdown name="resultPerPage" [options]="pageSizes" [autoWidth]="true" placeholder="Select..."
          id="resultsPerPageId" [(ngModel)]="selectedPageSize" [style]="{'min-width':'50px','border':'0'}"
          (onChange)="onPagePerSizeChanged($event)" dropdownIcon="select__Arrow">
        </p-dropdown>
      </div>
    </div>
    <div class="col-7">
      <ul class="custom-table-footer__pagination unlisted inline">
        <li class="custom-table-footer__pagination__current-page"><a href="javascript:void(0)">
            {{ currentPageDetails?.startIndex }}
            {{ "paginationFooter.grid.to" | translate }} {{ currentPageDetails?.lastIndex}}</a>
        </li>
        <li class="custom-table-footer__pagination__first-page"
          [class.custom-table-footer__pagination__disabled]="currentPage === 1"
          [class.disabled-page-click]="currentPage === 1" (click)="onFirstBtnClick($event)">
          <a href="javascript:void(0)"><i class="fas fa-chevron-left"></i><i class="fas fa-chevron-left"></i></a></li>
        <li class="custom-table-footer__pagination__prev-page"
          [class.custom-table-footer__pagination__disabled]="currentPage === 1"
          [class.disabled-page-click]="currentPage === 1" (click)="onPreviousBtnClick($event)">
          <a href="javascript:void(0)"><i class="fas fa-chevron-left"></i></a></li>
        <li class="custom-table-footer__pagination__current-page"><a
            href="javascript:void(0)">{{ 'paginationFooter.grid.page' | translate }} {{ currentPage }}
            {{ 'paginationFooter.grid.of' | translate }} {{lastPage}}</a>
        </li>
        <li class="custom-table-footer__pagination__next-page"
          [class.custom-table-footer__pagination__disabled]="currentPage === lastPage"
          [class.disabled-page-click]="currentPage === lastPage" (click)="onNextBtnClick($event)">
          <a href="javascript:void(0)"><i class="fas fa-chevron-right"></i></a></li>
        <li class="custom-table-footer__pagination__last-page"
          [class.custom-table-footer__pagination__disabled]="currentPage === lastPage"
          [class.disabled-page-click]="currentPage === lastPage" (click)="onLastBtnClick($event)">
          <a href="javascript:void(0)"><i class="fas fa-chevron-right"></i><i class="fas fa-chevron-right"></i></a></li>
      </ul>
    </div>
  </div>
</div>