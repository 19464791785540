import { Injectable } from '@angular/core';
export enum FullStoryCustomDimensions {
  UserType = 'userType_str',
  PangeLanguage = 'pageLanguage_str',
  DisplayName = 'displayName'
}
export type FullStoryCustomDimensionsSet = { 
  [key in FullStoryCustomDimensions]: string | undefined 
};
export type PartialCustomDimensionsSet = { [key: string]: string };
@Injectable({
  providedIn: 'root'
})
export class FullStoryService {
  private _userId: string;
  private _currentSet: FullStoryCustomDimensionsSet;
  private _constantDimensions: string[];
  private _isIdentified: boolean;
  constructor() {
    this._generateEmptyDimensionsSet();
    this._constantDimensions = [
      FullStoryCustomDimensions.UserType,
      FullStoryCustomDimensions.PangeLanguage,
      FullStoryCustomDimensions.DisplayName
    ];
  }
  set identify(userId: string){
    this._userId = userId;
  }
  set dimensions(someDimensions: PartialCustomDimensionsSet) {
    Object.keys(FullStoryCustomDimensions).map(key => FullStoryCustomDimensions[key])
    .forEach(key => {
      this._currentSet[key] = someDimensions[key] || this._constantDimensions[key];
    });
  }
  trigger() {
    if(!this._isIdentified){
      (<any>window).FS.identify(this._userId);
    }
    (<any>window).FS.setUserVars(this._currentSet);
  }
  private _generateEmptyDimensionsSet() {
    this._currentSet = {
      [FullStoryCustomDimensions.UserType]: undefined,
      [FullStoryCustomDimensions.PangeLanguage]: undefined,
      [FullStoryCustomDimensions.DisplayName]: undefined,
    };
  }
}