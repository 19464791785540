import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GlobalAppService } from './global-app.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpInvoicePriceNewService {

  constructor(private http: HttpClient,
    private _globalAppService: GlobalAppService) { }

  //fetch region specific url from environment.ts
  getRegionSpecificURL(item: any) {
    let regionURL = "";
    let region = localStorage.getItem('default-region') || this._globalAppService.getDefaultRegionString() || this._globalAppService.selectedRegion;
    if (region) {
      regionURL = "url_" + region.toUpperCase();
    }
    else {
      regionURL = "url_NA";
    }
    return item[regionURL];
  }

  makeHTTPCall(method: string, endPoint: string, additionalHeaders: any = null, requestParams: any = null): Observable<any> {
    let requestBody = {};
    if (additionalHeaders) {
      requestBody = Object.assign({}, requestBody, { headers: additionalHeaders });
    }
    if (requestParams) {
      requestBody = Object.assign({}, requestBody, { body: requestParams });
    }

    return this.http.request(method, endPoint, requestBody);
  }

  GetInvoicePriceStatusByCustomerID(request): Observable<any> {
    return this.makeHTTPCall(environment.GetInvoicePriceStatusByCustomerID_new.method, this.getRegionSpecificURL(environment.GetInvoicePriceStatusByCustomerID_new), null, request);
  }

  priceRefreshRequest(request): Observable<any> {
    return this.makeHTTPCall(environment.PriceRefreshRequest_new.method, this.getRegionSpecificURL(environment.PriceRefreshRequest_new), null, request);
  }

  exportInvoiceGrid(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportInvoiceGrid_new.method, this.getRegionSpecificURL(environment.exportInvoiceGrid_new), null, requestObj);
  }

  saveFavoriteInvoice(request): Observable<any> {
		return this.makeHTTPCall(environment.saveFavoriteInvoice_new.method, this.getRegionSpecificURL(environment.saveFavoriteInvoice_new), null, request);
  }
  
  downloadInvoicePrice(request): Observable<any> {
    return this.makeHTTPCall(environment.DownloadInvoicePrice_new.method, this.getRegionSpecificURL(environment.DownloadInvoicePrice_new), null, request);
  } 

  notifyInvoicePriceRequestByEmail(request): Observable<any> {
    return this.makeHTTPCall(environment.NotifyInvoicePriceRequestByEmail_new.method, this.getRegionSpecificURL(environment.NotifyInvoicePriceRequestByEmail_new), null, request);
  }
  getFavoriteCusotmerInvoice_new(request): Observable<any> {
    return this.makeHTTPCall(environment.GetFavoriteCusotmerInvoice_new.method, this.getRegionSpecificURL(environment.GetFavoriteCusotmerInvoice_new), null, request);
  }

  invoiceGetDistinctFilterForGrid(requestObj, gridName): Observable<any> {
    switch (gridName) {
      case 'invoiceSearchResult':
      case 'invoiceFavourite':
        return this.makeHTTPCall(environment.InvoiceSearchGridFilter_new.method, this.getRegionSpecificURL(environment.InvoiceSearchGridFilter_new), null, requestObj);
      default: 
        return of(true);
    }
  }

  invoicePriceSaveSearch(request){
    return this.makeHTTPCall(environment.InvoicePriceSaveSearch_new.method, this.getRegionSpecificURL(environment.InvoicePriceSaveSearch_new), null, request);
  }
  
  
  getRecentSearch():Observable<any>{
    return this.makeHTTPCall(environment.invoicePriceGetRecentSearch_new.method,this.getRegionSpecificURL(environment.invoicePriceGetRecentSearch_new), null, null);
  }

  getFavoriteSearch():Observable<any>{
    return this.makeHTTPCall(environment.invoicePriceGetFavoriteSearch_new.method,this.getRegionSpecificURL(environment.invoicePriceGetFavoriteSearch_new), null, null);
  }

  saveFavoriteSearch(searchId): Observable<any> {
    return this.makeHTTPCall(environment.saveFavoriteSearch_new.method,this.getRegionSpecificURL(environment.saveFavoriteSearch_new),null,{
      id:searchId,
      "name": "",
      "moduleType": "InvoicePrice"
    });
  }

  removeFavoriteSearch(searchId): Observable<any>{
    return this.makeHTTPCall(environment.removeFavoriteSearch_new.method,this.getRegionSpecificURL(environment.removeFavoriteSearch_new),null,{
      id:searchId,
      "name": "",
      "moduleType": "InvoicePrice"
    });
  }
  savedSearchName(tileId, name): Observable<any> {
    return this.makeHTTPCall(environment.saveSearchName_new.method, this.getRegionSpecificURL(environment.saveSearchName_new), null, {
      "id": tileId,
      "name": name,
      "moduleType": "InvoicePrice"
    })
  }

  IsPriceRequestAllowedforUser_new(request): Observable<any>{
    return this.makeHTTPCall(environment.IsPriceRequestAllowedforUser_new.method,this.getRegionSpecificURL(environment.IsPriceRequestAllowedforUser_new), null, request);
    // return Observable.of(
    //   {
    //     isAllowed: true,
    //     maxAllowedRequest: 5
    //   }
    // )
  }

  recentRequestGetDistinctFilterForGrid(requestObj, gridName): Observable<any> {
    switch (gridName) {
      case 'recentRequests':
        return this.makeHTTPCall(environment.RecentRefreshRequestGridFilter_new.method, this.getRegionSpecificURL(environment.RecentRefreshRequestGridFilter_new), null, requestObj);
      default: 
        return of(true);
    }
  }
  RecentRefreshRequestExport(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.RecentRefreshRequestExport_new.method, this.getRegionSpecificURL(environment.RecentRefreshRequestExport_new), null, requestObj);
  }

  saveFavoriteInvoiceCategory(request): Observable<any> {
    return this.makeHTTPCall(environment.SaveFavoriteInvoiceCategory_new.method, this.getRegionSpecificURL(environment.SaveFavoriteInvoiceCategory_new), null, request);
  }

  getFavoriteInvoiceCategory(request): Observable<any> {
    return this.makeHTTPCall(environment.GetFavoriteCategoryInvoice_new.method, this.getRegionSpecificURL(environment.GetFavoriteCategoryInvoice_new), null, request);
  }

  getCategoriesByCustomerNumber(request): Observable<any> {
    return this.makeHTTPCall(environment.GetCategoriesByCustomerNumber_new.method, this.getRegionSpecificURL(environment.GetCategoriesByCustomerNumber_new), null, request);
  }

  UserCustomerCountriesByCustomerType(reqHeader = null): Observable<any> {
    return this.makeHTTPCall(environment.UserCustomerCountriesByCustomerType.method, this.getRegionSpecificURL(environment.UserCustomerCountriesByCustomerType), reqHeader, null);
  }
}
