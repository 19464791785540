import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { footerLinks } from "@app/conf/appLink";
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { KCPContent } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { AppService } from '../../Services/app.service';
import { TranslateService } from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  imgPath: string = "/assets/images/international-flags/";
  defaultLangIcon = "";
  imgSource: string;
  applink: any;
  selectedoption: any;
  width: any = '90';
  @ViewChild('selectTagWidth') elementView;

  footerRequest: KCPContent;
  region: string;
  country: string;
  language: string;

  legalStatement: string;
  privacyPolicy: string;
  legalDisclaimers: string;
  salesOrg: number;
  countryLangSelctionHidden: boolean = false;
  preferredCountry: any;
  subscription: Subscription = new Subscription();
  isInternalUser: boolean;

  constructor(
    private languageServ: LanguageSelectionService,
    private httpServ: HttpService,
    private translateService: TranslateService,
    private _globalAppService: GlobalAppService,
    private appService: AppService) {
  }

  ngOnInit() {
    this.getInternalUserFlag();
    this.applink = footerLinks;
    this.httpServ.getUserDetails().subscribe(userDetails => {
      this._globalAppService.setUserDetails(userDetails.result);
      let userDetailsResult = userDetails.result;
      this.preferredCountry = userDetailsResult.preferredCountry;
    })

    this.languageServ.getlanguage().subscribe(data => {
      let selectedCountryLang = this._globalAppService.getSelectedCountryLang();
      this.country = selectedCountryLang.country;
      this.language = selectedCountryLang.language;
      this.salesOrg = selectedCountryLang.salesOrg;
      this.createFooterLinks(this.region);
    });

    this._globalAppService.getDefaultRegion().subscribe((region) => {
      if (region) {
        this.region = region;
        this._globalAppService.getDefaultCountry().subscribe((country) => {
          if (country) {
            this.country = country;
            this._globalAppService.getDefaultLanguage().subscribe((lang) => {
              if (lang) {
                let selectedCountryLang = this._globalAppService.getSelectedCountryLang();
                if (selectedCountryLang && selectedCountryLang.language) {
                  this.language = selectedCountryLang.language;
                } else {
                  this.language = lang;
                }
                /**Added for PADetails not showing the footer */
                this.translateService.setDefaultLang(this.region + '/' + this.languageServ.getLanguageCode(this.region,this.language).toLowerCase());
                this.createFooterLinks(this.region);
              }
            });
          }
        });
      }
    });

    this.subscription.add(this._globalAppService.getUserDetailsLoaded().subscribe((isUpdated) => {
      if (isUpdated) {
        let selectedLocation = this._globalAppService.getSelectedCountryLang();
        this.language = selectedLocation && selectedLocation.language ? selectedLocation.language : this._globalAppService.getDefaultLanguageString();
        this.country = selectedLocation && selectedLocation.country ? selectedLocation.country : this._globalAppService.getDefaultCountryString();
        // console.log("Footer 3", this.language);
        this.createFooterLinks(this.region);
      }
    }));

    this._globalAppService.getIsCountryLangSelctionHidden().subscribe((value) => {
      this.countryLangSelctionHidden = value;
    })

    /** When the Region or Country or Language gets change from Login/Registration page change the footer also*/
    this.subscription.add(this._globalAppService.getLocationLanguage().subscribe((selectedRegionCountrLang) => {
      if (selectedRegionCountrLang) {
        this.country = selectedRegionCountrLang.country;
        this.language = selectedRegionCountrLang.language
        this.createFooterLinks(selectedRegionCountrLang.region);
      }
    }));
  }

  getInternalUserFlag() {
    if (this.appService.AvailableOKtaProfile)
      this.isInternalUser = this.appService.isInternalUser;
    else
      this.appService.getOktaProfile().subscribe(data => {
        if (data) {
          this.isInternalUser = this.appService.isInternalUser;
        }
      })
  }

  createFooterRequest(region): KCPContent {
    this.footerRequest = new KCPContent();
    this.footerRequest.regionId = region;
    this.footerRequest.countryId = this.country;
    this.footerRequest.languageCode = this.language;
    // this.footerRequest.salesOrgId = +this._globalAppService.getSalesOrg();
    // this.footerRequest.salesOrgId = this.salesOrg;
    this.footerRequest.contentTypes = this.contentTypeRequest();
    return this.footerRequest;
  }

  createFooterLinks(region) {
    let footerRequest: KCPContent = this.createFooterRequest(region);
    this.httpServ.getKCPContent(footerRequest).subscribe(data => {
      if (data && data.result && data.result.length > 0) {
        let dataResult = JSON.parse(data.result[0].data);
        this.privacyPolicy = String(dataResult.privacypolicy);
        this.legalStatement = String(dataResult.legalstatement);
        this.legalDisclaimers = String(dataResult.legalDisclaimers);
      }
    });
  }
  contentTypeRequest() {
    let contentTypeArr = [];
    let myObj = Object.assign({});
    myObj.contentType = 'GlobalFooter';
    contentTypeArr.push(myObj);
    return contentTypeArr;
  }

  updateImgSRC(value: any): void {
    this.imgSource = this.imgPath + value.imagename;
  }

  onChargebackLinkClick(event) {
    this.appService.setAcceptancePolicyModalVisibility({ forceShowModal: true, action: "footerLinkClick" });
  }

  ngOnDestroy() {

  }
}
