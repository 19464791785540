import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { MyProfileService } from '@app/Modules/my-profile/Components/services/my-profile-service';
var MyprofilePriceagreementComponent = /** @class */ (function () {
    function MyprofilePriceagreementComponent(agGridService, myProfileService) {
        this.agGridService = agGridService;
        this.myProfileService = myProfileService;
        this.subscription = new Subscription();
        this.checkStatusPA = false;
        this.checkStatusMC = false;
        this.checkStatusCB = false;
        this.checkStatusIN = false;
        this.checkStatusOD = false;
        this.totalNumberOfRecord = 0;
        this.numberOfPASelected = 0;
    }
    MyprofilePriceagreementComponent.prototype.agInit = function (params) {
        var _this = this;
        this.currentNode = params;
        this.checkboxNamePA = "PA-" + params.data.customerNumber; //Math.random().toString(36).substring(2, 15);
        this.checkboxNameMC = "MC-" + params.data.customerNumber; //Math.random().toString(36).substring(2, 15);
        this.checkboxNameCB = "CB-" + params.data.customerNumber; //Math.random().toString(36).substring(2, 15);
        this.checkboxNameIN = "IN-" + params.data.customerNumber; //Math.random().toString(36).substring(2, 15);
        this.checkboxNameOD = "OD-" + params.data.customerNumber; //Math.random().toString(36).substring(2, 15);
        if (this.currentNode && this.currentNode.api) {
            this.currentNode.api.forEachNode(function (node, index) {
                if (params && params.data && params.data.Preferences) {
                    params.data.Preferences.forEach(function (prefernce) {
                        if (prefernce.isSelected) {
                            switch (prefernce.moduleId) {
                                case 1: // Price Aggrement
                                    _this.checkStatusPA = true;
                                    break;
                                case 2: // Meet Comp
                                    _this.checkStatusMC = true;
                                    break;
                                case 3: // ChargeBack
                                    _this.checkStatusCB = true;
                                    break;
                                case 4: // Invoices
                                    _this.checkStatusIN = true;
                                    break;
                                case 5: // Orders
                                    _this.checkStatusOD = true;
                                    break;
                            }
                        }
                    });
                }
            });
        }
    };
    MyprofilePriceagreementComponent.prototype.ngOnInit = function () {
    };
    MyprofilePriceagreementComponent.prototype.refresh = function (params) {
        return false;
    };
    MyprofilePriceagreementComponent.prototype.checkboxClick = function (event) {
        var _this = this;
        if (event.target.checked) {
            var prefCheckboxName = "ATP-" + this.currentNode.data.customerNumber;
            this.setCheckboxStatus(prefCheckboxName, event.target.checked);
            this.currentNode.data.isDefault = event.target.checked;
            var isAllPrefChecked = this.preferenceHeaderCheckboxState();
            this.setCheckboxStatus("addToPrefCheckBoxHeader", isAllPrefChecked);
        }
        this.currentNode.data.Preferences.forEach(function (Preference) {
            if (Preference.moduleId == _this.currentNode.colDef.appFeatureId) {
                Preference.isSelected = event.target.checked;
            }
        });
        switch (this.currentNode.colDef.appFeatureId) {
            case 1: // Price Aggrement
                this.checkStatusPA = event.target.checked;
                var isAllPAchecked = this.autoHeaderSelection(1);
                this.setCheckboxStatus("PACheckBoxHeader", isAllPAchecked);
                break;
            case 2: // Meet Comp
                this.checkStatusMC = event.target.checked;
                var isAllMCchecked = this.autoHeaderSelection(2);
                this.setCheckboxStatus("MCCheckBoxHeader", isAllMCchecked);
                break;
            case 3: // ChargeBack
                this.checkStatusCB = event.target.checked;
                var isAllCBchecked = this.autoHeaderSelection(3);
                this.setCheckboxStatus("CBCheckBoxHeader", isAllCBchecked);
                break;
            case 4: // Invoices
                this.checkStatusIN = event.target.checked;
                var isAllINchecked = this.autoHeaderSelection(4);
                this.setCheckboxStatus("INCheckBoxHeader", isAllINchecked);
                break;
            case 5: // Orders
                this.checkStatusOD = event.target.checked;
                var isAllODchecked = this.autoHeaderSelection(5);
                this.setCheckboxStatus("ODCheckBoxHeader", isAllODchecked);
                break;
        }
    };
    MyprofilePriceagreementComponent.prototype.autoHeaderSelection = function (moduleId) {
        var isAllchecked = true;
        var recordCount = 0;
        this.agGridService.gridApi.forEachNode(function (node) {
            if (node.data && node.data.customerNumber) {
                recordCount++;
                var selected = node.data.Preferences.find(function (x) {
                    return x.moduleId == moduleId;
                }).isSelected;
                if (!selected) {
                    isAllchecked = false;
                }
            }
        });
        if (recordCount)
            return isAllchecked;
        else
            return false;
    };
    MyprofilePriceagreementComponent.prototype.setCheckboxStatus = function (checkboxName, status) {
        var el = document.getElementById(checkboxName);
        if (el) {
            el.checked = status;
        }
    };
    MyprofilePriceagreementComponent.prototype.preferenceHeaderCheckboxState = function () {
        var isAllchecked = true;
        var recordCount = 0;
        this.agGridService.gridApi.forEachNode(function (node) {
            if (node.data) {
                recordCount++;
                var selected = node.data.isDefault;
                if (!selected) {
                    isAllchecked = false;
                }
            }
        });
        if (recordCount)
            return isAllchecked;
        else
            return false;
    };
    MyprofilePriceagreementComponent.prototype.ngOnDestroy = function () {
        // this.currentNode = null;
        // Required for unsubscribing and destroying the component  
    };
    MyprofilePriceagreementComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [CpAgGridService,
            MyProfileService])
    ], MyprofilePriceagreementComponent);
    return MyprofilePriceagreementComponent;
}());
export { MyprofilePriceagreementComponent };
