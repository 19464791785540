/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./commentformatter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./commentformatter.component";
import * as i5 from "../../../services/cp-ag-grid-service";
var styles_CommentformatterComponent = [i0.styles];
var RenderType_CommentformatterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommentformatterComponent, data: {} });
export { RenderType_CommentformatterComponent as RenderType_CommentformatterComponent };
function View_CommentformatterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], [[8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.comment)), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.comment)); _ck(_v, 2, 0, currVal_1); }); }
function View_CommentformatterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.comment; _ck(_v, 0, 0, currVal_0); }); }
export function View_CommentformatterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommentformatterComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["commentBlock", 2]], null, 0, null, View_CommentformatterComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMCModule; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CommentformatterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-commentformatter", [], null, null, null, View_CommentformatterComponent_0, RenderType_CommentformatterComponent)), i1.ɵdid(1, 49152, null, 0, i4.CommentformatterComponent, [i5.CpAgGridService], null, null)], null, null); }
var CommentformatterComponentNgFactory = i1.ɵccf("app-commentformatter", i4.CommentformatterComponent, View_CommentformatterComponent_Host_0, {}, {}, []);
export { CommentformatterComponentNgFactory as CommentformatterComponentNgFactory };
