import { Component, OnDestroy, ViewChild, ViewRef, ChangeDetectorRef } from '@angular/core';

import {
  IDoesFilterPassParams,
  IFilterParams,
  RowNode,
} from 'ag-grid-community';

import { IFilterAngularComp } from 'ag-grid-angular';

import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { TranslateService } from '@ngx-translate/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { Calendar } from 'primeng/calendar';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';

@AutoUnsubscribe()
@Component({
  selector: 'date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss']
})
export class DateSelectorComponent implements IFilterAngularComp, OnDestroy {

  public params: IFilterParams;
  public valueGetter: (rowNode: RowNode) => any;
  maxDateValue: Date = new Date();
  calenderData: any;
  txtDate: Date;
  filterModel: any = { type: "date-selector", value: this.txtDate };
  public colId: string;
  public headerName: string;
  isDateSelected: boolean = false;
  guiParams: any;
  subscription: Subscription = new Subscription();
  orgColId: string;
  dateFormat: any;
  primengDateFormat: any;
  region: any;
  @ViewChild('calendarElement') calendarElement: Calendar;

  constructor(
    private translateServ: TranslateService,
    private languageServ: LanguageSelectionService,
    private appServ: AppService,
    private cd: ChangeDetectorRef,
    private globalAppService: GlobalAppService,
    private gridService: CpAgGridService) {
  }

  agInit(params: IFilterParams): void {
    this.region = this.globalAppService.getDefaultRegionString();
    this.params = params;
    this.orgColId = params.column.getColId();
    this.colId = params.colDef.field;
    this.headerName = params.colDef.headerName;
    this.valueGetter = params.valueGetter;
    this.colId = this.colId.indexOf('_') !== -1 ? this.colId.substring(0, this.colId.indexOf('_')) : this.colId;
    if (this.colId !== 'endDate' && this.colId !== 'customerEndDate' && this.colId !== 'availableUntil' && this.colId !== 'passwordExpiryDate' && this.colId !== 'startDate') {
      if(this.region == 'NA'){
        //below 'validFrom' and 'validTo' is not unique colId, so comparing using headerName
        if(this.colId !== 'availableDate' && this.headerName !== 'agGrid.agGridColumns.MCValidFrom' && this.headerName !== 'agGrid.agGridColumns.MCValidTo'){
          this.maxDateValue.setDate(this.maxDateValue.getDate());
        }
        else{
          this.maxDateValue = null;
        } 
      }
      else{
        this.maxDateValue.setDate(this.maxDateValue.getDate());
      }
    }
    else
      this.maxDateValue = null;

    this.subscription.add(this.languageServ.currentSelected.subscribe((data) => {
      this.subscription.add(this.translateServ.get(['calender'])
        .subscribe(translations => {
          this.calenderData = translations['calender'];
          this.dateFormat = this.calenderData.format;
          this.primengDateFormat = this.calenderData.dateFormat.toLowerCase();
        }));
    }));

    this.params.api.setPopupParent(document.querySelector('body'));
  }

  getModel(): any {
    this.filterModel.value = this.txtDate;
    return this.filterModel;
  }

  setModel(model: any): void {
    if (model && model.value) {
      this.txtDate = this.filterModel.value = model.value;
    }
    else {
      this.txtDate = null;
      this.filterModel.value = "";
    }
  }

  isFilterActive(): boolean {
    return this.txtDate && this.txtDate instanceof Date;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return true;
  }

  // noinspection JSMethodCanBeStatic
  componentMethod(message: string): void {
    alert(`Alert from DateSelectorComponent ${message}`);
  }

  onDateSelected(event): void {
    this.isDateSelected = true;
  }

  onApplyClick(event, cpContainer) {
    if (this.isDateSelected) {
      this.params.filterChangedCallback();
      this.guiParams.hidePopup();
      this.isDateSelected = false;
    }
    cpContainer.classList.remove('date_range--popup')
  }

  afterGuiAttached(params) {
    this.subscription.add(this.languageServ.currentSelected.subscribe((data) => {
      this.subscription.add(this.translateServ.get(['calender'])
        .subscribe(translations => {
          this.calenderData = translations['calender'];
          this.dateFormat = this.calenderData.format;
          this.primengDateFormat = this.calenderData.dateFormat.toLowerCase();
        }));
    }));
    if (this.calendarElement && this.calendarElement.inputfieldViewChild)
      this.calendarElement.inputfieldViewChild.nativeElement.placeholder = this.dateFormat;
    this.guiParams = params;

    this.subscription.add(this.languageServ.currentSelected.subscribe((data) => {
      this.subscription.add(this.translateServ.get(['calender'])
        .subscribe(translations => {
          this.calenderData = translations['calender'];
          this.dateFormat = this.calenderData.format;
          this.primengDateFormat = this.calenderData.dateFormat.toLowerCase();
        }));
    }));
  }

  onSortRequested(isSortedRequest) {
    if (!!isSortedRequest) {
      this.guiParams.hidePopup();
    }
  }

  onCalFocus(event) {
    event.classList.add('date_range--popup');
    /** To render UI quickly in IE */
    if(this.appServ.isIEBrowser()){
      if (this.cd && !(this.cd as ViewRef).destroyed) {
        this.cd.detectChanges();
      }
    }
  }

  onShow(){
    console.log("@@@@ in On Show:  ");
  }

  onKeyPress(event, container){
    if(event.code == 'Enter'){
      this.onApplyClick(event, container);
    }
  }
  
  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }
}
