import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { HttpChargeBackService } from '@app/Modules/Shared/Services/http-charge-back.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { LanguageSelectionService } from './language-selection.service';
import * as i0 from "@angular/core";
import * as i1 from "./app.service";
import * as i2 from "./http-charge-back.service";
import * as i3 from "./global-app.service";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/router";
import * as i6 from "./language-selection.service";
var CbAcceptencePolicyService = /** @class */ (function () {
    function CbAcceptencePolicyService(appServ, httpServ, globalAppService, sanitizer, router, languageServ) {
        var _this = this;
        this.appServ = appServ;
        this.httpServ = httpServ;
        this.globalAppService = globalAppService;
        this.sanitizer = sanitizer;
        this.router = router;
        this.languageServ = languageServ;
        this.subscription = new Subscription();
        this.policyContentId = 49;
        this.fromFileUpload = false;
        this.eFileName = "";
        this.versionCreationDate = "";
        this.policySubject = new BehaviorSubject({ isAccepted: true, showAcceptancePolicy: false });
        this.isInternalUser = this.appServ.isInternalUser;
        this.languageServ.getlanguage().subscribe(function (data) {
            _this.language = data;
        });
    }
    CbAcceptencePolicyService.prototype.makeAcceptancePolicyCall = function (fromFileUpload, fromPricingTerms, region, country, language, salesOrgId) {
        var _this = this;
        if (fromFileUpload === void 0) { fromFileUpload = false; }
        if (fromPricingTerms === void 0) { fromPricingTerms = false; }
        if (region === void 0) { region = this.region; }
        if (country === void 0) { country = this.country; }
        if (language === void 0) { language = this.language; }
        if (salesOrgId === void 0) { salesOrgId = this.salesOrgId; }
        var countryCode = this.globalAppService.getDefaultCountryString();
        var disablePolicyCall = countryCode == 'RU' || countryCode == 'BY' || countryCode == 'KZ' ? true : false;
        if (!disablePolicyCall) {
            this.fromFileUpload = fromFileUpload;
            this.appServ.setLoadingStatus(true);
            this.policyContentId = this.fromFileUpload ? 50 : 49;
            if ((!this.appServ.isInternalUser && !fromFileUpload) || fromPricingTerms) {
                var request = {
                    contentId: this.policyContentId
                };
                this.subscription.add(this.httpServ
                    .getAccptancePolicyStatus(request)
                    .subscribe(function (data) {
                    if (data) {
                        _this.acceptancePolicyResponse = data.result;
                        _this.getLocationAndlanguage();
                        _this.getAccetptancePolicyContent(_this.acceptancePolicyResponse, region, country, language, salesOrgId);
                    }
                }, function (error) {
                    _this.appServ.setLoadingStatus(false);
                }, function () {
                    _this.appServ.setLoadingStatus(false);
                }));
            }
            else if (fromFileUpload) {
                this.getLocationAndlanguage();
                this.getAccetptancePolicyContent({ isAccepted: false }, this.region, this.country, this.language, this.salesOrgId);
            }
        }
    };
    CbAcceptencePolicyService.prototype.getLocationAndlanguage = function () {
        this.region = this.globalAppService.getDefaultRegionString();
        var selectedLocation = this.globalAppService.getSelectedCountryLang();
        this.language = selectedLocation && selectedLocation.language ? selectedLocation.language : this.globalAppService.getDefaultLanguageString();
        this.country = selectedLocation && selectedLocation.country ? selectedLocation.country : this.globalAppService.getDefaultCountryString();
        this.salesOrgId = +this.globalAppService.getSalesOrg();
    };
    CbAcceptencePolicyService.prototype.getAccetptancePolicyContent = function (statusResponse, region, country, language, salesOrgId) {
        var _this = this;
        if (statusResponse && statusResponse.isAccepted) {
            this.isAccepted = statusResponse.isAccepted;
            this.showAcceptancePolicy = !statusResponse.isAccepted;
            this.policySubject.next({ isAccepted: this.isAccepted, showAcceptancePolicy: this.showAcceptancePolicy, fromFileUpload: this.fromFileUpload });
            return;
        }
        this.appServ.setLoadingStatus(true);
        var KCPContentRequest = this.getKCPContentRequest(region, country, language, salesOrgId);
        this.httpServ.getKCPContent(KCPContentRequest, this.fromFileUpload).subscribe(function (data) {
            if (data.result.length > 0) {
                _this.policyContent = _this.sanitizer.bypassSecurityTrustHtml(JSON.parse(data.result[0].data).Content);
                _this.showAcceptancePolicy = !statusResponse.isAccepted;
                _this.isAccepted = statusResponse.isAccepted;
                _this.policySubject.next({ isAccepted: _this.isAccepted, showAcceptancePolicy: _this.showAcceptancePolicy, policyContent: _this.policyContent, fromFileUpload: _this.fromFileUpload });
                _this.versionCreationDate = data.result[0].versionCreationDate;
            }
            _this.appServ.setLoadingStatus(false);
        }, function (error) {
            _this.appServ.setLoadingStatus(false);
        }, function () {
            _this.appServ.setLoadingStatus(false);
        });
    };
    CbAcceptencePolicyService.prototype.getKCPContentRequest = function (region, country, language, salesOrgId) {
        return {
            regionId: region,
            countryId: country,
            languageCode: language,
            //salesOrgId: salesOrgId,
            contentTypes: [
                {
                    contentType: "Chargeback Policy Consent" //"AcceptancePolicy"//"Chargeback Policy Consent"
                }
            ],
            contentSubTypes: [
                {
                    contentSubType: "Popup"
                }
            ]
        };
    };
    CbAcceptencePolicyService.prototype.setUploadEfileName = function (eFileName) {
        this.eFileName = eFileName;
    };
    CbAcceptencePolicyService.prototype.onPolicyAccepted = function (isAggrementAccepted) {
        var _this = this;
        var req = {
            contentId: this.policyContentId,
            isAccepted: isAggrementAccepted
        };
        if (this.fromFileUpload && this.region == "EMEA") {
            req["eFileName"] = this.eFileName;
            req["versionCreationDate"] = this.versionCreationDate;
        }
        this.httpServ
            .setAccptancePolicyStatus(req, this.fromFileUpload)
            .subscribe(function (response) {
            if (response.result || (response && _this.fromFileUpload))
                _this.isAccepted = isAggrementAccepted;
            _this.showAcceptancePolicy = false;
            _this.policySubject.next({ isAccepted: _this.isAccepted, showAcceptancePolicy: _this.showAcceptancePolicy, fromFileUpload: _this.fromFileUpload });
        }, function (err) {
            console.log("Error Occured");
        });
    };
    CbAcceptencePolicyService.prototype.getFromFileUpload = function () {
        return this.fromFileUpload;
    };
    CbAcceptencePolicyService.prototype.getKCPContentByLanguage = function (lang) {
        var _this = this;
        this.appServ.setLoadingStatus(true);
        var KCPContentRequest = this.getKCPContentRequest(this.region, this.country, lang, this.salesOrgId);
        this.httpServ.getKCPContent(KCPContentRequest, this.fromFileUpload).subscribe(function (data) {
            if (data.result.length > 0) {
                _this.policyContent = _this.sanitizer.bypassSecurityTrustHtml(JSON.parse(data.result[0].data).Content);
                _this.policySubject.next({ isAccepted: _this.isAccepted, showAcceptancePolicy: _this.showAcceptancePolicy, policyContent: _this.policyContent, fromFileUpload: _this.fromFileUpload });
            }
            _this.appServ.setLoadingStatus(false);
        }, function (error) {
            _this.appServ.setLoadingStatus(false);
        }, function () {
            _this.appServ.setLoadingStatus(false);
        });
    };
    CbAcceptencePolicyService.prototype.getKCPContentEnglish = function () {
        return this.policyContentEnglish;
    };
    CbAcceptencePolicyService.ngInjectableDef = i0.defineInjectable({ factory: function CbAcceptencePolicyService_Factory() { return new CbAcceptencePolicyService(i0.inject(i1.AppService), i0.inject(i2.HttpChargeBackService), i0.inject(i3.GlobalAppService), i0.inject(i4.DomSanitizer), i0.inject(i5.Router), i0.inject(i6.LanguageSelectionService)); }, token: CbAcceptencePolicyService, providedIn: "root" });
    return CbAcceptencePolicyService;
}());
export { CbAcceptencePolicyService };
