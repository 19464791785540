import { BehaviorSubject, Subject } from 'rxjs';
import { siteAdminRoles } from '@app/conf/dataConfigurations';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../Modules/Shared/Services/app.service";
import * as i2 from "../../../../Modules/Shared/Services/global-app.service";
var EfileAccessService = /** @class */ (function () {
    function EfileAccessService(appServe, _globalAppService) {
        this.appServe = appServe;
        this._globalAppService = _globalAppService;
        this.userSearchSubject = new BehaviorSubject(null);
        this.selectedUserSubject = new BehaviorSubject(null);
        this.saveSuccessIndicator = new Subject();
        this.proceedIndicator = new Subject();
        this.refreshSections = new Subject();
        this.gridLoaded = new Subject();
        this.mySearchesNavigation = new BehaviorSubject(false);
        this.isAdminRole = false;
        this.isAdminRoleReadOnly = false;
        this.siteAdminRoles = siteAdminRoles;
    }
    EfileAccessService.prototype.updateUserSearch = function (value) {
        this.userSearchSubject.next(value);
        this.userSearchRequest = value;
    };
    EfileAccessService.prototype.getUserSearch = function () {
        return this.userSearchSubject.asObservable();
    };
    EfileAccessService.prototype.getSearchRequest = function () {
        return this.userSearchRequest;
    };
    EfileAccessService.prototype.updateSelectedUser = function (value) {
        this.selectedUserSubject.next(value);
    };
    EfileAccessService.prototype.getSelectedUser = function () {
        return this.selectedUserSubject.asObservable();
    };
    Object.defineProperty(EfileAccessService.prototype, "selectedUser", {
        get: function () {
            return this._selectedUser;
        },
        set: function (value) {
            this._selectedUser = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EfileAccessService.prototype, "parentCustomerLocation", {
        get: function () {
            return this._parentCustomerLocation;
        },
        set: function (value) {
            this._parentCustomerLocation = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EfileAccessService.prototype, "userDetails", {
        get: function () {
            return this._userDetails;
        },
        set: function (value) {
            this._userDetails = value;
        },
        enumerable: true,
        configurable: true
    });
    EfileAccessService.prototype.updateSaveSuccessIndicator = function (value) {
        this.saveSuccessIndicator.next(value);
    };
    EfileAccessService.prototype.getSaveSuccessIndicator = function () {
        return this.saveSuccessIndicator.asObservable();
    };
    EfileAccessService.prototype.setProceedIndicator = function (value) {
        this.proceedIndicator.next(value);
    };
    EfileAccessService.prototype.getProceedIndicator = function () {
        return this.proceedIndicator.asObservable();
    };
    EfileAccessService.prototype.setRefreshSections = function (value) {
        this.refreshSections.next(value);
    };
    EfileAccessService.prototype.getRefreshSections = function () {
        return this.refreshSections.asObservable();
    };
    EfileAccessService.prototype.setGridLoaded = function (value) {
        return this.gridLoaded.next(value);
    };
    EfileAccessService.prototype.getGridLoaded = function () {
        return this.gridLoaded.asObservable();
    };
    EfileAccessService.prototype.setMySearchesNavigation = function (value) {
        this.mySearchesNavigation.next(value);
    };
    EfileAccessService.prototype.getMySearchesNavigation = function () {
        return this.mySearchesNavigation.asObservable();
    };
    EfileAccessService.prototype.setAdminRole = function () {
        var _this = this;
        this._globalAppService.getDefaultRegion().subscribe(function (region) {
            if (region) {
                _this.region = region;
            }
        });
        var roles = this._globalAppService.getApprolesArray();
        if (this.region) {
            var whitelistedRoles = [];
            switch (this.region) {
                case 'EMEA':
                    whitelistedRoles = this.siteAdminRoles['EMEA'];
                    break;
                case 'NA':
                    whitelistedRoles = this.siteAdminRoles['NA'];
                    break;
                case 'LAO':
                    whitelistedRoles = this.siteAdminRoles['LAO'];
                    break;
            }
            if (this.appServe.isGlobalUser) {
                this.isAdminRole = false;
            }
            else {
                var adminRoles_1 = [];
                this.isAdminRole = whitelistedRoles.some(function (wl) {
                    if (roles.indexOf(wl) !== -1) {
                        adminRoles_1.push(wl);
                        return true;
                    }
                    else {
                        return false;
                    }
                });
                this.isAdminRoleReadOnly = adminRoles_1.toString().indexOf("K-C_CustomerPortal_SiteAdminReadOnly") > -1;
            }
        }
        //this.isAdminRole = true;
        return this.isAdminRole && !this.isAdminRoleReadOnly;
    };
    EfileAccessService.ngInjectableDef = i0.defineInjectable({ factory: function EfileAccessService_Factory() { return new EfileAccessService(i0.inject(i1.AppService), i0.inject(i2.GlobalAppService)); }, token: EfileAccessService, providedIn: "root" });
    return EfileAccessService;
}());
export { EfileAccessService };
