import { Component, OnDestroy, ViewChild } from '@angular/core';

import {
  IDoesFilterPassParams,
  IFilterParams,
  RowNode,
} from 'ag-grid-community';

import { IFilterAngularComp } from 'ag-grid-angular';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { TranslateService } from '@ngx-translate/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { Calendar } from 'primeng/calendar';

@AutoUnsubscribe()
@Component({
  selector: 'date-range-selector',
  templateUrl: './daterange-selector.component.html',
  styleUrls: ['./daterange-selector.component.scss']
})
export class DaterangeSelectorComponent implements IFilterAngularComp, OnDestroy {

  public params: IFilterParams;
  public valueGetter: (rowNode: RowNode) => any;

  txtFromDate: Date;
  txtToDate: Date;
  maxDateValue: Date = new Date();
  calenderData: any;
  public colId: string;

  filterModel: any = { type: "date-range-selector", value: { from: this.txtFromDate, to: this.txtToDate } };
  isDateRangeSelected: boolean;
  guiParams: any;
  subscription: Subscription = new Subscription();
  showMessage: boolean;
  orgColId: string;
  dateFormat: any;
  primengDateFormat: any;
  calendarText: string;
  region: any;
  @ViewChild('fromCalendar') fromCalendar: Calendar;
  @ViewChild('toCalendar') toCalendar: Calendar;

  constructor(
    private translateServ: TranslateService,
    private languageServ: LanguageSelectionService,
    private gridService: CpAgGridService,
    private _globalAppService: GlobalAppService) {
  }

  agInit(params: IFilterParams): void {
    this.params = params;
    this.region = this._globalAppService.getDefaultRegionString();
    this.orgColId = params.column.getColId();
    this.colId = params.colDef.field;
    this.valueGetter = params.valueGetter;
    this.colId = this.colId.indexOf('_') !== -1 ? this.colId.substring(0, this.colId.indexOf('_')) : this.colId;
    if(this.region == 'NA'){
      if(this.colId !== 'availableDate'){
        this.maxDateValue.setDate(this.maxDateValue.getDate());
      }
      else{
        this.maxDateValue = null;
      }      
    }
    else{
      this.maxDateValue.setDate(this.maxDateValue.getDate());
    }

    this.subscription.add(this.languageServ.currentSelected.subscribe((data) => {
      this.subscription.add(this.translateServ.get(['calender'])
        .subscribe(translations => {
          this.calenderData = translations['calender'];
          this.dateFormat = this.calenderData.format;
          this.primengDateFormat = this.calenderData.dateFormat.toLowerCase();
        }));
    }));

    this.params.api.setPopupParent(document.querySelector('body'));

    let currentCountryLang = this._globalAppService.getSelectedCountryLang();
    // this.calendarText = this._globalAppService.getDefaultLanguageString().split('-')[0];
    this.calendarText = (currentCountryLang && currentCountryLang.language) ? currentCountryLang.language.split('-')[0] : this._globalAppService.getDefaultLanguageString().split('-')[0];
    this.languageServ.getlanguage().subscribe(data => {
      if (data) {
        this.calendarText = data.split('-')[0];
      }
    });
  }

  getModel(): any {
    this.filterModel.value = new Object();
    this.filterModel.value.from = this.txtFromDate;
    this.filterModel.value.to = this.txtToDate;
    return this.filterModel;
  }

  setModel(model: any): void {
    if (model && model.value) {
      this.txtFromDate = this.filterModel.value.from = model.value.from;
      this.txtToDate = this.filterModel.value.to = model.value.to;
    }
    else {
      this.txtFromDate = null;
      this.txtToDate = null;
      this.filterModel.value = "";
      this.filterModel.value = "";
    }

    this.isDateRangeSelected = false;
    this.showMessage = false;
  }

  isFilterActive(): boolean {
    return this.txtFromDate && this.txtFromDate instanceof Date || this.txtToDate && this.txtToDate instanceof Date;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return true;
  }

  // noinspection JSMethodCanBeStatic
  componentMethod(message: string): void {
    alert(`Alert from DateRangeSelectorComponent ${message}`);
  }

  onFromDateSelected(event): void {
    if (this.validateDates()) {
      this.isDateRangeSelected = true;
      this.showMessage = false;
    }
    else {
      this.showMessage = true;
      this.isDateRangeSelected = false;
    }
  }

  onToDateSelected(event): void {
    if (this.validateDates()) {
      this.isDateRangeSelected = true;
      this.showMessage = false;
    } else {
      this.showMessage = true;
      this.isDateRangeSelected = false;
    }
  }

  validateDates(): boolean {
    let isValid: boolean = false;

    if (this.txtFromDate || this.txtToDate) {
      isValid = true;
    }
    else
      return false;

    if (this.txtFromDate && this.txtToDate)
      if (this.txtFromDate <= this.txtToDate)
        isValid = true
      else
        return false;

    return isValid;
  }

  onApplyClick(event) {
    if (this.validateDates()) {
      this.showMessage = false;
      this.params.filterChangedCallback();
      this.guiParams.hidePopup();
    }
    else
      this.showMessage = true;
  }

  afterGuiAttached(params) {
    if (this.fromCalendar && this.fromCalendar.inputfieldViewChild)
      this.fromCalendar.inputfieldViewChild.nativeElement.placeholder = this.dateFormat;
    if (this.toCalendar && this.toCalendar.inputfieldViewChild)
      this.toCalendar.inputfieldViewChild.nativeElement.placeholder = this.dateFormat;
    this.guiParams = params;

    this.subscription.add(this.languageServ.currentSelected.subscribe((data) => {
      this.subscription.add(this.translateServ.get(['calender'])
        .subscribe(translations => {
          this.calenderData = translations['calender'];
          this.dateFormat = this.calenderData.format;
          this.primengDateFormat = this.calenderData.dateFormat.toLowerCase();
        }));
    }));
    if(!this.txtFromDate && !this.txtToDate){
      this.showMessage = false;
    }
  }

  onDateInput(event) {
    if (event.target.name === 'fromDate' && event.target.value == '')
      this.txtFromDate = null;
    else if(event.target.name === 'toDate' && event.target.value == '')
      this.txtToDate = null;

    if (this.validateDates()) {
      this.isDateRangeSelected = true;
      this.showMessage = false;
    }
    else {
      this.isDateRangeSelected = false;
      this.showMessage = true;
    }
  }

  onSortRequested(isSortedRequest) {
    if (!!isSortedRequest) {
      this.guiParams.hidePopup();
    }
  }

  onCalFromFocus(cpContainer){
    cpContainer.classList.add('date_range_from--popup');
  }
  
  OnCalFromClosed(cpContainer){
    cpContainer.classList.remove('date_range_from--popup');
  }
  onCalToFocus(cpContainer){
    cpContainer.classList.add('date_range_to--popup');
  }
  OnCalToClosed(cpContainer){
    cpContainer.classList.remove('date_range_to--popup');
  }


  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }
}
