import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { DashBoardTilesCountCB } from '../DataModels/SharedDataModel';
import { GlobalAppService } from './global-app.service';
import { timeout, catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpChargeBackService {

  constructor(private http: HttpClient,
    private _globalAppService: GlobalAppService) { }


  //fetch region specific url from environment.ts
  getRegionSpecificURL(item: any) {
    let regionURL = "";
    let region = localStorage.getItem('default-region') || this._globalAppService.getDefaultRegionString() || this._globalAppService.selectedRegion;
    if (region) {
      regionURL = "url_" + region.toUpperCase();
    }
    else {
      regionURL = "url_NA";
    }
    return item[regionURL];
  }

  makeHTTPCall(method: string, endPoint: string, additionalHeaders: any = null, requestParams: any = null): Observable<any> {
    let requestBody = {};
    if (additionalHeaders) {
      requestBody = Object.assign({}, requestBody, { headers: additionalHeaders });
    }
    if (requestParams) {
      requestBody = Object.assign({}, requestBody, { body: requestParams });
    }

    return this.http.request(method, endPoint, requestBody);
  }

  getTilesCount(): Observable<DashBoardTilesCountCB> {
    return this.makeHTTPCall(environment.tilesCountCB.method, this.getRegionSpecificURL(environment.tilesCountCB), null, null)
  }

  getTilesPendingCount(): Observable<DashBoardTilesCountCB> {
    return this.makeHTTPCall(environment.tilesPendingCountCB.method, this.getRegionSpecificURL(environment.tilesPendingCountCB), null, null)
  }

  getTilesUnclaimedCount(): Observable<DashBoardTilesCountCB> {
    return this.makeHTTPCall(environment.tilesUnclaimedCountCB.method, this.getRegionSpecificURL(environment.tilesUnclaimedCountCB), null, null)
  }

  getTilesResult(): Observable<any> {
    return this.makeHTTPCall(environment.tilesResultCB.method, this.getRegionSpecificURL(environment.tilesResultCB), null, null)
  }

  getTilesPendingResult(): Observable<any> {
    return this.makeHTTPCall(environment.tilesPendingResultCB.method, this.getRegionSpecificURL(environment.tilesPendingResultCB), null, null)
  }

  exportTilesResult(searchRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportTilesResultCB.method, this.getRegionSpecificURL(environment.exportTilesResultCB), null, searchRequestObj)
  }

  exportTilesPendingResult(searchRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportTilesPendingResultCB.method, this.getRegionSpecificURL(environment.exportTilesPendingResultCB), null, searchRequestObj)
  }

  exportUnclaimedTilesResult(searchRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.tilesUnclaimedResultExportCB.method, this.getRegionSpecificURL(environment.tilesUnclaimedResultExportCB), null, searchRequestObj)
  }

  getSavedFavouriteSearchTiles(): Observable<any> {
    return this.makeHTTPCall(environment.getSavedFavoriteSearchCB.method, this.getRegionSpecificURL(environment.getSavedFavoriteSearchCB), null, null)
  }

  removeSavedFavouriteSearchTiles(reqParams): Observable<any> {
    return this.makeHTTPCall(environment.removeSavedFavoriteSearchCB.method, this.getRegionSpecificURL(environment.removeSavedFavoriteSearchCB), null, reqParams)
  }

  getRecentSearchTiles(): Observable<any> {
    return this.makeHTTPCall(environment.getRecentSearchCB.method, this.getRegionSpecificURL(environment.getRecentSearchCB), null, null)
  }

  /**
   * Method to call Search API, based on provided search fields entries.
   * @param searchObj 
   */
  CBSearch(searchObj): Observable<any> {
    return this.makeHTTPCall(environment.searchByFieldsCB.method, this.getRegionSpecificURL(environment.searchByFieldsCB), null, searchObj);
  }

  CBRecentSearchSave(searchObj): Observable<any> {
    return this.makeHTTPCall(environment.saveRecentSearchCB.method, this.getRegionSpecificURL(environment.saveRecentSearchCB), null, searchObj)
  }

  getLocation(userid): Observable<any> {
    return this.makeHTTPCall(environment.getLocationData.method, this.getRegionSpecificURL(environment.getLocationData) + "?userID=" + userid, null, null
    )
  }

  getStates(request: any): Observable<any> {
    return this.makeHTTPCall(environment.getStates.method, this.getRegionSpecificURL(environment.getStates), null, request);
  }

  getStateList(request) {
    return this.makeHTTPCall(environment.getStateList.method, this.getRegionSpecificURL(environment.getStateList), null, {
      "CP_Country_Code": (request ? request : "")
    });
  }

  exportChargeBackSearch(searchRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportBySearchResultCB.method, this.getRegionSpecificURL(environment.exportBySearchResultCB), null, searchRequestObj)
  }


  markSearchFavrouite(searchId): Observable<any> {
    return this.makeHTTPCall(environment.markSearchFavrouiteCB.method, this.getRegionSpecificURL(environment.markSearchFavrouiteCB), null, {
      id: searchId
    })
  }

  removeProfileSearch(searchId): Observable<any> {
    return this.makeHTTPCall(environment.removeSearchCB.method, this.getRegionSpecificURL(environment.removeSearchCB), null, {
      id: searchId,
      name: "",
      moduleType: "chargeback"
    })
  }

  saveSearchName(searchId, name): Observable<any> {
    return this.makeHTTPCall(environment.saveSearchNameCB.method, this.getRegionSpecificURL(environment.saveSearchNameCB), null, {
      id: searchId,
      name: name,
      moduleType: "chargeback"
    })
  }

  getCouponSummaryDetails(request): Observable<any> {
    return this.makeHTTPCall(environment.summaryResultsCB.method, this.getRegionSpecificURL(environment.summaryResultsCB), null, request);
  }

  getCouponSummaryCB(summaryReqObj): Observable<any> {
    return this.makeHTTPCall(environment.getCouponSummaryCB.method, this.getRegionSpecificURL(environment.getCouponSummaryCB), null, summaryReqObj);
  }

  getCustomerEU(request): Observable<any> {
    return this.makeHTTPCall(environment.getCustomerEuCB.method, this.getRegionSpecificURL(environment.getCustomerEuCB), null, request);
  }

  getCustEUDetails(request): Observable<any> {
    return this.makeHTTPCall(environment.getEndUserDetailsCB.method, this.getRegionSpecificURL(environment.getEndUserDetailsCB), null, request);
  }
  exportCBSummaryResult(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportCBSummaryResult.method, this.getRegionSpecificURL(environment.exportCBSummaryResult), null, requestObj);
  }

  getUploadedByEmailIds(requestObj) {
    return this.makeHTTPCall(environment.getUploadedByEmailIds.method, this.getRegionSpecificURL(environment.getUploadedByEmailIds), null, requestObj);
  }

  exportCBEfileResult(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportCBEfileResult.method, this.getRegionSpecificURL(environment.exportCBEfileResult), null, requestObj);
  }
  searchCBEfileResult(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.searchCBEfileResult.method, this.getRegionSpecificURL(environment.searchCBEfileResult), null, requestObj);
  }

  downloadDataCB(request: any, CBGridType): Observable<any> {
    // return this.http.post(environment.download.url, request).pipe(timeout(300000),
    let apiUrl;
    switch (CBGridType) {
      case 'CBsearchResult':
        apiUrl = environment.downloadCB;
        break;
      case 'CouponDetailsEU':
        apiUrl = environment.downloadEUDetails;
        break;
      case '':
        apiUrl = environment.downloadTileCB;
        break;
    }
    return this.makeHTTPCall(apiUrl.method, this.getRegionSpecificURL(apiUrl), null, request).pipe(timeout(300000),
      catchError((err: any) => {
        return of(null);
      }),
      map((response) => {
        if (response) {
          return response;
        }
      }));
  }

  // efileDownload(claimFileId, salesOrgId, fileversion) {
  efileDownload(request) {
    return this.makeHTTPCall(environment.efileDownload.method, this.getRegionSpecificURL(environment.efileDownload), null, request)
  }

  eFileLinesWithErrors(request): Observable<any> {
    return this.makeHTTPCall(environment.eFileLinesWithErrors.method, this.getRegionSpecificURL(environment.eFileLinesWithErrors), null, request)
  }

  updateCBEfileStatus(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.updateCBEfileStatus.method, this.getRegionSpecificURL(environment.updateCBEfileStatus), null, requestObj);
  }

  cbGetDiscrepancies(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.cbGetDiscrepancies.method, this.getRegionSpecificURL(environment.cbGetDiscrepancies), null, requestObj);
  }

  uploadEfile(requestObject): Observable<any> {
    return this.makeHTTPCall(environment.uploadEfile.method, this.getRegionSpecificURL(environment.uploadEfile), { 'Content-Type': 'multipart/form-data' }, requestObject);
  }

  downloadEFileTemplate(): Observable<any> {
    return this.makeHTTPCall(environment.downloadEFileTemplate.method, this.getRegionSpecificURL(environment.downloadEFileTemplate), null, null)
  }

  downloadKCFileTemplate(request): Observable<any> {
    return this.makeHTTPCall(environment.KCFileTemplateDownload.method, this.getRegionSpecificURL(environment.KCFileTemplateDownload), null, request);
  }

  getEFileErrorSummary(requestObj) {
    return this.makeHTTPCall(environment.eFileErrorSummary.method, this.getRegionSpecificURL(environment.eFileErrorSummary), null, requestObj);
  }

  exportCBEFileErrorSummaryResult(searchRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportCBEFileErrorSummaryResult.method, this.getRegionSpecificURL(environment.exportCBEFileErrorSummaryResult), null, searchRequestObj)
  }

  deleteEFileLineWithErrors(request): Observable<any> {
    return this.makeHTTPCall(environment.deleteEFileLineWithErrors.method, this.getRegionSpecificURL(environment.deleteEFileLineWithErrors), null, request)
  }

  efileHistoryLog(requestObj) {
    return this.makeHTTPCall(environment.efileHistoryLog.method, this.getRegionSpecificURL(environment.efileHistoryLog), null, requestObj);
  }

  exportEfileHistory(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportEfileHistory.method, this.getRegionSpecificURL(environment.exportEfileHistory), null, requestObj);
  }

  getKCPContent(request, fromFileUpload = false): Observable<any> {
    if (fromFileUpload) {
      return this.makeHTTPCall(environment.EfileUploadKCPContent.method, this.getRegionSpecificURL(environment.EfileUploadKCPContent), null, request)
    } else {
      return this.makeHTTPCall(environment.KCPContent.method, this.getRegionSpecificURL(environment.KCPContent), null, request)
    }
  }

  getAccptancePolicyStatus(request, fromFileUpload = false): Observable<any> {
    if (fromFileUpload) {
      return this.makeHTTPCall(environment.EfileUploadgetAccptancePolicyStatus.method, this.getRegionSpecificURL(environment.EfileUploadgetAccptancePolicyStatus), null, request)
    } else {
      return this.makeHTTPCall(environment.getAccptancePolicyStatus.method, this.getRegionSpecificURL(environment.getAccptancePolicyStatus), null, request)
    }
  }

  setAccptancePolicyStatus(request, fromFileUpload = false): Observable<any> {
    if (fromFileUpload) {
      return this.makeHTTPCall(environment.EfileUploadsetAccptancePolicyStatus.method, this.getRegionSpecificURL(environment.EfileUploadsetAccptancePolicyStatus), null, request)
    } else {
      return this.makeHTTPCall(environment.setAccptancePolicyStatus.method, this.getRegionSpecificURL(environment.setAccptancePolicyStatus), null, request)
    }
  }

  cbGetDistinctFilterSearchGrid(requestObj, gridName): Observable<any> {
    switch (gridName) {
      case "searchResultsCB":
        return this.makeHTTPCall(environment.cbGetDistinctFilterSearchGrid.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterSearchGrid), null, requestObj);
      case "recentActivity":
      case "issuedTileGrid":
      case "deniedTileGrid":
        return this.makeHTTPCall(environment.cbGetDistinctFilterTilesGrid.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterTilesGrid), null, requestObj);
      case "pendingTileGrid":
        return this.makeHTTPCall(environment.cbGetDistinctFilterTilesPending.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterTilesPending), null, requestObj);
      case "summaryResultsCB":
        return this.makeHTTPCall(environment.cbGetDistinctFilterSummaryGrid.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterSummaryGrid), null, requestObj);
      case "efileSearchResult":
        return this.makeHTTPCall(environment.cbGetDistinctFilterEFileGrid.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterEFileGrid), null, requestObj);
      case "eFileErrorSummary":
        return this.makeHTTPCall(environment.cbGetDistinctFilterEFileErrorSummaryGrid.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterEFileErrorSummaryGrid), null, requestObj);
      case "unclaimedTileGrid":
        return this.makeHTTPCall(environment.tilesUnclaimedDistinctFilterCB.method, this.getRegionSpecificURL(environment.tilesUnclaimedDistinctFilterCB), null, requestObj);
      case "kcfileSearchResult":
        return this.makeHTTPCall(environment.cbGetDistinctFilterKcFileGrid.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterKcFileGrid), null, requestObj);
      case "kcFileErrorSummary":
        return this.makeHTTPCall(environment.cbGetDistinctFilterKcFileErrorSummaryGrid.method, this.getRegionSpecificURL(environment.cbGetDistinctFilterKcFileErrorSummaryGrid), null, requestObj);

      default:
        return of(false);
    }
  }
  correctEfileErrors(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.correctEfileErrors.method, this.getRegionSpecificURL(environment.correctEfileErrors), null, requestObj);
  }

  //----- Added For KC-File ---///
  searchCBKcfileResult(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.searchCBKcfileResult.method, this.getRegionSpecificURL(environment.searchCBKcfileResult), null, requestObj);
  }
  exportCBKcfileResult(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportCBKcfileResult.method, this.getRegionSpecificURL(environment.exportCBKcfileResult), null, requestObj);
  }
  downloadKcFileTemplate(): Observable<any> {
    return this.makeHTTPCall(environment.downloadKcFileTemplate.method, this.getRegionSpecificURL(environment.downloadKcFileTemplate), null, null)
  }
  uploadKcfile(requestObject): Observable<any> {
    return this.makeHTTPCall(environment.uploadKcfile.method, this.getRegionSpecificURL(environment.uploadKcfile), { 'Content-Type': 'multipart/form-data' }, requestObject);
  }

  exportCBKcFileErrorSummaryResult(searchRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportCBKcFileErrorSummaryResult.method, this.getRegionSpecificURL(environment.exportCBKcFileErrorSummaryResult), null, searchRequestObj)
  }

  kcfileHistoryLog(requestObj) {
    return this.makeHTTPCall(environment.kcfileHistoryLog.method, this.getRegionSpecificURL(environment.kcfileHistoryLog), null, requestObj);
  }

  exportKcfileHistory(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportKcfileHistory.method, this.getRegionSpecificURL(environment.exportKcfileHistory), null, requestObj);
  }

  kcfileDownload(request) {
    return this.makeHTTPCall(environment.kcfileDownload.method, this.getRegionSpecificURL(environment.kcfileDownload), null, request)
  }

  updateCBKcfileStatus(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.updateCBKcfileStatus.method, this.getRegionSpecificURL(environment.updateCBKcfileStatus), null, requestObj);
  }

  correctKcfileErrors(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.correctKcfileErrors.method, this.getRegionSpecificURL(environment.correctKcfileErrors), null, requestObj);
  }

  deleteKcFileLineWithErrors(request): Observable<any> {
    return this.makeHTTPCall(environment.deleteKcFileLineWithErrors.method, this.getRegionSpecificURL(environment.deleteKcFileLineWithErrors), null, request)
  }

  KCFilePriceAgreementSearch(searchRequestObj): Observable<any> {
    return this.makeHTTPCall(environment.KCFilePriceAgreementSearch.method, this.getRegionSpecificURL(environment.KCFilePriceAgreementSearch), null, searchRequestObj)
  }
}
