let endPoints_IN_QA = {
	GetInvoicePriceStatusByCustomerID: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/NA/v1/Invoice/GetInvoicePriceStatusByCustomerID',
		method: 'POST'
	},
	GetCategoriesByCustomerNumber: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/na/v1/Invoice/GetCategoriesByCustomerNumber',
		method: 'POST'
	},
	SaveFavoriteInvoiceCategory: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/Invoice/SaveFavoriteCategory',
		method: 'POST'
	},
	GetFavoriteCategoryInvoice: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/Invoice/GetFavoriteCategory',
		method: 'POST'
	},
	PriceRefreshRequest: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/na/v1/Invoice/PriceRefreshRequest',
		method: 'POST'
	},
	DownloadInvoicePrice: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/na/v1/DownloadInvoice/DownloadInvoicePrice',
		method: 'POST'
	},
	NotifyInvoicePriceRequestByEmail: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/na/v1/Invoice/NotifyInvoicePriceRequestByEmail',
		method: 'POST'
	},

};

export { endPoints_IN_QA };