import { Component, OnDestroy, OnInit } from '@angular/core';
import { InvoicePriceService } from '@app/Modules/in-voice-new/Services/invoice-price.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { HttpInvoicePriceNewService } from '@app/Modules/Shared/Services/http-invoice-price-new.service';
import { AgRendererComponent } from 'ag-grid-angular';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
  @AutoUnsubscribe()

  @Component({
    selector: 'favorite',
    templateUrl: './favorite.component.html',
    styleUrls: ['./favorite.component.scss']
  })
  
  export class FavoriteComponent implements AgRendererComponent, OnInit, OnDestroy {

  params: any;
  isFavorite: boolean = false;
  isGlobalUser: boolean = false;
  customerNumber: any;
  test: boolean = false;

  constructor(
    private httpInvoiceServ: HttpInvoicePriceNewService,
    private appService: AppService,
    private invoiceServ: InvoicePriceService,
    private appServ: AppService) { }

    ngOnInit(){
      
    }

    

  agInit(params: any) {
    this.params = params;
    let favoriteCustomerNumbers = this.invoiceServ.favoritecustomerList;
    if(favoriteCustomerNumbers){
      if(params && params.data && params.data.customerNumber){
        let customerNumber = params.data.customerNumber;
        if(favoriteCustomerNumbers && favoriteCustomerNumbers.length){
          favoriteCustomerNumbers.find((item:any) => {
          if(item == customerNumber){
            this.isFavorite = true;
          }
        })
        }
      }
    }
    this.isGlobalUser = this.appService.isGlobalUser;
  }

  refresh(params: any): boolean {
    return false;
  }

  setUnsetFavourite(isSetToFav){
    this.isFavorite = !this.isFavorite;
    this.appService.setLoadingStatus(true);
    let request = {
      userId: this.appService.getuserId(false),
      favoriteCustomerList: [
        {
          customerNumber: this.params.data.customerNumber,
          isFavorite: this.isFavorite
        }
      ]
    };
    this.httpInvoiceServ.saveFavoriteInvoice(request).subscribe( res => {
      if(res.statusMessage == "Success"){
        // this.invoiceServ.setRedrawAdvancedGridRow([{isFavorite: isSetToFav, customerNumber: this.params.data.customerNumber}]);
        this.test = !this.test;
        this.getFavoriteCustomer();
      }      
    }, (e) => {

    }, () => {
      this.appService.setLoadingStatus(false);
    });
  }

  getFavoriteCustomer(){
    let userId = this.appServ.getuserId(true);
    let request = {
      userId : userId
    }
    this.httpInvoiceServ.getFavoriteCusotmerInvoice_new(request).subscribe(data => {
      let customerNumber = data.favoriteCustomerList.map((item) => {
        return item.customerNumber;
      });
      this.invoiceServ.favoritecustomerList = customerNumber;
    })
  }

  ngOnDestroy(){

  }

}