import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { DownloadRequest, DownloadRequestSignalRRebate } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { cookieSIMID } from '@env/environment';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { SignalRMaster } from '@app/Modules/Shared/Services/SingnalR.master.service';

@Component({
  selector: 'hyperlink-and-click',
  templateUrl: './hyperlink-and-click.component.html',
  styleUrls: ['./hyperlink-and-click.component.scss']
})

export class HyperlinkAndClickComponent implements AgRendererComponent, OnDestroy {

  params: any;
 
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpServ: HttpService,
    private gridService: CpAgGridService,
    private appserv: AppService,
    private globalAppService: GlobalAppService,
    private signalRService: SignalRMaster   ) { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClick() {
      switch (this.params.colDef.field) {
        case 'legacyPANumber':
          this.downloadPDF();
          break;
      }
  }

  downloadPDF() {
    this.appserv.setLoadingStatus(true);
    let data = this.pdfDownloadAttributes();
    if (data) {
      this.httpServ.downloadRebateData(data).subscribe(
        (res) => {
          if (res && res.fileUrl && res.fileUrl !== '') {            
            let opener = window.open(res.fileUrl, '_blank');
            this.appserv.setLoadingStatus(false);
          }
          else {
            this.appserv.setLoadingStatus(false);
            if(res.statusCode == '5003'){
              this.router.navigate([{ outlets: { dialogs: ['noDataModal'] } }], { relativeTo: this.activatedRoute.parent, state: { } });
            } else if(res.statusCode == "5011"){
              //Make the Signal R call.
              this.svcCallToSignalR(data);
            } else {
              this.gridService.setDownloadMessage({ key: 'downloadError', summary: 'errorLabel', detail:  (res && res.statusCode ? res.statusCode + '.errorMessage' : '0000.errorMessage'), severity: 'error' });
            }
          }
        },
        (error) => {
          this.appserv.setLoadingStatus(false);
          this.gridService.setDownloadMessage({ key: 'downloadError', summary: 'errorLabel', detail: '0000.errorMessage', severity: 'error' });
        })
    }
  }

  pdfDownloadAttributes(): DownloadRequest {
    let request: DownloadRequest = new DownloadRequest();
    request.priceAgreementSearchRequest = this.gridService.getSvcRequest();
    request.selectedCustomerNumbers = [(this.params.data.customerNumber === 'All' ? '0' : this.params.data.customerNumber)];
    request.selectedContractNumbers = [this.params.data.contractNumber];
    request.contractCustomers = [{ customerNumber: (this.params.data.customerNumber === 'All' ? '0' : this.params.data.customerNumber), contractNumber: this.params.data.contractNumber }]
    request.isDownloadAll = false;
    request.paStatus = [this.params.data.statusId ? this.params.data.statusId : []];
    request.includeEndusers = false;
    request.dates = 1;
    request.fileType = 2;
    request.isRecentlyChanged = false;
    request.emailType = 1;
    request.SelectedSearchCount = 1;
    // request.searchView = this.cvtView ? 'cvt' : 'customer';
    return request;
  }

  svcCallToSignalR(downloadRequest) {
    let integratedUserid = "";
    if (this.appserv.getCookie(cookieSIMID) && this.appserv.isGlobalUser) {
      integratedUserid = this.appserv.getuserId(false);
    }
    this.appserv.setLoadingStatus(false);
    let request: DownloadRequestSignalRRebate = new DownloadRequestSignalRRebate();
    request.UserName  = Math.random().toString(36).substring(2, 15);
    request.IntegratedUserid  = integratedUserid;
    request.Token = 'TOKEN'
    request.Region = this.globalAppService.getDefaultRegionString().toUpperCase();
    request.requestObj = downloadRequest;
    request.ModuleType = "3";
    request.Reconnect = false;
    let selectedCountryLang = this.globalAppService.getSelectedCountryLang();
    request.Languagecode  = selectedCountryLang.language.split('-')[0] + "-" + selectedCountryLang.language.split('-')[1].toUpperCase();

    this.signalRService.initSignalR_Download('EMEA-PA',
    request.UserName, 
    request.IntegratedUserid,
    'TOKEN',
    request.Region,
    JSON.stringify(request.requestObj),
    request.ModuleType,
    false,
    request.Languagecode
    );
  }

  ngOnDestroy() {

  }

}
