/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./efile-error-column.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "primeng/components/tooltip/tooltip";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./efile-error-column.component";
import * as i6 from "../../../services/cp-ag-grid-service";
import * as i7 from "../../../../Shared/Services/global-app.service";
var styles_EfileErrorColumnComponent = [i0.styles];
var RenderType_EfileErrorColumnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EfileErrorColumnComponent, data: {} });
export { RenderType_EfileErrorColumnComponent as RenderType_EfileErrorColumnComponent };
function View_EfileErrorColumnComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "textUnderlined"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "i", [["class", "fas fa-exclamation-circle"], ["tooltipPosition", "top"]], null, null, null, null, null)), i1.ɵdid(3, 4341760, null, 0, i3.Tooltip, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], escape: [1, "escape"], text: [2, "text"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "textUnderlined"; var currVal_1 = _co.tolltipHoverClass; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "top"; var currVal_3 = false; var currVal_4 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 2, i1.ɵnov(_v, 4).transform("chargeback.eFile.highErrorsFoundTooltip")), ""); _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); }, null); }
export function View_EfileErrorColumnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "textRed": 0, "textUnderlined": 1 }), (_l()(), i1.ɵted(3, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EfileErrorColumnComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.isErrorExist, (_co.isErrorHigh && _co.isPageEfileSearchResult)); _ck(_v, 1, 0, currVal_0); var currVal_2 = (_co.isErrorHigh && _co.isPageEfileSearchResult); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.params.value; _ck(_v, 3, 0, currVal_1); }); }
export function View_EfileErrorColumnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "efile-error-column", [], null, null, null, View_EfileErrorColumnComponent_0, RenderType_EfileErrorColumnComponent)), i1.ɵdid(1, 180224, null, 0, i5.EfileErrorColumnComponent, [i6.CpAgGridService, i7.GlobalAppService], null, null)], null, null); }
var EfileErrorColumnComponentNgFactory = i1.ɵccf("efile-error-column", i5.EfileErrorColumnComponent, View_EfileErrorColumnComponent_Host_0, {}, {}, []);
export { EfileErrorColumnComponentNgFactory as EfileErrorColumnComponentNgFactory };
