/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./AppHome.component.ngfactory";
import * as i3 from "./Modules/Shared/Services/monitoring.service";
import * as i4 from "@angular/router";
import * as i5 from "primeng/components/common/messageservice";
import * as i6 from "./AppHome.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./Modules/Shared/Services/language-selection.service";
import * as i9 from "./Modules/Shared/Services/content-area.service";
import * as i10 from "./Modules/Shared/Services/http.service";
import * as i11 from "./Modules/Shared/Services/global-app.service";
import * as i12 from "./Modules/Shared/Services/app.service";
import * as i13 from "@okta/okta-angular";
import * as i14 from "@ng-idle/core";
import * as i15 from "@ng-idle/keepalive";
import * as i16 from "./Modules/Shared/Services/region-check.service";
import * as i17 from "@angular/platform-browser";
import * as i18 from "./Modules/Shared/Services/SingnalR.master.service";
import * as i19 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-home", [], null, null, null, i2.View_AppHomecomponent_0, i2.RenderType_AppHomecomponent)), i1.ɵprd(512, null, i3.MonitoringService, i3.MonitoringService, [i4.Router, i4.ActivatedRoute]), i1.ɵprd(512, null, i5.MessageService, i5.MessageService, []), i1.ɵdid(3, 245760, null, 0, i6.AppHomecomponent, [i7.TranslateService, i8.LanguageSelectionService, i9.ContentAreaService, i4.Router, i4.ActivatedRoute, i10.HttpService, i11.GlobalAppService, i12.AppService, i13.OktaAuthService, i1.Renderer2, i3.MonitoringService, i1.ElementRef, i14.Idle, i15.Keepalive, i16.RegionCheckService, i17.DOCUMENT, i17.DomSanitizer, i18.SignalRMaster, i5.MessageService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).screenResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i19.AppComponent, [i13.OktaAuthService, i4.Router, i12.AppService, i1.ElementRef, i14.Idle, i15.Keepalive], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i19.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
