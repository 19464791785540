import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
@Component({
  selector: 'delegateduser-hyperlink',
  templateUrl: './delegated-user-hyperlink.component.html',
  styleUrls: ['./delegated-user-hyperlink.component.scss']
})

@AutoUnsubscribe()
export class DelegatedUserHyperlinkComponent implements AgRendererComponent, OnDestroy {

  params: any;
  isGroupAdmin: boolean;
  isNonAssociatedUser: boolean;
  isGlobalUser: boolean;

  constructor(
    private _appSer: AppService
  ) {
   }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.isGlobalUser = this._appSer.isGlobalUser;
    this.params = params;
    if (this.params) {
      this.isGroupAdmin = this.params.data ? this.params.data.isGroupAdmin : false;
      this.isNonAssociatedUser = this.params.data ? this.params.data.isNonAssociatedUser : false;
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  ngOnDestroy() {

  }

}
