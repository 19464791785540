import { Injectable } from '@angular/core';

export enum DataLayerCustomDimensions {
  Event = 'event',
  UserId = 'userId',
  UserType = 'user_type',
  PangeLanguage = 'page_language'
}

export type FullCustomDimensionsSet = { 
  [key in DataLayerCustomDimensions]: string | undefined 
};

export type PartialCustomDimensionsSet = { [key: string]: string };

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  private _currentSet: FullCustomDimensionsSet;
  private _constantDimensions: string[];

  constructor() {
    this._generateEmptyDimensionsSet();
    this._constantDimensions = [
      DataLayerCustomDimensions.Event,
      DataLayerCustomDimensions.UserId,
      DataLayerCustomDimensions.UserType,
      DataLayerCustomDimensions.PangeLanguage
    ];
  }

  set dimensions(someDimensions: PartialCustomDimensionsSet) {
    Object.keys(DataLayerCustomDimensions).map(key => DataLayerCustomDimensions[key])
    .forEach(key => {
      this._currentSet[key] = someDimensions[key] || this._constantDimensions[key];
    });
  }

  trigger() {
    (<any>window).dataLayer.push(this._currentSet);
  }

  private _generateEmptyDimensionsSet() {
    this._currentSet = {
      [DataLayerCustomDimensions.Event]: undefined,
      [DataLayerCustomDimensions.UserId]: undefined,
      [DataLayerCustomDimensions.UserType]: undefined,
      [DataLayerCustomDimensions.PangeLanguage]: undefined,
    };
  }
}
