<!-- <router-outlet></router-outlet> -->
<div *ngIf="loading">
  <div class="loading-screen-wrapper">
    <div class="loading-screen-icon">
      <div class="loadingImage">
        &nbsp;
      </div>
    </div>
  </div>
</div>
<div class="body-wrapper" [ngClass]="{'noScroll': blurActive}">
  <app-header *ngIf="((showAppHeader && isAuthenticated) && !forceHideHeader)">
  </app-header>
  <div class="content-area" [ngClass]="{'blurActive': blurActive}">
    <router-outlet></router-outlet>
    <ng-container>
      <acceptancepolicymodal [policyContent]="policyContent" [displayPolicyModal]="showAcceptancePolicy"
        [disableNotNow]="disableNotNow" [isAccepted]="isAccepted" (languageChanged)="onAcceptancePolicyLanguageChanged($event)"
        (closePolicyModal)="postPonePolicyModal($event)" (acceptPolicy)="acceptPolicyModal($event)">
      </acceptancepolicymodal>
    </ng-container>
    <!-- <app-home></app-home> -->
    <!-- {{isAuthenticated || showAppFooter}}  -->
    <app-footer *ngIf="((isAuthenticated || showAppFooter) && !forceHideFooter)"></app-footer>
  </div>
</div>
<!-- <div *ngIf="isAuthenticated">
  <p-dialog [(visible)]="visibleDownloadModal" [dismissableMask]="false" [modal]="true" [closeOnEscape]="true"
    styleClass="sessionModal" [blockScroll]="true" [closable]="false">
    <p-header>
      {{ 'sessionWarning.title' | translate }}
    </p-header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="modal__search__end-user-search content-area__white-box">
            <div class="row">
              <div class="col-12">
                {{ 'sessionWarning.description' | translate }}
              </div>
            </div>
            <button class="btn btn-primary btn-primary--icon-right" (click)="reset()">
              <i class="far fa-arrow-alt-circle-down"></i>
              {{ 'sessionWarning.continuebutton' | translate }}</button>
          </div>

        </div>
      </div>
    </div>
  </p-dialog>
</div> -->

<p-toast key="information" position="top-right" [style]="{ marginTop : '10px', width: '400px' }" [closable]="true" [baseZIndex]="9999"
    [autoZIndex]= "true" [hideTransitionOptions]="'250ms'" [preventOpenDuplicates]="true">
    <ng-template let-message pTemplate="message">
       <div style="text-align: center" class="signalRMsg">
          <i class="pi pi-info-circle"></i>
          <p>{{message.detail | translate}}</p>
       </div>
    </ng-template>
 </p-toast>
 <p-toast key="dnlSuccess" position="top-right" [style]="{ marginTop : '10px', width: '550px', fontSize: '13px'}" [closable]="true" [baseZIndex]="9999"
    [autoZIndex]="true" [hideTransitionOptions]="'250ms'" [preventOpenDuplicates]="true">
    <ng-template let-message pTemplate="message">
       <div style="text-align: center" class="signalRMsg">
          <i class="fa fa-check" aria-hidden="true"></i>
          <p style="padding-left: 25px">{{message.summary | translate}}</p>
          <p>{{'signalRMessages.fileNameLabel' | translate}} <span [innerHTML]="message.detail"></span></p>
       </div>
    </ng-template>
 </p-toast>