<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="content-area__white-box end-user">
                <div class="search">
                    <form class="needs-validation" *ngIf="selectedRegion && userSearchForm" [formGroup]="userSearchForm" name="userSearchForm">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label for="firstName">{{ "delegatedUser.firstnameLabel" | translate }}</label>
                                    <input type="text" formControlName="firstName" class="form-control" id="firstName" maxlength="100">
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label for="lastName">{{ "delegatedUser.lastnameLabel" | translate }}</label>
                                    <input type="text" formControlName="lastName" class="form-control" id="lastName" maxlength="100">
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label for="customerName">{{ "delegatedUser.customerNameLabel" | translate }}</label>
                                    <input type="text" formControlName="customerName" class="form-control" id="customerName" maxlength="100" autocomplete="nope">
                                </div>
                            </div>

                        </div>
                        <div class="row">


                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label for="customerId">{{ "delegatedUser.customerNumberLabel" | translate }}</label>
                                    <input type="text" formControlName="customerId" class="form-control" id="customerId" maxlength="100" autocomplete="nope">
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label for="userId">{{ "delegatedUser.userIdLabel" | translate }}</label>
                                    <input type="text" formControlName="userId" class="form-control" id="userId" maxlength="100">
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label for="customerId">{{ "delegatedUser.emailAddressLabel" | translate }}</label>
                                    <input type="text" formControlName="email" class="form-control" id="email" maxlength="100">
                                </div>
                            </div>
                        </div>
                        <div class="search__submit">
                            <button type="reset" name="reset" class="btn btn-reset btn-reset-na" (click)="resetForm()" [disabled]="userSearchForm.pristine" [class.disabled-reset-button]="userSearchForm.pristine">
                  {{ 'delegatedUser.ClearButton' | translate }}
              </button>
                            <button type="submit" (click)="onSumbit($event)" name="Search" class="btn btn-primary btn-primary--icon-right">{{ "delegatedUser.searchButtonLabel" | translate }} <i class="fas fa-search"></i></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


<router-outlet name="delegatedResults"></router-outlet>