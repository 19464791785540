import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
@Component({
  selector: 'hyperlink-and-legend',
  templateUrl: './hyperlink-and-legend.component.html',
  styleUrls: ['./hyperlink-and-legend.component.scss']
})

@AutoUnsubscribe()
export class HyperlinkAndLegendComponent implements AgRendererComponent, OnDestroy {

  params: any;
  isGroupAdmin: boolean;
  isNonAssociatedUser: boolean;
  showIcons: boolean = true;
  isGlobalUser: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _appSer: AppService,
    private gridService: CpAgGridService
  ) { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.isGlobalUser = this._appSer.isGlobalUser;
    this.params = params;
    if (this.params) {
      this.isGroupAdmin = this.params.data ? this.params.data.isGroupAdmin : false;
      this.isNonAssociatedUser = this.params.data ? this.params.data.isNonAssociatedUser : false;
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClick() {
    if (this.gridService.pageContext.pageName == 'searchResultsCB') {
      switch (this.params.colDef.field) {
        case 'couponNumber':
        case 'errors':
        case 'netIssued':
          window.open('http://www.google.com', '_blank');
          break;
      }
    } else {
      switch (this.params.colDef.field) {
        case 'userId':
          let selectedUser: any = { ...this.params.data };
          this.router.navigate([{ outlets: { searchResults: null } }], { relativeTo: this.activatedRoute.parent }).then(() => {
            this.router.navigate(['manage-mapping', selectedUser.userId], { queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')},relativeTo: this.activatedRoute.parent, state: { selectedUser: selectedUser } });
          });
          break;
        case 'customerNumber':
          let selectedCustomer = { ...this.params.data };
          this.router.navigate([{ outlets: { searchCustomer: null } }], { relativeTo: this.activatedRoute.parent }).then(() => {
            this.router.navigate([{ outlets: { customerHierarchy: 'customerHierarchy' } }], { queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')},relativeTo: this.activatedRoute.parent, state: { selectedCustomer: selectedCustomer } });
          })
          break;
      }
    }
  }

  ngOnDestroy() {

  }

}
