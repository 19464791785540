<ng-container>
  <span class="custom-table__row-menu" *ngIf="showMenu">
    <i class="fas fa-ellipsis-h ellipsisbtn" [ngClass]="{'ellipseSelect' : ellipseSelect}" (click)="contextmenuToggle(contextmenu, $event)"></i>
  </span>
</ng-container>
<p-overlayPanel appendTo="body" styleClass="contextMenu" #contextmenu (onHide)="onContextMenuHide()" (onShow)="onContextMenuShow()">
  <span class="custom-table__popup__info custom-table__row-menu">
    <ul class="custom-table__row-menu__content">
      <li *ngIf="params.data?.refreshStatus == 2">
        <a href="javascript:void(0)" (click)="downloadInvoice()">
          <i class="far fa-arrow-alt-circle-down"></i>
          {{'invoicePrice.contextMenuAdvanced.downloadInvoiceLabel' | translate}}
        </a>
      </li>
      <li *ngIf="params.data?.refreshStatus == 3">
        <a href="javascript:void(0)" (click)="refreshInvoice()">
          <i class="fa fa-refresh"></i>
          {{'invoicePrice.contextMenuAdvanced.refreshInvoiceLabel' | translate}}
        </a>
      </li>
    </ul>
  </span>
</p-overlayPanel>