import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-overflow-ellipsestext',
  templateUrl: './overflow-ellipsestext.component.html',
  styleUrls: ['./overflow-ellipsestext.component.scss']
})
export class OverflowEllipsestextComponent implements AgRendererComponent {

  constructor() { }
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

}
