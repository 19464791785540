import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { HttpChargeBackService } from '@app/Modules/Shared/Services/http-charge-back.service';
import { MessageService } from 'primeng/api';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
var EfileDownnloadComponent = /** @class */ (function () {
    function EfileDownnloadComponent(httpServ, messageService, appServ) {
        this.httpServ = httpServ;
        this.messageService = messageService;
        this.appServ = appServ;
        this.subscription = new Subscription();
    }
    EfileDownnloadComponent.prototype.agInit = function (params) {
        this.params = params;
    };
    EfileDownnloadComponent.prototype.refresh = function (params) {
        return false;
    };
    EfileDownnloadComponent.prototype.onEfileNameClick = function () {
        var _this = this;
        this.appServ.setLoadingStatus(true);
        if (this.params.data && this.params.data.eFileID && this.params.data.salesOrgId) {
            var fileversion = (this.params.data.status && this.params.data.status == '3') ? 0 : 1;
            var request = {
                "claimFileId": this.params.data.eFileID,
                "salesOrgId": this.params.data.salesOrgId,
                "fileversion": fileversion //passing fileversion as 1 as new file to be downloaded code is 1 and for old its 0
            };
            this.subscription.add(this.httpServ.efileDownload(request).subscribe(function (res) {
                if (res && res.fileUrl) {
                    window.open(res.fileUrl, '_blank');
                }
                else {
                    _this.messageService.add({ key: 'efileDownload', detail: 'An unexpected error occurred. Please try again.', summary: 'Error', severity: 'error' });
                }
            }, function (e) {
                _this.messageService.add({ key: 'efileDownload', detail: 'An unexpected error occurred. Please try again.', summary: 'Error', severity: 'error' });
            }, function () {
                _this.appServ.setLoadingStatus(false);
            }));
        }
    };
    EfileDownnloadComponent.prototype.ngOnDestroy = function () {
        // Required for unsubscribing and destroying the component  
    };
    EfileDownnloadComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [HttpChargeBackService,
            MessageService,
            AppService])
    ], EfileDownnloadComponent);
    return EfileDownnloadComponent;
}());
export { EfileDownnloadComponent };
