import { Component, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
@AutoUnsubscribe()
@Component({
  selector: 'pa-split-details',
  templateUrl: './pa-split-details.component.html',
  styleUrls: ['./pa-split-details.component.scss']
})

export class PaSplitDetailsComponent implements AgRendererComponent, OnDestroy {

  params: any;
  data: any;
  productRebateDetail: any;
  display: boolean = false;
  formattedDate: string;
  firmEndDate: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    ) { }


  agInit(params: any) {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  show() {
    this.router.navigate([{ outlets: { dialogs: null } }]).then(() => {
      this.router.navigate([{ outlets: { dialogs: ['splitViewModal'] } }], { relativeTo: this.activatedRoute.parent, state: { data: this.params.data } });
    });
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }
}
