/*
Acceptance Policy = 1
Announcement = 2
News and Messages = 2
Recently Changed = 3
New Location Added = 4
*/
export const popupSequence = {
    "NA": {
        "internalUser": [ 2 ],
        "externalUser": [2,3,4]
    },
    "EMEA": {
        "internalUser": [ ],
        "externalUser": [3,4] 
    },
    "LAO" : {
        "internalUser": [ ],
        "externalUser": [3,4]
    }
}