/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recent-download-document.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./recent-download-document.component";
import * as i5 from "../../../../Shared/Services/http.service";
import * as i6 from "../../../../Shared/Services/app.service";
import * as i7 from "@angular/router";
var styles_RecentDownloadDocumentComponent = [i0.styles];
var RenderType_RecentDownloadDocumentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecentDownloadDocumentComponent, data: {} });
export { RenderType_RecentDownloadDocumentComponent as RenderType_RecentDownloadDocumentComponent };
function View_RecentDownloadDocumentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["href", "javascript:void(0)"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLinkClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.documentId, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("agGrid.agGridColumns.contextMenu.download")); _ck(_v, 1, 0, currVal_1); }); }
export function View_RecentDownloadDocumentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecentDownloadDocumentComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.documentId; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RecentDownloadDocumentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recent-download-document", [], null, null, null, View_RecentDownloadDocumentComponent_0, RenderType_RecentDownloadDocumentComponent)), i1.ɵdid(1, 180224, null, 0, i4.RecentDownloadDocumentComponent, [i5.HttpService, i6.AppService, i7.ActivatedRoute, i7.Router], null, null)], null, null); }
var RecentDownloadDocumentComponentNgFactory = i1.ɵccf("app-recent-download-document", i4.RecentDownloadDocumentComponent, View_RecentDownloadDocumentComponent_Host_0, {}, {}, []);
export { RecentDownloadDocumentComponentNgFactory as RecentDownloadDocumentComponentNgFactory };
