<p-dialog [(visible)]="displayPolicyModal" [dismissableMask]="false" [modal]="true" [draggable]="false"
   [closeOnEscape]="false" positionTop="20" [blockScroll]="true" [responsive]="true" [closable]="isAccepted || !disableNotNow"
   styleClass="dialogClass policyDialogue" (onHide)="notAcceptedNow()" (onShow)="onShowModal($event)">  
   <p-header>
      {{"acceptancePolicy.header" | translate}}
   </p-header>
   <div class="container-fluid">
      <div class="row">
         <div class="col-12">
            <div class="acceptance_policy_sub-heading" *ngIf="showLanguageBar">
               <div class="acceptance_policy_lang">
                  <a href="javascript:void(0)" [class.highlighted]="language.code == currentLanguage" *ngFor="let language of languages;" (click)="onLanguageChanged(language.code)"> {{language.label}} </a>
               </div>
            </div>
            <div class="content-area__white-box">
              <div class="modal__wrapper__info justify-content" [innerHTML]="policyContent" >

               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="modal__wrapper__buttons">
      <button type="button" class="btn btn-primary btn-primary--icon-right" [hidden]="isAccepted"
         (click)="acknowledgePolicy()"><span>{{"acceptancePolicy.acknowledge" | translate}} </span>
         {{"acceptancePolicy.agree" | translate}} <i class="far fa-check-circle"></i></button>
      <button type="button" class="btn btn-primary btn-primary--icon-right btn-primary--white"
         [disabled]="disableNotNow" [class.btn-primary--disabled]="disableNotNow" [hidden]="isAccepted || disableNotNow"
         (click)="notAcceptedNow()">{{"acceptancePolicy.notNow" | translate}} <i
            class="far fa-times-circle"></i></button>
   </div>



</p-dialog>