import { Component, OnDestroy, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { MyProfileService } from '@app/Modules/my-profile/Components/services/my-profile-service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';

@AutoUnsubscribe()

@Component({
  selector: 'app-myprofile-addto-preference',
  templateUrl: './myprofile-addto-preference.component.html',
  styleUrls: ['./myprofile-addto-preference.component.scss']
})
export class MyprofileAddtoPreferenceComponent implements AgRendererComponent, OnInit, OnDestroy {

  currentNode: any;
  checkboxName: string;
  check: boolean = false;
  region: string;

  constructor(
    private myProfileService: MyProfileService,
    private globalAppService: GlobalAppService,
    private gridService: CpAgGridService
  ) {
    // this.currentNode = null;
  }

  agInit(params: any): void {
    this.currentNode = params;
    if (!this.checkboxName) {
      this.checkboxName = "ATP-" + params.data.customerNumber; //Math.random().toString(36).substring(2, 15);
    }
    if (params && params.data && params.data.isDefault) {
      this.check = params.data.isDefault;
    }
  }

  ngOnInit() {
    this.region = this.globalAppService.getDefaultRegionString();
  }

  refresh(params: any): boolean {
    return false;
  }

  checkboxClick(event) {
    this.check = event.target.checked;
    this.currentNode.data.isDefault = event.target.checked;
    if(!event.target.checked){
      this.updateCheckStateOfDailyMails(event.target.checked);
    }    
    let isAllchecked = this.autoHeaderSelection("location-external-pref");
    this.setCheckboxStatus("addToPrefCheckBoxHeader", isAllchecked);
    if(!event.target.checked){
      this.updateHeaderCheckStateOfDailyMails(isAllchecked);
    }
  }

  /** Maintain the header check state of Daily mails depending upon the Add to Pref selected length */
  updateHeaderCheckStateOfDailyMails(isAllchecked) {
    if (isAllchecked) {
      if (this.region == "EMEA") {
        let isAllPAchecked = this.autoHeaderSelection("external-PA", 1);
        if (isAllPAchecked) {
          this.setCheckboxStatus("PACheckBoxHeader", true);
        }
        let isAllCBchecked = this.autoHeaderSelection("external-CB", 3);
        if (isAllCBchecked) {
          this.setCheckboxStatus("CBCheckBoxHeader", true);
        }
      } else if (this.region == "LAO") {
        //this.setCheckboxStatus("PACheckBoxHeader", true);
        let isAllPAchecked = this.autoHeaderSelection("external-PA", 1);
        if (isAllPAchecked) {
          this.setCheckboxStatus("PACheckBoxHeader", true);
        }
      } else if (this.region == "NA") {
        let isAllPAchecked = this.autoHeaderSelection("external-PA", 1);
        if (isAllPAchecked) {
          this.setCheckboxStatus("PACheckBoxHeader", true);
        }
        let isAllMCchecked = this.autoHeaderSelection("external-MC", 2);
        if (isAllMCchecked) {
          this.setCheckboxStatus("MCCheckBoxHeader", true);
        }
        let isAllCBchecked = this.autoHeaderSelection("external-CB", 3);
        if (isAllCBchecked) {
          this.setCheckboxStatus("CBCheckBoxHeader", true);
        }
        let isAllINchecked = this.autoHeaderSelection("external-IN", 4);
        if (isAllINchecked) {
          this.setCheckboxStatus("INCheckBoxHeader", true);
        }
        let isAllODchecked = this.autoHeaderSelection("external-OD", 5);
        if (isAllODchecked) {
          this.setCheckboxStatus("ODCheckBoxHeader", true);
        }
      }
    } else {
      let isAllPAchecked = this.autoHeaderSelection("external-PA", 1);
      if (this.region == "EMEA") {
        this.setCheckboxStatus("PACheckBoxHeader", isAllPAchecked);
        let isAllCBchecked = this.autoHeaderSelection("external-CB", 3);
        this.setCheckboxStatus("CBCheckBoxHeader", isAllCBchecked);
      } else if (this.region == "LAO") {
        this.setCheckboxStatus("PACheckBoxHeader", isAllPAchecked);
      }
      else if (this.region == "NA") {
        this.setCheckboxStatus("PACheckBoxHeader", isAllPAchecked);
        let isAllMCchecked = this.autoHeaderSelection("external-MC", 2);
        this.setCheckboxStatus("MCCheckBoxHeader", isAllMCchecked);
        let isAllCBchecked = this.autoHeaderSelection("external-CB", 3);
        this.setCheckboxStatus("CBCheckBoxHeader", isAllCBchecked);
        let isAllINchecked = this.autoHeaderSelection("external-IN", 4);
        this.setCheckboxStatus("INCheckBoxHeader", isAllINchecked);
        let isAllODchecked = this.autoHeaderSelection("external-OD", 5);
        this.setCheckboxStatus("ODCheckBoxHeader", isAllODchecked);
      }
    }


    /** If all Items from Add to Pref is checked then 
     * also check the Daily mail in front of Default disabled location
     * */
    let defaultLocation = this.myProfileService.getPreveiousSelectedLoc();
    if (isAllchecked && defaultLocation) {
      if (this.region == "EMEA") {
        this.setCheckboxStatus("PA-" + defaultLocation, true);
        this.currentNode.data.Preferences[0].isSelected = true;
        this.setCheckboxStatus("CB-" + defaultLocation, true);
        this.currentNode.data.Preferences[1].isSelected = true;
      } else if (this.region == "LAO") {
        this.setCheckboxStatus("PA-" + defaultLocation, true);
        this.currentNode.data.Preferences[0].isSelected = true;
      } else if (this.region == "NA") {
        this.setCheckboxStatus("PA-" + defaultLocation, true);
        this.currentNode.data.Preferences[0].isSelected = true;
        this.setCheckboxStatus("MC-" + defaultLocation, true);
        this.currentNode.data.Preferences[1].isSelected = true;
        this.setCheckboxStatus("CB-" + defaultLocation, true);
        this.currentNode.data.Preferences[2].isSelected = true;
        this.setCheckboxStatus("IN-" + defaultLocation, true);
        this.currentNode.data.Preferences[3].isSelected = true;
        this.setCheckboxStatus("OD-" + defaultLocation, true);
        this.currentNode.data.Preferences[4].isSelected = true;
      }
    }
  }

  /** Check/Uncheck the daily mail item in front of checked Add to Prefernce item */
  updateCheckStateOfDailyMails(checkStatus) {
    if (this.region == "EMEA") {
      this.setCheckboxStatus("PA-" + this.currentNode.data.customerNumber, checkStatus);
      this.currentNode.data.Preferences[0].isSelected = checkStatus;
      this.setCheckboxStatus("CB-" + this.currentNode.data.customerNumber, checkStatus);
      this.currentNode.data.Preferences[1].isSelected = checkStatus;
    } else if (this.region == "LAO") {
      this.setCheckboxStatus("PA-" + this.currentNode.data.customerNumber, checkStatus);
      this.currentNode.data.Preferences[0].isSelected = checkStatus;
    } else if (this.region == "NA") {
      this.setCheckboxStatus("PA-" + this.currentNode.data.customerNumber, checkStatus);
      this.currentNode.data.Preferences[0].isSelected = checkStatus;
      this.setCheckboxStatus("MC-" + this.currentNode.data.customerNumber, checkStatus);
      this.currentNode.data.Preferences[1].isSelected = checkStatus;
      this.setCheckboxStatus("CB-" + this.currentNode.data.customerNumber, checkStatus);
      this.currentNode.data.Preferences[2].isSelected = checkStatus;
      this.setCheckboxStatus("OD-" + this.currentNode.data.customerNumber, checkStatus);
      this.currentNode.data.Preferences[3].isSelected = checkStatus;
      this.setCheckboxStatus("IN-" + this.currentNode.data.customerNumber, checkStatus);
      this.currentNode.data.Preferences[4].isSelected = checkStatus;
    }
  }

  autoHeaderSelection(collectionName: string, moduleId = 0) {
    if (moduleId) {
      let isAllchecked = true;
      let recordCount = 0;
      this.gridService.gridApi.forEachNode((node) => {
        if (node.data && node.data.customerNumber) {
          recordCount++;
          let selected = node.data.Preferences.find(x => {
            return x.moduleId == moduleId;
          }).isSelected;
          if (!selected) {
            isAllchecked = false;
          }
        }
      });
      if (recordCount)
        return isAllchecked;
      else
        return false;
    } else {
      let allLocations = <HTMLCollection>document.getElementsByClassName(collectionName);
      let isAllchecked = false;
      for (let index = 0; index < allLocations.length; index++) {
        const element = <HTMLInputElement>allLocations[index];
        if (element.checked) {
          isAllchecked = true;
        } else {
          isAllchecked = false;
          break;
        }
      }
      return isAllchecked;
    }
  }

  setCheckboxStatus(checkboxName: string, status: boolean) {
    let el: HTMLInputElement = <HTMLInputElement>document.getElementById(checkboxName);
    if (el) {
      el.checked = status;
    }
  }

  ngOnDestroy() {
    // this.currentNode = null;
  }

}
