import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LocationLanguageService {

  locationLanguageData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  locationsData: Array<any>;
  selectedLocationLanguage: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  setSelectedLocationLanguage(value: any) {
    // this.selectedLocationLanguage = value;
    this.selectedLocationLanguage.next(value);
  }

  getSelectedLocationLanguage(): Observable<any> {
    return this.selectedLocationLanguage.asObservable();
  }

  setLocationLanguageData(value: any) {
    this.locationsData = value;
    this.locationLanguageData.next(value);
  }

  getLocationLanguageData(): Observable<any> {
    return this.locationLanguageData.asObservable();
  }

  getAllRegions() {
    return this.locationsData.map(region => {
      return {
        value: region.regionId,
        label: region.description
      }
    });
  }

  filterByRegion(regionCode: string): any {
    if(!this.locationsData){
      return null;
    }
    let selectedRegions = this.locationsData.filter(region => {
      return region.regionId === regionCode;
    })
    if (selectedRegions && selectedRegions.length) {
      return selectedRegions[0];
    }
    else
      return null;
  }

  filterByCountry(countryCode: string, selectedRegion: any): any {
    let selectedCountries = selectedRegion.country.filter(country => {
      return country.countryId === countryCode;
    })
    if (selectedCountries && selectedCountries.length) {
      return selectedCountries[0];
    }
    else
      return null;
  }

  getCountriesForRegion(regionCode: string) {
    let countries: Array<any>;
    let selectedRegion: any = this.filterByRegion(regionCode);
    if (selectedRegion) {
      countries = selectedRegion.country.map(country => {
        return {
          label: country.countryName,
          value: country.countryId,
          salesOrg: country.salesOrg[0].salesOrgId
        }
      });
    }

    return countries;
  }

  getLanguagesForCountry(regionCode: string, countryCode: string) {
    let languages: Array<any>;
    let languageList: Array<any> = new Array<any>();
    let selectedRegion: any = this.filterByRegion(regionCode);
    let selectedCountry: any;
    if (selectedRegion) {
      selectedCountry = this.filterByCountry(countryCode, selectedRegion);
      if (selectedCountry) {
        selectedCountry.salesOrg.forEach(so => {
          languageList = languageList.concat(so.language);
        });
        languageList = languageList.filter((item, index) => {
          let indexOfItem: number = languageList.findIndex(l => {
            return l.languageCode === item.languageCode
          });
          return indexOfItem === index;
        })
        return languageList.map(l => {
          return {
            label: l.description,
            value: l.languageCode
          }
        })
      }
      else {
        return null;
      }
    }
    else
      return null;
  }

  doesRegionExists(regionCode: string, regions: Array<any>) {
    return regions.findIndex(r => { return r.value === regionCode }) !== -1;
  }

  doesCountryExists(countryCode: string, countries: Array<any>) {
    return countries.findIndex(c => { return c.value === countryCode }) !== -1;
  }

  doesLangaugeExists(languageCode: string, languages: Array<any>) {
    return languages.findIndex(l => { return l.value === languageCode }) !== -1;
  }
}

