import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var MyProfileService = /** @class */ (function () {
    function MyProfileService() {
        this.selectedLocationSubject = new Subject();
        this.closePopUpSubject = new Subject();
        this.orderPrefSaveSuccessSubject = new Subject();
        this.defaultLocationSubject = new Subject();
        this.newLocationAddedSubject = new Subject();
        this.reloadDataAfterLangChange = new Subject();
        this.prefernceClickedSubject = new Subject();
        this.setExternalGridReadySubject = new Subject();
        this.submitDefaultProfileSubject = new Subject();
        this.submitLocationsSubject = new Subject();
        this.totalNumberOfLocations = [];
        this.numberOfDefaultLocSelected = [];
        this.numberOfSelectedPAs = [];
        this.numberOfSelectedMCs = [];
        this.numberOfSelectedCBs = [];
        this.numberOfSelectedINs = [];
        this.numberOfSelectedODs = [];
        this.totalNumberOfPAs = [];
        this.sortByChanged = new Subject();
    }
    MyProfileService.prototype.updateSortBy = function (sortBy) {
        //console.log("set save subcription", sortBy);
        this.sortByChanged.next(sortBy);
    };
    MyProfileService.prototype.getSortByValue = function () {
        return this.sortByChanged.asObservable();
    };
    MyProfileService.prototype.submitDefaultProfile = function (locations) {
        //console.log("set save subcription", locations);
        this.submitDefaultProfileSubject.next(locations);
    };
    MyProfileService.prototype.submitDefaultProfileObservable = function () {
        return this.submitDefaultProfileSubject.asObservable();
    };
    MyProfileService.prototype.submitLocations = function (locations) {
        this.submitLocationsSubject.next(locations);
    };
    MyProfileService.prototype.submitLocationsObservable = function () {
        return this.submitLocationsSubject.asObservable();
    };
    MyProfileService.prototype.setSelectedLocations = function (locations) {
        this.selectedLocationSubject.next(locations);
    };
    MyProfileService.prototype.getSelectedLocations = function () {
        return this.selectedLocationSubject;
    };
    MyProfileService.prototype.setClosePopEvent = function () {
        this.closePopUpSubject.next(true);
    };
    MyProfileService.prototype.getClosePopEvent = function () {
        return this.closePopUpSubject;
    };
    MyProfileService.prototype.setDefaultLocation = function (location) {
        this.defaultLocationSubject.next(location);
    };
    MyProfileService.prototype.getDefaultLocation = function () {
        return this.defaultLocationSubject;
    };
    MyProfileService.prototype.setnewLocationAddedOrRemoved = function (isRemovedOrAdded) {
        this.newLocationAddedSubject.next(isRemovedOrAdded);
    };
    MyProfileService.prototype.getnewLocationAddedOrRemoved = function () {
        return this.newLocationAddedSubject;
    };
    MyProfileService.prototype.setNewlyAddedLocation = function (value) {
        this.newlyAddedLocation = value;
    };
    MyProfileService.prototype.getNewlyAddedLocation = function () {
        return this.newlyAddedLocation;
    };
    MyProfileService.prototype.setUserPrefernceData = function (data) {
        this.prefernceData = data;
    };
    MyProfileService.prototype.getUserPrefernceData = function () {
        return this.prefernceData;
    };
    MyProfileService.prototype.setTotalNumberOfLocations = function (locations, command) {
        if (command === void 0) { command = 'Add'; }
        if (command == 'Add') {
            if (this.totalNumberOfLocations.indexOf(locations) == -1) {
                this.totalNumberOfLocations.push(locations);
            }
        }
        else if (command == 'Remove') {
            var indexToRemove = this.totalNumberOfLocations.indexOf(locations);
            if (indexToRemove > -1) {
                this.totalNumberOfLocations.splice(indexToRemove, 1);
            }
        }
        else if (command == 'Reset') {
            this.totalNumberOfLocations = [];
        }
    };
    MyProfileService.prototype.getTotalNumberOfLocations = function () {
        return this.totalNumberOfLocations.length;
    };
    MyProfileService.prototype.setnumberOfDefaultLocSelected = function (selectedLocations, command) {
        if (command === void 0) { command = 'Add'; }
        if (command == 'Add') {
            if (this.numberOfDefaultLocSelected.indexOf(selectedLocations) == -1) {
                this.numberOfDefaultLocSelected.push(selectedLocations);
            }
        }
        else if (command == 'Remove') {
            var indexToRemove = this.numberOfDefaultLocSelected.indexOf(selectedLocations);
            if (indexToRemove > -1) {
                this.numberOfDefaultLocSelected.splice(indexToRemove, 1);
            }
        }
        else if (command == 'Reset') {
            this.numberOfDefaultLocSelected = [];
        }
    };
    MyProfileService.prototype.getnumberOfDefaultLocSelected = function () {
        return this.numberOfDefaultLocSelected.length;
    };
    // =======================================
    MyProfileService.prototype.setTotalNumberOfPAs = function (totalPAs, command) {
        if (command === void 0) { command = 'Add'; }
        if (command == 'Add') {
            if (this.totalNumberOfPAs.indexOf(totalPAs) == -1) {
                this.totalNumberOfPAs.push(totalPAs);
            }
        }
        else if (command == 'Reset') {
            this.totalNumberOfPAs = [];
        }
    };
    MyProfileService.prototype.getTotalNumberOfPAs = function () {
        return this.totalNumberOfPAs.length;
    };
    //================== PA ==================================
    MyProfileService.prototype.setnumberOfSelectedPA = function (selectedPA, command) {
        if (command === void 0) { command = 'Add'; }
        if (command == 'Add') {
            if (this.numberOfSelectedPAs.indexOf(selectedPA) == -1) {
                this.numberOfSelectedPAs.push(selectedPA);
            }
        }
        else if (command == 'Remove') {
            var indexToRemove = this.numberOfSelectedPAs.indexOf(selectedPA);
            if (indexToRemove > -1) {
                this.numberOfSelectedPAs.splice(indexToRemove, 1);
            }
        }
        else if (command == 'Reset') {
            this.numberOfSelectedPAs = [];
        }
    };
    MyProfileService.prototype.getnumberOfSelectedPA = function () {
        return this.numberOfSelectedPAs.length;
    };
    //===========================
    //================== MeetComp ==================================
    MyProfileService.prototype.setnumberOfSelectedMC = function (selectedMC, command) {
        if (command === void 0) { command = 'Add'; }
        if (command == 'Add') {
            if (this.numberOfSelectedMCs.indexOf(selectedMC) == -1) {
                this.numberOfSelectedMCs.push(selectedMC);
            }
        }
        else if (command == 'Remove') {
            var indexToRemove = this.numberOfSelectedMCs.indexOf(selectedMC);
            if (indexToRemove > -1) {
                this.numberOfSelectedMCs.splice(indexToRemove, 1);
            }
        }
        else if (command == 'Reset') {
            this.numberOfSelectedMCs = [];
        }
    };
    MyProfileService.prototype.getnumberOfSelectedMC = function () {
        return this.numberOfSelectedMCs.length;
    };
    //===========================
    //================== Charge Back ==================================
    MyProfileService.prototype.setnumberOfSelectedCB = function (selectedCB, command) {
        if (command === void 0) { command = 'Add'; }
        if (command == 'Add') {
            if (this.numberOfSelectedCBs.indexOf(selectedCB) == -1) {
                this.numberOfSelectedCBs.push(selectedCB);
            }
        }
        else if (command == 'Remove') {
            var indexToRemove = this.numberOfSelectedCBs.indexOf(selectedCB);
            if (indexToRemove > -1) {
                this.numberOfSelectedCBs.splice(indexToRemove, 1);
            }
        }
        else if (command == 'Reset') {
            this.numberOfSelectedCBs = [];
        }
    };
    MyProfileService.prototype.getnumberOfSelectedCB = function () {
        return this.numberOfSelectedCBs.length;
    };
    //===========================
    //================== Invoices ==================================
    MyProfileService.prototype.setnumberOfSelectedIN = function (selectedIN, command) {
        if (command === void 0) { command = 'Add'; }
        if (command == 'Add') {
            if (this.numberOfSelectedINs.indexOf(selectedIN) == -1) {
                this.numberOfSelectedINs.push(selectedIN);
            }
        }
        else if (command == 'Remove') {
            var indexToRemove = this.numberOfSelectedINs.indexOf(selectedIN);
            if (indexToRemove > -1) {
                this.numberOfSelectedINs.splice(indexToRemove, 1);
            }
        }
        else if (command == 'Reset') {
            this.numberOfSelectedINs = [];
        }
    };
    MyProfileService.prototype.getnumberOfSelectedIN = function () {
        return this.numberOfSelectedINs.length;
    };
    //===========================
    //================== Orders ==================================
    MyProfileService.prototype.setnumberOfSelectedOD = function (selectedOD, command) {
        if (command === void 0) { command = 'Add'; }
        if (command == 'Add') {
            if (this.numberOfSelectedODs.indexOf(selectedOD) == -1) {
                this.numberOfSelectedODs.push(selectedOD);
            }
        }
        else if (command == 'Remove') {
            var indexToRemove = this.numberOfSelectedODs.indexOf(selectedOD);
            if (indexToRemove > -1) {
                this.numberOfSelectedODs.splice(indexToRemove, 1);
            }
        }
        else if (command == 'Reset') {
            this.numberOfSelectedODs = [];
        }
    };
    MyProfileService.prototype.getnumberOfSelectedOD = function () {
        return this.numberOfSelectedODs.length;
    };
    //===========================
    MyProfileService.prototype.setPreveiousSelectedLoc = function (location) {
        this.preveiousSelectedLocation = location;
    };
    MyProfileService.prototype.getPreveiousSelectedLoc = function () {
        return this.preveiousSelectedLocation;
    };
    MyProfileService.prototype.setReloadDataAfterLangChange = function (status) {
        this.reloadDataAfterLangChange.next(status);
    };
    MyProfileService.prototype.getReloadDataAfterLangChange = function () {
        return this.reloadDataAfterLangChange.asObservable();
    };
    MyProfileService.prototype.setPrefernceClicked = function (data) {
        this.prefernceClickedSubject.next(data);
    };
    MyProfileService.prototype.getPrefernceClicked = function () {
        return this.prefernceClickedSubject.asObservable();
    };
    MyProfileService.prototype.setExternalGridReady = function (status) {
        this.setExternalGridReadySubject.next(status);
    };
    MyProfileService.prototype.getExternalGridReady = function () {
        return this.setExternalGridReadySubject.asObservable();
    };
    MyProfileService.prototype.setPrefSaveSuccessEvent = function () {
        this.orderPrefSaveSuccessSubject.next(true);
    };
    MyProfileService.prototype.getPrefSaveSuccessEvent = function () {
        return this.orderPrefSaveSuccessSubject.asObservable();
    };
    MyProfileService.ngInjectableDef = i0.defineInjectable({ factory: function MyProfileService_Factory() { return new MyProfileService(); }, token: MyProfileService, providedIn: "root" });
    return MyProfileService;
}());
export { MyProfileService };
