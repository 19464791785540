import {
    AppRegions,
    PropConf,
    InfoCenter,
    InfoCenterControl,
    ProductSrBrowse,
    KCPConnections,
    Message
} from '@app/Modules/Shared/DataModels/SharedDataModel';
import { efileHistoryLegacySite, historicPricingLinkMC, CMTReportsURL, efileHistoryLegacySiteEMEA } from './appLink';

let appRegions: Array<AppRegions> = [
    {
        label: "English",
        country: "United States",
        value: { imagename: "226-united-states.svg", regionCode: "en" }
    }
];

let profession: any =
{
    "NA": {
        "listItem": [
            { label: "dataConfigData.profession.profession1", value: "NA - KC Professional" },
            { label: "Option 2", value: "Option 2" },
            { label: "Option 2 Long", value: "Option 2 Long" },
            { label: "Option 2 Longer", value: "Option 2 Longer" },
            { label: "Option 2 Longest", value: "Option 2 Longest" }
        ]
    },
    "LAO": {
        "listItem": [
            { label: "dataConfigData.profession.profession1", value: "LAO - KC Professional" },
            { label: "Option 2", value: "Option 2" },
            { label: "Option 2 Long", value: "Option 2 Long" },
            { label: "Option 2 Longer", value: "Option 2 Longer" },
            { label: "Option 2 Longest", value: "Option 2 Longest" }
        ]
    }
}

let transactionCenter: Array<InfoCenter> = [
    {
        id: "TC_Order_Management",
        label: "Order Management",
        icon: "fas fa-check-double"

    },
    {
        id: "TC_Price_Agreements",
        label: "Price Agreements",
        icon: "fas fa-list"

    },
    {
        id: "TC_Chargebac_ Coupons",
        label: "Chargeback Coupon Center",
        icon: "fas fa-credit-card"

    },
    {
        id: "TC_Chargeback_Coupons",
        label: "Chargeback Management",
        icon: "fas fa-credit-card"

    },
    {
        id: "TC_Dispensers",
        label: "Dispensers",
        icon: "fas fa-warehouse",

    },
    {
        id: "TC_Policies",
        label: "Policies",
        icon: "fas fa-stamp",

    },
    {
        id: "TC_EDI",
        label: "EDI",
        icon: "fas fa-clipboard",

    },
    {
        id: "TC_Invoice_Price_Download",
        label: "Invoice Price Download",
        icon: "fas fa-search-dollar",

    },
    {
        id: "TC_Meet_Comps",
        label: "Meet Comps",
        icon: "fas fa-handshake",

    },
    {
        id: "TC_Overage_Shortage_Damages",
        label: "Overage Shortage & Damages",
        icon: "fas fa-directions",

    },
    {
        id: "TC_Rebate_Price",
        label: "Rebate Price",
        icon: "fas fa-undo-alt",

    }
];

let learningCenter: Array<InfoCenter> = [
    {
        id: "LC_Product_INFO",
        label: "Product INFORMATION",
        icon: "fas fa-info-circle"

    },
    {
        id: "LC_KCP_CONNECTION",
        label: "KCP CONNECTION",
        icon: "fas fa-exchange-alt"
    },
    {
        id: "LC_EDI",
        label: "EDI",
        icon: "fas fa-clipboard"
    },
    {
        id: "LC_KCP_ADVANTAGE",
        label: "KCP ADVANTAGE",
        icon: "fas fa-star-of-life"
    },
    {
        id: "LC_Product_Catalog_Download",
        label: "PRODUCT CATALOG DOWNLOAD",
        icon: "fas fa-scroll"
    }
];

let tranactionCenterControl: Array<InfoCenterControl> = [
    { id: "TC_Order_Management", display: true },
    { id: "TC_Price_Agreements", display: true },
    { id: "TC_Chargebac_Coupons", display: true },
    { id: "Dispensers", display: true },
    { id: "Policies", display: true },
    { id: "EDI", display: true },
    { id: "Invoice_Price_Download", display: true },
    { id: "Meet_Comps", display: true },
    { id: "Overage_Shortage_Damages", display: true }
];

let learningCenterControl: Array<InfoCenterControl> = [
    { id: "Product_INFO", display: true },
    { id: "KCP_CONNECTION", display: true },
    { id: "EDI", display: true },
    { id: "KCP_ADVANTAGE", display: true }
];


let appMenu: any = [
    {
        id: "",
        label: "Home",
        enabled: true,
        visibility: false,
        hasChild: false,
        expanded: false,
        uri: "/"
    },
    {
        id: "",
        label: "Learning Center",
        enabled: true,
        visibility: false,
        hasChild: true,
        expanded: false,
        uri: "",
        menu: [
            {
                id: "",
                label: "Product Information",
                enabled: true,
                visibility: false,
                hasChild: false,
                expanded: false,
                uri: "#"
            },
            {
                id: "",
                label: "Your Messages",
                enabled: true,
                visibility: false,
                hasChild: false,
                expanded: false,
                uri: "#"
            },
            {
                id: "",
                label: "KCP Connection",
                enabled: true,
                visibility: false,
                hasChild: false,
                expanded: false,
                uri: "#"
            },
            {
                id: "",
                label: "EDI Center",
                enabled: true,
                visibility: false,
                hasChild: false,
                expanded: false,
                uri: "#"
            },
            {
                id: "",
                label: "KCP Advantage",
                enabled: true,
                visibility: false,
                hasChild: false,
                expanded: false,
                uri: "#"
            }
        ]
    },
    {
        id: "",
        label: "Market Center",
        enabled: true,
        visibility: false,
        hasChild: true,
        expanded: false,
        uri: "",
        menu: [
            {
                id: "",
                label: "Customer Promotions Center",
                enabled: true,
                visibility: false,
                hasChild: true,
                expanded: false,
                uri: "#",
                menu: [
                    {
                        id: "",
                        label: "Customer Promotions",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    }
                ]
            }
        ]
    },
    {
        id: "",
        label: "Transaction Center",
        enabled: true,
        visibility: false,
        hasChild: true,
        expanded: false,
        uri: "",
        menu: [
            {
                id: "",
                label: "Order Management",
                enabled: true,
                visibility: false,
                hasChild: true,
                expanded: false,
                uri: "",
                menu: [
                    {
                        id: "",
                        label: "Order Browse",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Mill Of Origin Wizard",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Mill Of Origin Matrix",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Invoice browse",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Product Catalog Browse",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Order Online",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Shipping Discrepency Document",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Overstock Return Document",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "New!! Truckload",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    }
                ]
            },
            {
                id: "",
                label: "Price Agreements",
                enabled: true,
                visibility: false,
                hasChild: true,
                expanded: false,
                uri: "",
                menu: [
                    {
                        id: "",
                        label: "Price Agreements Browse",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "/priceagreement"
                    }
                ]
            },
            {
                id: "",
                label: "Chargeback Coupon Center",
                enabled: true,
                visibility: false,
                hasChild: true,
                expanded: false,
                uri: "",
                menu: [
                    {
                        id: "",
                        label: "Chargeback Coupons Browse",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Web Entry",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "eFile",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    }
                ]
            },
            {
                id: "",
                label: "Dispensers",
                enabled: true,
                visibility: false,
                hasChild: true,
                expanded: false,
                uri: "",
                menu: [
                    {
                        id: "",
                        label: "Dispensers Browse",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Dispenser Pricing",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Dispenser Key Cross Reference",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Loyalty Agreement Program Overview",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Loyalty Agreement Entry / Browse",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    }
                ]
            },
            {
                id: "",
                label: "Policy",
                enabled: true,
                visibility: false,
                hasChild: true,
                expanded: false,
                uri: "#",
                menu: [
                    {
                        id: "",
                        label: "Policy Center",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    }
                ]
            },
            {
                id: "",
                label: "EDI",
                enabled: true,
                visibility: false,
                hasChild: true,
                expanded: false,
                uri: "",
                menu: [
                    {
                        id: "",
                        label: "General Information",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Setup guide",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Contact Information",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    }
                ]
            },
            {
                id: "",
                label: "Invoice Prices",
                enabled: true,
                visibility: false,
                hasChild: true,
                expanded: false,
                uri: "#",
                menu: [
                    {
                        id: "",
                        label: "Invoice Price Download",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    }
                ]
            },
            {
                id: "",
                label: "Meet Comp Center",
                enabled: true,
                visibility: false,
                hasChild: true,
                expanded: false,
                uri: "#",
                menu: [
                    {
                        id: "",
                        label: "Meet Comp Browse",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    }
                ]
            },
            {
                id: "",
                label: "Overage Shortages & Damages",
                enabled: true,
                visibility: false,
                hasChild: true,
                expanded: false,
                uri: "",
                menu: [
                    {
                        id: "",
                        label: "Shipping Discrepancy Document",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    },
                    {
                        id: "",
                        label: "Overstock Return Document",
                        enabled: true,
                        visibility: false,
                        hasChild: false,
                        expanded: false,
                        uri: "#"
                    }
                ]
            }
        ]
    },
    {
        id: "",
        label: "Site Administration",
        enabled: true,
        visibility: false,
        hasChild: true,
        uri: "",
        menu: [
            {
                id: "",
                label: "Change Password",
                enabled: true,
                visibility: false,
                hasChild: false,
                expanded: false,
                uri: "#"
            },
            {
                id: "",
                label: "Forgot Password",
                enabled: true,
                visibility: false,
                hasChild: false,
                expanded: false,
                uri: "#"
            },
            {
                id: "",
                label: "manage users",
                enabled: true,
                visibility: false,
                hasChild: false,
                expanded: false,
                uri: "#"
            },
            {
                id: "",
                label: "Portal Usage Report",
                enabled: true,
                visibility: false,
                hasChild: false,
                expanded: false,
                uri: "#"
            },
            {
                id: "",
                label: "Site Administrator Manual",
                enabled: true,
                visibility: false,
                hasChild: false,
                expanded: false,
                uri: "#"
            }
        ]
    }
];

//below needs to be translated // neha: not used any more as data is coming from announcementdteials api
let KCPConnection: Array<KCPConnections> = [
    {
        CP_Business_Area: "2811",
        CP_Dist_Ann_ID: "740",
        CP_RecipientID: "2811",
        CP_EffectiveDate: "2019-01-07 00:00:00.0",
        //CP_Title: "dataConfigData.KCPConnection.CP_Title1",
        CP_Title: "test Dist ann during KT",
        RowIndex: 1
    },
    {
        CP_Business_Area: "2811",
        CP_Dist_Ann_ID: "719",
        CP_RecipientID: "2831",
        CP_EffectiveDate: "2015-01-12 00:00:00.0",
        //CP_Title: "dataConfigData.KCPConnection.CP_Title2",
        CP_Title: "E2 Foam Soap Name Change & Expansion Into Canada",
        RowIndex: 2
    },
    {
        CP_Business_Area: "2811",
        CP_Dist_Ann_ID: "717",
        CP_RecipientID: "2831",
        CP_EffectiveDate: "2014-11-24 00:00:00.0",
        //CP_Title: "dataConfigData.KCPConnection.CP_Title3",
        CP_Title: "Mill of Origin Effective January 1, 2015",
        RowIndex: 3
    },
    {
        CP_Business_Area: "2811",
        CP_Dist_Ann_ID: "716",
        CP_RecipientID: "2831",
        CP_EffectiveDate: "2014-11-12 00:00:00.0",
        //CP_Title: "dataConfigData.KCPConnection.CP_Title4",
        CP_Title: "2014 Holiday and Year-End Shipping Schedule",
        RowIndex: 4
    },
    {
        CP_Business_Area: "2811",
        CP_Dist_Ann_ID: "707",
        CP_RecipientID: "2831",
        CP_EffectiveDate: "2014-10-14 00:00:00.0",
        //CP_Title:"dataConfigData.KCPConnection.CP_Title5",
        CP_Title: "Manufacturer’s Suggested Retail Pricing for KCP’s Industrial Welding Products",
        RowIndex: 5
    }
];

//below needs to be translated // Already existing in JSON
let activeCount = {
    Id: "activeCount",
    URL: "/priceagreement/dashboard/activefuture",
    Numberic_Details: "2,956",
    Description: "Price Agreement",
    DescriptionId: "priceAgreement",
    priceAgrements: "priceAgreements",
    QuestionMark: true,
    Tooltip: "Updated in the last 7 days",
    TooltipId: "activeFuture",
    DataSection: "padashactivefuture",
    DataItem: "tileactivefuture"
};

//below needs to be translated // Already existing in JSON
let recentlyChangedCountNA = {
    Id: "recentlyChangedCount",
    URL: "/priceagreement/dashboard/recentlychanged",
    Numberic_Details: "15",
    Description: "Price Agreement",
    DescriptionId: "priceAgreement",
    priceAgrements: "priceAgreementsitems",
    QuestionMark: true,
    Tooltip: "Updated in the last 7 days",
    TooltipId: "updatedNA",
    DataSection: "padashrecentlychanged",
    DataItem: "tilerecentlychanged"
}

let recentlyChangedCount = {
    Id: "recentlyChangedCount",
    URL: "/priceagreement/dashboard/recentlychanged",
    Numberic_Details: "15",
    Description: "Price Agreement",
    DescriptionId: "priceAgreement",
    priceAgrements: "priceAgreementsitems",
    QuestionMark: true,
    Tooltip: "Updated in the last 7 days",
    TooltipId: "updated",
    DataSection: "padashrecentlychanged",
    DataItem: "tilerecentlychanged"
}

//below needs to be translated // Already existing in JSON
let expiringSoonCount = {
    Id: "expiringSoonCount",
    URL: "/priceagreement/dashboard/expiringsoon",
    Numberic_Details: "24",
    Description: "Price Agreement",
    DescriptionId: "priceAgreement",
    priceAgrements: "priceAgreementsitems",
    QuestionMark: true,
    Tooltip: "Expiring in next 30 days",
    TooltipId: "expiring",
    DataSection: "padashexpiringsoon",
    DataItem: "tileexpiringsoon"
}

//below needs to be translated // Already existing in JSON
let recentlyExpiredCount =
{
    Id: "recentlyExpiredCount",
    URL: "/priceagreement/dashboard/recentlyexpired",
    Numberic_Details: "57",
    Description: "Price Agreement",
    DescriptionId: "priceAgreement",
    priceAgrements: "priceAgreementsitems",
    QuestionMark: true,
    Tooltip: "Expired in the last 30 days",
    TooltipId: "expired",
    DataSection: "padashrecentlyexpired",
    DataItem: "tilerecentlyexpired"
}

//below needs to be translated // Already existing in JSON
let endUserCount = {
    Id: "endUserCount",
    URL: "/priceagreement/dashboard/enduseradmin/requests",
    Numberic_Details: "57",
    Description: "RECENTLY REQUESTED",
    DescriptionId: "recentlyRequested",
    priceAgrements: "recentlyRequested",
    QuestionMark: false,
    TooltipId: "endUser",
    DataSection: "padashenduser",
    DataItem: "tileendusers"
};

//below needs to be translated // Already existing in JSON
let paFavoritesCount = {

    Id: "paFavoritesCount",
    URL: "/priceagreement/mypafavorite",
    Numberic_Details: "57",
    Description: "FAVORITES",
    DescriptionId: "favorites",
    priceAgrements: "priceAgreements",
    QuestionMark: false,
    DataSection: "padashfavorites",
    DataItem: "tilepafavorites"
}

//Added for Chargeback

let recentActivityCount = {
    Id: "recentActivityCount",
    URL: "/chargeback/dashboard/recentlychanged",
    Numberic_Details: "16",
    Description: "STATUS CHANGES",
    DescriptionId: "statusChange",
    chargeBack: "recentActivity",
    QuestionMark: true,
    TooltipId: "recentActivityTtp"
};

let pendingCount = {
    Id: "pendingCount",
    URL: "/priceagreement/dashboard/enduseradmin/requests",
    Numberic_Details: "3",
    Description: "CLAIMS",
    DescriptionId: "claim",
    chargeBack: "pending",
    QuestionMark: true,
    TooltipId: "pendingTtp"
};

let issuedCount = {
    Id: "issuedCount",
    URL: "/priceagreement/dashboard/enduseradmin/requests",
    Numberic_Details: "24",
    Description: "CHARGEBACK COUPONS",
    DescriptionId: "chargeBackCpn",
    chargeBack: "issued",
    QuestionMark: true,
    TooltipId: "issuedTtp"
};

let deniedCount = {
    Id: "deniedCount",
    URL: "/priceagreement/dashboard/enduseradmin/requests",
    Numberic_Details: "9",
    Description: "CHARGEBACK COUPONS",
    DescriptionId: "chargeBackCpn",
    chargeBack: "denied",
    QuestionMark: true,
    TooltipId: "deniedTtp"
};

let unclaimedCount = {
    Id: "unclaimedCount",
    URL: "",
    Numberic_Details: "10",
    Description: "CHARGEBACK COUPONS",
    DescriptionId: "claimPeriods",
    chargeBack: "unclaimed",
    QuestionMark: true,
    TooltipId: "unclaimedTtp"
};


//Added for Chargeback---end

let DashBoardTiles: any = {
    "NA": [activeCount, recentlyChangedCountNA, expiringSoonCount, recentlyExpiredCount, endUserCount, paFavoritesCount],
    "LAO": [activeCount, recentlyChangedCount, expiringSoonCount, recentlyExpiredCount, paFavoritesCount],
    "EMEA": [activeCount, recentlyChangedCount, expiringSoonCount, recentlyExpiredCount, paFavoritesCount]
};

//Added for Chargeback

let DashBoardTilesCB: any = {
    "NA": [recentActivityCount, pendingCount, issuedCount, deniedCount],
    "EMEA": [recentActivityCount, pendingCount, issuedCount, deniedCount, unclaimedCount]
};

let dashboardTilesGridDropdownCB = [
    { label: 'dataConfigData.dashboardTilesGridDropdown.past7Days', value: '7' },
    { label: 'dataConfigData.dashboardTilesGridDropdown.past30Days', value: '30' },
    { label: 'dataConfigData.dashboardTilesGridDropdown.past60Days', value: '60' },
    { label: 'dataConfigData.dashboardTilesGridDropdown.past90Days', value: '90' },
    { label: 'dataConfigData.dashboardTilesGridDropdown.past180Days', value: '180' }
];

let dashboardTilesGridDropdownCBEMEA = [
    { label: 'dataConfigData.dashboardTilesGridDropdown.past7Days', value: '1' },
    { label: 'dataConfigData.dashboardTilesGridDropdown.past30Days', value: '2' },
    { label: 'dataConfigData.dashboardTilesGridDropdown.past60Days', value: '3' },
    { label: 'dataConfigData.dashboardTilesGridDropdown.past90Days', value: '4' },
    { label: 'dataConfigData.dashboardTilesGridDropdown.past180Days', value: '5' }
];

//Added for Chargeback---end

let recentSearchesData: any = [
    {
        Title: '1',
        End_User: '1',
        Last_Changes: '1/1/2019 to 1/4/2019',
        Type: 'Local',
        Saved: '1/1/2019'
    },
    {
        Title: '2',
        End_User: '2',
        Last_Changes: '1/1/2019 to 1/4/2019',
        Type: 'Local',
        Saved: '1/1/2019'
    },
    {
        Title: 'Test3',
        End_User: '3',
        Last_Changes: '1/1/2019 to 1/4/2019',
        Type: 'Local',
        Saved: '1/1/2019'
    }
]


let SavedSearchesData: any = [
    {
        Title: '',
        End_User: '',
        Last_Changes: '1/1/2019 to 1/4/2019',
        Type: 'Local',
        Saved: '1/1/2019'
    },
    {
        Title: '',
        End_User: '123456',
        Last_Changes: '1/1/2019 to 1/4/2019',
        Type: 'Local',
        Saved: '1/1/2019'
    },
    {
        Title: 'Test3',
        End_User: '123457',
        Last_Changes: '1/1/2019 to 1/4/2019',
        Type: 'Local',
        Saved: '1/1/2019'
    },
    {
        Title: 'Test4',
        End_User: '123458',
        Last_Changes: '1/1/2019 to 1/4/2019',
        Type: 'Local',
        Saved: '1/1/2019'
    },
    {
        Title: 'Test5',
        End_User: '123459',
        Last_Changes: '1/1/2019 to 1/4/2019',
        Type: 'Local',
        Saved: '1/1/2019'
    },
    {
        Title: 'Test6',
        End_User: '123459',
        Last_Changes: '1/1/2019 to 1/4/2019',
        Type: 'Local',
        Saved: '1/1/2019'
    },
    {
        Title: 'Test7',
        End_User: '123459',
        Last_Changes: '1/1/2019 to 1/4/2019',
        Type: 'Local',
        Saved: '1/1/2019'
    },
    {
        Title: 'Test8',
        End_User: '123459',
        Last_Changes: '1/1/2019 to 1/4/2019',
        Type: 'Local',
        Saved: '1/1/2019'
    },
    {
        Title: 'Test9',
        End_User: '123459',
        Last_Changes: '1/1/2019 to 1/4/2019',
        Type: 'Local',
        Saved: '1/1/2019'
    },
    {
        Title: 'Test10',
        End_User: '123459',
        Last_Changes: '1/1/2019 to 1/4/2019',
        Type: 'Local',
        Saved: '1/1/2019'
    },
    {
        Title: 'Test11',
        End_User: '123459',
        Last_Changes: '1/1/2019 to 1/4/2019',
        Type: 'Local',
        Saved: '1/1/2019'
    },
];

let dashboardRibbonmenu = {
    id: "1",
    label: "PAHomeLabel",
    icon: "fas fa-compass",
    uri: "/priceagreement",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "panavi",
        datanavitem: "dashboard",
        datasection: "pamydashboard",
    }
}
let dashboardExternalRibbonmenu = {
    id: "1",
    label: "dashboardLabel",
    icon: "fas fa-compass",
    uri: "/priceagreement",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "panavi",
        datanavitem: "dashboard",
        datasection: "pamydashboard",
    }
}
let notificationRibbonmenu = {
    id: "2",
    label: "notificationLabel",
    icon: "fas fa-envelope-open-text",
    uri: "/priceagreement/myNotifications",
    hasChild: false,
    gtmconfig: {
        datanavi: "panavi",
        datanavitem: "notifications",
        datasection: "panotifications"
    }
}
let workspaceRibbonmenu = {
    id: "3",
    label: "workspaceLabel",
    icon: "fas fa-list-alt",
    uri: "/priceagreement/mysearches",
    hasChild: true,
    //below needs translations
    menu: [
        {
            // label: "My Favorites",
            label: "dataConfigData.workspaceRibbonmenu.menu.myFavorites",
            uri: "/priceagreement/mypafavorite",
            id: "302"
        },
        {
            // label: "My Searches",
            label: "dataConfigData.workspaceRibbonmenu.menu.mySearches",
            uri: "/priceagreement/mysearches",
            id: "301"
        },
        {
            // label: "Recent Downloads",
            label: "dataConfigData.workspaceRibbonmenu.menu.recentDownloads",
            uri: "/priceagreement/recentDownload",
            id: "303"
        }
    ],
    gtmconfig: {
        datanavi: "panavi",
        datanavitem: "workspace",
        datasection: "paworkspace",
    }
}
let workspaceGlobalRibbonmenu = {
    id: "3",
    label: "workspaceLabel",
    icon: "fas fa-list-alt",
    uri: "/priceagreement/mypafavorite",
    hasChild: true,
    //below needs translations
    menu: [
        {
            // label: "My Searches",
            label: "dataConfigData.workspaceRibbonmenu.menu.mySearches",
            uri: "/priceagreement/mysearches",
            id: "301"
        },
        {
            // label: "My Favorites",
            label: "dataConfigData.workspaceRibbonmenu.menu.myFavorites",
            uri: "/priceagreement/mypafavorite",
            id: "302"
        },
        {
            // label: "Recent Downloads",
            label: "dataConfigData.workspaceRibbonmenu.menu.recentDownloads",
            uri: "/priceagreement/recentDownload",
            id: "303"
        }
    ],
    gtmconfig: {
        datanavi: "panavi",
        datanavitem: "workspace",
        datasection: "paworkspace",
    }
}
let rebateRibbonmenu = {
    id: "8",
    label: "rebateLabel",
    icon: "fas fa-undo-alt",
    uri: "/rebateprice",
    hasChild: false,
    gtmconfig: {
        datanavi: "panavi",
        datanavitem: "rebate",
        datasection: "parebate",
    }
}

let enduserRibbonmenu = {
    id: "4",
    label: "enduserLabel",
    icon: "fas fa-users",
    uri: "/priceagreement/enduseradmin",
    hasChild: false,
    gtmconfig: {
        datanavi: "panavi",
        datanavitem: "enduseradmin",
        datasection: "paenduseradmin"
    }
}

let downloadRibbonmenu = {
    id: "5",
    label: "downloadLabel",
    icon: "fas fa-arrow-alt-circle-down",
    uri: "/priceagreement/downloadall",
    hasChild: false,
    gtmconfig: {
        datanavi: "panavi",
        datanavitem: "downloadpa",
        datasection: "padownload"
    }
}
let searchRibbonmenu = {
    id: "6",
    label: "searchLabel",
    icon: "fas fa-search",
    uri: "/priceagreement/search",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "panavi",
        datanavitem: "search",
        datasection: "pasearch"
    }
}
let meetcompRibbonmenu = {
    id: "7",
    label: "meetcomLabel",
    icon: "fas fa-users",
    uri: "/meetcomps",
    hasChild: false,
    cvtView: true,
    customerView: false,
    menu: [],
    gtmconfig: {
        datanavi: "",
        datanavitem: "",
        datasection: "pameetcomp"
    }
}

let manageUserInternalPUmenu = {
    id: "305",
    label: "managusersLabelInternal",
    uri: "/manageusersInternal",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "",
        datanavitem: "",
        datasection: ""
    }
}

let reports = {
    id: "306",
    label: "reportsLabel",
    uri: "/reports/dashboard",
    hasChild: false,
    menu: []
}

let eFileAccess = {
    id: "307",
    label: "eFileAccessLabel",
    uri: "/efilekcfileaccess/dashboard",
    hasChild: false,
    menu: []
}

let cmtReports = {
    id: "308",
    label: "cmtReportsLabel",
    uri: CMTReportsURL,
    hasChild: false,
    menu: []
}

let manageUserExternalPUmenu = {
    id: "304",
    label: "managusersLabelExternal",
    uri: "/manageusersExternal",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "",
        datanavitem: "",
        datasection: "",
    }
}
let adminManualPUmenu = {
    id: "3",
    label: "adminmanualLabel",
    uri: "/adminmanual",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "",
        datanavitem: "",
        datasection: "",
    }
}
let usageReportsPUmenu = {
    id: "3",
    label: "usagereportsLabel",
    uri: "/portalusage",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "",
        datanavitem: "",
        datasection: ""
    }
}

let manageUsermenu = {
    id: "4",
    label: "managusersLabelExternal",
    uri: "/manageuser",
    hasChild: false,
    menu: []
}

let reportsPUmenu = {
    id: "4",
    label: "reportsLabel",
    uri: "/portalusage",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "",
        datanavitem: "",
        datasection: "",
    }
}

let usageReportsPUExternalmenu = {
    id: "1",
    label: "usagereportsLabel",
    uri: "/portalusage",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "",
        datanavitem: "",
        datasection: ""
    }
}

let usermappingPUExternalmenu = {
    id: "2",
    label: "usermappingLabel",
    uri: "/uc-mapping/dashboard/search",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "",
        datanavitem: "",
        datasection: ""
    }
}

let helpdocsmenu = {
    id: "1",
    label: "helpdocsLabel",
    uri: "/help",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "",
        datanavitem: "",
        datasection: ""
    }
}

let shippingdiscrepancymenu = {
    id: "2",
    label: "shippingdiscrepancyLabel",
    uri: "/help",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "",
        datanavitem: "",
        datasection: ""
    }
}

let overstockreturnmenu = {
    id: "3",
    label: "overstockreturnLabel",
    uri: "/help",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "",
        datanavitem: "",
        datasection: ""
    }
}

let qualityincidentsmenu = {
    id: "4",
    label: "qualityincidentsLabel",
    uri: "/help",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "",
        datanavitem: "",
        datasection: ""
    }
}

let UCMappingUsageReports = {
    id: "1",
    label: "usageReportsLabel",
    uri: "/portalusage/dashboard",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "",
        datanavitem: "",
        datasection: ""
    }
}

let UCMapping = {
    id: "2",
    label: "ucMapping",
    uri: "/uc-mapping/dashboard/search",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "",
        datanavitem: "",
        datasection: ""
    }
}

let EFileKCFileAccessmenu = {
    id: "304",
    label: "eFileKCFileAccesLabel",
    uri: "",
    hasChild: false,
    menu: []
}

// below are ribbonmenu items for PA
let ribbonMenuExternal = {
    "LAO": [dashboardExternalRibbonmenu, notificationRibbonmenu, workspaceRibbonmenu, downloadRibbonmenu, searchRibbonmenu],
    "NA": [dashboardExternalRibbonmenu, notificationRibbonmenu, workspaceRibbonmenu, enduserRibbonmenu, downloadRibbonmenu, searchRibbonmenu],
    "EMEA": [dashboardExternalRibbonmenu, notificationRibbonmenu, workspaceRibbonmenu, rebateRibbonmenu, downloadRibbonmenu, searchRibbonmenu]
}
let ribbonMenuGlobal = {
    "LAO": [dashboardExternalRibbonmenu, notificationRibbonmenu, workspaceGlobalRibbonmenu, downloadRibbonmenu, searchRibbonmenu],
    "NA": [dashboardExternalRibbonmenu, notificationRibbonmenu, workspaceGlobalRibbonmenu, enduserRibbonmenu, downloadRibbonmenu, searchRibbonmenu],
    "EMEA": [dashboardExternalRibbonmenu, notificationRibbonmenu, workspaceGlobalRibbonmenu, rebateRibbonmenu, downloadRibbonmenu, searchRibbonmenu]
};
let ribbonMenuInternalCVT = {
    "LAO": [dashboardRibbonmenu, workspaceRibbonmenu, meetcompRibbonmenu, searchRibbonmenu],
    "NA": [dashboardRibbonmenu, workspaceRibbonmenu, meetcompRibbonmenu, searchRibbonmenu],
    "EMEA": [dashboardRibbonmenu, workspaceRibbonmenu, meetcompRibbonmenu, searchRibbonmenu]
};
let ribbonMenuInternalCustomer = {
    "LAO": [dashboardRibbonmenu, workspaceRibbonmenu, downloadRibbonmenu, searchRibbonmenu],
    "NA": [dashboardRibbonmenu, workspaceRibbonmenu, downloadRibbonmenu, searchRibbonmenu],
    "EMEA": [dashboardRibbonmenu, workspaceRibbonmenu, rebateRibbonmenu, downloadRibbonmenu, searchRibbonmenu]
};
// above are ribbonmenu items for PA

//below menus need to confirm beacuse used in help components
let ribbonMenuExternalPUCustomer = [manageUserExternalPUmenu, usageReportsPUmenu, adminManualPUmenu];
let ribbonMenuInternalPU = {
    "NA": [usageReportsPUExternalmenu, reports, cmtReports, usermappingPUExternalmenu, manageUserInternalPUmenu],
    "LAO": [usageReportsPUExternalmenu, usermappingPUExternalmenu, manageUserInternalPUmenu],
    "EMEA": [usageReportsPUExternalmenu, usermappingPUExternalmenu, manageUserInternalPUmenu],
};
let ribbonMenuInternalHelp = [helpdocsmenu, shippingdiscrepancymenu, overstockreturnmenu, qualityincidentsmenu];
//above menus need to confirm beacuse used in help components


// in below manageUserInternalPUmenu and manageUserExternalPUmenu has same navigation just different labels
let ribbonMenuSiteAdminInternal = {
    "NA": [UCMappingUsageReports, reports, cmtReports, UCMapping, eFileAccess],
    "LAO": [manageUserInternalPUmenu, UCMappingUsageReports, UCMapping],
    "EMEA": [UCMappingUsageReports, UCMapping]
};
let ribbonMenuSiteAdminExternal = {
    "NA": [manageUserExternalPUmenu, UCMappingUsageReports, adminManualPUmenu],
    "LAO": [UCMappingUsageReports],
    "EMEA": [manageUsermenu, UCMappingUsageReports, adminManualPUmenu]

    //UCMappingUsageReports - 1 , manageUserExternalPUmenu - 304, adminManualPUmenu - 2, UCMapping -2
};

// let siteAdminMenus = {
//   "NA": {
//     "UCMapping_Internal": [],
//     "Reports_Internal": [],
//     "AccessManagement_Internal":[],
//     "PortalUsage_Internal": [],
//     "PortalUsage_External": [],
//     "PortalUsage_Global": []
//   }
// }

let padetailsRibbonMenu = [
    {
        id: "8",
        label: "detailsLable",
        icon: "fas fa-list",
        uri: "#",
        hasChild: false,
        datanavitem: "padetails",
        region: ['NA', 'LAO', 'EMEA']
    },
    {
        id: "9",
        label: "addenduserLabel",
        icon: "fas fa-user-plus",
        uri: "/enduseradmin/requests/create/createenduser",
        // uri: "/enduseradmin",
        hasChild: false,
        region: ['NA'],
        datanavitem: "addenduser"
    },
    {
        id: "10",
        label: "addnotesLabel",
        icon: "far fa-edit",
        uri: "#",
        hasChild: false,
        datanavitem: "addnote",
        region: ['NA', 'LAO', 'EMEA']
    },
    {
        id: "13",
        label: "downloadSelection",
        icon: "fas fa-arrow-alt-circle-down",
        uri: "#",
        hasChild: false,
        datanavitem: "downloadSelection",
        region: ['NA', 'LAO', 'EMEA']

    }
    // {
    //   id: "11",
    //   label: "downloadExcelLabel",
    //   icon: "far fa-file-excel",
    //   uri: "#",
    //   hasChild: false,
    //   region: ['NA'],
    //    datanavitem: "downloadexcel"
    // },
    // {
    //   id: "12",
    //   label: "downloadPDFLabel",
    //   icon: "far fa-file-pdf",
    //   // uri: "download",
    //   hasChild: false,
    //   region: ['NA'],
    //    datanavitem: "downloadpdf"
    // }
];


let displayLine = [
    { label: 'dataConfigData.displayLine.label1', value: 1 },
    { label: 'dataConfigData.displayLine.label2', value: 2 },
    { label: 'dataConfigData.displayLine.label3', value: 3 }
];

let pastatus = [
    { label: 'dataConfigData.pastatus.Active_key', value: 1 },
    { label: 'dataConfigData.pastatus.Expired_key', value: 2 },
    { label: 'dataConfigData.pastatus.Future_key', value: 3 }
];

let patype = [
    { label: 'dataConfigData.patype.2party', value: '2P' },
    { label: 'dataConfigData.patype.3party', value: '3P' },
    { label: 'dataConfigData.patype.businessType', value: 'BT' },
    { label: 'dataConfigData.patype.corporateAgreement', value: 'CA' },
    { label: 'dataConfigData.patype.deviatedPrice', value: 'RP' },
    { label: 'dataConfigData.patype.directSales', value: 'DS' },
    { label: 'dataConfigData.patype.GPO_Courtesy', value: 'GC' },
    { label: 'dataConfigData.patype.GPO_National', value: 'GN' },
    { label: 'dataConfigData.patype.local', value: 'LA' },
    { label: 'dataConfigData.patype.national', value: 'NA' },
    { label: 'dataConfigData.patype.redistribution', value: 'RE' },
    { label: 'dataConfigData.patype.regional', value: 'RA' }
];


let patypecustomer = [
    { label: 'dataConfigData.patypecustomer.2party', value: '2P' },
    { label: 'dataConfigData.patypecustomer.3party', value: '3P' },
    { label: 'dataConfigData.patypecustomer.deviatedPrice', value: 'RP' },
    { label: 'dataConfigData.patypecustomer.directSales', value: 'DS' },
    { label: 'dataConfigData.patypecustomer.GPO_Courtesy', value: 'GC' },
    { label: 'dataConfigData.patypecustomer.GPO_National', value: 'GN' },
    { label: 'dataConfigData.patypecustomer.local', value: 'LA' },
    { label: 'dataConfigData.patypecustomer.national', value: 'NA' },
    { label: 'dataConfigData.patypecustomer.redistribution', value: 'RE' },
    { label: 'dataConfigData.patypecustomer.regional', value: 'RA' }
];


let endUserAdminPAType = [
    { label: 'dataConfigData.endUserAdminPAType.GPO_Courtesy', value: 'GC' },
    { label: 'dataConfigData.endUserAdminPAType.GPO_National', value: 'GN' },
    { label: 'dataConfigData.endUserAdminPAType.local', value: 'LA' },
    { label: 'dataConfigData.endUserAdminPAType.national', value: 'NA' },
    { label: 'dataConfigData.endUserAdminPAType.regional', value: 'RA' }
];

let favoritepa = ["0030203419", "0030205150", "0030201541"];

let Messages: Array<Message> = [
    {
        RowIndex: 3,
        CP_NewsMessageID: "1188",
        CP_Title: "Chargeback Browse Enhancement",
        CP_StartDate: "2013-04-01 00:00:00.000",
        CP_ExpiryDate: "9999-12-31 00:00:00.000",
        CP_FilePath: "",
        CP_ImageURL: "",
        CP_NewsMessageBody: "<p><span style='color: black; font-size: 10pt'>The Chargeback Browse&nbsp;has been enhanced&nbsp;to provide customers with more options for viewing and downloading Chargebacks. </span></p>  <p><span style='font-size: 10pt'>A <strong>Download Chargebacks </strong>link has been added to the search results page which allows you to select multiple chargebacks with the ability to:</span></p>  <ul>      <li style='margin: 0in 0in 0pt; color: black'><span style='font-size: 10pt'>Export summary only of Chargeback issued into PDF</span></li>      <li style='margin: 0in 0in 0pt; color: blac'><span style='font-size: 10pt'>Export errors only details of Chargeback issued into excel </span></li>      <li style='margin: 0in 0in 0pt; color: black'><span style='font-size: 10pt'>Export full details of Chargeback issued into excel</span></li>  </ul>",
        CP_URL: "",
        CP_Bussiness_Area: "2811",
        CP_Update_Date: "2013-04-01 06:59:39.050",
        CP_Isdefault: 1
    },
    {
        RowIndex: 3,
        CP_NewsMessageID: "1001",
        CP_Title: "Second Message Heading Chargeback Browse Enhancement",
        CP_StartDate: "2013-04-01 00:00:00.000",
        CP_ExpiryDate: "9999-12-31 00:00:00.000",
        CP_FilePath: "",
        CP_ImageURL: "",
        CP_NewsMessageBody: "<p><span style='color: black; font-size: 10pt'>Second Message The Chargeback Browse&nbsp;has been enhanced&nbsp;to provide customers with more options for viewing and downloading Chargebacks. </span></p>  <p><span style='font-size: 10pt'>A <strong>Download Chargebacks </strong>link has been added to the search results page which allows you to select multiple chargebacks with the ability to:</span></p>  <ul>      <li style='margin: 0in 0in 0pt; color: black'><span style='font-size: 10pt'>Export summary only of Chargeback issued into PDF</span></li>      <li style='margin: 0in 0in 0pt; color: blac'><span style='font-size: 10pt'>Export errors only details of Chargeback issued into excel </span></li>      <li style='margin: 0in 0in 0pt; color: black'><span style='font-size: 10pt'>Export full details of Chargeback issued into excel</span></li>  </ul>",
        CP_URL: "",
        CP_Bussiness_Area: "2811",
        CP_Update_Date: "2013-04-01 06:59:39.050",
        CP_Isdefault: 1
    }
]

let refineResult = [
    { label: 'dataConfigData.refineResult.product', value: 1, region: ['NA', 'LAO', 'EMEA'] },
    { label: 'dataConfigData.refineResult.productDescription', value: 2, region: ['NA', 'LAO', 'EMEA'] },
    { label: 'dataConfigData.refineResult.productCategory', value: 3, region: ['NA'] }
];

let ProductModalResult = {
    "TotalRecCount": "1",
    "searchResult": [
        {
            "CP_Product_Display_Code": "58805",
            "CP_Hiearachy_Desc": "null",
            "CP_Product_Code": "000000000991058807",
            "CP_BUOM": "CS ",
            "CP_Product_Name": "KLEENGUARD XXXX LA",
            "CP_Color": "null",
            "CP_Short_Prod_Code": "58807",
            "CP_Alt_Product_Code": "null",
            "RowIndex": 1
        },
        {
            "CP_Product_Display_Code": "58807",
            "CP_Hiearachy_Desc": "null",
            "CP_Product_Code": "000000000991058807",
            "CP_BUOM": "CS ",
            "CP_Product_Name": "KLEENGUARD XXXX LA",
            "CP_Color": "null",
            "CP_Short_Prod_Code": "58807",
            "CP_Alt_Product_Code": "null",
            "RowIndex": 2
        },
        {
            "CP_Product_Display_Code": "58806",
            "CP_Hiearachy_Desc": "null",
            "CP_Product_Code": "000000000991058807",
            "CP_BUOM": "CS ",
            "CP_Product_Name": "KLEENGUARD XXXX LA",
            "CP_Color": "null",
            "CP_Short_Prod_Code": "58807",
            "CP_Alt_Product_Code": "null",
            "RowIndex": 3
        }
    ]
};

let kcpUpdates = [
    { changeReason: '1', value: 'kcpUpdatesUserNote.kcpUpdates.newProductAdded' },
    { changeReason: '2', value: 'kcpUpdatesUserNote.kcpUpdates.productExpired' },
    { changeReason: '3', value: 'kcpUpdatesUserNote.kcpUpdates.productPriceChanged' },
    { changeReason: '4', value: 'kcpUpdatesUserNote.kcpUpdates.productDateChanged' }
];

//below needs translations // neha : required for EU admin, api not working 
// let endUserComments = [
//   { changeReason: '1', value: 'New End User Added' },
//   { changeReason: '2', value: 'End User Expired' },
//   { changeReason: '3', value: 'End User Price Changed' },
//   { changeReason: '4', value: 'End User Date Changed' }
// ];

let endUserComments = [
    { changeReason: '1', value: 'dataConfigData.endUserComments.newEndUserAdded' },
    { changeReason: '2', value: 'dataConfigData.endUserComments.endUserExpired' },
    { changeReason: '4', value: 'dataConfigData.endUserComments.endUserDateChanged' },
    { changeReason: '7', value: 'dataConfigData.endUserComments.endUserDateExtended' },
    { changeReason: '6', value: 'dataConfigData.endUserComments.endUserDateShortened' }
];

//below needs translations // getting data from api now
let customerComments = [
    { changeReason: '1', value: 'New Customer Added' },
    { changeReason: '2', value: 'Customer Expired' },
    { changeReason: '3', value: 'Customer Price Changed' },
    { changeReason: '4', value: 'Customer Date Changed' }
];


//below needs translations //required in NA PA details customer sold to grid
let customerSoldToComments = [
    { changeReason: '1', value: 'New Customer Added' },
    { changeReason: '2', value: 'Customer Expired' },
    { changeReason: '4', value: 'Customer Date Changed' },
    { changeReason: '5', value: 'Customer Date Extended' },
    { changeReason: '6', value: 'Customer Date Shortened' }
];


//below needs translations //required in end user admin end user request frid
let euStatus = [
    { label: 'New', value: "NEW" },
    { label: 'Pending', value: "PEN" },
    { label: 'Denied', value: "DEN" },
    { label: 'Complete', value: "COM" }
];
let customerList = [
    { label: "John", value: "John" },
    { label: "Sam", value: "Sam" },
    { label: "George", value: "George" }
];

let customerType = [
    { label: "dataConfigData.customerType.soldTo", value: "0" },
    { label: "dataConfigData.customerType.hierarchyCustomer", value: "1" },
    { label: "dataConfigData.customerType.all", value: "2" },
];

let customerTypeEMEAandLAO = [
    { label: "dataConfigData.customerType.soldTo", value: "0" },
    { label: "dataConfigData.customerType.hierarchyCustomer", value: "1" },
];

let customerTypeFilter = [
    { label: "dataConfigData.customerTypeFilter.level1", value: "ZA01" },
    { label: "dataConfigData.customerTypeFilter.level2", value: "ZA02" },
    { label: "dataConfigData.customerTypeFilter.level3", value: "ZA03" },
    { label: "dataConfigData.customerTypeFilter.level4", value: "ZA04" },
    { label: "dataConfigData.customerTypeFilter.soldTo", value: "0001" },
];

let recentDownloadStatus = [
    { label: 'dataConfigData.recentDownloadStatus.processing', value: "1" },
    { label: 'dataConfigData.recentDownloadStatus.completed', value: "2" },
    { label: 'dataConfigData.recentDownloadStatus.failed', value: "3" }
]

// used in PA search cvt and pa search EU admin will do later
const selectAllText = 'Select All'

let siteAdminRoles = {
    NA: ['K-C_CustomerPortal_SiteAdminModify', 'K-C_CustomerPortal_SiteAdminReadOnly'],
    LAO: ['K-C_CustomerPortal_SiteAdminModify_LAO', 'K-C_CustomerPortal_SiteAdminReadOnly_LAO'],
    EMEA: ['K-C_CustomerPortal_SiteAdminModify_EMEA', 'K-C_CustomerPortal_SiteAdminReadOnly_EMEA']
}

let recentlychangedDropdown = [
    { label: 'dataConfigData.recentlychangedDropdown.monthToDate', value: '1' },
    { label: 'dataConfigData.recentlychangedDropdown.last1stMonth', value: '2' },
    { label: 'dataConfigData.recentlychangedDropdown.last2ndMonth', value: '3' },
    { label: 'dataConfigData.recentlychangedDropdown.last3rdMonth', value: '4' },
    { label: 'dataConfigData.recentlychangedDropdown.sinceLastLogin', value: '5' }
];

let expiringSoonDropdown = [
    { label: 'dataConfigData.expiringSoonDropdown.30Days', value: '1' },
    { label: 'dataConfigData.expiringSoonDropdown.60Days', value: '2' },
    { label: 'dataConfigData.expiringSoonDropdown.90Days', value: '3' }
];

let recentlyexpiredDropdown = [
    // { label: 'dataConfigData.recentlyexpiredDropdown.monthToDate', value: '1' },
    { label: 'dataConfigData.recentlyexpiredDropdown.last1stMonth', value: '2' },
    { label: 'dataConfigData.recentlyexpiredDropdown.last2ndMonth', value: '3' }
];

let waysToContactDropdown = [
    { label: 'dataConfigData.waysToContactDropdown.phone', value: 'Phone' },
    { label: 'dataConfigData.waysToContactDropdown.email', value: 'Email' }
];

let currency = [
    { label: 'COP', value: '$' }, //Colombia
    { label: 'CRC', value: '₡' },//Costa Rica
    { label: 'PEN', value: 'S/' },//peru
    { label: 'BRL', value: 'R$' },//brazil
    { label: 'ARS', value: '$' },//argentina
    { label: 'CAD', value: '$' },//canada
    { label: 'USD', value: '$' },//US
    { label: 'RUB', value: '₽' },//RU
    { label: 'CHF', value: '₽' },//RU
    { label: 'CZK', value: '₽' },//RU
    { label: 'DKK', value: '₽' },//RU
    { label: 'GBP', value: '₽' },//RU
    { label: 'HUF', value: '₽' },//RU
    { label: 'NOK', value: '₽' },//RU
    { label: 'PLN', value: '₽' },//RU
    { label: 'SEK', value: '₽' },//RU
    { label: 'EUR', value: '€' }//RU

];
/**
 * -----------Added for ChargeBack.--------------------
 */
let dashboardExternalRibbonmenuCB = {
    id: "1",
    label: "dashboardLabel",
    icon: "fas fa-compass 11",
    uri: "/chargeback",
    hasChild: false,
    menu: []
}


let dashboardInternalRibbonmenuCB = {
    id: "1",
    label: "internalDashboardLabel",
    icon: "fas fa-compass 22",
    uri: "/chargeback",
    hasChild: false,
    menu: []
}



let workspaceRibbonmenuCB = {
    id: "2",
    label: "workspaceLabel",
    icon: "fas fa-list-alt",
    uri: "/chargeback/mysearches",
    hasChild: false
}

let eFileRibbonmenuCB = {
    id: "3",
    label: "eFileLabel",
    icon: "fa-file-import fas",
    uri: "/chargeback/efile/dashboard",
    hasChild: false,
    menu: []
}


let eFileHistoryRibbonmenuCB = {
    id: "6",
    label: "eFileHistoryLabel",
    icon: "far fa-clock",
    uri: efileHistoryLegacySite,
    hasChild: false,
    menu: [],
    externalLink: true
}

let eFileHistoryRibbonmenuCBEMEA = {
    id: "6",
    label: "eFileHistoryLabel",
    icon: "far fa-clock",
    uri: efileHistoryLegacySiteEMEA,
    hasChild: false,
    menu: [],
    externalLink: true
}


let kcFileRibbonmenuCBInternal = {
    id: "14",
    label: "kcFileLabel",
    icon: "fa-file-import fas",
    uri: "/chargeback/kcfile/dashboard",
    hasChild: true,
    menu: [
        {
            label: "dataConfigData.kcFileRibbonmenuCB.menu.searchKcFile",
            uri: "/chargeback/kcfile/dashboard",
            id: "301"
        },
        {
            label: "dataConfigData.kcFileRibbonmenuCB.menu.downloadTemplates",
            uri: "/chargeback/kcfile/dashboard/kcfileDownloadTemplatesSection",
            id: "302"
        }
    ]
}

let kcFileRibbonmenuCBExternal = {
    id: "14",
    label: "kcFileLabel",
    icon: "fa-file-import fas",
    uri: "/chargeback/kcfile/dashboard",
    hasChild: true,
    menu: [
        {
            label: "dataConfigData.kcFileRibbonmenuCB.menu.uploadAndManageKcFile",
            uri: "/chargeback/kcfile/dashboard/kcfileSearchSection",
            id: "301"
        },
        {
            label: "dataConfigData.kcFileRibbonmenuCB.menu.downloadTemplates",
            uri: "/chargeback/kcfile/dashboard/kcfileDownloadTemplatesSection",
            id: "302"
        }
    ]
}

let searchRibbonmenuCB = {
    id: "5",
    label: "searchLabel",
    icon: "fas fa-search",
    uri: "/chargeback/search",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "cbnavi",
        datanavitem: "search",
        datasection: "cbsearch"
    }
}

let ribbonMenuExternalCB = {
    "LAO": [dashboardExternalRibbonmenuCB, workspaceRibbonmenuCB, eFileRibbonmenuCB, searchRibbonmenuCB],
    "NA": [dashboardExternalRibbonmenuCB, workspaceRibbonmenuCB, eFileRibbonmenuCB, searchRibbonmenuCB],
    "EMEA": [dashboardExternalRibbonmenuCB, workspaceRibbonmenuCB, eFileRibbonmenuCB, kcFileRibbonmenuCBExternal, searchRibbonmenuCB]
}
let ribbonMenuInternalCustomerCB = {
    "LAO": [dashboardInternalRibbonmenuCB, workspaceRibbonmenuCB, eFileRibbonmenuCB, eFileHistoryRibbonmenuCB, searchRibbonmenuCB],
    "NA": [dashboardInternalRibbonmenuCB, workspaceRibbonmenuCB, eFileRibbonmenuCB, eFileHistoryRibbonmenuCB, searchRibbonmenuCB],
    "EMEA": [dashboardInternalRibbonmenuCB, workspaceRibbonmenuCB, eFileRibbonmenuCB, kcFileRibbonmenuCBInternal, eFileHistoryRibbonmenuCBEMEA, searchRibbonmenuCB]
}
let ribbonMenuGlobalCB = {
    "LAO": [dashboardExternalRibbonmenuCB, workspaceRibbonmenuCB, eFileRibbonmenuCB, searchRibbonmenuCB],
    "NA": [dashboardExternalRibbonmenuCB, workspaceRibbonmenuCB, eFileRibbonmenuCB, searchRibbonmenuCB],
    "EMEA": [dashboardExternalRibbonmenuCB, workspaceRibbonmenuCB, eFileRibbonmenuCB, kcFileRibbonmenuCBExternal, searchRibbonmenuCB]
}


let couponDetailsCB = {
    id: "1",
    label: "couponDetailsCBLabel",
    icon: "fas fa-list-alt",
    uri: "/UI/Transaction%20Center/MeetCompBrowse.aspx?x=2",
    hasChild: false,
    menu: []
}

let downloadCouponSummaryCB = {
    id: "2",
    label: "downloadCouponSummaryCBLapel",
    icon: "fas fa-arrow-alt-circle-down",
    uri: "/chargeback/search",
    hasChild: false,
    menu: []
}

let meetCompsDetails = {
    id: "1",
    label: "meetCompsDetailsLabel",
    icon: "fas fa-list-alt",
    uri: "/UI/Transaction%20Center/MeetCompBrowse.aspx?x=2",
    hasChild: false,
    menu: []
}

let historicPricing = {
    id: "1",
    label: "historicPricingLabel",
    icon: "fas fa-clock",
    uri: historicPricingLinkMC,
    hasChild: false,
    menu: []
}

let meetCompSearch = {
    id: "2",
    label: "meetCompSearchLabel",
    icon: "fas fa-search",
    uri: "/meetcomps/dashboard/search",
    hasChild: false,
    menu: []
}

let defaultCustomer = {
    id: "1",
    label: "defaultCustomerLabel",
    icon: "fas fa-user-circle",
    uri: '/invoice-new/default-customer',
    hasChild: false,
    menu: []
}

let invoiceMyWorkSpace = {
    id: "2",
    label: "myWorkspaceLabel",
    icon: "fas fa-list-alt",
    uri: "/invoice-new/myinvoicefavorites",
    hasChild: true,
    menu: [
        {
            // label: "My Favorites",
            label: "dataConfigData.workspaceRibbonmenu.menu.myFavorites",
            uri: "/invoice-new/myinvoicefavorites",
            id: "301"
        },
        {
            // label: "My Searches",
            label: "dataConfigData.workspaceRibbonmenu.menu.mySearches",
            uri: "/invoice-new/my-searches",
            id: "302"
        },

        {
            // label: "Recent Downloads",
            label: "dataConfigData.workspaceRibbonmenu.menu.recentDownloads",
            uri: "/invoice-new/recentrequests",
            id: "303"
        }
    ]
}
let invoiceSearch = {
    id: "3",
    label: "invoiceSearchLabel",
    icon: "fas fa-search",
    uri: "/invoice-new/search",
    hasChild: false,
    menu: [],
    gtmconfig: {
        datanavi: "invoicenavi",
        datanavitem: "search",
        datasection: "invoicesearch"
    }
}

let downloadMeetCompDetails = {
    id: "2",
    label: "downloadMeetCompDetailsLabel",
    icon: "fas fa-arrow-alt-circle-down",
    uri: "/chargeback/search",
    hasChild: false,
    menu: []
}

let ribbonMenuCouponDetailsCB = {
    "LAO": [couponDetailsCB, downloadCouponSummaryCB],
    "NA": [couponDetailsCB, downloadCouponSummaryCB],
    "EMEA": [couponDetailsCB, downloadCouponSummaryCB]
}

let ribbonMenuMeetCompDetails = {
    "LAO": [meetCompsDetails, downloadMeetCompDetails],
    "NA": [meetCompsDetails, downloadMeetCompDetails]
}

let ribbonMenuMeetComp = {
    "LAO": [historicPricing, meetCompSearch],
    "NA": [historicPricing, meetCompSearch]
}

let ribbonMenuInvoice = {
    "NA": [defaultCustomer, invoiceMyWorkSpace, invoiceSearch],
    "EMEA": [defaultCustomer, invoiceMyWorkSpace, invoiceSearch]
}


let cbStatus = [
    { label: 'dataConfigData.cbStatus.Pending_key', value: "PD" },
    { label: 'dataConfigData.cbStatus.Issued_key', value: "IS" },
    { label: 'dataConfigData.cbStatus.Denied_key', value: "B" }
];
let cbErrors = [
    { label: 'dataConfigData.cbErrors.WithErrors_key', value: 1 },
    { label: 'dataConfigData.cbErrors.WithoutErrors_key', value: 2 }
];
let cbRvRb = [
    { label: 'dataConfigData.cbRvRb.Empty_key', value: "001" },
    { label: 'dataConfigData.cbRvRb.Rv_key', value: "002" },
    { label: 'dataConfigData.cbRvRb.Rb_key', value: "003" },
    { label: 'dataConfigData.cbRvRb.DirectWarehouse_key', value: "004" },
    { label: 'dataConfigData.cbRvRb.ReclamationKCPMistake_key', value: "005" },
    { label: 'dataConfigData.cbRvRb.ReclamationDistribMistake_key', value: "006" },
    { label: 'dataConfigData.cbRvRb.AuditFinding_key', value: "007" },
    { label: 'dataConfigData.cbRvRb.DispenserOnly_key', value: "008" },
];
let cbClaimRequestType = [
    { label: 'dataConfigData.cbClaimRequestType.Manual_key', value: "1" },
    { label: 'dataConfigData.cbClaimRequestType.eFile_key', value: "2" },
    { label: 'dataConfigData.cbClaimRequestType.EDI_key', value: "3" },
    { label: 'dataConfigData.cbClaimRequestType.Excel_key', value: "4" },
    { label: 'dataConfigData.cbClaimRequestType.Web_key', value: "5" },
    { label: 'dataConfigData.cbClaimRequestType.KCfile_key', value: "6" },
    { label: 'dataConfigData.cbClaimRequestType.ClaimUploadTemplate_key', value: "7" }
];

let cbDiscrepancies = [
    { label: 'dataConfigData.cbDiscrepancies.Yes_key', value: "1" },
    { label: 'dataConfigData.cbDiscrepancies.No_key', value: "0" }
];

let cbCurrency = [
    { label: 'dataConfigData.cbCurrency.CAD_key', value: "CAD" },
    { label: 'dataConfigData.cbCurrency.USD_key', value: "USD" },
    { label: 'dataConfigData.cbCurrency.COP_key', value: 'COP' }, //Colombia
    { label: 'dataConfigData.cbCurrency.CRC_key', value: 'CRC' },//Costa Rica
    { label: 'dataConfigData.cbCurrency.PEN_key', value: 'PEN' },//peru
    { label: 'dataConfigData.cbCurrency.BRL_key', value: 'BRL' },//brazil
    { label: 'dataConfigData.cbCurrency.ARS_key', value: 'ARS' },//argentina
    { label: 'dataConfigData.cbCurrency.RUB_key', value: 'RUB' },//RU
    { label: 'dataConfigData.cbCurrency.CHF_key', value: 'CHF' },//RU
    { label: 'dataConfigData.cbCurrency.CZK_key', value: 'CZK' },//RU
    { label: 'dataConfigData.cbCurrency.DKK_key', value: 'DKK' },//RU
    { label: 'dataConfigData.cbCurrency.GBP_key', value: 'GBP' },//RU
    { label: 'dataConfigData.cbCurrency.HUF_key', value: 'HUF' },//RU
    { label: 'dataConfigData.cbCurrency.NOK_key', value: 'NOK' },//RU
    { label: 'dataConfigData.cbCurrency.PLN_key', value: 'PLN' },//RU
    { label: 'dataConfigData.cbCurrency.SEK_key', value: 'SEK' },//RU
    { label: 'dataConfigData.cbCurrency.EUR_key', value: 'EUR' }//RU
];

let cbDownloadOptions = [
    { label: 'dataConfigData.cbDownloadOptions.option1', value: "1" },
    { label: 'dataConfigData.cbDownloadOptions.option2', value: "2" },
    { label: 'dataConfigData.cbDownloadOptions.option3', value: "3" },
    { label: 'dataConfigData.cbDownloadOptions.option4', value: "4" }
];

let endUserDetailsFooterTitle = [
    { label: 'agGrid.agGridRowTitle.endUserTotalCust', value: "C" },
    { label: 'agGrid.agGridRowTitle.endUserTotalKC', value: "KC" },
]

let cbEfileType = [
    { label: 'dataConfigData.cbEfileType.Active_key', value: "1" },
    { label: 'dataConfigData.cbEfileType.Test_key', value: "2" }
];
let cbEfileStatusForActive = [
    { label: 'dataConfigData.cbEfileStatusForActive.ErrorsFound_key', value: "1" },
    { label: 'dataConfigData.cbEfileStatusForActive.Processing_key', value: "3" },
    { label: 'dataConfigData.cbEfileStatusForActive.ReadyToSubmit_key', value: "4" },
    { label: 'dataConfigData.cbEfileStatusForActive.Submitted_key', value: "5" },
    { label: 'dataConfigData.cbEfileStatusForActive.Deleted_key', value: "6" }
];
let cbEfileStatusForTest = [
    { label: 'dataConfigData.cbEfileStatusForTest.ErrorsFound_key', value: "1" },
    { label: 'dataConfigData.cbEfileStatusForTest.Validated_key', value: "2" },
    { label: 'dataConfigData.cbEfileStatusForTest.Processing_key', value: "3" },
    { label: 'dataConfigData.cbEfileStatusForTest.Deleted_key', value: "6" }
];

//Added For KC-File//
let cbKcfileStatusInternalUser = [
    { label: 'dataConfigData.cbKcfileStatus.ErrorsFound_key', value: "1" },
    { label: 'dataConfigData.cbKcfileStatus.Processing_key', value: "3" },
    { label: 'dataConfigData.cbKcfileStatus.ReadyToSubmit_key', value: "4" },
    { label: 'dataConfigData.cbKcfileStatus.Submitted_key', value: "5" },
    { label: 'dataConfigData.cbKcfileStatus.Deleted_key', value: "6" }
];
let cbKcfileStatusExternalUser = [
    { label: 'dataConfigData.cbKcfileStatus.ErrorsFound_key', value: "1" },
    { label: 'dataConfigData.cbKcfileStatus.Processing_key', value: "3" },
    { label: 'dataConfigData.cbKcfileStatus.ReadyToSubmit_key', value: "4" },
    { label: 'dataConfigData.cbKcfileStatus.Submitted_key', value: "5" },
    { label: 'dataConfigData.cbKcfileStatus.Deleted_key', value: "6" }
];
//-------------------//

let accessDistinctOptions = [
    { label: 'dataConfigData.singelActionOptions.Access', value: "True" },
    { label: 'dataConfigData.singelActionOptions.NoAccess', value: "False" }
];
let refreshStatus = [
    {label: 'dataConfigData.refreshStatus.processingLabel', value: "1"},
    {label: 'dataConfigData.refreshStatus.readyToDnlLabel', value: "2"},
    {label: 'dataConfigData.refreshStatus.refReqLabel', value: "3"}
]

let distinctValueFilterSet = {
    'rvRb': cbRvRb,
    'status': cbStatus,
    'currency': cbCurrency,
    'claimRequestType': cbClaimRequestType,
    'discrepancies': cbDiscrepancies,
    'type': cbEfileType,
    'status_Efile': [...cbEfileStatusForActive, ...cbEfileStatusForTest.filter(k => (cbEfileStatusForActive.map(d => d.value)).indexOf(k.value) < 0)],
    'status_Kcfile': [...cbKcfileStatusInternalUser, ...cbKcfileStatusExternalUser.filter(k => (cbKcfileStatusInternalUser.map(d => d.value)).indexOf(k.value) < 0)],
    'status_mc': pastatus,
    'access_AC': accessDistinctOptions,
    'refreshStatus_invoice': refreshStatus
}
let cbManageErrorGridColMap = {
    "KC_ENDU": "kC_ENDU",
    "SHTO_NBR": "shtO_NBR",
    "PROD_ID": "proD_ID",
    "REF_NUM": "reF_NUM",
    "CUSTLOC": "custloc",
    "D_BRANCH": "d_BRANCH",
    "SHTO_NME": "shtO_NME",
    "SHTO_AD1": "shtO_AD1",
    "SHTO_AD2": "shtO_AD2",
    "SHTO_CTY": "shtO_CTY",
    "SHTO_ST": "shtO_ST",
    "SHTO_CTRY": "shtO_CTRY",
    "SHTO_ZIP": "shtO_ZIP",
    "D_PROD": "d_PROD",
    "INVC_DTE": "invC_DTE",
    "INVC_NBR": "invC_NBR",
    "CASES": "cases",
    "UNT_MSR": "unT_MSR",
    "ITW_PRC": "itW_PRC",
    "PA_PRC": "pA_PRC",
    "EXT_RBT": "exT_RBT",
    "PA_NBR": "pA_NBR",
    "GPO_ID": "gpO_ID",
    "BILL_TO_NBR": "bilL_TO_NBR",
    "BILL_TO_NME": "bilL_TO_NME"
}

let submitText = 'chargeback.eFile.contextMenu.submit';
let configuredWidth = 1440;
let cbMaxNoOfLinesToShowLinelevelDetailsPopup = 500;

let typeAheadFilterPlaceholder = {
    "shortProductCode": "Product # Search...",
    "customerNumber": "Customer # Search..."
}

/**
 * ------------------------------------------------
 */

//Added for e-file Kc-file Access
let bulkActionOptions = [
    { label: 'dataConfigData.bulkActionOptions.GrantAccess', value: true, disabled: false },
    { label: 'dataConfigData.bulkActionOptions.DenyAccess', value: false, disabled: false }
];

let singelActionOptions = [
    { label: 'dataConfigData.singelActionOptions.GrantAccess', value: true, customLabel: 'dataConfigData.singelActionOptions.Access' },
    { label: 'dataConfigData.singelActionOptions.DenyAccess', value: false, customLabel: 'dataConfigData.singelActionOptions.NoAccess' }
];
//

let moduleType = [
    { label: 'dataConfigData.moduleType.PA_key', value: 1 },
    { label: 'dataConfigData.moduleType.CB_key', value: 2 },
    { label: 'dataConfigData.moduleType.Rebate_key', value: 3 }
];

let languageNames = {
    cs: "CZECH",
    de: "German",
    en: "English",
    es: "Spanish",
    fr: "French",
    it: "Italian",
    nl: "Dutch",
    pl: "Polish",
    pt: "PORTUGUESE",
    ru: "RUSSIAN"
}



export {
    currency,
    selectAllText,
    KCPConnection,
    customerType,
    customerTypeFilter,
    customerTypeEMEAandLAO,
    appRegions,
    profession,
    learningCenter,
    transactionCenter,
    learningCenterControl,
    tranactionCenterControl,
    appMenu,
    DashBoardTiles,
    DashBoardTilesCB,
    SavedSearchesData,
    recentSearchesData,
    ribbonMenuInternalCVT,
    ribbonMenuInternalCustomer,
    ribbonMenuExternal,
    ribbonMenuExternalPUCustomer,
    ribbonMenuInternalPU,
    ribbonMenuInternalHelp,
    ribbonMenuGlobal,
    displayLine,
    patype,
    patypecustomer,
    pastatus,
    recentDownloadStatus,
    favoritepa,
    padetailsRibbonMenu,
    Messages,
    refineResult,
    ProductModalResult,
    kcpUpdates,
    endUserComments,
    customerComments,
    customerSoldToComments,
    endUserAdminPAType,
    euStatus,
    customerList,
    ribbonMenuSiteAdminInternal,
    ribbonMenuSiteAdminExternal,
    siteAdminRoles,
    recentlychangedDropdown,
    expiringSoonDropdown,
    recentlyexpiredDropdown,
    waysToContactDropdown,
    cbStatus,
    cbErrors,
    cbRvRb,
    cbClaimRequestType,
    ribbonMenuExternalCB,
    ribbonMenuInternalCustomerCB,
    ribbonMenuGlobalCB,
    dashboardTilesGridDropdownCB,
    dashboardTilesGridDropdownCBEMEA,
    cbDiscrepancies,
    cbDownloadOptions,
    endUserDetailsFooterTitle,
    configuredWidth,
    cbEfileStatusForActive,
    cbEfileStatusForTest,
    cbEfileType,
    ribbonMenuCouponDetailsCB,
    ribbonMenuMeetCompDetails,
    ribbonMenuMeetComp,
    ribbonMenuInvoice,
    cbMaxNoOfLinesToShowLinelevelDetailsPopup,
    distinctValueFilterSet,
    cbManageErrorGridColMap,
    submitText,
    typeAheadFilterPlaceholder,
    bulkActionOptions,
    singelActionOptions,
    moduleType,
    languageNames,
    cbKcfileStatusInternalUser,
    cbKcfileStatusExternalUser
};
