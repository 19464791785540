<div>
  <div class="form-group form-group--date-picker">
    <div class="form-group--date-picker__Single">
      <p-calendar #calendarElement [maxDate]="maxDateValue" (onClose)="onApplyClick($event)" (onSelect)="onDateSelected($event)"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:2100" [placeholder]="dateFormat"
        [dateFormat]="primengDateFormat" [(ngModel)]="txtDate" [locale]="calenderData" name="validDate_fromDate"
        appendTo="body" [baseZIndex]="100000" panelStyleClass="ag-custom-component-popup">
      </p-calendar>
      <i class="search__button fas fa-search" aria-hidden="true" (click)="onApplyClick($event)"></i>
    </div>
  </div>
</div>