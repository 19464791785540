import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { cbEfileType } from '@app/conf/dataConfigurations';

@Component({
  selector: 'app-efile-type-column',
  templateUrl: './efile-type-column.component.html',
  styleUrls: ['./efile-type-column.component.scss']
})

export class EfileTypeColumnComponent implements AgRendererComponent, OnDestroy {

  params:any;
  cbEfileType: any;
  typeValue: any;
  constructor() {
    this.cbEfileType = cbEfileType;
   }

  agInit(params: any) {
    this.params =params;
    if (params.data && params.data.type) {
      this.type();
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  type(){
    let type = this.cbEfileType.find((item) => {
      return item.value === this.params.data.type;
    });
    this.typeValue = type.label;
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }

}
