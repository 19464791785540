<span class="custom-table__popup custom-table__favorite ellipsisWrap">
  <span class="custom-table__customer custom-table__popup__trigger ellipsis"
    (mouseenter)="showCustomerInfoTooltip($event, op)" (mouseleave)="hideCustomerInfoTooltip(op)">
    {{ cellValue }}
</span>
</span>
<p-overlayPanel appendTo="body" [hideTransitionOptions]="'0ms'" [showTransitionOptions]="'500ms'" #op>
  <span class="custom-table__popup__info">
    <p>{{ cellValue }}</p>
    <p>{{ street }} {{ city }} {{ district }} {{ country }} {{ zipcode }}</p>
  </span>
</p-overlayPanel>