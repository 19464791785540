import { Component, OnInit, Input, Output, OnDestroy } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { LanguageSelectionService } from "@app/Modules/Shared/Services/language-selection.service";
import { socialCompliance } from "@app/conf/appLink";
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { LocationLanguageService } from '@app/Modules/Shared/Services/location-language.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from "../../Services/app.service";

@AutoUnsubscribe()
@Component({
  selector: "acceptancepolicymodal",
  templateUrl: "./acceptancepolicy.component.html",
  styleUrls: ["./acceptancepolicy.component.scss"]
})
export class AcceptancepolicyComponent implements OnInit, OnDestroy {
  @Input() displayPolicyModal: boolean;
  @Output() closePolicyModal = new EventEmitter();
  @Output() acceptPolicy = new EventEmitter();
  @Output() languageChanged = new EventEmitter();
  @Input() disableNotNow: boolean;
  @Input() policyContent: any;
  @Input() isAccepted: boolean;
  languages: any;
  currentLanguage: string;
  subscription: Subscription = new Subscription();
  showLanguageBar: boolean;
  country: any;
  region: any;

  constructor(
    private _languageServ: LanguageSelectionService,
    private locationLangService: LocationLanguageService,
    private _globalAppService: GlobalAppService,
    private _appService: AppService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.subscription.add(this._languageServ.currentSelected.subscribe(data => { }));
    this.region = this._globalAppService.getDefaultRegionString();
    this._globalAppService.getDefaultCountry().subscribe(country => {
      if (country) {
        this.country = country;
      }
    });
    this.showHideLanguageBar();
    // if(this.policyContent){
    //   this.showHideLanguageBar();
    // }
  }

  onShowModal(event) {
    this.showHideLanguageBar();
  }

  showHideLanguageBar() {
    this.region = this._globalAppService.getDefaultRegionString();
    let selectedLocation: any = this._globalAppService.getSelectedCountryLang();
    let defaultLanguage = this._globalAppService.getDefaultLanguageString();
    if (selectedLocation) {
      defaultLanguage = selectedLocation && selectedLocation.language ? selectedLocation.language : this._globalAppService.getDefaultLanguageString();
      this.country = selectedLocation && selectedLocation.country ? selectedLocation.country : this._globalAppService.getDefaultCountryString();;
    }

    // TESTING Purpose Only : this.showLanguageBar = (defaultLanguage == null || defaultLanguage == "en-US") ? false : true;
    this.showLanguageBar = (defaultLanguage == null || defaultLanguage.toLowerCase() == "en-" + (this.country ? this.country.toLowerCase() : '')) ? false : true;
    if (this.showLanguageBar) {
      this.currentLanguage = defaultLanguage || "en-" + this.country;
      let languagesForcountry = this.locationLangService.getLanguagesForCountry(this.region, this.country);
      console.log("languages for country", languagesForcountry, defaultLanguage);
      let defaultLanguageForcountry = languagesForcountry.filter(obj => {
        if (obj.value && defaultLanguage)
          return obj.value.toLowerCase() == defaultLanguage.toLowerCase();
        else
          return false;
      })
      this.languages = [
        {
          label: "English",
          code: "en-" + this.country
        },
        {
          label: defaultLanguageForcountry && defaultLanguageForcountry.length ? defaultLanguageForcountry[0].label : "",
          code: defaultLanguage
        }
      ];
    }

  }

  notAcceptedNow() {
    if (!this.isAccepted)
      this.closePolicyModal.emit({ accept: false, action: 'save&Close' });
    else
      this.closePolicyModal.emit({ action: 'close' });
  }

  acknowledgePolicy() {
    this.acceptPolicy.emit(true);
  }

  onLanguageChanged(languageCode) {
    this.currentLanguage = languageCode;
    this.translateService.use(this.region + '/' + languageCode.toLowerCase());
    this.languageChanged.emit(languageCode);
  }

  openCompliance() {
    window.open(socialCompliance.compliance, '_target');
  }
  ngOnDestroy() {
    //Destroying component
    this._appService.setPopUpSequenceStatus(this._appService.getPopupSequenceNumber());
  }
}
