<div class="body-wrapper">



  <header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="header">

            <div class="header__left">
              <a class="header__left__logo" routerLink="/"><img src="/assets/images/KCP-logo.png"></a>
              <h1 class="header__left__heading"><span class="bold-heading">{{ "logout.heading1" | translate }}</span>
                <span class="header__left__heading__divider"></span> {{ "logout.heading2" | translate }}</h1>
            </div>

          </div>
        </div>
      </div>
    </div>
  </header>



  <!-- <div class="shade"></div> -->



  <div class="content-area">
    <!-- content area start -->
    <div class="container-fluid">
      <div class="row justify-content-md-center">
        <div class="col-12 col-md-10 col-lg-8 col-xl-6">
          <div class="logout">
            <p class="content-area__headline content-area__headline--center">{{ "logout.heading" | translate }}</p>
            <p class="logout__description">{{ "logout.para" | translate }}</p>
            <div class="align-items-center logout__call-to-action-blocks">
              <div class="logout__call-to-action-blocks__logout">
                <a href="javascript:void(0)" (click)="login()" class="btn btn-primary">
                  {{ "logout.loginBtn" | translate }} <i class="far fa-arrow-alt-circle-right"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> <!-- content area end -->
</div> <!-- body wrapper end -->