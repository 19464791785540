<div class="contact-us-info">
   <img width="18" height="18" class="flag-class" src="assets/images/international-flags/{{ allCountryId | lowercase }}.svg">
   <p>{{ allData && allData.contactUs.customerService.country }}</p>
   <table class="info-table info-table-contacts" cellspacing="0" cellpadding="0">
      <tr
         *ngIf="(allData?.contactUs?.customerService?.phone).length >= 1 && (allData?.contactUs?.customerService?.phone)[0]">
         <td class="phone__heading"><span>{{'contactUs.details.phone' | translate}}</span></td>
         <td>
            <div *ngFor="let data of allData.contactUs.customerService.phone">{{data}}</div>
         </td>
      </tr>
      <ng-container *ngIf="!isCountryUSAorCa">
         <tr
            *ngIf="(allData?.contactUs?.chargeBack?.email) && (allData?.contactUs?.chargeBack?.email).length >= 1 && (allData?.contactUs?.chargeBack?.email)[0]">
            <td class="email__heading"><span>{{'contactUs.details.email' | translate}}</span></td>
            <td class="email__value"><a *ngFor="let data of allData.contactUs.chargeBack.email" href="">{{data}}</a></td>
         </tr>
      </ng-container>

      <!-- <tr *ngIf="allData && allData?.contactUs?.chargeBack?.url">
         <td><span>{{allData && allData.contactUs.chargeBack.label}}</span></td>
         <td><a href="mailto:{{allData && allData?.contactUs?.chargeBack?.url}}"
               class="lowercase">{{allData && allData.contactUs.chargeBack.label}}</a></td>
      </tr>
      <tr
         *ngIf="(allData?.contactUs?.CustomerPortal?.phone).length >= 1 && (allData?.contactUs?.CustomerPortal?.phone)[0]">
         <td class="vertical_top"><span>{{allData && allData.contactUs.CustomerPortal.label}}</span></td>
         <td>
            <p *ngFor="let data of allData?.contactUs?.CustomerPortal?.phone">{{data}}</p>
         </td>
      </tr>
      <tr
         *ngIf="(allData?.contactUs?.CustomerSolutions?.phone).length >= 1 && (allData?.contactUs?.CustomerSolutions?.phone)[0]">
         <td class="vertical_top"><span>{{allData && allData.contactUs.CustomerSolutions.label}}</span></td>
         <td>
            <p *ngFor="let data of allData?.contactUs?.CustomerSolutions?.phone">{{data}}</p>
         </td>
      </tr> -->

   </table>
</div>