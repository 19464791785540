import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { TranslateService } from '@ngx-translate/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { Calendar } from 'primeng/calendar';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
var DateselectoronlyComponent = /** @class */ (function () {
    function DateselectoronlyComponent(translateServ, languageServ, globalAppService, gridService) {
        this.translateServ = translateServ;
        this.languageServ = languageServ;
        this.globalAppService = globalAppService;
        this.gridService = gridService;
        this.maxDateValue = new Date();
        this.filterModel = { type: "date-selector", value: this.txtDate };
        this.subscription = new Subscription();
    }
    DateselectoronlyComponent.prototype.agInit = function (params) {
        var _this = this;
        this.params = params;
        this.region = this.globalAppService.getDefaultRegionString();
        this.colId = params.colDef.field;
        this.valueGetter = params.valueGetter;
        this.colId = this.colId.indexOf('_') !== -1 ? this.colId.substring(0, this.colId.indexOf('_')) : this.colId;
        if (this.colId !== 'endDate' && this.colId !== 'customerEndDate') {
            this.maxDateValue.setDate(this.maxDateValue.getDate());
        }
        else
            this.maxDateValue = null;
        this.subscription.add(this.languageServ.currentSelected.subscribe(function (data) {
            _this.subscription.add(_this.translateServ.get(['calender'])
                .subscribe(function (translations) {
                _this.calenderData = translations['calender'];
                _this.dateFormat = _this.calenderData.format;
                _this.primengDateFormat = _this.calenderData.dateFormat.toLowerCase();
            }));
        }));
        this.params.api.setPopupParent(document.querySelector('body'));
    };
    DateselectoronlyComponent.prototype.getModel = function () {
        this.filterModel.value = this.txtDate;
        return this.filterModel;
    };
    DateselectoronlyComponent.prototype.setModel = function (model) {
        if (model && model.value) {
            this.txtDate = this.filterModel.value = model.value;
        }
        else {
            this.txtDate = null;
            this.filterModel.value = "";
        }
    };
    DateselectoronlyComponent.prototype.isFilterActive = function () {
        return this.txtDate && this.txtDate instanceof Date;
    };
    DateselectoronlyComponent.prototype.doesFilterPass = function (params) {
        return true;
    };
    // noinspection JSMethodCanBeStatic
    DateselectoronlyComponent.prototype.componentMethod = function (message) {
        alert("Alert from DateSelectorComponent " + message);
    };
    DateselectoronlyComponent.prototype.onDateSelected = function (event) {
        this.isDateSelected = true;
    };
    DateselectoronlyComponent.prototype.onApplyClick = function (event) {
        if (this.isDateSelected) {
            this.params.filterChangedCallback();
            this.guiParams.hidePopup();
            this.isDateSelected = false;
        }
    };
    DateselectoronlyComponent.prototype.afterGuiAttached = function (params) {
        var _this = this;
        if (this.calendarElement && this.calendarElement.inputfieldViewChild)
            this.calendarElement.inputfieldViewChild.nativeElement.placeholder = this.dateFormat;
        this.guiParams = params;
        this.subscription.add(this.languageServ.currentSelected.subscribe(function (data) {
            _this.subscription.add(_this.translateServ.get(['calender'])
                .subscribe(function (translations) {
                _this.calenderData = translations['calender'];
                _this.dateFormat = _this.calenderData.format;
                _this.primengDateFormat = _this.calenderData.dateFormat.toLowerCase();
            }));
        }));
    };
    DateselectoronlyComponent.prototype.onSortRequested = function (isSortedRequest) {
        if (!!isSortedRequest) {
            this.guiParams.hidePopup();
        }
    };
    DateselectoronlyComponent.prototype.ngOnDestroy = function () {
        // Required for unsubscribing and destroying the component  
    };
    DateselectoronlyComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [TranslateService,
            LanguageSelectionService,
            GlobalAppService,
            CpAgGridService])
    ], DateselectoronlyComponent);
    return DateselectoronlyComponent;
}());
export { DateselectoronlyComponent };
