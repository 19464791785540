let endPoints_SiteAdmin_QA = {
	getReports: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/v1/Master/Report/GetReportsAsync",
		method: "POST"
	},
	// getEmailNotificationReports: {
	// 	url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v2/ReportGeneration/GetEmailNotification",
	// 	method: "POST"
	// },
	// getUserMappingListReports: {
	// 	url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v2/ReportGeneration/GetUserMappingList",
	// 	method: "POST"
	// },
	getCustomerPriceHistory: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/NA/v1/DownloadInvoice/CustomerPriceHistory",
		method: "POST"
	},
	getEfileCGBCompany: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Reports/EfileCGBCompany",
		method: "POST"
	},
	getExpectedDateToPay: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Reports/ExpectedDateToPay",
		method: "POST"
	},
	getCustomerHistory: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Reports/CustomerHistory",
		method: "POST"
	},
	getCBAcceptancePolicyList: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v2/Report/GetCBAcceptancePolicyList",
		method: "POST"
	},
	getDaysToDisplayList: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v2/Report/GetDaysToDisplayList",
		method: "POST"
	},
	// getViewPAcomment: {
	// 	url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v2/ReportGeneration/ViewPAcomment",
	// 	method: "POST"
	// },
	// getExpectedVolumeReport: {
	// 	url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v2/ReportGeneration/ExportExpectedVolumeReport",
	// 	method: "POST"
	// },

	getReportGeneric: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v2/Report/GetDownloadLinkAsync/GetDownloadLink",		
		method: "POST"
	},

	// UC mapping starts
	searchUsers: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/SearchUser",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-pdqa.br-s1.cloudhub.io/usercustomermapping/searchuser",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/SearchUser",
		method: 'GET'
	},
	getUsersAssociated: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/GetUserAssociatedToMapping",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-pdqa.br-s1.cloudhub.io/usercustomermapping/getuserassociatedtomapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/GetUserAssociatedToMapping",
		method: "POST"
	},
	removeUcMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/DeleteUserCustomerMapping",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-pdqa.br-s1.cloudhub.io/usercustomermapping/deleteusercustomermapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/UserCustomerMapping/DeleteUserCustomerMapping",
		method: "POST"
	},
	addUcMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/SaveUserCustomerMapping",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-pdqa.br-s1.cloudhub.io/usercustomermapping/saveusercustomermapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/UserCustomerMapping/SaveUserCustomerMapping",
		method: "POST"
	},
	getStates: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/v2/Master/GetState",
		url_LAO: "https://kcc-sls-ang-kcpconfig-exp-api-pdqa.br-s1.cloudhub.io/master/state",
		method: "POST"
	},
	ucMappingCustomerSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/CustomerV2/GetCustomerBySearchFields",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-pdqa.br-s1.cloudhub.io/customer/getcustomerbysearchfields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/CustomerV2/GetCustomerBySearchFields",
		method: "POST"
	},
	ucMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/GetUserCustomerMapping",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-pdqa.br-s1.cloudhub.io/usercustomermapping/getusercustomermapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/GetUserCustomerMapping",
		method: "POST"
	},
	customerMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/CustomerV2/GetCustomerHierarchy",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-pdqa.br-s1.cloudhub.io/customer/customerhierarchy",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/CustomerV2/GetCustomerHierarchy",
		method: "POST"
	},
	markAsAdmin: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/SetGroupAdmin",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-pdqa.br-s1.cloudhub.io/usercustomermapping/setgroupadmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/SetGroupAdmin",
		method: "POST"
	},
	validateCustomerGroupMapping: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/ValidateCustomerGroupMapping",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-pdqa.br-s1.cloudhub.io/usercustomermapping/ValidateCustomerGroupMapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/ValidateCustomerGroupMapping",
		method: "POST"
	},
	// UC mapping ends

	// portal usage starts
	portalUsageInternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-pdqa.br-s1.cloudhub.io/usercustomermapping/searchuserforinternaladmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		method: "POST"
	},
	portalUsageExternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/SearchUserForExternalAdmin",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-pdqa.br-s1.cloudhub.io/usercustomermapping/searchuserforexternaladmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/SearchUserForExternalAdmin",
		method: "POST"
	},
	exportPortalUsageInternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Export/SearchUserForInternalAdmin",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-pdqa.br-s1.cloudhub.io/export/searchuserforinternaladmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/export/SearchUserForInternalAdmin",
		method: "POST"
	},
	exportPortalUsageExternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Export/SearchUserForExternalAdmin",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-pdqa.br-s1.cloudhub.io/export/searchuserforexternaladmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/export/SearchUserForExternalAdmin",
		method: "POST"
	},
	// portal usage ends

	// E-File Access Start
	getEFileAccessBySearchFields: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/EFile/EFileAccessBySearchFields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/EFileAccessBySearchFields",
		method: "POST"
	},

	updateEFileAccess: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/EFile/UpdateAccess",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/UpdateAccess",
		method: "POST"
	},

	eaGetDistinctFilterSearchGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/EFile/EFileAccessSearchGridHeaderFilters",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/EFileAccessSearchGridHeaderFilters",
		method: "POST"
	},

	// E-File Access Ends

	//Manage User API Starts
	manageUsersSubmit:{
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v1/UserService/ManageUsers",
		method: "POST"
	},
	UCMSync: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/TriggerUCMSync",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/TriggerUCMSync",
		url_EMEA: "",
		method: "POST"
	}
};

export { endPoints_SiteAdmin_QA };