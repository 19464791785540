
/** This file will import the regionwise column definations and will return the column definations of
 * passed region from component.
 */
import { NA_COL_DEF } from './agColumnsConfNA';
import { LAO_COL_DEF } from './agColumnsConfLAO';
import { EMEA_COL_DEF } from './agColumnsConfEMEA';

export class RegionWiseColDefination {
  getRegionWiseColDef(region = 'LAO') {
    let columnDefinations;
    switch (region) {
      case "NA":
        columnDefinations = NA_COL_DEF;
        break;

      case "LAO":
        columnDefinations = LAO_COL_DEF;
        break;

      case "EMEA":
        columnDefinations = EMEA_COL_DEF;
        break;
    }

    let {
      customerSoldToColDef,
      sfdcColDef,
      sfdcColDefCustAndEUNumber,
      activeFutureColDef,
      recentlyChangedColDef,
      expiringSoonColDef,
      recentlyExpiredColDef,
      searchResultColDef,
      CVTResultColDef,
      myPAFavoritesColDef,
      tradeOrgColDef,
      tradeOrgCvtColDef,
      productColDefOtherThanREandFD,
      productColDefForREandFD,
      productCvtColDefOtherThanREandFD,
      productCvtColDefForREandFD,
      productColDef,
      productColDefInternal,
      endUserColDef,
      endUserColDefInternal,
      endUserCVTColDef,
      productSearchModalDef,
      custListModalDef,
      endUserSubmittedColDef,
      endUserRequestsColDef,
      endUserAdminSearchResultColDef,
      endUserSearchModalDef,
      recentDownloadColDef,
      HQModalDef,
      customerSearchColDef,
      myProfileAddLocationColDef,
      myNotificationsColDef,
      searchResultExternalColDef,
      userSearchColDef,
      delegatedUserSearchColDef,
      manageMappingsColDef,
      customerMappingsColDef,
      ucMappingCustomerSearchColDef,
      ExternallocationandMailsColDef,
      InternallocationandMailsColDef,
      dowloadCustomerListColDef,
      puSearchInternalColDef,
      puSearchExternalColDef,
      efileKcFileAccessColDef,
      reportsPageColumnsGrid,
      multipleChangedModalDef,
      rebateSearchResult
    } = columnDefinations;

    return {
      customerSoldToColDef,
      sfdcColDef,
      sfdcColDefCustAndEUNumber,
      activeFutureColDef,
      recentlyChangedColDef,
      expiringSoonColDef,
      recentlyExpiredColDef,
      searchResultColDef,
      CVTResultColDef,
      myPAFavoritesColDef,
      tradeOrgColDef,
      tradeOrgCvtColDef,
      productColDefOtherThanREandFD,
      productColDefForREandFD,
      productCvtColDefOtherThanREandFD,
      productCvtColDefForREandFD,
      productColDef,
      productColDefInternal,
      endUserColDef,
      endUserColDefInternal,
      endUserCVTColDef,
      productSearchModalDef,
      custListModalDef,
      endUserSubmittedColDef,
      endUserRequestsColDef,
      endUserAdminSearchResultColDef,
      endUserSearchModalDef,
      recentDownloadColDef,
      HQModalDef,
      customerSearchColDef,
      myProfileAddLocationColDef,
      myNotificationsColDef,
      searchResultExternalColDef,
      userSearchColDef,
      delegatedUserSearchColDef,
      manageMappingsColDef,
      customerMappingsColDef,
      ucMappingCustomerSearchColDef,
      ExternallocationandMailsColDef,
      InternallocationandMailsColDef,
      dowloadCustomerListColDef,
      puSearchInternalColDef,
      puSearchExternalColDef,
      efileKcFileAccessColDef,
      reportsPageColumnsGrid,
      multipleChangedModalDef,
      rebateSearchResult
    };
  }
}

