import { Component, OnDestroy, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription, ObjectUnsubscribedError } from 'rxjs';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { MyProfileService } from '@app/Modules/my-profile/Components/services/my-profile-service';

@AutoUnsubscribe()

@Component({
  selector: 'app-myprofile-interna-priceagreement',
  templateUrl: './myprofile-Internal-pa.component.html',
  styleUrls: ['./myprofile-Internal-pa.component.scss']
})
export class MyprofileInternalPAComponent implements AgRendererComponent, OnInit, OnDestroy {

  currentNode: any;
  subscription: Subscription = new Subscription();
  checkboxNamePA: string;
  checkboxNameMC: string;
  checkboxNameCB: string;
  checkboxNameIN: string;
  checkboxNameOD: string;
  checkStatusPA: boolean = false;
  checkStatusMC: boolean = false;
  checkStatusCB: boolean = false;
  checkStatusIN: boolean = false;
  checkStatusOD: boolean = false;
  saveChangesObj : any;
  totalNumberOfRecord: number = 0;
  numberOfPASelected: number = 0;

  constructor(
    private agGridService: CpAgGridService,
    private myProfileService: MyProfileService
  ) { }

  agInit(params: any): void {
    this.currentNode = params;
    this.checkboxNamePA = Math.random().toString(36).substring(2, 15);
    this.checkboxNameMC = Math.random().toString(36).substring(2, 15);
    this.checkboxNameCB = Math.random().toString(36).substring(2, 15);
    this.checkboxNameIN = Math.random().toString(36).substring(2, 15);
    this.checkboxNameOD = Math.random().toString(36).substring(2, 15);

    if (params && params.data && params.data.Preferences) {
      params.data.Preferences.forEach(prefernce => {
        if(prefernce.isSelected){
          switch(prefernce.moduleId){
            case 1: // Price Aggrement
                    this.checkStatusPA = true;
                    this.myProfileService.setnumberOfSelectedPA(params.data.customerNumber,'Add');
                    break;
            case 2:// Meet Comp
                    this.checkStatusMC = true;
                    this.myProfileService.setnumberOfSelectedMC(params.data.customerNumber,'Add');
                    break;
            case 3: // ChargeBack
                    this.checkStatusCB = true;
                    this.myProfileService.setnumberOfSelectedCB(params.data.customerNumber,'Add');
                    break;
            case 4: // Invoices
                    this.checkStatusIN = true;
                    this.myProfileService.setnumberOfSelectedIN(params.data.customerNumber,'Add');
                    break;
            case 5: // Orders
                    this.checkStatusOD = true;
                    this.myProfileService.setnumberOfSelectedOD(params.data.customerNumber,'Add');
                    break;
          }
        }
        
      });
    }

    if(params.data){
      this.myProfileService.setTotalNumberOfPAs(params.data.customerNumber);
    }

  }

  ngOnInit() {
    //Select All Header Subscription Price Agreement
    this.subscription.add(this.agGridService.getMyProfilePASelectAll().subscribe(isSelecAll => {
      this.updateCheckCount(isSelecAll,1);
    }));

    //Select All Header Subscription Meet Comp
    this.subscription.add(this.agGridService.getMyProfileMCSelectAll().subscribe(isSelecAll => {
      this.updateCheckCount(isSelecAll,2);
    }));

    //Select All Header Subscription Price Agreement
    this.subscription.add(this.agGridService.getMyProfileCBSelectAll().subscribe(isSelecAll => {
      this.updateCheckCount(isSelecAll,3);
    }));

    //Select All Header Subscription Price Agreement
    this.subscription.add(this.agGridService.getMyProfileINSelectAll().subscribe(isSelecAll => {
      this.updateCheckCount(isSelecAll,4);
    }));

    //Select All Header Subscription Price Agreement
    this.subscription.add(this.agGridService.getMyProfileODSelectAll().subscribe(isSelecAll => {
      this.updateCheckCount(isSelecAll,5);
    }));
    this.defaultHeaderCheckBoxState();
  }

  updateCheckCount(isSelecAll, appModuleId){
      if(this.currentNode.data && this.currentNode.data.Preferences)
      this.currentNode.data.Preferences.forEach(Preference => {
        if(Preference.moduleId == appModuleId ){
          Preference.isSelected = isSelecAll;
        }
      });

      if(isSelecAll){
        switch(appModuleId){
          case 1: 
            this.checkStatusPA = true;
            this.myProfileService.setnumberOfSelectedPA([], 'Reset');
            if(this.currentNode && this.currentNode.api){
              this.currentNode.api.forEachNode((node, index) => {
                node.setExpanded(true);
                if(node.data && node.data.customerNumber){
                  this.myProfileService.setnumberOfSelectedPA(node.data.customerNumber, 'Add');
                }
              })
            }
            break;
          case 2:
            this.checkStatusMC = true;
            this.myProfileService.setnumberOfSelectedMC([], 'Reset');
            if(this.currentNode && this.currentNode.api){
              this.currentNode.api.forEachNode((node, index) => {
                node.setExpanded(true);
                if(node.data && node.data.customerNumber){
                  this.myProfileService.setnumberOfSelectedMC(node.data.customerNumber, 'Add');
                }
              })
            }
            break;
          case 3:
            this.checkStatusCB = true;
            this.myProfileService.setnumberOfSelectedCB([], 'Reset');
            if(this.currentNode && this.currentNode.api){
              this.currentNode.api.forEachNode((node, index) => {
                node.setExpanded(true);
                if(node.data && node.data.customerNumber){
                  this.myProfileService.setnumberOfSelectedCB(node.data.customerNumber, 'Add');
                }
              })
            }
            break;
          case 4:
            this.checkStatusIN = true;
            this.myProfileService.setnumberOfSelectedIN([], 'Reset');
            if(this.currentNode && this.currentNode.api){
              this.currentNode.api.forEachNode((node, index) => {
                node.setExpanded(true);
                if(node.data && node.data.customerNumber){
                  this.myProfileService.setnumberOfSelectedIN(node.data.customerNumber, 'Add');
                }
              })
            }
            break;
          case 5:
            this.checkStatusOD = true;
            this.myProfileService.setnumberOfSelectedOD([], 'Reset');
            if(this.currentNode && this.currentNode.api){
              this.currentNode.api.forEachNode((node, index) => {
                node.setExpanded(true);
                if(node.data && node.data.customerNumber){
                  this.myProfileService.setnumberOfSelectedOD(node.data.customerNumber, 'Add');
                }
              })
            }
            break;
        }
        
      } else {
        switch(appModuleId){
          case 1:
            this.checkStatusPA = false;
            this.myProfileService.setnumberOfSelectedPA([], 'Reset');
            break;
          case 2:
            this.checkStatusMC = false;
            this.myProfileService.setnumberOfSelectedMC([], 'Reset');
            break;
          case 3:
            this.checkStatusCB = false;
            this.myProfileService.setnumberOfSelectedCB([], 'Reset');
            break;
          case 4:
            this.checkStatusIN = false;
            this.myProfileService.setnumberOfSelectedIN([], 'Reset');
            break;
          case 5:
            this.checkStatusOD = false;
            this.myProfileService.setnumberOfSelectedOD([], 'Reset');
            break;
        }
      }   
  }

  refresh(params: any): boolean {
    return false;
  }

  checkboxClick(event) {
    this.currentNode.data.Preferences.forEach(Preference => {
      if(Preference.moduleId == this.currentNode.colDef.appFeatureId ){
        Preference.isSelected = event.target.checked;
      }
    });
    switch(this.currentNode.colDef.appFeatureId){
      case 1: // Price Aggrement
              this.checkStatusPA = !this.checkStatusPA;
              this.myProfileService.setnumberOfSelectedPA(this.currentNode.data.customerNumber, event.target.checked ? 'Add' :  'Remove');
              break;
      case 2:// Meet Comp
              this.checkStatusMC = !this.checkStatusMC;
              this.myProfileService.setnumberOfSelectedMC(this.currentNode.data.customerNumber, event.target.checked ? 'Add' :  'Remove');
              break;
      case 3: // ChargeBack
              this.checkStatusCB = !this.checkStatusCB;
              this.myProfileService.setnumberOfSelectedCB(this.currentNode.data.customerNumber, event.target.checked ? 'Add' :  'Remove');
              break;
      case 4: // Invoices
              this.checkStatusIN = !this.checkStatusIN;
              this.myProfileService.setnumberOfSelectedIN(this.currentNode.data.customerNumber, event.target.checked ? 'Add' :  'Remove');
              break;
      case 5: // Orders
              this.checkStatusOD = !this.checkStatusOD;
              this.myProfileService.setnumberOfSelectedOD(this.currentNode.data.customerNumber, event.target.checked ? 'Add' :  'Remove');
              break;
    }

    setTimeout(()=>{
      this.defaultHeaderCheckBoxState();
    });
  }	

  defaultHeaderCheckBoxState(){
    // Total Records
    const totalNumberOfRecord = this.myProfileService.getTotalNumberOfPAs();
    //Price Agreement
    if(this.currentNode.colDef.appFeatureId == 1){
      const numberOfPASelected = this.myProfileService.getnumberOfSelectedPA();
      if( (numberOfPASelected == totalNumberOfRecord) && (totalNumberOfRecord>0)) {
        this.agGridService.setnodeMyProfilePASelectAll("ALL");
      }else{
        this.agGridService.setnodeMyProfilePASelectAll("NONE");
      }
    }
    
    // Meet Comp
    if(this.currentNode.colDef.appFeatureId == 2){
      const numberOfMCSelected = this.myProfileService.getnumberOfSelectedMC();
      if( (numberOfMCSelected == totalNumberOfRecord) && (totalNumberOfRecord>0)) {
        this.agGridService.setnodeMyProfileMCSelectAll("ALL");
      }else{
        this.agGridService.setnodeMyProfileMCSelectAll("NONE");
      }
    }
    // ChargeBack
    if(this.currentNode.colDef.appFeatureId == 3){
      const numberOfCBSelected = this.myProfileService.getnumberOfSelectedCB();
      if( (numberOfCBSelected == totalNumberOfRecord) && (totalNumberOfRecord>0)) {
        this.agGridService.setnodeMyProfileCBSelectAll("ALL");
      }else{
        this.agGridService.setnodeMyProfileCBSelectAll("NONE");
      }
    }
    // Invoices
    if(this.currentNode.colDef.appFeatureId == 4){
      const numberOfINSelected = this.myProfileService.getnumberOfSelectedIN();
      if( (numberOfINSelected == totalNumberOfRecord) && (totalNumberOfRecord>0)) {
        this.agGridService.setnodeMyProfileINSelectAll("ALL");
      }else{
        this.agGridService.setnodeMyProfileINSelectAll("NONE");
      }
    }
    // Orders
    if(this.currentNode.colDef.appFeatureId == 5){
      const numberOfODSelected = this.myProfileService.getnumberOfSelectedOD();
      if( (numberOfODSelected == totalNumberOfRecord) && (totalNumberOfRecord>0)) {
        this.agGridService.setnodeMyProfileODSelectAll("ALL");
      }else{
        this.agGridService.setnodeMyProfileODSelectAll("NONE");
      }
    }
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }

}
