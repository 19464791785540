let endpoints_MC_QA = {
	mcGetCustomerDetailsGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v1/MeetCompCustomerSearch/CustomerSoldTo",
		method: "POST"
	},

	exportMCCustomerDetailsGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v1/MeetCompExport/CustomerSoldTo",
		method: "POST"
	},

	mcGetDistinctFilterCustomerDetailsGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v1/MeetCompCustomerSearch/GridHeaderFilters",
		method: "POST"
	},

	mcGetProductsDetailsGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-pdqa/v2/na/en-us/MeetComp/GetMeetCompProductBy",
		method: "POST"
	},

	exportMCProductsDetailsGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v1/MeetCompExport/MeetCompProducts",
		method: "POST"
	},

	mcGetDistinctFilterProductsDetailsGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v1/MeetComp/MCProdGridHeaderFilters",
		method: "POST"
	},

	mcGetMeetCompDetail: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v1/MeetComp/MeetCompDetail',
		method: "POST"
	},
	mcDownloadMCDetails: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v1/MeetCompDownload/DownloadMCDetailAsync',
		method: "POST"
	},
	mcDownloadMCDetailsExcelFile: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v1/MeetCompDownload/DownloadMCDetailAsync',
		method: "POST"
	},

	mcSearchResult: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v1/MeetComp/ByMCSearchField',
		method: "POST"
	},

	mcGetDistinctFilterSearchGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v1/MeetComp/MCSearchGridHeaderFilters",
		method: "POST"
	},

	mcExportSearchGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v1/MeetCompExport/ByMCSearchField",
		method: "POST"
	},

	DownloadMCAsync: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v1/MeetCompDownload/DownloadMCAsync",
		method: "POST"
	},

	signalREndpointMC: {
		url_NA: "https://pam-kimclark-com-qa-ncus-lane4.azurewebsites.net/signalRHubMC/PAMHubEntryPoint",
		method: "POST"
	}
};

export { endpoints_MC_QA };