import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { GlobalAppService } from './global-app.service';
import { timeout, catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global-app.service";
var HttpService = /** @class */ (function () {
    function HttpService(http, _globalAppService) {
        this.http = http;
        this._globalAppService = _globalAppService;
    }
    // getUserInfo(OKTA_TOKEN: string): Observable<any> {
    //   let httpHeaders: HttpHeaders = new HttpHeaders({ 'Authorization': 'Bearer ' + OKTA_TOKEN }).append('Content-Type', 'application/json');
    //   return this.makeHTTPCall(environment.userInfo.method, environment.userInfo.url, httpHeaders, null)
    // }
    HttpService.prototype.getUserInfo = function (OKTA_TOKEN) {
        var oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
        if ((oktaStorage && (Object.keys(oktaStorage).length === 0))) {
            return new Observable(function (observer) {
                observer.next(null);
            });
        }
        return new Observable(function (observer) {
            // console.log("jwt token", jwt.payload);
            var name = oktaStorage.idToken.claims.name;
            var given_name = name.substring(name.indexOf(',') + 1, name.length).trim();
            var family_name = name.substring(0, name.indexOf(',')).trim();
            var appregions = oktaStorage.idToken.claims.appregions;
            var appRoles = oktaStorage.idToken.claims.approles;
            // let appregions = ['LAO'];
            var User_Info = (function (_a) {
                var sub = _a.sub, name = _a.name, email = _a.email, phone = _a.phone, preferred_username = _a.preferred_username, sAMAccountName = _a.sAMAccountName, approles = _a.approles, userType = _a.userType, appRoles = _a.appRoles;
                return ({ sub: sub, name: name, email: email, phone: phone, preferred_username: preferred_username, sAMAccountName: sAMAccountName, approles: approles, userType: userType, appRoles: appRoles });
            })(oktaStorage.idToken.claims);
            var final_User_Info = Object.assign({}, User_Info, { given_name: given_name, family_name: family_name, appregions: appregions });
            // console.log("User_Info ===> ", final_User_Info)
            observer.next(final_User_Info);
        });
    };
    HttpService.prototype.oktaAuthRenew = function (OKTA_TOKEN) {
        var httpHeaders = new HttpHeaders({ 'Authorization': 'Bearer ' + OKTA_TOKEN }).append('Content-Type', 'application/json');
        return this.makeHTTPCall(environment.oktaAuthorize.method, this.getRegionSpecificURL(environment.oktaAuthorize), httpHeaders, null);
    };
    HttpService.prototype.getImpersonatedUserInfo = function (ebamId) {
        var oktaUrl = environment.getImpersonatedUserInfo.url.replace("XXX_EBAMID_XXX", ebamId);
        return this.makeHTTPCall(environment.getImpersonatedUserInfo.method, oktaUrl, null, null);
    };
    HttpService.prototype.getCategoryList = function () {
        return this.makeHTTPCall(environment.productcategory.method, this.getRegionSpecificURL(environment.productcategory), null, null);
    };
    HttpService.prototype.getFavContractList = function () {
        return this.makeHTTPCall(environment.getFavorite.method, this.getRegionSpecificURL(environment.getFavorite), null, null);
    };
    // getUserProfile(qid: string): Observable<any> {
    //   return this.makeHTTPCall(environment.userProfile.method, environment.userProfile.url, null, {
    //     "userid": qid
    //   })
    // }
    HttpService.prototype.getUserProfile = function (qid) {
        return this.makeHTTPCall(environment.userProfile.method, this.getRegionSpecificURL(environment.userProfile), null, null);
    };
    HttpService.prototype.getdashBoardCount = function () {
        return this.makeHTTPCall(environment.dashboardcount.method, this.getRegionSpecificURL(environment.dashboardcount), null, null);
    };
    HttpService.prototype.getEndUserCount = function () {
        return this.makeHTTPCall(environment.endUserCount.method, this.getRegionSpecificURL(environment.endUserCount), null, null);
    };
    HttpService.prototype.PASearch = function (searchObj) {
        return this.makeHTTPCall(environment.SearchByFields.method, this.getRegionSpecificURL(environment.SearchByFields), null, searchObj);
    };
    HttpService.prototype.PARecentSearchSave = function (searchObj) {
        return this.makeHTTPCall(environment.saveRecentSearch.method, this.getRegionSpecificURL(environment.saveRecentSearch), null, searchObj);
    };
    HttpService.prototype.getRecentSearchTiles = function () {
        return this.makeHTTPCall(environment.getRecentSearch.method, this.getRegionSpecificURL(environment.getRecentSearch), null, null);
    };
    HttpService.prototype.getSavedSearchTiles = function () {
        return this.makeHTTPCall(environment.getSavedSearch.method, this.getRegionSpecificURL(environment.getSavedSearch), null, null);
    };
    HttpService.prototype.setSavedSearchName = function (tileId, name) {
        return this.makeHTTPCall(environment.saveSearchName.method, this.getRegionSpecificURL(environment.saveSearchName), null, {
            "id": tileId,
            "name": name
        });
    };
    HttpService.prototype.getHamburgerMenu = function (language) {
        var httpHeaders = new HttpHeaders({ 'Accept-Language': language });
        return this.makeHTTPCall(environment.hamburgerMenu.method, this.getRegionSpecificURL(environment.hamburgerMenu), httpHeaders, null);
    };
    HttpService.prototype.removeProfileSearch = function (searchId) {
        return this.makeHTTPCall(environment.removeSearch.method, this.getRegionSpecificURL(environment.removeSearch), null, {
            id: searchId
        });
    };
    HttpService.prototype.markSearchFavrouite = function (searchId) {
        return this.makeHTTPCall(environment.markSearchFavrouite.method, this.getRegionSpecificURL(environment.markSearchFavrouite), null, {
            id: searchId
        });
    };
    HttpService.prototype.getTransactionCenterMenu = function (language) {
        var httpHeaders = new HttpHeaders({ 'Accept-Language': language });
        return this.makeHTTPCall(environment.transactionCenterMenu.method, this.getRegionSpecificURL(environment.transactionCenterMenu), httpHeaders, null);
    };
    HttpService.prototype.getLearningCenterMenu = function () {
        return this.makeHTTPCall(environment.learningCenterMenu.method, this.getRegionSpecificURL(environment.learningCenterMenu), null, null);
    };
    HttpService.prototype.getWelcomeFlag = function (userId) {
        var httpHeaders = new HttpHeaders({ 'userId': userId });
        return this.makeHTTPCall(environment.getWelcomeFlag.method, this.getRegionSpecificURL(environment.getWelcomeFlag), httpHeaders, null);
    };
    HttpService.prototype.updateWelcomeFlag = function (userId) {
        var httpHeaders = new HttpHeaders({ 'userId': userId });
        return this.makeHTTPCall(environment.updateWelcomeFlag.method, this.getRegionSpecificURL(environment.updateWelcomeFlag), httpHeaders, null);
        // return Observable.of({ status : true});
    };
    HttpService.prototype.markFavorite = function (contractId, customerId, todayDate) {
        return this.makeHTTPCall(environment.markFavorite.method, this.getRegionSpecificURL(environment.markFavorite), null, [{
                "contractId": "" + contractId,
                "customerId": "" + customerId,
                "favoriteDate": "2019-04-29T22:57:15.413+05:30" //hardcode value as discussed with Azure team as date is been used from server side
            }]);
    };
    HttpService.prototype.markUnFavorite = function (contractId, customerId, todayDate) {
        return this.makeHTTPCall(environment.markUnFavorite.method, this.getRegionSpecificURL(environment.markUnFavorite), null, [{
                "contractId": "" + contractId,
                "customerId": "" + customerId,
                "favoriteDate": "2019-04-29T22:57:15.413+05:30" //hardcode value as discussed with Azure team as date is been used from server side
            }]);
    };
    HttpService.prototype.getCustomerInfo = function (customerId) {
        return this.makeHTTPCall(environment.getCustomerInfo.method, this.getRegionSpecificURL(environment.getCustomerInfo), null, {
            custid: [customerId]
        });
    };
    HttpService.prototype.getContractCustomerList = function (searchobj) {
        return this.makeHTTPCall(environment.getContractCustomerList.method, this.getRegionSpecificURL(environment.getContractCustomerList), null, searchobj);
    };
    HttpService.prototype.exportRecentDownload = function (request) {
        return this.makeHTTPCall(environment.recentDownloadExport.method, this.getRegionSpecificURL(environment.recentDownloadExport), null, request);
    };
    HttpService.prototype.exportPriceAgreementSearch = function (searchRequestObj) {
        return this.makeHTTPCall(environment.exportPriceAgreementSearch.method, this.getRegionSpecificURL(environment.exportPriceAgreementSearch), null, searchRequestObj);
    };
    HttpService.prototype.exportPADetailEndUser = function (searchRequestObj) {
        return this.makeHTTPCall(environment.exportPADetailEndUser.method, this.getRegionSpecificURL(environment.exportPADetailEndUser), null, searchRequestObj);
    };
    HttpService.prototype.exportPADetailProduct = function (searchRequestObj) {
        return this.makeHTTPCall(environment.exportPADetailProduct.method, this.getRegionSpecificURL(environment.exportPADetailProduct), null, searchRequestObj);
    };
    HttpService.prototype.exportPADetailTradeOrg = function (searchRequestObj) {
        return this.makeHTTPCall(environment.exportPADetailTradeOrg.method, this.getRegionSpecificURL(environment.exportPADetailTradeOrg), null, searchRequestObj);
    };
    HttpService.prototype.exportDashboardTiles = function (searchRequestObj) {
        return this.makeHTTPCall(environment.exportDashboardTiles.method, this.getRegionSpecificURL(environment.exportDashboardTiles), null, searchRequestObj);
    };
    HttpService.prototype.exportMyNotification = function (searchRequestObj) {
        return this.makeHTTPCall(environment.exportMyNotification.method, this.getRegionSpecificURL(environment.exportMyNotification), null, searchRequestObj);
    };
    HttpService.prototype.getContractDetail = function (contractNumber, customerNumber, statusId, searchView) {
        return this.makeHTTPCall(environment.getContractDetail.method, this.getRegionSpecificURL(environment.getContractDetail), null, {
            "contractNumber": contractNumber,
            "customerNumber": customerNumber,
            "paStatus": +statusId,
            "searchView": (searchView == false ? "customer" : "cvt")
            // "searchView": 0
        });
    };
    HttpService.prototype.saveUserNotes = function (note, contractNumber, customerNumber, username) {
        return this.makeHTTPCall(environment.saveUserNotes.method, this.getRegionSpecificURL(environment.saveUserNotes), null, {
            "notes": note,
            "contractNumber": contractNumber,
            "customerNumber": customerNumber,
            "userName": username
        });
    };
    HttpService.prototype.getUserNotes = function (contractNumber, customerNumber, statusId) {
        return this.makeHTTPCall(environment.getUserNotes.method, this.getRegionSpecificURL(environment.getUserNotes), null, {
            "contractNumber": contractNumber,
            "customerNumber": customerNumber,
            "paStatus": +statusId
        });
    };
    HttpService.prototype.getKCPUserUpdates = function (contractNumber, customerNumber, statusId) {
        return this.makeHTTPCall(environment.getKCPUserUpdates.method, this.getRegionSpecificURL(environment.getKCPUserUpdates), null, {
            "contractNumber": contractNumber,
            "customerNumber": customerNumber,
            "paStatus": +statusId
        });
    };
    HttpService.prototype.announcementModalDetails = function (userid, announcementid) {
        if (announcementid === void 0) { announcementid = []; }
        return this.makeHTTPCall(environment.announcementsModalDetails.method, this.getRegionSpecificURL(environment.announcementsModalDetails), null, {
            "userid": userid,
            "announcementid": announcementid
        });
    };
    HttpService.prototype.messageModalDetails = function (userid, messagesid) {
        if (messagesid === void 0) { messagesid = []; }
        return this.makeHTTPCall(environment.messagesModalDetails.method, this.getRegionSpecificURL(environment.messagesModalDetails), null, {
            "userid": userid,
            "messageid": messagesid
        });
    };
    HttpService.prototype.getLocation = function (userid, sortingField, sortingOrder) {
        if (sortingField === void 0) { sortingField = null; }
        if (sortingOrder === void 0) { sortingOrder = null; }
        // return this.makeHTTPCall(environment.getLocationData.method, environment.getLocationData.url + "?userID=" + userid, null, null
        return this.makeHTTPCall(environment.getLocationData.method, this.getRegionSpecificURL(environment.getLocationData) + "?userID=" + userid + '&sortingField=' + sortingField + '&sortingOrder=' + sortingOrder, null, null);
    };
    HttpService.prototype.getCustomerList = function (customerList) {
        return this.makeHTTPCall(environment.getCustomerInfo.method, this.getRegionSpecificURL(environment.getCustomerInfo), null, {
            "custid": customerList
        });
    };
    HttpService.prototype.getPATYpe = function (cvtview) {
        return this.makeHTTPCall(environment.getPAType.method, this.getRegionSpecificURL(environment.getPAType) + "?iscvt=" + cvtview, null, null);
    };
    HttpService.prototype.downloadData = function (request) {
        // return this.http.post(environment.download.url, request).pipe(timeout(300000),
        return this.makeHTTPCall(environment.download.method, this.getRegionSpecificURL(environment.download), null, request).pipe(timeout(300000), catchError(function (err) {
            return of(null);
        }), map(function (response) {
            if (response) {
                return response;
            }
        }));
    };
    HttpService.prototype.downloadPADetails = function (request, isPDFDownload) {
        var url = isPDFDownload ? this.getRegionSpecificURL(environment.downloadPADetails) : this.getRegionSpecificURL(environment.downloadPADetailsExcelFile);
        return this.makeHTTPCall(environment.downloadPADetails.method, url, null, request).pipe(timeout(300000), catchError(function (err) {
            return of(null);
        }), map(function (response) {
            if (response) {
                return response;
            }
        }));
    };
    // getEndUserRequests(url, request) {
    //   return this.makeHTTPCall(environment.getEndUserRequests.method, url, null, request);
    // }
    HttpService.prototype.downloadRebateData = function (request) {
        // return this.http.post(environment.download.url, request).pipe(timeout(300000),
        return this.makeHTTPCall(environment.rebateDownload.method, this.getRegionSpecificURL(environment.rebateDownload), null, request).pipe(timeout(300000), catchError(function (err) {
            return of(null);
        }), map(function (response) {
            if (response) {
                return response;
            }
        }));
    };
    HttpService.prototype.checkenduser = function (searchObj) {
        return this.makeHTTPCall(environment.endusersearch.method, this.getRegionSpecificURL(environment.endusersearch), null, searchObj);
    };
    HttpService.prototype.getStateList = function (request) {
        return this.makeHTTPCall(environment.getStateList.method, this.getRegionSpecificURL(environment.getStateList), null, {
            "CP_Country_Code": (request ? request : "")
        });
    };
    HttpService.prototype.validateAddress = function (request) {
        return this.makeHTTPCall(environment.validateAddress.method, this.getRegionSpecificURL(environment.validateAddress), null, request);
    };
    HttpService.prototype.submitEndUsers = function (request) {
        return this.makeHTTPCall(environment.submitEndUsers.method, this.getRegionSpecificURL(environment.submitEndUsers), null, request);
    };
    HttpService.prototype.ClearCache = function () {
        return this.makeHTTPCall(environment.ClearCache.method, this.getRegionSpecificURL(environment.ClearCache), null, { "clearCacheItem": 1 });
    };
    HttpService.prototype.downloadPaDetailsExcel = function (request) {
        return this.makeHTTPCall(environment.downloadPadetailsExcel.method, this.getRegionSpecificURL(environment.downloadPadetailsExcel), null, request);
    };
    HttpService.prototype.getRegionSpecificURL = function (item) {
        var regionURL = "";
        var region = localStorage.getItem('default-region') || this._globalAppService.getDefaultRegionString() || this._globalAppService.selectedRegion;
        if (region) {
            regionURL = "url_" + region.toUpperCase();
        }
        else {
            regionURL = "url_LAO";
        }
        return item[regionURL];
    };
    HttpService.prototype.makeHTTPCall = function (method, endPoint, additionalHeaders, requestParams) {
        if (additionalHeaders === void 0) { additionalHeaders = null; }
        if (requestParams === void 0) { requestParams = null; }
        var requestBody = {};
        if (additionalHeaders) {
            requestBody = Object.assign({}, requestBody, { headers: additionalHeaders });
        }
        if (requestParams) {
            requestBody = Object.assign({}, requestBody, { body: requestParams });
        }
        return this.http.request(method, endPoint, requestBody);
    };
    HttpService.prototype.downloadSelectedRecentDownload = function (fileId, isGlobalUser) {
        return this.makeHTTPCall(environment.selectedrecentDownloadDoculent.method, this.getRegionSpecificURL(environment.selectedrecentDownloadDoculent), null, {
            "fileId": fileId,
            "isGlobalUser": isGlobalUser
        });
    };
    HttpService.prototype.deleteDownload = function (deleteObj) {
        return this.makeHTTPCall(environment.recentDownloadDelete.method, this.getRegionSpecificURL(environment.recentDownloadDelete), null, deleteObj);
    };
    HttpService.prototype.deleteUserNotes = function (id) {
        return this.makeHTTPCall(environment.deleteUserNotes.method, this.getRegionSpecificURL(environment.deleteUserNotes) + id, null, null);
    };
    HttpService.prototype.downloadEndUserRequests = function (request) {
        return this.makeHTTPCall(environment.endUserRequestsDownload.method, this.getRegionSpecificURL(environment.endUserRequestsDownload), null, request);
    };
    HttpService.prototype.getKCPContent = function (request) {
        return this.makeHTTPCall(environment.KCPContent.method, this.getRegionSpecificURL(environment.KCPContent), null, request);
    };
    HttpService.prototype.getAccptancePolicyStatus = function (request) {
        return this.makeHTTPCall(environment.getAccptancePolicyStatus.method, this.getRegionSpecificURL(environment.getAccptancePolicyStatus), null, request);
    };
    HttpService.prototype.setAccptancePolicyStatus = function (request) {
        return this.makeHTTPCall(environment.setAccptancePolicyStatus.method, this.getRegionSpecificURL(environment.setAccptancePolicyStatus), null, request);
    };
    HttpService.prototype.getLocations = function () {
        return this.makeHTTPCall(environment.getLocations.method, this.getRegionSpecificURL(environment.getLocations), null, null);
    };
    HttpService.prototype.getLocationsWithDefaultLanguage = function (language) {
        var httpHeaders = new HttpHeaders({ 'Accept-Language': language });
        return this.makeHTTPCall(environment.getLocations.method, this.getRegionSpecificURL(environment.getLocations), httpHeaders, null);
    };
    HttpService.prototype.getContentService = function (request) {
        return this.makeHTTPCall(environment.getContentService.method, this.getRegionSpecificURL(environment.getContentService), null, request);
    };
    HttpService.prototype.getUserDetails = function () {
        return this.makeHTTPCall(environment.getUserDetails.method, this.getRegionSpecificURL(environment.getUserDetails), null, null);
    };
    HttpService.prototype.getAccessManagementList = function (request) {
        var httpHeaders = new HttpHeaders({ 'Accept-Language': request.languageCode });
        delete request.languageCode;
        return this.makeHTTPCall(environment.getAccessManagementList.method, this.getRegionSpecificURL(environment.getAccessManagementList), httpHeaders, request);
    };
    HttpService.prototype.getAccessManagementListRegisterPage = function (request) {
        var httpHeaders = new HttpHeaders({ 'Accept-Language': request.languageCode });
        return this.makeHTTPCall(environment.getAccessManagementList.method, this.getRegionSpecificURL(environment.getAccessManagementList), httpHeaders, request);
    };
    HttpService.prototype.registerUser = function (request) {
        return this.makeHTTPCall(environment.registerUser.method, this.getRegionSpecificURL(environment.registerUser), null, request);
    };
    HttpService.prototype.saveUpdateUserProfile = function (request) {
        return this.makeHTTPCall(environment.saveUpdateUserProfile.method, this.getRegionSpecificURL(environment.saveUpdateUserProfile), null, request);
    };
    HttpService.prototype.CheckPAUpdates = function () {
        return this.makeHTTPCall(environment.CheckPAUpdates.method, this.getRegionSpecificURL(environment.CheckPAUpdates), null, null);
    };
    HttpService.prototype.getNewlyAddedCustomers = function (request) {
        return this.makeHTTPCall(environment.getNewlyAddedCustomers.method, this.getRegionSpecificURL(environment.getNewlyAddedCustomers), null, request);
    };
    HttpService.prototype.getUserRoles = function (regionID) {
        if (regionID === void 0) { regionID = ''; }
        return this.makeHTTPCall(environment.GetUserRoles.method, this.getRegionSpecificURL(environment.GetUserRoles) + "?regionId=" + regionID, null, null);
    };
    HttpService.prototype.getCountrySalesOrgLanguage = function (reqHeader) {
        if (reqHeader === void 0) { reqHeader = null; }
        return this.makeHTTPCall(environment.GetCountrySalesOrgLanguage.method, this.getRegionSpecificURL(environment.GetCountrySalesOrgLanguage), reqHeader, null);
    };
    HttpService.prototype.getExternalUserCountries = function () {
        return this.makeHTTPCall(environment.UserCustomerCountries.method, this.getRegionSpecificURL(environment.UserCustomerCountries), null, null);
    };
    HttpService.prototype.getSortByOptions = function (request, header) {
        return this.makeHTTPCall(environment.GetSortBy.method, this.getRegionSpecificURL(environment.GetSortBy), header, request);
    };
    HttpService.prototype.saveUserDefaultProfile = function (request) {
        return this.makeHTTPCall(environment.SaveUserDefaultProfile.method, this.getRegionSpecificURL(environment.SaveUserDefaultProfile), null, request);
    };
    HttpService.prototype.getUserLocations = function () {
        return this.makeHTTPCall(environment.UserDefaultLocations.method, this.getRegionSpecificURL(environment.UserDefaultLocations), null, null);
    };
    HttpService.prototype.getStates = function (request) {
        return this.makeHTTPCall(environment.getStates.method, this.getRegionSpecificURL(environment.getStates), null, request);
    };
    HttpService.prototype.saveMyLocationData = function (request) {
        return this.makeHTTPCall(environment.saveLocationData.method, this.getRegionSpecificURL(environment.saveLocationData), null, request);
    };
    HttpService.prototype.removeLocation = function (request) {
        return this.makeHTTPCall(environment.removeUcMappings.method, this.getRegionSpecificURL(environment.removeUcMappings), null, request);
    };
    HttpService.prototype.addLocatioOrChangeGroup = function (request) {
        return this.makeHTTPCall(environment.addUcMappings.method, this.getRegionSpecificURL(environment.addUcMappings), null, request);
    };
    HttpService.prototype.triggerUCMSync = function (request) {
        return this.makeHTTPCall(environment.UCMSync.method, this.getRegionSpecificURL(environment.UCMSync), null, request);
    };
    HttpService.prototype.validateRecaptcha = function (request) {
        return this.makeHTTPCall(environment.captchaValidationUrl.method, this.getRegionSpecificURL(environment.captchaValidationUrl), null, request);
    };
    HttpService.prototype.getDownloadCustomerList = function (request) {
        return this.makeHTTPCall(environment.downloadCustomerList.method, this.getRegionSpecificURL(environment.downloadCustomerList), null, request);
    };
    HttpService.prototype.exportPortalUsage = function (request) {
        return this.makeHTTPCall(environment.exportPortalUsageInternal.method, this.getRegionSpecificURL(environment.exportPortalUsageInternal), null, request);
    };
    HttpService.prototype.saveUserContact = function (request) {
        return this.makeHTTPCall(environment.saveUserContact.method, this.getRegionSpecificURL(environment.saveUserContact), null, request);
    };
    HttpService.prototype.postToPardot = function (request) {
        return this.makeHTTPCall(environment.pardot.method, this.getRegionSpecificURL(environment.pardot), null, request);
    };
    HttpService.prototype.globalUserAuditLog = function (auditRequestObj) {
        return this.makeHTTPCall(environment.GlobalUserAuditLog.method, this.getRegionSpecificURL(environment.GlobalUserAuditLog), null, auditRequestObj);
    };
    HttpService.prototype.getEFileAccessBySearchFields = function (request) {
        return this.makeHTTPCall(environment.getEFileAccessBySearchFields.method, this.getRegionSpecificURL(environment.getEFileAccessBySearchFields), null, request);
    };
    HttpService.prototype.updateEFileAccess = function (request) {
        return this.makeHTTPCall(environment.updateEFileAccess.method, this.getRegionSpecificURL(environment.updateEFileAccess), null, request);
    };
    HttpService.prototype.eaGetDistinctFilterForGrid = function (requestObj, gridName) {
        switch (gridName) {
            case "efileAccessPage":
                return this.makeHTTPCall(environment.eaGetDistinctFilterSearchGrid.method, this.getRegionSpecificURL(environment.eaGetDistinctFilterSearchGrid), null, requestObj);
            default:
                return of(false);
        }
    };
    HttpService.prototype.getSalesOrgNames = function () {
        return this.makeHTTPCall(environment.getSalesOrgNames.method, this.getRegionSpecificURL(environment.getSalesOrgNames), null, null);
    };
    HttpService.prototype.getKCOwnerNames = function () {
        return this.makeHTTPCall(environment.getKCOwnerNames.method, this.getRegionSpecificURL(environment.getKCOwnerNames), null, null);
    };
    HttpService.prototype.getMultipleChanges = function (request) {
        return this.makeHTTPCall(environment.multipleChanges.method, this.getRegionSpecificURL(environment.multipleChanges), null, request);
    };
    HttpService.prototype.exportRebatePriceSearch = function (searchRequestObj) {
        return this.makeHTTPCall(environment.exportRebatePriceSearch.method, this.getRegionSpecificURL(environment.exportRebatePriceSearch), null, searchRequestObj);
    };
    HttpService.prototype.getOrderPopupPreferences = function (region, country) {
        var queryString = "?RegionId=" + region + "&CountryId=" + country;
        var url = this.getRegionSpecificURL(environment.getOrderPopupPreferences) + queryString;
        return this.makeHTTPCall(environment.getOrderPopupPreferences.method, url, null, null);
    };
    HttpService.prototype.getOrderPreferences = function (userId) {
        var queryString = "?userId=" + userId;
        var url = this.getRegionSpecificURL(environment.getOrderPreferences) + queryString;
        return this.makeHTTPCall(environment.getOrderPreferences.method, url, null, null);
    };
    HttpService.prototype.saveOrderPreferences = function (reqObj) {
        return this.makeHTTPCall(environment.saveOrderPreferences.method, this.getRegionSpecificURL(environment.saveOrderPreferences), null, reqObj);
    };
    HttpService.prototype.makeAuditLogoutRequest = function () {
        return this.makeHTTPCall(environment.auditLogout.method, this.getRegionSpecificURL(environment.auditLogout), null, null);
    };
    HttpService.prototype.getEndUsers = function (request) {
        return this.makeHTTPCall(environment.getEndUsersResultData.method, this.getRegionSpecificURL(environment.getEndUsersResultData), null, request);
    };
    HttpService.ngInjectableDef = i0.defineInjectable({ factory: function HttpService_Factory() { return new HttpService(i0.inject(i1.HttpClient), i0.inject(i2.GlobalAppService)); }, token: HttpService, providedIn: "root" });
    return HttpService;
}());
export { HttpService };
