let endPoints_IN_NEW_QA = {
	GetInvoicePriceStatusByCustomerID_new:{
		url_NA: "https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/NA/v1/Invoice/GetInvoicePriceStatusByCustomerID",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-pdqa/EMEA/v1/Invoice/GetInvoicePriceStatusByCustomerID",
		method: "POST"
	},
	PriceRefreshRequest_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-dev/NA/v1/Invoice/PriceRefreshRequest',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-pdqa/EMEA/v1/Invoice/PriceRefreshRequest',
		method: 'POST'
	},
	IsPriceRequestAllowedforUser_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/NA/v1/Invoice/IsPriceRequestAllowedforUser',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-pdqa/EMEA/v1/Invoice/IsPriceRequestAllowedforUser',
		method: 'POST'
	},
	invoiceSearchResult_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/NA/v1/Invoice/InvoiceCustomerSearch',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-pdqa/EMEA/v1/Invoice/InvoiceCustomerSearch',
		method: "POST"
	},
	exportInvoiceGrid_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/NA/v1/InvoiceExport/InvoiceCustomerSearchExport',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-pdqa/EMEA/v1/InvoiceExport/InvoiceCustomerSearchExport',
		method: "POST"
	},
	saveFavoriteInvoice_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/Invoice/SaveFavoriteCustomer',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/Invoice/SaveFavoriteCustomer',
		method: "POST"
	},
	DownloadInvoicePrice_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/na/v1/DownloadInvoice/DownloadInvoicePrice',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-pdqa/EMEA/v1/DownloadInvoice/DownloadInvoicePrice',
		method: 'POST'
	},
	NotifyInvoicePriceRequestByEmail_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/na/v1/Invoice/NotifyInvoicePriceRequestByEmail',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-pdqa/EMEA/v1/Invoice/NotifyInvoicePriceRequestByEmail',
		method: 'POST'
	},
	GetFavoriteCusotmerInvoice_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/Invoice/GetFavoriteCustomer',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/Invoice/GetFavoriteCustomer',
		method: 'POST'
	},
	invoicePriceGetRecentSearch_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/UserService/InvoicePriceGetRecentSearch',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/InvoicePriceGetRecentSearch',
		method: 'GET'

	},
	invoicePriceGetFavoriteSearch_new:{
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/UserService/InvoicePriceGetFavoriteSearch',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/InvoicePriceGetFavoriteSearch',
		method: 'GET'
	},
	saveFavoriteSearch_new:{
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/UserService/InvoicePriceSaveFavoriteSearch',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/InvoicePriceSaveFavoriteSearch',
		method: 'POST'
	},
	removeFavoriteSearch_new:{
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/UserService/RemoveFavoriteSearch',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/RemoveFavoriteSearch',
		method: 'POST'
	},
	saveSearchName_new:{
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/na/v2/UserService/InvoiceSaveSearchName',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/emea/v2/UserService/InvoiceSaveSearchName',
		method: 'POST'
	},
	InvoiceSearchGridFilter_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/NA/v1/Invoice/InvoiceSearchGridFilter',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-pdqa/EMEA/v1/Invoice/InvoiceSearchGridFilter',
		method: 'POST'
	},
	InvoicePriceSaveSearch_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/UserService/InvoicePriceSaveSearch',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/InvoicePriceSaveSearch',
		method: 'POST'
	},
	InvoiceRecentRequests_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/NA/v1/Invoice/RecentRefreshRequest',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-pdqa/EMEA/v1/Invoice/RecentRefreshRequest',
		method: 'POST'
	},
	RecentRefreshRequestGridFilter_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/NA/v1/Invoice/RecentRefreshRequestGridFilter',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-pdqa/EMEA/v1/Invoice/RecentRefreshRequestGridFilter',
		method: 'POST'
	},
	RecentRefreshRequestExport_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-dev/NA/v1/InvoiceExport/RecentRefreshRequestExport',
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eukcpinvoiceprice-pxy-api-pdqa/EMEA/v1/InvoiceExport/RecentRefreshRequestExport',
		method: 'POST'
	},
	GetCategoriesByCustomerNumber_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-kcpinvoiceprice-pxy-api-pdqa/na/v1/Invoice/GetCategoriesByCustomerNumber',
		method: 'POST'
	},
	SaveFavoriteInvoiceCategory_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/Invoice/SaveFavoriteCategory',
		method: 'POST'
	},
	GetFavoriteCategoryInvoice_new: {
		url_NA: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/Invoice/GetFavoriteCategory',
		method: 'POST'
	},
	UserCustomerCountriesByCustomerType: {
		url_EMEA: 'https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/UserCustomerCountriesByCustomerType',
		method: 'GET'
	}
};

export { endPoints_IN_NEW_QA };