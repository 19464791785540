/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delegated-user-hyperlink.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./delegated-user-hyperlink.component";
import * as i4 from "../../../../Shared/Services/app.service";
var styles_DelegatedUserHyperlinkComponent = [i0.styles];
var RenderType_DelegatedUserHyperlinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DelegatedUserHyperlinkComponent, data: {} });
export { RenderType_DelegatedUserHyperlinkComponent as RenderType_DelegatedUserHyperlinkComponent };
function View_DelegatedUserHyperlinkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-user-friends custom-table__icon fill"]], null, null, null, null, null))], null, null); }
function View_DelegatedUserHyperlinkComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-user-slash custom-table__icon fill"]], null, null, null, null, null))], null, null); }
export function View_DelegatedUserHyperlinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DelegatedUserHyperlinkComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DelegatedUserHyperlinkComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.isGroupAdmin && !_co.isGlobalUser); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.isNonAssociatedUser && !_co.isGlobalUser); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 1, 0, currVal_0); }); }
export function View_DelegatedUserHyperlinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "delegateduser-hyperlink", [], null, null, null, View_DelegatedUserHyperlinkComponent_0, RenderType_DelegatedUserHyperlinkComponent)), i1.ɵdid(1, 245760, null, 0, i3.DelegatedUserHyperlinkComponent, [i4.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DelegatedUserHyperlinkComponentNgFactory = i1.ɵccf("delegateduser-hyperlink", i3.DelegatedUserHyperlinkComponent, View_DelegatedUserHyperlinkComponent_Host_0, {}, {}, []);
export { DelegatedUserHyperlinkComponentNgFactory as DelegatedUserHyperlinkComponentNgFactory };
