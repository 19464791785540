import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalAppService } from '../../Services/global-app.service';
import { LanguageSelectionService } from '../../Services/language-selection.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  currentNavigationState: any;
  region: any;
  constructor(private router: Router,
    private _globalAppService: GlobalAppService,
    private languageServ: LanguageSelectionService,
    private translate: TranslateService
  ) {
    this.currentNavigationState = this.router.getCurrentNavigation();
  }

  ngOnInit() {
    this.region = this._globalAppService.getDefaultRegionString();
    if (this.currentNavigationState && this.currentNavigationState.extras &&
      this.currentNavigationState.extras.state && this.currentNavigationState.extras.state.message) {
      this.currentNavigationState = this.currentNavigationState.extras.state.message;
    } else{
      this.currentNavigationState = 'error.errorPage';
    }

    this._globalAppService.getDefaultLanguage().subscribe(defaultlang => {
      if (defaultlang) {
        this.translate.setDefaultLang(this.region + '/' + defaultlang.toLowerCase());
      }
    })

    this.languageServ.getlanguage().subscribe(data => {
      this.getLanguageChange(data);
    });
    let selectedCountryLang = this._globalAppService.getSelectedCountryLang();
    let currentLanguage = this._globalAppService.getDefaultLanguageString() || 'en-US';
    if(selectedCountryLang && selectedCountryLang.language){
      currentLanguage = selectedCountryLang.language;
    }
    this.getLanguageChange(currentLanguage);
     

    // console.log(this.currentNavigationState)
  }

  getLanguageChange(language){
    if (language) {
      this.translate.use(this.region + '/' + language.toLowerCase());
    }
  }

}
