/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tooltip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/primeng/components/overlaypanel/overlaypanel.ngfactory";
import * as i3 from "primeng/components/overlaypanel/overlaypanel";
import * as i4 from "./tooltip.component";
import * as i5 from "../../../../PriceAgreement/Services/pa.service";
import * as i6 from "../../../../Shared/Services/http.service";
var styles_TooltipComponent = [i0.styles];
var RenderType_TooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipComponent, data: {} });
export { RenderType_TooltipComponent as RenderType_TooltipComponent };
export function View_TooltipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "custom-table__popup custom-table__favorite ellipsisWrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "custom-table__customer custom-table__popup__trigger ellipsis"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.showCustomerInfoTooltip($event, i1.ɵnov(_v, 4)) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.hideCustomerInfoTooltip(i1.ɵnov(_v, 4)) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", "\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "p-overlayPanel", [["appendTo", "body"]], null, null, null, i2.View_OverlayPanel_0, i2.RenderType_OverlayPanel)), i1.ɵdid(4, 180224, [["op", 4]], 0, i3.OverlayPanel, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.NgZone], { appendTo: [0, "appendTo"], showTransitionOptions: [1, "showTransitionOptions"], hideTransitionOptions: [2, "hideTransitionOptions"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 4, "span", [["class", "custom-table__popup__info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " ", " ", " ", " ", ""]))], function (_ck, _v) { var currVal_1 = "body"; var currVal_2 = "500ms"; var currVal_3 = "0ms"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cellValue; _ck(_v, 2, 0, currVal_0); var currVal_4 = _co.cellValue; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.street; var currVal_6 = _co.city; var currVal_7 = _co.district; var currVal_8 = _co.country; var currVal_9 = _co.zipcode; _ck(_v, 9, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_TooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tooltip", [], null, null, null, View_TooltipComponent_0, RenderType_TooltipComponent)), i1.ɵdid(1, 180224, null, 0, i4.TooltipComponent, [i5.PaService, i6.HttpService], null, null)], null, null); }
var TooltipComponentNgFactory = i1.ɵccf("app-tooltip", i4.TooltipComponent, View_TooltipComponent_Host_0, {}, {}, []);
export { TooltipComponentNgFactory as TooltipComponentNgFactory };
