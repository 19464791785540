import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { createContractLink } from "@app/conf/appLink";

@Component({
  selector: 'app-hyperlink',
  templateUrl: './hyperlink.component.html',
  styleUrls: ['./hyperlink.component.scss']
})
export class HyperlinkComponent implements AgRendererComponent {

  constructor() { }
  params: any;  

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClick(event) {
    let redirectUrl: string = createContractLink.createContractURI + this.params.data.contractNumber;
    window.open(redirectUrl, '_blank');
  }

}
