import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'errorcell',
  templateUrl: './efile-errorcell.component.html',
  styleUrls: ['./efile-errorcell.component.scss']
})

export class EfileErrorcellComponent implements AgRendererComponent {

  params: any;
  tooltipText: string = "";

  constructor(
  ) {}

  agInit(params: any) {
    this.params = params;
    if (this.params.colDef && this.params.data && this.params.data.lineErrors && this.params.data.lineErrors.length){
      this.params.data.lineErrors.forEach(item => {
        let field = this.params.colDef.field;
        if ((item.fieldName).toUpperCase() === field.toUpperCase()){
          this.tooltipText += '<i class="fas fa-exclamation-circle textRed"></i>&nbsp;' + item.errorMessage + '<br/>';
        }
      });    
    }
  }

  refresh(params: any): boolean {
    return true;
  }
}
