<div class="body-wrapper">
  <!-- <div class="shade"></div> -->
  <div class="content-area">
    <!-- content area start -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="content-area__white-box">
            <h1 class="text-center p-4">
              {{ currentNavigationState | translate }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div> <!-- content area end -->
</div> <!-- body wrapper end -->