import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-end-user-number',
  templateUrl: './end-user-number.component.html',
  styleUrls: ['./end-user-number.component.scss']
})
export class EndUserNumberComponent implements AgRendererComponent {

  constructor() { }
  params: any;
  hideValue: boolean = false;
  agInit(params: any): void {
    this.params = params;
    if(params && params.data){
      if(params.data.status == 'New' || params.data.status == 'Pending' || params.data.status == 'Submitted' ){
        this.hideValue = true;
      }else if(params.data.status == 'Complete ' || (params.data.status =='Denied' && params.data.comments!= null) ){
        this.hideValue = false;
      }
    }

  }

  refresh(params: any): boolean {
    return false;
  }

}
