import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DelegatedUserSearchQuery, DelegatedUserSearch } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe'
import { cookieSIMID } from '@env/environment';

@AutoUnsubscribe()
@Component({
  selector: 'delegated-user-search',
  templateUrl: './delegated-user-search.component.html',
  styleUrls: ['./delegated-user-search.component.scss']
})
export class DelegatedUserSearchComponent implements OnInit, OnDestroy {

  selectedRegion: any;
  userSearchForm: FormGroup;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalAppService: GlobalAppService,
    private fb: FormBuilder
  ) {

  }

  ngOnInit() {
    this.deleteCookie(cookieSIMID);
    this.globalAppService.getDefaultRegion().subscribe(region => {
      this.selectedRegion = region;
      this.initFormGroup();
    })
  }

  onSumbit() {
    let request: DelegatedUserSearch = this.createSearchRequest();
    this.globalAppService.updateDelegatedUserSearch(request);
    this.router.navigate([{ outlets: { delegatedResults: 'delegatedUserSearchResults' } }], {queryParamsHandling: 'preserve', relativeTo: this.activatedRoute });
  }

  initFormGroup() {
    switch (this.selectedRegion) {
      case 'LAO':
      case 'NA':
      case 'EMEA':
        this.userSearchForm = this.fb.group({
          firstName: ['', Validators.compose([Validators.maxLength(100)])],
          customerName: ['', Validators.compose([Validators.maxLength(100)])],
          userId: ['', Validators.compose([Validators.maxLength(100)])],
         
          lastName: ['', Validators.compose([Validators.maxLength(100)])],
          customerId: ['', Validators.compose([Validators.email, Validators.maxLength(100)])],
          email: ['', Validators.compose([Validators.email, Validators.maxLength(100)])],
        });
        break;
    }
  }

  createSearchRequest()
  {
    let request: DelegatedUserSearch = new DelegatedUserSearch();
    request.query = new DelegatedUserSearchQuery();
    let formValue: any = this.userSearchForm.getRawValue();
   
    request.query.firstName = formValue.firstName.trim();
    request.query.customerName = formValue.customerName.trim();
    request.query.userId = formValue.userId.trim();
    
    request.query.lastName = formValue.lastName.trim();
    request.query.customerNumber = [formValue.customerId.trim()];
    request.query.email = formValue.email.trim();

    request = Object.assign({}, request, {isGlobalSearchPage:true});

    return request;
  }

  resetForm() {
    this.userSearchForm.reset();
    this.initFormGroup();
  }
  

  ngOnDestroy()
  {

  }

  deleteCookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

}
