/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./padetails-decimal-values.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./padetails-decimal-values.component";
import * as i4 from "../../../services/cp-ag-grid-service";
import * as i5 from "../../../../Shared/Services/global-app.service";
var styles_PadetailsDecimalValuesComponent = [i0.styles];
var RenderType_PadetailsDecimalValuesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PadetailsDecimalValuesComponent, data: {} });
export { RenderType_PadetailsDecimalValuesComponent as RenderType_PadetailsDecimalValuesComponent };
function View_PadetailsDecimalValuesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "textRed": 0, "textUnderlined": 1 }), (_l()(), i1.ɵted(3, null, ["", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.isErrorExist, (_co.isErrorHigh && _co.isPageEfileSearchResult)); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.params.value; _ck(_v, 3, 0, currVal_1); }); }
function View_PadetailsDecimalValuesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "textRed": 0, "textUnderlined": 1 }), (_l()(), i1.ɵted(3, null, ["", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.isErrorExist, (_co.isErrorHigh && _co.isPageEfileSearchResult)); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.params.euValue; _ck(_v, 3, 0, currVal_1); }); }
export function View_PadetailsDecimalValuesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PadetailsDecimalValuesComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PadetailsDecimalValuesComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.params.column.colId == "por"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.params.column.colId == "euPrice"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_PadetailsDecimalValuesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-padetails-decimal-values", [], null, null, null, View_PadetailsDecimalValuesComponent_0, RenderType_PadetailsDecimalValuesComponent)), i1.ɵdid(1, 180224, null, 0, i3.PadetailsDecimalValuesComponent, [i4.CpAgGridService, i5.GlobalAppService], null, null)], null, null); }
var PadetailsDecimalValuesComponentNgFactory = i1.ɵccf("app-padetails-decimal-values", i3.PadetailsDecimalValuesComponent, View_PadetailsDecimalValuesComponent_Host_0, {}, {}, []);
export { PadetailsDecimalValuesComponentNgFactory as PadetailsDecimalValuesComponentNgFactory };
