import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentAreaService {

  constructor() { }
  private contentArea = new BehaviorSubject(false);
  currentBlurStatus = this.contentArea.asObservable();  
  

  updateBlurStatus(updateBlurStatus:any){
    this.contentArea.next(updateBlurStatus);
  }
}
