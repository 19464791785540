import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { CbService } from '@app/Modules/chargeback/Services/cb.service';
import { HttpChargeBackService } from '@app/Modules/Shared/Services/http-charge-back.service';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { MessageService } from 'primeng/api';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
var EfileHistoryDownloadComponent = /** @class */ (function () {
    function EfileHistoryDownloadComponent(cbServ, httpServ, gridService, messageService, appServ) {
        this.cbServ = cbServ;
        this.httpServ = httpServ;
        this.gridService = gridService;
        this.messageService = messageService;
        this.appServ = appServ;
        this.isdownloadLatest = false;
        this.isdownloadOlder = false;
        this.subscription = new Subscription();
    }
    EfileHistoryDownloadComponent.prototype.agInit = function (params) {
        this.params = params;
        var HighestRowIndex = this.cbServ.getRowCount() - 1;
        if (this.params.rowIndex === 0) {
            this.isdownloadLatest = true;
            this.fileversion = 1;
        }
        else if (this.params.rowIndex > 0 && this.params.rowIndex === HighestRowIndex) {
            this.isdownloadOlder = true;
            this.fileversion = 0;
        }
    };
    EfileHistoryDownloadComponent.prototype.svcCall = function () {
        var _this = this;
        this.appServ.setLoadingStatus(true);
        var pageContext = this.gridService.pageContext;
        var request = {
            "claimFileId": pageContext.request.claimFileId,
            "salesOrgId": pageContext.request.salesOrgId,
            "fileversion": this.fileversion
        };
        this.subscription.add(this.httpServ.efileDownload(request).subscribe(function (res) {
            if (res && res.fileUrl) {
                window.open(res.fileUrl, '_blank');
            }
            else {
                _this.messageService.add({ key: 'efileDownload', detail: 'An unexpected error occurred. Please try again.', summary: 'Error', severity: 'error' });
            }
        }, function (e) {
            _this.messageService.add({ key: 'efileDownload', detail: 'An unexpected error occurred. Please try again.', summary: 'Error', severity: 'error' });
        }, function () {
            _this.appServ.setLoadingStatus(false);
        }));
    };
    EfileHistoryDownloadComponent.prototype.refresh = function (params) {
        return false;
    };
    EfileHistoryDownloadComponent.prototype.ngOnDestroy = function () {
    };
    EfileHistoryDownloadComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [CbService,
            HttpChargeBackService,
            CpAgGridService,
            MessageService,
            AppService])
    ], EfileHistoryDownloadComponent);
    return EfileHistoryDownloadComponent;
}());
export { EfileHistoryDownloadComponent };
