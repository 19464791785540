import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-list-row',
  templateUrl: './list-row.component.html',
  styleUrls: ['./list-row.component.scss']
})
export class ListRowComponent implements OnInit {
  @Input() rowObj: any;
  @Output() checkBoxChanged = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  onCustomerSelected(e){
    if(e.target.checked){
      let obj = {
        customerEU: this.rowObj.customerEU,
        checked: true
      }
      this.checkBoxChanged.emit(obj);
    } else {
      let obj = {
        customerEU: this.rowObj.customerEU,
        checked: false
      }
      this.checkBoxChanged.emit(obj);
    }
  }

}
