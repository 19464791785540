import { Component, OnInit, AfterViewInit } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectionService } from '../../Services/language-selection.service';
import { GlobalAppService } from '../../Services/global-app.service';

@Component({
  selector: 'app-final-logout',
  templateUrl: './final-logout.component.html',
  styleUrls: ['./final-logout.component.scss']
})
export class FinalLogoutComponent implements OnInit, AfterViewInit {
  isGlobalUser: any;
  region: any;
  lang: any;

  constructor(
    public _oktaAuth: OktaAuthService,
    private router: Router,
    private translateService: TranslateService,
    private languageServ: LanguageSelectionService,
    private _globalAppService: GlobalAppService) { }

  ngOnInit() {

    // this._globalAppService.getDefaultRegion().subscribe(region => {
    //   if (region) {
    //     this.region = region;
    //     this._globalAppService.getDefaultLanguage().subscribe(lang => {
    //       if (region && lang) {
    //         this.lang = lang;
    //         this.translateService.setDefaultLang(this.region + '/' + this.languageServ.getLanguageCode(this.region, this.lang).toLowerCase());
    //       }
    //     })
    //   }
    // });
    if (window.sessionStorage && sessionStorage.getItem("KCC.region") && sessionStorage.getItem("KCC.language"))
      this.translateService.setDefaultLang(sessionStorage.getItem("KCC.region") + '/' + this.languageServ.getLanguageCode(sessionStorage.getItem("KCC.region"), sessionStorage.getItem("KCC.language")).toLowerCase());
      
  }

  ngAfterViewInit()
  {
    if (window.sessionStorage) {
      window.sessionStorage.clear();
    }

    if (localStorage) {
      localStorage.removeItem('session-id');
    }
    
  }

  login() {
    this.router.navigateByUrl("/");
  }

}
