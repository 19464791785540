import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
var HyperlinkAndLegendComponent = /** @class */ (function () {
    function HyperlinkAndLegendComponent(router, activatedRoute, _appSer, gridService) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this._appSer = _appSer;
        this.gridService = gridService;
        this.showIcons = true;
    }
    HyperlinkAndLegendComponent.prototype.ngOnInit = function () {
    };
    HyperlinkAndLegendComponent.prototype.agInit = function (params) {
        this.isGlobalUser = this._appSer.isGlobalUser;
        this.params = params;
        if (this.params) {
            this.isGroupAdmin = this.params.data ? this.params.data.isGroupAdmin : false;
            this.isNonAssociatedUser = this.params.data ? this.params.data.isNonAssociatedUser : false;
        }
    };
    HyperlinkAndLegendComponent.prototype.refresh = function (params) {
        return false;
    };
    HyperlinkAndLegendComponent.prototype.onLinkClick = function () {
        var _this = this;
        if (this.gridService.pageContext.pageName == 'searchResultsCB') {
            switch (this.params.colDef.field) {
                case 'couponNumber':
                case 'errors':
                case 'netIssued':
                    window.open('http://www.google.com', '_blank');
                    break;
            }
        }
        else {
            switch (this.params.colDef.field) {
                case 'userId':
                    var selectedUser_1 = tslib_1.__assign({}, this.params.data);
                    this.router.navigate([{ outlets: { searchResults: null } }], { relativeTo: this.activatedRoute.parent }).then(function () {
                        _this.router.navigate(['manage-mapping', selectedUser_1.userId], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') }, relativeTo: _this.activatedRoute.parent, state: { selectedUser: selectedUser_1 } });
                    });
                    break;
                case 'customerNumber':
                    var selectedCustomer_1 = tslib_1.__assign({}, this.params.data);
                    this.router.navigate([{ outlets: { searchCustomer: null } }], { relativeTo: this.activatedRoute.parent }).then(function () {
                        _this.router.navigate([{ outlets: { customerHierarchy: 'customerHierarchy' } }], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') }, relativeTo: _this.activatedRoute.parent, state: { selectedCustomer: selectedCustomer_1 } });
                    });
                    break;
            }
        }
    };
    HyperlinkAndLegendComponent.prototype.ngOnDestroy = function () {
    };
    HyperlinkAndLegendComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [Router,
            ActivatedRoute,
            AppService,
            CpAgGridService])
    ], HyperlinkAndLegendComponent);
    return HyperlinkAndLegendComponent;
}());
export { HyperlinkAndLegendComponent };
