/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./selected-customer-eu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./selected-customer-eu.component";
var styles_SelectedCustomerEUComponent = [i0.styles];
var RenderType_SelectedCustomerEUComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectedCustomerEUComponent, data: {} });
export { RenderType_SelectedCustomerEUComponent as RenderType_SelectedCustomerEUComponent };
function View_SelectedCustomerEUComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "listItem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "far fa-times-circle closeIcon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeCustomerEU(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
export function View_SelectedCustomerEUComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "outerCont"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectedCustomerEUComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedCustomerEU; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SelectedCustomerEUComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-selected-customer-eu", [], null, null, null, View_SelectedCustomerEUComponent_0, RenderType_SelectedCustomerEUComponent)), i1.ɵdid(1, 114688, null, 0, i3.SelectedCustomerEUComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectedCustomerEUComponentNgFactory = i1.ɵccf("app-selected-customer-eu", i3.SelectedCustomerEUComponent, View_SelectedCustomerEUComponent_Host_0, { selectedCustomerEU: "selectedCustomerEU" }, { removeValue: "removeValue" }, []);
export { SelectedCustomerEUComponentNgFactory as SelectedCustomerEUComponentNgFactory };
