import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import 'ag-grid-enterprise';
import { LicenseManager } from "ag-grid-enterprise";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { TextEncoder } from 'text-encoding';

if (environment.production) {
  enableProdMode();
}

// polyfill TextEncoder for IE Edge
if (typeof (window as any).TextEncoder === 'undefined') {
  (window as any).TextEncoder = TextEncoder;
}

LicenseManager.setLicenseKey("Kimberly-Clark_KCP_Customer_Portal_single_1_Devs_1_Deployment_License_23_July_2020_[v2]_MTU5NTQ2MjQwMDAwMA==4bd2ac5f6891fbea3e38cd21fc917abc");

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
