import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { GlobalAppService } from './../../Shared/Services/global-app.service';
import { ribbonMenuExternalCB, ribbonMenuInternalCustomerCB } from '@app/conf/dataConfigurations';
import * as i0 from "@angular/core";
import * as i1 from "../../Shared/Services/http.service";
import * as i2 from "../../Shared/Services/app.service";
import * as i3 from "../../Shared/Services/global-app.service";
var CbService = /** @class */ (function () {
    function CbService(_http, appService, globalAppService) {
        this._http = _http;
        this.appService = appService;
        this.globalAppService = globalAppService;
        this._searchCriteria = {};
        this._efileSearchCriteria = {};
        this._mcSearchCriteria = {};
        this.searchRequestState = new BehaviorSubject(null);
        this.efileSearchRequestState = new BehaviorSubject(null);
        this.MCSearchRequestState = new BehaviorSubject(null);
        this.reloadEfileSearchGrid = new Subject();
        this._kcfileSearchCriteria = {};
        this.kcfileSearchRequestState = new BehaviorSubject(null);
        this.reloadKcfileSearchGrid = new Subject();
        this.custInfoDataHolder = [];
        this.locationTreeData = new Subject();
        this.deleted = new Subject();
        this.deletedSttausText = new Subject();
        this.submitStatusText = new Subject();
        this.searchRequestState$ = this.searchRequestState.asObservable();
        this._favoriteSearch = new Subject();
        this.recordCount = new BehaviorSubject(undefined);
        this.recordCount$ = this.recordCount.asObservable();
        this._isFilterOn = false;
        this.pageSize = 50;
        this.customerNo = [];
        this.stateList = [];
        this.gridLoaded = new Subject();
        this.mySearchesNavigation = new BehaviorSubject(false);
        this.typeAheadSelectedCustEU = new Subject();
        this.isRowDeleted = new Subject();
        this.deleteErrorLineRequestSubject = new Subject();
        this.efileManageErrorHeaderData = new Subject();
        this.efileDataErrorsCorrected = new Subject();
        this.efileValidatedColumnData = new Subject();
        this.kcfileManageErrorHeaderData = new Subject();
        this.kcfileDataErrorsCorrected = new Subject();
        this.kcfileValidatedColumnData = new Subject();
        this.menu = new BehaviorSubject(null);
        this.customerIds = [];
        this.setMenu();
    }
    CbService.prototype.setRowCount = function (count) {
        this.rowCount = count;
    };
    CbService.prototype.getRowCount = function () {
        return this.rowCount;
    };
    CbService.prototype.setCustEUList = function (value) {
        return this.typeAheadSelectedCustEU.next(value);
    };
    CbService.prototype.setSubmitContextMenu = function (value) {
        this.deleted.next(value);
    };
    CbService.prototype.getSubmitContextMenu = function () {
        return this.deleted.asObservable();
    };
    CbService.prototype.setDeletedStatusText = function (value, status, rowIndex) {
        this.deletedSttausText.next({ 'value': value, 'status': status, 'rowIndex': rowIndex });
    };
    CbService.prototype.getDeletedStatusText = function () {
        return this.deletedSttausText.asObservable();
    };
    CbService.prototype.setSubmitStatusText = function (value, rowIndex) {
        this.submitStatusText.next({ 'value': value, 'rowIndex': rowIndex });
    };
    CbService.prototype.getSubmitStatusText = function () {
        return this.submitStatusText.asObservable();
    };
    CbService.prototype.getCustEUList = function () {
        return this.typeAheadSelectedCustEU.asObservable();
    };
    CbService.prototype.setCustomerNo = function (CustomerData) {
        this.customerNo = CustomerData;
    };
    CbService.prototype.getCustomerNo = function () {
        return this.customerNo;
    };
    CbService.prototype.setCustInfoDataHolder = function (CustomerData) {
        this.custInfoDataHolder = CustomerData;
    };
    CbService.prototype.getCustInfoDataHolder = function () {
        return this.custInfoDataHolder;
    };
    CbService.prototype.setLocationCount = function (value) {
        this._locationCount = value;
    };
    CbService.prototype.getLocationCount = function () {
        return this._locationCount;
    };
    CbService.prototype.setStateList = function (CustomerData) {
        this.stateList = CustomerData;
    };
    CbService.prototype.getStateList = function () {
        return this.stateList;
    };
    CbService.prototype.setLocationData = function (tree) {
        this.locationTreeData = tree;
    };
    CbService.prototype.getLocationData = function () {
        return this.locationTreeData;
    };
    CbService.prototype.setLocationSelectedData = function (tree) {
        this.locationSelectedTreeData = tree;
    };
    CbService.prototype.getLocationSelectedData = function () {
        return this.locationSelectedTreeData;
    };
    CbService.prototype.setGridLoaded = function (value) {
        return this.gridLoaded.next(value);
    };
    CbService.prototype.getGridLoaded = function () {
        return this.gridLoaded.asObservable();
    };
    CbService.prototype.setGridApiContext = function (gridContext) {
        this._gridApiContext = gridContext;
    };
    CbService.prototype.getsearchData = function () {
        return this._searchCriteria;
    };
    CbService.prototype.initCBSearch = function (searchObj) {
        this._searchCriteria = searchObj;
    };
    CbService.prototype.getSearchRequest = function () {
        return this.searchRequestState.asObservable();
    };
    CbService.prototype.changeSearchRequest = function (searchObject) {
        this.appService.changeSearchRequest(searchObject);
        this.searchRequestState.next(searchObject);
    };
    CbService.prototype.getMCSearchRequest = function () {
        return this.MCSearchRequestState.asObservable();
    };
    CbService.prototype.changeMCSearchRequest = function (searchObject) {
        this.appService.changeSearchRequest(searchObject);
        this.MCSearchRequestState.next(searchObject);
    };
    CbService.prototype.getMCSearchData = function () {
        return this._mcSearchCriteria;
    };
    CbService.prototype.initMCSearch = function (searchObj) {
        this._mcSearchCriteria = searchObj;
    };
    CbService.prototype.geteFileSearchData = function () {
        return this._efileSearchCriteria;
    };
    CbService.prototype.initCBEFileSearch = function (searchObj) {
        this._efileSearchCriteria = searchObj;
    };
    CbService.prototype.getEfileSearchRequest = function () {
        return this.efileSearchRequestState.asObservable();
    };
    CbService.prototype.changeEfileSearchRequest = function (searchObject) {
        this.appService.changeSearchRequest(searchObject);
        this.efileSearchRequestState.next(searchObject);
    };
    CbService.prototype.getkcFileSearchData = function () {
        return this._kcfileSearchCriteria;
    };
    CbService.prototype.initCBKcFileSearch = function (searchObj) {
        this._kcfileSearchCriteria = searchObj;
    };
    CbService.prototype.getKcfileSearchRequest = function () {
        return this.kcfileSearchRequestState.asObservable();
    };
    CbService.prototype.changeKcfileSearchRequest = function (searchObject) {
        this.appService.changeSearchRequest(searchObject);
        this.kcfileSearchRequestState.next(searchObject);
    };
    CbService.prototype.getResultSet = function () {
        return this.resultSetData;
    };
    CbService.prototype.setResultSet = function (value) {
        if (value) {
            this.resultSetData = value.map(function (item) {
                return item;
            });
        }
    };
    CbService.prototype.getFilterOn = function () {
        return this._isFilterOn;
    };
    CbService.prototype.setFilterOn = function (value) {
        this._isFilterOn = value;
    };
    CbService.prototype.getSortOn = function () {
        return this._isSortOn;
    };
    CbService.prototype.setSortOn = function (value) {
        this._isSortOn = value;
    };
    CbService.prototype.initializCountZero = function () {
        this.recordCount.next(0);
    };
    CbService.prototype.setMySearchesNavigation = function (value) {
        this.mySearchesNavigation.next(value);
    };
    CbService.prototype.getMySearchesNavigation = function () {
        return this.mySearchesNavigation.asObservable();
    };
    CbService.prototype.markSearchFavorite = function (searchItem) {
        this._favoriteSearch.next(searchItem);
    };
    CbService.prototype.getMarkSearchFavorite = function () {
        return this._favoriteSearch;
    };
    CbService.prototype.initiateRowDelete = function (value) {
        this.isRowDeleted.next(value);
    };
    CbService.prototype.implementRowDelete = function () {
        return this.isRowDeleted.asObservable();
    };
    CbService.prototype.sortHandler = function (params, sortOrder) {
        if (sortOrder)
            this._gridApiContext.setSortModel([{ sort: sortOrder, colId: params.column.colId }]);
        else
            this._gridApiContext.setSortModel(null);
    };
    CbService.prototype.loadCustomerLocations = function (results) {
        results = results.map(function (d) {
            var row = Object.assign({}, d);
            row.CustomerNumber = d.customerNumber;
            row.CustomerName = d.name;
            row.City = d.city;
            row.State = d.state;
            return row;
        });
        for (var ctr = 0; ctr <= results.length; ctr++) {
            this.recursiveProcessNode(results[ctr], ctr, results);
        }
        var temp = results.filter(function (n) {
            return n.tree;
        });
        return temp;
    };
    CbService.prototype.recursiveProcessNode = function (CURRENT_NODE, CURRENT_NODE_INDEX, ALL_DATA) {
        if (CURRENT_NODE && CURRENT_NODE['parentCustomer']) {
            var PREV_NODE_INDEX = CURRENT_NODE_INDEX === 0 ? -1 : CURRENT_NODE_INDEX - 1;
            if (PREV_NODE_INDEX >= 0) {
                var PREV_NODE = ALL_DATA[PREV_NODE_INDEX];
                if (PREV_NODE['customerNumber'] === CURRENT_NODE['parentCustomer']) {
                    if (!PREV_NODE.children) {
                        Object.assign(PREV_NODE, { 'children': [] });
                    }
                    PREV_NODE.children.push(CURRENT_NODE);
                }
                else {
                    this.recursiveProcessNode(CURRENT_NODE, PREV_NODE_INDEX, ALL_DATA);
                }
            }
        }
        else {
            if (CURRENT_NODE && !CURRENT_NODE.hasOwnProperty('tree')) {
                Object.assign(CURRENT_NODE, { 'tree': true });
            }
        }
    };
    CbService.prototype.setDeleteOrDownloadPayload = function (req) {
        this.deleteErrorLineRequest = req;
        this.deleteErrorLineRequestSubject.next(req);
    };
    CbService.prototype.getDeleteOrDownloadPayload = function () {
        return this.deleteErrorLineRequest;
    };
    CbService.prototype.getDeleteOrDownloadPayloadObservable = function () {
        return this.deleteErrorLineRequestSubject.asObservable();
    };
    CbService.prototype.setHeaderData = function (obj) {
        this.efileManageErrorHeaderData.next({
            'efileName': obj.efileName,
            'efileId': obj.efileId,
            'uploadedBy': obj.uploadedBy,
            'uploaded': obj.uploaded,
            'validLines': obj.validLines,
            'errorLines': obj.errorLines,
            'eFileClaimTypeId': obj.eFileClaimTypeId
        });
    };
    CbService.prototype.setHeaderDataKcFile = function (obj) {
        this.kcfileManageErrorHeaderData.next({
            'kcfileName': obj.kcfileName,
            'kcfileId': obj.kcfileId,
            'uploadedBy': obj.uploadedBy,
            'uploaded': obj.uploaded,
            'validLines': obj.validLines,
            'errorLines': obj.errorLines
        });
    };
    CbService.prototype.getHeaderData = function () {
        return this.efileManageErrorHeaderData.asObservable();
    };
    CbService.prototype.setEfileCorrectedStatus = function (data) {
        this.efileDataErrorsCorrected.next(data);
    };
    CbService.prototype.getEfileCorrectedStatus = function () {
        return this.efileDataErrorsCorrected.asObservable();
    };
    CbService.prototype.setEfileValidatedColumsData = function (data) {
        this.efileValidatedColumnData.next(data);
    };
    CbService.prototype.getEfileValidatedColumsData = function () {
        return this.efileValidatedColumnData.asObservable();
    };
    CbService.prototype.getHeaderDataKcFile = function () {
        return this.kcfileManageErrorHeaderData.asObservable();
    };
    CbService.prototype.setKcfileCorrectedStatus = function (data) {
        this.kcfileDataErrorsCorrected.next(data);
    };
    CbService.prototype.getKcfileCorrectedStatus = function () {
        return this.kcfileDataErrorsCorrected.asObservable();
    };
    CbService.prototype.setKcfileValidatedColumsData = function (data) {
        this.kcfileValidatedColumnData.next(data);
    };
    CbService.prototype.getKcfileValidatedColumsData = function () {
        return this.kcfileValidatedColumnData.asObservable();
    };
    CbService.prototype.setRecordCountForMCDownload = function (count) {
        this.recordcountForMCDownload = count;
    };
    CbService.prototype.getRecordCountForMCDownload = function () {
        return this.recordcountForMCDownload;
    };
    Object.defineProperty(CbService.prototype, "efileSearchQuery", {
        get: function () {
            return this.efileSearchQueryStore;
        },
        set: function (query) {
            this.efileSearchQueryStore = query;
        },
        enumerable: true,
        configurable: true
    });
    CbService.prototype.setEfileSearchGridReload = function (value) {
        this.reloadEfileSearchGrid.next(value);
    };
    CbService.prototype.getEfileSearchGridReload = function () {
        return this.reloadEfileSearchGrid.asObservable();
    };
    Object.defineProperty(CbService.prototype, "kcfileSearchQuery", {
        get: function () {
            return this.kcfileSearchQueryStore;
        },
        set: function (query) {
            this.kcfileSearchQueryStore = query;
        },
        enumerable: true,
        configurable: true
    });
    CbService.prototype.setKcfileSearchGridReload = function (value) {
        this.reloadKcfileSearchGrid.next(value);
    };
    CbService.prototype.getKcfileSearchGridReload = function () {
        return this.reloadKcfileSearchGrid.asObservable();
    };
    CbService.prototype.setKcfileCustomerIds = function (CustomerData) {
        this.customerIds = CustomerData;
    };
    CbService.prototype.getKcfileCustomerIds = function () {
        return this.customerIds;
    };
    CbService.prototype.getKcfilesearchData = function () {
        return this._searchCriteria;
    };
    CbService.prototype.initKcfileSearch = function (searchObj) {
        this._searchCriteria = searchObj;
    };
    CbService.prototype.setLocationForEU = function (locData) {
        this.locationData = locData;
    };
    CbService.prototype.getLocationForEU = function () {
        return this.locationData;
    };
    CbService.prototype.setMenu = function () {
        var _this = this;
        this.globalAppService.getDefaultRegion().subscribe(function (region) {
            if (_this.appService.isInternalUser) {
                _this.menu.next(ribbonMenuInternalCustomerCB[region]);
            }
            else {
                _this.menu.next(ribbonMenuExternalCB[region]);
            }
            return _this.menu;
        });
    };
    CbService.prototype.getMenu = function () {
        return this.menu;
    };
    CbService.ngInjectableDef = i0.defineInjectable({ factory: function CbService_Factory() { return new CbService(i0.inject(i1.HttpService), i0.inject(i2.AppService), i0.inject(i3.GlobalAppService)); }, token: CbService, providedIn: "root" });
    return CbService;
}());
export { CbService };
