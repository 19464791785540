<div>
    <p-dropdown name="accessOptions" [(ngModel)]="selectedValue" [options]="singelActionOptions" [autoWidth]="false" id="accessOptions" dropdownIcon="select__Arrow" styleClass="form-control  SumoSelect" appendTo="body" (onChange)="onChange($event)" [panelStyleClass]="'efile-access-dropdown'"
        [disabled]="!isAdminRoleModify">
        <ng-template let-item pTemplate="selectedItem">
            <i *ngIf="item.value == true" class="fas fa-check-circle"></i>
            <i *ngIf="item.value == false" class="fas fa-times-circle"></i> {{item.customLabel | translate}}
        </ng-template>
        <ng-template let-item pTemplate="item" disabled="item.value == selectedValue">
            <div *ngIf="item.value != selectedValue">
                <i *ngIf="item.value == true" class="fas fa-check-circle"></i>
                <i *ngIf="item.value == false" class="fas fa-times-circle"></i> {{item.label| translate}}
            </div>
        </ng-template>
    </p-dropdown>
</div>