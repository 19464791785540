<div class="header__nav">
        <div class="header__nav__professional-link">
                <a href="{{kcProfessionalLink}}" target="_blank" rel="noopener noreferrer">{{ "humburger.kcpTitle" | translate }}
                        <i class="far fa-arrow-alt-circle-right"></i></a>
        </div>
        <!-- Added the spinner -->
        <div #mainTag>
                <div class="loadingImage" *ngIf="!menu">
                        &nbsp;
                </div>
        </div>
        <div class="d-md-none">
                <ul class="unlisted">
                        <li class="header__nav__settings" (click)="passclicked('settings')"><span><i
                                                class="fas fa-cog"></i> {{ "humburger.settingsLabel"| translate }}</span></li>
                        <li class="header__nav__support" (click)="passclicked('support')"><span><i
                                                class="fas fa-question"></i> {{ "humburger.supportLabel"| translate }}</span></li>
                </ul>
        </div>
</div>