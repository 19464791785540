import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { contactsLink } from "@app/conf/appLink";
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { KCPContent } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
var contactusRightSidebarComponent = /** @class */ (function () {
    function contactusRightSidebarComponent(translateServ, languageServ, _appService, _globalAppService, httpServ) {
        this.translateServ = translateServ;
        this.languageServ = languageServ;
        this._appService = _appService;
        this._globalAppService = _globalAppService;
        this.httpServ = httpServ;
        this.subscription = new Subscription();
        this.TemplateConfig = {
            "Customer_Care": {
                "display": true
            },
            "Customer_Service": {
                "display": true
            }
        };
        this.isCountryUSAorCa = false;
    }
    contactusRightSidebarComponent.prototype.ngOnInit = function () {
        // this.subscription.add(this.languageServ.currentSelected.subscribe((language) => {
        //   this.translateServ.setDefaultLang(language.regionCode);
        // }));
        var _this = this;
        this.mailtolink = contactsLink.emailLink;
        this.loadcomponentConfig();
        var selectedCountryLang = this._globalAppService.getSelectedCountryLang();
        this.region = this._globalAppService.getDefaultRegionString();
        this.country = selectedCountryLang.country || this._globalAppService.getDefaultCountryString();
        this.language = selectedCountryLang.language || this._globalAppService.getDefaultLanguageString();
        this.createContactLinks(this.region);
        this.languageServ.getlanguage().subscribe(function (data) {
            var selectedCountryLang = _this._globalAppService.getSelectedCountryLang();
            _this.country = selectedCountryLang.country;
            _this.language = selectedCountryLang.language;
            _this.salesOrg = selectedCountryLang.salesOrg;
            _this.createContactLinks(_this.region);
        });
    };
    contactusRightSidebarComponent.prototype.createContactLinks = function (region) {
        var _this = this;
        var contactRequest = this.createContactRequest(region);
        this.httpServ.getKCPContent(contactRequest).subscribe(function (data) {
            console.log("Ranjith contact data", data);
            if (data && data.result && data.result.length) {
                _this.allData = JSON.parse(data.result[0].data);
                _this.allCountryId = data.result[0].countryId;
                if (_this.allData && _this.allData.contactUs && _this.allData.contactUs.customerService && _this.allData.contactUs.customerService.country) {
                    var country = _this.allData.contactUs.customerService.country;
                    if (country.toLowerCase() === 'usa' || country.toLowerCase() === 'canada') {
                        _this.isCountryUSAorCa = true;
                    }
                }
            }
        });
    };
    contactusRightSidebarComponent.prototype.createContactRequest = function (region) {
        this.contactRequest = new KCPContent();
        this.contactRequest.regionId = region;
        this.contactRequest.countryId = this.country;
        this.contactRequest.languageCode = this.language;
        // this.contactRequest.salesOrgId = +this._globalAppService.getSalesOrg();
        // this.contactRequest.salesOrgId = 7042;
        this.contactRequest.salesOrgId = this.salesOrg;
        this.contactRequest.contentTypes = this.contentTypeRequest();
        return this.contactRequest;
    };
    contactusRightSidebarComponent.prototype.contentTypeRequest = function () {
        var contentTypeArr = [];
        var myObj = Object.assign({});
        myObj.contentType = 'Contacts';
        contentTypeArr.push(myObj);
        return contentTypeArr;
    };
    contactusRightSidebarComponent.prototype.loadcomponentConfig = function () {
        var _this = this;
        this._globalAppService.getDefaultRegion().subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var HomeContactSectionConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!data) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._appService.dynamicImport(data)];
                    case 1:
                        HomeContactSectionConfig = (_a.sent()).HomeContactSectionConfig;
                        Object.assign(this.TemplateConfig, HomeContactSectionConfig);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
    contactusRightSidebarComponent.prototype.ngOnDestroy = function () {
        //Destroying component
    };
    contactusRightSidebarComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [TranslateService,
            LanguageSelectionService,
            AppService,
            GlobalAppService,
            HttpService])
    ], contactusRightSidebarComponent);
    return contactusRightSidebarComponent;
}());
export { contactusRightSidebarComponent };
