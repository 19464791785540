/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sfdc-pa-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sfdc-pa-details.component";
import * as i3 from "../../../services/cp-ag-grid-service";
import * as i4 from "../../../../Shared/Services/global-app.service";
import * as i5 from "@angular/router";
var styles_SfdcPaDetailsComponent = [i0.styles];
var RenderType_SfdcPaDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SfdcPaDetailsComponent, data: {} });
export { RenderType_SfdcPaDetailsComponent as RenderType_SfdcPaDetailsComponent };
export function View_SfdcPaDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLinkClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 1, 0, currVal_0); }); }
export function View_SfdcPaDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sfdc-pa-details", [], null, null, null, View_SfdcPaDetailsComponent_0, RenderType_SfdcPaDetailsComponent)), i1.ɵdid(1, 180224, null, 0, i2.SfdcPaDetailsComponent, [i3.CpAgGridService, i4.GlobalAppService, i5.Router], null, null)], null, null); }
var SfdcPaDetailsComponentNgFactory = i1.ɵccf("app-sfdc-pa-details", i2.SfdcPaDetailsComponent, View_SfdcPaDetailsComponent_Host_0, {}, {}, []);
export { SfdcPaDetailsComponentNgFactory as SfdcPaDetailsComponentNgFactory };
