import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoicePriceService {

  private updateFavHeaderStatus: Subject<boolean> = new Subject<boolean>();
  private isRedrawRow: Subject<any> = new Subject<any>();
  private showProcessingPopup: Subject<any> = new Subject<any>();
  private showEmailPopup: Subject<any> = new Subject<any>();
  private reloadDownloadAllPage: Subject<boolean> = new Subject<boolean>();
  private updateCategoryStatus: Subject<any> = new Subject<any>();
  private showErrorMessage: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  setUpdateFavColHeader(updateStatus) {
    this.updateFavHeaderStatus.next(updateStatus);
  }

  getUpdateFavColHeader(): Observable<boolean> {
    return this.updateFavHeaderStatus.asObservable();
  }

  setRedrawAdvancedGridRow(favData){
    this.isRedrawRow.next(favData);
  }

  getRedrawAdvancedGridRow(): Observable<any> {
    return this.isRedrawRow.asObservable();
  }

  setProcessingPopupVisible(categoryData){
    this.showProcessingPopup.next(categoryData);
  }

  getProcessingPopupVisible(): Observable<any> {
    return this.showProcessingPopup.asObservable();
  }

  setEmailPopupVisible(emailData){
    this.showEmailPopup.next(emailData);
  }

  getEmailPopupVisible(): Observable<any> {
    return this.showEmailPopup.asObservable();
  }

  setReloadDownloadAllPage(isReload){
    this.reloadDownloadAllPage.next(isReload);
  }

  getReloadDownloadAllPage(): Observable<any> {
    return this.reloadDownloadAllPage.asObservable();
  }

  setUpdateRefreshStatusRequest(statusData){
    this.updateCategoryStatus.next(statusData);
  }

  getUpdateRefreshStatusRequest(): Observable<any> {
    return this.updateCategoryStatus.asObservable();
  }

  setErrorMsg(showError){
    this.showErrorMessage.next(showError);
  }

  getErrorMsg(): Observable<any> {
    return this.showErrorMessage.asObservable();
  }
}
