<div>
    <div class="innerCont">
        <div class="form-group">
            <input class="form-control" [(ngModel)]="searchText" [placeholder]="placeHolder || 'CustomerEU# to search...'" type="text" (ngModelChange)="this.userInput.next($event)">
        </div>
        <div class="listWrapper">
            <div class="listCont" [@fadeInOut] *ngIf="selectedCustomers.length && !mappedCustEUList.length">
                <app-selected-customer-eu [selectedCustomerEU]="selectedCustomers" (removeValue)="removeCustomerEU($event)"></app-selected-customer-eu>
            </div>
            <div class="listCont" [@fadeInOut] *ngIf="mappedCustEUList && mappedCustEUList.length">
                <div *ngFor="let val of mappedCustEUList;  let i = index">
                    <app-list-row [rowObj]="val" (checkBoxChanged)="onCheckboxChange($event)"></app-list-row>
                </div>
            </div>
        </div>
        <div class="buttonWrapper">
            <button class="btn btn-primary btn-primary--disabled btn-primary--icon-left" [disabled]="!selectedCustomers.length" [class.btn-primary--disabled]="!selectedCustomers.length" (click)="onApplyClick($event)">
        <i class="far fa-check-circle"></i>
        {{ "calender.apply" | translate }}
      </button>
            <span (click)="onRemoveFilter()" class="clearFilter">
        <i class="far fa-times-circle" aria-hidden="true"></i> {{ "calender.clear" | translate }}
      </span>
        </div>
    </div>
</div>