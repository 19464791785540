import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { moduleType } from '@app/conf/dataConfigurations';

@Component({
  selector: 'app-module-type-column',
  templateUrl: './module-type-column.component.html',
  styleUrls: ['./module-type-column.component.scss']
})
export class ModuleTypeColumnComponent implements AgRendererComponent, OnDestroy  {

  params: any;
  type: string;

  constructor(
  ) { }

  agInit(params: any): void {
    this.params = params;
    if(params && params.data && params.data.moduleType){
      this.mapModuleType(params.data.moduleType);
    }
  }

  mapModuleType(typeValue){
    let moduleName = moduleType.filter(type => {
      return type.value === typeValue;
    });
    if(moduleName && moduleName.length){
      this.type = moduleName[0].label;
    } else {
      this.type = typeValue;
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }

}
