/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-width-row.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./full-width-row.component";
var styles_FullWidthRowComponent = [i0.styles];
var RenderType_FullWidthRowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullWidthRowComponent, data: {} });
export { RenderType_FullWidthRowComponent as RenderType_FullWidthRowComponent };
function View_FullWidthRowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 1, 0, currVal_0); }); }
function View_FullWidthRowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "error-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-exclamation-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorDescription; _ck(_v, 2, 0, currVal_0); }); }
function View_FullWidthRowComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "footer-title-holder"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.getLabel(_co.params))); _ck(_v, 1, 0, currVal_0); }); }
export function View_FullWidthRowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "full-width-error-line"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullWidthRowComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullWidthRowComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullWidthRowComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isGroup; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isGroup; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.params.colDef.field == "id"); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_FullWidthRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-full-width-row", [], null, null, null, View_FullWidthRowComponent_0, RenderType_FullWidthRowComponent)), i1.ɵdid(1, 49152, null, 0, i4.FullWidthRowComponent, [], null, null)], null, null); }
var FullWidthRowComponentNgFactory = i1.ɵccf("app-full-width-row", i4.FullWidthRowComponent, View_FullWidthRowComponent_Host_0, {}, {}, []);
export { FullWidthRowComponentNgFactory as FullWidthRowComponentNgFactory };
