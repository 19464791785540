/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hyperlink-and-favorite.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./hyperlink-and-favorite.component";
import * as i4 from "../../../../Shared/Services/http.service";
import * as i5 from "../../../../Shared/Services/app.service";
import * as i6 from "../../../services/cp-ag-grid-service";
import * as i7 from "../../../../Shared/Services/global-app.service";
var styles_HyperlinkAndFavoriteComponent = [i0.styles];
var RenderType_HyperlinkAndFavoriteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HyperlinkAndFavoriteComponent, data: {} });
export { RenderType_HyperlinkAndFavoriteComponent as RenderType_HyperlinkAndFavoriteComponent };
export function View_HyperlinkAndFavoriteComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { favicon: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.onLinkHover() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.onLinkMouseout() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLinkClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, [" ", "\n"])), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["favicon", 1]], null, 3, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFavClick(_co.params) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(6, { "noEvent": 0, "hideIcon": 1 }), (_l()(), i1.ɵted(-1, null, ["\u00A0"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 6, 0, _co.isGlobalUser, _co.isPageKCFilePA); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 3, 0, currVal_0); }); }
export function View_HyperlinkAndFavoriteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hyperlink-and-favorite", [], null, null, null, View_HyperlinkAndFavoriteComponent_0, RenderType_HyperlinkAndFavoriteComponent)), i1.ɵdid(1, 245760, null, 0, i3.HyperlinkAndFavoriteComponent, [i4.HttpService, i5.AppService, i1.Renderer2, i6.CpAgGridService, i7.GlobalAppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HyperlinkAndFavoriteComponentNgFactory = i1.ɵccf("app-hyperlink-and-favorite", i3.HyperlinkAndFavoriteComponent, View_HyperlinkAndFavoriteComponent_Host_0, {}, {}, []);
export { HyperlinkAndFavoriteComponentNgFactory as HyperlinkAndFavoriteComponentNgFactory };
