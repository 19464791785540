import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-custom-loading-overlay',
  templateUrl: './custom-loading-overlay.component.html',
  styleUrls: ['./custom-loading-overlay.component.scss']
})
export class CustomLoadingOverlayComponent implements ILoadingOverlayAngularComp {

  constructor() { }

  agInit() {
  }

}
