/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./price-formatter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./price-formatter.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../Shared/Services/language-selection.service";
import * as i6 from "../../../../Shared/Services/global-app.service";
import * as i7 from "../../../services/cp-ag-grid-service";
var styles_PriceFormatterComponent = [i0.styles];
var RenderType_PriceFormatterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PriceFormatterComponent, data: {} });
export { RenderType_PriceFormatterComponent as RenderType_PriceFormatterComponent };
function View_PriceFormatterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.numericValue; _ck(_v, 1, 0, currVal_0); }); }
function View_PriceFormatterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 1, 0, currVal_0); }); }
export function View_PriceFormatterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PriceFormatterComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PriceFormatterComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formattedValue; _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.formattedValue && (_co.params.colDef.field == "casePrice")); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_PriceFormatterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-price-formatter", [], null, null, null, View_PriceFormatterComponent_0, RenderType_PriceFormatterComponent)), i1.ɵdid(1, 180224, null, 0, i3.PriceFormatterComponent, [i1.LOCALE_ID, i4.TranslateService, i5.LanguageSelectionService, i6.GlobalAppService, i7.CpAgGridService], null, null)], null, null); }
var PriceFormatterComponentNgFactory = i1.ɵccf("app-price-formatter", i3.PriceFormatterComponent, View_PriceFormatterComponent_Host_0, {}, {}, []);
export { PriceFormatterComponentNgFactory as PriceFormatterComponentNgFactory };
