import { Component, OnInit } from '@angular/core';
import { InvoicePriceService } from '@app/Modules/in-voice-new/Services/invoice-price.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { HttpInvoicePriceNewService } from '@app/Modules/Shared/Services/http-invoice-price-new.service';

@Component({
  selector: 'advanced-invoicenew-favourite',
  templateUrl: './advanced-invoicenew-favourite.component.html',
  styleUrls: ['./advanced-invoicenew-favourite.component.scss']
})
export class AdvancedInvoiceNewFavouriteComponent implements OnInit {

  params: any;
  isFavorite: boolean = false;
  isGlobalUser: boolean = false;

  constructor(private httpInvoiceService: HttpInvoicePriceNewService,
              private appService: AppService,
              private invoicePriceService: InvoicePriceService) { }

  agInit(params: any) {
    this.params = params;
    if(params.data && params.data.isFavorite){
      this.isFavorite = true;
    } else {
      this.isFavorite = false;
    }
    this.isGlobalUser = this.appService.isGlobalUser;
  }

  ngOnInit() {
  }

  setUnsetFavourite(isSetToFav){
    this.appService.setLoadingStatus(true);
    let request = {
      userId: this.appService.getuserId(false),
      favoriteCategoryList: [
        {
          categoryCode: this.params.data.categoryCode,
          isFavorite: isSetToFav
        }
      ]
    };
    this.httpInvoiceService.saveFavoriteInvoiceCategory(request).subscribe( res => {
      if(res.statusMessage == "Success"){
        this.invoicePriceService.setRedrawAdvancedGridRow([{isFavorite: isSetToFav, categoryCode: this.params.data.categoryCode}]);
      }      
    }, (e) => {

    }, () => {
      this.appService.setLoadingStatus(false);
    });
  }

}
