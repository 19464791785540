/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./advanced-invoicenew-favourite.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./advanced-invoicenew-favourite.component";
import * as i4 from "../../../../Shared/Services/http-invoice-price-new.service";
import * as i5 from "../../../../Shared/Services/app.service";
import * as i6 from "../../../../in-voice-new/Services/invoice-price.service";
var styles_AdvancedInvoiceNewFavouriteComponent = [i0.styles];
var RenderType_AdvancedInvoiceNewFavouriteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdvancedInvoiceNewFavouriteComponent, data: {} });
export { RenderType_AdvancedInvoiceNewFavouriteComponent as RenderType_AdvancedInvoiceNewFavouriteComponent };
function View_AdvancedInvoiceNewFavouriteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "blueText"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setUnsetFavourite(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "far fa-heart"]], null, null, null, null, null))], null, null); }
function View_AdvancedInvoiceNewFavouriteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "blueText"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setUnsetFavourite(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-heart"]], null, null, null, null, null))], null, null); }
function View_AdvancedInvoiceNewFavouriteComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "blueTextNoClick"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "far fa-heart"]], null, null, null, null, null))], null, null); }
function View_AdvancedInvoiceNewFavouriteComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "blueTextNoClick"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-heart"]], null, null, null, null, null))], null, null); }
export function View_AdvancedInvoiceNewFavouriteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdvancedInvoiceNewFavouriteComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdvancedInvoiceNewFavouriteComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdvancedInvoiceNewFavouriteComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdvancedInvoiceNewFavouriteComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isFavorite && !_co.isGlobalUser); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.isFavorite && !_co.isGlobalUser); _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.isFavorite && _co.isGlobalUser); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.isFavorite && _co.isGlobalUser); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_AdvancedInvoiceNewFavouriteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "advanced-invoicenew-favourite", [], null, null, null, View_AdvancedInvoiceNewFavouriteComponent_0, RenderType_AdvancedInvoiceNewFavouriteComponent)), i1.ɵdid(1, 114688, null, 0, i3.AdvancedInvoiceNewFavouriteComponent, [i4.HttpInvoicePriceNewService, i5.AppService, i6.InvoicePriceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdvancedInvoiceNewFavouriteComponentNgFactory = i1.ɵccf("advanced-invoicenew-favourite", i3.AdvancedInvoiceNewFavouriteComponent, View_AdvancedInvoiceNewFavouriteComponent_Host_0, {}, {}, []);
export { AdvancedInvoiceNewFavouriteComponentNgFactory as AdvancedInvoiceNewFavouriteComponentNgFactory };
