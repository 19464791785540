import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss']
})
export class TestPageComponent implements OnInit {

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  callPardotAPI(){
    let url = "https://go.pardot.com/l/322901/2019-09-09/23lrrs?hdUserFirstName=shib&hdUserLastName=alwa&hdUserEmail=shibi.alwar@kcc.com&hdCompanyName=KCC&hdCountry=US"
    this.http.post(url,{}).subscribe((response)=>{
      console.log("@@@@ Pardot Post Response: ", response)
    });
  }

}
