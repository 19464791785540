/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./status-column.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./status-column.component";
import * as i4 from "../../../services/cp-ag-grid-service";
var styles_StatusColumnComponent = [i0.styles];
var RenderType_StatusColumnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatusColumnComponent, data: {} });
export { RenderType_StatusColumnComponent as RenderType_StatusColumnComponent };
export function View_StatusColumnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.status)); _ck(_v, 0, 0, currVal_0); }); }
export function View_StatusColumnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-status-column", [], null, null, null, View_StatusColumnComponent_0, RenderType_StatusColumnComponent)), i1.ɵdid(1, 180224, null, 0, i3.StatusColumnComponent, [i4.CpAgGridService], null, null)], null, null); }
var StatusColumnComponentNgFactory = i1.ɵccf("app-status-column", i3.StatusColumnComponent, View_StatusColumnComponent_Host_0, {}, {}, []);
export { StatusColumnComponentNgFactory as StatusColumnComponentNgFactory };
