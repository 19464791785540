import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'hyperlink-and-popup',
  templateUrl: './hyperlink-and-popup.component.html',
  styleUrls: ['./hyperlink-and-popup.component.scss']
})

export class HyperlinkAndPopupComponent implements AgRendererComponent, OnDestroy {

  params: any;
 
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute  ) { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClick() {
      switch (this.params.colDef.field) {
        case 'customerComments':
          let selectedCustomer = { ...this.params.data };
          this.router.navigate([{ outlets: { dialogs: ['multipleChanges'] } }], { relativeTo: this.activatedRoute.parent, state: { selectedCustomer: selectedCustomer } });
          break;
      }
  }

  ngOnDestroy() {

  }

}
