import { Component, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { GlobalAppService } from './../../../../Shared/Services/global-app.service';

@Component({
  selector: 'efile-edit-error-line',
  templateUrl: './efile-edit-error-line.component.html',
  styleUrls: ['./efile-edit-error-line.component.scss']
})

export class EfileEditErrorLineComponent implements AgRendererComponent, OnInit {

  params: any;
  isInternalUser: boolean = false;
  isGlobalUser: boolean = false;
  region: string;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appServ: AppService,
    private cpAgGridService: CpAgGridService,
    private globalAppService: GlobalAppService
  ) {
  }

  agInit(params: any) {
    this.params = params;
  }

  ngOnInit() {
    this.isInternalUser = this.appServ.isInternalUser;
    this.isGlobalUser = this.appServ.isGlobalUser;
    this.region = this.globalAppService.getDefaultRegionString();
  }
  refresh(params: any): boolean {
    return true;
  }

  efileValidationErrors() {
    let fileDetails = this.cpAgGridService.getEFileErrorData().fileDetails;
    let errorDataArray = this.cpAgGridService.getEFileErrorData().errorData;
    errorDataArray = errorDataArray.filter(item => {
      return !item.isDeleted;
    });
    let Obj = {
      "errorData": errorDataArray,
      "fileDetails": fileDetails
    }
    let rawErrorData = Object.assign({}, Obj, { "currentErrorLine": this.params.data.line });
    this.router.navigate([{ outlets: { editModalDialog: ['editModal'] } }], { relativeTo: this.activatedRoute, state: { 'fileErrorData': rawErrorData }, queryParamsHandling: "preserve" });
  }
}
