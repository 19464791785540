import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { GlobalAppService } from './global-app.service';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global-app.service";
var HttpInvoicePriceNewService = /** @class */ (function () {
    function HttpInvoicePriceNewService(http, _globalAppService) {
        this.http = http;
        this._globalAppService = _globalAppService;
    }
    //fetch region specific url from environment.ts
    HttpInvoicePriceNewService.prototype.getRegionSpecificURL = function (item) {
        var regionURL = "";
        var region = localStorage.getItem('default-region') || this._globalAppService.getDefaultRegionString() || this._globalAppService.selectedRegion;
        if (region) {
            regionURL = "url_" + region.toUpperCase();
        }
        else {
            regionURL = "url_NA";
        }
        return item[regionURL];
    };
    HttpInvoicePriceNewService.prototype.makeHTTPCall = function (method, endPoint, additionalHeaders, requestParams) {
        if (additionalHeaders === void 0) { additionalHeaders = null; }
        if (requestParams === void 0) { requestParams = null; }
        var requestBody = {};
        if (additionalHeaders) {
            requestBody = Object.assign({}, requestBody, { headers: additionalHeaders });
        }
        if (requestParams) {
            requestBody = Object.assign({}, requestBody, { body: requestParams });
        }
        return this.http.request(method, endPoint, requestBody);
    };
    HttpInvoicePriceNewService.prototype.GetInvoicePriceStatusByCustomerID = function (request) {
        return this.makeHTTPCall(environment.GetInvoicePriceStatusByCustomerID_new.method, this.getRegionSpecificURL(environment.GetInvoicePriceStatusByCustomerID_new), null, request);
    };
    HttpInvoicePriceNewService.prototype.priceRefreshRequest = function (request) {
        return this.makeHTTPCall(environment.PriceRefreshRequest_new.method, this.getRegionSpecificURL(environment.PriceRefreshRequest_new), null, request);
    };
    HttpInvoicePriceNewService.prototype.exportInvoiceGrid = function (requestObj) {
        return this.makeHTTPCall(environment.exportInvoiceGrid_new.method, this.getRegionSpecificURL(environment.exportInvoiceGrid_new), null, requestObj);
    };
    HttpInvoicePriceNewService.prototype.saveFavoriteInvoice = function (request) {
        return this.makeHTTPCall(environment.saveFavoriteInvoice_new.method, this.getRegionSpecificURL(environment.saveFavoriteInvoice_new), null, request);
    };
    HttpInvoicePriceNewService.prototype.downloadInvoicePrice = function (request) {
        return this.makeHTTPCall(environment.DownloadInvoicePrice_new.method, this.getRegionSpecificURL(environment.DownloadInvoicePrice_new), null, request);
    };
    HttpInvoicePriceNewService.prototype.notifyInvoicePriceRequestByEmail = function (request) {
        return this.makeHTTPCall(environment.NotifyInvoicePriceRequestByEmail_new.method, this.getRegionSpecificURL(environment.NotifyInvoicePriceRequestByEmail_new), null, request);
    };
    HttpInvoicePriceNewService.prototype.getFavoriteCusotmerInvoice_new = function (request) {
        return this.makeHTTPCall(environment.GetFavoriteCusotmerInvoice_new.method, this.getRegionSpecificURL(environment.GetFavoriteCusotmerInvoice_new), null, request);
    };
    HttpInvoicePriceNewService.prototype.invoiceGetDistinctFilterForGrid = function (requestObj, gridName) {
        switch (gridName) {
            case 'invoiceSearchResult':
            case 'invoiceFavourite':
                return this.makeHTTPCall(environment.InvoiceSearchGridFilter_new.method, this.getRegionSpecificURL(environment.InvoiceSearchGridFilter_new), null, requestObj);
            default:
                return of(true);
        }
    };
    HttpInvoicePriceNewService.prototype.invoicePriceSaveSearch = function (request) {
        return this.makeHTTPCall(environment.InvoicePriceSaveSearch_new.method, this.getRegionSpecificURL(environment.InvoicePriceSaveSearch_new), null, request);
    };
    HttpInvoicePriceNewService.prototype.getRecentSearch = function () {
        return this.makeHTTPCall(environment.invoicePriceGetRecentSearch_new.method, this.getRegionSpecificURL(environment.invoicePriceGetRecentSearch_new), null, null);
    };
    HttpInvoicePriceNewService.prototype.getFavoriteSearch = function () {
        return this.makeHTTPCall(environment.invoicePriceGetFavoriteSearch_new.method, this.getRegionSpecificURL(environment.invoicePriceGetFavoriteSearch_new), null, null);
    };
    HttpInvoicePriceNewService.prototype.saveFavoriteSearch = function (searchId) {
        return this.makeHTTPCall(environment.saveFavoriteSearch_new.method, this.getRegionSpecificURL(environment.saveFavoriteSearch_new), null, {
            id: searchId,
            "name": "",
            "moduleType": "InvoicePrice"
        });
    };
    HttpInvoicePriceNewService.prototype.removeFavoriteSearch = function (searchId) {
        return this.makeHTTPCall(environment.removeFavoriteSearch_new.method, this.getRegionSpecificURL(environment.removeFavoriteSearch_new), null, {
            id: searchId,
            "name": "",
            "moduleType": "InvoicePrice"
        });
    };
    HttpInvoicePriceNewService.prototype.savedSearchName = function (tileId, name) {
        return this.makeHTTPCall(environment.saveSearchName_new.method, this.getRegionSpecificURL(environment.saveSearchName_new), null, {
            "id": tileId,
            "name": name,
            "moduleType": "InvoicePrice"
        });
    };
    HttpInvoicePriceNewService.prototype.IsPriceRequestAllowedforUser_new = function (request) {
        return this.makeHTTPCall(environment.IsPriceRequestAllowedforUser_new.method, this.getRegionSpecificURL(environment.IsPriceRequestAllowedforUser_new), null, request);
        // return Observable.of(
        //   {
        //     isAllowed: true,
        //     maxAllowedRequest: 5
        //   }
        // )
    };
    HttpInvoicePriceNewService.prototype.recentRequestGetDistinctFilterForGrid = function (requestObj, gridName) {
        switch (gridName) {
            case 'recentRequests':
                return this.makeHTTPCall(environment.RecentRefreshRequestGridFilter_new.method, this.getRegionSpecificURL(environment.RecentRefreshRequestGridFilter_new), null, requestObj);
            default:
                return of(true);
        }
    };
    HttpInvoicePriceNewService.prototype.RecentRefreshRequestExport = function (requestObj) {
        return this.makeHTTPCall(environment.RecentRefreshRequestExport_new.method, this.getRegionSpecificURL(environment.RecentRefreshRequestExport_new), null, requestObj);
    };
    HttpInvoicePriceNewService.prototype.saveFavoriteInvoiceCategory = function (request) {
        return this.makeHTTPCall(environment.SaveFavoriteInvoiceCategory_new.method, this.getRegionSpecificURL(environment.SaveFavoriteInvoiceCategory_new), null, request);
    };
    HttpInvoicePriceNewService.prototype.getFavoriteInvoiceCategory = function (request) {
        return this.makeHTTPCall(environment.GetFavoriteCategoryInvoice_new.method, this.getRegionSpecificURL(environment.GetFavoriteCategoryInvoice_new), null, request);
    };
    HttpInvoicePriceNewService.prototype.getCategoriesByCustomerNumber = function (request) {
        return this.makeHTTPCall(environment.GetCategoriesByCustomerNumber_new.method, this.getRegionSpecificURL(environment.GetCategoriesByCustomerNumber_new), null, request);
    };
    HttpInvoicePriceNewService.prototype.UserCustomerCountriesByCustomerType = function (reqHeader) {
        if (reqHeader === void 0) { reqHeader = null; }
        return this.makeHTTPCall(environment.UserCustomerCountriesByCustomerType.method, this.getRegionSpecificURL(environment.UserCustomerCountriesByCustomerType), reqHeader, null);
    };
    HttpInvoicePriceNewService.ngInjectableDef = i0.defineInjectable({ factory: function HttpInvoicePriceNewService_Factory() { return new HttpInvoicePriceNewService(i0.inject(i1.HttpClient), i0.inject(i2.GlobalAppService)); }, token: HttpInvoicePriceNewService, providedIn: "root" });
    return HttpInvoicePriceNewService;
}());
export { HttpInvoicePriceNewService };
