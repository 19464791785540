<header>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="header">
                    <div class="header__left">
                        <a class="header__left__logo" [ngClass]="{'disabled-prefernce':!defaultCountry}" href="javascript:void(0)" (click)="redirectHome()"><img src="/assets/images/KCP-logo.png"></a>
                        <h1 class="header__left__heading">
                            <span class="bold-heading" *ngIf="headerTextIndex == 'primaryHeader' || headerTextIndex == 'myProfileHeader'">{{ "header.pageHeader."+headerTextIndex+".part1"| translate}}</span>
                            <span *ngIf="headerTextIndex == 'primaryHeader' || headerTextIndex == 'myProfileHeader'" [hidden]="!defaultCountry && !countryLangSelctionHidden" class="header__left__heading__divider"></span>
                            <span [hidden]="!defaultCountry && !countryLangSelctionHidden">{{ "header.pageHeader."+headerTextIndex+".part2"| translate}}</span>

                            <span class="internalView" *ngIf="isInternalUser && !showGlobalUserTag">{{ "header.pageHeader.Internal" | translate}}</span>
                            <span class="cvtView" *ngIf="showGlobalUserTag; else cvtTag">{{ "header.pageHeader.Global" | translate}}</span>
                            <ng-template #cvtTag>
                                <span class="cvtView" *ngIf="isCVT && !isMyProfilePage">{{ "header.pageHeader.CVT" | translate}}</span>
                            </ng-template>

                        </h1>
                        <ng-container *ngIf="!hideIfDelegatedSearchPage">
                            <div class="header__left__hamburger no-select" (click)="toggleSidebar('menu')" [hidden]="!defaultCountry">
                                <button type="button" class="tcon tcon-menu--xcross" [ngClass]="{'tcon-transform' :showHamburger}" aria-label="toggle menu">
                                    <span class="tcon-menu__lines" aria-hidden="true"></span>
                                    <span class="tcon-visuallyhidden">{{ "header.toggleMenu" | translate}}</span>
                                </button>
                                <span class="d-none d-md-inline-block" [translate]="'header.menuTitle'"></span>
                            </div>
                        </ng-container>
                        <!-- <p-tieredMenu #menu [model]="items" [popup]="true" ></p-tieredMenu> -->
                    </div>

                    <!-- <div class="header__right">
                        <div class="header__right__utilities unlisted inline no-select">
                            <ul>
                                <li class="header__right__utilities__settings d-none d-md-inline-block">
                                    <i class="fas fa-user-circle" [ngClass]="{'menuactive': visibleSettingsGlobalHomePage}" (click)="toggleSidebar('settingsGlobalHomePage')"></i>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                    <div class="header__right">
                        <div class="header__right__utilities unlisted inline no-select" [ngClass]="{ 'header__right__utilities-globalUser' : isGlobalRoleAssigned }">
                            <a *ngIf="isGlobalRoleAssigned && defaultCountry" class="changeGlobalUser" href="javascript:void(0)" (click)="actionBtnMenu.toggle($event)">{{'delegatedUser.changeUser' | translate}} <i
                           class="fa sign-in-alt"></i></a>
                            <p-overlayPanel appendTo="body" styleClass="contextMenu actionBtnMenuOverlay change-user-menu" #actionBtnMenu hideEvent="mousedown">
                                <span class="custom-table__popup__info custom-table__row-menu">
                                    <ul class="custom-table__row-menu__content actionBtnWrapper">
                                        <li class="action-items" (click)="loginExistingUser()">{{globalUserName}}</li>
                                        <hr class="global-menu-line">
                                        <li class="action-items" [ngClass]="{'disabled' : isDelegatedPageOpen}" (click)="changeGlobalUser()">{{'delegatedUser.selectUser' | translate}}</li>
                                    </ul>
                                </span>
                            </p-overlayPanel>
                            <ul class="">
                                <li class="header__right__utilities__account d-md-none">
                                    <i class="fas fa-times"></i>
                                    <i class="fas fa-user" [ngClass]="{'menuactive': visibleLoggedPanel}" (click)="toggleSidebar('loggedPanel')"></i>
                                </li>
                                <li class="header__right__utilities__support d-none d-md-inline-block">
                                    <i [hidden]="!defaultCountry" class="far fa-question-circle" [ngClass]="{'menuactive': visibleSupport}" (click)="toggleSidebar('support')"></i>
                                </li>
                                <li class="header__right__utilities__settings d-none d-md-inline-block">
                                    <i [hidden]="!defaultCountry" class="fas fa-user-circle" [ngClass]="{'menuactive': visibleSettings}" (click)="toggleSidebar('settings')"></i>
                                </li>
                                <li class="header__right__utilities__settings d-none d-md-inline-block">
                                    <img [hidden]="!defaultCountry && !countryLangSelctionHidden" width="18" height="18" src="assets/images/international-flags/{{selecteCountry}}.svg" [ngClass]="{'menuactive': visiblePreferenceMenu, 'disabled-prefernce': !defaultCountry}" (click)="toggleSidebar('preferenceMenu')"
                                    />
                                </li>
                            </ul>
                        </div>
                        <div class="header__right__account" [hidden]="!defaultCountry && !countryLangSelctionHidden">
                            <div class="header__right__account__heading d-md-none">
                                <h2>{{'header.loggedInAs' | translate}}</h2>
                            </div>
                            <div class="tooltip-hover" [class.tooltip-hover--open]="customerToolTip">
                                <ul class="unlisted">
                                    <li class="header__right__account__username" (mouseover)="customerToolTip=true" (mouseout)="customerToolTip=false">{{_username }}</li>
                                    <ng-container *ngIf="region == 'EMEA' && isInternalUser && !isGlobalUser; else userDetail">
                                        <li>EMEA KCP</li>
                                    </ng-container>
                                    <ng-template #userDetail>
                                        <li>{{user?.name1}}</li>
                                        <li>{{user?.address1}}</li>
                                        <li>{{user?.city}}, {{user?.state}} {{user?.postalCode}}</li>
                                    </ng-template>
                                    <li *ngIf="salesOrgLabel && !showAllTagForEMEA" class="saleorg-ellises" [title]="salesOrgLabel.value">
                                        {{ salesOrgLabel.value }}
                                    </li>
                                    <li *ngIf="salesOrgLabel && showAllTagForEMEA" class="saleorg-ellises">
                                        {{ salesOrgLabel.value }}
                                    </li>

                                    <!-- <li class="header__right__account-type">
                              <p-dropdown [options]="profession" [style]="{'border':'none', 'width':'150px'}"
                                 dropdownIcon="select__Arrow" [disabled]="true">
                                 <ng-template let-item pTemplate="selectedItem">
                                    {{item.label | translate}}
                                 </ng-template>
                                 <ng-template let-item pTemplate="item">
                                    {{item.label| translate}}
                                 </ng-template>
                              </p-dropdown>
                           </li> -->
                                </ul>
                                <div class="tooltip-hover__popup">
                                    <span>{{ "header.customer" | translate }}{{user?.customerNumber}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p-sidebar [(visible)]="visibleHamburger" position="left" class="header__utility-sidebar header__hamburger" [style]="{top: '100px','padding':'0'}" (onHide)="hideSidebar('menu');" (onShow)="showSidebar();">

        <div class="header__nav__professional-link d-none d-md-block">
            <!-- <a href="#">KCProfessional.com <i class="far fa-arrow-alt-circle-right"></i></a> -->
        </div>
        <app-hamburger (clickedValue)="clickedValue($event)"></app-hamburger>
    </p-sidebar>

    <p-sidebar [(visible)]="visibleSupport" position="right" class="header__utility-sidebar" (onHide)="hideSidebar();" (onShow)="showSidebar();" [style]="{top: '100px',padding:'0'}">
        <div class="header__nav  d-md-none">
            <div class="header__nav__professional-link">
                <a href="#">{{ "humburger.kcpTitle"| translate}} <i class="far fa-arrow-alt-circle-right"></i></a>
            </div>
        </div>
        <div class="header__utility-pullout__heading-mobile d-md-none" [translate]="'supportNav.title'"></div>
        <div class="header__utility-pullout__heading d-none d-md-block">
            <h2 [translate]="'supportNav.title'"></h2>
        </div>
        <ul class="unlisted">
            <!-- <li *ngIf="isInternalUser; else external">
            <a href={{applink.helpLink}} [translate]="'supportNav.link1'"></a>
         </li>
         <ng-template #external>
            <li><a style="color: grey; cursor: default;" [translate]="'supportNav.link1'"></a></li>
         </ng-template> -->
            <!--<li *ngIf="region !== 'LAO'">
                <a (click)="walkmeSetUp($event)" [translate]="'supportNav.link1'"></a>
            </li>-->
            <li>
                <a (click)="contactUs()" [translate]="'supportNav.link2'"></a>
            </li>
        </ul>
    </p-sidebar>

    <p-sidebar [(visible)]="visibleSettings" position="right" class="header__utility-sidebar" (onHide)="hideSidebar();" (onShow)="showSidebar();" [style]="{top: '100px',padding:'0'}">
        <div class="header__nav  d-md-none">
            <div class="header__nav__professional-link">
                <a href="http://www.kcprofessional.com/" target="_blank">{{"humburger.kcpTitle"| translate}} <i
                  class="far fa-arrow-alt-circle-right"></i></a>
            </div>
        </div>
        <div class="header__utility-pullout__heading-mobile d-md-none" [translate]="'settingsNav.title'"></div>
        <div class="header__utility-pullout__heading d-none d-md-block">
            <h2 [translate]="'settingsNav.title'"></h2>
        </div>
        <ul class="unlisted" *ngIf="headerTextIndex !== 'delegatedHeader'; else delegatedPageSettingMenu">
            <li *ngIf="isInternalUser && region == 'NA'">
                <a href={{applink.contentMangementTool}} [translate]="'settingsNav.contentMang'"></a>
            </li>
            <li>
                <a [class.disableLink]="isDisabled" (click)="profileLink($event)" [translate]="'settingsNav.profile'"></a>
            </li>
            <li *ngIf="isInternalUser && isAdminRole" class="sub-list">
                <!--<li *ngIf="isInternalUser" class="sub-list">-->
                <div [translate]="'settingsNav.subheading1'"></div>
                <ul class="unlisted">
                    <li *ngIf="region === 'LAO'">
                        <a href="javascript:void(0)" (click)="openAccessManagementLink()" [translate]="'settingsNav.subheading1Link3'"></a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="portalUsage()" [translate]="'settingsNav.subheading1Link4'"></a>
                    </li>
                    <li *ngIf="region === 'NA'">
                        <a href="javascript:void(0)" (click)="reportsLink()" [translate]="'settingsNav.reports'"></a>
                    </li>
                    <li *ngIf="region === 'NA'">
                        <a href="javascript:void(0)" (click)="cmtReportsLink()" [translate]="'settingsNav.cmtReports'"></a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="ucMapping()" [translate]="'settingsNav.subheading1Link6'"></a>
                    </li>
                    <!-- <li *ngIf="region != 'NA'">
                        <a href="javascript:void(0)" (click)="openAccessManagementLink()" [translate]="'settingsNav.subheading1Link7'"></a>
                    </li> -->
                    <li *ngIf="region === 'NA' || region === 'EMEA'">
                        <a href="javascript:void(0)" (click)="efileKcfileAccesslink()" [translate]="'settingsNav.eFileAccess'"></a>
                    </li>
                </ul>
            </li>

            <li #isExternalAdminUser class="sub-list">
                <div [translate]="'settingsNav.subheading1'"></div>
                <ul class="unlisted">
                    <li *ngIf="region != 'LAO'">
                        <a href="javascript:void(0)" (click)="openAccessManagementLink()" [translate]="'settingsNav.subheading1Link3'"></a>
                    </li>
                    <!-- manage users -->
                    <li>
                        <a href="javascript:void(0)" [translate]="'settingsNav.subheading1Link4'" (click)="portalUsage()"></a>
                    </li>
                    <!-- Portal Usage Report -->
                    <li *ngIf="region === 'NA'">
                        <a target="_blank" href={{applink.adminManualLink}} [translate]="'settingsNav.subheading1Link5'"></a>
                    </li>
                    <!-- <li *ngIf="region === 'LAO'">
                        <a target="_blank" href={{applink.adminManualLinkLAO}} [translate]="'settingsNav.subheading1Link5'"></a>
                    </li> -->
                    <!-- TODO update the Manual link when available from business -->
                    <li *ngIf="region === 'EMEA'">
                        <a target="_blank" href={{applink.adminManualLinkLAO}} [translate]="'settingsNav.subheading1Link5'"></a>
                    </li>
                    <!-- Site Administrator Manual -->
                </ul>
            </li>
            <!--<li *ngIf="isInternalUser">
                <a href="{{applink.kcProfessionalLink}}" target="_blank">EFile/K-C File Access</a>
            </li>-->

            <li>
                <a href="javascript:void(0)" (click)="logout()" [translate]="'settingsNav.logout'"></a>
            </li>
        </ul>
        <ng-template #delegatedPageSettingMenu>
            <ul class="unlisted">
                <li>
                    <a href="javascript:void(0)" (click)="logout()" [translate]="'settingsNav.logout'"></a>
                </li>
            </ul>
        </ng-template>
    </p-sidebar>

    <p-sidebar [(visible)]="visiblePreferenceMenu" position="right" class="header__utility-sidebar" (onHide)="hideSidebar();" (onShow)="showSidebar();" [style]="{top: '100px',padding:'0'}">
        <div class="header__nav  d-md-none">
            <div class="header__nav__professional-link">
                <a href="http://www.kcprofessional.com/" target="_blank">{{ " header.regionsLabel "| translate}} <i
                  class="far fa-arrow-alt-circle-right"></i></a>
            </div>
        </div>
        <div class="header__utility-pullout__heading-mobile d-md-none" [translate]="'regionNav.region'"></div>
        <div class="header__utility-pullout__heading d-none d-md-block">
            <h2 [translate]="'settingsNav.region'"></h2>
        </div>

        <div class="form-group">
            <label for="access1" class="hidden-text-for-accessibility">{{ " header.accessLabel "| translate}}</label>

            <p-dropdown [options]="displayCountry" [(ngModel)]="defaultCountry" [autoWidth]="true" styleClass="form-control countrySelect" dropdownIcon="select__Arrow" (onChange)="onCountryChange($event)">

                <ng-template let-item pTemplate="selectedItem">
                    <img width="18" height="18" src="assets/images/international-flags/{{item.value | lowercase }}.svg" />
                    <span>{{item.label}}</span>
                </ng-template>
                <ng-template let-item pTemplate="item">
                    <img width="18" height="18" src="assets/images/international-flags/{{item.value | lowercase}}.svg" />
                    <span>{{item.label}}</span>
                </ng-template>
            </p-dropdown>
        </div>
        <div class="form-group">
            <p-dropdown [options]="displayLanguage" [(ngModel)]="preferredLanguage" [autoWidth]="true" styleClass="form-control countrySelect" dropdownIcon="select__Arrow" (onChange)="onLanguageChange($event)">
            </p-dropdown>
        </div>
    </p-sidebar>

    <p-sidebar [(visible)]="visibleSettingsGlobalHomePage" position="right" class="header__utility-sidebar" (onHide)="hideSidebar();" (onShow)="showSidebar();" [style]="{top: '100px',padding:'0'}">
        <div class="header__utility-pullout__heading d-none d-md-block">
            <h2 [translate]="'settingsNav.title'"></h2>
        </div>
        <ul class="unlisted">
            <li>
                <a href="javascript:void(0)" (click)="logout()" [translate]="'settingsNav.logout'"></a>
            </li>
        </ul>
    </p-sidebar>
</header>