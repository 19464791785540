import { OnInit, EventEmitter } from '@angular/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
var SortComponent = /** @class */ (function () {
    function SortComponent(gridService) {
        this.gridService = gridService;
        this.colSet = [
            { colID: 'contractNumber', type: 'number' },
            { colID: 'contractId', type: 'number' },
            { colID: 'contractName', type: 'string' },
            { colID: 'customerNumber', type: 'number' },
            { colID: 'customerName', type: 'string' },
            { colID: 'description', type: 'string' },
            { colID: 'typedescription', type: 'string' },
            { colID: 'contractSubTypeName', type: 'string' },
            { colID: 'startDate', type: 'number' },
            { colID: 'endDate', type: 'number' },
            { colID: 'lastChanged', type: 'number' },
            { colID: 'status', type: 'string' },
            { colID: 'lastDownload', type: 'number' },
            { colID: 'change', type: 'string' },
            { colID: 'updatedDate', type: 'number' },
            { colID: 'updateDate', type: 'number' },
            { colID: 'expiring', type: 'string' },
            { colID: 'territoryNumber', type: 'number' },
            { colID: 'territoryName', type: 'string' },
            { colID: 'tradeOrgNumber', type: 'number' },
            { colID: 'tradeOrgAddress', type: 'string' },
            { colID: 'groupNumber', type: 'number' },
            { colID: 'ag-Grid-AutoColumn', type: 'number' },
            { colID: 'groupName', type: 'string' },
            { colID: 'address', type: 'string' },
            { colID: 'ShortProductCode', type: 'number' },
            { colID: 'shortProductCode', type: 'number' },
            { colID: 'ProductDescription', type: 'string' },
            { colID: 'uom', type: 'string' },
            { colID: 'itemsPerCase', type: 'number' },
            { colID: 'itemsPerCase_1', type: 'number' },
            { colID: 'caseBreakDown', type: 'number' },
            { colID: 'price', type: 'number' },
            { colID: 'comments', type: 'string' },
            { colID: 'caseBreakdownUOM', type: 'string' },
            { colID: 'casePrice', type: 'number' },
            { colID: 'twelveMonthSales', type: 'string' },
            { colID: 'twelveMonthVolume', type: 'string' },
            { colID: 'itemsPerCase', type: 'number' },
            { colID: 'startDate', type: 'number' },
            { colID: 'endDate', type: 'number' },
            { colID: 'endUserNumber', type: 'number' },
            { colID: 'EUName', type: 'string' },
            { colID: 'address', type: 'string' },
            { colID: 'CP_Product_Display_Code', type: 'number' },
            { colID: 'CP_Product_Code', type: 'number' },
            { colID: 'CP_Hiearachy_Desc', type: 'string' },
            { colID: 'contractNumber', type: 'number' },
            { colID: 'requestedDate', type: 'number' },
            { colID: 'companyName', type: 'string' },
            { colID: 'fullName', type: 'string' },
            { colID: 'address', type: 'string' },
            { colID: 'city', type: 'string' },
            { colID: 'state', type: 'string' },
            { colID: 'zipCode', type: 'number' },
            { colID: 'businessPhone', type: 'number' },
            { colID: 'status', type: 'number' },
            { colID: 'customerNumber', type: 'number' },
            { colID: 'CustomerNumber', type: 'number' },
            { colID: 'CP_Alt_Product_Code', type: 'number' },
            { colID: 'AltProductCode', type: 'number' },
            { colID: 'EndUser_Name', type: 'string' },
            { colID: 'CustAddress', type: 'string' },
            { colID: 'CustState', type: 'string' },
            { colID: 'ZipCode', type: 'number' },
            { colID: 'customerEndDate', type: 'number' },
            { colID: 'customerStartDate', type: 'number' },
            { colID: 'CP_Phone_Number', type: 'number' },
            { colID: 'fileName', type: 'string' },
            { colID: 'size', type: 'number' },
            { colID: 'availableUntil', type: 'number' },
            { colID: 'lastDownloadedDate', type: 'number' },
            { colID: 'CustomerNumber', type: 'number' },
            { colID: 'HQ_Name', type: 'string' },
            { colID: 'CustAddress', type: 'string' },
            { colID: 'City', type: 'string' },
            { colID: 'CustState', type: 'string' },
            { colID: 'ZipCode', type: 'number' },
            { colID: 'CP_Customer_Number', type: 'number' },
            { colID: 'CP_Customer_Name_1', type: 'string' },
            { colID: 'CP_Street_1', type: 'string' },
            { colID: 'CP_CP_City', type: 'string' },
            { colID: 'CP_State_Name', type: 'string' },
            { colID: 'CP_Zip_Code', type: 'number' },
            { colID: 'CP_Customer_Type', type: 'string' },
            { colID: 'legacyPANumber', type: 'number' },
            { colID: 'postalCode', type: 'number' },
            { colID: 'passwordExpiryDate', type: 'number' },
            { colID: 'loginCount', type: 'number' },
            { colID: 'lastLoginDate', type: 'number' },
            { colID: 'lastLoginDate__1', type: 'number' },
            { colID: 'phone', type: 'number' },
            { colID: 'firmEndDate', type: 'number' },
            { colID: 'euPrice', type: 'number' },
            { colID: 'por', type: 'number' },
            { colID: 'volume', type: 'number' },
            { colID: 'lastChangedDate', type: 'number' },
            { colID: 'lastUpdatedDate', type: 'number' }
        ];
        this.ascClass = 'fas fa-sort-alpha-down';
        this.dscClass = 'fas fa-sort-alpha-down-alt';
        this.onSortRequested = new EventEmitter();
    }
    Object.defineProperty(SortComponent.prototype, "colId", {
        get: function () {
            return this._colId;
        },
        set: function (value) {
            this._colId = value;
        },
        enumerable: true,
        configurable: true
    });
    SortComponent.prototype.ngOnInit = function () {
        var _this = this;
        var obj = this.colSet.find(function (item) {
            if (item.colID === _this.colId)
                return item;
        });
        if (obj) {
            if (obj.type == 'number') {
                this.ascClass = 'fa fa-sort-numeric-asc';
                this.dscClass = 'fa fa-sort-numeric-desc';
            }
            else {
                this.ascClass = 'fas fa-sort-alpha-down';
                this.dscClass = 'fas fa-sort-alpha-down-alt';
            }
        }
        else {
            this.ascClass = 'fas fa-sort-alpha-down';
            this.dscClass = 'fas fa-sort-alpha-down-alt';
        }
    };
    SortComponent.prototype.onClick = function (event, value) {
        if (value.toLowerCase() === 'reset')
            value = null;
        // if (value !== this.previousValue) {
        //   this.previousValue = value;
        //   this.gridService.sortHandler(this.orgColId, value);
        // }
        this.previousValue = value;
        this.gridService.sortHandler(this.orgColId, value);
        this.onSortRequested.emit(true);
    };
    return SortComponent;
}());
export { SortComponent };
