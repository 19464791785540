import * as CryptoJS from 'crypto-js';
import * as i0 from "@angular/core";
var EncrDecrService = /** @class */ (function () {
    function EncrDecrService() {
        this.encryptSecretKey = "CustomerPortalCustomer Portal";
    }
    EncrDecrService.prototype.encryptData = function (data) {
        try {
            return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
        }
        catch (e) {
            console.log(e);
        }
    };
    EncrDecrService.prototype.decryptData = function (data) {
        try {
            var bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
            if (bytes.toString()) {
                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            }
            return data;
        }
        catch (e) {
            console.log(e);
        }
    };
    EncrDecrService.ngInjectableDef = i0.defineInjectable({ factory: function EncrDecrService_Factory() { return new EncrDecrService(); }, token: EncrDecrService, providedIn: "root" });
    return EncrDecrService;
}());
export { EncrDecrService };
