import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { CbService } from '@app/Modules/chargeback/Services/cb.service';

@Component({
  selector: 'contextmenumc',
  templateUrl: './contextmenumc.component.html',
  styleUrls: ['./contextmenumc.component.scss']
})

export class ContextmenumcComponent implements AgRendererComponent, OnDestroy {

  ellipseSelect: boolean = false;
  params: any;
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gridService: CpAgGridService,
    private cbServ: CbService
  ) { }

  agInit(params: any) {
    this.params = params;
  }

  refresh(params: any): boolean {
    return true;
  }

  openMeetCompDetails(){
    if(this.params && this.params.data && this.params.data.meetCompNumber && this.params.data.contractNumber && this.params.data.status){
      let mcNumber = this.params.data.contractNumber;
      let customerNumber = this.params.data.customerNumber;
      let mcStatus = parseInt(this.params.data.status);
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/meetcomps/meetcompsDetails/details'], { relativeTo: this.activatedRoute.parent, queryParams: { mcNumber: mcNumber, customerNumber: customerNumber, mcStatus: mcStatus } }));
      window.open(url, '_blank');
    }    
  }

  openDownloadDialog(){
    this.router.navigate([{ outlets: { dialogs: ['downloadSelected'] } }], { relativeTo: this.activatedRoute.parent, state: this.getDownloadContext() });
  }

  getDownloadContext() {
    let downloadContext: any = new Object();
    let selectedrows: Array<any> = [];
    selectedrows.push(this.params.data);
    downloadContext.request = this.gridService.getSvcRequest();
    downloadContext.selectedRows = selectedrows;
    downloadContext.totalCount = this.cbServ.getRecordCountForMCDownload();
    downloadContext.title = 'agGrid.agGridHeading.downloadSelectedLabel';
    return downloadContext;
  }

  contextmenuToggle(element, e){
    element.toggle(e);
  }

  onContextMenuHide(){
    this.ellipseSelect = false;
  }

  onContextMenuShow(){
    this.ellipseSelect = true;
  }

  ngOnDestroy(){
    
  }

}
