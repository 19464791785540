<div #cpContainer>
  <cp-sort-component #sortComponent [colId]="colId" [orgColId]="orgColId" (onSortRequested)="onSortRequested($event)">
  </cp-sort-component>
  <div class="form-group form-group--date-picker">
    <div class="form-group--date-picker__group {{calendarText}}"
      style="margin-bottom: 10px; border-right: 1px solid #CCCCCC !important; border-radius: 3px;">
      <p-calendar #fromCalendar name="fromDate" [maxDate]="maxDateValue" [placeholder]="dateFormat"
        [dateFormat]="primengDateFormat" yearRange="1900:2100" styleClass="calendarClass dateRangeCalendar"
        [monthNavigator]="true" [yearNavigator]="true" [locale]="calenderData" [(ngModel)]="txtFromDate"
        (onSelect)="onFromDateSelected($event)" [inputStyle]="{'width':'100%'}" [autoZIndex]="true"
        (onInput)="onDateInput($event)" [baseZIndex]="1000000" panelStyleClass="ag-custom-component-popup"
        (onFocus)="onCalFromFocus(cpContainer)"
        (onClose)="OnCalFromClosed(cpContainer)">
      </p-calendar>
    </div>
    <br>
    <div class="form-group--date-picker__group {{calendarText}}">
      <p-calendar #toCalendar name="toDate" [maxDate]="maxDateValue" [monthNavigator]="true" [yearNavigator]="true"
        yearRange="1900:2100" [placeholder]="dateFormat" [dateFormat]="primengDateFormat"
        styleClass="calendarClass dateRangeToCalendar Cal115Width" [locale]="calenderData" [(ngModel)]="txtToDate"
        (onSelect)="onToDateSelected($event)" [autoZIndex]="true" (onInput)="onDateInput($event)" [baseZIndex]="1000000"
        panelStyleClass="ag-custom-component-popup"
        (onFocus)="onCalToFocus(cpContainer)"
        (onClose)="OnCalToClosed(cpContainer)">
      </p-calendar>
    </div>
  </div>
  <div class=" filter-validation" *ngIf="showMessage && !isDateRangeSelected">
    {{ 'agGrid.agGridDateFilterValidation' | translate }}
  </div>
  <div class="text-right">
    <button type="button" class="btn btn-primary btn-primary--disabled btn-primary--icon-left"
      [disabled]="!isDateRangeSelected" [class.btn-primary--disabled]="!isDateRangeSelected"
      (click)="onApplyClick($event)">
      <i class="far fa-check-circle"></i>
      {{ "calender.apply" | translate }}
    </button>
  </div>
  <!-- 
  <p-button label="Apply" class="btn-filters" [class.disable]="!isDateRangeSelected" [disabled]="!isDateRangeSelected"
    (click)="onApplyClick($event)">
  </p-button> -->
</div>