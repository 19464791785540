import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { InvoicePriceService } from '@app/Modules/in-voice-new/Services/invoice-price.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { HttpInvoicePriceNewService } from '@app/Modules/Shared/Services/http-invoice-price-new.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
var FavoriteComponent = /** @class */ (function () {
    function FavoriteComponent(httpInvoiceServ, appService, invoiceServ, appServ) {
        this.httpInvoiceServ = httpInvoiceServ;
        this.appService = appService;
        this.invoiceServ = invoiceServ;
        this.appServ = appServ;
        this.isFavorite = false;
        this.isGlobalUser = false;
        this.test = false;
    }
    FavoriteComponent.prototype.ngOnInit = function () {
    };
    FavoriteComponent.prototype.agInit = function (params) {
        var _this = this;
        this.params = params;
        var favoriteCustomerNumbers = this.invoiceServ.favoritecustomerList;
        if (favoriteCustomerNumbers) {
            if (params && params.data && params.data.customerNumber) {
                var customerNumber_1 = params.data.customerNumber;
                if (favoriteCustomerNumbers && favoriteCustomerNumbers.length) {
                    favoriteCustomerNumbers.find(function (item) {
                        if (item == customerNumber_1) {
                            _this.isFavorite = true;
                        }
                    });
                }
            }
        }
        this.isGlobalUser = this.appService.isGlobalUser;
    };
    FavoriteComponent.prototype.refresh = function (params) {
        return false;
    };
    FavoriteComponent.prototype.setUnsetFavourite = function (isSetToFav) {
        var _this = this;
        this.isFavorite = !this.isFavorite;
        this.appService.setLoadingStatus(true);
        var request = {
            userId: this.appService.getuserId(false),
            favoriteCustomerList: [
                {
                    customerNumber: this.params.data.customerNumber,
                    isFavorite: this.isFavorite
                }
            ]
        };
        this.httpInvoiceServ.saveFavoriteInvoice(request).subscribe(function (res) {
            if (res.statusMessage == "Success") {
                // this.invoiceServ.setRedrawAdvancedGridRow([{isFavorite: isSetToFav, customerNumber: this.params.data.customerNumber}]);
                _this.test = !_this.test;
                _this.getFavoriteCustomer();
            }
        }, function (e) {
        }, function () {
            _this.appService.setLoadingStatus(false);
        });
    };
    FavoriteComponent.prototype.getFavoriteCustomer = function () {
        var _this = this;
        var userId = this.appServ.getuserId(true);
        var request = {
            userId: userId
        };
        this.httpInvoiceServ.getFavoriteCusotmerInvoice_new(request).subscribe(function (data) {
            var customerNumber = data.favoriteCustomerList.map(function (item) {
                return item.customerNumber;
            });
            _this.invoiceServ.favoritecustomerList = customerNumber;
        });
    };
    FavoriteComponent.prototype.ngOnDestroy = function () {
    };
    FavoriteComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [HttpInvoicePriceNewService,
            AppService,
            InvoicePriceService,
            AppService])
    ], FavoriteComponent);
    return FavoriteComponent;
}());
export { FavoriteComponent };
