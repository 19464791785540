import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { languageNames } from '@app/conf/dataConfigurations';
import { CbAcceptencePolicyService } from '../../Services/cb-acceptence-policy.service';

@Component({
  selector: 'app-cb-pricing-terms',
  templateUrl: './cb-pricing-terms.component.html',
  styleUrls: ['./cb-pricing-terms.component.scss']
})
export class CBPricingTermsComponent implements OnInit, OnDestroy {
  policyContent: any;
  currentSelectedLanguage: string;
  userLanguageName: string;
  regionFromQueryParam;
  countryFromQueryParam;
  languageFromQueryParam;
  isEnglishActive: boolean = false;
  hideEnglishTab: boolean = false;
  constructor(
    private cbPolicyService: CbAcceptencePolicyService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {   
    this.regionFromQueryParam = this.activatedRoute.snapshot.queryParamMap.get('regionId');
    this.countryFromQueryParam = this.activatedRoute.snapshot.queryParamMap.get('countryId');
    this.languageFromQueryParam = this.activatedRoute.snapshot.queryParamMap.get('languageId');
    this.setPolicyContent(this.regionFromQueryParam, this.countryFromQueryParam, this.languageFromQueryParam);
  }

  setPolicyContent(region, country, language){
    this.cbPolicyService.makeAcceptancePolicyCall(false, true, region, country, language, 0);
    this.cbPolicyService.policySubject.subscribe(data => {
      console.log("@@@ CB Policy Content: ", data);
      this.policyContent = data.policyContent;
    });
    this.currentSelectedLanguage = language;
    /** Set the ACtive Menu */
    this.currentSelectedLanguage.indexOf('en') > -1 ? this.isEnglishActive = true : this.isEnglishActive = false;
    /** Hide the English Tab as User Passed/Query passed language is english */
    this.languageFromQueryParam.indexOf('en') > -1 ? this.hideEnglishTab = true : this.hideEnglishTab = false;
    /**Set the user Language name in Second tab */
    let userlanguage = (this.languageFromQueryParam.split("-")[0]).toLowerCase();
    this.userLanguageName = languageNames[userlanguage];
  }

  onEnglishClick() {
    this.setPolicyContent(this.regionFromQueryParam, this.countryFromQueryParam, "en-"+ this.countryFromQueryParam);
  }
  
  onUserLanguageClick() {
    this.setPolicyContent(this.regionFromQueryParam, this.countryFromQueryParam, this.languageFromQueryParam);
  }

  ngOnDestroy(): void {
    localStorage.removeItem('cb-pricing-terms-url');
  }

}
