import { Component } from "@angular/core";
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode } from "ag-grid-community";
import { IFilterAngularComp } from "ag-grid-angular";
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';

@Component({
  selector: 'app-sortonly',
  templateUrl: './sortonly.component.html',
  styleUrls: ['./sortonly.component.scss']
})

export class SortonlyComponent implements IFilterAngularComp {

  public params: IFilterParams;
  public valueGetter: (rowNode: RowNode) => any;
  public text: string = '';
  public colId: string;
  isNumeric: boolean;
  maxLength: number;
  isTextProvided: boolean;
  guiParams: any;

  // textControl = new FormControl();

  filterModel: any = { type: "free-text", value: this.text };
  orgColId: string;

  // @ViewChild('input', { read: ViewContainerRef }) public txtSearchInput;

  constructor(private gridService: CpAgGridService) {
  }

  agInit(params: IFilterParams): void {
    this.params = params;
    this.colId = params.column.getColId();
    this.orgColId = params.colDef.field;
    this.setColumnSematics();
    this.params.api.setPopupParent(document.querySelector('body'));
  }

  setColumnSematics() {
    switch (this.colId) {
      case 'contractNumber':
        this.maxLength = 10;
        this.isNumeric = true;
        break;
      case 'customerNumber':
        this.isNumeric = true;
        break;
      default:
        this.isNumeric = false;
        break;
    }
  }

  isFilterActive(): boolean {
    return this.text !== null && this.text !== undefined && this.text !== '';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return this.valueGetter(params.node).toString().toLowerCase().indexOf(this.text.toLowerCase()) >= 0;
  }

  getModel(): any {
    this.filterModel.value = this.text;
    return this.filterModel;
  }

  setModel(model: any): void {
    if (model) {
      this.filterModel = new Object();
      this.filterModel.type = "free-text";
      this.filterModel.value = model ? model.value : "";
      this.text = model.value;
    }
  }

  ngAfterViewInit(params: IAfterGuiAttachedParams): void {
  }

  // noinspection JSMethodCanBeStatic
  componentMethod(message: string): void {
    alert(`Alert from FreetextSearchComponent ${message}`);
  }

  numberOnly(event): boolean {
    if (this.isNumeric) {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && charCode != 44 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }
    else
      return true;
  }

  onChange(newValue) {
    this.isTextProvided = newValue && newValue !== '';
  }

  onApplyClick(event, newValue): void {
    if (this.text !== newValue) {
      this.text = newValue.trim();
      this.params.filterChangedCallback();
    }
    this.guiParams.hidePopup();
  }

  afterGuiAttached(params) {
    //this.txtSearchInput.element.nativeElement.focus();
    this.guiParams = params;
  }

  onSortRequested(isSortedRequest) {
    if (!!isSortedRequest) {
      this.guiParams.hidePopup();
    }
  }
}
