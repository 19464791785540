import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var LanguageSelectionService = /** @class */ (function () {
    function LanguageSelectionService(translateServ) {
        this.translateServ = translateServ;
        this.lang = new Subject();
        this.language = new BehaviorSubject({ imagename: '226-united-states.svg', regionCode: 'en' });
        this.currentSelected = this.language.asObservable();
    }
    LanguageSelectionService.prototype.updatelanguage = function (updatedLang) {
        this.language.next(updatedLang);
    };
    LanguageSelectionService.prototype.getlanguage = function () {
        return this.lang;
    };
    LanguageSelectionService.prototype.setlanguage = function (updatedLang) {
        this.lang.next(updatedLang);
    };
    LanguageSelectionService.prototype.getLanguageCode = function (region, language) {
        //console.log("region,  language: ",region,  language)
        if (region == "EMEA") {
            var emeaSupportedLanguages = ["en", "ru", "de", "it", "nl", "pl", "cs", "pt", "es", "fr"];
            if (language && language.indexOf("-")) {
                var lang = language.substring(0, language.indexOf("-"));
                var finalLang = emeaSupportedLanguages.indexOf(lang) > -1 ? lang : "en";
                return finalLang;
            }
            else {
                return 'en';
            }
        }
        else {
            return language;
        }
    };
    LanguageSelectionService.ngInjectableDef = i0.defineInjectable({ factory: function LanguageSelectionService_Factory() { return new LanguageSelectionService(i0.inject(i1.TranslateService)); }, token: LanguageSelectionService, providedIn: "root" });
    return LanguageSelectionService;
}());
export { LanguageSelectionService };
