import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { TranslateService } from '@ngx-translate/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { Calendar } from 'primeng/calendar';
var DaterangeSelectorComponent = /** @class */ (function () {
    function DaterangeSelectorComponent(translateServ, languageServ, gridService, _globalAppService) {
        this.translateServ = translateServ;
        this.languageServ = languageServ;
        this.gridService = gridService;
        this._globalAppService = _globalAppService;
        this.maxDateValue = new Date();
        this.filterModel = { type: "date-range-selector", value: { from: this.txtFromDate, to: this.txtToDate } };
        this.subscription = new Subscription();
    }
    DaterangeSelectorComponent.prototype.agInit = function (params) {
        var _this = this;
        this.params = params;
        this.region = this._globalAppService.getDefaultRegionString();
        this.orgColId = params.column.getColId();
        this.colId = params.colDef.field;
        this.valueGetter = params.valueGetter;
        this.colId = this.colId.indexOf('_') !== -1 ? this.colId.substring(0, this.colId.indexOf('_')) : this.colId;
        if (this.region == 'NA') {
            if (this.colId !== 'availableDate') {
                this.maxDateValue.setDate(this.maxDateValue.getDate());
            }
            else {
                this.maxDateValue = null;
            }
        }
        else {
            this.maxDateValue.setDate(this.maxDateValue.getDate());
        }
        this.subscription.add(this.languageServ.currentSelected.subscribe(function (data) {
            _this.subscription.add(_this.translateServ.get(['calender'])
                .subscribe(function (translations) {
                _this.calenderData = translations['calender'];
                _this.dateFormat = _this.calenderData.format;
                _this.primengDateFormat = _this.calenderData.dateFormat.toLowerCase();
            }));
        }));
        this.params.api.setPopupParent(document.querySelector('body'));
        var currentCountryLang = this._globalAppService.getSelectedCountryLang();
        // this.calendarText = this._globalAppService.getDefaultLanguageString().split('-')[0];
        this.calendarText = (currentCountryLang && currentCountryLang.language) ? currentCountryLang.language.split('-')[0] : this._globalAppService.getDefaultLanguageString().split('-')[0];
        this.languageServ.getlanguage().subscribe(function (data) {
            if (data) {
                _this.calendarText = data.split('-')[0];
            }
        });
    };
    DaterangeSelectorComponent.prototype.getModel = function () {
        this.filterModel.value = new Object();
        this.filterModel.value.from = this.txtFromDate;
        this.filterModel.value.to = this.txtToDate;
        return this.filterModel;
    };
    DaterangeSelectorComponent.prototype.setModel = function (model) {
        if (model && model.value) {
            this.txtFromDate = this.filterModel.value.from = model.value.from;
            this.txtToDate = this.filterModel.value.to = model.value.to;
        }
        else {
            this.txtFromDate = null;
            this.txtToDate = null;
            this.filterModel.value = "";
            this.filterModel.value = "";
        }
        this.isDateRangeSelected = false;
        this.showMessage = false;
    };
    DaterangeSelectorComponent.prototype.isFilterActive = function () {
        return this.txtFromDate && this.txtFromDate instanceof Date || this.txtToDate && this.txtToDate instanceof Date;
    };
    DaterangeSelectorComponent.prototype.doesFilterPass = function (params) {
        return true;
    };
    // noinspection JSMethodCanBeStatic
    DaterangeSelectorComponent.prototype.componentMethod = function (message) {
        alert("Alert from DateRangeSelectorComponent " + message);
    };
    DaterangeSelectorComponent.prototype.onFromDateSelected = function (event) {
        if (this.validateDates()) {
            this.isDateRangeSelected = true;
            this.showMessage = false;
        }
        else {
            this.showMessage = true;
            this.isDateRangeSelected = false;
        }
    };
    DaterangeSelectorComponent.prototype.onToDateSelected = function (event) {
        if (this.validateDates()) {
            this.isDateRangeSelected = true;
            this.showMessage = false;
        }
        else {
            this.showMessage = true;
            this.isDateRangeSelected = false;
        }
    };
    DaterangeSelectorComponent.prototype.validateDates = function () {
        var isValid = false;
        if (this.txtFromDate || this.txtToDate) {
            isValid = true;
        }
        else
            return false;
        if (this.txtFromDate && this.txtToDate)
            if (this.txtFromDate <= this.txtToDate)
                isValid = true;
            else
                return false;
        return isValid;
    };
    DaterangeSelectorComponent.prototype.onApplyClick = function (event) {
        if (this.validateDates()) {
            this.showMessage = false;
            this.params.filterChangedCallback();
            this.guiParams.hidePopup();
        }
        else
            this.showMessage = true;
    };
    DaterangeSelectorComponent.prototype.afterGuiAttached = function (params) {
        var _this = this;
        if (this.fromCalendar && this.fromCalendar.inputfieldViewChild)
            this.fromCalendar.inputfieldViewChild.nativeElement.placeholder = this.dateFormat;
        if (this.toCalendar && this.toCalendar.inputfieldViewChild)
            this.toCalendar.inputfieldViewChild.nativeElement.placeholder = this.dateFormat;
        this.guiParams = params;
        this.subscription.add(this.languageServ.currentSelected.subscribe(function (data) {
            _this.subscription.add(_this.translateServ.get(['calender'])
                .subscribe(function (translations) {
                _this.calenderData = translations['calender'];
                _this.dateFormat = _this.calenderData.format;
                _this.primengDateFormat = _this.calenderData.dateFormat.toLowerCase();
            }));
        }));
        if (!this.txtFromDate && !this.txtToDate) {
            this.showMessage = false;
        }
    };
    DaterangeSelectorComponent.prototype.onDateInput = function (event) {
        if (event.target.name === 'fromDate' && event.target.value == '')
            this.txtFromDate = null;
        else if (event.target.name === 'toDate' && event.target.value == '')
            this.txtToDate = null;
        if (this.validateDates()) {
            this.isDateRangeSelected = true;
            this.showMessage = false;
        }
        else {
            this.isDateRangeSelected = false;
            this.showMessage = true;
        }
    };
    DaterangeSelectorComponent.prototype.onSortRequested = function (isSortedRequest) {
        if (!!isSortedRequest) {
            this.guiParams.hidePopup();
        }
    };
    DaterangeSelectorComponent.prototype.onCalFromFocus = function (cpContainer) {
        cpContainer.classList.add('date_range_from--popup');
    };
    DaterangeSelectorComponent.prototype.OnCalFromClosed = function (cpContainer) {
        cpContainer.classList.remove('date_range_from--popup');
    };
    DaterangeSelectorComponent.prototype.onCalToFocus = function (cpContainer) {
        cpContainer.classList.add('date_range_to--popup');
    };
    DaterangeSelectorComponent.prototype.OnCalToClosed = function (cpContainer) {
        cpContainer.classList.remove('date_range_to--popup');
    };
    DaterangeSelectorComponent.prototype.ngOnDestroy = function () {
        // Required for unsubscribing and destroying the component  
    };
    DaterangeSelectorComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [TranslateService,
            LanguageSelectionService,
            CpAgGridService,
            GlobalAppService])
    ], DaterangeSelectorComponent);
    return DaterangeSelectorComponent;
}());
export { DaterangeSelectorComponent };
