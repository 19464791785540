import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MenuTogglerService {

  private menuState: boolean;

  private menuControls = new Subject<boolean>();

  public menuControls$: Observable<boolean> = this.menuControls.asObservable();

  setMenuState(menuState: boolean) {
     this.menuState = menuState;
    this.menuControls.next(menuState);
  }

  get getMenuState(): boolean {
    return this.menuState;
  }

}
