import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { HttpChargeBackService } from '@app/Modules/Shared/Services/http-charge-back.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { LanguageSelectionService } from './language-selection.service';

@Injectable({
  providedIn: 'root'
})
export class CbAcceptencePolicyService {
  isInternalUser: boolean;
  isAccepted: boolean;
  showAcceptancePolicy: boolean;
  policyContent: any;
  policyContentEnglish: any;
  subscription: Subscription = new Subscription();
  acceptancePolicyResponse: any;
  language: string;
  country: string;
  region: string;
  policyContentId: number = 49;
  salesOrgId: number;
  fromFileUpload: boolean = false;
  eFileName: string = "";
  versionCreationDate: string = "";  
  public policySubject: BehaviorSubject<any> = new BehaviorSubject({ isAccepted: true, showAcceptancePolicy: false })
  constructor(
    private appServ: AppService,
    private httpServ: HttpChargeBackService,
    private globalAppService: GlobalAppService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private languageServ: LanguageSelectionService
  ) {
    this.isInternalUser = this.appServ.isInternalUser;
    this.languageServ.getlanguage().subscribe(data => {
      this.language = data;
    });
  }

  makeAcceptancePolicyCall(fromFileUpload = false, fromPricingTerms = false, region = this.region, country = this.country, language = this.language, salesOrgId = this.salesOrgId) {
    let countryCode = this.globalAppService.getDefaultCountryString()
    let disablePolicyCall = countryCode == 'RU' || countryCode == 'BY' || countryCode == 'KZ' ? true : false;
    if(!disablePolicyCall){
      this.fromFileUpload = fromFileUpload;
      this.appServ.setLoadingStatus(true);
      this.policyContentId = this.fromFileUpload ? 50 : 49;
      if ( (!this.appServ.isInternalUser && !fromFileUpload) || fromPricingTerms) {
        const request = {
          contentId: this.policyContentId
        };
        this.subscription.add(this.httpServ
          .getAccptancePolicyStatus(request)
          .subscribe(
            (data) => {
              if (data) {
                this.acceptancePolicyResponse = data.result;
                this.getLocationAndlanguage();
                this.getAccetptancePolicyContent(this.acceptancePolicyResponse, region, country, language, salesOrgId);
              }
            },
            (error) => {
              this.appServ.setLoadingStatus(false);
            },
            () => {
              this.appServ.setLoadingStatus(false);
            }));
      } else if (fromFileUpload) {
        this.getLocationAndlanguage();
        this.getAccetptancePolicyContent({ isAccepted: false }, this.region, this.country, this.language, this.salesOrgId);

      }
    }    
  }

  getLocationAndlanguage() {
    this.region = this.globalAppService.getDefaultRegionString();
    let selectedLocation = this.globalAppService.getSelectedCountryLang();
    this.language = selectedLocation && selectedLocation.language ? selectedLocation.language : this.globalAppService.getDefaultLanguageString();
    this.country = selectedLocation && selectedLocation.country ? selectedLocation.country : this.globalAppService.getDefaultCountryString();
    this.salesOrgId = +this.globalAppService.getSalesOrg();
  }

  getAccetptancePolicyContent(statusResponse, region, country, language, salesOrgId) {
    if (statusResponse && statusResponse.isAccepted) {
      this.isAccepted = statusResponse.isAccepted;
      this.showAcceptancePolicy = !statusResponse.isAccepted;
      this.policySubject.next({ isAccepted: this.isAccepted, showAcceptancePolicy: this.showAcceptancePolicy, fromFileUpload: this.fromFileUpload })
      return;
    }
    this.appServ.setLoadingStatus(true);
    let KCPContentRequest = this.getKCPContentRequest(region, country, language, salesOrgId);
    this.httpServ.getKCPContent(KCPContentRequest, this.fromFileUpload).subscribe(
      (data) => {
        if (data.result.length > 0) {
          this.policyContent = this.sanitizer.bypassSecurityTrustHtml(JSON.parse(data.result[0].data).Content);
          this.showAcceptancePolicy = !statusResponse.isAccepted;
          this.isAccepted = statusResponse.isAccepted;
          this.policySubject.next({ isAccepted: this.isAccepted, showAcceptancePolicy: this.showAcceptancePolicy, policyContent: this.policyContent, fromFileUpload: this.fromFileUpload })
          this.versionCreationDate = data.result[0].versionCreationDate;
        }
        this.appServ.setLoadingStatus(false);
      },
      (error) => {
        this.appServ.setLoadingStatus(false);
      },
      () => {
        this.appServ.setLoadingStatus(false);
      })
  }

  getKCPContentRequest(region, country, language, salesOrgId) {
    return {
      regionId: region,
      countryId: country,
      languageCode: language,
      //salesOrgId: salesOrgId,
      contentTypes: [
        {
          contentType: "Chargeback Policy Consent"//"AcceptancePolicy"//"Chargeback Policy Consent"
        }
      ],
      contentSubTypes: [
        {
          contentSubType: "Popup"
        }
      ]
    }
  }

  setUploadEfileName(eFileName) {
    this.eFileName = eFileName;
  }
  onPolicyAccepted(isAggrementAccepted) {
    let req = {
      contentId: this.policyContentId,
      isAccepted: isAggrementAccepted
    };

    if (this.fromFileUpload && this.region == "EMEA") {
      req["eFileName"] = this.eFileName;
      req["versionCreationDate"] = this.versionCreationDate;
    }

    this.httpServ
      .setAccptancePolicyStatus(req, this.fromFileUpload)
      .subscribe(
        response => {
          if (response.result || (response && this.fromFileUpload))
            this.isAccepted = isAggrementAccepted;
          this.showAcceptancePolicy = false;
          this.policySubject.next({ isAccepted: this.isAccepted, showAcceptancePolicy: this.showAcceptancePolicy, fromFileUpload: this.fromFileUpload });
        },
        err => {
          console.log("Error Occured");
        }
      );
  }

  getFromFileUpload() {
    return this.fromFileUpload;
  }

  getKCPContentByLanguage(lang) {
    this.appServ.setLoadingStatus(true);
    let KCPContentRequest = this.getKCPContentRequest(this.region, this.country, lang, this.salesOrgId);
    this.httpServ.getKCPContent(KCPContentRequest, this.fromFileUpload).subscribe(
      (data) => {
        if (data.result.length > 0) {
          this.policyContent = this.sanitizer.bypassSecurityTrustHtml(JSON.parse(data.result[0].data).Content);
          this.policySubject.next({ isAccepted: this.isAccepted, showAcceptancePolicy: this.showAcceptancePolicy, policyContent: this.policyContent, fromFileUpload: this.fromFileUpload })
        }
        this.appServ.setLoadingStatus(false);
      },
      (error) => {
        this.appServ.setLoadingStatus(false);
      },
      () => {
        this.appServ.setLoadingStatus(false);
      })
  }

  getKCPContentEnglish() {
    return this.policyContentEnglish;
  }
}
