<p-dialog [(visible)]="visibleCustModal" [dismissableMask]="true" [modal]="true" [closeOnEscape]="true"
  [closable]="true" (onHide)="close()" (onShow)="openDialog()" positionTop='20' styleClass="dialogClass"
  [blockScroll]="true" [contentStyle]="{'overflow-y':'scroll','max-height':'90vh'}">

  <p-header>
    <ng-container *ngIf="region == 'EMEA'">
      {{ 'priceagreement.search.locationsHeading' | translate }}
    </ng-container>  
    <ng-container *ngIf="region != 'EMEA'">
      {{ 'priceagreement.search.customerLabel' | translate }}
    </ng-container>  
    <span *ngIf="recordCount">({{ recordCount }})</span> </p-header>
  <!-- <p-header>
    {{ 'priceagreement.search.customerLabel' | translate }}
    <span *ngIf="recordCount">({{ recordCount }})</span> </p-header> -->

  <div class="content-area__white-box">
    <div class="custom-table-filters row">
      <div class="col-12 col-md-12" *ngIf="allFilters && allFilters.length>0">
        <div class="custom-table-clear-filters">
          <ul class="unlisted inline">
            <li *ngIf="allFilters && allFilters.length>0">
              <i class="far fa-times-circle" aria-hidden="true" (click)="onRemoveFilter($event, 'all')"></i>
              {{"agGrid.agGridHeading.clearAllFilters" | translate }}
            </li>
            <li *ngFor="let filter of allFilters; let i = index;">
              <i class="far fa-times-circle" aria-hidden="true" (click)="onRemoveFilter($event, filter.key)"></i>
              <ng-container *ngIf="filter.type != 'multi-select' && filter.type != 'date-range-selector'">
                {{filter.header | translate }} :
                {{filter.text | translate }}</ng-container>
              <ng-container *ngIf="filter.type == 'multi-select'">
                {{filter.header | translate }} :
                <ng-container *ngFor="let item of filter.text; let itemIndex = index;">
                  {{ item | translate }}
                  <ng-container *ngIf="itemIndex !== filter.text.length - 1">, </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="filter.type == 'date-range-selector'">
                {{filter.header | translate }} :
                {{  filter.rangeType  | translate }} {{filter.text | translate }}
              </ng-container>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12">
        <p class="custom-table-filters__select-filter" *ngIf="(!allFilters) || allFilters.length==0"><i
            class="fas fa-filter"></i>{{ 'agGrid.agGridHeading.selectFilters' | translate }}
        </p>
      </div>
    </div>
    <ag-grid-angular *ngIf="showGrid" style="width: 100%;"
      class="ag-theme-balham singleSelectionGrid gridHeaderRowCellHeightFix" [rowData]="rowData"
      [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowHeight]="rowHeight" rowSelection="single"
      [suppressRowTransform]=true suppressRowClickSelection=true [frameworkComponents]="frameworkComponents"
      [rowModelType]="rowModelType" [paginationPageSize]="paginationPageSize" [cacheOverflowSize]="cacheOverflowSize"
      [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests" domLayout="autoHeight"
      [maxBlocksInCache]="maxBlocksInCache" [pagination]="true" [infiniteInitialRowCount]="infiniteInitialRowCount"
      [suppressPaginationPanel]="true" [suppressScrollOnNewData]="true" [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)" (columnResized)="onColumnResized($event)"
      (filterChanged)="onFilterChanged($event)" (rowSelected)="onRowSelected($event)"
      [noRowsOverlayComponent]="noRowsOverlayComponent" [loadingOverlayComponent]="loadingOverlayComponent">
    </ag-grid-angular>
    <cp-grid-pagination [resultsPerPageId]="'dd-product-modal-results-page-size'"
      (pageSizeChanged)="onPageSizeChanged($event)"></cp-grid-pagination>
  </div>
</p-dialog>