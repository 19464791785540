import { Component, OnInit, OnDestroy } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams, IAfterGuiAttachedParams, RowNode } from 'ag-grid-community';
import { CbService } from '@app/Modules/chargeback/Services/cb.service';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { typeAheadFilterPlaceholder } from '@app/conf/dataConfigurations';

@Component({
  selector: 'app-type-ahead-search',
  templateUrl: './type-ahead-search.component.html',
  styleUrls: ['./type-ahead-search.component.scss']
})
export class TypeAheadSearchComponent implements IFilterAngularComp {
  public params: IFilterParams;
  public valueGetter: (rowNode: RowNode) => any;
  selectedCustomerEUList: any = [];
  filterModel: any = { type: "type-ahead", value: this.selectedCustomerEUList };
  guiParams: any;
  orgColId: string;
  hideSort: boolean = false;
  colId: string;
  optionData: any;
  placeHolder:any;
  constructor(private cbService: CbService, private gridService: CpAgGridService) { }
  agInit(params: IFilterParams): void {
    this.params = params;
    this.orgColId = params.column.getColId();
    this.colId = params.colDef.field;
    this.valueGetter = params.valueGetter;
    this.params.api.setPopupParent(document.querySelector('body'));

    if (this.gridService.pageContext.pageName != 'summaryResultsCB') {
      let distintFilters = this.gridService.getMultiSelectDistinctFilter();
      let gridName = this.gridService.getGridName();
      this.optionData = distintFilters[gridName][this.colId];
      this.placeHolder = typeAheadFilterPlaceholder[this.colId];
    }

  }
  isFilterActive(): boolean {
    return this.selectedCustomerEUList && this.selectedCustomerEUList.length;
  }
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    if (this.selectedCustomerEUList && this.selectedCustomerEUList.length) {
      return this.selectedCustomerEUList.some(filter => {
        return (
          this.valueGetter(params.node)
            .toString()
            .toLowerCase() === filter.text.toLowerCase()
        );
      });
    } else {
      return true;
    }
  }
  getModel() {
    this.filterModel.value = this.selectedCustomerEUList;
    return this.filterModel;
  }
  setModel(model: any): void {
    if (model && model.value) {
      this.filterModel = new Object();
      this.filterModel.type = "type-ahead";
      this.filterModel.value = this.selectedCustomerEUList;
    } else {
      this.selectedCustomerEUList = [];
      this.filterModel = new Object();
      this.filterModel.type = "type-ahead";
      this.filterModel.value = this.selectedCustomerEUList;
      this.cbService.setCustEUList(this.selectedCustomerEUList);
    }
  }
  // onNewRowsLoaded?(): void {
  //   throw new Error("Method not implemented.");
  // }
  // getFrameworkComponentInstance?() {
  //   throw new Error("Method not implemented.");
  // }
  // getModelAsString?(model: any): string {
  //   throw new Error("Method not implemented.");
  // }
  // ngOnDestroy(): void {
  //   throw new Error("Method not implemented.");
  // }
  selectedCustomerEU(event) {
    this.selectedCustomerEUList = [];
    event.forEach(customerEU => {
      this.selectedCustomerEUList.push(customerEU);
    });
    this.params.filterChangedCallback();
    this.guiParams.hidePopup();
  }

  afterGuiAttached(params) {
    this.guiParams = params;
    this.cbService.setCustEUList(this.selectedCustomerEUList);
  }

  onSortRequested(isSortedRequest) {
    if (!!isSortedRequest) {
      this.guiParams.hidePopup();
    }
  }

}
