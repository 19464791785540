import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { endUserDetailsFooterTitle } from '@app/conf/dataConfigurations';

@Component({
  selector: 'app-full-width-row',
  templateUrl: './full-width-row.component.html',
  styleUrls: ['./full-width-row.component.scss']
})
export class FullWidthRowComponent implements AgRendererComponent {
  errorDescription: string = "";
  errorIndicator: string = "";
  isGroup: boolean = false;
  endUserDetailsFooterTitle: any;
  label: string;
  constructor() { }
  params: any;
  agInit(params): void {
    this.params = params;
    this.isGroup = params.node.group;
    if (this.isGroup) {
      this.errorDescription = params.node.allLeafChildren[0].data.erroDescription;     
    }
    this.endUserDetailsFooterTitle = endUserDetailsFooterTitle;
  }
  getLabel(params) {
    let el = endUserDetailsFooterTitle.find((el) => {
      return el.value == params.data.id;
    });
    return el.label;
  }

  refresh(params: any): boolean {
    return false;
  }

}
