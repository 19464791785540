import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { MyProfileService } from '@app/Modules/my-profile/Components/services/my-profile-service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
var MyprofileAddtoPreferenceComponent = /** @class */ (function () {
    function MyprofileAddtoPreferenceComponent(myProfileService, globalAppService, gridService) {
        this.myProfileService = myProfileService;
        this.globalAppService = globalAppService;
        this.gridService = gridService;
        this.check = false;
        // this.currentNode = null;
    }
    MyprofileAddtoPreferenceComponent.prototype.agInit = function (params) {
        this.currentNode = params;
        if (!this.checkboxName) {
            this.checkboxName = "ATP-" + params.data.customerNumber; //Math.random().toString(36).substring(2, 15);
        }
        if (params && params.data && params.data.isDefault) {
            this.check = params.data.isDefault;
        }
    };
    MyprofileAddtoPreferenceComponent.prototype.ngOnInit = function () {
        this.region = this.globalAppService.getDefaultRegionString();
    };
    MyprofileAddtoPreferenceComponent.prototype.refresh = function (params) {
        return false;
    };
    MyprofileAddtoPreferenceComponent.prototype.checkboxClick = function (event) {
        this.check = event.target.checked;
        this.currentNode.data.isDefault = event.target.checked;
        if (!event.target.checked) {
            this.updateCheckStateOfDailyMails(event.target.checked);
        }
        var isAllchecked = this.autoHeaderSelection("location-external-pref");
        this.setCheckboxStatus("addToPrefCheckBoxHeader", isAllchecked);
        if (!event.target.checked) {
            this.updateHeaderCheckStateOfDailyMails(isAllchecked);
        }
    };
    /** Maintain the header check state of Daily mails depending upon the Add to Pref selected length */
    MyprofileAddtoPreferenceComponent.prototype.updateHeaderCheckStateOfDailyMails = function (isAllchecked) {
        if (isAllchecked) {
            if (this.region == "EMEA") {
                var isAllPAchecked = this.autoHeaderSelection("external-PA", 1);
                if (isAllPAchecked) {
                    this.setCheckboxStatus("PACheckBoxHeader", true);
                }
                var isAllCBchecked = this.autoHeaderSelection("external-CB", 3);
                if (isAllCBchecked) {
                    this.setCheckboxStatus("CBCheckBoxHeader", true);
                }
            }
            else if (this.region == "LAO") {
                //this.setCheckboxStatus("PACheckBoxHeader", true);
                var isAllPAchecked = this.autoHeaderSelection("external-PA", 1);
                if (isAllPAchecked) {
                    this.setCheckboxStatus("PACheckBoxHeader", true);
                }
            }
            else if (this.region == "NA") {
                var isAllPAchecked = this.autoHeaderSelection("external-PA", 1);
                if (isAllPAchecked) {
                    this.setCheckboxStatus("PACheckBoxHeader", true);
                }
                var isAllMCchecked = this.autoHeaderSelection("external-MC", 2);
                if (isAllMCchecked) {
                    this.setCheckboxStatus("MCCheckBoxHeader", true);
                }
                var isAllCBchecked = this.autoHeaderSelection("external-CB", 3);
                if (isAllCBchecked) {
                    this.setCheckboxStatus("CBCheckBoxHeader", true);
                }
                var isAllINchecked = this.autoHeaderSelection("external-IN", 4);
                if (isAllINchecked) {
                    this.setCheckboxStatus("INCheckBoxHeader", true);
                }
                var isAllODchecked = this.autoHeaderSelection("external-OD", 5);
                if (isAllODchecked) {
                    this.setCheckboxStatus("ODCheckBoxHeader", true);
                }
            }
        }
        else {
            var isAllPAchecked = this.autoHeaderSelection("external-PA", 1);
            if (this.region == "EMEA") {
                this.setCheckboxStatus("PACheckBoxHeader", isAllPAchecked);
                var isAllCBchecked = this.autoHeaderSelection("external-CB", 3);
                this.setCheckboxStatus("CBCheckBoxHeader", isAllCBchecked);
            }
            else if (this.region == "LAO") {
                this.setCheckboxStatus("PACheckBoxHeader", isAllPAchecked);
            }
            else if (this.region == "NA") {
                this.setCheckboxStatus("PACheckBoxHeader", isAllPAchecked);
                var isAllMCchecked = this.autoHeaderSelection("external-MC", 2);
                this.setCheckboxStatus("MCCheckBoxHeader", isAllMCchecked);
                var isAllCBchecked = this.autoHeaderSelection("external-CB", 3);
                this.setCheckboxStatus("CBCheckBoxHeader", isAllCBchecked);
                var isAllINchecked = this.autoHeaderSelection("external-IN", 4);
                this.setCheckboxStatus("INCheckBoxHeader", isAllINchecked);
                var isAllODchecked = this.autoHeaderSelection("external-OD", 5);
                this.setCheckboxStatus("ODCheckBoxHeader", isAllODchecked);
            }
        }
        /** If all Items from Add to Pref is checked then
         * also check the Daily mail in front of Default disabled location
         * */
        var defaultLocation = this.myProfileService.getPreveiousSelectedLoc();
        if (isAllchecked && defaultLocation) {
            if (this.region == "EMEA") {
                this.setCheckboxStatus("PA-" + defaultLocation, true);
                this.currentNode.data.Preferences[0].isSelected = true;
                this.setCheckboxStatus("CB-" + defaultLocation, true);
                this.currentNode.data.Preferences[1].isSelected = true;
            }
            else if (this.region == "LAO") {
                this.setCheckboxStatus("PA-" + defaultLocation, true);
                this.currentNode.data.Preferences[0].isSelected = true;
            }
            else if (this.region == "NA") {
                this.setCheckboxStatus("PA-" + defaultLocation, true);
                this.currentNode.data.Preferences[0].isSelected = true;
                this.setCheckboxStatus("MC-" + defaultLocation, true);
                this.currentNode.data.Preferences[1].isSelected = true;
                this.setCheckboxStatus("CB-" + defaultLocation, true);
                this.currentNode.data.Preferences[2].isSelected = true;
                this.setCheckboxStatus("IN-" + defaultLocation, true);
                this.currentNode.data.Preferences[3].isSelected = true;
                this.setCheckboxStatus("OD-" + defaultLocation, true);
                this.currentNode.data.Preferences[4].isSelected = true;
            }
        }
    };
    /** Check/Uncheck the daily mail item in front of checked Add to Prefernce item */
    MyprofileAddtoPreferenceComponent.prototype.updateCheckStateOfDailyMails = function (checkStatus) {
        if (this.region == "EMEA") {
            this.setCheckboxStatus("PA-" + this.currentNode.data.customerNumber, checkStatus);
            this.currentNode.data.Preferences[0].isSelected = checkStatus;
            this.setCheckboxStatus("CB-" + this.currentNode.data.customerNumber, checkStatus);
            this.currentNode.data.Preferences[1].isSelected = checkStatus;
        }
        else if (this.region == "LAO") {
            this.setCheckboxStatus("PA-" + this.currentNode.data.customerNumber, checkStatus);
            this.currentNode.data.Preferences[0].isSelected = checkStatus;
        }
        else if (this.region == "NA") {
            this.setCheckboxStatus("PA-" + this.currentNode.data.customerNumber, checkStatus);
            this.currentNode.data.Preferences[0].isSelected = checkStatus;
            this.setCheckboxStatus("MC-" + this.currentNode.data.customerNumber, checkStatus);
            this.currentNode.data.Preferences[1].isSelected = checkStatus;
            this.setCheckboxStatus("CB-" + this.currentNode.data.customerNumber, checkStatus);
            this.currentNode.data.Preferences[2].isSelected = checkStatus;
            this.setCheckboxStatus("OD-" + this.currentNode.data.customerNumber, checkStatus);
            this.currentNode.data.Preferences[3].isSelected = checkStatus;
            this.setCheckboxStatus("IN-" + this.currentNode.data.customerNumber, checkStatus);
            this.currentNode.data.Preferences[4].isSelected = checkStatus;
        }
    };
    MyprofileAddtoPreferenceComponent.prototype.autoHeaderSelection = function (collectionName, moduleId) {
        if (moduleId === void 0) { moduleId = 0; }
        if (moduleId) {
            var isAllchecked_1 = true;
            var recordCount_1 = 0;
            this.gridService.gridApi.forEachNode(function (node) {
                if (node.data && node.data.customerNumber) {
                    recordCount_1++;
                    var selected = node.data.Preferences.find(function (x) {
                        return x.moduleId == moduleId;
                    }).isSelected;
                    if (!selected) {
                        isAllchecked_1 = false;
                    }
                }
            });
            if (recordCount_1)
                return isAllchecked_1;
            else
                return false;
        }
        else {
            var allLocations = document.getElementsByClassName(collectionName);
            var isAllchecked = false;
            for (var index = 0; index < allLocations.length; index++) {
                var element = allLocations[index];
                if (element.checked) {
                    isAllchecked = true;
                }
                else {
                    isAllchecked = false;
                    break;
                }
            }
            return isAllchecked;
        }
    };
    MyprofileAddtoPreferenceComponent.prototype.setCheckboxStatus = function (checkboxName, status) {
        var el = document.getElementById(checkboxName);
        if (el) {
            el.checked = status;
        }
    };
    MyprofileAddtoPreferenceComponent.prototype.ngOnDestroy = function () {
        // this.currentNode = null;
    };
    MyprofileAddtoPreferenceComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [MyProfileService,
            GlobalAppService,
            CpAgGridService])
    ], MyprofileAddtoPreferenceComponent);
    return MyprofileAddtoPreferenceComponent;
}());
export { MyprofileAddtoPreferenceComponent };
