import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-advanced-refresh-status',
  templateUrl: './invoice-advanced-refresh-status.component.html',
  styleUrls: ['./invoice-advanced-refresh-status.component.scss']
})
export class InvoiceAdvancedRefreshStatusComponent implements OnInit {
  
  params: any;
  
  constructor() { }

  agInit(params: any) {
    this.params = params;
  }

  ngOnInit() {
  }

}
