import { HttpClient } from '@angular/common/http';
import { GlobalAppService } from './global-app.service';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./global-app.service";
var HttpInvoicePriceService = /** @class */ (function () {
    function HttpInvoicePriceService(http, _globalAppService) {
        this.http = http;
        this._globalAppService = _globalAppService;
    }
    //fetch region specific url from environment.ts
    HttpInvoicePriceService.prototype.getRegionSpecificURL = function (item) {
        var regionURL = "";
        var region = localStorage.getItem('default-region') || this._globalAppService.getDefaultRegionString() || this._globalAppService.selectedRegion;
        if (region) {
            regionURL = "url_" + region.toUpperCase();
        }
        else {
            regionURL = "url_NA";
        }
        return item[regionURL];
    };
    HttpInvoicePriceService.prototype.makeHTTPCall = function (method, endPoint, additionalHeaders, requestParams) {
        if (additionalHeaders === void 0) { additionalHeaders = null; }
        if (requestParams === void 0) { requestParams = null; }
        var requestBody = {};
        if (additionalHeaders) {
            requestBody = Object.assign({}, requestBody, { headers: additionalHeaders });
        }
        if (requestParams) {
            requestBody = Object.assign({}, requestBody, { body: requestParams });
        }
        return this.http.request(method, endPoint, requestBody);
    };
    HttpInvoicePriceService.prototype.GetInvoicePriceStatusByCustomerID = function (request) {
        return this.makeHTTPCall(environment.GetInvoicePriceStatusByCustomerID.method, this.getRegionSpecificURL(environment.GetInvoicePriceStatusByCustomerID), null, request);
    };
    HttpInvoicePriceService.prototype.saveFavoriteInvoiceCategory = function (request) {
        return this.makeHTTPCall(environment.SaveFavoriteInvoiceCategory.method, this.getRegionSpecificURL(environment.SaveFavoriteInvoiceCategory), null, request);
    };
    HttpInvoicePriceService.prototype.getFavoriteInvoiceCategory = function (request) {
        return this.makeHTTPCall(environment.GetFavoriteCategoryInvoice.method, this.getRegionSpecificURL(environment.GetFavoriteCategoryInvoice), null, request);
    };
    HttpInvoicePriceService.prototype.priceRefreshRequest = function (request) {
        return this.makeHTTPCall(environment.PriceRefreshRequest.method, this.getRegionSpecificURL(environment.PriceRefreshRequest), null, request);
    };
    HttpInvoicePriceService.prototype.downloadInvoicePrice = function (request) {
        return this.makeHTTPCall(environment.DownloadInvoicePrice.method, this.getRegionSpecificURL(environment.DownloadInvoicePrice), null, request);
    };
    HttpInvoicePriceService.prototype.notifyInvoicePriceRequestByEmail = function (request) {
        return this.makeHTTPCall(environment.NotifyInvoicePriceRequestByEmail.method, this.getRegionSpecificURL(environment.NotifyInvoicePriceRequestByEmail), null, request);
    };
    HttpInvoicePriceService.prototype.GetCategoriesByCustomerNumber = function (request) {
        return this.makeHTTPCall(environment.GetCategoriesByCustomerNumber.method, this.getRegionSpecificURL(environment.GetCategoriesByCustomerNumber), null, request);
    };
    HttpInvoicePriceService.ngInjectableDef = i0.defineInjectable({ factory: function HttpInvoicePriceService_Factory() { return new HttpInvoicePriceService(i0.inject(i1.HttpClient), i0.inject(i2.GlobalAppService)); }, token: HttpInvoicePriceService, providedIn: "root" });
    return HttpInvoicePriceService;
}());
export { HttpInvoicePriceService };
