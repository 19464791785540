let endPoints_PA_QA = {
	oktaAuthorize: {
		url_NA: "https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7/v1/authorize",
		url_LAO: "https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7/v1/authorize",
		url_EMEA: "https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7/v1/authorize",
		method: "GET"
	},
	userInfo: {
		url_NA: "https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7/v1/userinfo",
		url_LAO: "https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7/v1/userinfo",
		url_EMEA: "https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7/v1/userinfo",
		method: "GET"
	},
	productcategory: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-pdqa/v2/na/en-us/productcategory",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-pdqa/v2/lao/en-us/productcategory",
		url_EMEA: " ",
		method: "GET"
	},
	userProfile: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/CustomerV2/GetDefaultCustomerInfo",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/customer/defaultcustomerinfo",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/CustomerV2/GetDefaultCustomerInfo",
		method: "GET"
	},
	dashboardcount: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Dashboard",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/dashboard/dashboard",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Dashboard",
		method: "GET"
	},
	endUserCount: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-enduser-pxy-api-pdqa/na/v2/EndUserService/EndUserRequestsDashboardCount",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-enduser-pxy-api-pdqa/lao/v2/EndUserService/EndUserRequestsDashboardCount",
		method: "GET"
	},
	SearchByFields: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/PriceAgreement/BySearchFields",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/priceagreement/pabysearchfields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-pdqa/v2/emea/en-us/Priceagreement/BySearchFields",
		method: "POST"
	},
	hamburgerMenu: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/MenuService/GetHambergMenu",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/menuservice/hambergmenu",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/MenuService/GetHambergMenu",
		method: "GET"
	},
	removeSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/RemoveFavoriteSearch",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/removefavoritesearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/RemoveFavoriteSearch",
		method: "POST"
	},
	saveRecentSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/SaveSearch",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/savesearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/SaveSearch",
		method: "POST"
	},
	markSearchFavrouite: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/SaveFavoriteSearch",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/savefavoritesearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/SaveFavoriteSearch",
		method: "POST"
	},
	getRecentSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/GetRecentSearch",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/getrecentsearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/GetRecentSearch",
		method: "GET"
	},
	getSavedSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/GetFavoriteSearch",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/getfavoritesearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/GetFavoriteSearch",
		method: "GET"
	},
	transactionCenterMenu: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/MenuService/GetTransactionCenterMenu",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/menuservice/transactioncentermenu",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/MenuService/GetTransactionCenterMenu",
		method: "GET"
	},
	learningCenterMenu: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/MenuService/GetLearningCenterMenu",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/menuservice/learningcentermenu",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/V2/MenuService/GetLearningCenterMenu",
		method: "GET"
	},
	markFavorite: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/SaveFavoriteContract",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/savefavoritecontract",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/SaveFavoriteContract",
		method: "POST"
	},
	markUnFavorite: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/RemoveFavoriteContract",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/removefavoritecontract",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/RemoveFavoriteContract",
		method: "POST"
	},
	getFavorite: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/GetFavoriteContract",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/getfavoritecontract",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/GetFavoriteContract",
		method: "GET"
	},
	getCustomerInfo: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/NA/v2/CustomerV2/custinformation",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/customer/custinformation",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/CustomerV2/custinformation",
		method: "POST"
	},
	getDashboardTilesData: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Dashboard/BySearchFields",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/dashboard/dashboardbysearchfields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Dashboard/BySearchFields",
		method: "POST"
	},
	getContractDetail: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/PriceAgreement/ContractDetail",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/priceagreement/contractdetail",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/PriceAgreement/ContractDetail",
		method: "POST"
	},
	getContractCustomerList: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/V2/PriceAgreement/ContractCustomerSoldTo",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/priceagreement/contractcustomersoldto",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/PriceAgreement/ContractCustomerSoldTo",
		method: "POST"
	},
	getTradeOrgResults: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/ContractGroup/GetContractGroupBy",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/contractgroup/getcontractgroupby",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/ContractGroupV2/ContractGroupV2_GetContractGroupBy",
		method: "POST"
	},
	getProductSearchModalResults: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/ContractProduct/GetProductBySearchFields",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/contractproduct/getproductbysearchfields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/ContractProduct/GetProductBySearchFields",
		method: "POST"
	},
	geCustomerListResults: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/CustomerV2/GetMappedCustomerInfo",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/customer/getmappedcustomerinfo",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/CustomerV2/GetMappedCustomerInfo",
		method: "POST"
	},
	getProductResultData: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-pdqa/v2/na/en-us/ContractProduct/GetContractProductBy",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/contractproduct/getcontractproductby",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/ContractProduct/GetContractProductBy",
		method: "POST"
	},
	getTradeOrgData: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/ContractGroup/GetContractEndUserByGroup",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/contractgroup/getcontractenduserbygroup",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/ContractGroup/GetContractEndUserByGroup",
		method: 'POST'
	},
	getEndUsersResultData: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/ContractEndUser/GetContractEndUserBy",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/contractenduser/getcontractenduserby",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/ContractEndUser/GetContractEndUserBy",
		method: "POST"
	},
	getUserNotes: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/GetUserNotes",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/getusernotes",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/GetUserNotes",
		method: "POST"
	},
	saveUserNotes: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/SaveUserNotes",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/saveusernotes",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/SaveUserNotes",
		method: "POST"
	},
	deleteUserNotes: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/UserNotes/",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/UserNotes/",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/UserNotes/",
		method: "DELETE"
	},
	getKCPUserUpdates: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/ChangeTracking/KCPUserUpdates",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/changetracking/kcpuserupdates",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/ChangeTracking/KCPUserUpdates",
		method: "POST"
	},
	KCPContent: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/na/v2/Content/GetKCPContent",
		url_LAO: "https://kcc-sls-ang-kcpconfig-exp-api-supqa.br-s1.cloudhub.io/content/kcpcontent",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/EMEA/v2/Content/GetKCPContent",
		method: "POST"
	},
	getAccptancePolicyStatus: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/GetUserAgreementAcceptanceStatus",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/getuseragreementstatus",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/GetUserAgreementAcceptanceStatus",
		method: "POST"
	},
	setAccptancePolicyStatus: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/SaveUserAgreementAcceptanceStatus",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/saveuseragreementstatus",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/SaveUserAgreementAcceptanceStatus",
		method: "POST"
	},
	announcementsModalDetails: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-pdqa/v2/na/en-us/announcementmessage",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-pdqa/v2/lao/en-us/announcementmessage",
		url_EMEA: " ",
		method: "POST"
	},
	messagesModalDetails: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-pdqa/v2/na/en-us/messagedetails",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-pdqa/v2/lao/en-us/messagedetails",
		url_EMEA: " ",
		method: "POST"
	},
	download: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Download/DownloadPAAsync",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/download/downloadpaasync",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Download/DownloadPAAsync",
		method: "POST"
	},
	downloadPADetails: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Download/DownloadPADetailAsync",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/download/downloadpadetailasync",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Download/DownloadPADetailAsync",
		method: "POST"
	},
	downloadPADetailsExcelFile: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Export/GetContractDetailExcel",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/export/getcontractdetailexcel",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Export/GetContractDetailExcel",
		method: "POST"
	},
	saveSearchName: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/SaveSearchName",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/savesearchname",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/SaveSearchName",
		method: "POST"
	},
	getPAType: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/PriceAgreement/patype",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/priceagreement/patype",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/PriceAgreement/patype",
		method: "GET"
	},
	getEndUserRequests: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-enduser-pxy-api-pdqa/na/v2/EndUserService/GetEndUserRequests",
		url_LAO: "https://kcc-sls-enduser-pxy-api-qa.us-e1.cloudhub.io/lao/v2/EndUserService/GetEndUserRequests",
		method: "POST"
	},
	getStateList: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-pdqa/v2/na/en-us/state",
		url_LAO: "https://kcc-sls-ang-kcpconfig-exp-api-supqa.br-s1.cloudhub.io/master/state",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/v2/Master/GetState",
		method: "POST"
	},
	endusersearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/ContractEndUser/GetEndUsersBySearchFields",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/contractenduser/getendusersbysearchfields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/ContractEndUser/GetEndUsersBySearchFields",
		method: "POST"
	},
	tradeHQSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/ContractGroup/GetTradeOrgBySearchFields",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/contractgroup/gettradeorgbysearchfields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/ContractGroup/GetTradeOrgBySearchFields",
		method: "POST"
	},
	customerSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/CustomerV2/GetCustomerBySearchFields",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/customer/getcustomerbysearchfields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/CustomerV2/GetCustomerBySearchFields",
		method: "POST"
	},
	recentDownloadGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/RecentDownload?isGlobalUser=false",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/recentdownload",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/RecentDownload",
		method: "GET"
	},
	recentDownloadDelete: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/RecentDownload/DeleteRecentDownloads",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/recentdownload/deleterecentdownloads",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/RecentDownload/DeleteRecentDownloads",
		method: "DELETE"
	},
	downloadCustomerList: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/RecentDownload/GetDownloadCustomer",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/recentdownload/getdownloadcustomer",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/RecentDownload/GetDownloadCustomer",
		method: "POST"
	},
	validateAddress: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-pdqa/v2/na/en-us/addressvalidation",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-db-ircp-sys-api-pdqa/v2/lao/en-us/addressvalidation",
		url_EMEA: " ",
		method: "POST"
	},
	submitEndUsers: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-enduser-pxy-api-pdqa/na/v2/EndUserService/SaveEndUsers",
		url_LAO: "https://kcc-sls-enduser-pxy-api-qa.us-e1.cloudhub.io/lao/v2/EndUserService/SaveEndUsers",
		method: "POST"
	},
	ClearCache: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/v2/Master/ClearCache",
		url_LAO: "https://kcc-sd-kcpuser-ux-api2-qa.us-e1.cloudhub.io/v2/lao/en-us/ClearCache",
		method: "POST"
	},
	downloadPadetailsExcel: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Export/GetContractDetailExcel",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/export/getcontractdetailexcel",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Export/GetContractDetailExcel",
		method: "POST"
	},
	selectedrecentDownloadDoculent: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/RecentDownload/GetDownloadLink",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/recentdownload/getdownloadlink",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/RecentDownload/GetDownloadLink",
		method: "POST"
	},
	endUserRequestsDownload: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-enduser-pxy-api-pdqa/na/v2/EndUserService/EndUserRequestsExport",
		url_LAO: "https://kcc-sls-enduser-pxy-api-qa.us-e1.cloudhub.io/lao/v2/EndUserService/EndUserRequestsExport",
		method: "POST"
	},
	myNotification: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/ChangeTracking/GetMyNotifications",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/changetracking/getmynotifications",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/ChangeTracking/GetMyNotifications",
		method: "POST"
	},
	getWelcomeFlag: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/GetFlag",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/getflag",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/GetFlag",
		method: "GET"
	},
	updateWelcomeFlag: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/UpdateFlag",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/updateflag",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/UpdateFlag",
		method: "GET"
	},
	exportPriceAgreementSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Export/PriceAgreementSearch",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/export/priceagreementsearch",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/emea/v2/Export/PriceAgreementSearch",
		method: "POST"
	},
	exportPADetailEndUser: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Export/ContractEndUsers",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/export/contractendusers",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Export/ContractEndUsers",
		method: "POST"
	},
	exportPADetailProduct: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Export/ContractProducts",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/export/contractproducts",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Export/ContractProducts",
		method: "POST"
	},
	exportPADetailTradeOrg: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Export/ContractGroups",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/export/contractgroups",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Export/ContractGroups",
		method: "POST"
	},
	exportMyNotification: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Export/GetMyNotification",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/export/getmynotification",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Export/GetMyNotification",
		method: "POST"
	},
	exportDashboardTiles: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Export/DashboardPriceAgreements",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/export/dashboardpriceagreements",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/Export/DashboardPriceAgreements",
		method: "POST"
	},
	getLocations: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/v2/Master/GetMasterDetails",
		// For now using the EMEA Url to show all region in dropdown
		// url_LAO: "https://kcc-sls-ang-kcpconfig-exp-api-pdqa.br-s1.cloudhub.io/master/masterdetails",
		url_LAO: "https://kcc-sls-ang-kcpconfig-exp-api-supqa.br-s1.cloudhub.io/master/masterdetails",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/v2/Master/GetMasterDetails",
		method: "GET"
	},
	getContentService: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/na/v2/Content/GetKCPContent",
		url_LAO: "https://kcc-sls-ang-kcpconfig-exp-api-supqa.br-s1.cloudhub.io/content/kcpcontent",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/EMEA/v2/Content/GetKCPContent",
		method: "POST"
	},
	getAccessManagementList: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/na/v2/Master/FeatureList",
		url_LAO: "https://kcc-sls-ang-kcpconfig-exp-api-supqa.br-s1.cloudhub.io/master/featurelist",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/EMEA/v2/Master/FeatureList",
		method: "POST"
	},
	registerUser: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-kcpreg-pxy-api-pdqa/na/v2/UserRegistration/UserRegistration",
		url_LAO: "https://kcc-sls-ang-kcpreg-exp-api-supqa.br-s1.cloudhub.io/userregistration",
		url_EMEA: "https://kcc-sls-ang-kcpreg-exp-api-pdqa.br-s1.cloudhub.io/userregistration",
		method: "POST"
	},
	getUserDetails: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/GetUserDetails",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/userdetails",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/GetUserDetails",
		method: "GET"
	},
	captchaValidationUrl: {
		url_NA: "https://kcc-sls-ang-kcpreg-exp-api.br-s1.cloudhub.io/ReCaptcha",
		url_LAO: "https://kcc-sls-ang-kcpreg-exp-api.br-s1.cloudhub.io/ReCaptcha",
		url_EMEA: "https://kcc-sls-ang-kcpreg-exp-api.br-s1.cloudhub.io/ReCaptcha",
		method: 'POST'
	},
	saveUpdateUserProfile: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/SaveUpdateUserProfile",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/saveupdateuserprofile",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/SaveUpdateUserProfile",
		method: 'POST'
	},
	CheckPAUpdates: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Dashboard/CheckPAUpdates",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/dashboard/checkpaupdates",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Dashboard/CheckPAUpdates",
		method: 'GET'
	},
	/** My Profile Related APIs */
	GetUserRoles: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/v2/Master/GetjobRole",
		url_LAO: "https://kcc-sls-ang-kcpconfig-exp-api-supqa.br-s1.cloudhub.io/master/jobrole",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/v2/Master/GetjobRole",
		method: 'GET'
	},
	searchUsers: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/SearchUser",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/usercustomermapping/searchuser",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/SearchUser",
		method: 'GET'
	},
	searchDelegatedUsers: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/usercustomermapping/searchuserforinternaladmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		method: 'POST'
	},
	GetCountrySalesOrgLanguage: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/UserCustomerCountries/UserCustomerCountries",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/usercustomermapping/usercustomercountries",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/UserCustomerCountries/UserCustomerCountries",
		method: 'GET'
	},
	UserCustomerCountries: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/UserCustomerCountries/UserCustomerCountries",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/usercustomermapping/usercustomercountries",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/UserCustomerCountries/UserCustomerCountries",
		method: 'GET'
	},
	GetSortBy: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/v2/Master/SortBy",
		url_LAO: "https://kcc-sls-ang-kcpconfig-exp-api-supqa.br-s1.cloudhub.io/master/sortby",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/v2/Master/SortBy",
		method: 'POST'
	},
	SaveUserDefaultProfile: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/SaveUserDefaultProfile",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/saveuserdefaultprofile",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/SaveUserDefaultProfile",
		method: 'POST'
	},
	UserDefaultLocations: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/UserDefaultLocations",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/usercustomermapping/userdefaultlocations",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/UserCustomerMapping/UserDefaultLocations",
		method: 'GET'
	},
	getLocationData: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/GetUserCustomerPreference",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/usercustomermapping/getusercustomerpreference",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/UserCustomerMapping/GetUserCustomerPreference",
		method: 'GET'
	},
	saveLocationData: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/SaveUserCustomerPreference",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/usercustomermapping/saveusercustomerpreference",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/UserCustomerMapping/SaveUserCustomerPreference",
		method: 'POST'
	},
	/** My Profile Related APIs End here */
	/** UC Mapping */
	getUsersAssociated: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/GetUserAssociatedToMapping",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/usercustomermapping/getuserassociatedtomapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/GetUserAssociatedToMapping",
		method: "POST"
	},

	//below apis are for UCmapping/portalusage and my profile too. Hence kept here too	
	removeUcMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/DeleteUserCustomerMapping",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/usercustomermapping/deleteusercustomermapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/UserCustomerMapping/DeleteUserCustomerMapping",
		method: "POST"
	},
	markAsAdmin: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/SetGroupAdmin",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/usercustomermapping/setgroupadmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/SetGroupAdmin",
		method: "POST"
	},
	addUcMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/SaveUserCustomerMapping",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/usercustomermapping/saveusercustomermapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/V2/UserCustomerMapping/SaveUserCustomerMapping",
		method: "POST"
	},
	getStates: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/v2/Master/GetState",
		url_LAO: "https://kcc-sls-ang-kcpconfig-exp-api-supqa.br-s1.cloudhub.io/master/state",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/v2/Master/GetState",
		method: "POST"
	},
	ucMappingCustomerSearch: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/CustomerV2/GetCustomerBySearchFields",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/customer/getcustomerbysearchfields",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/CustomerV2/GetCustomerBySearchFields",
		method: "POST"
	},
	ucMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/GetUserCustomerMapping",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/usercustomermapping/getusercustomermapping",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/GetUserCustomerMapping",
		method: "POST"
	},
	customerMappings: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/CustomerV2/GetCustomerHierarchy",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/customer/customerhierarchy",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/CustomerV2/GetCustomerHierarchy",
		method: "POST"
	},
	portalUsageInternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/usercustomermapping/searchuserforinternaladmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/SearchUserForInternalAdmin",
		method: "POST"
	},
	portalUsageExternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/SearchUserForExternalAdmin",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/usercustomermapping/searchuserforexternaladmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/SearchUserForExternalAdmin",
		method: "POST"
	},
	exportPortalUsageInternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Export/SearchUserForInternalAdmin",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/export/searchuserforinternaladmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Export/SearchUserForInternalAdmin",
		method: "POST"
	},
	exportPortalUsageExternal: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/Export/SearchUserForExternalAdmin",
		url_LAO: "https://kcc-sls-ang-priceagreement-exp-api-supqa.br-s1.cloudhub.io/export/searchuserforexternaladmin",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Export/SearchUserForExternalAdmin ",
		method: "POST"
	},
	//above apis are for UCmapping/portalusage and my profile too. Hence kept here too 

	saveUserContact: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/UserService/SaveUserContact",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/saveusercontact",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/SaveUserContact",
		method: "POST"
	},
	recentDownloadExport: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v1/Export/RecentDownloadsExport",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/export/recentdownloadsexport",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/Export/RecentDownloadsExport",
		method: "POST"
	},
	pardot: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/pardot",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/pardot",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/Pardot",
		method: "POST"
	},
	GlobalUserAuditLog: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/na/v2/UserService/GlobalUserAuditLog",
		url_LAO: "https://kcc-sls-ang-kcpuser-exp-api-supqa.br-s1.cloudhub.io/userservice/GlobalUserAuditLog",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/GlobalUserAuditLog",
		method: "POST"
	},
	getNewlyAddedCustomers: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/GetNewlyAddedCustomers",
		url_LAO: "",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/UserCustomerMapping/GetNewlyAddedCustomers",
		method: "POST"
	},
	getSalesOrgNames: {
		url_NA: "",
		url_LAO: "",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/SalesOrg/GetSalesOrgDescription",
		method: "GET"
	},
	getKCOwnerNames: {
		url_NA: "",
		url_LAO: "",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/PriceAgreement/KCOwner",
		method: "GET"
	},
	multipleChanges:{
		url_NA: "",
		url_LAO: "",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/ChangeTracking/KCPUserUpdatesByDuration",
		method: "POST"
	},
	rebateDownload:{
		url_NA: "",
		url_LAO: "",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/emea/v2/Rebate/DownloadRebate",
		method: "POST"
	},
	exportRebatePriceSearch:{
		url_NA: "",
		url_LAO: "",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Export/RebateSearch",
		method: "POST"
	},
	getOrderPopupPreferences: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/v2/Master/GetOrderpopupPreferences",
		url_LAO: "",
		url_EMEA: "",
		method: "GET"
	},
	getOrderPreferences: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/GetOrderPreferences",
		url_LAO: "",
		url_EMEA: "",
		method: "GET"
	},
	saveOrderPreferences: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/SaveOrderPreferences",
		url_LAO: "",
		url_EMEA: "",
		method: "POST"
	},
	signalREndpointPA: {
		url_NA: "https://app-emea-pam-kimclar-qa-weur-lane2.azurewebsites.net/signalrhub/PAMHubEntryPoint",
		url_LAO: "https://app-emea-pam-kimclar-qa-weur-lane2.azurewebsites.net/signalrhub/PAMHubEntryPoint",
		url_EMEA: "https://app-emea-pam-kimclar-qa-weur-lane2.azurewebsites.net/signalrhub/PAMHubEntryPoint"
	},
	auditLogout: {
		url_NA: "",
		url_LAO: "",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/emea/v2/UserService/Logout",
		method: "GET"
	},
	spartaModifyPAURL: {
		url_NA: "",
		url_LAO: "",
		url_EMEA: "https://qa3.kcsparta.com/createpa/modifypa?contractno=####&SFDC=0"
	},
	UCMSync: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/TriggerUCMSync",
		url_LAO: "https://us1.api.kimclark.com/kcc-sls-ang-priceagreement-pxy-api-pdqa/na/v2/UserCustomerMapping/TriggerUCMSync",
		url_EMEA: "",
		method: "POST"
	}
};

export { endPoints_PA_QA };