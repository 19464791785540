import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
var HyperlinkAndPopupComponent = /** @class */ (function () {
    function HyperlinkAndPopupComponent(router, activatedRoute) {
        this.router = router;
        this.activatedRoute = activatedRoute;
    }
    HyperlinkAndPopupComponent.prototype.ngOnInit = function () {
    };
    HyperlinkAndPopupComponent.prototype.agInit = function (params) {
        this.params = params;
    };
    HyperlinkAndPopupComponent.prototype.refresh = function (params) {
        return false;
    };
    HyperlinkAndPopupComponent.prototype.onLinkClick = function () {
        switch (this.params.colDef.field) {
            case 'customerComments':
                var selectedCustomer = tslib_1.__assign({}, this.params.data);
                this.router.navigate([{ outlets: { dialogs: ['multipleChanges'] } }], { relativeTo: this.activatedRoute.parent, state: { selectedCustomer: selectedCustomer } });
                break;
        }
    };
    HyperlinkAndPopupComponent.prototype.ngOnDestroy = function () {
    };
    return HyperlinkAndPopupComponent;
}());
export { HyperlinkAndPopupComponent };
