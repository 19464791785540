/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./number-formatter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./number-formatter.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../Shared/Services/language-selection.service";
import * as i5 from "../../../services/cp-ag-grid-service";
import * as i6 from "../../../../Shared/Services/global-app.service";
var styles_NumberFormatterComponent = [i0.styles];
var RenderType_NumberFormatterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NumberFormatterComponent, data: {} });
export { RenderType_NumberFormatterComponent as RenderType_NumberFormatterComponent };
export function View_NumberFormatterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.numericValue; _ck(_v, 1, 0, currVal_0); }); }
export function View_NumberFormatterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "number-formatter", [], null, null, null, View_NumberFormatterComponent_0, RenderType_NumberFormatterComponent)), i1.ɵdid(1, 180224, null, 0, i2.NumberFormatterComponent, [i1.LOCALE_ID, i3.TranslateService, i4.LanguageSelectionService, i5.CpAgGridService, i6.GlobalAppService], null, null)], null, null); }
var NumberFormatterComponentNgFactory = i1.ɵccf("number-formatter", i2.NumberFormatterComponent, View_NumberFormatterComponent_Host_0, {}, {}, []);
export { NumberFormatterComponentNgFactory as NumberFormatterComponentNgFactory };
