<div #cpContainer>
  <cp-sort-component #sortComponent [colId]="colId" [orgColId]="orgColId" (onSortRequested)="onSortRequested($event)">
  </cp-sort-component>
  <div class="form-group form-group--date-picker">
    <div class="form-group--date-picker__Single">
      <p-calendar #calendarElement [maxDate]="maxDateValue" (onClose)="onApplyClick($event,cpContainer)"
        (onSelect)="onDateSelected($event)" [placeholder]="dateFormat" [dateFormat]="primengDateFormat" [monthNavigator]="true" [yearNavigator]="true"
        yearRange="1900:2100" [(ngModel)]="txtDate" [locale]="calenderData" name="validDate_fromDate"
        styleClass="calendarClass dateRangeCalendar" (onInput)="onDateSelected($event)"
        (onFocus)="onCalFocus(cpContainer)" [baseZIndex]="1000000"
        (keypress)="onKeyPress($event,cpContainer)"
        panelStyleClass="ag-custom-component-popup"
        (onShow)="onShow()">
      </p-calendar>
      <i class="search__button fas fa-search" aria-hidden="true" (click)="onApplyClick($event,cpContainer)"></i>
    </div>
  </div>
</div>