<div class="container-fluid delegatedUser">
  <div class="row">
    <div class="col-12">
      <div class="content-area__white-box">
        <div class="content-area__white-box__heading content-area__white-box__heading--right-content">
          <div class="row row--no-padding">
            <div class="col-12 col-md-6">
              <ng-container *ngIf="recordCount">
                <h2>{{recordCount}} {{ 'agGrid.resultLabel' | translate }}
                </h2>
              </ng-container>
            </div>
            <div class=" col-12 col-md-6">
              <ng-container *ngIf="showRowSelectionMsg">
                <p class="content-area__white-box__heading__alert content-area__white-box__heading__alert--selected">
                  <i class="fas fa-check-circle"></i>{{ "delegatedUser.searchResult.selectedUserLabel" | translate }}
                </p>
                <a class="btn btn-primary btn-primary--small" href="javascript:void(0)" (click)="validateUserRoles()">
                  <i class="far fa-check-circle"></i>
                  {{ "delegatedUser.searchResult.loginAsSelectedUserLabel" | translate }}</a>
              </ng-container>
              <ng-container *ngIf="!showRowSelectionMsg">
                <p class="content-area__white-box__heading__alert content-area__white-box__heading__alert--notselected">
                  <i class="fas fa-check-circle"></i>{{ "delegatedUser.searchResult.selectUserLabel" | translate }}
                </p>
                <a class="btn btn-primary btn-primary--small btn-primary--disabled" href="javascript:void(0)">
                  <i class="far fa-check-circle"></i>
                  {{ "delegatedUser.searchResult.loginAsSelectedUserLabel" | translate }}</a>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="custom-table-filters custom-table-filters--middle row">
          <div class="col-12">
            <ul class="unlisted inline custom-table-filters__users">
              <li>
                <!-- <i class="fas fa-grip-lines-vertical"></i> -->
                <i class="verticalSeparator">|</i>
                <i class="verticalSeparator">|</i>
                {{ "delegatedUser.searchResult.highlightedUserLabel" | translate }}<span
                  style="font-weight: 100;">{{ "delegatedUser.searchResult.highlightedUserLabel1" | translate }}</span>
              </li>
              <li><i class="fas fa-user-friends"></i>
                {{"delegatedUser.searchResult.groupAdministratorLabel" | translate}}</li>
              <li><i class="fas fa-user-slash"></i>
                {{"delegatedUser.searchResult.nonAssociatedUserLabel" | translate}}</li>
            </ul>
          </div>
        </div>
        <div class="custom-table-filters row">
          <div class="col-6">
            <p class="custom-table-filters__select-filter" *ngIf="(!allFilters) || allFilters.length==0"><i
                class="fas fa-filter"></i>{{ 'agGrid.agGridHeading.selectFilters' | translate }}
            </p>
          </div>
          <div class="col-6">
            <a class="custom-table-filters__icon-link" href="javascript:void(0)"
                *ngIf="recordCount && recordCount>0" (click)="exportExcelView()"><i
                    class="far fa-file-excel"></i>{{ 'agGrid.agGridHeading.exportViewLabel' | translate }}</a>
        </div>
        </div>
        <div class="col-12 col-md-12">
          <div class="custom-table-clear-filters">
            <ul class="unlisted inline">
              <li *ngIf="allFilters && allFilters.length>0">
                <i class="far fa-times-circle" aria-hidden="true" (click)="onRemoveFilter($event, 'all')"></i>
                {{ 'agGrid.agGridHeading.clearAllFilters' | translate }}
              </li>
              <li *ngFor="let filter of allFilters; let i = index;">
                <i class="far fa-times-circle" aria-hidden="true" (click)="onRemoveFilter($event, filter.key)"></i>
                <ng-container *ngIf="filter.type != 'multi-select' && filter.type != 'date-range-selector'">
                  {{filter.header | translate }} :
                  {{filter.text | translate }}</ng-container>
                <ng-container *ngIf="filter.type == 'multi-select'">
                  {{filter.header | translate }} :
                  <ng-container *ngFor="let item of filter.text; let itemIndex = index;">
                    {{ item | translate }}
                    <ng-container *ngIf="itemIndex !== filter.text.length - 1">, </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="filter.type == 'date-range-selector'">
                  {{filter.header | translate }} :
                  {{  filter.rangeType  | translate }} {{filter.text | translate }}
                </ng-container>
              </li>
            </ul>
          </div>
        </div>
        <ag-grid-angular #agGrid style="width: 100%;"
          class="ag-theme-balham singleSelectionGrid gridHeaderRowCellHeightFix" [rowData]="rowData"
          [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowHeight]="rowHeight" rowSelection="single"
          [suppressRowTransform]=true suppressRowClickSelection=true [frameworkComponents]="frameworkComponents"
          [rowModelType]="rowModelType" [paginationPageSize]="paginationPageSize"
          [cacheOverflowSize]="cacheOverflowSize" [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
          [maxBlocksInCache]="maxBlocksInCache" [pagination]="true" [infiniteInitialRowCount]="infiniteInitialRowCount"
          [suppressPaginationPanel]="true" [suppressScrollOnNewData]="true" [gridOptions]="gridOptions"
          (gridReady)="onGridReady($event)" (columnResized)="onColumnResized($event)"
          (filterChanged)="onFilterChanged($event)" domLayout="autoHeight" (rowSelected)="onRowSelected($event)"
          [noRowsOverlayComponent]="noRowsOverlayComponent" [loadingOverlayComponent]="loadingOverlayComponent"
          [processCellForClipboard]="processCellForClipboard" [rowClassRules]="rowClassRules">
        </ag-grid-angular>
        <cp-grid-pagination [resultsPerPageId]="'dd-eu-search-results-page-size'"
          (pageSizeChanged)="onPageSizeChanged($event)"></cp-grid-pagination>
      </div>
    </div>
  </div>
</div>

<p-toast key="noEBAM" position="center" [style]="{ marginTop : '100px', width: '400px'}" [closable]="true"
[baseZIndex]="999999" [autoZIndex]="999999" [hideTransitionOptions]="'500ms'" styleClass="no-ebam-message">
<ng-template let-message pTemplate="message">
   <div style="text-align: center;">
    <h3>{{message.summary | translate}}</h3>
    <p>{{message.detail | translate}}</p>
   </div>
</ng-template>
</p-toast>
