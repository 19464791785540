import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { TranslateService } from '@ngx-translate/core';
var TimeFormatComponent = /** @class */ (function () {
    function TimeFormatComponent(translateServ) {
        this.translateServ = translateServ;
        this.formatTime = "hh:mm a";
    }
    TimeFormatComponent.prototype.agInit = function (params) {
        var _this = this;
        this.translateServ.get(['calender'])
            .subscribe(function (translations) {
            var calenderData = translations['calender'];
            _this.formatDate = calenderData.gridDateFormat;
        });
        this.params = params;
        this.dates = this.params.data ? this.params.data.updated : "";
    };
    TimeFormatComponent.prototype.refresh = function (params) {
        return false;
    };
    TimeFormatComponent.prototype.ngOnDestroy = function () {
    };
    TimeFormatComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [TranslateService])
    ], TimeFormatComponent);
    return TimeFormatComponent;
}());
export { TimeFormatComponent };
