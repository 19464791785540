<a class="dnlLink" href="javascript:void(0)" (click)="onLinkClick($event)">
  {{paramsValue}}
</a>
<span class="copyUrlLink" [copy-clipboard]="downloadLink" (copied)="notify($event)">
  <i class="far fa-copy" pTooltip="Copy download link"></i>
</span>
<span *ngIf="showNotification" class="saveNotification"><i class="fa fa-check" aria-hidden="true"></i>Link copied</span>
<p-toast key="downloadError" position="center" [style]="{ marginTop : '100px', width: '400px' }" [closable]="true"  [baseZIndex]="500" [autoZIndex]="100" [hideTransitionOptions]="'250ms'">
  <ng-template let-message pTemplate="message">
      <div style="text-align: center">
          <h3>{{message.summary | translate}}</h3>
          <p>{{message.detail | translate}}</p>
      </div>
  </ng-template>
</p-toast>