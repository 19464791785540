/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./time-format.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./time-format.component";
var styles_TimeFormatComponent = [i0.styles];
var RenderType_TimeFormatComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimeFormatComponent, data: {} });
export { RenderType_TimeFormatComponent as RenderType_TimeFormatComponent };
export function View_TimeFormatComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2), (_l()(), i1.ɵted(4, null, ["\n", " ", ""])), i1.ɵppd(5, 2), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), _co.dates, _co.formatDate)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.dates, _co.formatTime)); var currVal_2 = i1.ɵunv(_v, 4, 1, i1.ɵnov(_v, 6).transform("timeStamp")); _ck(_v, 4, 0, currVal_1, currVal_2); }); }
export function View_TimeFormatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "time-format", [], null, null, null, View_TimeFormatComponent_0, RenderType_TimeFormatComponent)), i1.ɵdid(1, 180224, null, 0, i4.TimeFormatComponent, [i3.TranslateService], null, null)], null, null); }
var TimeFormatComponentNgFactory = i1.ɵccf("time-format", i4.TimeFormatComponent, View_TimeFormatComponent_Host_0, {}, {}, []);
export { TimeFormatComponentNgFactory as TimeFormatComponentNgFactory };
