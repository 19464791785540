import { Component, OnDestroy } from '@angular/core';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { AgRendererComponent } from 'ag-grid-angular';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()

@Component({
  selector: 'invoice-default-customer-number',
  templateUrl: './invoice-default-customer-number.component.html',
  styleUrls: ['./invoice-default-customer-number.component.scss']
})
export class InvoiceDefaultCustomerNumberComponent implements AgRendererComponent, OnDestroy {
  customerNumber: string;

  constructor(
    private appServ: AppService
  ) { }

  agInit(params: any) {
    if (this.appServ.userProfileInfo && this.appServ.userProfileInfo["defaultCustomerInfo"] && this.appServ.userProfileInfo["defaultCustomerInfo"].length) {
      this.customerNumber = this.appServ.userProfileInfo["defaultCustomerInfo"][0].customerNumber;
    }
    else {
      this.appServ.getuserProfileData().subscribe((data) => {
        if (data["defaultCustomerInfo"] && data['defaultCustomerInfo'].length) { 
          this.customerNumber = data["defaultCustomerInfo"][0].customerNumber;
        }
      });
    }
  }

  setCustomerNumber(){
    
  }

  refresh(params: any): boolean {
    return false;
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }

}
