import { Component, OnInit, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
// import { CB_SPA_LEGACY_MENU_HOST } from '@env/environment';
// import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { OktaProfile } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CouponDetailsService } from '@app/Modules/chargeback/Modules/CBCouponDetails/Services/coupon-details.service';
import { cbMaxNoOfLinesToShowLinelevelDetailsPopup } from '@app/conf/dataConfigurations';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';

@Component({
  selector: 'app-cbhyperlink',
  templateUrl: './cbhyperlink.component.html',
  styleUrls: ['./cbhyperlink.component.scss']
})
export class CbhyperlinkComponent implements AgRendererComponent, OnDestroy {

  params: any;
  oktaProfile: OktaProfile;
  isMCCustomerCol: boolean = false;
  hideLink: boolean = true;
  FormattedValue: string;

  constructor(
    private appServ: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private couponDetailsService: CouponDetailsService,
    private globalAppService: GlobalAppService,
    private languageServ: LanguageSelectionService
  ) { }

  ngOnInit() {
    this.oktaProfile = this.appServ.AvailableOKtaProfile;

  }

  agInit(params: any): void {
    this.params = params;
    if (params && params.colDef && params.colDef.headerName && params.colDef.headerName == 'agGrid.agGridColumns.MCcustomer') {
      this.isMCCustomerCol = true;
    }
    const ZeroCheck =(params && params.value) ? params.value.toString().trim() : '';
    if(ZeroCheck == '0.00' || ZeroCheck == '0,00' || ZeroCheck == '0.0000' || ZeroCheck == '0,0000')
    {
      this.hideLink = false;
    }
    else
    {
      this.hideLink = true;
    }

    const languageCode = this.globalAppService.getDefaultLanguageString();
    this.formatValue(params, languageCode);
    this.languageServ.getlanguage().subscribe(langCode => {
      this.formatValue(params, langCode);
    });
  }

  formatValue(params, languageCode){
    if (params && params.colDef && params.colDef.headerName && (params.colDef.headerName == 'agGrid.agGridColumns.errorsLabel' || params.colDef.headerName == 'agGrid.agGridColumns.netIssuedLabel')) {
      this.FormattedValue = this.globalAppService.getNumberCurrencyFormatted(languageCode, params.value, 'NUMBER', 2);
      if(this.FormattedValue.indexOf('-') > -1){
        this.FormattedValue = '(' + this.FormattedValue.substring(1) + ')';
      }
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClick() {
    let paramAvailabel = this.params && this.params.data;
    let couponNumber = paramAvailabel ? this.params.data.couponNumber : "";
    let companyCode = paramAvailabel ? this.params.data.companyCode : "";
    let fiscalYear = paramAvailabel ? this.params.data.fiscalYear : "";
    let salesOrgId = paramAvailabel ? this.params.data.salesOrgId : "";
    //let colName = paramAvailabel && this.params.colDef.field != "couponNumber" ? this.params.colDef.field : "";
    let redirectFrm = "";
    if (paramAvailabel && this.params.colDef.field == "netIssued") {
      redirectFrm = 'NetIssued';
    } else if (paramAvailabel && this.params.colDef.field == "errors") {
      redirectFrm = 'ErrorValue';
    }

    let mcNumber = paramAvailabel ? this.params.data.contractNumber : "";
    let customerNumber = paramAvailabel ? this.params.data.customerNumber : "";
    let mcStatus = paramAvailabel ? parseInt(this.params.data.status) : 0;
    let meetCompName = paramAvailabel ? this.params.data.meetCompName : "";
    if (this.params.colDef.headerName == 'agGrid.agGridColumns.customerEULabel') {

      if (this.params.data.lines >= cbMaxNoOfLinesToShowLinelevelDetailsPopup) {
        this.router.navigate([{ outlets: { dialogs: ['downloadMoreLinesModal'] } }], { relativeTo: this.activatedRoute.parent, state: { euNumber: this.params.data.custEU, kCEU: this.params.data.kCEU, kCEUDetails: this.params.data.kCEUDetails  }, queryParamsHandling: "preserve" });
      } else {
        this.couponDetailsService.setSelectedCustEU(this.params.data);
        this.couponDetailsService.setDetailsPageQueryData(
          {
            customerEUNumber: this.params.data.custEU,
            customerEuDetail: ""
          }
        );
        this.router.navigate([{ outlets: { euSelected: 'euSelected' } }], { relativeTo: this.activatedRoute.parent, queryParamsHandling: "preserve" });
      }
    } else if (this.params.colDef.headerName == 'agGrid.agGridColumns.MCNumber') {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/meetcomps/meetcompsDetails/details'], { relativeTo: this.activatedRoute.parent, queryParams: { mcNumber: mcNumber, mcStatus: mcStatus } })
      );
      window.open(url, '_blank');
    } else if (this.params.colDef.headerName == 'agGrid.agGridColumns.MCcustomer') {
      this.router.navigate([{ outlets: { customerDetailsDialog: ['customerDetails'] } }], { relativeTo: this.activatedRoute.parent, queryParams: { mcNumber: mcNumber, customerNumber: customerNumber, mcStatus: mcStatus, mcName: meetCompName } });

    } else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/chargeback/coupon/details'], { relativeTo: this.activatedRoute.parent, queryParams: { couponNumber: couponNumber, cc: companyCode, fiscalYear: fiscalYear, salesOrgId: salesOrgId, redirectFrom: redirectFrm } })
      );
      window.open(url, '_blank');
    }

  }

  ngOnDestroy() {

  }

}
