<div #cpContainer>
  <cp-sort-component #sortComponent [colId]="colId" [orgColId]="orgColId" (onSortRequested)="onSortRequested($event)">
  </cp-sort-component>
  <div class="topBorder">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" id="validDate_ufn" name="validDate_fromDate" class="custom-control-input"
        [(ngModel)]="txtDateRadio" (change)="onUFNChecboxClicked($event)">
      <label class="custom-control-label" for="validDate_ufn">UFN</label>
    </div>
    <div class="form-group form-group--date-picker">
      <div class="form-group--date-picker__Single">
        <p-calendar #calendarElement [maxDate]="maxDateValue" (onSelect)="onDateSelected($event)" [placeholder]="dateFormat" [dateFormat]="primengDateFormat"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:2100" [(ngModel)]="txtDate"
          [locale]="calenderData" name="validDate_fromDate" styleClass="calendarClass dateCalendar"
          (onFocus)="onCalFocus(cpContainer)" [disabled]="txtDateRadio" [baseZIndex]="1000000"
          panelStyleClass="ag-custom-component-popup"
          (onClose)="OnCalClosed(cpContainer)">
        </p-calendar>
      </div>
    </div>
  </div>
  <div class="buttonWrapper">
    <button class="btn btn-primary btn-primary--icon-left" (click)="onApplyClick($event,cpContainer)">
      <i class="far fa-check-circle"></i>
      {{ "calender.apply" | translate }}
    </button>
    <span (click)="onRemoveFilter()" class="clearFilter">
      <i class="far fa-times-circle" aria-hidden="true"></i> {{ "calender.clear" | translate }}
    </span>
  </div>
</div>