<div class="sub-nav-l1" [ngClass]="{'sub-nav-l1--open': mobilemenu}">
  <div class="sub-nav-l1__chevron sub-nav-l1__chevron--left"><i class="fas fa-chevron-left"></i></div>
  <div class="sub-nav-l1__chevron sub-nav-l1__chevron--right"><i class="fas fa-chevron-right"></i></div>
  <div class="sub-nav-l1__mobile-trigger d-lg-none" (click)="showMobileDropMenu()">
     <i class="{{mobilemenuActive.icon}}" *ngIf="mobilemenuActive && mobilemenuActive.icon"></i>&nbsp;
     <span *ngIf=" mobilemenuActive && mobilemenuActive.label">
        {{"priceagreement.ribbonmenu."+mobilemenuActive.label | translate}}</span>&nbsp;
     <i class="fas fa-chevron-down"></i></div>
  <div class="sub-nav-l1__draggable dragscroll">
     <ul class="sub-nav-l1__nav unlisted">
        <!--ExternalUser menu-->
        <ng-container *ngIf='!isInternalUser'>
           <li *ngFor="let menu of customerViewPUMenu" [ngClass]="[ activeMenu==menu.id ? 'sub-nav-l1__nav__current-page' : '',
              activeMenu==menu.id && menu.hasChild ? 'sub-nav-l1__nav__has-l2 sub-nav-l1__nav__has-l2--open' : '']"
              (click)="activeMenuClick(menu)">
              <ng-container *ngIf="!menu.hasChild">
                 <a [routerLink]='menu.uri' queryParamsHandling="preserve">{{ "portalusage.ribbonmenu."+menu.label | translate}}</a>
              </ng-container>
              <ng-container *ngIf="menu.hasChild">
                 <a [routerLink]='menu.uri' queryParamsHandling="preserve"><span>{{ "portalusage.ribbonmenu."+menu.label | translate}}</span></a>
              </ng-container>
           </li>
        </ng-container>
        <!--Internal User menu for customer View-->
        <ng-container *ngIf='isInternalUser'>
            <li *ngFor="let menu of InternalViewHelpmenu" [ngClass]="[ activeMenu==menu.id ? 'sub-nav-l1__nav__current-page' : '',
            activeMenu==menu.id && menu.hasChild ? 'sub-nav-l1__nav__has-l2 sub-nav-l1__nav__has-l2--open' : '']"
               (click)="activeMenuClick(menu)">
               <ng-container *ngIf="!menu.hasChild">
                  <a [routerLink]='menu.uri' queryParamsHandling="preserve"><i
                        class={{menu.icon}}></i>{{ "help.ribbonmenu."+menu.label | translate}}</a>
               </ng-container>
               <ng-container *ngIf="menu.hasChild">
                  <a [routerLink]='menu.uri' queryParamsHandling="preserve"><span><i
                           class={{menu.icon}}></i>{{ "help.ribbonmenu."+menu.label | translate}}</span></a>
               </ng-container>
            </li>
         </ng-container>
     </ul>
  </div>
</div>
<div class="sub-nav-l2-desktop d-none d-lg-block" *ngIf="ShowSubmenu">
  <ul class="unlisted inline">
     <li *ngFor="let submenu of submenus"
        [ngClass]="[ activeSubmenu==submenu.id ? 'sub-nav-l2-desktop__current-page' : '']"
        (click)="submenuclick(submenu)"><a [routerLink]='submenu.uri' queryParamsHandling="preserve">{{submenu.label}}</a></li>
  </ul>
</div>

<div class="container-fluid">
   <div class="row">
     <div class="col-12">
       <div class="content-area__white-box search-items">
         <div class="content-area__white-box__heading content-area__white-box__heading--simple">
           <h2>
             {{'help.interactiveLabel' | translate}}
           </h2>
         </div>
         
         <div class="col-12">
            <div class="intro__button-box">
               <div>
                  <button>
                     class="btn btn-primary btn-primary--icon-right">{{ "help.launchbuttonLabel" | translate }} <i
                        class="far fa-arrow-alt-circle-right"></i></button>   
               </div>
            </div>
         </div>
       </div>
     </div>
   </div>
 </div>