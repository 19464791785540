import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { TranslateService } from '@ngx-translate/core';

@AutoUnsubscribe()

@Component({
  selector: 'time-format',
  templateUrl: './time-format.component.html',
  styleUrls: ['./time-format.component.scss']
})

export class TimeFormatComponent implements AgRendererComponent, OnDestroy {

  params: any;
  dates: any;
  formatTime: string = "hh:mm a";
  formatDate: string;

  constructor(
    private translateServ: TranslateService
  ) { }

  agInit(params: any) {
    this.translateServ.get(['calender'])
      .subscribe(translations => {
        let calenderData = translations['calender'];
        this.formatDate = calenderData.gridDateFormat;
      });
    this.params = params;
    this.dates = this.params.data ? this.params.data.updated: "";
  }

  refresh(params: any): boolean {
    return false;
  }

  ngOnDestroy(){

  }

}
