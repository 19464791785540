import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { PaService } from '@app/Modules/PriceAgreement/Services/pa.service';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
var TooltipComponent = /** @class */ (function () {
    function TooltipComponent(_paService, _http) {
        this._paService = _paService;
        this._http = _http;
        this.subscription = new Subscription();
    }
    TooltipComponent.prototype.agInit = function (params) {
        this.params = params;
        if (params.column.colDef.headerName === "agGrid.agGridColumns.customerNameLabel"
            && params.column.colDef.field === 'customerName' && (params.context.gridService.gridName != "myNotifications" && params.context.gridService.region != "EMEA")) {
            var fullNameList = [];
            var customerName = params.data ? params.data.customerName : null;
            if (customerName) {
                fullNameList.push(customerName.name1 || '');
                // fullNameList.push(customerName.name2 || '');
                // fullNameList.push(customerName.name3 || '');
                // fullNameList.push(customerName.name4 || '');
            }
            this.cellValue = fullNameList && fullNameList.length ? fullNameList.join(' ') : '';
        }
        else if (params.column.colDef.headerName === "agGrid.agGridColumns.customerNameLabel"
            && params.column.colDef.field === 'fullName') {
            var fullNameList = [];
            var customerName = params.data ? params.data.fullName : null;
            if (customerName) {
                fullNameList.push(customerName.name1 || '');
                // fullNameList.push(customerName.name2 || '');
                // fullNameList.push(customerName.name3 || '');
                // fullNameList.push(customerName.name4 || '');
            }
            this.cellValue = fullNameList && fullNameList.length ? fullNameList.join(' ') : '';
        }
        else
            this.cellValue = params.value;
    };
    TooltipComponent.prototype.refresh = function (params) {
        return false;
    };
    TooltipComponent.prototype.showCustomerInfoTooltip = function (event, overlaypanel) {
        var _this = this;
        this.customerNumber = this.params.data.customerNumber;
        this.overlaySubscription = this._http.getCustomerInfo(this.customerNumber)
            .subscribe(function (data) {
            if (data['customerInformation'] && data['customerInformation'].length > 0) {
                _this.customerName = data['customerInformation'][0]['customer_Name_1'];
                _this.street = data['customerInformation'][0]['street1'];
                _this.city = data['customerInformation'][0]['city'];
                _this.district = data['customerInformation'][0]['district_1'];
                _this.country = data['customerInformation'][0]['country'];
                _this.zipcode = data['customerInformation'][0]['zip_Code'];
                overlaypanel.show(event);
            }
        }, function (err) {
            console.log("Error");
        });
        this.subscription.add(this.overlaySubscription);
    };
    TooltipComponent.prototype.hideCustomerInfoTooltip = function (overlaypanel) {
        overlaypanel.hide();
        if (this.overlaySubscription)
            this.overlaySubscription.unsubscribe();
    };
    TooltipComponent.prototype.ngOnDestroy = function () {
        // Required for unsubscribing and destroying the component  
    };
    TooltipComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [PaService, HttpService])
    ], TooltipComponent);
    return TooltipComponent;
}());
export { TooltipComponent };
