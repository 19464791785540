import { Injectable, Inject } from '@angular/core';
import {
  formatDate
} from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { BehaviorSubject, Observable, Subject, observable, forkJoin, of } from 'rxjs';
import {
  TradeOrgEndUserRequest,
  PASearchQuery,
  PAEndUserQuery,
  PAProductQuery,
  TradeOrgQuery,
  EndUserRequestsQuery,
  MyNotificationQuery,
  ProductSearchFilter,
  endusersearchQuery,
  hqModalQuery,
  TradeOrgEndUser,
  GetCustomerInfoFilter,
  SoldToQuery,
  UserSearchQuery,
  CustomerSearchFilter,
  UserSearchFilter,
  UcMappingCustomerSearchFilter,
  DelegatedUserSearchFilter,
  DownloadCustomerFilter,
  PuSearchQuery,
  PuSearchFilter,
  CBSearchQuery,
  CBTilesFilter,
  CBSearchFilter,
  CBCouponSummaryResult,
  CBCouponSummaryResultFilter,
  CBEfileSearchFilter,
  CBEfileErrorSummaryResultFilter,
  MCCustomerDetailsFilter,
  MCProductsDetailsFilter,
  MCSearchFilter,
  endusersearchFilter,
  hqModalFilter,
  InvoiceCustomerSearchFilter,
  UnclaimedTileFilter,
  CBKcfileSearchFilter,
  CBKcfileErrorSummaryResultFilter,
  KcfilePASearchQuery
} from '@app/Modules/Shared/DataModels/SharedDataModel';

import { kcpUpdates, endUserComments, customerComments, customerSoldToComments, cbStatus, cbClaimRequestType, cbRvRb, endUserDetailsFooterTitle, configuredWidth } from '@app/conf/dataConfigurations';
import { AppService } from '@app/Modules/Shared/Services/app.service';

import { group } from '@angular/animations';
import { filter } from 'rxjs-compat/operator/filter';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpChargeBackService } from '@app/Modules/Shared/Services/http-charge-back.service';
import { HttpMeetCompService } from '@app/Modules/Shared/Services/http-meet-comp.service';
import { HttpInvoicePriceService } from '@app/Modules/Shared/Services/http-invoice-price.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EfileKcfileAccessSearchFilter } from './../../Shared/DataModels/SharedDataModel';
import { HttpInvoicePriceNewService } from '@app/Modules/Shared/Services/http-invoice-price-new.service';
@Injectable({
  providedIn: 'root'
})

// Important Note: Please provide this service in component decorator- providers section
export class CpAgGridService {
  _allFilters: any[];
  _columnDefs: any;
  _gridApi: any;
  _clientPageSize: number;
  _serverPageSize: number;
  isSorted: boolean;
  _gridRowModelType: string;
  _initialCall: boolean;
  allData: any;
  _treeData: any;
  isMultiSelect: boolean = false;
  multiSelectDistinctFilter: any = {};

  // Grid Attributes constants/readonly
  readonly cacheOverflowSize: number = 0;
  readonly maxConcurrentDataSourceRequests: number = 2;
  readonly infiniteInitialRowCount: number = 0;
  readonly maxBlocksInCache: number = 0;
  readonly rowModelType: string = 'infinite';
  readonly serverSideRowModelType: string = 'serverSide';
  readonly rowSelection: string = 'multiple'

  dataSource: any;
  oRequest: any;

  gridName: string = '';

  // Subjects - Pls note to unsubscribe them from your component when not needed or on ngOnDestroy life cycle hook
  recordCount: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  recordCount$: Observable<number> = this.recordCount.asObservable();

  params: any;
  currentPage: any;
  _pageContext: any;
  _sortColumnContext: any;

  rowCheckboxSelection: BehaviorSubject<string> = new BehaviorSubject<string>("SelectNone");
  _selectedRows: any[] = [];
  statusArray: any[];

  menustate: Subject<number> = new Subject<number>();
  recentDownloadSubject: Subject<number> = new Subject<number>();
  public menustate$: Observable<number> = this.menustate.asObservable();

  reloadGridSubject: Subject<boolean> = new Subject<boolean>();
  private gridLoaded = new Subject<any>();
  downloadMessageSubject: Subject<any> = new Subject<any>();
  tradeOrgMaxSize: number;

  public addToPrefernceCheckBox: Subject<any> = new Subject<any>();
  public myProfilePACheckBox: Subject<any> = new Subject<any>();
  public myProfileMCCheckBox: Subject<any> = new Subject<any>();
  public myProfileCBCheckBox: Subject<any> = new Subject<any>();
  public myProfileINCheckBox: Subject<any> = new Subject<any>();
  public myProfileODCheckBox: Subject<any> = new Subject<any>();
  dataContext: Subject<any> = new Subject<any>();

  stateList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  stateListArray: Array<any>;
  dateFormat: any;
  _countries: any;
  gridLoadedValue: any;

  private couponSummaryData = new Subject<any>();
  private isLocationObsolete = new Subject<boolean>();
  correctErrorPageHeaderData: any;
  eFileErrorData: any;
  kcFileErrorData: any;

  getDataContext(): Observable<any> {
    return this.dataContext.asObservable();
  }

  public nodeAddToPrefSelectAll: Subject<any> = new Subject<any>();
  public nodeMyProfilePASelectAll: Subject<any> = new Subject<any>();
  public nodeMyProfileMCSelectAll: Subject<any> = new Subject<any>();
  public nodeMyProfileCBSelectAll: Subject<any> = new Subject<any>();
  public nodeMyProfileINSelectAll: Subject<any> = new Subject<any>();
  public nodeMyProfileODSelectAll: Subject<any> = new Subject<any>();

  public removeSelected: Subject<any> = new Subject<any>();
  public changeGroup: Subject<any> = new Subject<any>();
  public columnMultiselectFilter: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);

  public errorCellData: any;


  setRemoveSelected(status) {
    this.removeSelected.next(status);
  }

  getRemoveSelected(): Observable<any> {
    return this.nodeMyProfilePASelectAll.asObservable();
  }

  setChangeGroup(status) {
    this.changeGroup.next(status);
  }

  getChangeGroup(): Observable<any> {
    return this.changeGroup.asObservable();
  }

  setnodeMyProfilePASelectAll(status) {
    this.nodeMyProfilePASelectAll.next(status);
  }

  getnodeMyProfilePASelectAll(): Observable<any> {
    return this.nodeMyProfilePASelectAll.asObservable();
  }

  setnodeMyProfileMCSelectAll(status) {
    this.nodeMyProfileMCSelectAll.next(status);
  }

  getnodeMyProfileMCSelectAll(): Observable<any> {
    return this.nodeMyProfileMCSelectAll.asObservable();
  }

  setnodeMyProfileCBSelectAll(status) {
    this.nodeMyProfileCBSelectAll.next(status);
  }

  getnodeMyProfileCBSelectAll(): Observable<any> {
    return this.nodeMyProfileCBSelectAll.asObservable();
  }

  setnodeMyProfileINSelectAll(status) {
    this.nodeMyProfileINSelectAll.next(status);
  }

  getnodeMyProfileINSelectAll(): Observable<any> {
    return this.nodeMyProfileINSelectAll.asObservable();
  }

  setnodeMyProfileODSelectAll(status) {
    this.nodeMyProfileODSelectAll.next(status);
  }

  getnodeMyProfileODSelectAll(): Observable<any> {
    return this.nodeMyProfileODSelectAll.asObservable();
  }

  setnodeAddToPrefSelectAll(status) {
    this.nodeAddToPrefSelectAll.next(status);
  }

  getnodeAddToPrefSelectAll(): Observable<any> {
    return this.nodeAddToPrefSelectAll.asObservable();
  }

  setCheckBoxStatusAddToPrefSelectAll(status) {
    this.addToPrefernceCheckBox.next(status);
  }

  getCheckBoxStatusAddToPrefSelectAll(): Observable<any> {
    return this.addToPrefernceCheckBox.asObservable();
  }

  setMyProfilePASelectAll(status) {
    this.myProfilePACheckBox.next(status);
  }

  getMyProfilePASelectAll(): Observable<any> {
    return this.myProfilePACheckBox.asObservable();
  }

  setMyProfileMCSelecAll(status) {
    this.myProfileMCCheckBox.next(status);
  }

  getMyProfileMCSelectAll(): Observable<any> {
    return this.myProfileMCCheckBox.asObservable();
  }

  setMyProfileCBSelecAll(status) {
    this.myProfileCBCheckBox.next(status);
  }

  getMyProfileCBSelectAll(): Observable<any> {
    return this.myProfileCBCheckBox.asObservable();
  }

  setMyProfileINSelecAll(status) {
    this.myProfileINCheckBox.next(status);
  }

  getMyProfileINSelectAll(): Observable<any> {
    return this.myProfileINCheckBox.asObservable();
  }

  setMyProfileODSelecAll(status) {
    this.myProfileODCheckBox.next(status);
  }

  getMyProfileODSelectAll(): Observable<any> {
    return this.myProfileODCheckBox.asObservable();
  }

  setDownloadMessage(value) {
    this.downloadMessageSubject.next(value);
  }

  getDownloadMessage(): Observable<any> {
    return this.downloadMessageSubject.asObservable();
  }

  setReloadGrid(value) {
    this.reloadGridSubject.next(true);
  }

  getReloadGrid(): Observable<boolean> {
    return this.reloadGridSubject.asObservable();
  }

  setGridLoaded(value) {
    this.gridLoadedValue = value;
    return this.gridLoaded.next(value);
  }

  getGridLoaded() {
    return this.gridLoaded.asObservable();
  }

  setmenustate(value) {
    this.menustate.next(value);
  }

  setRecentDownloadPageReady(value) {
    this.recentDownloadSubject.next(value);
  }
  getRecentDownloadPageReady(): Observable<any> {
    return this.recentDownloadSubject.asObservable();
  }

  getRowCheckboxSelection(): Observable<string> {
    return this.rowCheckboxSelection;
  }

  setRowCheckboxSelection(status) {
    this.rowCheckboxSelection.next(status);
  }

  getCouponSummaryData() {
    return this.couponSummaryData.asObservable();
  }

  getLocationObseleteStatus(): Observable<boolean> {
    return this.isLocationObsolete.asObservable();
  }

  setEFileErrorData(errorData, fileDetails) {
    let data = {
      "errorData": errorData,
      "fileDetails": fileDetails
    }
    this.eFileErrorData = Object.assign({}, data);
  }
  setKcFileErrorData(errorData, fileDetails) {
    let data = {
      "errorData": errorData,
      "fileDetails": fileDetails
    }
    this.kcFileErrorData = Object.assign({}, data);
  }

  getEFileErrorData() {
    return this.eFileErrorData;
  }
  getKcFileErrorData() {
    return this.kcFileErrorData;
  }

  // Use this getter/setter only when you want to different the rowModel Type by default it would be infinite
  get gridRowModelType(): string {
    return this._gridRowModelType;
  }

  set gridRowModelType(rowModelType) {
    this._gridRowModelType = rowModelType;
  }

  constructor(
    private httpService: HttpService,
    @Inject(LOCALE_ID) private locale: string,
    private _globalAppService: GlobalAppService,
    private translateService: TranslateService,
    private appService: AppService,
    private httpServiceCB: HttpChargeBackService,
    private httpServiceMC: HttpMeetCompService,
    private httpServiceIP: HttpInvoicePriceService,
    private httpServiceEA: HttpService,
    private httpServiceIPNew: HttpInvoicePriceNewService,
    private router: Router,
    private activateRoute: ActivatedRoute) {
    // Configure the page sizes here
    this.serverPageSize = 100;
    this.clientPageSize = 10;
    this.tradeOrgMaxSize = 1000;
    this.translateService.get(['calender'])
      .subscribe(translations => {
        let calenderData = translations['calender'];
        this.dateFormat = calenderData.gridDateFormat;
      });
  }

  getPageSizes(): Array<any> {
    // Configure page sizes here
    return [
      { label: '10', value: '10' },
      { label: '25', value: '25' },
      { label: '50', value: '50' },
      { label: '100', value: '100' }];
  }

  get sortColumnContext(): any {
    return this._sortColumnContext;
  }

  set sortColumnContext(sortColumnContext) {
    this._sortColumnContext = sortColumnContext;
  }

  set gridApi(gridApi: any) {
    this._gridApi = gridApi;
  }

  get gridApi(): any {
    return this._gridApi;
  }

  set allFilters(allFilters: any) {
    this._allFilters = allFilters;
  }

  get allFilters(): any {
    return this._allFilters;
  }

  set columnDefs(columnDefs: any) {
    this._columnDefs = columnDefs;
  }

  get columnDefs(): any {
    return this._columnDefs;
  }

  get recentDownloadAPICall(): boolean {
    return this._initialCall;
  }

  set recentDownloadAPICall(initialCall) {
    this._initialCall = initialCall;
  }

  getRecordCount(): Observable<number> {
    return this.recordCount$;
  }

  getGridName() {
    return this.gridName;
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  showLoadingOverlay() {
    this.gridApi.showLoadingOverlay();
  }

  showNoRowsOverlay() {
    this.gridApi.showNoRowsOverlay();
  }

  sizeColumnsToFit() {
    this.gridApi.sizeColumnsToFit();
  }

  clearFilterState() {
    this.removeFilters('all');
    this.allFilters = null;
  }

  clearSortState() {
    this.isSorted = false;
    this.gridApi.setSortModel(null);
  }

  get pageContext() {
    return this._pageContext;
  }

  set pageContext(pageContext) {
    this._pageContext = pageContext;
  }

  getDataSource() {
    let me = this;
    if (!this.dataSource) {
      this.dataSource = {
        getRows: (params) => {
          this.params = params;
          me.showLoadingOverlay();
          if (this.pageContext.pageName == 'recentDownload') {
            if (this.recentDownloadAPICall == true) {
              me.registerDataSource();
            }
            else {
              this.handleSortAndFilter();
            }
          }
          else {
            me.registerDataSource();
          }
          // me.gridApi.sizeColumnsToFit();
        },
      };
    }
    return this.dataSource;
  }

  getServerSideDatasource() {
    let me = this;
    if (!this.dataSource) {
      this.dataSource = {
        getRows: (params) => {
          this.params = params;
          if (me.isRowExpanded()) {
            me.handleRowExpansion();
          }
          else {
            // me.showLoadingOverlay();
            me.registerDataSource();
          }
        },
      };
    }
    return this.dataSource;
  }

  getServerSideTreedataSource() {
    let me = this;
    if (!this.dataSource) {
      this.dataSource = {
        getRows: (params) => {
          this.params = params;
          if (me.isRowExpanded()) {
            me.handleTreeNodeExpansion();
          }
          else {
            // me.showLoadingOverlay();
            me.registerDataSource();
          }
        },
      };
    }
    return this.dataSource;
  }


  registerDataSource() {
    this.currentPage = this.getCurrentPage();
    this.oRequest = this.generateSvcRequest();

    this.makeHttpSvcCall()
      .subscribe(
        res => {
          if ((this.gridRowModelType === 'serverSide')) {
            this.svcTreeResponseHandler(res);
          }
          else {
            /** Added timeout to load the grid rows properly after changing the filters #13346 */
            setTimeout(() => {
              this.svcResponseHandler(res);
            });
          }

          this.gridLoadedValue = true;
          setTimeout(() => {
            this.setGridLoaded(true);
            // this.makePageReady();
            // this.hideLoadingOverlay();
          })
        },
        (error: any) => {
          this.setGridLoaded(true);
          this.gridLoadedValue = true;
          this.emitTotalRecordCount(0);
        });
  }

  svcResponseHandler(res) {
    this.showLoadingOverlay();
    if (res && res.StatusCode === 401) {
      if (this.pageContext.pageName === 'ManageError') {
        localStorage.removeItem('manageErrorDownloadUrl');
        this.router.navigate(['efile'], { relativeTo: this.activateRoute.parent.parent });
        return;
      } else if (this.pageContext.pageName === 'ManageErrorKcFile') {
        localStorage.removeItem('manageErrorDownloadUrl');
        this.router.navigate(['kcfile'], { relativeTo: this.activateRoute.parent.parent });
        return;
      }
    }
    if (res) {
      let results: any = res.searchResult || res.result || res.ProductDetails || res.UserDetails || res.resulst;
      if (this.pageContext.pageName == 'couponSummaryGrid') {
        results = res;
        this.couponSummaryData.next(res);
        setTimeout(() => {
          if (window.innerWidth > configuredWidth) {
            this.sizeColumnsToFit();
          }
        });

      }
      else if (this.pageContext.pageName == 'ManageError') {
        if (res.result.eFileRecordsWithError && res.result.eFileRecordsWithError.length && res.result.errors && res.result.errors.length && res.result.eFileDetail) {
          this.correctErrorPageHeaderData = res.result.eFileDetail;
          let resObjectsMerged = res.result.eFileRecordsWithError.map((item, index) => {
            let resErrorsObj = res.result.errors.find(errorItem => {
              return item.line === errorItem.lineNumber
            });
            let mergedObj = Object.assign({}, item, resErrorsObj);
            return mergedObj;
          });
          results = resObjectsMerged;
          this.setEFileErrorData(resObjectsMerged, res.result.eFileDetail);
        }
        else if (res.result.eFileDetail) {
          this.correctErrorPageHeaderData = res.result.eFileDetail;
        }
        else {
          console.log('some error occured.');
        }
      }
      else if (this.pageContext.pageName == 'ManageErrorKcFile') {
        if (res.result.kcFileRecordsWithError && res.result.kcFileRecordsWithError.length && res.result.errors && res.result.errors.length && res.result.kcFileDetail) {
          this.correctErrorPageHeaderData = res.result.kcFileDetail;
          let resObjectsMerged = res.result.kcFileRecordsWithError.map((item, index) => {
            let resErrorsObj = res.result.errors.find(errorItem => {
              return item.line === errorItem.lineNumber
            });
            let mergedObj = Object.assign({}, item, resErrorsObj);
            return mergedObj;
          });
          results = resObjectsMerged;
          this.setKcFileErrorData(resObjectsMerged, res.result.kcFileDetail);
        }
        else if (res.result.kcFileDetail) {
          this.correctErrorPageHeaderData = res.result.kcFileDetail;
        }
        else {
          console.log('some error occured.');
        }
      }
      else if (this.pageContext.pageName == 'efileHistoryLog' || this.pageContext.pageName == 'kcfileHistoryLog') {
        results = res;
      }
      else if (this.pageContext.pageName == 'advancedInvoice' || this.pageContext.pageName == 'advancedInvoiceNew') {
        if (res[0].isObsolete) {
          this.isLocationObsolete.next(true);
        } else {
          this.isLocationObsolete.next(false);
        }
        results = res[0].categoryList.map(category => {
          let favObj = res[1].find(fav => {
            return fav.categoryCode == category.categoryCode;
          });
          if (favObj) {
            category.isFavorite = favObj.isFavorite;
          } else {
            category.isFavorite = false;
          }
          category.customerNumber = this.oRequest.customerNumber;
          return category;
        });
        res.totalRecCount = res[0].categoryList.length;
      }
      else if (this.pageContext.pageName == 'efileSearchResult' || this.pageContext.pageName == 'kcfileSearchResult') {
        setTimeout(() => {
          if (window.innerWidth > configuredWidth) {
            this.sizeColumnsToFit();
          }
        });

      }
      if (results && results.length > 0) {
        let lastRow: number = this.getLastRowIndex(this.currentPage, res.count || res.TotalRecCount || res.totalCount);
        if (this.pageContext.pageName == 'recentDownload') {
          lastRow = res.count;
        }
        else if (this.pageContext.pageName == 'couponSummaryGrid') {
          lastRow = res.length;
        }
        else if (this.pageContext.pageName == 'efileHistoryLog' || this.pageContext.pageName == 'kcfileHistoryLog') {
          lastRow = res.length;
        }
        else if (this.pageContext.pageName == 'advancedInvoice' || this.pageContext.pageName == 'advancedInvoiceNew') {
          lastRow = res[0].categoryList.length;
        }
        this.allData = results;
        this.params.successCallback(results, lastRow);
        if (this.recentDownloadAPICall == true) {
          this.recentDownloadAPICall = !this.recentDownloadAPICall;
        }
        this.makePageReady();
        if (this.pageContext.pageName == 'recentDownload') {
          this.setRecentDownloadPageReady(true);
        }
      }
      else {
        this.showNoRowsOverlay();
      }
      this.gridLoadedValue = true;
      this.emitTotalRecordCount(res.count || res.totalRecCount || res.TotalRecCount || res.totalCount || res.length);

    }
    else {
      this.showNoRowsOverlay();
    }
    this.setGridLoaded(true);
  }

  svcTreeResponseHandler(res) {
    // this.showLoadingOverlay();
    if (res) {
      let results: any;
      let count: number = 0;
      switch (this.pageContext.pageName) {
        case 'ucMappings':
          if (res && res.result) {
            results = res.result.customerMapping;
            count = res.result.customerMapping ? res.result.customerMapping.length : 0;
            this.dataContext.next(res.result.user);
          }
          break;
        case 'customerMappings':
          if (res && res.result && res.result.length && res.result[0]) {
            results = res.result[0].customerMapping;
            count = res.result[0].customerMapping ? res.result[0].customerMapping.length : 0;
          }
          break;
        case 'myProfile':
          if (res && res.result) {
            results = res.result.customerMapping;
            count = res.result.customerMapping ? res.result.customerMapping.length : 0;
            console.log("@@ Results: ", results);
            console.log("@@ count: ", count);
          }
          break;
        case 'tradeOrgLazyLoading':
          if (res && res.result) {
            results = res.result;
            count = res.result ? res.result.length : 0;
            console.log("@@ Results: ", results);
            console.log("@@ count: ", count);
          }
          break;
        default:
          results = res.result;
          count = res.count;
          break;
      }
      if (results && results.length > 0) {
        let lastRow: number = this.getLastRowIndex(this.currentPage, count);
        this.allData = results;
        let treeData: Array<any> = this.getTreeStructure([...results]);
        console.log("@ Treedata: ", treeData);
        this.treeData = treeData;
        this.params.successCallback(treeData, this.treeData.length);
        if (this.pageContext.pageName != 'tradeOrgLazyLoading') {
          this.gridApi.forEachNode((node) => {
            node.setExpanded(true);
          });
        }

        this.makePageReady();
        this.emitTotalRecordCount(res.count);
      }
      else {
        this.params.successCallback([], 0);
        this.emitTotalRecordCount(0);
        this.showNoRowsOverlay();
      }
    }
    else {
      this.params.successCallback([], 0);
      this.emitTotalRecordCount(0);
      this.showNoRowsOverlay();
    }
    this.setGridLoaded(true);
  }

  setExpanded(treeData: Array<any>) {
    if (treeData)
      treeData.forEach((item) => {
        let node: any = this.gridApi.getRowNode(item.customerNumber);
        if (node)
          node.setExpanded(true);
        // if (item && item.children && item.children.length) {
        //   // this.setExpanded(item.children);
        //   item.children.forEach(element => {
        //     let node: any = this.gridApi.getRowNode(element.customerNumber);
        //     if (node)
        //       node.setExpanded(true);
        //     // if (element && element.children && element.children.length) {
        //     //   this.setExpanded(item);
        //     // }
        //   });
        // }
      });
  }

  set treeData(value: Array<any>) {
    this._treeData = value;
  }

  get treeData(): Array<any> {
    return this._treeData;
  }

  getTreeStructure(DataToProcess) {
    console.log("@@ DataToProcess: ", DataToProcess);
    for (let ctr = 0; ctr <= DataToProcess.length; ctr++) {
      this.recursiveProcessNode(DataToProcess[ctr], ctr, DataToProcess)
    }
    let temp = DataToProcess.filter((n) => {
      return n.tree;
    });
    console.log("@@ Temo: ", temp);
    return temp.map(d => {
      d.comparerGroupKey = d[this.pageContext.referenceKeyName];
      return d;
    });
  }

  recursiveProcessNode(CURRENT_NODE, CURRENT_NODE_INDEX, ALL_DATA) {
    if (CURRENT_NODE && CURRENT_NODE[this.pageContext.groupKeyName]) {
      let PREV_NODE_INDEX = CURRENT_NODE_INDEX === 0 ? -1 : CURRENT_NODE_INDEX - 1;
      if (PREV_NODE_INDEX >= 0) {
        let PREV_NODE = ALL_DATA[PREV_NODE_INDEX];
        if (PREV_NODE[this.pageContext.referenceKeyName] === CURRENT_NODE[this.pageContext.groupKeyName]) {
          if (!PREV_NODE.children) {
            Object.assign(PREV_NODE, { 'children': [] });
          }
          PREV_NODE.children.push(CURRENT_NODE);
        }
        else {
          this.recursiveProcessNode(CURRENT_NODE, PREV_NODE_INDEX, ALL_DATA);
        }
      }
    }
    else {
      if (CURRENT_NODE && !CURRENT_NODE.hasOwnProperty('tree')) {
        Object.assign(CURRENT_NODE, { 'tree': true });
      }
    }
  }

  doesTextFilterPass(key, filter, item): boolean {
    let doesFilterPass: boolean = false;
    if (key && filter.value && filter.value !== "") {
      if (item[key]) {
        if (item[key].toLowerCase().indexOf(filter.value.toLowerCase()) < 0) {
          doesFilterPass = false;
        }
        else {
          doesFilterPass = true;
        }
      }
    }
    else {
      doesFilterPass = true;
    }

    return doesFilterPass;
  }

  doesSizeFilterPass(key, filter, item): boolean {
    let doesFilterPass: boolean = false;
    if (key && filter.value && filter.value !== "") {
      if (item[key] !== null && item[key] !== undefined) {
        let itemKey = parseInt(item[key], 10) >= 1024 ? (parseInt(item[key], 10) / 1024).toString().split('.')[0] : item[key];
        if (parseInt(itemKey, 10) !== parseInt(filter.value, 10)) {
          doesFilterPass = false;
        }
        else {
          doesFilterPass = true;
        }
      }
    }
    else {
      doesFilterPass = true;
    }

    return doesFilterPass;
  }

  doesDateFilterPass(key, filter, item) {
    let doesFilterPass: boolean = false;
    if (key && filter.value && filter.value !== "") {
      if (item[key]) {
        let dateObject = new Date(item[key]).setHours(0, 0, 0, 0);
        let filterDate = filter.value.setHours(0, 0, 0, 0);
        if (filterDate === dateObject) {
          doesFilterPass = true;
        }
      }
    }
    else {
      doesFilterPass = true;
    }
    return doesFilterPass;
  }

  doesDateRangeFilterPass(key, filter, item) {
    let doesFilterPass: boolean = false;
    if (key && filter.value && filter.value.from && filter.value.to && filter.value.from !== "" && filter.value.to !== "") {
      if (item[key]) {
        let lastChanged = new Date(item.lastChanged).setHours(0, 0, 0, 0);
        let filterFromDate = filter.value.from.setHours(0, 0, 0, 0)
        let filterToDate = filter.value.to.setHours(0, 0, 0, 0)
        if (lastChanged >= filterFromDate && lastChanged <= filterToDate) {
          doesFilterPass = true;
        }
      }
      else {
        doesFilterPass = false;;
      }
    }
    return doesFilterPass;
  }

  doesMultiSelectFilterPass(key, filter, item): boolean {
    let doesFilterPass: boolean = false;
    if (key) {
      let selectedTypes = filter.value.filter(filter => {
        return filter.selected;
      });
      if (selectedTypes && selectedTypes.length > 0) {
        doesFilterPass = selectedTypes.some(f => {
          return (
            item[key].toString().toLowerCase() === f.value.toLowerCase()
          );
        });
      }
      else {
        doesFilterPass = true;
      }
    }
    else {
      doesFilterPass = true;
    }
    return doesFilterPass;
  }

  doesRecentDownloadsMultiSelectFilterPass(key, filter, item): boolean {
    let doesFilterPass: boolean = false;
    if (key) {
      let selectedTypes = filter.value.filter(filter => {
        return filter.selected;
      });
      if (selectedTypes && selectedTypes.length > 0) {
        doesFilterPass = selectedTypes.some(f => {
          return (
            parseInt(item[key], 10) === parseInt(f.value, 10)
          );
        });
      }
      else {
        doesFilterPass = true;
      }
    }
    else {
      doesFilterPass = true;
    }
    return doesFilterPass;
  }

  handleSortAndFilter() {
    this.showLoadingOverlay();
    let dataAfterSortingAndFiltering = this.sortAndFilter(this.allData, this.params.sortModel, this.params.filterModel);
    let rowsThisPage = dataAfterSortingAndFiltering.slice(this.params.startRow, this.params.endRow);
    let lastRow = -1;
    if (dataAfterSortingAndFiltering.length <= this.params.endRow) {
      lastRow = dataAfterSortingAndFiltering.length;
    }
    // if (this.params.filterModel && Object.entries(this.params.filterModel).every(fm => fm[1]["value"] === null)) {
    //   lastRow = -1;
    //   // this.setFilterOn(false);
    // }
    if (dataAfterSortingAndFiltering && dataAfterSortingAndFiltering.length) {
      this.params.successCallback(rowsThisPage, lastRow);
      this.makePageReady();
    }
    else {
      this.showNoRowsOverlay();
    }
    this.emitTotalRecordCount(dataAfterSortingAndFiltering.length);
  }

  sortAndFilter(allOfTheData, sortModel, filterModel) {
    return this.sortData(sortModel, this.filterData(filterModel, allOfTheData));
  }

  filterData(filterModel, data) {
    let filterPresent = filterModel && Object.keys(filterModel).length > 0;
    if (!filterPresent) {
      return data;
    }
    let resultOfFilter = [];
    for (let i = 0; i < data.length; i++) {
      let item = data[i];

      let hasFilterPass: boolean = true;

      // Add your field names here if as per category
      for (let [key, filter] of Object.entries(filterModel)) {
        switch (key) {
          case 'contractNumber': // remove once all SQL API integration is done
          case 'contractId':
          case 'legacyPANumber':
          case 'contractName':
          case 'customerNumber':
          case 'customerName':
          case 'fileName':
            hasFilterPass = hasFilterPass && this.doesTextFilterPass(key, filter, item);
            break;
          case 'size':
            hasFilterPass = hasFilterPass && this.doesSizeFilterPass(key, filter, item);
            break;
          case 'startDate':
          case 'endDate':
          case 'firmEndDate':
          case 'validFrom':
          case 'validTo':
          case 'lastDownloadedDate':
          case 'availableUntil':
            hasFilterPass = hasFilterPass && this.doesDateFilterPass(key, filter, item);
            break;
          // case 'lastDownloadedDate':
          //   hasFilterPass = hasFilterPass && this.doesDateRangeFilterPass(key, filter, item);
          //   break;
          case 'status':
          case 'moduleType':
            hasFilterPass = hasFilterPass && this.doesRecentDownloadsMultiSelectFilterPass(key, filter, item);
            break;
          case 'Status':
            hasFilterPass = hasFilterPass && this.doesMultiSelectFilterPass(key, filter, item);
            break;
        }
      }

      if (!hasFilterPass)
        continue;

      resultOfFilter.push(item);
    }
    return resultOfFilter;
  }

  sortData(sortModel, data) {
    let sortPresent = sortModel && sortModel.length > 0;
    if (!sortPresent) {
      return data;
    }
    let resultOfSort = data.slice();
    resultOfSort.sort(function (a, b) {
      for (let k = 0; k < sortModel.length; k++) {
        let sortColModel = sortModel[k];
        let valueA = a[sortColModel.colId];
        let valueB = b[sortColModel.colId];
        if (valueA == valueB) {
          continue;
        }
        let sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
        if (valueA > valueB) {
          return sortDirection;
        } else {
          return sortDirection * -1;
        }
      }
      return 0;
    });
    return resultOfSort;
  }

  isRowExpanded(): boolean {
    return this.params.request && this.params.request.groupKeys && this.params.request.groupKeys.length > 0;
  }

  handleRowExpansion() {
    // Imp Note: Put only those pageNames here which have tree structure
    let request: TradeOrgEndUser = this.generateChildNodesRequest();
    switch (this.pageContext.pageName) {
      case 'tradeOrg':
      case 'tradeOrgLazyLoading': {
        // this.httpService.getTradeOrgChildResults(request).subscribe(res => {
        //   if (res) {
        //     let results: any = res.searchResult || res.result;
        //     if (results && results.length > 0) {
        //       let lastRow: number = results.length;
        //       results = results.map(row => {
        //         let newRow: any = Object.assign({}, row);
        //         newRow.groupNumber = row.endUserId;
        //         return newRow;
        //       })
        //       this.params.successCallback(results, lastRow);
        //     }
        //     else {
        //       this.params.successCallback([], 0);
        //     }
        //   }
        //   else {
        //     this.params.successCallback([], 0);
        //   }
        // });
        break;
      }
    }
  }

  handleTreeNodeExpansion() {
    if (this.pageContext.pageName == 'tradeOrgLazyLoading') {
      this.appService.setLoadingStatus(true);
    }
    let results: Array<any> = [];
    results = this.retrieveChildren(this.params.request.groupKeys, this.treeData);
    this.params.successCallback(results, results.length);
    if (this.pageContext.pageName != 'tradeOrgLazyLoading') {
      this.gridApi.forEachNode((node) => {
        node.setExpanded(true);
      });
    }
  }

  retrieveChildren(groupKeys, data): Array<any> {
    if (groupKeys.length === 0) {
      return data.map(d => {
        d.comparerGroupKey = this.params.request.groupKeys[0];
        return d;
      });
    }
    let key = groupKeys[0];
    for (let i = 0; i < data.length; i++) {
      if (data[i][this.pageContext.referenceKeyName] === key) {
        return this.retrieveChildren(
          groupKeys.slice(1),
          data[i].children.slice()
        );
      }
    }
  }

  generateChildNodesRequest(): any {
    // TK To Do: Prep request as per groupkeys
    let request: TradeOrgEndUser = new TradeOrgEndUser();
    request.pageSize = this.serverPageSize;
    // request.query = Object.assign({}, this.oRequest.query);
    request.query.searchView = this.oRequest && this.oRequest.query ? this.oRequest.query.searchView : '';
    request.query.groupNumber = this.params.request.groupKeys[0];
    request.query.customerNumber = this.oRequest && this.oRequest.query ? this.oRequest.query.customerNumber : '';
    request.query.contractNumber = this.oRequest && this.oRequest.query ? this.oRequest.query.contractNumber : '';

    return request;
  }

  getCurrentPage(): number {
    let startRow: number;
    if (this.gridRowModelType === 'serverSide')
      startRow = this.params.request ? this.params.request.startRow : 0;
    else
      startRow = this.params.startRow;

    return (((startRow / this.serverPageSize) + 1) || 1);
  }

  getRowModelType() {
    return this.rowModelType;
  }

  getServerSideRowModelType() {
    return this.serverSideRowModelType;
  }

  getRowSelection() {
    return this.rowSelection;
  }

  get clientPageSize() {
    return this._clientPageSize;
  }

  set clientPageSize(pageSize: number) {
    this._clientPageSize = pageSize;
  }

  get serverPageSize() {
    return this._serverPageSize;
  }

  set serverPageSize(pageSize: number) {
    this._serverPageSize = pageSize;
  }

  getCacheOverflowSize(): number {
    return this.cacheOverflowSize;
  }

  getMaxConcurrentDatasourceRequests(): number {
    return this.maxConcurrentDataSourceRequests;
  }

  getInfiniteInitialRowCount(): number {
    return this.infiniteInitialRowCount;
  }

  getMaxBlocksInCache(): number {
    return this.maxBlocksInCache;
  }

  getRowNodeId() {
    return function (item) {
      return item.line ? item.line : 0;
    };
  }

  getAdvancedGridRowId() {
    return function (item) {
      return item.categoryCode ? item.categoryCode : 0;
    };
  }

  getTooltipHoverClass(event) {
    return (event.type == 'mouseover' ? 'tooltip-hover tooltip-hover--open' : 'tooltip-hover');
  }

  getEndUserTooltipHoverClass(event) {
    return (event.type == 'mouseover' ? 'endUser-tooltip-hover tooltip-hover--open' : 'endUser-tooltip-hover');
  }

  getProductSearchTooltipHoverClass(event) {
    return (event.type == 'mouseover' ? 'product-search-tooltip-hover tooltip-hover--open' : 'product-search-tooltip-hover');
  }

  getMultipleLocationTooltipHoverClass(event) {
    return (event.type == 'mouseover' ? 'multiple-location-tooltip-hover tooltip-hover--open' : 'multiple-location-tooltip-hover');
  }

  get5andMoreTooltipHoverClass(event) {
    return (event.type == 'mouseover' ? 'fiveandmore-tooltip-hover tooltip-hover--open' : 'fiveandmore-tooltip-hover');
  }

  getHoldingSearchTooltipHoverClass(event) {
    return (event.type == 'mouseover' ? 'holding-search-tooltip-hover tooltip-hover--open' : 'holding-search-tooltip-hover');
  }

  getTradeTooltipHoverClass(event) {
    return (event.type == 'mouseover' ? 'trade-search-tooltip-hover tooltip-hover--open' : 'trade-search-tooltip-hover');
  }
  getDefaultColDef() {
    return { resizable: true, sortable: true };
  }

  onFilterChanged() {
    this.setReloadGrid(true);
    this.getAllFilters();
  }

  getAllFilters() {
    this.allFilters = new Array<any>();
    let filterModel: any = this.gridApi.getFilterModel();

    for (let [key, filter] of Object.entries(filterModel)) {
      if (this.pageContext.pageName === "tradeOrg" && key === "ag-Grid-AutoColumn") {
        key = "groupNumber";
      }
      let currentCd: any = this._columnDefs.find(cd => {
        let actualKey: string = key.indexOf('_') !== -1 ? key.substring(0, key.indexOf('_')) : key;
        return cd.field === key || cd.field === actualKey;
      });
      let oFilter: any = new Object();
      oFilter.header = currentCd ? currentCd.headerName : key;
      oFilter.key = key;
      switch (filter["type"]) {
        case "free-text":
          {
            oFilter.text = filter["value"];
            break;
          }
        case "multi-select":
          {
            // oFilter.text = filter["value"].filter(f => f.selected).map(f => { return f.text }).join(', ');
            oFilter.text = filter["value"].filter(f => f.selected).map(f => { return f.text });
            oFilter.type = "multi-select";
            break;
          }
        case "date-range-selector":
          {
            if (filter["value"]) {
              if (filter["value"].from && filter["value"].to)
                oFilter.text = (formatDate(filter["value"].from, this.dateFormat || this.pageContext.dateFormat, this.locale) + "-" + formatDate(filter["value"].to, this.dateFormat || this.pageContext.dateFormat, this.locale));
              else if (filter["value"].from) {
                oFilter.rangeType = "filterResult.fromLabel";
                oFilter.text = formatDate(filter["value"].from, this.dateFormat || this.pageContext.dateFormat, this.locale);
              }
              else if (filter["value"].to) {
                oFilter.rangeType = "filterResult.toLabel";
                oFilter.text = formatDate(filter["value"].to, this.dateFormat || this.pageContext.dateFormat, this.locale);
              }
              oFilter.type = "date-range-selector";
            }
            break;
          }
        case "date-selector":
          oFilter.text = filter["value"] ? formatDate(filter["value"], this.dateFormat || this.pageContext.dateFormat, this.locale) : "";
          break;
        case "date-selectorufn":
          {
            let dateFormatted: string = formatDate(filter["value"], this.dateFormat || this.pageContext.dateFormat, this.locale);
            if (dateFormatted == '12/31/9999' || dateFormatted == "31/12/9999") {
              oFilter.text = "UFN"
            } else
              oFilter.text = filter["value"] ? formatDate(filter["value"], this.dateFormat || this.pageContext.dateFormat, this.locale) : ""
            break;
          }
        case "type-ahead":
          {
            oFilter.text = filter["value"];
            oFilter.type = "type-ahead";
            break;
          }
      }
      if (oFilter.text && (!oFilter.type && oFilter.text !== '') || (oFilter.type === 'multi-select' && oFilter.text.length) ||
        (oFilter.type === 'date-range-selector' && oFilter.text) || (oFilter.type === 'type-ahead' && oFilter.text.length)) {
        this.allFilters.push(oFilter);
      }
    }
  }

  removeFilters(filterKey) {
    let filterModel = this.gridApi.getFilterModel();
    if (filterKey === 'all') {
      for (let [key, filter] of Object.entries(filterModel)) {
        if (filterModel[key])
          filterModel[key].value = null;
      }
      this.gridApi.setFilterModel(filterModel);
    }
    else {
      if (Object.keys(filterModel).indexOf(filterKey) !== -1) {
        filterModel[filterKey].value = null;
        this.gridApi.setFilterModel(filterModel);
      }
    }
  }

  setColumnDefs(colDefs: Array<any>) {
    this._columnDefs = colDefs;
  }

  mapColDefs(colDefs) {
    let me = this; let modifiedColDefs = colDefs.filter(i => { return true });
    // Add any value formatters here
    // return colDefs.map(cd => {
    //   let mappedCd = Object.assign({}, cd);
    colDefs = colDefs.filter(i => { return true; })
    modifiedColDefs.forEach(mappedCd => {
      // Add any value formatters here
      // return colDefs.map(cd => {
      // let mappedCd = Object.assign({}, cd);
      switch (mappedCd.field) {
        case 'startDate':
          mappedCd.valueFormatter = (data) => {
            if (data.colDef.headerName === "agGrid.agGridColumns.claimPeriodLabel") {
              let startDate, endDate;
              if (data && data.data) {
                startDate = this.filterInvalidDate(data.data.startDate);
                endDate = this.filterInvalidDate(data.data.endDate);
                return (startDate && endDate) ? startDate + " - " + endDate : "";
              } else {
                return "";
              }
            }
            else {
              if (data && data.value) {
                if (data.value.indexOf('9999') > -1) {
                  return "UFN";
                }
                else if (data.value.indexOf('0001') > -1) {
                  return '';
                }
                else {
                  if (data.value.length > 10)
                    return formatDate(data.value.substring(0, 10), this.dateFormat || this.pageContext.dateFormat, this.locale);
                  else
                    return data.value;
                }
              }
              else
                return "";
            }
          }
          return mappedCd;
        // break;
        case 'endDate':
        case 'firmEndDate':
        case 'lastChanged':
        case 'updatedDate':
        case 'requestedDate':
        case 'updateDate':
        case 'expiring':
        case 'customerStartDate':
        case 'customerEndDate':
        case 'lastDownloadedDate':
        case 'availableUntil':
        case 'lastUpdatedDate':
        case 'lastChangedDate':
        case 'validFrom':
        case 'validTo':
        case 'lastDownload':
        case 'passwordExpiryDate':
        case 'lastLoginDate':
        case 'passwordExpiryDate':
        case 'receivedDate':
        case 'availableDate':
        case 'issueDate':
        case 'submittedDate':
        case 'invoiceDate':
        case 'uploaded':
        case 'submitted':
        case 'updated':
        case 'lastRefreshDate':
        case 'nextAvailableDate':
          mappedCd.valueFormatter = (data) => {
            if (data && data.value) {
              if (data.value.indexOf('9999') > -1) {
                return "UFN";
              }
              else if (data.value.indexOf('0001') > -1) {
                if (this.pageContext.pageName == 'invoiceSearchResult') {
                  return '-';
                }
                else {
                  return '';
                }
              }
              else {
                if (data.value.length > 10) {
                  return formatDate(data.value.substring(0, 10), this.dateFormat || this.pageContext.dateFormat, this.locale);
                }
                else {
                  return data.value;
                }
              }

            }
            else {
              if (this.pageContext && (this.pageContext.pageName == 'advancedInvoice' || this.pageContext.pageName == 'advancedInvoiceNew')) {
                return "--"
              } else {
                return "";
              }
            }
          }
          return mappedCd;
        case 'lastLoginDate__1':
          mappedCd.valueFormatter = (data) => {
            if (data && data.data && data.data.lastLoginDate) {
              if (data.data.lastLoginDate.length > 18)
                return data.data.lastLoginDate.substring(11, 19);
              else
                return "";
            }
            else
              return "";
          }
          return mappedCd;
        case 'address':
          mappedCd.valueFormatter = (data) => {
            if (data.colDef.headerName === "priceagreement.agGridColumns.euFullAddress") {
              return data.value.address;
            }
            else
              return data.value;

          }
          return mappedCd;
        case 'status':
          mappedCd.valueFormatter = (data) => {
            if (data.colDef.headerName === "priceagreement.agGridColumns.euStatusLabel") {
              return (data && data.data && data.data.status ? data.data.status : '');
            }
            else if (data.colDef.headerName === "")
              return '';
            else
              return (data && data.data ? data.data.status : '');
          }
          return mappedCd;
        case 'comments':
          mappedCd.valueFormatter = (data) => {
            if (data && data.data && data.data.comments) {
              if (data.colDef.headerName !== "priceagreement.agGridColumns.euComments") {
                if (Number.isInteger(+data.data.comments))
                  return this.mapComments(data.data.comments, kcpUpdates)
                else
                  return (data.data.comments != null ? data.data.comments : '');
              }
              else {
                return (data.data.comments != null ? data.data.comments : '');
              }
            }
            else
              return '';
          }
          return mappedCd;
        case 'comment':
          mappedCd.valueFormatter = (data) => {
            if (data && data.data && data.data.comment) {
              if (data.colDef.headerName !== "priceagreement.agGridColumns.soldToComment") {
                if (Number.isInteger(+data.data.comment))
                  return this.mapComments(data.data.comment, customerSoldToComments)
                else
                  return (data.data.comment != null ? data.data.comment : '');
              }
              else {
                return (data.data.comment != null ? data.data.comment : '');
              }
            }
            else
              return '';
          }
          return mappedCd;
        case 'changeReason':
          mappedCd.valueFormatter = (data) => {
            if (data && data.data && data.data.changeReason && !data.colDef.feature) {
              if (data && data.data && data.data.trackingType && data.data.updatedLineItemNumber) {
                if (data.data.trackingType.toLowerCase() == "product") {
                  return (data.data.changeReason + " - " + data.data.updatedLineItemNumber);
                }
                else if (data.data.trackingType.toLowerCase() == "enduser") {
                  return (data.data.changeReason + " - " + data.data.updatedLineItemNumber);
                }
                else if (data.data.trackingType.toLowerCase() == "customer") {
                  return (data.data.changeReason + " - " + data.data.updatedLineItemNumber);
                }
                else {
                  return (this.mapComments(data.data.changeReason, customerComments) + " - " + data.data.updatedLineItemNumber);
                }
              }
            } else if (data.colDef.feature && data.colDef.feature == 'emeaNotifications') {
              return (data.data.changeReason);
            }
            else {
              return '';
            }
          }
          return mappedCd;

        //azure team will directly send the data in api now
        // case 'customerComments':
        //   mappedCd.valueFormatter = (data) => {
        //     if (data && data.data && data.data.customerComments) {
        //       let fieldValue = data.data.customerComments;
        //       let fieldValueArray = fieldValue.split('_');
        //       let changeType = fieldValueArray[0];
        //       let code = fieldValueArray[1];
        //       switch (changeType) {
        //         case 'Customer':
        //           return this.mapComments(code, customerComments);
        //         case 'EndUser':
        //           return this.mapComments(code, endUserComments);
        //         case 'Product':
        //           return this.mapComments(code, kcpUpdates);
        //         case 'Multiple':
        //           return 'Multiple Changes Done'
        //         default:
        //           return ''
        //       }
        //     }
        //     else
        //       return '';
        //   }
        //   return mappedCd;
        case 'pricePerCaseForREandFD':
          mappedCd.valueFormatter = (data) => {
            return '%'
          }
          return mappedCd;
        case 'itemsPerUOM':
          mappedCd.valueFormatter = (data) => {
            if (this.pageContext.pageName !== 'mcProductsDetailsSection') {
              let fieldValue = data && data.data ? data.data.itemsPerUOM : '';
              if (fieldValue && fieldValue.indexOf(' ') !== -1) {
                return fieldValue.substring(0, fieldValue.indexOf(' '));
              }
              else {
                return '';
              }
            }
          }
          return mappedCd;
        case 'price':
          mappedCd.valueFormatter = (data) => {
            if (data.colDef.headerName === "priceagreement.agGridColumns.FDRate") {
              if (data && data.value) {
                return data.value + " %";
              }
            }
          }
          return mappedCd;
        // case 'twelveMonthVolume':
        //   mappedCd.valueFormatter = (data) => {
        //     if (data) {
        //       if (data.value) {
        //         let n = new Number(data.value);
        //         return n.toLocaleString("en-US", {
        //           minimumFractionDigits: 2,
        //           maximumFractionDigits: 2
        //         });
        //       }
        //       else {
        //         return '0';
        //       }
        //     }
        //   }
        //   return mappedCd;
        case 'size':
          mappedCd.valueFormatter = (data) => {
            if (data && data.value) {
              let fieldValue = data.value / 1024;
              let fieldValueArray = fieldValue.toString().split('.');
              if (data.value >= 1024) {
                return fieldValueArray[0] + " KB";
              }
              else {
                return data.value + " Bytes";
              }
            }
          }
          return mappedCd;
        case 'endUserNumber':
          mappedCd.valueFormatter = (data) => {
            if (data.colDef.headerName === "priceagreement.agGridColumns.EuRequestsNumber") {
              if (data && data.data) {
                if (data.data.status === 'COM')
                  return data.data.endUserNumber;
                else
                  return '';
              }
              else
                return '';
            }
            else
              return data.value;
          }
          return mappedCd;
        case 'claimStartDate':
          mappedCd.valueFormatter = (data) => {
            if (data.colDef.headerName === "agGrid.agGridColumns.claimPeriodLabel") {
              let startDate, endDate;
              if (data && data.data) {
                startDate = this.filterInvalidDate(data.data.claimStartDate);
                endDate = this.filterInvalidDate(data.data.claimEndDate);
                return (startDate && endDate) ? startDate + " - " + endDate : "";
              } else {
                return "";
              }

            }
          }
          return mappedCd;
        case 'errors':
        case 'netIssued':
          mappedCd.valueFormatter = (data) => {
            if (this.pageContext.pageName != 'efileHistoryLog' || this.pageContext.pageName != 'kcfileHistoryLog') {
              if (data && (data.value || data.value == 0) && data.value != null && data.value !== "") {
                let formattedValue = parseFloat(data.value).toLocaleString("en-US", {
                  minimumFractionDigits: 2
                });
                if (formattedValue.indexOf('-') > -1) {
                  let returnValue = '(' + formattedValue.substring(1) + ')';
                  return returnValue;
                }
                else
                  return formattedValue;
              }
            }
          }
          return mappedCd;
        case '':
          return mappedCd;
        default:
          return mappedCd;
      }


    })

    return modifiedColDefs;
  }

  filterInvalidDate(date) {
    if (date.indexOf('9999') > -1) {
      return "UFN";
    } else if (date.indexOf('0001') > -1) {
      return '';
    } else {
      return date.length > 10 ? formatDate(date.substring(0, 10), this.dateFormat || this.pageContext.dateFormat, this.locale) : date;
    }
  }
  mapComments(commentCode, referenceList: Array<any>): string {
    let comments = referenceList.filter(ku => {
      return parseInt(ku.changeReason, 10) === parseInt(commentCode, 10)
    });

    if (comments && comments.length) {
      return comments[0].value;
    }
    else {
      return '';
    }
  }

  mapEuStatuses(status): string {
    switch (status) {
      case 'NEW':
        return 'New';
      case 'PEN':
        return 'Pending';
      case 'DEN':
        return 'Denied';
      case 'COM':
        return 'Complete';
      case 'Submitted':
        return 'Submitted';
      default:
        return ''
    }
  }

  onColumnResized(event) {
    this.gridApi.resetRowHeights();
  }

  generateSvcRequest(): any {
    let oRequest = Object.assign({}, this.pageContext.request);
    switch (this.pageContext.pageName) {
      case 'ucMappings':
      case 'customerMappings':
        break;
      case 'couponSummaryGrid':
        oRequest = this.pageContext.request
        break;
      case 'tradeOrgLazyLoading':
        oRequest.pageSize = this.tradeOrgMaxSize;
        break;
      // case 'ManageError':
      case 'efileHistoryLog':
        oRequest = this.pageContext.request;
        break;
      case 'advancedInvoice':
      case 'advancedInvoiceNew':
        oRequest = this.pageContext.request;
        break;
      case 'kcfileHistoryLog':
        oRequest = this.pageContext.request;
        break;
      default:
        oRequest.pageSize = this.serverPageSize;
        if (this.pageContext.pageName === 'tradeOrgLazyLoading')
          oRequest.pageSize = this.tradeOrgMaxSize;
        oRequest.currentPage = this.getCurrentPage();
        break;
    }

    let tileType: number = this.getTileType();
    if (tileType) {
      if (!oRequest.query)
        oRequest.query = {};
      oRequest.query.dashboardTilesType = tileType;
    }

    if (oRequest.filter)
      oRequest.filter = this.getFilterRequestParams(oRequest.filter);

    if (this.getSortRequestParams().length > 0)
      oRequest.orderBy = this.getSortRequestParams();

    // if (this.pageContext.pageName === 'padetailproduct') {
    //   if (!(oRequest.orderBy && oRequest.orderBy.length > 0 && !!oRequest.orderBy[0] && oRequest.orderBy[0] !== '')) {
    //     oRequest.orderBy = ['lastChanged desc'];
    //   }
    // }

    // if (this.pageContext.pageName === 'downloadCustListModal') {
    //   oRequest.filter = this.pageContext.request.filter;
    // }

    this.oRequest = oRequest;
    return this.oRequest;
  }

  getTileType(): number {
    let tileType: number;

    switch (this.pageContext.pageName) {
      case 'activeFuture':
        tileType = 1;
        break;
      case 'recentlyChanged':
        tileType = 2;
        break;
      case 'expiringSoon':
        tileType = 3;
        break;
      case 'recentlyExpired':
        tileType = 4
        break;
      case 'paFavourite':
        tileType = 5;
        break;
    }
    return tileType;
  }

  getFilterRequestParams(oFilter): any {
    let filterParams: any = this.getFilterRequestObject();

    let filterModel: any = this.getFilterModel();

    if (filterModel) {
      for (let [key, filter] of Object.entries(filterModel)) {
        if (filter && filter["value"]) {
          key = key.indexOf('_') !== -1 ? key.substring(0, key.indexOf('_')) : key;
          console.log("key : ", key)
          switch (key) {
            case 'contractNumber': // remove once all SQL API integration is done
            case 'contractId':
              if (this.pageContext.pageName === "endUserRequests" || this.pageContext.pageName === "myNotifications" || this.pageContext.pageName === "downloadCustListModal") {
                filterParams.contractNumber = filter["value"];
              }
              else {
                filterParams.contractIds = [];
                filterParams.contractIds.push(filter["value"]);
              }
              break;
            case 'customerId':
                filterParams.customerIds = [];
                filterParams.customerIds.push(filter["value"]);              
              break;
            case 'legacyPANumber':
              if (this.pageContext.pageName === "endUserRequests" || this.pageContext.pageName === "myNotifications") {
                filterParams.legacyPANumber = filter["value"];
              }
              else {
                filterParams.legacyPANumber = [];
                filterParams.legacyPANumber.push(filter["value"]);
              }
              break;
            case 'contractName':
              filterParams.contractName = filter["value"];
              break;
            case 'eFileID':
              filterParams.eFileID = filter["value"];
              break;
            case 'eFileName':
              filterParams.eFileName = filter["value"];
              break;
            case 'meetCompNumber':
              filterParams.meetCompNumber = [];
              filterParams.meetCompNumber.push(filter["value"]);
              break;
            case 'salesTerritoryNumber':
              filterParams.salesTerritoryNumber = [];
              filterParams.salesTerritoryNumber.push(filter["value"]);
              break;
            case 'uploadedBy':
              filterParams.uploadedBy = filter["value"];
              break;
            case 'meetCompName':
              filterParams.meetCompName = filter["value"];
              break;
            case 'salesTerritoryName':
              filterParams.salesTerritoryName = filter["value"];
              break;
            case 'customerNumber':
              if (this.pageContext.pageName === "endUserRequests" || this.pageContext.pageName === "myNotifications" || this.pageContext.pageName === "padetailsSoldTo" || this.pageContext.pageName === "custListModal" || this.pageContext.pageName === "customerSearchModal" || this.pageContext.pageName === 'ucMappingCustomerSearch' || this.pageContext.pageName === 'downloadCustListModal' || this.pageContext.pageName === "mcCustomerDetails" || this.pageContext.pageName === "invoiceSearchResult" || this.pageContext.pageName === "invoiceFavourite" || this.pageContext.pageName === "recentRequests" || this.pageContext.pageName === "unclaimedTileGrid") {
                filterParams.customerNumber = filter["value"];
              }
              else if (this.pageContext.pageName === "recentActivity" || this.pageContext.pageName === "pendingTileGrid" || this.pageContext.pageName === "issuedTileGrid" || this.pageContext.pageName === "deniedTileGrid" || this.pageContext.pageName === "searchResultsCB" || this.pageContext.pageName === "mcSearchResult" || this.pageContext.pageName === "efileSearchResult" || this.pageContext.pageName === "kcfileSearchResult") {
                filterParams.customerNumber = [];
                filterParams.customerNumber.push(filter["value"]);
              }
              else if (this.pageContext.pageName === "mcCustomerDetailsSection") {
                filterParams.customerNumberCBFilter = [];
                filterParams.customerNumberCBFilter = filter["value"];
              }
              else if (this.pageContext.pageName === "efileAccessPage") {
                filterParams.customerNumber = "";
                filterParams.customerNumber = filter["value"];
              }
              else {
                filterParams.customerIds = [];
                filterParams.customerIds.push(filter["value"]);
              }
              break;
            case 'customerName':
            case 'name1':
              if (this.pageContext.pageName === "custListModal")
                filterParams.name = filter["value"];
              else {
                if (this.pageContext.pageName === "efileSearchResult" || this.pageContext.pageName === "kcfileSearchResult") {
                  filterParams.customerName = [];
                  filterParams.customerName.push(filter["value"]);
                }
                else {
                  filterParams.customerName = filter["value"];
                }
              }

              break;
            case 'territoryNumber':
              filterParams.territoryIds = [];
              filterParams.territoryIds.push(filter["value"]);
              break;
            case 'territoryName':
              filterParams.territoryName = filter["value"];
              break;
            case 'productDescription':
            case 'ProductDescription':
            case 'productDesc':
              if (this.pageContext.pageName === "prodSearchModal")
                filterParams.productDescription = filter["value"];
              else
                filterParams.description = filter["value"];
              break;
            case 'AltProductCode':
            case 'altProductCode':
              if (this.pageContext.pageName === "prodSearchModal")
                filterParams.altProductCode = filter["value"];
              else
                filterParams.alternateProductCode = filter["value"];
              break;
            case 'customerStartDate':
            case 'startDate':
            case 'validFrom':
              if (this.pageContext.pageName === "tradeOrgLazyLoading" || this.pageContext.pageName === "padetailendusers" ||
                this.pageContext.pageName === "padetailproduct" || this.pageContext.pageName === "padetailsSoldTo" || this.pageContext.pageName === "mcProductsDetailsSection") {
                filterParams.startDate = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              }
              else if (this.pageContext.pageName === "mcSearchResult") {
                filterParams.active.fromDate = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              }
              else {
                if (!filterParams.validDate) {
                  filterParams.validDate = {};
                }
                let region = this._globalAppService.getDefaultRegionString()
                if (region == 'EMEA') {
                  filterParams.firmValidDate.fromDate = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
                } else {
                  filterParams.validDate.fromDate = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
                }
              }
              break;
            case 'customerEndDate':
            case 'endDate':
            case 'validTo':
              if (this.pageContext.pageName === "tradeOrgLazyLoading" || this.pageContext.pageName === "padetailendusers" ||
                this.pageContext.pageName === "padetailproduct" || this.pageContext.pageName === "padetailsSoldTo" || this.pageContext.pageName === "mcProductsDetailsSection") {
                filterParams.endDate = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              }
              else if (this.pageContext.pageName === "mcSearchResult") {
                filterParams.active.toDate = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              }
              else {
                if (!filterParams.validDate) {
                  filterParams.validDate = {};
                }
                filterParams.validDate.toDate = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              }
              break;
            //below field is for EMEA
            case 'firmEndDate':
              if (this.pageContext.pageName === 'padetailproduct' || this.pageContext.pageName === 'padetailendusers') {
                filterParams.firmEndDate = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              } else {
                if (!filterParams.firmValidDate) {
                  filterParams.firmValidDate = {};
                }
                filterParams.firmValidDate.toDate = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              }
              break;
            case 'lastDownload':
              filterParams.lastDownloaded = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              break;
            case 'lastChanged':
            case 'lastChangedDate':
            case 'lastUpdatedDate':
              if (this.pageContext.pageName == "padetailproduct" || this.pageContext.pageName == "padetailendusers" || this.pageContext.pageName == 'mcProductsDetailsSection') {
                filterParams.lastChangedFrom = filter["value"].from ? formatDate(filter["value"].from, 'MM/dd/yyyy', this.locale) : "";
                filterParams.lastChangedTo = filter["value"].to ? formatDate(filter["value"].to, 'MM/dd/yyyy', this.locale) : "";
              }
              else if (this.pageContext.pageName == "efileSearchResult" || this.pageContext.pageName == "kcfileSearchResult") {
                filterParams.lastChanged = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              }
              else if (this.pageContext.pageName == "myNotifications") {
                filterParams.lastChanged.fromDate = filter["value"] ? formatDate(filter["value"], 'MM/dd/yyyy', this.locale) : "";
              }
              else {
                if (!filterParams.lastChanged) {
                  filterParams.lastChanged = {};
                }
                filterParams.lastChanged.fromDate = filter["value"].from ? formatDate(filter["value"].from, 'MM/dd/yyyy', this.locale) : "";
                filterParams.lastChanged.toDate = filter["value"].to ? formatDate(filter["value"].to, 'MM/dd/yyyy', this.locale) : "";
              }
              break;
            case 'submitted':
              filterParams.submitted = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              break;
            case 'typedescription':
            case 'typeName':
              filterParams.contractType = [];
              filter["value"].filter(f => { return f.selected }).forEach(item => {
                filterParams.contractType.push(item.value);
              });
              break;
            case 'type':
              if (this.pageContext.pageName === 'efileSearchResult' || this.pageContext.pageName === 'kcfileSearchResult') {
                filterParams.type = [];
                filter["value"].filter(f => { return f.selected }).forEach(item => {
                  filterParams.type.push(item.value);
                });
              }
              else {
                filterParams.contractType = [];
                filter["value"].filter(f => { return f.selected }).forEach(item => {
                  filterParams.contractType.push(item.value);
                });
              }
              break;
            case 'salesorgName':
              filterParams.SalesorgName = [];
              filter["value"].filter(f => { return f.selected }).forEach(item => {
                filterParams.SalesorgName.push(item.value);
              });
              break;
            /* TODO pass the Key when avaiable from Dinakaran*/
            case 'kcOwner':
              filterParams.kcOwner = [];
              filter["value"].filter(f => { return f.selected }).forEach(item => {
                filterParams.kcOwner.push(item.value);
              });
              break;
            case 'customerType':
              filterParams.customerType = [];
              filter["value"].filter(f => { return f.selected }).forEach(item => {
                filterParams.customerType.push(item.value);
              });
              break;
            case 'statusText':
            case 'status':
            case 'status_Efile':
              filterParams.status = [];
              let filteredStatuses = filter["value"].filter(f => { return f.selected });
              filteredStatuses.forEach(item => {
                filterParams.status.push(item.value);
              });
              if (filter["value"].length === filteredStatuses.length)
                filterParams.status = [];
              break;
            // case 'lastDownload':
            //   filterParams.lastDownload = filter["value"];
            //   break;
            case 'change':
              filterParams.change = [];
              filter["value"].filter(f => { return f.selected }).forEach(item => {
                filterParams.change.push(item.value);
              });
              break;
            case 'moduleType':
              filterParams.moduleType = [];
              filter["value"].filter(f => { return f.selected }).forEach(item => {
                filterParams.moduleType.push(item.value);
              });
              break;
            case 'lastUpdated':
            case 'lastChangedDate':
              if (!filterParams.lastUpdated) {
                filterParams.lastUpdated = {};
              }
              filterParams.lastUpdated.fromDate = filter["value"].from ? formatDate(filter["value"].from, 'MM/dd/yyyy', this.locale) : "";
              filterParams.lastUpdated.toDate = filter["value"].to ? formatDate(filter["value"].to, 'MM/dd/yyyy', this.locale) : "";
              break;
            case 'expiring':
              if (!filterParams.expiring) {
                filterParams.expiring = {};
              }
              filterParams.expiring.fromDate = filter["value"].from ? formatDate(filter["value"].from, 'MM/dd/yyyy', this.locale) : "";
              filterParams.expiring.toDate = filter["value"].to ? formatDate(filter["value"].to, 'MM/dd/yyyy', this.locale) : "";
              break;
            case 'endUserId':
            case 'ag-Grid-AutoColumn':
              filterParams.endUserId = filter["value"];
              break;
            case 'ownerName':
              filterParams.filterOwnerName = filter["value"];
              break;
            case 'shortProductCode':
              if (this.pageContext.pageName === "prodSearchModal")
                filterParams.shortProductCode = filter["value"];
              if (this.pageContext.pageName == 'mcProductsDetailsSection')
                filterParams.productCode = (filter["value"]).join(",");
              else
                filterParams.productCode = filter["value"];
              break;
            case 'itemsPerCase':
              filterParams.itemsPerCase = filter["value"];
              break;
            case 'altProd':
              filterParams.altProd = [];
              filterParams.altProd.push(filter["value"]);
              break;
            case 'uom':
              // filterParams.uom = [];
              // filter["value"].filter(f => { return f.selected }).forEach(item => {
              //   filterParams.uom.push(item.value);
              // });
              filterParams.uom = filter["value"];
              break;
            case 'euPrice':
              filterParams.euPrice = filter["value"];
              break;
            case 'por':
              filterParams.por = filter["value"];
              break;
            case 'volume':
              filterParams.volume = filter["value"];
              break;
            case 'ItemsPerUOM':
            case 'itemsPerUom':
            case 'itemsPerUOM':
              filterParams.itemsPerUom = filter["value"];
              break;
            case 'caseBreakDownUom':
              filterParams.caseBreakDownUom = filter["value"];
              break;
            case 'caseBreakDown':
              filterParams.caseBreakDown = filter["value"];
              break;
            case 'price':
              filterParams.price = this.reverseFormatter(filter["value"]);
              break;
            case 'CommentsDescription':
            case 'comments':
            case 'CommentsDescription':
            case 'comment':
              if (this.pageContext.pageName === 'padetailproduct' || this.pageContext.pageName === 'padetailsSoldTo' ||
                this.pageContext.pageName === 'padetailendusers') {
                filterParams.comments = [];
                let filteredComments = filter["value"].filter(f => { return f.selected });
                filteredComments.forEach(item => {
                  filterParams.comments.push(item.value);
                });
                if (filter["value"].length === filteredComments.length)
                  filterParams.comments = [];
              } else if (this.pageContext.pageName === 'mcProductsDetailsSection') {
                filterParams.comments = [];
                // filterParams.comments.push(filter["value"])
                let filteredComments = filter["value"].filter(f => { return f.selected });
                filteredComments.forEach(item => {
                  filterParams.comments.push(item.value);
                });
              }
              else {
                filterParams.comments = filter["value"];
              }
              break;
            case 'priceChargeback':
              filterParams.priceChargeback = filter["value"];
              break;
            case 'pricePerCase':
              filterParams.pricePerCase = filter["value"];
              break;
            case 'caseBreakdownUOM':
              filterParams.caseBreakdownUOM = filter["value"];
              break;
            case 'casePrice':
              filterParams.casePrice = filter["value"];
              break;
            case 'twelveMonthSales':
              filterParams.twelveMonthSales = filter["value"];
              break;
            case 'twelveMonthVolume':
              filterParams.twelveMonthVolume = filter["value"];
              break;
            case 'endUserNumber':
              filterParams.endUserNumber = filter["value"];
              break;
            case 'fullName':
              if (this.pageContext.pageName === 'tradeOrgLazyLoading')
                filterParams.name = filter["value"];
              else
                filterParams.fullName = filter["value"];
              break;
            case 'addressLine1':
            case 'address':
              filterParams.address = filter["value"];
              break;
            case 'euNumber':
              filterParams.euNumber = filter["value"];
              break;
            case 'companyName':
              filterParams.companyName = filter["value"];
              break;
            case 'estimatedUnitCount':
              filterParams.estimatedUnitCount = filter["value"];
              break;
            case 'requestedDate':
              if (this.pageContext.pageName == 'recentRequests') {
                let formattedDate = formatDate(filter["value"], 'yyyy-MM-dd', this.locale);
                formattedDate = formattedDate + "T00:00:00.000Z";
                filterParams.requestedDateFrom = formattedDate;
                filterParams.requestedDateTo = formattedDate;
              }
              else {
                let formattedDate = formatDate(filter["value"], 'yyyy-MM-dd', this.locale);
                formattedDate = formattedDate + "T00:00:00.000Z";
                filterParams.requestedDate = formattedDate;
              }
              break;
            case 'updatedDate':
              if (filter["type"] === 'date-range-selector') {
                filterParams.updatedDate = filter["value"].from ? formatDate(filter["value"].from, 'MM/dd/yyyy', this.locale) : '';
                // filterParams.updatedDate = filter["value"].from ? formatDate(filter["value"].from, 'MM/dd/yyyy', this.locale) : '';
              }
              else if (filter['type'] === 'date-selector' || filter['type'] === 'date-selectorufn') {
                filterParams.updatedDate = filter["value"] ? formatDate(filter["value"], 'MM/dd/yyyy', this.locale) : '';
              }
              else
                filterParams.updatedDate = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              break;
            case 'lastRefresh':
              filterParams.lastRefresh = formatDate(filter["value"], 'yyyy-MM-dd', this.locale);
              break;
            case 'lastRefreshDate':
              filterParams.lastRefresh = formatDate(filter["value"], 'yyyy-MM-dd', this.locale);
              break;
            case 'updateDate':
              if (filter["type"] === 'date-range-selector') {
                filterParams.updateDate = filter["value"].from ? formatDate(filter["value"].from, 'MM/dd/yyyy', this.locale) : '';
                // filterParams.updatedDate = filter["value"].from ? formatDate(filter["value"].from, 'MM/dd/yyyy', this.locale) : '';
              }
              else
                filterParams.updateDate = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              break;
            case 'changeReason':
              filterParams.changeReason = filter["value"];
              break;
            case 'endUserName':
              filterParams.endUserName = filter["value"];
              break;
            case 'taxNumber':
              filterParams.taxNumber = filter["value"];
              break;
            case 'city':
              filterParams.city = filter["value"];
              break;
            case 'state':
              if (this.pageContext.pageName === 'mcCustomerDetails' || this.pageContext.pageName === 'mcCustomerDetailsSection') {
                filterParams.state = [];
                let filteredItems = filter["value"].filter(f => { return f.selected });
                filteredItems.forEach(item => {
                  filterParams.state.push(item.value);
                });
              } else {
                filterParams.state = filter["value"];
              }
              break;
            case 'country':
              if (this.pageContext.pageName === 'ucMappingCustomerSearch' || this.pageContext.pageName === 'customerSearchModal' || this.pageContext.pageName === 'endUserSearchModal' || this.pageContext.pageName === 'tradeHQModal' || this.pageContext.pageName === 'invoiceFavourite' || this.pageContext.pageName === 'invoiceSearchResult' || this.pageContext.pageName === 'unclaimedTileGrid' || this.pageContext.pageName === 'recentRequests') {
                filterParams.country = [];
                let filteredItems = filter["value"].filter(f => { return f.selected });
                filteredItems.forEach(item => {
                  filterParams.country.push(item.value);
                });
              }
              break;
            case 'phone':
              filterParams.phone = filter["value"];
              break;
            case 'postalCode':
              filterParams.postalCode = filter["value"];
              break;
            case 'zipCode':
              filterParams.zipCode = filter["value"];
              break;
            case 'zipcode':
              filterParams.zipcode = filter["value"];
              break;
            case 'tax':
              filterParams.tax = filter["value"];
              break;
            case 'customerType':
              if (this.pageContext.pageName === 'ucMappingCustomerSearch') {
                filterParams.customerType = [];
                let filteredItems = filter["value"].filter(f => { return f.selected });
                filteredItems.forEach(item => {
                  filterParams.customerType.push(item.value);
                });
              }
              else
                filterParams.customerType = filter["value"];
              break;
            case 'tradeOrgNumber':
              filterParams.tradeOrgNumber = filter["value"];
              break;
            case 'tradeOrgName':
              filterParams.tradeOrgName = filter["value"];
              break;
            case 'categoryDescription':
              if (this.pageContext.pageName === "prodSearchModal")
                filterParams.categoryDescription = filter["value"];
              break;
            case 'userId':
              filterParams.userId = filter["value"];
              break;
            case 'firstName':
              filterParams.firstName = filter["value"];
              break;
            case 'lastName':
              filterParams.lastName = filter["value"];
              break;
            case 'email':
              filterParams.email = filter["value"];
              break;
            case 'passwordExpiryDate':
              filterParams.passwordExpiryDate = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              break;
            case 'lastLoginDate':
              filterParams.lastLoginDate = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              break;
            case 'loginCount':
              filterParams.loginCount = this.reverseFormatter(filter["value"]);
              break;
            case 'committedVolume':
              filterParams.committedVolume = this.reverseFormatter(filter["value"]);
              break;
            case 'couponNumber':
              filterParams.couponNumber = filter["value"];
              break;
            case 'referenceNumber':
              filterParams.referenceNumber = filter["value"];
              break;
            case 'claimPeriod':
            case 'claimStartDate':
              if (filter["type"] === 'date-range-selector') {
                filterParams.claimPeriod.fromDate = filter["value"].from ? formatDate(filter["value"].from, 'MM/dd/yyyy', this.locale) : '';
                filterParams.claimPeriod.toDate = filter["value"].to ? formatDate(filter["value"].to, 'MM/dd/yyyy', this.locale) : '';
              }
              else if (filter['type'] === 'date-selector' || filter['type'] === 'date-selectorufn') {
                filterParams.claimPeriod = filter["value"] ? formatDate(filter["value"], 'MM/dd/yyyy', this.locale) : '';
              }
              else
                filterParams.claimPeriod = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              break;
            case 'issueDate':
            case 'issuePeriod':
              if (filter["type"] === 'date-range-selector') {
                console.log("issuePeriod", filter["value"])
                filterParams.issuePeriod.fromDate = filter["value"].from ? formatDate(filter["value"].from, 'MM/dd/yyyy', this.locale) : '';
                filterParams.issuePeriod.toDate = filter["value"].to ? formatDate(filter["value"].to, 'MM/dd/yyyy', this.locale) : '';
              }
              else if (filter['type'] === 'date-selector' || filter['type'] === 'date-selectorufn') {
                filterParams.issuePeriod.fromDate = filter["value"] ? formatDate(filter["value"], 'MM/dd/yyyy', this.locale) : '';
              }
              else
                filterParams.issuePeriod = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              break;

            case 'submittedDate':
              filterParams.submittedPeriod.fromDate = filter["value"] ? formatDate(filter["value"], 'MM/dd/yyyy', this.locale) : '';
              break;
            case 'receivedDate':
              filterParams.received = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              break;
            case 'availableDate':
              filterParams.available = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              break;
            case 'uploaded':
              filterParams.uploaded = formatDate(filter["value"], 'MM/dd/yyyy', this.locale);
              break;
            case 'claimRequestType':
              filterParams.requestType = [];
              filter["value"].filter(f => { return f.selected }).forEach(item => {
                filterParams.requestType.push(item.value);
              });
              break;
            case 'rvRb':
              filterParams.reverseBillBack = [];
              filter["value"].filter(f => { return f.selected }).forEach(item => {
                filterParams.reverseBillBack.push(item.value);
              });
              break;
            case 'claimPeriodMonthAndYear':
              filterParams.claimPeriodMonthAndYear = [];
              filter["value"].filter(f => { return f.selected }).forEach(item => {
                filterParams.claimPeriodMonthAndYear.push(item.value);
              });
              break;
            case 'currency':
              filterParams.currency = [];
              filter["value"].filter(f => { return f.selected }).forEach(item => {
                filterParams.currency.push(item.value);
              });
              break;
            case 'custEU':
              filterParams.custEU = filter["value"];
              break;
            case 'custEuDetails':
              filterParams.custEuDetails = filter["value"];
              break;
            case 'kCEU':
              filterParams.kCEU = filter["value"];
              break;
            case 'kCEUDetails':
              filterParams.kCEUDetails = filter["value"];
              break;
            case 'requested':
              filterParams.requested = filter["value"];
              break;
            case 'ActiveLines':
              filterParams.ActiveLines = parseInt(filter["value"], 10);
              break;
            case 'issued':
              filterParams.issued = filter["value"];
              break;
            case 'discrepancies':
              filterParams.discrepancies = [];
              filter["value"].filter(f => { return f.selected }).forEach(item => {
                filterParams.discrepancies.push(item.value);
              });
              break;
            case 'lines':
              if (this.pageContext.pageName === 'efileSearchResult' || this.pageContext.pageName === 'kcfileSearchResult') {
                filterParams.lines = parseInt(filter["value"], 10);
              }
              else {
                filterParams.lines = filter["value"];
              }
              filterParams.lines = filter["value"];
              break;
            case 'errors':
              filterParams.errors = parseInt(filter["value"], 10);;
              break;
            case 'row':
              // filterParams.row = filter["value"];
              filterParams.row = [];
              let filteredComments = filter["value"].filter(f => { return f.selected });
              filteredComments.forEach(item => {
                filterParams.row.push(item.value);
              });
              break;
            case 'field':
              filterParams.field = filter["value"];
              break;
            case 'fieldName':
              filterParams.fieldName = filter["value"];
              break;
            case 'error':
              filterParams.error = filter["value"];
              break;
            case 'shortProductCode':
              filterParams.shortProductCode = filter["value"];
              break;
            case 'productCode':
              filterParams.productCode = filter["value"];
              break;
            case 'access':
              let selectedItem = filter["value"].filter(item => {
                return item.selected;
              });
              if (selectedItem.length) {
                let selected = selectedItem.map(selection => {
                  return selection.value
                });
                filterParams.access = selected.join();
              }
              else
                filterParams.access = "";
              break;
            case 'refreshStatus':
              filterParams.refreshStatus = [];
              filter["value"].filter(f => { return f.selected }).forEach(item => {
                filterParams.refreshStatus.push(item.value);
              });
              break;
            case 'unclaimedMonth':
              filterParams.unclaimedMonth = [];
              filter["value"].filter(f => { return f.selected }).forEach(item => {
                filterParams.unclaimedMonth.push(item.value);
              });
              break;
            case 'kcFileID':
              filterParams.kcFileID = filter["value"];
              break;
            case 'kcFileName':
              filterParams.kcFileName = filter["value"];
              break;
          }
        }
      }
    }

    return filterParams;
  }


  getFilterRequestObject(): any {
    switch (this.pageContext.pageName) {
      case 'searchResults':
        {
          let filterRequest = Object.assign({}, new PASearchQuery(),
            { searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : "") })
          return filterRequest;
        }
      case 'myNotifications':
        return new MyNotificationQuery();
      case 'activeFuture':
      case 'recentlyChanged':
      case 'recentlyExpired':
      case 'expiringSoon':
      case 'paFavourite':
        {
          let filterRequest = Object.assign({}, new PASearchQuery(), {
            dashboardTilesType: this.getTileType(),
            searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : ""),
            dataDurationType: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.dataDurationType : "")
          });
          return filterRequest;
        }
      case 'tradeOrgLazyLoading':
      case 'tradeOrg':
        {
          let filterRequest = Object.assign({}, new TradeOrgEndUserRequest(), {
            contractNumber: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.contractNumber : ""),
            customerNumber: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.customerNumber : ""),
            paStatus: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.paStatus : ""),
            searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : ""),
            dataDurationType: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.dataDurationType : "")
          }
          );
          return filterRequest;
        }
      case 'padetailproduct':
        {

          // let filterRequest = Object.assign({}, new PAProductQuery(), { searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : "") });;
          // To Do : Uncomment below lines once api changes are done, comment above line
          let filterRequest = Object.assign({}, new PAProductQuery(), {
            filterView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.filterView : ""),
            dashboardType: (this.pageContext.request && this.pageContext.request.query ? parseInt(this.pageContext.request.query.dashboardType, 10) : 0),
            searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : ""),
            dataDurationType: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.dataDurationType : "")
          });
          return filterRequest;
        }
      case 'padetailendusers':
        {
          // let filterRequest = Object.assign({}, new PAEndUserQuery(), { searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : "") });;
          // To Do : Uncomment below lines once api changes are done, comment above line
          let filterRequest = Object.assign({}, new PAEndUserQuery(), {
            filterView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.filterView : ""),
            dashboardType: (this.pageContext.request && this.pageContext.request.query ? parseInt(this.pageContext.request.query.dashboardType, 10) : 0),
            searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : ""),
            dataDurationType: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.dataDurationType : "")
          });
          return filterRequest;
        }
      case 'custListModal':
        {
          let filterRequest = new GetCustomerInfoFilter();
          return filterRequest;
        }
      case 'prodSearchModal':
        {
          let filterRequest = new ProductSearchFilter();
          return filterRequest;
        }
      case 'endUserSearchModal':
        {
          let filterRequest = new endusersearchFilter();
          return filterRequest;
        }
      case 'customerSearchModal':
        {
          let filterRequest = new CustomerSearchFilter();
          return filterRequest;
        };
      case 'tradeHQModal':
        {
          let filterRequest = new hqModalFilter();
          return filterRequest;
        };
      case 'padetailsSoldTo':
        {
          let filterRequest = new SoldToQuery();
          return filterRequest;
        };
      case 'endUserRequests':
        return new EndUserRequestsQuery();
      case 'ucMappingSearchUsers':
        return new UserSearchFilter();
      case 'delegatedSearchUsers':
        return new DelegatedUserSearchFilter();
      case 'ucMappingCustomerSearch':
        return new UcMappingCustomerSearchFilter();
      case 'downloadCustListModal':
        return new DownloadCustomerFilter();
      case 'portalUsageInternal':
        return new PuSearchFilter();
      case 'searchResultsCB':
        {
          let filterRequest = Object.assign({}, new CBSearchFilter());
          return filterRequest;
        }
      case 'kcfileSearchResult':
        {
          let filterRequest = Object.assign({}, new CBKcfileSearchFilter(),
            { searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : "") })
          return filterRequest;
        }
      case 'efileSearchResult':
        {
          let filterRequest = Object.assign({}, new CBEfileSearchFilter(),
            { searchView: (this.pageContext.request && this.pageContext.request.query ? this.pageContext.request.query.searchView : "") })
          return filterRequest;
        }
      case 'mcSearchResult':
        {
          let filterRequest = Object.assign({}, new MCSearchFilter());
          return filterRequest;
        }
      case 'recentActivity':
      case 'pendingTileGrid':
      case 'issuedTileGrid':
      case 'deniedTileGrid':
        {
          let filterRequest = Object.assign({}, new CBTilesFilter());
          return filterRequest;
        }
      case 'summaryResultsCB':
        {
          let filterRequest = Object.assign({}, new CBCouponSummaryResultFilter());
          return filterRequest;
        }
      case 'eFileErrorSummary':
        {
          let filterRequest = Object.assign({}, new CBEfileErrorSummaryResultFilter());
          return filterRequest;
        }
      case 'mcCustomerDetails':
        {
          let filterRequest = Object.assign({}, new MCCustomerDetailsFilter());
          return filterRequest;
        }
      case 'mcCustomerDetailsSection':
        {
          let filterRequest = Object.assign({}, new MCCustomerDetailsFilter());
          return filterRequest;
        }
      case 'mcProductsDetailsSection':
        {
          let filterRequest = Object.assign({}, new MCProductsDetailsFilter());
          return filterRequest;
        }
      case 'efileAccessPage':
        {
          let filterRequest = Object.assign({}, new EfileKcfileAccessSearchFilter());
          return filterRequest;
        }
      case 'defaultCustomer':
        {
          let filterRequest = Object.assign({}, new InvoiceCustomerSearchFilter())
          return filterRequest;
        }
      case 'invoiceSearchResult':
        {
          let filterRequest = Object.assign({}, new InvoiceCustomerSearchFilter());
          return filterRequest;
        }
      case 'invoiceFavourite':
        {
          let filterRequest = Object.assign({}, new InvoiceCustomerSearchFilter());
          return filterRequest;
        }
      case 'unclaimedTileGrid': {
        let filterRequest = Object.assign({}, new UnclaimedTileFilter());
        return filterRequest;
      }
      case 'recentRequests': {
        let filterRequest = Object.assign({}, new InvoiceCustomerSearchFilter());
        return filterRequest;
      }
      case 'kcFileErrorSummary':
        {
          let filterRequest = Object.assign({}, new CBKcfileErrorSummaryResultFilter());
          return filterRequest;
        }
      case 'kcfilePASearchResults':
        {
          let filterRequest = Object.assign({}, new KcfilePASearchQuery());
          return filterRequest;
        }
    }
  }

  getSortRequestParams(): Array<any> {
    let sortParams: Array<any>;
    let sortModel: any = this.getSortModel();
    if (sortModel && sortModel.length > 0) {
      sortParams = [];
      sortModel.forEach(sm => {
        let colId: string = sm.colId;
        if (this.pageContext.pageName != "endUserSearchModal" && this.pageContext.pageName != "" && this.pageContext.pageName != "tradeHQModal") {
          if (sm.colId.indexOf('_') !== -1) {
            colId = sm.colId.substring(0, sm.colId.indexOf('_'));
          }
        }
        if (colId == 'ag-Grid-AutoColumn') {
          colId = 'endUserId'
        }
        sortParams.push(colId + ' ' + sm.sort);
      });
    }
    else {
      sortParams = [];
    }
    return sortParams;
  }

  getFilterModel(): any {
    let filterModel: any;

    if (this.gridRowModelType === 'serverSide')
      filterModel = this.params && this.params.request ? this.params.request.filterModel : null;
    else
      filterModel = this.params ? this.params.filterModel : null;

    return filterModel;
  }

  getSortModel(): any {
    let sortModel: any;

    if (this.gridRowModelType === 'serverSide')
      sortModel = this.params && this.params.request ? this.params.request.sortModel : null;
    else
      sortModel = this.params ? this.params.sortModel : null

    return sortModel;
  }

  makeHttpSvcCall(): Observable<any> {
    // TK To Do: Add page specific URLs, Page Names here
    // Pls use the same pageName when you set the pageContext
    switch (this.pageContext.pageName) {
      case 'searchResults':
        {
          this.gridName = "searchResults";
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'activeFuture':
      case 'recentlyChanged':
      case 'recentlyExpired':
      case 'expiringSoon':
      case 'paFavourite': {
        this.gridName = "activeFuture";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'tradeOrg': {
        this.gridName = "tradeOrg";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'tradeOrgLazyLoading': {
        this.gridName = "tradeOrgLazyLoading";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'padetailproduct': {
        this.gridName = "padetailproduct";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'padetailendusers': {
        this.gridName = "padetailendusers";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'prodSearchModal': {
        this.gridName = "prodSearchModal";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'custListModal': {
        this.gridName = "custListModal";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'endUserSearchModal': {
        this.gridName = "endUserSearchModal";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest)
      }
      case 'endUserRequests': {
        this.gridName = "endUserRequests";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest)
      }
      case 'tradeHQModal': {
        this.gridName = "tradeHQModal";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest)
      }
      case 'customerSearchModal': {
        this.gridName = "customerSearchModal";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest)
      }
      case 'recentDownload': {
        this.gridName = "recentDownload";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.pageContext.request)
      }
      case 'myNotifications': {
        this.gridName = "myNotifications";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest)
      }
      case 'padetailsSoldTo': {
        this.gridName = "padetailsSoldTo";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest)
      }
      case 'ucMappingSearchUsers': {
        this.gridName = "ucMappingSearchUsers";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'delegatedSearchUsers': {
        this.gridName = "delegatedSearchUsers";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'ucMappingCustomerSearch': {
        this.gridName = "ucMappingCustomerSearch";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'myProfile': {
        this.gridName = "myProfile";
        //below code is to set preferences from featurelist api in grid api when not available in response
      }
      case 'myProfileInternal': {
        this.gridName = "myProfileInternal";
      }

      case 'ucMappings': {
        this.gridName = "ucMappings";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'customerMappings': {
        this.gridName = 'customerMappings';
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'downloadCustListModal': {
        this.gridName = 'downloadCustomerList';
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'portalUsageInternal':
        {
          this.gridName = 'portalUsageInternal';
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'searchResultsCB':
        {
          this.gridName = "searchResultsCB";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'recentActivity':
        {
          this.gridName = "recentActivity";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'pendingTileGrid':
        {
          this.gridName = "pendingTileGrid";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'issuedTileGrid':
        {
          this.gridName = "issuedTileGrid";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'deniedTileGrid':
        {
          this.gridName = "deniedTileGrid";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'summaryResultsCB':
        {
          this.gridName = "summaryResultsCB";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'couponSummaryGrid':
        {
          this.gridName = "couponSummaryGrid";
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'endUserDetailsGridCB':
        {
          this.gridName = "endUserDetailsGridCB";
          //return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'efileSearchResult':
        {
          this.gridName = "efileSearchResult";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'kcfileSearchResult':
        {
          this.gridName = "kcfileSearchResult";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'mcSearchResult':
        {
          this.gridName = "mcSearchResult";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "MC");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'eFileErrorSummary':
        {
          this.gridName = "eFileErrorSummary";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);

        }

      case 'ManageError':
        {
          this.gridName = "ManageError";
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }

      case 'efileHistoryLog':
        {
          this.gridName = "efileHistoryLog";
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'mcCustomerDetails':
        {
          this.gridName = "mcCustomerDetails";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "MC");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);

        }
      case 'mcCustomerDetailsSection':
        {
          this.gridName = "mcCustomerDetailsSection";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "MC");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'mcProductsDetailsSection':
        {
          this.gridName = "mcProductsDetailsSection";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "MC");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'advancedInvoice':
        {
          this.gridName = "advancedInvoice";
          let advancedInvoiceGridData = this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
          let requestObj = {
            userId: this.appService.getuserId(false)
          }
          let getFavoriteCategories = this.httpServiceIP.getFavoriteInvoiceCategory(requestObj);
          return forkJoin([advancedInvoiceGridData, getFavoriteCategories]);
          //return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'advancedInvoiceNew':
        {
          this.gridName = "advancedInvoiceNew";
          let advancedInvoiceGridData = this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
          let requestObj = {
            userId: this.appService.getuserId(false)
          }
          let getFavoriteCategories = this.httpServiceIPNew.getFavoriteInvoiceCategory(requestObj);
          return forkJoin([advancedInvoiceGridData, getFavoriteCategories]);
        }
      case 'getReports':
        {
          this.gridName = "getReports";
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'efileAccessPage':
        {
          this.gridName = "efileAccessPage";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "EA");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'defaultCustomer': {
        this.gridName = "defaultCustomer";
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'invoiceSearchResult': {
        this.gridName = "invoiceSearchResult";
        this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "InvoicePriceNew");
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'invoiceFavourite': {
        this.gridName = "invoiceFavourite";
        this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "InvoicePriceNew");
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'unclaimedTileGrid': {
        this.gridName = "unclaimedTileGrid";
        this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }
      case 'recentRequests': {
        this.gridName = "recentRequests";
        this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "InvoicePriceRecentRequests");
        return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
      }

      case 'kcFileErrorSummary':
        {
          this.gridName = "kcFileErrorSummary";
          this.getMultiselectDistinctFilterOptions(this.oRequest, this.gridName, "CB");
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'kcfileHistoryLog':
        {
          this.gridName = "kcfileHistoryLog";
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'ManageErrorKcFile':
        {
          this.gridName = "ManageErrorKcFile";
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
      case 'kcfilePASearchResults':
        {
          this.gridName = "kcfilePASearchResults";
          return this.httpService.makeHTTPCall(this.pageContext.verb, this.pageContext.url, null, this.oRequest);
        }
    }
  }

  getMultiselectDistinctFilterOptions(searchRequest, gridName, moduleName = "CB") {
    if (moduleName == "CB") {
      this.httpServiceCB.cbGetDistinctFilterSearchGrid(searchRequest, gridName).subscribe(data => {
        this.updateDistinctFilters(gridName, data);
      });
    }
    else if (moduleName == "MC") {
      this.httpServiceMC.mcGetDistinctFilterForGrid(searchRequest, gridName).subscribe(data => {
        this.updateDistinctFilters(gridName, data);
      });
    }
    else if (moduleName == "EA") {
      this.httpServiceEA.eaGetDistinctFilterForGrid(searchRequest, gridName).subscribe(data => {
        this.updateDistinctFilters(gridName, data);
      });
    }
    else if (moduleName == "InvoicePriceNew") {
      this.httpServiceIPNew.invoiceGetDistinctFilterForGrid(searchRequest, gridName).subscribe(data => {
        this.updateDistinctFilters(gridName, data);
      });
    }
    else if (moduleName == "InvoicePriceRecentRequests") {
      this.httpServiceIPNew.recentRequestGetDistinctFilterForGrid(searchRequest, gridName).subscribe(data => {
        this.updateDistinctFilters(gridName, data);
      });
    }
  }

  updateDistinctFilters(gridName, data) {
    if (data) {
      this.multiSelectDistinctFilter[gridName] = {};
      for (let colName in data) {
        this.multiSelectDistinctFilter[gridName][colName] = data[colName];
      }
      this.setColumnMultiselectFilter("filterUpdated")
    }
  }

  getMultiSelectDistinctFilter() {
    return this.multiSelectDistinctFilter;
  }

  setColumnMultiselectFilter(value: any) {
    this.columnMultiselectFilter.next(value);
  }

  getColumnMultiselectFilter(): Observable<any> {
    return this.columnMultiselectFilter.asObservable();
  }

  getLastRowIndex(currentPage, totalCount): number {
    let lastRow = -1;
    if (totalCount <= this.serverPageSize)
      lastRow = totalCount;
    else {
      if (currentPage * this.serverPageSize < totalCount)
        lastRow = -1;
      else
        lastRow = totalCount;
    }
    return lastRow;
  }

  emitTotalRecordCount(count: number) {
    this.recordCount.next(count);
  }

  hideLoadingOverlay() {
    this.gridApi.hideOverlay();
  }

  makePageReady() {
    this.gridApi.hideOverlay();
  }

  sortHandler(colId, sortOrder) {
    if (sortOrder) {
      this.isSorted = true;
      this.gridApi.deselectAll();
      this.gridApi.setSortModel([{ sort: sortOrder, colId: colId }]);
    }
    else {
      this.isSorted = false;
      this.gridApi.setSortModel(null);
    }
    this.setReloadGrid(true);
  }



  rowSelectionChanged(row) {
    if (row.data) {
      let index: number = this._selectedRows && this._selectedRows.length > 0 ? this._selectedRows.map(r => { return r.id }).indexOf(row.data.id) : -1;
      if (row.node.selected) {
        if (index === -1)
          this._selectedRows.push({ id: row.data.id, contractNumber: row.data.contractNumber, customerNumber: row.data.customerNumber });
      }
      else {
        this._selectedRows.splice(index, 1);
      }
    }
  }

  getSelectedRows(): Array<any> {
    return this._selectedRows;
  }

  getSvcRequest(): any {
    return Object.assign({}, this.oRequest);
  }

  //checkbox selection code
  mapSelectedRowToArray(rowData: any, _selectedRowData: Array<number>) {
    let startRowIndex = rowData.api.getFirstDisplayedRow();
    let lastRowIndex = rowData.api.getLastDisplayedRow();

    if (rowData.node.selected) {
      _selectedRowData.push(rowData.rowIndex);
      this.setHeaderCheckboxStatus(startRowIndex, lastRowIndex, _selectedRowData);
    }
    else {
      _selectedRowData.splice(_selectedRowData.indexOf(rowData.rowIndex), 1);
      this.setHeaderCheckboxStatus(startRowIndex, lastRowIndex, _selectedRowData);
    }
  }

  setHeaderCheckboxStatus(start, end, _selectedRowData: Array<number>) {
    let maxRowsInPage = end - start;
    let init = -1;
    for (let ctr = start; ctr <= end; ctr++) {
      if (_selectedRowData.indexOf(ctr) > -1) {
        init++;
      }
    }
    if (init === maxRowsInPage) {
      this.setRowCheckboxSelection("SelectAll");
    }
    else if (init > -1) {
      this.setRowCheckboxSelection("SelectPartial");
    }
    else {
      this.setRowCheckboxSelection("SelectNone");
    }
  }

  onPaginationChanged(event: any, _selectedRowData: Array<number>) {
    let startRowIndex = event.api.getFirstDisplayedRow();
    let lastRowIndex = event.api.getLastDisplayedRow();

    if (lastRowIndex > 0) {
      this.setHeaderCheckboxStatus(startRowIndex, lastRowIndex, _selectedRowData);
    }
  }
  //checkbox selection ends
  gridRowClass() {
    let region = this._globalAppService.getDefaultRegionString();
    return {
      "statusExpiredRow": function (rowNode) {
        if (rowNode.data && (rowNode.data.status || rowNode.data.statusText || rowNode.data.statusId)) {
          return rowNode.data.status === 'DEN' || rowNode.data.status === 'Expired' || rowNode.data.status === 'Denied' || rowNode.data.statusText === 'Expired' || rowNode.data.statusId === 2 || rowNode.data.status === 3 || rowNode.data.efileStatusId === 2;
        }
      },
      "statusFutureRow": function (rowNode) {
        if (rowNode.data && (rowNode.data.status || rowNode.data.statusText || rowNode.data.statusId)) {
          return rowNode.data.status === 'Future' || rowNode.data.statusText === 'Future' || rowNode.data.statusId === 3;
        }
      },
      "statusCompleteRow": function (rowNode) {
        if (rowNode.data && (rowNode.data.status || rowNode.data.statusText || rowNode.data.StatusDesc || rowNode.data.status)) {
          return rowNode.data.status === 'COM' || rowNode.data.status === 'Completed' || rowNode.data.status === 'Complete' || rowNode.data.statusText === 'COM' || rowNode.data.statusText === 'Completed' || rowNode.data.statusText === 'Complete' || rowNode.data.StatusDesc === 'Completed' || rowNode.data.status === 2 || rowNode.data.efileStatusId === 4;
        }
      },
      "statusGlobalUserLastLoginRow": function (rowNode) {
        if (rowNode.data && rowNode.data.lastLoginDate) {
          return rowNode.data.lastLoginDate;
        }
      },
      "globalUserDisableRadio": function (rowNode) {
        switch (region.toUpperCase()) {
          case 'LAO':
            if (rowNode.data && !rowNode.data.lastLoginDate) {
              return rowNode.data && !rowNode.data.lastLoginDate;
            }
            break;
          case 'NA':
          case 'EMEA':
            if (rowNode.data && !rowNode.data.lastLoginDate && !rowNode.data.isPolicyAccepted) {
              return rowNode.data && !rowNode.data.lastLoginDate && !rowNode.data.isPolicyAccepted;
            }
            break;
        }
      },
      "statusDeniedRow": function (rowNode) {
        if (rowNode.data && (rowNode.data.status || rowNode.data.statusText)) {
          return rowNode.data.status === 'B' || rowNode.data.status === 'Denied';
        }
      },
      "statusPendingRowCB": function (rowNode) {
        if (rowNode.data && (rowNode.data.status || rowNode.data.statusText)) {
          return rowNode.data.status === 'PD' || rowNode.data.status === 'Pending';
        }
      }
    };
  }

  saveStateList(states) {
    this.stateList.next(states);
    this.stateListArray = states;
  }

  getStateList(): Observable<any> {
    return this.stateList.asObservable();
  }

  setCountries(value) {
    this._countries = value;
  }

  getCountries() {
    return this._countries;
  }


  reverseFormatter(filterValue: string) {
    let valueToFilter: string = filterValue;
    if (filterValue) {
      switch (this._globalAppService.getDefaultRegionString()) {
        case 'LAO':
          {
            const regexToMatch = /^(\d{1,3}(.?\d{3})*?(\,\d{1,})?)$|^(\d{1,}\,?\d{1,2})$/g;
            // const found = filterValue.match(regexToMatch);

            if (filterValue.match(regexToMatch)) {
              let str2 = filterValue.replace(/\./g, '@');
              let str3 = str2.replace(/,/g, '.');
              let str4 = str3.replace(/@/g, '');
              valueToFilter = str4;
            }
            else {
              valueToFilter = filterValue;
            }
          }
          break;
        case 'NA':
          {
            valueToFilter = filterValue;
          }
          break;
      }
    }
    return valueToFilter;
  }
}



