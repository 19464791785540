import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { GlobalAppService } from './global-app.service';
import { timeout, catchError, map } from 'rxjs/operators';
import { NullInjector } from '@angular/core/src/di/injector';

@Injectable({
  providedIn: 'root'
})
export class HttpMeetCompService {

  constructor(private http: HttpClient,
    private _globalAppService: GlobalAppService) { }


  //fetch region specific url from environment.ts
  getRegionSpecificURL(item: any) {
    let regionURL = "";
    let region = localStorage.getItem('default-region') || this._globalAppService.getDefaultRegionString() || this._globalAppService.selectedRegion;
    if (region) {
      regionURL = "url_" + region.toUpperCase();
    }
    else {
      regionURL = "url_NA";
    }
    return item[regionURL];
  }

  makeHTTPCall(method: string, endPoint: string, additionalHeaders: any = null, requestParams: any = null): Observable<any> {
    let requestBody = {};
    if (additionalHeaders) {
      requestBody = Object.assign({}, requestBody, { headers: additionalHeaders });
    }
    if (requestParams) {
      requestBody = Object.assign({}, requestBody, { body: requestParams });
    }

    return this.http.request(method, endPoint, requestBody);
  }
  /**
   * Method to call Search API, based on provided search fields entries.
   * @param searchObj 
   */
  mcGetCustomerDetailsGrid(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.mcGetCustomerDetailsGrid.method, this.getRegionSpecificURL(environment.mcGetCustomerDetailsGrid), null, requestObj);
  }

  mcGetMeetCompDetail(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.mcGetMeetCompDetail.method, this.getRegionSpecificURL(environment.mcGetMeetCompDetail), null, requestObj);
  }

  exportMCCustomerDetailsGrid(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportMCCustomerDetailsGrid.method, this.getRegionSpecificURL(environment.exportMCCustomerDetailsGrid), null, requestObj);
  }

  mcGetProductsDetailsGrid(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.mcGetProductsDetailsGrid.method, this.getRegionSpecificURL(environment.mcGetProductsDetailsGrid), null, requestObj);
  }

  exportMCProductsDetailsGrid(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.exportMCProductsDetailsGrid.method, this.getRegionSpecificURL(environment.exportMCProductsDetailsGrid), null, requestObj);
  }

  exportMCSearchGrid(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.mcExportSearchGrid.method, this.getRegionSpecificURL(environment.mcExportSearchGrid), null, requestObj);
  }

  mcDownloadMCDetails(requestObj): Observable<any> {
    return this.makeHTTPCall(environment.mcDownloadMCDetails.method, this.getRegionSpecificURL(environment.mcDownloadMCDetails), null, requestObj);
  }


  mcGetDistinctFilterForGrid(requestObj, gridName): Observable<any> {
    switch (gridName) {
      case "mcCustomerDetailsSection":
      case "mcCustomerDetails":
        return this.makeHTTPCall(environment.mcGetDistinctFilterCustomerDetailsGrid.method, this.getRegionSpecificURL(environment.mcGetDistinctFilterCustomerDetailsGrid), null, requestObj);
	    case "mcSearchResult":
        return this.makeHTTPCall(environment.mcGetDistinctFilterSearchGrid.method, this.getRegionSpecificURL(environment.mcGetDistinctFilterSearchGrid), null, requestObj);
      case "mcProductsDetailsSection":
        return this.makeHTTPCall(environment.mcGetDistinctFilterProductsDetailsGrid.method, this.getRegionSpecificURL(environment.mcGetDistinctFilterProductsDetailsGrid), null, requestObj);
      default:
        return of(false);
    }
  }

  downloadMCDetails(request: any, isPDFDownload): Observable<any> {
    const url = isPDFDownload ? this.getRegionSpecificURL(environment.mcDownloadMCDetails) : this.getRegionSpecificURL(environment.mcDownloadMCDetailsExcelFile);
    return this.makeHTTPCall(environment.downloadPADetails.method, url, null, request).pipe(timeout(300000),
      catchError((err: any) => {
        return of(null);
      }),
      map((response) => {
        if (response) {
          return response;
        }
      }));
  }

  downloadMCData(request: any): Observable<any> {
    // return this.http.post(environment.download.url, request).pipe(timeout(300000),
    return this.makeHTTPCall(environment.DownloadMCAsync.method, this.getRegionSpecificURL(environment.DownloadMCAsync), null, request).pipe(timeout(300000),
      catchError((err: any) => {
        return of(null);
      }),
      map((response) => {
        if (response) {
          return response;
        }
      }));
  }

}
