let endPoints_CB_QA = {
	tilesCountCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Dashboard/TilesCount",
		method: "GET"
	},
	tilesPendingCountCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Dashboard/TilesPendingCount",
		method: "GET"
	},
	tilesUnclaimedCountCB: {
		url_NA: "",		
		method: "GET"
	},
	tilesResultCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Dashboard/TilesResult",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/Dashboard/TilesResult",
		method: "POST"
	},
	tilesPendingResultCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Dashboard/TilesPendingResult",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/Dashboard/TilesPendingResult",
		method: "POST"
	},
	exportTilesResultCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/Export/TilesResult",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/Export/TilesResult",
		method: "POST"
	},
	exportTilesPendingResultCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/Export/TilesPendingResult",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/Export/TilesPendingResult",
		method: "POST"
	},

	searchByFieldsCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Chargeback/BySearchFields",
		url_LAO: "https://kcc-sls-ang-chargeback-pxy-api-qa.us-e1.cloudhub.io/NA/v1/Chargeback/BySearchFields",
		method: "POST"
	},
	exportBySearchResultCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/Export/BySearchResult",
		url_LAO: "https://kcc-sls-ang-chargeback-pxy-api-qa.us-e1.cloudhub.io/na/v1/Export/BySearchResult",
		method: "POST"
	},

	saveRecentSearchCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/UserService/ChargeBackSaveSearch",
		method: "POST"
	},
	getSavedFavoriteSearchCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/UserService/ChargeBackGetFavoriteSearch",
		method: "GET"
	},
	removeSavedFavoriteSearchCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/UserService/ChargeBackRemoveFavoriteSearch",
		method: "POST"
	},

	getRecentSearchCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/UserService/ChargeBackGetRecentSearch",
		method: "GET"
	},

	markSearchFavrouiteCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/UserService/ChargeBackSaveFavoriteSearch",
		method: "POST"
	},

	removeSearchCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/UserService/ChargeBackRemoveFavoriteSearch",
		method: "POST"
	},
	saveSearchNameCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/UserService/ChargeBackSaveSearchName",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/ChargeBackSaveSearchName",
		method: "POST"
	},

	summaryResultsCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Chargeback/ChargeBackSummaryResults",
		method: "POST"
	},

	getCouponSummaryCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/Chargeback/GetSummary",
		method: "POST"
	},

	getCustomerEuCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Chargeback/GetCustEU",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/V1/Chargeback/GetCustEU ",
		method: "POST"
	},

	downloadCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Download/DownloadSearchAsync",
		method: "POST"
	},

	getEndUserDetailsCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Chargeback/ChargeBackEndUserDetails",
		method: "POST"
	},

	downloadTileCB: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Download/DownloadTilesAsync",
		method: "POST"
	},
	downloadEUDetails: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Download/DownloadEUDetailsAsync",
		method: "POST"
	},
	exportCBSummaryResult: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Export/CBSummaryResult",
		method: "POST"
	},
	getUploadedByEmailIds: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/UserService/GetUserEmailAddress",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/GetUserEmailAddress",
		method: "POST"
	},
	exportCBEfileResult: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Export/EFileResult",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/Export/EFileResult",
		method: "POST"
	},

	searchCBEfileResult: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/EFile/Search",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/Search",
		method: "POST"
	},

	efileDownload: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/EFile/UserEFileDownload",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/UserEFileDownload",
		method: "POST"
	},

	efileResultExport: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Export/EFileResult",
		method: "POST"
	},

	updateCBEfileStatus: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/EFile/UpdateEFileStatus",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/UpdateEFileStatus",
		method: "POST"
	},

	cbGetDiscrepancies: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Chargeback/CBGetDiscrepancies",
		method: "POST"
	},

	uploadEfile: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/EFile/UploadEFile",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/UploadEFile",
		method: "POST"
	},

	downloadEFileTemplate: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/EFile/DownloadEfileTemplate",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/DownloadEfileTemplate",
		method: "GET"
	},
	eFileLinesWithErrors: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/V1/EFile/EFileLinesWithErrors",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/EFileLinesWithErrors",
		method: "POST"
	},
	eFileErrorSummary: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/V1/EFile/EFileErrorSummary",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/EFileErrorSummary",
		method: "POST"
	},
	exportCBEFileErrorSummaryResult: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/Export/EFileErrorSummaryResult",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/Export/EFileErrorSummaryResult",
		method: "POST"
	},
	deleteEFileLineWithErrors: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/EFile/DeleteEFileLineWithErrors",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/DeleteEFileLineWithErrors",
		method: "POST"
	},
	efileHistoryLog: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/EFile/EFileHistoryLog",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/EFileHistoryLog",
		method: "POST"
	},
	exportEfileHistory: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/v1/Export/EFileHistory",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/Export/EFileHistory",
		method: "POST"
	},

	cbGetDistinctFilterSearchGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/Chargeback/ChargebackGridHeaderFilters",
		method: "POST"
	},

	cbGetDistinctFilterSummaryGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/Chargeback/ChargeBackSummaryGridHeaderFilters",
		method: "POST"
	},

	correctEfileErrors: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/NA/V1/EFile/CorrectErrors",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/CorrectErrors",
		method: "POST"
	},


	cbGetDistinctFilterTilesPending: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/Dashboard/TilesPendingGridHeaderFilters",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/Dashboard/TilesPendingGridHeaderFilters",
		method: "POST"
	},

	cbGetDistinctFilterTilesGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/Dashboard/TilesGridHeaderFilters",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/Dashboard/TilesGridHeaderFilters",
		method: "POST"
	},

	cbGetDistinctFilterEFileGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/EFile/EFileGridHeaderFilters",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/EFileGridHeaderFilters",
		method: "POST"
	},

	cbGetDistinctFilterEFileErrorSummaryGrid: {
		url_NA: "https://us1.api.kimclark.com/kcc-sls-ang-chargeback-pxy-api-pdqa/na/v1/EFile/EFileErrorSummaryGridHeaderFilters",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/EFileErrorSummaryGridHeaderFilters",
		method: "POST"
	},
	
	EfileUploadKCPContent: {
		url_NA: "",
		url_LAO: "",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-ang-kcpconfig-pxy-api-pdqa/EMEA/v2/Content/GetKCPContent",
		method: "POST"
	},
	EfileUploadgetAccptancePolicyStatus: {
		url_NA: "",
		url_LAO: "",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eukcpuser-pxy-api-pdqa/EMEA/v2/UserService/GetUserAgreementAcceptanceStatus",
		method: "POST"
	},
	EfileUploadsetAccptancePolicyStatus: {
		url_NA: "",
		url_LAO: "",
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/EFile/SaveEFileAcceptancePolicy",
		method: "POST"
	},
	signalREndpoint: {
		url_NA: "https://app-chargeback-kimcl-qa-ncus-lane2.azurewebsites.net/signalRHub/ChargebackHubEntryPoint",
		url_LAO: "https://app-chargeback-kimcl-qa-ncus-lane2.azurewebsites.net/signalRHub/ChargebackHubEntryPoint",
		url_EMEA: "https://app-emea-chargeback-qa-weur-1-lane2.azurewebsites.net/signalRHub/ChargebackHubEntryPoint"
	},
	tilesUnclaimedResultCB: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/Dashboard/TilesUnclaimedResult",
		method: "POST"
	},
	tilesUnclaimedResultExportCB: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/Export/TilesUnclaimedResult",
		method: "POST"
	},
	tilesUnclaimedDistinctFilterCB: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/Dashboard/TilesUnclaimedGridHeaderFilters",
		method: "POST"
	},

	//------ Added For Kc-File ----------//
	searchCBKcfileResult: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/KCFile/Search",
		method: "POST"
	},
	exportCBKcfileResult: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/Export/KCFileResult",
		method: "POST"
	},

	uploadKcfile: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/KCFile/UploadKCFile",
		method: "POST"
	},

	downloadKcFileTemplate: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/KCFile/KCFileTemplateDownload",
		method: "GET"
	},

	kcfileHistoryLog: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/KCFile/KCFileHistoryLog",
		method: "POST"
	},
	exportKcfileHistory: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/Export/KCFileHistory",
		method: "POST"
	},
	kcfileDownload: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/KCFile/UserKCFileDownload",
		method: "POST"
	},
	cbGetDistinctFilterKcFileGrid: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/KCFile/KCFileGridHeaderFilters",
		method: "POST"
	},

	exportCBKcFileErrorSummaryResult: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/emea/v1/Export/KCFileErrorSummaryResult",
		method: "POST"
	},
	kcFileErrorSummary: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/KCFile/KCFileErrorSummary",
		method: "POST"
	},

	cbGetDistinctFilterKcFileErrorSummaryGrid: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/KCFile/KCFileErrorSummaryGridHeaderFilters",
		method: "POST"
	},

	updateCBKcfileStatus: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/KCFile/UpdateKCFileStatus",
		method: "POST"
	},

	correctKcfileErrors: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/KCFile/CorrectErrors",
		method: "POST"
	},

	kcFileLinesWithErrors: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/KCFile/KCFileLinesWithErrors",
		method: "POST"
	},

	deleteKcFileLineWithErrors: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/EMEA/v1/KCFile/DeleteKCFileLineWithErrors",
		method: "POST"
	},
	KCFilePABySearchFields: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/PriceAgreement/KCFilePABySearchFields",
		method: "POST"
	},
	KCFilePriceAgreementSearch: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-eupriceagreement-pxy-api-pdqa/EMEA/v2/Export/KCFilePriceAgreementSearch",
		method: "POST"
	},
	KCFileTemplateDownload: {
		url_EMEA: "https://us1.api.kimclark.com/kcc-sls-euchargeback-pxy-api-pdqa/emea/v1/KCFile/KCFileTemplateDownload ",
		method: "POST"
	}
};

export { endPoints_CB_QA };