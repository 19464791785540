import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { HttpChargeBackService } from '@app/Modules/Shared/Services/http-charge-back.service';
import { MessageService } from 'primeng/api';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()

@Component({
  selector: 'app-efile-downnload',
  templateUrl: './efile-downnload.component.html',
  styleUrls: ['./efile-downnload.component.scss']
})

export class EfileDownnloadComponent implements AgRendererComponent, OnDestroy {

  params: any;
  subscription: Subscription = new Subscription();
  constructor(
    private httpServ: HttpChargeBackService,
    private messageService: MessageService,
    private appServ: AppService
  ) { }

  agInit(params: any) {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  onEfileNameClick(){
    this.appServ.setLoadingStatus(true);
    if (this.params.data && this.params.data.eFileID && this.params.data.salesOrgId) {
      let fileversion = (this.params.data.status && this.params.data.status == '3') ? 0 : 1;
      let request = {
        "claimFileId": this.params.data.eFileID,
        "salesOrgId": this.params.data.salesOrgId,
        "fileversion": fileversion //passing fileversion as 1 as new file to be downloaded code is 1 and for old its 0
      }
      this.subscription.add(this.httpServ.efileDownload(request).subscribe((res) => {
        if (res && res.fileUrl){
          window.open(res.fileUrl, '_blank');
        }
        else {
          this.messageService.add({ key: 'efileDownload', detail: 'An unexpected error occurred. Please try again.', summary: 'Error', severity: 'error' })
        }
      },
      (e) => {
        this.messageService.add({ key: 'efileDownload', detail: 'An unexpected error occurred. Please try again.', summary: 'Error', severity: 'error' })
      },
      () => {
        this.appServ.setLoadingStatus(false);
      }));
    }
  }
  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }

}
