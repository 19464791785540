/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-loading-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./custom-loading-overlay.component";
var styles_CustomLoadingOverlayComponent = [i0.styles];
var RenderType_CustomLoadingOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomLoadingOverlayComponent, data: {} });
export { RenderType_CustomLoadingOverlayComponent as RenderType_CustomLoadingOverlayComponent };
export function View_CustomLoadingOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading-custom"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("agGrid.agGridLoadingMsg")); _ck(_v, 1, 0, currVal_0); }); }
export function View_CustomLoadingOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-loading-overlay", [], null, null, null, View_CustomLoadingOverlayComponent_0, RenderType_CustomLoadingOverlayComponent)), i1.ɵdid(1, 49152, null, 0, i3.CustomLoadingOverlayComponent, [], null, null)], null, null); }
var CustomLoadingOverlayComponentNgFactory = i1.ɵccf("app-custom-loading-overlay", i3.CustomLoadingOverlayComponent, View_CustomLoadingOverlayComponent_Host_0, {}, {}, []);
export { CustomLoadingOverlayComponentNgFactory as CustomLoadingOverlayComponentNgFactory };
