<div class="container-fluid" *ngIf="isLoggedIn; else notLoggedInBlock">
    <div class="row">
        <div [ngClass]="(region=='NA')?'col-12 col-md-12 col-lg-12':'col-12 col-md-8 col-lg-9'">
            <div class="content-area__white-box content-area__white-box--info-module">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <table class="info-table info-table-contacts" cellspacing="0" cellpadding="0">
                            <tr>
                                <td><span>{{ "contactUs.details.customerNumLabel" | translate }}</span></td>
                                <td>{{userProfileData?.customerNumber}}</td>
                            </tr>
                            <tr>
                                <td><span>{{ "contactUs.details.companyLabel" | translate }}</span></td>
                                <td>{{userProfileData?.name}}</td>
                            </tr>
                            <tr>
                                <td><span>{{ "contactUs.details.countryLabel" | translate }}</span></td>
                                <td>{{userProfileData?.countryName}}</td>
                            </tr>
                            <tr>
                                <td><span>{{ "contactUs.details.addressLabel" | translate }}</span></td>
                                <td *ngIf="region != 'EMEA'">{{userProfileData?.address1}}<br>{{userProfileData?.city}}, {{userProfileData?.state}} {{userProfileData?.postalCode}}
                                </td>
                                <td *ngIf="region == 'EMEA'">{{userProfileData?.address1}}<br>{{userProfileData?.city}}, {{userProfileData?.postalCode}}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-12 col-sm-6">
                        <table class="info-table info-table-contacts" cellspacing="0" cellpadding="0">
                            <tr>
                                <td><span>{{ "contactUs.details.userIdLabel" | translate }}</span></td>
                                <td>
                                    <div *ngIf="isGlobalUser; else userIdElseBlock">{{_userId}}</div>
                                    <ng-template #userIdElseBlock>{{oktaProfile?.sAMAccountName}}</ng-template>
                                </td>
                            </tr>
                            <tr>
                                <td><span>{{ "contactUs.details.contactLabel" | translate }}</span></td>
                                <td>
                                    <div *ngIf="isGlobalUser; else usernameElseBlock">{{_username}}</div>
                                    <ng-template #usernameElseBlock>{{username}}</ng-template>
                                </td>
                            </tr>
                            <tr>
                                <td><span>{{ "contactUs.details.phoneLabel" | translate }}</span></td>
                                <td>
                                    <div *ngIf="isGlobalUser; else userPhoneElseBlock">{{_phone}}</div>
                                    <ng-template #userPhoneElseBlock>{{oktaProfile?.phone}}</ng-template>
                                </td>
                            </tr>
                            <tr>
                                <td><span>{{ "contactUs.details.emailLabel" | translate }}</span></td>
                                <td>
                                    <div *ngIf="isGlobalUser; else useremailElseBlock">{{_email}}</div>
                                    <ng-template #useremailElseBlock>{{oktaProfile?.email}}</ng-template>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="content-area__white-box">
                <div class="content-area__white-box__heading">
                    <h2>{{ "contactUs.message.sendUsMessageLabel" | translate }}</h2>
                    <!-- Commented as in NA region business want to remove this info #13691 -->
                    <!-- <p *ngIf="regionNA" class="content-area__white-box__heading__alert"><i
                     class="fas fa-exclamation-circle"></i>{{ "contactUs.message.respondMessageLabel" | translate }}</p> -->
                </div>
                <div class="row justify-content-md-center">
                    <div class="col-12 col-md-10" *ngIf="showcontactform">
                        <form class="needs-validation" [formGroup]="contactForm">
                            <div class="form-group" *ngIf="selectLabel && region!='NA'">
                                <label for="contactBy">{{ "contactUs.message.BestWayToContactLabel" | translate }}</label>
                                <p-dropdown name="waysToContactDropdown" [placeholder]="selectLabel" [options]="waysToContactDropdown" name="contactBy" formControlName="contactBy" [style]="{'display':'inline-block','min-width':'30em','width':'auto'}"
                                    styleClass="form-control" dropdownIcon="select__Arrow" [showClear]="false">
                                    <ng-template let-item pTemplate="selectedItem">
                                        {{item.label | translate}}
                                    </ng-template>
                                    <ng-template let-item pTemplate="item">
                                        {{item.label| translate}}
                                    </ng-template>
                                </p-dropdown>
                                <div [hidden]="!(contactForm.controls['contactBy'].invalid && (contactForm.controls['contactBy'].dirty || contactForm.controls['contactBy'].touched))" class="invalid-feedback">
                                    {{ 'contactUs.validationMsgs.contactByRequired' | translate }}</div>
                            </div>
                            <div class="form-group commentField">
                                <label for="comments">{{ "contactUs.message.commentsLabel" | translate }} *</label>
                                <textarea #commentsText name="comments" formControlName="comments" type="text" class="form-control" id="comments" rows="6"></textarea>
                                <div [hidden]="!(contactForm.controls['comments'].invalid && (contactForm.controls['comments'].dirty || contactForm.controls['comments'].touched))" class="invalid-feedback">
                                    {{ 'contactUs.validationMsgs.contactByRequired' | translate }}</div>
                            </div>
                            <div *ngIf="showButton" class="search__submit">
                                <button type="submit" name="Search" (click)="onSubmit()" class="btn btn-primary btn-primary--icon-right" [class.btn-primary--disabled]="contactForm && !contactForm.valid" [class.disable]="contactForm && !contactForm.valid" [class.btnGlobalUser]="isGlobalUser">{{ "contactUs.details.submitLabel" | translate }}
                           <i class="fas fa-arrow-alt-circle-right"></i></button>

                            </div>
                        </form>
                        <p class="success-message" *ngIf="showSuccessMessage"><i class="far fa-check-circle"></i>
                            <span>{{ "contactUs.message.thankYouLabel" | translate }}</span>&nbsp;<a href="javascript:void(0)" (click)="reset()"> {{ "contactUs.message.clickHereLabel" | translate }}</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3" *ngIf="region!='NA'">
            <div class="content-area__white-box content-area__white-box--full-height">
                <div class="content-area__white-box__heading">
                    <h2>{{ "contactUs.sidebar.contactInfoLabel" | translate }}</h2>
                </div>
                <div class="contact-us-info">
                    <contactusRightSidebar></contactusRightSidebar>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #notLoggedInBlock>
    <header>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="header">
                        <div class="header__left">
                            <a class="header__left__logo" routerLink="/"><img src="/assets/images/KCP-logo.png"></a>
                            <h1 class="header__left__heading">
                                <span class="bold-heading">{{ "contactUs.loggedOutContact.heading" | translate }}</span>
                                <span class="header__left__heading__divider"></span>
                                <span>{{ "contactUs.loggedOutContact.headingPageName"| translate}}</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div class="container-fluid" *ngIf="showForm">
        <form class="needs-validation" class="login-form" [formGroup]="locationLanguageSelectorForm">
            <div class="row justify-content-md-center">
                <div class="col-12 col-md-10 col-lg-6">
                    <div class="contact-logged-out">
                        <p class="contact-logged-out__intro" [innerHTML]="'contactUs.loggedOutContact.thankYouMessage' | translate">
                        </p>
                        <div class="content-area__white-box">
                            <div class="content-area__white-box__heading">
                                <h2>{{ "contactUs.loggedOutContact.contactInfoLabel" | translate }}</h2>
                            </div>
                            <div class="location-language-selector row">
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label for="region">{{ "contactUs.loggedOutContact.regionLabel" | translate }}</label>
                                        <p-dropdown name="region" [options]="regions" [autoWidth]="true" [disabled]="!!defaultRegion" styleClass="form-control custom-select SumoSelect" id="region" formControlName="region" dropdownIcon="select__Arrow" [showClear]="false" (onChange)="onRegionChange($event)">
                                        </p-dropdown>
                                        <!-- <select class="form-control custom-select" id="region">
                              <option>LAO</option>
                              <option>Option 2 Long</option>
                              <option>Option 3 Longer</option>
                              <option>Option 4 Longest</option>
                              <option>Option 5 Even Longer</option>
                           </select> -->
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label for="country">{{ "contactUs.loggedOutContact.countryLabel" | translate }}</label>
                                        <!-- <select class="form-control custom-select" id="country">
                              <option>Argentina</option>
                              <option>Option 2 Long</option>
                              <option>Option 3 Longer</option>
                              <option>Option 4 Longest</option>
                              <option>Option 5 Even Longer</option>
                           </select> -->
                                        <p-dropdown name="country" [options]="countries" [autoWidth]="true" styleClass="form-control custom-select SumoSelect" id="country" formControlName="country" dropdownIcon="select__Arrow" [showClear]="false" (onChange)="onCountryChange($event)">
                                        </p-dropdown>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label for="language">{{ "contactUs.loggedOutContact.languageLabel" | translate }}</label>
                                        <!-- <select class="form-control custom-select" id="language">
                              <option>English</option>
                              <option>Option 2 Long</option>
                              <option>Option 3 Longer</option>
                              <option>Option 4 Longest</option>
                              <option>Option 5 Even Longer</option>
                           </select> -->
                                        <p-dropdown name="language" [(ngModel)]="selectedLocationLanguage.language" [options]="languages" [autoWidth]="true" styleClass="form-control custom-select SumoSelect" id="language" formControlName="language" dropdownIcon="select__Arrow" [showClear]="false" (onChange)="onLanguageChange($event)">
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="contact-us-info">
                                <p>
                                    <img src="assets/images/international-flags/{{selectedCountryValue | lowercase}}.svg">
                                    <span class="left-margin"> {{selectedCountryLabel}}</span>
                                </p>
                                <table class="info-table" cellspacing="0" cellpadding="0">
                                    <tr *ngIf="contactNumber">
                                        <td><span>{{ "contactUs.loggedOutContact.phoneLabel" | translate }}</span></td>
                                        <td>{{contactNumber}}</td>
                                    </tr>
                                    <tr *ngIf="emailId">
                                        <td><span>{{ "contactUs.loggedOutContact.emailLabel" | translate }}</span></td>
                                        <td><a href="mailto:{{emailId}}">{{emailId}}</a></td>
                                    </tr>
                                </table>
                            </div>
                        </div>


                        <div class="login">
                            <div class="login__call-to-action-blocks">
                                <div class="login__call-to-action-blocks__login">
                                    <div>
                                        <h2>{{ "contactUs.loggedOutContact.loginLabel" | translate }}</h2>
                                        <a href="#" routerLink="/" class="btn btn-primary"><i
                                    class="far fa-arrow-alt-circle-right"></i>
                                 {{ "contactUs.loggedOutContact.loginToPortalLabel" | translate }}</a>

                                    </div>
                                </div>
                                <div class="login__call-to-action-blocks__register">
                                    <div>
                                        <h2>{{ "contactUs.loggedOutContact.notRegisteredLabel" | translate }}</h2>
                                        <a href="javascript:void(0)" routerLink="/register" class="btn btn-primary btn-primary--white">{{ "contactUs.loggedOutContact.signUpToday" | translate }}</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <footer>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <p [innerHTML]="'footer.copyrightFull' | translate: {legalStatement: legalStatement, privacyPolicy: privacyPolicy}">
                    </p>
                </div>
            </div>
        </div>
    </footer>
</ng-template>
<p-toast position="center" [style]="{ marginTop : '100px', width: '400px' }" [closable]="true" [baseZIndex]="500" [autoZIndex]="100" [hideTransitionOptions]="'250ms'"></p-toast>
<p-toast key="downloadError" position="center" [style]="{ marginTop : '100px', width: '400px' }" [closable]="true" [baseZIndex]="500" [autoZIndex]="100" [hideTransitionOptions]="'250ms'">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center">
            <h3>{{message.summary | translate}}</h3>
            <p>{{message.detail | translate}}</p>
        </div>
    </ng-template>
</p-toast>