import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var LocationLanguageService = /** @class */ (function () {
    function LocationLanguageService() {
        this.locationLanguageData = new BehaviorSubject(null);
        this.selectedLocationLanguage = new BehaviorSubject(null);
    }
    LocationLanguageService.prototype.setSelectedLocationLanguage = function (value) {
        // this.selectedLocationLanguage = value;
        this.selectedLocationLanguage.next(value);
    };
    LocationLanguageService.prototype.getSelectedLocationLanguage = function () {
        return this.selectedLocationLanguage.asObservable();
    };
    LocationLanguageService.prototype.setLocationLanguageData = function (value) {
        this.locationsData = value;
        this.locationLanguageData.next(value);
    };
    LocationLanguageService.prototype.getLocationLanguageData = function () {
        return this.locationLanguageData.asObservable();
    };
    LocationLanguageService.prototype.getAllRegions = function () {
        return this.locationsData.map(function (region) {
            return {
                value: region.regionId,
                label: region.description
            };
        });
    };
    LocationLanguageService.prototype.filterByRegion = function (regionCode) {
        if (!this.locationsData) {
            return null;
        }
        var selectedRegions = this.locationsData.filter(function (region) {
            return region.regionId === regionCode;
        });
        if (selectedRegions && selectedRegions.length) {
            return selectedRegions[0];
        }
        else
            return null;
    };
    LocationLanguageService.prototype.filterByCountry = function (countryCode, selectedRegion) {
        var selectedCountries = selectedRegion.country.filter(function (country) {
            return country.countryId === countryCode;
        });
        if (selectedCountries && selectedCountries.length) {
            return selectedCountries[0];
        }
        else
            return null;
    };
    LocationLanguageService.prototype.getCountriesForRegion = function (regionCode) {
        var countries;
        var selectedRegion = this.filterByRegion(regionCode);
        if (selectedRegion) {
            countries = selectedRegion.country.map(function (country) {
                return {
                    label: country.countryName,
                    value: country.countryId,
                    salesOrg: country.salesOrg[0].salesOrgId
                };
            });
        }
        return countries;
    };
    LocationLanguageService.prototype.getLanguagesForCountry = function (regionCode, countryCode) {
        var languages;
        var languageList = new Array();
        var selectedRegion = this.filterByRegion(regionCode);
        var selectedCountry;
        if (selectedRegion) {
            selectedCountry = this.filterByCountry(countryCode, selectedRegion);
            if (selectedCountry) {
                selectedCountry.salesOrg.forEach(function (so) {
                    languageList = languageList.concat(so.language);
                });
                languageList = languageList.filter(function (item, index) {
                    var indexOfItem = languageList.findIndex(function (l) {
                        return l.languageCode === item.languageCode;
                    });
                    return indexOfItem === index;
                });
                return languageList.map(function (l) {
                    return {
                        label: l.description,
                        value: l.languageCode
                    };
                });
            }
            else {
                return null;
            }
        }
        else
            return null;
    };
    LocationLanguageService.prototype.doesRegionExists = function (regionCode, regions) {
        return regions.findIndex(function (r) { return r.value === regionCode; }) !== -1;
    };
    LocationLanguageService.prototype.doesCountryExists = function (countryCode, countries) {
        return countries.findIndex(function (c) { return c.value === countryCode; }) !== -1;
    };
    LocationLanguageService.prototype.doesLangaugeExists = function (languageCode, languages) {
        return languages.findIndex(function (l) { return l.value === languageCode; }) !== -1;
    };
    LocationLanguageService.ngInjectableDef = i0.defineInjectable({ factory: function LocationLanguageService_Factory() { return new LocationLanguageService(); }, token: LocationLanguageService, providedIn: "root" });
    return LocationLanguageService;
}());
export { LocationLanguageService };
