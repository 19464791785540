/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-no-rows-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./custom-no-rows-overlay.component";
var styles_CustomNoRowsOverlayComponent = [i0.styles];
var RenderType_CustomNoRowsOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomNoRowsOverlayComponent, data: {} });
export { RenderType_CustomNoRowsOverlayComponent as RenderType_CustomNoRowsOverlayComponent };
export function View_CustomNoRowsOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("agGrid.agGridNoData")); _ck(_v, 0, 0, currVal_0); }); }
export function View_CustomNoRowsOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-no-rows-overlay", [], null, null, null, View_CustomNoRowsOverlayComponent_0, RenderType_CustomNoRowsOverlayComponent)), i1.ɵdid(1, 49152, null, 0, i3.CustomNoRowsOverlayComponent, [], null, null)], null, null); }
var CustomNoRowsOverlayComponentNgFactory = i1.ɵccf("app-custom-no-rows-overlay", i3.CustomNoRowsOverlayComponent, View_CustomNoRowsOverlayComponent_Host_0, {}, {}, []);
export { CustomNoRowsOverlayComponentNgFactory as CustomNoRowsOverlayComponentNgFactory };
