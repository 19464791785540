/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reports-download.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../Shared/directives/copy-to-clipboard.directive";
import * as i4 from "primeng/components/tooltip/tooltip";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../../node_modules/primeng/components/toast/toast.ngfactory";
import * as i7 from "primeng/components/toast/toast";
import * as i8 from "primeng/components/common/messageservice";
import * as i9 from "primeng/components/common/shared";
import * as i10 from "./reports-download.component";
import * as i11 from "../../../../Shared/Services/http-siteadmin.service";
import * as i12 from "../../../../Shared/Services/global-app.service";
import * as i13 from "../../../../Shared/Services/app.service";
import * as i14 from "@angular/router";
var styles_ReportsDownloadComponent = [i0.styles];
var RenderType_ReportsDownloadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportsDownloadComponent, data: {} });
export { RenderType_ReportsDownloadComponent as RenderType_ReportsDownloadComponent };
function View_ReportsDownloadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "saveNotification"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Link copied"]))], null, null); }
function View_ReportsDownloadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.context.$implicit.summary)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.context.$implicit.detail)); _ck(_v, 5, 0, currVal_1); }); }
export function View_ReportsDownloadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "dnlLink"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLinkClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "copyUrlLink"]], null, [[null, "copied"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("copied" === en)) {
        var pd_1 = (_co.notify($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i3.CopyToClipboardDirective, [], { payload: [0, "payload"] }, { copied: "copied" }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "i", [["class", "far fa-copy"], ["pTooltip", "Copy download link"]], null, null, null, null, null)), i1.ɵdid(5, 4341760, null, 0, i4.Tooltip, [i1.ElementRef, i1.NgZone], { text: [0, "text"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsDownloadComponent_1)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 5, "p-toast", [["key", "downloadError"], ["position", "center"]], [[8, "closable", 0]], null, null, i6.View_Toast_0, i6.RenderType_Toast)), i1.ɵdid(9, 1294336, null, 1, i7.Toast, [i8.MessageService], { key: [0, "key"], autoZIndex: [1, "autoZIndex"], baseZIndex: [2, "baseZIndex"], style: [3, "style"], position: [4, "position"], hideTransitionOptions: [5, "hideTransitionOptions"] }, null), i1.ɵqud(603979776, 1, { templates: 1 }), i1.ɵpod(11, { marginTop: 0, width: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ReportsDownloadComponent_2)), i1.ɵdid(13, 16384, [[1, 4]], 0, i9.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.downloadLink; _ck(_v, 3, 0, currVal_1); var currVal_2 = "Copy download link"; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.showNotification; _ck(_v, 7, 0, currVal_3); var currVal_5 = "downloadError"; var currVal_6 = 100; var currVal_7 = 500; var currVal_8 = _ck(_v, 11, 0, "100px", "400px"); var currVal_9 = "center"; var currVal_10 = "250ms"; _ck(_v, 9, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = "message"; _ck(_v, 13, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paramsValue; _ck(_v, 1, 0, currVal_0); var currVal_4 = true; _ck(_v, 8, 0, currVal_4); }); }
export function View_ReportsDownloadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "reports-download", [], null, null, null, View_ReportsDownloadComponent_0, RenderType_ReportsDownloadComponent)), i1.ɵdid(1, 180224, null, 0, i10.ReportsDownloadComponent, [i11.HttpSiteadminService, i12.GlobalAppService, i2.TranslateService, i13.AppService, i8.MessageService, i14.ActivatedRoute, i14.Router], null, null)], null, null); }
var ReportsDownloadComponentNgFactory = i1.ɵccf("reports-download", i10.ReportsDownloadComponent, View_ReportsDownloadComponent_Host_0, {}, {}, []);
export { ReportsDownloadComponentNgFactory as ReportsDownloadComponentNgFactory };
