import * as tslib_1 from "tslib";
import { OnDestroy, ChangeDetectorRef, ViewRef } from '@angular/core';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { TranslateService } from '@ngx-translate/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { Calendar } from 'primeng/calendar';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
var DateSelectorUFNComponent = /** @class */ (function () {
    function DateSelectorUFNComponent(translateServ, cd, appServ, languageServ, globalAppService, gridService) {
        this.translateServ = translateServ;
        this.cd = cd;
        this.appServ = appServ;
        this.languageServ = languageServ;
        this.globalAppService = globalAppService;
        this.gridService = gridService;
        this.maxDateValue = new Date();
        this.txtDateRadio = false;
        this.filterModel = { type: "date-selectorufn", value: this.txtDate };
        this.isDateSelected = false;
        this.subscription = new Subscription();
    }
    DateSelectorUFNComponent.prototype.agInit = function (params) {
        var _this = this;
        this.params = params;
        this.region = this.globalAppService.getDefaultRegionString();
        this.orgColId = params.column.getColId();
        this.colId = params.colDef.field;
        this.valueGetter = params.valueGetter;
        this.colId = this.colId.indexOf('_') !== -1 ? this.colId.substring(0, this.colId.indexOf('_')) : this.colId;
        if (this.colId !== 'endDate' && this.colId !== 'customerEndDate' && this.colId !== 'availableUntil') {
            if (this.region == 'NA' || this.colId == 'firmEndDate') {
                this.maxDateValue = null;
            }
            else {
                this.maxDateValue.setDate(this.maxDateValue.getDate());
            }
        }
        else
            this.maxDateValue = null;
        this.subscription.add(this.languageServ.currentSelected.subscribe(function (data) {
            _this.subscription.add(_this.translateServ.get(['calender'])
                .subscribe(function (translations) {
                _this.calenderData = translations['calender'];
                _this.dateFormat = _this.calenderData.format;
                _this.primengDateFormat = _this.calenderData.dateFormat.toLowerCase();
            }));
        }));
        this.params.api.setPopupParent(document.querySelector('body'));
    };
    DateSelectorUFNComponent.prototype.getModel = function () {
        if (this.isapplyClicked) {
            this.filterModel.value = this.txtDate;
        }
        else if (!this.txtDate) {
            this.filterModel.value = null;
        }
        console.log("@@@in GetModel: ", this.filterModel);
        return this.filterModel;
    };
    DateSelectorUFNComponent.prototype.setModel = function (model) {
        if (model && model.value) {
            this.txtDate = this.filterModel.value = model.value;
        }
        else {
            this.txtDate = null;
            this.filterModel.value = "";
        }
    };
    DateSelectorUFNComponent.prototype.isFilterActive = function () {
        return this.txtDate && this.txtDate instanceof Date;
    };
    DateSelectorUFNComponent.prototype.doesFilterPass = function (params) {
        return true;
    };
    // noinspection JSMethodCanBeStatic
    DateSelectorUFNComponent.prototype.componentMethod = function (message) {
        alert("Alert from DateSelectorComponent " + message);
    };
    DateSelectorUFNComponent.prototype.onDateSelected = function (event) {
        this.isDateSelected = true;
    };
    DateSelectorUFNComponent.prototype.onApplyClick = function (event, cpContainer) {
        this.isapplyClicked = true;
        if (this.txtDateRadio) {
            this.txtDate = new Date('12/31/9999');
            this.isDateSelected = true;
        }
        if (this.isDateSelected) {
            this.params.filterChangedCallback();
            this.guiParams.hidePopup();
            this.isDateSelected = false;
        }
        cpContainer.classList.remove('date_range--popup');
    };
    DateSelectorUFNComponent.prototype.OnCalClosed = function (cpContainer) {
        cpContainer.classList.remove('date_range--popup');
    };
    DateSelectorUFNComponent.prototype.onRemoveFilter = function () {
        this.txtDate = null;
        this.txtDateRadio = false;
        this.gridService.removeFilters(this.params.column.getColId());
        this.guiParams.hidePopup();
    };
    DateSelectorUFNComponent.prototype.afterGuiAttached = function (params) {
        var _this = this;
        if (this.calendarElement && this.calendarElement.inputfieldViewChild)
            this.calendarElement.inputfieldViewChild.nativeElement.placeholder = this.dateFormat;
        this.guiParams = params;
        this.subscription.add(this.languageServ.currentSelected.subscribe(function (data) {
            _this.subscription.add(_this.translateServ.get(['calender'])
                .subscribe(function (translations) {
                _this.calenderData = translations['calender'];
                _this.dateFormat = _this.calenderData.format;
                _this.primengDateFormat = _this.calenderData.dateFormat.toLowerCase();
            }));
        }));
        if (!this.txtDate) {
            this.txtDateRadio = false;
            if (this.appServ.isIEBrowser()) {
                if (this.cd && !this.cd.destroyed) {
                    this.cd.detectChanges();
                }
            }
        }
        if (!this.isDateSelected && !this.txtDateRadio && !this.isapplyClicked) {
            this.txtDate = null;
        }
        if (this.txtDate) {
            this.isDateSelected = true;
        }
        this.isapplyClicked = false;
    };
    DateSelectorUFNComponent.prototype.onSortRequested = function (isSortedRequest) {
        if (!!isSortedRequest) {
            this.guiParams.hidePopup();
        }
    };
    DateSelectorUFNComponent.prototype.onCalFocus = function (event) {
        this.txtDateRadio = false;
        var UFNDate = new Date('12/31/9999').getTime();
        var selectedDate = this.txtDate ? this.txtDate.getTime() : null;
        if (selectedDate == UFNDate) {
            this.txtDate = null;
        }
        event.classList.add('date_range--popup');
        if (this.appServ.isIEBrowser()) {
            if (this.cd && !this.cd.destroyed) {
                this.cd.detectChanges();
            }
        }
    };
    DateSelectorUFNComponent.prototype.onUFNChecboxClicked = function (event) {
        if (event.target.checked) {
            this.txtDate = new Date('12/31/9999');
            this.isDateSelected = true;
        }
        else {
            this.txtDate = null;
            this.isDateSelected = false;
        }
        /** To render UI quickly in IE */
        if (this.appServ.isIEBrowser()) {
            if (this.cd && !this.cd.destroyed) {
                this.cd.detectChanges();
            }
        }
    };
    DateSelectorUFNComponent.prototype.ngOnDestroy = function () {
        if (!this.isapplyClicked) {
            this.txtDate = null;
        }
        // Required for unsubscribing and destroying the component  
    };
    DateSelectorUFNComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [TranslateService,
            ChangeDetectorRef,
            AppService,
            LanguageSelectionService,
            GlobalAppService,
            CpAgGridService])
    ], DateSelectorUFNComponent);
    return DateSelectorUFNComponent;
}());
export { DateSelectorUFNComponent };
