import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoicePriceService } from '@app/Modules/in-voice/Services/invoice-price.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { HttpInvoicePriceService } from '@app/Modules/Shared/Services/http-invoice-price.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-contextmenu-advanced-invoice',
  templateUrl: './contextmenu-advanced-invoice.component.html',
  styleUrls: ['./contextmenu-advanced-invoice.component.scss']
})
export class ContextmenuAdvancedInvoiceComponent implements OnInit {

  params: any;
  ellipseSelect: boolean = false;
  isGlobalUser: boolean = false;
  showMenu: boolean = false;

  constructor(private httpInvoiceService: HttpInvoicePriceService,
    private appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private invoicePriceService: InvoicePriceService) { }

  agInit(params: any) {
    this.params = params;
  }

  ngOnInit() {
    this.isGlobalUser = this.appService.isGlobalUser;
    this.showMenu = this.params.data && this.params.data.refreshStatus != 1;
  }

  contextmenuToggle(element, e){
    element.toggle(e);
  }

  onContextMenuHide(){
    this.ellipseSelect = false;
  }

  onContextMenuShow(){
    this.ellipseSelect = true;
  }
  
  refreshInvoice(){
    if(this.isGlobalUser){
      this.downloadInvoice();
    } else {
      this.appService.setLoadingStatus(true);
      let request = {
        customerNumber: this.params.data.customerNumber,
        categoryList: [
          this.params.data.categoryCode
        ]
      };
      this.httpInvoiceService.priceRefreshRequest(request).subscribe(data => {
        if(data.statusMessage == "Price refresh request initiated."){
          this.invoicePriceService.setUpdateRefreshStatusRequest([this.params.data.categoryCode]);
          this.router.navigate([{ outlets: { emailDialog: ['emailModal'] } }], { relativeTo: this.activatedRoute, state: { customerNumber: this.params.data.customerNumber, customerList: [this.params.data.categoryCode]}, queryParamsHandling: "preserve"});
        }
      },
      (e) => {
        this.invoicePriceService.setErrorMsg(true);
      },
      () => {
        this.appService.setLoadingStatus(false);
      });
    }
  }

  downloadInvoice(){
    let invDownloadData = {
      customerNumber: this.params.data.customerNumber,
      selectedCategoryNumber: [
        this.params.data.categoryCode
      ],
      isDownloadAll: false
    }
    this.router.navigate([{ outlets: { downloadDialog: ['downloadInvoice'] } }], { relativeTo: this.activatedRoute, state : {downloadData: invDownloadData}, queryParamsHandling: "preserve" });
  }

}
