import { Injectable } from '@angular/core';
import { CustomerInfomation, OktaProfile, SearchModalData } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { Observable, BehaviorSubject, Subject, Subscription } from 'rxjs';
import "rxjs/add/observable/of";
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { Router,ActivatedRoute } from '@angular/router';
import { globalUser, cookieSIMID, cookieLoggedInUserId, APP_LEGACY_MENU_HOST } from '@env/environment';
import { indexLink, WelcomeGetStartedLink } from '@app/conf/appLink';
import { GlobalAppService } from './global-app.service';
import * as moment from 'moment';
import { ribbonMenuExternal, ribbonMenuInternalCustomer, ribbonMenuInternalCVT, ribbonMenuGlobal, DashBoardTiles } from '@app/conf/dataConfigurations';
import { LanguageSelectionService } from './language-selection.service';
import { DataLayerService } from './data-layer.service';
import { FullStoryService } from './full-story.service';
import { countryOBJ } from '@app/conf/countryURLConfig';

@Injectable({ providedIn: 'root' })
export class AppService {

  private cvtView: boolean;
  private cvtViewSubject = new Subject<any>();
  private _oktaProfileData = new Subject<OktaProfile>();
  private _userProfileData = new Subject<CustomerInfomation>();
  private _isLoading = new BehaviorSubject<boolean>(false);
  private _userType: string;
  private _userID: string;
  private userProfile: CustomerInfomation;
  private _loadedHomeModal: boolean = false;
  private _LoggedInUserProfile: OktaProfile;
  private recentlyChangedDownload: boolean;
  private redirectHome: boolean;
  private _SearchSelectedModalData = new Subject<SearchModalData>();
  private globalUser: boolean = false;
  private favoritepaList: Array<any>;
  private activesubNav : BehaviorSubject<any>;
  private activeTile : BehaviorSubject<any>;
  // profileLoaded: Subject<boolean> = new Subject<boolean>();
  screenResize: Subject<boolean> = new Subject<boolean>();
  private gUserInfo: any;
  guid: any;
  private _disableLink: Subject<boolean> = new Subject<boolean>();
  private _paType: Array<any>;
  private isProfileSet: boolean = false;
  private isExternalAdminUserVar: boolean = false;
  private isDelegatedUserPage: Subject<boolean> = new Subject<boolean>();
  private isDelegatedUserPageBoolean: boolean = false;
  paTypeSubject: Subject<any> = new Subject<any>();
  salesOrgNames: Array<any>;
  KCOwnerNames: Array<any>;
  salesOrgNamesSubject: Subject<any> = new Subject<any>();
  KCOwnerNamesSubject: Subject<any> = new Subject<any>();
  homeNavigation: Subject<boolean> = new Subject<boolean>();
  subscription: Subscription = new Subscription();
  acceptancePolicyResponse: any;
  showAcceptancePolicyModal: Subject<any> = new Subject<any>();
  userSimulated : boolean ;
  private _timeOutLoaderHide = new Subject<boolean>();
  flagImageResRecd: Subject<any> = new Subject<any>();
  flagImageResRecdString: any;
  private menu : BehaviorSubject<any>; 
  private popUpStatusSubject = new BehaviorSubject<any>(1);
  popupSequenceNumber: Array<number> = [];
  popupSequenceNumberIndex: number = -1;

  setLoaderStatusForHeaderFlagImage(value){
    this.flagImageResRecd.next({
      'flagImageResRecd': value
    });
  }

  getLoaderStatusForHeaderFlagImage(): Observable<any>{
    return this.flagImageResRecd.asObservable();
  }

  setUserSimulated(value: any) {
    this.userSimulated = value;
  }

  getUserSimulated(): boolean {
    return this.userSimulated;
  }

  setAcceptancePolicyModalVisibility(value: any) {
    console.log("set acceptance policy modal", value);
    this.showAcceptancePolicyModal.next(value);
  }

  getAcceptancePolicyModalVisibility(): Observable<any> {
    return this.showAcceptancePolicyModal.asObservable();
  }

  setHomeNavigation(homeNavigation) {
    this.homeNavigation.next(homeNavigation);
  }

  getHomeNavigation() {
    return this.homeNavigation;
  }


  setPAtype(patype) {
    this._paType = patype;
    this.paTypeSubject.next(patype);
  }

  getPAtype() {
    return this._paType;
  }

  getPAtypeObservable(): Observable<any> {
    return this.paTypeSubject.asObservable();
  }

  setSaleOrgNames(salesOrgNames) {
    this.salesOrgNames = salesOrgNames;
    this.salesOrgNamesSubject.next(salesOrgNames);
  }

  getSalesOrgNames() {
    return this.salesOrgNames;
  }

  getSalesOrgNamesObservable(): Observable<any> {
    return this.salesOrgNamesSubject.asObservable();
  }

  setKCOwnerNames(KCOwnerNames) {
    this.KCOwnerNames = KCOwnerNames;
    this.KCOwnerNamesSubject.next(KCOwnerNames);
  }

  getKCOwnerNames() {
    return this.KCOwnerNames;
  }

  getKCOwnerNamesObservable(): Observable<any> {
    return this.KCOwnerNamesSubject.asObservable();
  }

  setIsDelegatedUserPage(value) {
    this.isDelegatedUserPageBoolean = value;
    this.isDelegatedUserPage.next(value);
  }

  getIsDelegatedUserPage(): Observable<boolean> {
    return this.isDelegatedUserPage;
  }

  getisDelegatedUserPageBoolean() {
    return this.isDelegatedUserPageBoolean;
  }


  public searchRequestState: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public locationLanguage: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  setLocationLanguage(value) {
    this.locationLanguage.next(value);
  }

  getLocationLanguage(): Observable<any> {
    return this.locationLanguage.asObservable();
  }

  getSearchRequest(): Observable<any> {
    return this.searchRequestState.asObservable();
  }

  changeSearchRequest(searchObject) {
    this.searchRequestState.next(searchObject);
  }

  // getProfileLoadedObservable(): Observable<boolean> {
  //   return this.profileLoaded.asObservable();
  // }

  getFavoritePA(): Array<string> {
    return this.favoritepaList;
  }

  setFavoritePA() {
    this.favoritepaList = [];
    this._HTTPService.getFavContractList().subscribe((data) => {
      if (data && data.favoriteContractList) {
        this.favoritepaList = data.favoriteContractList;
      }
    });
  }

  constructor(
    private _HTTPService: HttpService,
    private _router: Router,
    private route:ActivatedRoute,
    private globalAppService: GlobalAppService,
    private languageSelected: LanguageSelectionService,
    private _dataLayerService: DataLayerService,
    private _fullStoryService: FullStoryService) { 
      this.activesubNav = new BehaviorSubject({ id: "1" });
      this.activeTile = new BehaviorSubject(DashBoardTiles["LAO"].find(x => x.id == "activeCount"));
      this.menu = new BehaviorSubject(ribbonMenuGlobal["LAO"]);
      this.setMenu();
    }
  setuserProfileData(customerInfo: CustomerInfomation) {
    this.userProfile = Object.assign({}, customerInfo);
    this._userProfileData.next(customerInfo);
  }

  get userProfileInfo(): CustomerInfomation {
    return this.userProfile;
  }
  getuserProfileData(): Observable<CustomerInfomation> {
    return this._userProfileData;
  }

  getScreenResize(): Observable<boolean> {
    return this.screenResize.asObservable();
  }

  setScreenResize(value) {
    return this.screenResize.next(value);
  }

  isUserInformationAvailable() {
    return this.gUserInfo;
  }

  getOKTAProfile(OKTA_TOKEN: string): void {
    this._HTTPService.getUserInfo(OKTA_TOKEN).subscribe((profile: OktaProfile) => {
      // this.profileLoaded.next(true);
      this.setOktaProfile(profile);
      //global user check
      this._userID = profile.sAMAccountName;
      this._userType = profile.userType;
      document.cookie = cookieLoggedInUserId + "=" + this._userID + ";path=/"; // This cookie is required by legacy application            
      if (this.isGlobalUser && this.getCookie(cookieSIMID)) {
        this.globalUser = true;
      } 
      if (this.globalUser && this.getCookie(cookieSIMID) == null) {
        window.open(indexLink, '_self');
        return;
      }
    })
  }
  //code ends here for global user

  registerUserInformation(userInformation: any) {
    this.gUserInfo = userInformation;
    // create a UserProfile member variable and assign the UserObject to it.
  }

  processUserInformation(gUserInfo) {
    // this.profileLoaded.next(true);    
    if (!gUserInfo) {
      return;
    }
    this.setOktaProfile(gUserInfo);
    this.setGTMDataLayer(gUserInfo);
    this.setFullStoryVariables(gUserInfo);
    //global user check
    this._userID = gUserInfo.sAMAccountName;
    this._userType = gUserInfo.userType;
    // document.cookie = cookieLoggedInUserId + "=" + this._userID + ";path=/"; // This cookie is required by legacy application      
    // document.cookie = cookieSIMID + "=9crp000105~^KC test user KC test user~*test@kcc.com;path=/";
    if (this.isGlobalUser && this.getCookie(cookieSIMID) == null) {
      // window.open(indexLink, '_self');
      this._router.navigate(['delegatedUserSearch'],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
    }
    //code ends here for global user
    this._HTTPService.getUserProfile(this.getuserId(false)).subscribe((data) => {
      // if (data && data['Customer_Information'] && data['Customer_Information'].Status) {      
      if (!(data && data['defaultCustomerInfo'] && data['defaultCustomerInfo'].length)) {
        this._HTTPService.getWelcomeFlag(this.getuserId(false))
          .subscribe(d => {
            if (d.showWelcomeMessage === false) {
              if (this.isGlobalUser && this.getCookie(cookieSIMID) == null) {
                this._router.navigate(['delegatedUserSearch'],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
              }
              else {
                if (!this.isInternalUser) {
                  this._HTTPService.getLocation(this.getuserId(false)).subscribe((data) => {
                    if (data.result.customerMapping.length == 0) {
                      console.log("@@@Redirecting to Profile as no customer mapping and not defaultCustomerInfo");
                      this.globalAppService.setNoLocationsAvaiable(true);
                    }
                    this._router.navigate(['profile'],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
                    this.globalAppService.setIsFirstTimeOnProfile(true);
                    this.isProfileSet = false;
                    this.redirectHome = false;
                  });
                } else {
                  this._router.navigateByUrl('profile',{queryParamsHandling: 'preserve'});
                  this.globalAppService.setIsFirstTimeOnProfile(true);
                  this.isProfileSet = false;
                  this.redirectHome = false;
                }
              }
            }
            else {
              this._router.navigateByUrl("welcome");
              this.redirectHome = false;
              this.isProfileSet = false;
            }
          });
      }
      else {
        this.globalAppService.setDefaultCustomerInfo(data['defaultCustomerInfo']);
        this._HTTPService.getWelcomeFlag(this.getuserId(false)).subscribe(d => {
          this.setuserProfileData(data);
          this.setCvtViewOkta(gUserInfo.userType, data);
          if (d.showWelcomeMessage) {
            this.redirectHome = true;
            this._router.navigateByUrl("welcome");
          }
          else {
            // if (this._router.url.indexOf('/pa/details') === -1 && this._router.url.indexOf('/dashboard/search') === -1)
            if (window.location.href.indexOf('/pa/details') === -1 &&
              window.location.href.indexOf('/chargeback/coupon/details') === -1 &&
              window.location.href.indexOf('/meetcomps/meetcompsDetails/details') === -1 &&
              window.location.href.indexOf('/dashboard/search') === -1 &&
              window.location.href.indexOf('/priceagreement/dashboard') === -1 &&
              window.location.href.indexOf('/priceagreement/dashboard/mypafavorite') === -1 &&
              window.location.href.indexOf('/priceagreement/dashboard/mysearches') === -1 &&
              window.location.href.indexOf('/portalusage/dashboard') === -1 &&
              window.location.href.indexOf('/uc-mapping/dashboard/search') === -1 &&
              window.location.href.indexOf('/chargeback') === -1 &&
              window.location.href.indexOf('/profile/myprofile') === -1 &&
              window.location.href.indexOf('/meetcomps') === -1 &&
              window.location.href.indexOf('/invoice') === -1 &&
              window.location.href.indexOf('/invoice-new') === -1 &&
              window.location.href.indexOf('/contactus') === -1 &&
              window.location.href.indexOf('/recentDownload?') === -1 &&
              window.location.href.indexOf('/manage-errors?') === -1 &&
              window.location.href.indexOf('/cb-pricing-terms') === -1 &&
              window.location.href.indexOf('/pricing/termsConditions') === -1 &&
              window.location.href.indexOf('/reports') === -1) {
              if (sessionStorage.getItem('recentDownloadParams')) {
                let parsedParams: any = JSON.parse(sessionStorage.getItem('recentDownloadParams'));
                this._router.navigate(['priceagreement/dashboard/recentDownload'], { queryParams: {parsedParams,region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
                sessionStorage.removeItem('recentDownloadParams');
                sessionStorage.removeItem('recentDownloadUrl');
              } else if(localStorage.getItem("cb-pricing-terms-url")){
                let pricingTermURL = localStorage.getItem("cb-pricing-terms-url");
                localStorage.removeItem('cb-pricing-terms-url');
                let modifiedBaseUrl = APP_LEGACY_MENU_HOST.indexOf('http://localhost:34025') > -1 ? APP_LEGACY_MENU_HOST : APP_LEGACY_MENU_HOST;
                window.open(modifiedBaseUrl + pricingTermURL, '_self');
              }
              else {
                this.isProfileSet = true; // Check With Sagar
                let paDetailsURL = window.sessionStorage.getItem('paDetailsURL');
                console.log('paDetailsURL', paDetailsURL);
                if (paDetailsURL) {
                  window.sessionStorage.removeItem('paDetailsURL');
                  // let modifiedBaseUrl = APP_LEGACY_MENU_HOST.indexOf('http://localhost:34025') > -1 ? APP_LEGACY_MENU_HOST : APP_LEGACY_MENU_HOST + '/SPA';
                  let modifiedBaseUrl = APP_LEGACY_MENU_HOST.indexOf('http://localhost:34025') > -1 ? APP_LEGACY_MENU_HOST : APP_LEGACY_MENU_HOST;
                  window.open(modifiedBaseUrl + paDetailsURL, '_self');
                }
                if (!this.isGlobalUser && window.location.href.indexOf('/error') === -1) {
                  this._HTTPService.getCountrySalesOrgLanguage().subscribe((countriesLangResponse) => {
                    if (countriesLangResponse && countriesLangResponse.result && countriesLangResponse.result[0].country && countriesLangResponse.result[0].country.length) {
                      let mappedCountries = countriesLangResponse.result[0].country;
                      let userPreferedCountry = this.globalAppService.getUserDetails().preferredCountry;
                      let isPreferredCountryMatched = false;
                      mappedCountries.forEach((country) => {
                        if (country.countryId == userPreferedCountry) {
                          isPreferredCountryMatched = true;
                        }
                      });
                      if (isPreferredCountryMatched) {
                        // this._router.navigateByUrl('home');  
                        if(window.location.href.indexOf('/register') === -1 && window.location.href.indexOf('/login') === -1){
                          this._router.navigate(
                            ['/home'], 
                            { relativeTo: this.route,
                              queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')},                             
                            });
                        }                                              
                      } else {
                        this._router.navigate(['profile'],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
                        this.globalAppService.setIsFirstTimeOnProfile(true);
                      }
                    } else {
                      this.globalAppService.setNoLocationsAvaiable(true);
                      this._router.navigate(['profile'],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
                      this.globalAppService.setIsFirstTimeOnProfile(true);
                    }
                  });

                } else if (this.isGlobalUser && this.getCookie(cookieSIMID)) {
                  if(window.location.href.indexOf('/register') === -1 && window.location.href.indexOf('/login') === -1){
                    this._router.navigate(['home'],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
                  }                  
                } else if (this.isGlobalUser && !this.getCookie(cookieSIMID)) {
                  this._router.navigate(['delegatedUserSearch'],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
                }
              }
            }
          }
        }, (err) => {
          console.log("Error");
        })

      }
    }, (err) => {
      console.log("Error");
    });
  }

  navigateToLanding(OKTA_TOKEN: string): void {
    // check if the global user profile is available if not then make API call.
    if (this.gUserInfo) {
      this.processUserInformation(this.gUserInfo);
    }
    else {
      this._HTTPService.getUserInfo(OKTA_TOKEN).subscribe((profile: OktaProfile) => {
        this.gUserInfo = profile;
        this.processUserInformation(this.gUserInfo);
      });
    }

  }
  //  User Okta Information

  setOktaProfile(oktaProfile: OktaProfile) {
    if (oktaProfile.userType) {
      this._userType = oktaProfile.userType;
    }
    if (oktaProfile.sAMAccountName) {
      this._userID = oktaProfile.sAMAccountName;
    }

    this._LoggedInUserProfile = oktaProfile;
    this._oktaProfileData.next(oktaProfile);
  }
  get AvailableOKtaProfile(): OktaProfile {
    return this._LoggedInUserProfile;
  }
  getOktaProfile(): Observable<OktaProfile> {
    return this._oktaProfileData;
  }

  getuserId(force: boolean = false): string {
    if (force) {
      return this._userID;
    } else {

      if (this.getCookie(cookieSIMID) && this.getCookie(cookieSIMID).length > 0) {
        let userID = this.getCookie(cookieSIMID).substring(0, this.getCookie(cookieSIMID).indexOf('~^'));
        let userEmail = this.getCookie(cookieSIMID).substring(this.getCookie(cookieSIMID).indexOf('~*')+2, this.getCookie(cookieSIMID).indexOf('~&'));
        return userID;
      }
      else {
        return this._userID;
      }
    }
  }

  getuserEmail(): string {
    if (this.getCookie(cookieSIMID) && this.getCookie(cookieSIMID).length > 0) {
      let userEmail = this.getCookie(cookieSIMID).substring(this.getCookie(cookieSIMID).indexOf('~*')+2, this.getCookie(cookieSIMID).indexOf('~&'));
      return userEmail;
    }
  }

  getuserPhone() {
    if (this.getCookie(cookieSIMID) && this.getCookie(cookieSIMID).length > 0) {
      let userPhone = this.getCookie(cookieSIMID).substring(this.getCookie(cookieSIMID).indexOf('~&') + 2);
      return userPhone;
    }
  }

  get isGlobalUser(): boolean {
    let isGlobalRole: boolean = false;
    let region = this.globalAppService.getDefaultRegionString();
    let appRolesList = this.globalAppService.getApprolesArray();
    if(region){
      globalUser[region.toLocaleUpperCase()].forEach(element => {
        if (appRolesList && appRolesList.indexOf(element) > -1 && this.getCookie(cookieSIMID)) {
          isGlobalRole = true;
        }
      });
    }    
    return isGlobalRole;
  }

  get isGlobalRoleAssigned(): boolean {
    let isGlobalRolePresent: boolean = false;
    let region = this.globalAppService.getDefaultRegionString();
    let appRolesList = this.globalAppService.getApprolesArray();
    if(region){
      globalUser[region.toLocaleUpperCase()].forEach(element => {
        if (appRolesList && appRolesList.indexOf(element) > -1) {
          isGlobalRolePresent = true;
        }
      });
    }    
    return isGlobalRolePresent;
  }


  get isInternalUser(): boolean {
    if (this.isGlobalUser && this.getCookie(cookieSIMID)) {
      return false;
    }
    if (this._userType == "C" || this._userType == "E") {
      return true;
    }
    else {
      return false;
    }
  }

  setLoadingStatus(loadingStatus: boolean) {
    this._isLoading.next(loadingStatus);
  }

  getLoadingStatus(): Observable<boolean> {
    return this._isLoading
  }

  set loadedHomeModal(loaded: boolean) {
    this._loadedHomeModal = loaded
  }

  get loadedHomeModal(): boolean {
    return this._loadedHomeModal;
  }

  getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
    }
    else {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) {
        end = dc.length;
      }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
  }

  getRedirectHome() {
    return this.redirectHome;
  }

  getActiveSubNav(): Observable<any> {
    return this.activesubNav;
  }
  updateNavStatus(updateSubanv: any) {
    this.activesubNav.next(updateSubanv);
  }
  
  getActiveTile(): Observable<any> {
    return this.activeTile;
  }
  updateTile(updateTile: any) {
    this.activeTile.next(updateTile);
  }

  setModalData(updateData: SearchModalData) {
    this._SearchSelectedModalData.next(updateData);
  }
  getModalData(): Observable<SearchModalData> {
    return this._SearchSelectedModalData;
  }

  
  setCvtViewOkta(userType, data) {
    // let arraymap = data['User_Favorate'];
    // let filteritem = arraymap.find((item) => {
    //   return (item.Fav_Type_Id == '200' && item.SalesOrg == '2811');
    // })
    // if (filteritem && userType == "C" || userType == "E") {
    // this.cvtView = (filteritem && filteritem.Favorite_Value && filteritem.Favorite_Value === '2' ? false : true);
    // }
    // else {
    //   this.cvtView = false;
    // }
    //set Favorite marked contract for the user



    this.cvtView = false;
    this.setFavoritePA();

    if (location.href && location.href.indexOf("/pa/details") > -1) {
      if (location.href.indexOf("/CVTYes") > -1 && this.isInternalUser) {
        if (this.globalAppService.getDefaultRegionString().toUpperCase() == 'LAO')
          this.setCVTView(false);
        else
          this.setCVTView(true);
      } else {
        this.setCVTView(false);
      }
    } else {
      this.setCVTView(this.cvtView)
    }

  }

  isCVTView(): boolean {
    return this.cvtView;
  }
  isCVTViewSubject(): Observable<any> {
    return this.cvtViewSubject.asObservable();
  }
  setCVTView(status) {
    if(this.isGlobalUser){
      status = false;
    }
    this.cvtViewSubject.next(status);
    this.cvtView = status;
  }

  forceDownloadCSV(recentlyCHangedDownload) {
    this.recentlyChangedDownload = recentlyCHangedDownload;
  }
  isForceDownloadCSV(): boolean {
    return this.recentlyChangedDownload;
  }

  oktaAuthRenewToken(token) {
    this._HTTPService.oktaAuthRenew(token).subscribe((profile: OktaProfile) => {
      // console.log("%%%%%%%%%%%%%okta renew token%%%%%%%%%%%%%%%%%%%%%", profile)
    })
  }

  //to dynamically import path for template override to show/hide sections in component
  dynamicImport(region: String): any {
    switch (region) {
      case 'NA':
        return import('@app/conf/component-overrides/regions/NA/override.ts');
      case 'LAO':
        return import('@app/conf/component-overrides/regions/LAO/override.ts');
      case 'APAC':
        return import('@app/conf/component-overrides/regions/APAC/override.ts');
      case 'EMEA':
        return import('@app/conf/component-overrides/regions/EMEA/override.ts');
    }
  }

  //Added for chargeback

  dynamicImportColumnDefCB(): any {
    return import('@app/conf/agColumnsConfigCB.ts');
  }

  //Added for chargeback---end

  //Added for MeetComp

  dynamicImportColumnDefMC(): any {
    return import('@app/conf/col-def-meetcomp/agColumnsConfigMCNA.ts');
  }

  //Added for MeetComp---end

  setDisableLink(value) {
    this._disableLink.next(value);
  }
  getDisableLink(): Observable<any> {
    return this._disableLink.asObservable();
  }

  getUserProfileStatus() {
    return this.isProfileSet;
  }

  isExternalAdminUser() {
    return this.isExternalAdminUserVar;
  }

  setExternalAdminUser(value) {
    this.isExternalAdminUserVar = value;
  }

  isIEBrowser(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return true
    {
      return true;
    }
    else  // If another browser, return false
    {
      return false;
    }
  }


  getDateForRequestParam(dateValue: any): string {
    let formattedDateValue: string = "";
    if (dateValue) {
      if (typeof dateValue === 'object') {
        formattedDateValue = moment(dateValue).format("MM/DD/yyyy");
      }
      else if (typeof dateValue === 'string' && dateValue.length > 10) {
        formattedDateValue = moment(dateValue.substring(0, 10), 'yyyy-MM-DD').format("MM/DD/yyyy");
      }
      else if (typeof dateValue === 'string' && dateValue.length === 10) {
        formattedDateValue = moment(dateValue.substring(0, 10), 'MM/DD/yyyy').format("MM/DD/yyyy");
      }
    }

    return formattedDateValue;
  }

  setTimeoutLoaderHide(value: any) {    
    this._timeOutLoaderHide.next(value);
  }

  gettTimeoutLoaderHide(): Observable<any> {
    return this._timeOutLoaderHide.asObservable();
  }

  setMenu(){
    this.globalAppService.getDefaultRegion().subscribe(region => {
      if (this.isInternalUser) {
        if (this.cvtView) {
          this.menu.next(ribbonMenuInternalCVT[region]);
        }
        else{
          this.menu.next(ribbonMenuInternalCustomer[region]);
        }
      } else if(this.isGlobalUser) {
        this.menu.next(ribbonMenuGlobal[region]);
      }
      else{
        this.menu.next(ribbonMenuExternal[region]);
      }
      return this.menu;
    })
  }

  getMenu(): Observable<any> {
    return this.menu;
  }
  
  setGTMDataLayer(oktaProfile : OktaProfile) {
    this.languageSelected.currentSelected.subscribe(isUserLoggedIn =>
      {
        this._dataLayerService.dimensions = {
          'event': !this.getCookie(cookieLoggedInUserId) ? 'login' : 'dayalayer-fill',
          'userId': this.getuserId(false),
          'user_type': this.isInternalUser ? 'internal' : 'external',
          'page_language': isUserLoggedIn.regionCode
        };
        this._dataLayerService.trigger();
      });
  }
  setFullStoryVariables(oktaProfile: OktaProfile) {
    this.languageSelected.currentSelected.subscribe(isUserLoggedIn =>
      {
        let userId = this.getuserId(false);
        this._fullStoryService.identify = userId;
        this._fullStoryService.dimensions = {
          'displayName': userId,
          'userType_str': this.isInternalUser ? 'internal' : 'external',
          'pageLanguage_str': isUserLoggedIn.regionCode
        };
        this._fullStoryService.trigger();
      });
  }
  setCountryURL(countryCode){
    let obj = countryOBJ.find((item) => {
        return item.countryID === countryCode.toLowerCase();
    });
    return obj.url;
  }

  appendQueryParamToLegacyUrl(url){
    let queryParam;
    let savedStatus = window.localStorage.getItem('profileSavedStatus');
    if(savedStatus) {
      queryParam = 'RefreshProfile=1';
    } else {
      queryParam = 'RefreshProfile=0';
    }
    let separator = (url.indexOf("?")===-1)?"?":"&";
    let returnUrl = url.trim() + separator + queryParam;
    return returnUrl;
  }

  setPopUpSequenceStatus(status) {
    this.popUpStatusSubject.next(status);
  }

  getPopUpSequenceStatus(): Observable<any> {
    return this.popUpStatusSubject.asObservable();
  }

  setPopupSequenceNumber(num:Array<number>) {
    this.popupSequenceNumber = [...num];
  }

  getPopupSequenceNumber() {
    this.popupSequenceNumberIndex++;
    if(this.popupSequenceNumber[this.popupSequenceNumberIndex]){
      return this.popupSequenceNumber[this.popupSequenceNumberIndex];
    }
    else{
      return null;
    }
  }

}

