/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./type-ahead-search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../sort-component/sort.component.ngfactory";
import * as i3 from "../../sort-component/sort.component";
import * as i4 from "../../../../services/cp-ag-grid-service";
import * as i5 from "../../../../../chargeback/Components/customer-eu-type-ahead/type-ahead/type-ahead.component.ngfactory";
import * as i6 from "../../../../../chargeback/Components/customer-eu-type-ahead/type-ahead/type-ahead.component";
import * as i7 from "../../../../../Shared/Services/http-charge-back.service";
import * as i8 from "../../../../../chargeback/Services/cb.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./type-ahead-search.component";
var styles_TypeAheadSearchComponent = [i0.styles];
var RenderType_TypeAheadSearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TypeAheadSearchComponent, data: {} });
export { RenderType_TypeAheadSearchComponent as RenderType_TypeAheadSearchComponent };
export function View_TypeAheadSearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "cp-sort-component", [], [[8, "hidden", 0]], [[null, "onSortRequested"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSortRequested" === en)) {
        var pd_0 = (_co.onSortRequested($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SortComponent_0, i2.RenderType_SortComponent)), i1.ɵdid(2, 114688, [["sortComponent", 4]], 0, i3.SortComponent, [i4.CpAgGridService], { colId: [0, "colId"], orgColId: [1, "orgColId"] }, { onSortRequested: "onSortRequested" }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-type-ahead", [], null, [[null, "selectedCustEU"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedCustEU" === en)) {
        var pd_0 = (_co.selectedCustomerEU($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_TypeAheadComponent_0, i5.RenderType_TypeAheadComponent)), i1.ɵdid(4, 114688, null, 0, i6.TypeAheadComponent, [i7.HttpChargeBackService, i8.CbService, i9.TranslateService], { optionData: [0, "optionData"], placeHolder: [1, "placeHolder"] }, { selectedCustEU: "selectedCustEU" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.colId; var currVal_2 = _co.orgColId; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.optionData; var currVal_4 = _co.placeHolder; _ck(_v, 4, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hideSort; _ck(_v, 1, 0, currVal_0); }); }
export function View_TypeAheadSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-type-ahead-search", [], null, null, null, View_TypeAheadSearchComponent_0, RenderType_TypeAheadSearchComponent)), i1.ɵdid(1, 49152, null, 0, i10.TypeAheadSearchComponent, [i8.CbService, i4.CpAgGridService], null, null)], null, null); }
var TypeAheadSearchComponentNgFactory = i1.ɵccf("app-type-ahead-search", i10.TypeAheadSearchComponent, View_TypeAheadSearchComponent_Host_0, {}, {}, []);
export { TypeAheadSearchComponentNgFactory as TypeAheadSearchComponentNgFactory };
