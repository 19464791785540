import { Injectable } from '@angular/core';
import { UserPrefrence, OktaProfile, DelegatedUserSearch } from '../DataModels/SharedDataModel';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { cookieSIMID, globalUser } from '@env/environment';
import { countryOBJ } from '@app/conf/countryURLConfig';
import {currencyArray} from '@app/conf/countryCurrencyConfig';
import * as currency from 'currency.js';

@Injectable({ providedIn: 'root' })
export class GlobalAppService {
    // private userPreferenceData: UserPrefrence;
    private userPreferenceData: BehaviorSubject<UserPrefrence> = new BehaviorSubject<UserPrefrence>(null);
    private regions: [];
    private region: string;
    public userRegions: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public userDefaultRegion: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    userDefaultRegionString: string;
    userSalesOrdID: string;

    public userDefaultLanguage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public userDefaultCountry: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public countryOnMenuChange: Subject<any> = new Subject<any>();
    public languageOnMenuChange: Subject<any> = new Subject<any>();
    public selectedCountryFromProfile: Subject<any> = new Subject<any>();

    userSearchSubject: BehaviorSubject<DelegatedUserSearch> = new BehaviorSubject<DelegatedUserSearch>(null);
    userSearchRequest: DelegatedUserSearch;

    appStartSubject: Subject<boolean> = new Subject<boolean>();
    noCountriesAvaiableSubject: Subject<boolean> = new Subject<boolean>();
    noLocationsAvaiable: boolean;
    noLocationsAvaiableSubject: Subject<boolean> = new Subject<boolean>();
    userDefaultCountryString: any;
    userDefaultLanguageString: string;
    defaultProfileLocation: any;

    defaultProfileLocationUpdated: Subject<any> = new Subject<any>();
    public masterDataDetails: Subject<any> = new Subject<any>();
    locationLanguageChanged: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public approles: Subject<any> = new Subject<any>();
    approlesArray: Array<any> = [];

    public runWalkMe: Subject<any> = new Subject<any>();
    userName: string;
    userProfileDetails: OktaProfile;
    userDetails;
    defaultCustomerInfo: any;
    _selectedRegion: any;
    currentCountry: any;
    currentLanguage: any;
    salesOrg: any;
    countryLangSelction: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    selectedLanguage: any;
    _cvtView: boolean;
    private cvtViewSubject = new Subject<any>();
    cvtView: any;
    countrySalesOrg: any;
    userDetailsLoaded: BehaviorSubject<any> = new BehaviorSubject<any>(true);
    isFirstTimeOnProfile: boolean;
    includeExpiryItemStatus: boolean;
    recentlyChangedDuration: any

    setUserDetailsLoaded(value) {
        this.userDetailsLoaded.next(value);
    }

    getUserDetailsLoaded(): Observable<any> {
        return this.userDetailsLoaded.asObservable();
    }

    isCVTView(): boolean {
        return this.cvtView;
    }

    isCVTViewSubject(): Observable<any> {
        return this.cvtViewSubject.asObservable();
    }

    setCVTView(status) {
        if(this.isGlobalUser){
            status = false;
        }
        this.cvtViewSubject.next(status);
        this.cvtView = status;
    }

    get isGlobalUser(): boolean {
        let isGlobalRole: boolean = false;
        let region = this.getDefaultRegionString();
        let appRolesList = this.getApprolesArray();
        if(region){
          globalUser[region.toLocaleUpperCase()].forEach(element => {
            if (appRolesList && appRolesList.indexOf(element) > -1 && this.getCookie(cookieSIMID)) {
              isGlobalRole = true;
            }
          });
        }    
        return isGlobalRole;
    }

    getCookie(name) {
        var dc = document.cookie;
        var prefix = name + "=";
        var begin = dc.indexOf("; " + prefix);
        if (begin == -1) {
          begin = dc.indexOf(prefix);
          if (begin != 0) return null;
        }
        else {
          begin += 2;
          var end = document.cookie.indexOf(";", begin);
          if (end == -1) {
            end = dc.length;
          }
        }
        // because unescape has been deprecated, replaced with decodeURI
        //return unescape(dc.substring(begin + prefix.length, end));
        return decodeURI(dc.substring(begin + prefix.length, end));
      }

    // set cvtView(value: boolean) {
    //     this._cvtView = value;
    // }

    // get cvtView(): boolean {
    //     return this._cvtView;
    // }

    setLocationLanguage(value: any) {
        this.locationLanguageChanged.next(value);
    }

    getLocationLanguage(): Observable<any> {
        return this.locationLanguageChanged.asObservable();
    }

    getRegions(): Observable<any> {
        return this.userRegions.asObservable();
    }

    setRegions(regions) {
        this.userRegions.next(regions);
    }

    setUserPreferenceData(userPreferenceData: UserPrefrence) {
        // this.userPreferenceData = Object.assign({}, userPreferenceData);
        let myObj = Object.assign({}, userPreferenceData);
        this.userPreferenceData.next(myObj);
    }

    getUserPreferenceData(): Observable<UserPrefrence> {
        return this.userPreferenceData;
    }

    setDefaultCustomerInfo(defaultCustomerInfo) {
        this.defaultCustomerInfo = defaultCustomerInfo;
    }

    getDefaultCustomerInfo() {
        return this.defaultCustomerInfo;
    }

    getDefaultRegion(): Observable<any> {
        return this.userDefaultRegion.asObservable();
    }


    getDefaultRegionString(): string {
        return this.userDefaultRegionString;
    }

    setDefaultRegion(region: string) {
        let storedRegion = localStorage.getItem('default-region');
        if (region) {
            this.userDefaultRegion.next(region);
            this.userDefaultRegionString = region;
        } else if (storedRegion) {
            this.userDefaultRegion.next(storedRegion);
            this.userDefaultRegionString = storedRegion;
        }

    }

    setDefaultLanguage(lang: string) {
        this.userDefaultLanguage.next(lang);
        this.userDefaultLanguageString = lang;
        localStorage.setItem('country-lang',lang);
    }

    getDefaultLanguageString() {
        return this.userDefaultLanguageString;
    }

    getDefaultLanguage(): Observable<any> {
        return this.userDefaultLanguage.asObservable();
    }

    setDefaultCountry(country: string) {
        this.userDefaultCountry.next(country);
        this.userDefaultCountryString = country;
        localStorage.setItem('country',country);
    }

    getDefaultCountryString() {
        return this.userDefaultCountryString;
    }

    getDefaultCountry(): Observable<any> {
        return this.userDefaultCountry.asObservable();
    }

    setUserNameString(name: string) {
        this.userName = name;
    }

    getUserNameString() {
        return this.userName;
    }

    setAppStart(value: boolean) {
        this.appStartSubject.next(value);
    }

    getAppStart() {
        return this.appStartSubject.asObservable();
    }

    setdefaultProfileLocationUpdated(value: boolean) {
        this.defaultProfileLocationUpdated.next(value);
    }

    getdefaultProfileLocationUpdated() {
        return this.defaultProfileLocationUpdated.asObservable();
    }

    setCountryFromMenu(country) {
        this.countryOnMenuChange.next(country);
        localStorage.setItem('country',country)
    }

    getCountryFromMenu(): Observable<any> {
        return this.countryOnMenuChange.asObservable();
    }

    setLangFromMenu(langauage) {
        this.selectedLanguage = langauage;
        this.languageOnMenuChange.next(langauage);
        localStorage.setItem('country-lang',langauage)
    }

    getLangFromMenu(): Observable<any> {
        return this.languageOnMenuChange.asObservable();
    }

    getCurrenLanguage() {
        return this.selectedLanguage;
    }

    setMasterDataDetails(data) {
        this.masterDataDetails.next(data);
    }

    getMasterDataDetails(): Observable<any> {
        return this.masterDataDetails.asObservable();
    }

    isBlackListedCVTUrl(){
        let blacklistCVTurl = [
            '/pa/details',
            '/meetcomps/',
            // '/home'
        ];
        let blackListedURL = false;
        for(let ctr=0 ; ctr < blacklistCVTurl.length; ctr++ ){
            if(location.href.indexOf(blacklistCVTurl[ctr]) > -1){
                blackListedURL = true;
                break;
            }
        }
        return blackListedURL;
    }

    setUserDetails(usseDetails) {
        this.userDetails = usseDetails;
        // this.setCVTView(usseDetails && usseDetails.preferredView ? usseDetails.preferredView.toLowerCase() === 'cvt' ? true : false : false);
        let cvtView = usseDetails && usseDetails.preferredView ? usseDetails.preferredView.toLowerCase() === 'cvt' ? true : false : false;
        this.isBlackListedCVTUrl();
        // if (location.href && location.href.indexOf("/pa/details") > -1) {
        if (location.href && this.isBlackListedCVTUrl()) {
            if (location.href.indexOf("/CVTYes") > -1) {
                if (this.getDefaultRegionString().toUpperCase() == 'LAO') {
                    this.setCVTView(false)
                }
                else {
                    this.setCVTView(true)
                };
            }
            else {
                this.setCVTView(false);
            }
        }
        else {
            this.setCVTView(cvtView)
        }

        this.setSalesOrg(usseDetails.preferredSalesOrg || usseDetails.salesOrg);
    }

    getUserDetails() {
        return this.userDetails;
    }

    defaultDetailsAvailable() { }

    startWalkMe() {
        this.runWalkMe.next(true);
    }
    notifyWalkMeStart(): Observable<any> {
        return this.runWalkMe.asObservable();
    }

    setUserProfileDetails(userDetails: OktaProfile) {
        this.userProfileDetails = userDetails;
    }

    getUserProfileDetails(): OktaProfile {
        return this.userProfileDetails;
    }

    setApproles(approles) {
        this.approlesArray = approles;
        this.approles.next(approles);
    }

    getApproles(): Observable<any> {
        return this.approles.asObservable();
    }

    getApprolesArray() {
        return this.approlesArray;
    }

    setDefaultProfileLocation(location: any) {
        this.defaultProfileLocation = location;
    }

    getDefaultProfileLocation() {
        return this.defaultProfileLocation;
    }

    setSalesOrg(id) {
        this.userSalesOrdID = id;
    }

    getSalesOrg() {
        return this.userSalesOrdID;
    }

    // Please DO nOT user below setters for sales or - it is for future purpose
    setCountrySalesOrg(id) {
        this.countrySalesOrg = id;
    }

    getCountrySalesOrg() {
        return this.countrySalesOrg;
    }


    get selectedRegion() {
        return this._selectedRegion;
    }

    set selectedRegion(value: string) {
        this._selectedRegion = value;
    }

    getIsCountryLangSelctionHidden(): Observable<any> {
        return this.countryLangSelction.asObservable();
    }

    setIsCountryLangSelctionHidden(value) {
        this.countryLangSelction.next(value);
    }

    setSelectedCountryLang(country, lang, salesOrg) {
        this.currentCountry = country;
        this.currentLanguage = lang;
        this.salesOrg = salesOrg;
    }

    getSelectedCountryLang() {
        return {
            'country': this.currentCountry,
            'language': this.currentLanguage || this.getDefaultLanguageString(),
            'salesOrg': this.salesOrg
        }
    }

    updateDelegatedUserSearch(value: DelegatedUserSearch) {
        this.userSearchSubject.next(value);
        this.userSearchRequest = value;
    }

    getDelegatedUserSearch(): Observable<DelegatedUserSearch> {
        return this.userSearchSubject.asObservable();
    }

    getDelegatedUserSearchRequest(): DelegatedUserSearch {
        return this.userSearchRequest;
    }

    setSelectedCountryFromProfile(country) {
        this.selectedCountryFromProfile.next(country);
    }

    getSelectedCountryFromProfile(): Observable<any> {
        return this.selectedCountryFromProfile.asObservable();
    }

    setNoCountriesAvaiable(value: boolean) {
        this.noCountriesAvaiableSubject.next(value);
    }

    getNoCountriesAvaiable() {
        return this.noCountriesAvaiableSubject.asObservable();
    }

    setNoLocationsAvaiable(value: boolean) {
        this.noLocationsAvaiable = value;
        this.noLocationsAvaiableSubject.next(value)
    }

    getNoLocationsAvaiable() {
        return this.noLocationsAvaiable;
    }

    getNoLocationSubscription() {
        return this.noLocationsAvaiableSubject.asObservable();
    }

    setIsFirstTimeOnProfile(value) {
        this.isFirstTimeOnProfile = value;
    }

    getIsFirstTimeOnProfile() {
        return this.isFirstTimeOnProfile;
    }

    setCountryURL(countryCode){
        let obj = countryOBJ.find((item) => {
            return item.countryID === countryCode.toLowerCase();
        });
        return obj.url;
    }

    setIncludeExpiryItemStatus(status){
        this.includeExpiryItemStatus = status;
    }

    getIncludeExpiryItemStatus(){
        return this.includeExpiryItemStatus;
    }

    setRecentlyChangedDuration(duration){
        this.recentlyChangedDuration = duration;
    }

    getRecentlyChangedDuration(){
        return this.recentlyChangedDuration;
    }

    /*
    ** function use  : getNumberCurrencyFormatted
    parameters: 
    languageCode = 'en-US'
    value = value to be formatted
    type = 'NUMBER' or 'CURRENCY'
    precisionValue = 0 by default else pass any numeric precision value required
    */
    getNumberCurrencyFormatted(languageCode : string, value:any, type:string,precisionValue : number = 0){
        let country = 'US';
        if(languageCode){
            if(languageCode.toUpperCase().indexOf('-CA') > -1){
                country = languageCode;
            }
            else{
                country = languageCode.split('-')[1];
            }
        }
        
        const currencyConfig = currencyArray.find(item => {
            return item.code.toUpperCase() == country.toUpperCase();
        });
        if(currencyConfig && value){
           
            // const decimalValue = this.whatIsTheDecimalSeperator(value);
            // const decimalValue = currencyConfig.decimal;
            // if(decimalValue && decimalValue =='.')
            // {
            //     value = value.toString().replace(/,/g,'');
            // }
            // else if(decimalValue && decimalValue ==',') {
            //     value = value.toString().replace(/\./g,'');
            //     value = value.toString().replace(',','.');
            // }

            if(type.toUpperCase() == 'NUMBER'){
                const val =  currency(parseFloat(value), { separator: currencyConfig.separator, decimal: currencyConfig.decimal, precision: precisionValue, symbol: '' }).format();
                return val;
            }
            else{
                const val =   currency(parseFloat(value), { separator: currencyConfig.separator, decimal: currencyConfig.decimal, precision: precisionValue, symbol: currencyConfig.symbol }).format();
                return val;
            } 
        }
        else{
            if(type.toUpperCase() == 'NUMBER'){
                const val =   currency(parseFloat(value), { separator: ',', decimal: '.', precision: precisionValue, symbol: '' }).format();
                return val;
            }
            else{
                const val =   currency(parseFloat(value), { separator: ',', decimal: '.', precision: precisionValue, symbol: currencyConfig.symbol }).format();
                return val;
            }  
        }
    }

    whatIsTheDecimalSeperator(value:any)
    {
        if(value)
        {
            let realValue = value.toString().trim();
            if(realValue.indexOf("-")>-1)
            {
                realValue = realValue.replace('-','');
            }
            const maxLength = realValue.length;
            
            if(realValue.lastIndexOf(',') == -1 && realValue.lastIndexOf('.') == -1 )
            {
                return null;
            }
            else if ( maxLength - realValue.lastIndexOf(',') == 3)
            {
                return ',';
            }
            else {
                return '.';
            }
        }
        else
        {
            return null;
        }
        
        
    }
}