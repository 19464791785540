/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./myLocation-internal-cr.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./myLocation-Internal-cr.component";
import * as i4 from "../../../services/cp-ag-grid-service";
import * as i5 from "../../../../my-profile/Components/services/my-profile-service";
var styles_LocationCRComponent = [i0.styles];
var RenderType_LocationCRComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocationCRComponent, data: {} });
export { RenderType_LocationCRComponent as RenderType_LocationCRComponent };
function View_LocationCRComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "input", [["class", "custom-control-input"], ["type", "checkbox"]], [[8, "checked", 0], [8, "disabled", 0], [8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.checkboxClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "newly-added-loc": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "label", [["class", "custom-control-label"]], [[8, "htmlFor", 0]], null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "newly-added-loc": 0 }), (_l()(), i1.ɵted(7, null, [" ", " (", ") "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "custom-control-input"; var currVal_4 = _ck(_v, 3, 0, _co.highlightChild); _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_6 = "custom-control-label"; var currVal_7 = _ck(_v, 6, 0, _co.highlightChild); _ck(_v, 5, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.check; var currVal_1 = _co.isCheckboxDisabled; var currVal_2 = _co.checkboxName; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _co.checkboxName; _ck(_v, 4, 0, currVal_5); var currVal_8 = _co.currentNode.data.name; var currVal_9 = _co.currentNode.data.customerNumber; _ck(_v, 7, 0, currVal_8, currVal_9); }); }
function View_LocationCRComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "input", [["class", "custom-control-input"], ["type", "checkbox"]], [[8, "checked", 0], [8, "disabled", 0], [8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.checkboxClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "newly-added-loc": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "label", [["class", "custom-control-label"]], [[8, "htmlFor", 0]], null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "newly-added-loc": 0 }), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "custom-control-input"; var currVal_4 = _ck(_v, 3, 0, _co.highlightGroup); _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_6 = "custom-control-label"; var currVal_7 = _ck(_v, 6, 0, _co.highlightGroup); _ck(_v, 5, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.check; var currVal_1 = _co.isCheckboxDisabled; var currVal_2 = _co.checkboxName; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _co.checkboxName; _ck(_v, 4, 0, currVal_5); var currVal_8 = _co.currentNode.data.parentCustomer; _ck(_v, 7, 0, currVal_8); }); }
export function View_LocationCRComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationCRComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationCRComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentNode.data.name; _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.currentNode.data.name && _co.currentNode.data.parentCustomer); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LocationCRComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-myLocation-internal-cr", [], null, null, null, View_LocationCRComponent_0, RenderType_LocationCRComponent)), i1.ɵdid(1, 245760, null, 0, i3.LocationCRComponent, [i4.CpAgGridService, i5.MyProfileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocationCRComponentNgFactory = i1.ɵccf("app-myLocation-internal-cr", i3.LocationCRComponent, View_LocationCRComponent_Host_0, {}, {}, []);
export { LocationCRComponentNgFactory as LocationCRComponentNgFactory };
