<ng-container *ngIf="!isMCCustomerCol; else MCCustomerColBlock">
  <a *ngIf="hideLink" href="javascript:void(0)" (click)="onLinkClick()" >
    {{FormattedValue || params.valueFormatted || params.value}}
  </a>
</ng-container>


<ng-template #MCCustomerColBlock>
  <a *ngIf="hideLink" href="javascript:void(0)" (click)="onLinkClick()">
    {{'agGrid.agGridColumns.customerDetails' | translate}}
  </a>
</ng-template>

