/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pa-split-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./pa-split-details.component";
import * as i3 from "@angular/router";
var styles_PaSplitDetailsComponent = [i0.styles];
var RenderType_PaSplitDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaSplitDetailsComponent, data: {} });
export { RenderType_PaSplitDetailsComponent as RenderType_PaSplitDetailsComponent };
export function View_PaSplitDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.show() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "textUnderlined"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 2, 0, currVal_0); }); }
export function View_PaSplitDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pa-split-details", [], null, null, null, View_PaSplitDetailsComponent_0, RenderType_PaSplitDetailsComponent)), i1.ɵdid(1, 180224, null, 0, i2.PaSplitDetailsComponent, [i3.ActivatedRoute, i3.Router], null, null)], null, null); }
var PaSplitDetailsComponentNgFactory = i1.ɵccf("pa-split-details", i2.PaSplitDetailsComponent, View_PaSplitDetailsComponent_Host_0, {}, {}, []);
export { PaSplitDetailsComponentNgFactory as PaSplitDetailsComponentNgFactory };
