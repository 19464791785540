/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./myprofile-addto-preference.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./myprofile-addto-preference.component";
import * as i3 from "../../../../my-profile/Components/services/my-profile-service";
import * as i4 from "../../../../Shared/Services/global-app.service";
import * as i5 from "../../../services/cp-ag-grid-service";
var styles_MyprofileAddtoPreferenceComponent = [i0.styles];
var RenderType_MyprofileAddtoPreferenceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MyprofileAddtoPreferenceComponent, data: {} });
export { RenderType_MyprofileAddtoPreferenceComponent as RenderType_MyprofileAddtoPreferenceComponent };
export function View_MyprofileAddtoPreferenceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "location-prefernces"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["class", "custom-control-input location-external-pref"], ["type", "checkbox"]], [[8, "checked", 0], [8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.checkboxClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [["class", "custom-control-label pa-label pa-label-external"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.check; var currVal_1 = _co.checkboxName; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.checkboxName; _ck(_v, 2, 0, currVal_2); }); }
export function View_MyprofileAddtoPreferenceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-myprofile-addto-preference", [], null, null, null, View_MyprofileAddtoPreferenceComponent_0, RenderType_MyprofileAddtoPreferenceComponent)), i1.ɵdid(1, 245760, null, 0, i2.MyprofileAddtoPreferenceComponent, [i3.MyProfileService, i4.GlobalAppService, i5.CpAgGridService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MyprofileAddtoPreferenceComponentNgFactory = i1.ɵccf("app-myprofile-addto-preference", i2.MyprofileAddtoPreferenceComponent, View_MyprofileAddtoPreferenceComponent_Host_0, {}, {}, []);
export { MyprofileAddtoPreferenceComponentNgFactory as MyprofileAddtoPreferenceComponentNgFactory };
