import * as tslib_1 from "tslib";
import { OnDestroy, ViewRef, ChangeDetectorRef } from '@angular/core';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { TranslateService } from '@ngx-translate/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { Calendar } from 'primeng/calendar';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
var DateSelectorComponent = /** @class */ (function () {
    function DateSelectorComponent(translateServ, languageServ, appServ, cd, globalAppService, gridService) {
        this.translateServ = translateServ;
        this.languageServ = languageServ;
        this.appServ = appServ;
        this.cd = cd;
        this.globalAppService = globalAppService;
        this.gridService = gridService;
        this.maxDateValue = new Date();
        this.filterModel = { type: "date-selector", value: this.txtDate };
        this.isDateSelected = false;
        this.subscription = new Subscription();
    }
    DateSelectorComponent.prototype.agInit = function (params) {
        var _this = this;
        this.region = this.globalAppService.getDefaultRegionString();
        this.params = params;
        this.orgColId = params.column.getColId();
        this.colId = params.colDef.field;
        this.headerName = params.colDef.headerName;
        this.valueGetter = params.valueGetter;
        this.colId = this.colId.indexOf('_') !== -1 ? this.colId.substring(0, this.colId.indexOf('_')) : this.colId;
        if (this.colId !== 'endDate' && this.colId !== 'customerEndDate' && this.colId !== 'availableUntil' && this.colId !== 'passwordExpiryDate' && this.colId !== 'startDate') {
            if (this.region == 'NA') {
                //below 'validFrom' and 'validTo' is not unique colId, so comparing using headerName
                if (this.colId !== 'availableDate' && this.headerName !== 'agGrid.agGridColumns.MCValidFrom' && this.headerName !== 'agGrid.agGridColumns.MCValidTo') {
                    this.maxDateValue.setDate(this.maxDateValue.getDate());
                }
                else {
                    this.maxDateValue = null;
                }
            }
            else {
                this.maxDateValue.setDate(this.maxDateValue.getDate());
            }
        }
        else
            this.maxDateValue = null;
        this.subscription.add(this.languageServ.currentSelected.subscribe(function (data) {
            _this.subscription.add(_this.translateServ.get(['calender'])
                .subscribe(function (translations) {
                _this.calenderData = translations['calender'];
                _this.dateFormat = _this.calenderData.format;
                _this.primengDateFormat = _this.calenderData.dateFormat.toLowerCase();
            }));
        }));
        this.params.api.setPopupParent(document.querySelector('body'));
    };
    DateSelectorComponent.prototype.getModel = function () {
        this.filterModel.value = this.txtDate;
        return this.filterModel;
    };
    DateSelectorComponent.prototype.setModel = function (model) {
        if (model && model.value) {
            this.txtDate = this.filterModel.value = model.value;
        }
        else {
            this.txtDate = null;
            this.filterModel.value = "";
        }
    };
    DateSelectorComponent.prototype.isFilterActive = function () {
        return this.txtDate && this.txtDate instanceof Date;
    };
    DateSelectorComponent.prototype.doesFilterPass = function (params) {
        return true;
    };
    // noinspection JSMethodCanBeStatic
    DateSelectorComponent.prototype.componentMethod = function (message) {
        alert("Alert from DateSelectorComponent " + message);
    };
    DateSelectorComponent.prototype.onDateSelected = function (event) {
        this.isDateSelected = true;
    };
    DateSelectorComponent.prototype.onApplyClick = function (event, cpContainer) {
        if (this.isDateSelected) {
            this.params.filterChangedCallback();
            this.guiParams.hidePopup();
            this.isDateSelected = false;
        }
        cpContainer.classList.remove('date_range--popup');
    };
    DateSelectorComponent.prototype.afterGuiAttached = function (params) {
        var _this = this;
        this.subscription.add(this.languageServ.currentSelected.subscribe(function (data) {
            _this.subscription.add(_this.translateServ.get(['calender'])
                .subscribe(function (translations) {
                _this.calenderData = translations['calender'];
                _this.dateFormat = _this.calenderData.format;
                _this.primengDateFormat = _this.calenderData.dateFormat.toLowerCase();
            }));
        }));
        if (this.calendarElement && this.calendarElement.inputfieldViewChild)
            this.calendarElement.inputfieldViewChild.nativeElement.placeholder = this.dateFormat;
        this.guiParams = params;
        this.subscription.add(this.languageServ.currentSelected.subscribe(function (data) {
            _this.subscription.add(_this.translateServ.get(['calender'])
                .subscribe(function (translations) {
                _this.calenderData = translations['calender'];
                _this.dateFormat = _this.calenderData.format;
                _this.primengDateFormat = _this.calenderData.dateFormat.toLowerCase();
            }));
        }));
    };
    DateSelectorComponent.prototype.onSortRequested = function (isSortedRequest) {
        if (!!isSortedRequest) {
            this.guiParams.hidePopup();
        }
    };
    DateSelectorComponent.prototype.onCalFocus = function (event) {
        event.classList.add('date_range--popup');
        /** To render UI quickly in IE */
        if (this.appServ.isIEBrowser()) {
            if (this.cd && !this.cd.destroyed) {
                this.cd.detectChanges();
            }
        }
    };
    DateSelectorComponent.prototype.onShow = function () {
        console.log("@@@@ in On Show:  ");
    };
    DateSelectorComponent.prototype.onKeyPress = function (event, container) {
        if (event.code == 'Enter') {
            this.onApplyClick(event, container);
        }
    };
    DateSelectorComponent.prototype.ngOnDestroy = function () {
        // Required for unsubscribing and destroying the component  
    };
    DateSelectorComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [TranslateService,
            LanguageSelectionService,
            AppService,
            ChangeDetectorRef,
            GlobalAppService,
            CpAgGridService])
    ], DateSelectorComponent);
    return DateSelectorComponent;
}());
export { DateSelectorComponent };
