import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { HttpService } from './../../../../Shared/Services/http.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
var RecentDownloadDocumentComponent = /** @class */ (function () {
    function RecentDownloadDocumentComponent(httpService, appServ, route, router) {
        this.httpService = httpService;
        this.appServ = appServ;
        this.route = route;
        this.router = router;
        this.subscription = new Subscription();
    }
    RecentDownloadDocumentComponent.prototype.agInit = function (params) {
        this.params = params;
        this.documentId = params.data ? params.data.id : "";
    };
    RecentDownloadDocumentComponent.prototype.refresh = function (params) {
        return false;
    };
    RecentDownloadDocumentComponent.prototype.onLinkClick = function (event) {
        var _this = this;
        if (event.srcElement.id) {
            this.documentId = event.srcElement.id;
            this.subscription.add(this.httpService.downloadSelectedRecentDownload(this.documentId, this.appServ.isGlobalUser).subscribe(function (res) {
                if (res && res.fileUrl) {
                    var downloadResource_1 = res.fileUrl;
                    _this.router.navigate([{ outlets: { dialogs: null } }]).then(function () {
                        _this.router.navigate([{ outlets: { dialogs: ['downloadLinkModal'] } }], { relativeTo: _this.route.parent, state: { url: downloadResource_1, title: '' } });
                    });
                }
            }));
        }
    };
    RecentDownloadDocumentComponent.prototype.ngOnDestroy = function () {
        // Required for unsubscribing and destroying the component  
    };
    RecentDownloadDocumentComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [HttpService,
            AppService,
            ActivatedRoute,
            Router])
    ], RecentDownloadDocumentComponent);
    return RecentDownloadDocumentComponent;
}());
export { RecentDownloadDocumentComponent };
