import { Component, OnInit, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { UpdateEfileKcfileAccess } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { singelActionOptions, siteAdminRoles } from '@app/conf/dataConfigurations';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { TranslateService } from '@ngx-translate/core';
import { EfileAccessService } from './../../../../../SiteAdmin/Modules/efile-kcfile-access/services/efile-access.service';

@Component({
  selector: 'app-ea-dropdown',
  templateUrl: './ea-dropdown.component.html',
  styleUrls: ['./ea-dropdown.component.scss']
})
export class EaDropdownComponent implements AgRendererComponent, OnDestroy {

  params: any;
  singelActionOptions: any = [];
  singelActionOptionsOriginal: any = [];
  selectedValue: boolean = true;
  updateRequest: UpdateEfileKcfileAccess = new UpdateEfileKcfileAccess();

  isAdminRoleModify:boolean = false;
  
  constructor(
    private httpServe: HttpService,
    private gridService: CpAgGridService,
    private appServe: AppService,
    private _globalAppService: GlobalAppService,
    private translateServ: TranslateService,
    private efileAccessServe: EfileAccessService
  ) { }

  ngOnInit() {    
    this.isAdminRoleModify  = this.efileAccessServe.setAdminRole();
  }

  agInit(params: any): void {
    this.singelActionOptions = this.singelActionOptionsOriginal = singelActionOptions;
    this.params = params;
    this.selectedValue = this.params.value;
    console.log(this.singelActionOptions)
  }

  refresh(params: any): boolean {
    return false;
  }

  onChange(event) {
    console.log("this.params.value : ",this.params.value)
    this.selectedValue = event.value;
    console.log(this.selectedValue)
    this.updateRequest.updateAccessRequestItems = [
      { customerNumber: this.params.data.customerNumber, access: this.selectedValue }
    ];
    this.appServe.setLoadingStatus(true);
    
    this.httpServe.updateEFileAccess(this.updateRequest).subscribe((data) => {
      if (this.gridService.pageContext.pageName === 'efileAccessPage') {
        this.gridService.getMultiselectDistinctFilterOptions(this.gridService.oRequest, this.gridService.gridName, "EA");
      }
      this.params.value = this.selectedValue;
      this.appServe.setLoadingStatus(false);
    },
      (error) => {
        this.selectedValue = this.params.value;
        this.appServe.setLoadingStatus(false);
      })
  }

  ngOnDestroy() {

  }

}
