import { Component, OnInit } from '@angular/core';
import { createProductCodeLink } from "@app/conf/appLink";
import { AgRendererComponent } from 'ag-grid-angular';
import { SPA_LEGACY_MENU_HOST } from '@env/environment';

@Component({
  selector: 'app-padetails-hyper-link',
  templateUrl: './padetails-hyper-link.component.html',
  styleUrls: ['./padetails-hyper-link.component.scss']
})
export class PadetailsHyperLinkComponent {

  constructor() { }
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
  onLinkClick() {
    window.open(SPA_LEGACY_MENU_HOST + this.params.data.contractNumber + "/" + (this.params.data.customerNumber === "0000000000" ? 'All' : this.params.data.customerNumber) + "/" + this.params.data.statusId + "/CVTYes/?custName=", "_self")
  }

}
