<span class="custom-table__popup custom-table__favorite" *ngIf="!showTooltipForCB">
  <span class="custom-table__customer custom-table__popup__trigger" (mouseenter)="onCustomerLinkHover($event, op)"
    (mouseleave)="onCustomerLinkMouseout(op)" (click)="onLinkClick($event)">
    <span *ngIf="params.value == 'Multiple'">{{"priceagreement.myWorkSpace.recentDownloads.multipleLabel" | translate}}</span>
    <span *ngIf="params.value != 'Multiple'">{{params.value}}</span>
    <!-- <ng-container>
      <span [ngClass]="custIdfavoriteCSS" (click)="onCustomerFavClick(params)"></span>
</ng-container> -->
</span>
</span>

<span class="custom-table__popup custom-table__favorite" *ngIf="showTooltipForCB">
  <span class="custom-table__popup__trigger"
    [ngClass]="{'custom-table__customer' : (((params.value == 'PD' || params.value == 'Pending') && region != 'EMEA') || params.colDef.headerName == 'agGrid.agGridColumns.customerNumberLabel'), 'denied-red': params.value == 'B'}"
    (mouseenter)="onCustomerLinkHover($event, op)" (mouseleave)="onCustomerLinkMouseout(op)">{{ 
      statusCBvalueToShow ? (statusCBvalueToShow.label | translate) : params.value
    }}
  </span>

</span>



<p-overlayPanel appendTo="body" [hideTransitionOptions]="'0ms'" [showTransitionOptions]="'500ms'" #op>
    <span class="custom-table__popup__info" *ngIf="!showTooltipForCB">
    <p>{{ customerName }}</p>
    <p>{{ street }} {{ city }} {{ district }} {{ country }} {{ zipcode }}</p>
  </span>

    <span class="custom-table__popup__info" *ngIf="showTooltipForCB && region != 'EMEA'">     
      <p [innerHTML]="searchResultsCBToolTip"></p>     
    </span>

    <span class="custom-table__popup__info" *ngIf="showTooltipForCB && region == 'EMEA' && !(params.value == 'PD' || params.value == 'Pending')">     
      <p [innerHTML]="searchResultsCBToolTip"></p>     
    </span>
</p-overlayPanel>



<ng-container *ngIf="showModal">
    <download-customer-list [visibleCustModal]="showModal" [searchObj]="downloadCustomerListRequest" [downloadType]="downloadType" (closedCustModal)="closedCustModal($event)">
    </download-customer-list>
</ng-container>