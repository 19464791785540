import * as i0 from "@angular/core";
var RegionCheckService = /** @class */ (function () {
    function RegionCheckService() {
    }
    Object.defineProperty(RegionCheckService.prototype, "userRegion", {
        get: function () {
            return this.region;
        },
        set: function (region) {
            this.region = region;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegionCheckService.prototype, "devEndPoints", {
        get: function () {
            return this.endPoints;
        },
        set: function (endPoints) {
            this.endPoints = endPoints;
        },
        enumerable: true,
        configurable: true
    });
    RegionCheckService.ngInjectableDef = i0.defineInjectable({ factory: function RegionCheckService_Factory() { return new RegionCheckService(); }, token: RegionCheckService, providedIn: "root" });
    return RegionCheckService;
}());
export { RegionCheckService };
