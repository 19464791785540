import { Component, OnDestroy, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription, ObjectUnsubscribedError } from 'rxjs';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { MyProfileService } from '@app/Modules/my-profile/Components/services/my-profile-service';

@AutoUnsubscribe()

@Component({
  selector: 'app-myprofile-priceagreement',
  templateUrl: './myprofile-priceagreement.component.html',
  styleUrls: ['./myprofile-priceagreement.component.scss']
})
export class MyprofilePriceagreementComponent implements AgRendererComponent, OnInit, OnDestroy {

  currentNode: any;
  subscription: Subscription = new Subscription();
  checkboxNamePA: string;
  checkboxNameMC: string;
  checkboxNameCB: string;
  checkboxNameIN: string;
  checkboxNameOD: string;
  checkStatusPA: boolean = false;
  checkStatusMC: boolean = false;
  checkStatusCB: boolean = false;
  checkStatusIN: boolean = false;
  checkStatusOD: boolean = false;
  saveChangesObj : any;
  totalNumberOfRecord: number = 0;
  numberOfPASelected: number = 0;

  constructor(
    private agGridService: CpAgGridService,
    private myProfileService: MyProfileService
  ) { }

  agInit(params: any): void {
    this.currentNode = params;

    this.checkboxNamePA = "PA-" + params.data.customerNumber;//Math.random().toString(36).substring(2, 15);
    this.checkboxNameMC = "MC-" + params.data.customerNumber;//Math.random().toString(36).substring(2, 15);
    this.checkboxNameCB = "CB-" + params.data.customerNumber;//Math.random().toString(36).substring(2, 15);
    this.checkboxNameIN = "IN-" + params.data.customerNumber;//Math.random().toString(36).substring(2, 15);
    this.checkboxNameOD = "OD-" + params.data.customerNumber;//Math.random().toString(36).substring(2, 15);

    if(this.currentNode && this.currentNode.api){
      this.currentNode.api.forEachNode((node, index) => {
        if (params && params.data && params.data.Preferences) {
          params.data.Preferences.forEach(prefernce => {
            if(prefernce.isSelected){
              switch(prefernce.moduleId){
                case 1: // Price Aggrement
                        this.checkStatusPA = true;
                        break;
                case 2:// Meet Comp
                        this.checkStatusMC = true;
                        break;
                case 3: // ChargeBack
                        this.checkStatusCB = true;
                        break;
                case 4: // Invoices
                        this.checkStatusIN = true;
                        break;
                case 5: // Orders
                        this.checkStatusOD = true;
                        break;
              }
            }
          });
        }
      })
    }
  }

  ngOnInit() {
    
  }

  refresh(params: any): boolean {
    return false;
  }

  checkboxClick(event) {
    if(event.target.checked){
      let prefCheckboxName = "ATP-" + this.currentNode.data.customerNumber;
      this.setCheckboxStatus(prefCheckboxName, event.target.checked);
      this.currentNode.data.isDefault = event.target.checked;
      let isAllPrefChecked = this.preferenceHeaderCheckboxState();
      this.setCheckboxStatus("addToPrefCheckBoxHeader", isAllPrefChecked);
    }    
    this.currentNode.data.Preferences.forEach(Preference => {
      if(Preference.moduleId == this.currentNode.colDef.appFeatureId ){
        Preference.isSelected = event.target.checked;
      }
    });
    switch(this.currentNode.colDef.appFeatureId){
      case 1: // Price Aggrement
              this.checkStatusPA = event.target.checked;
              let isAllPAchecked = this.autoHeaderSelection(1);
              this.setCheckboxStatus("PACheckBoxHeader", isAllPAchecked);
              break;
      case 2:// Meet Comp
              this.checkStatusMC = event.target.checked;
              let isAllMCchecked = this.autoHeaderSelection(2);
              this.setCheckboxStatus("MCCheckBoxHeader", isAllMCchecked);
              break;
      case 3: // ChargeBack
              this.checkStatusCB = event.target.checked;
              let isAllCBchecked = this.autoHeaderSelection(3);
              this.setCheckboxStatus("CBCheckBoxHeader", isAllCBchecked);
              break;
      case 4: // Invoices
              this.checkStatusIN = event.target.checked;
              let isAllINchecked = this.autoHeaderSelection(4);
              this.setCheckboxStatus("INCheckBoxHeader", isAllINchecked);
              break;
      case 5: // Orders
              this.checkStatusOD = event.target.checked;
              let isAllODchecked = this.autoHeaderSelection(5);
              this.setCheckboxStatus("ODCheckBoxHeader", isAllODchecked);
              break;
    }
  }

  autoHeaderSelection(moduleId: number){
    let isAllchecked = true;
    let recordCount = 0;
    this.agGridService.gridApi.forEachNode((node) => {
      if(node.data && node.data.customerNumber){
        recordCount ++;
        let selected = node.data.Preferences.find(x => {
            return x.moduleId == moduleId;
        }).isSelected;
        if(!selected){
          isAllchecked = false;
        }
      }
    });
    if(recordCount)
        return isAllchecked;
      else
        return false;
  }

  setCheckboxStatus(checkboxName: string, status: boolean){
    let el: HTMLInputElement = <HTMLInputElement>document.getElementById(checkboxName);
    if(el){
        el.checked = status;
    }
  }

  preferenceHeaderCheckboxState(){
      let isAllchecked = true;
      let recordCount = 0;
      this.agGridService.gridApi.forEachNode((node) => {
        if (node.data) {
          recordCount++;
          let selected = node.data.isDefault;
          if (!selected) {
            isAllchecked = false;
          }
        }
      });
      if (recordCount)
        return isAllchecked;
      else
        return false;
  }

  ngOnDestroy() {
    // this.currentNode = null;
    // Required for unsubscribing and destroying the component  
  }

}
