import * as tslib_1 from "tslib";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import { OktaAuthService } from '@okta/okta-angular';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { cookieSIMID } from '@env/environment';
import { Guid } from "guid-typescript";
import { GlobalAppService } from './global-app.service';
import * as i0 from "@angular/core";
import * as i1 from "@okta/okta-angular";
import * as i2 from "./app.service";
import * as i3 from "@angular/common/http";
import * as i4 from "./global-app.service";
var AuthInterceptorService = /** @class */ (function () {
    function AuthInterceptorService(oktaAuth, _appServ, http, globalAppService) {
        this.oktaAuth = oktaAuth;
        this._appServ = _appServ;
        this.http = http;
        this.globalAppService = globalAppService;
    }
    AuthInterceptorService.prototype.intercept = function (request, next) {
        return Observable.fromPromise(this.handleAccess(request, next));
    };
    AuthInterceptorService.prototype.getRegionSpecificDefaultLanguage = function () {
        var region = this.globalAppService.getDefaultRegionString();
        if (region == 'EMEA') {
            return 'en-GB';
        }
        else {
            return 'en-US';
        }
    };
    AuthInterceptorService.prototype.handleAccess = function (request, next) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var accessToken, HTTP_HEADER_OBJ, selectedCountryLang, currentLanguage, sessionId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.oktaAuth.getAccessToken()];
                    case 1:
                        accessToken = _a.sent();
                        this._userID = this._appServ.getuserId(true);
                        this._simUserId = this._appServ.getuserId(false);
                        this._simUserEmail = this._appServ.getuserEmail();
                        this.isInternalUser = this._appServ.isInternalUser.toString();
                        HTTP_HEADER_OBJ = {};
                        if (request.url.indexOf('/UploadEFile') != -1 || request.url.indexOf('/UploadKCFile') != -1) {
                            HTTP_HEADER_OBJ = Object.assign({}, { 'Authorization': 'Bearer ' + accessToken }, HTTP_HEADER_OBJ);
                        }
                        else {
                            HTTP_HEADER_OBJ = Object.assign({}, { 'Authorization': 'Bearer ' + accessToken, 'Content-Type': 'application/json' }, HTTP_HEADER_OBJ);
                        }
                        if (!request.headers.get('Accept-Language')) {
                            selectedCountryLang = this.globalAppService.getSelectedCountryLang();
                            currentLanguage = this.globalAppService.getDefaultLanguageString() || this.getRegionSpecificDefaultLanguage();
                            if (selectedCountryLang && selectedCountryLang.language) {
                                currentLanguage = selectedCountryLang.language;
                            }
                            HTTP_HEADER_OBJ = Object.assign({}, { 'Accept-Language': currentLanguage }, HTTP_HEADER_OBJ);
                        }
                        else {
                            HTTP_HEADER_OBJ = Object.assign({}, { 'Accept-Language': request.headers.get('Accept-Language') }, HTTP_HEADER_OBJ);
                        }
                        sessionId = localStorage.getItem('session-id');
                        if (sessionId) {
                            HTTP_HEADER_OBJ = Object.assign({}, { 'sessionId': sessionId }, HTTP_HEADER_OBJ);
                        }
                        HTTP_HEADER_OBJ = Object.assign({}, { 'CorrelationId': this._simUserId + "_" + Guid.raw() }, HTTP_HEADER_OBJ);
                        if (this._userID) {
                            HTTP_HEADER_OBJ = Object.assign({}, { 'userId': this._userID }, HTTP_HEADER_OBJ);
                        }
                        if (this.isInternalUser) {
                            HTTP_HEADER_OBJ = Object.assign({}, { 'isInternalUser': this.isInternalUser }, HTTP_HEADER_OBJ);
                        }
                        if (this._appServ.getCookie(cookieSIMID) && this._appServ.isGlobalUser) {
                            HTTP_HEADER_OBJ = Object.assign({}, { 'IntegratedUserId': this._simUserId }, HTTP_HEADER_OBJ);
                        }
                        if (this._appServ.getCookie(cookieSIMID) && this._appServ.isGlobalUser) {
                            HTTP_HEADER_OBJ = Object.assign({}, { 'IntegratedUserEmail': this._simUserEmail }, HTTP_HEADER_OBJ);
                        }
                        if (request.url.indexOf('/GetDefaultCustomerInfo') != -1) {
                            HTTP_HEADER_OBJ = Object.assign({}, { 'region': 'LAO' }, HTTP_HEADER_OBJ);
                        }
                        if (request.method.toUpperCase() === 'GET') {
                            HTTP_HEADER_OBJ = Object.assign({}, { 'Cache-control': 'no-cache,no-store', 'Pragma': 'no-cache' }, HTTP_HEADER_OBJ);
                        }
                        if (request.url.indexOf('/GetUserDetails') != -1 && localStorage.getItem('temp-pre-impersonated-user')) {
                            HTTP_HEADER_OBJ = Object.assign({}, { 'IntegratedUserId': localStorage.getItem('temp-pre-impersonated-user') }, HTTP_HEADER_OBJ);
                        }
                        request = request.clone({
                            headers: new HttpHeaders(HTTP_HEADER_OBJ)
                        });
                        return [2 /*return*/, next.handle(request).toPromise()];
                }
            });
        });
    };
    AuthInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function AuthInterceptorService_Factory() { return new AuthInterceptorService(i0.inject(i1.OktaAuthService), i0.inject(i2.AppService), i0.inject(i3.HttpClient), i0.inject(i4.GlobalAppService)); }, token: AuthInterceptorService, providedIn: "root" });
    return AuthInterceptorService;
}());
export { AuthInterceptorService };
