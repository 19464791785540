import * as i0 from "@angular/core";
export var DataLayerCustomDimensions;
(function (DataLayerCustomDimensions) {
    DataLayerCustomDimensions["Event"] = "event";
    DataLayerCustomDimensions["UserId"] = "userId";
    DataLayerCustomDimensions["UserType"] = "user_type";
    DataLayerCustomDimensions["PangeLanguage"] = "page_language";
})(DataLayerCustomDimensions || (DataLayerCustomDimensions = {}));
var DataLayerService = /** @class */ (function () {
    function DataLayerService() {
        this._generateEmptyDimensionsSet();
        this._constantDimensions = [
            DataLayerCustomDimensions.Event,
            DataLayerCustomDimensions.UserId,
            DataLayerCustomDimensions.UserType,
            DataLayerCustomDimensions.PangeLanguage
        ];
    }
    Object.defineProperty(DataLayerService.prototype, "dimensions", {
        set: function (someDimensions) {
            var _this = this;
            Object.keys(DataLayerCustomDimensions).map(function (key) { return DataLayerCustomDimensions[key]; })
                .forEach(function (key) {
                _this._currentSet[key] = someDimensions[key] || _this._constantDimensions[key];
            });
        },
        enumerable: true,
        configurable: true
    });
    DataLayerService.prototype.trigger = function () {
        window.dataLayer.push(this._currentSet);
    };
    DataLayerService.prototype._generateEmptyDimensionsSet = function () {
        var _a;
        this._currentSet = (_a = {},
            _a[DataLayerCustomDimensions.Event] = undefined,
            _a[DataLayerCustomDimensions.UserId] = undefined,
            _a[DataLayerCustomDimensions.UserType] = undefined,
            _a[DataLayerCustomDimensions.PangeLanguage] = undefined,
            _a);
    };
    DataLayerService.ngInjectableDef = i0.defineInjectable({ factory: function DataLayerService_Factory() { return new DataLayerService(); }, token: DataLayerService, providedIn: "root" });
    return DataLayerService;
}());
export { DataLayerService };
