import * as i0 from "@angular/core";
export var FullStoryCustomDimensions;
(function (FullStoryCustomDimensions) {
    FullStoryCustomDimensions["UserType"] = "userType_str";
    FullStoryCustomDimensions["PangeLanguage"] = "pageLanguage_str";
    FullStoryCustomDimensions["DisplayName"] = "displayName";
})(FullStoryCustomDimensions || (FullStoryCustomDimensions = {}));
var FullStoryService = /** @class */ (function () {
    function FullStoryService() {
        this._generateEmptyDimensionsSet();
        this._constantDimensions = [
            FullStoryCustomDimensions.UserType,
            FullStoryCustomDimensions.PangeLanguage,
            FullStoryCustomDimensions.DisplayName
        ];
    }
    Object.defineProperty(FullStoryService.prototype, "identify", {
        set: function (userId) {
            this._userId = userId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FullStoryService.prototype, "dimensions", {
        set: function (someDimensions) {
            var _this = this;
            Object.keys(FullStoryCustomDimensions).map(function (key) { return FullStoryCustomDimensions[key]; })
                .forEach(function (key) {
                _this._currentSet[key] = someDimensions[key] || _this._constantDimensions[key];
            });
        },
        enumerable: true,
        configurable: true
    });
    FullStoryService.prototype.trigger = function () {
        if (!this._isIdentified) {
            window.FS.identify(this._userId);
        }
        window.FS.setUserVars(this._currentSet);
    };
    FullStoryService.prototype._generateEmptyDimensionsSet = function () {
        var _a;
        this._currentSet = (_a = {},
            _a[FullStoryCustomDimensions.UserType] = undefined,
            _a[FullStoryCustomDimensions.PangeLanguage] = undefined,
            _a[FullStoryCustomDimensions.DisplayName] = undefined,
            _a);
    };
    FullStoryService.ngInjectableDef = i0.defineInjectable({ factory: function FullStoryService_Factory() { return new FullStoryService(); }, token: FullStoryService, providedIn: "root" });
    return FullStoryService;
}());
export { FullStoryService };
