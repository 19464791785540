/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sort.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./sort.component";
import * as i4 from "../../../services/cp-ag-grid-service";
var styles_SortComponent = [i0.styles];
var RenderType_SortComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SortComponent, data: {} });
export { RenderType_SortComponent as RenderType_SortComponent };
export function View_SortComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "ul", [["class", "sorting"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "li", [["value", "asc"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event, "asc") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 4, "li", [["value", "desc"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event, "desc") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "separator"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.ascClass, ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("sort.asc")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.dscClass, ""); _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("sort.desc")); _ck(_v, 10, 0, currVal_3); }); }
export function View_SortComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cp-sort-component", [], null, null, null, View_SortComponent_0, RenderType_SortComponent)), i1.ɵdid(1, 114688, null, 0, i3.SortComponent, [i4.CpAgGridService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SortComponentNgFactory = i1.ɵccf("cp-sort-component", i3.SortComponent, View_SortComponent_Host_0, { colId: "colId", orgColId: "orgColId" }, { onSortRequested: "onSortRequested" }, []);
export { SortComponentNgFactory as SortComponentNgFactory };
