/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hyperlink-and-legend.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./hyperlink-and-legend.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../Shared/Services/app.service";
import * as i6 from "../../../services/cp-ag-grid-service";
var styles_HyperlinkAndLegendComponent = [i0.styles];
var RenderType_HyperlinkAndLegendComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HyperlinkAndLegendComponent, data: {} });
export { RenderType_HyperlinkAndLegendComponent as RenderType_HyperlinkAndLegendComponent };
function View_HyperlinkAndLegendComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLinkClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 1, 0, currVal_0); }); }
function View_HyperlinkAndLegendComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 1, 0, currVal_0); }); }
function View_HyperlinkAndLegendComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-user-friends custom-table__icon fill"]], null, null, null, null, null))], null, null); }
function View_HyperlinkAndLegendComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-user"]], null, null, null, null, null))], null, null); }
function View_HyperlinkAndLegendComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-user-slash custom-table__icon fill"]], null, null, null, null, null))], null, null); }
export function View_HyperlinkAndLegendComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HyperlinkAndLegendComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HyperlinkAndLegendComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HyperlinkAndLegendComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HyperlinkAndLegendComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HyperlinkAndLegendComponent_5)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isGlobalUser; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isGlobalUser; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.isGroupAdmin && !_co.isGlobalUser); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.isGroupAdmin && _co.isGlobalUser); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.isNonAssociatedUser && !_co.isGlobalUser); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_HyperlinkAndLegendComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "hyperlink-and-legend", [], null, null, null, View_HyperlinkAndLegendComponent_0, RenderType_HyperlinkAndLegendComponent)), i1.ɵdid(1, 245760, null, 0, i3.HyperlinkAndLegendComponent, [i4.Router, i4.ActivatedRoute, i5.AppService, i6.CpAgGridService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HyperlinkAndLegendComponentNgFactory = i1.ɵccf("hyperlink-and-legend", i3.HyperlinkAndLegendComponent, View_HyperlinkAndLegendComponent_Host_0, {}, {}, []);
export { HyperlinkAndLegendComponentNgFactory as HyperlinkAndLegendComponentNgFactory };
