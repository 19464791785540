import { Component, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { PaService } from '@app/Modules/PriceAgreement/Services/pa.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { PadetailsService } from '@app/Modules/PADetails/Services/padetails.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { OktaProfile } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { globalUser, SPA_LEGACY_MENU_HOST } from '@env/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';

@Component({
  selector: 'app-pa-id-hyperlink-only',
  templateUrl: './pa-id-hyperlink-only.component.html',
  styleUrls: ['./pa-id-hyperlink-only.component.scss']
})


export class PaIdHyperlinkOnlyComponent implements AgRendererComponent, OnDestroy {
  today;
  cvtView: any;
  subscription: Subscription = new Subscription();
  oktaProfile: OktaProfile;

  constructor(private appServ: AppService,
    private _globalAppService : GlobalAppService) { }

  params: any;
  customerId: string = "";

  ngOnInit() {
    this.oktaProfile = this.appServ.AvailableOKtaProfile;

  }
  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  onLinkClick() {
    let qsParams = this.getQsParams();
    let isCVT: string = "CVTN/o";
    if (this._globalAppService.isCVTView()) {
      this.cvtView = this._globalAppService.isCVTView();
      isCVT = "CVTYes"
    }
    else {
      isCVT = "CVTNo"
    }

    if (this.params.data.customerNumber == null) {
      this.params.data.customerNumber = 'All';
    }
    if (this.oktaProfile && this.oktaProfile.sAMAccountName) {
      window.open(SPA_LEGACY_MENU_HOST + this.params.data.contractNumber + '/' + this.params.data.customerNumber + '/0/' + isCVT + qsParams);
    }

  }

  getQsParams(): string {
    let qsList: Array<any> = new Array<any>();
    let qsParams: string;
    let qsTileType: string = this.params.tileType ? ('tileType=' + this.params.tileType) : "";
    if (qsTileType)
      qsList.push(qsTileType);

    let fullNameList = [];
    let oCustomerName: any = this.params.data.customerName;
    if (oCustomerName) {
      fullNameList.push(oCustomerName.name1 || '');
      // fullNameList.push(oCustomerName.name2 || '');
      // fullNameList.push(oCustomerName.name3 || '');
      // fullNameList.push(oCustomerName.Fname4 || '');
    }
    let qsCustomerName: string = fullNameList && fullNameList.length ? ('custName=' + fullNameList.join(' ')) : '';
    if (qsCustomerName)
      qsList.push(qsCustomerName);
    qsParams = qsList && qsList.length > 0 ? '?' + qsList.join('&') : '';

    return qsParams;
  }

  getTodaysDate() {
    let today: any = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    let yyyy: any = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = dd + '/' + mm + '/' + yyyy;
    return (today)
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component
  }
}
