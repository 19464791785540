import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import * as i0 from "@angular/core";
import * as i1 from "../../Shared/Services/http.service";
import * as i2 from "../../Shared/Services/app.service";
var PaService = /** @class */ (function () {
    function PaService(_http, appService) {
        this._http = _http;
        this.appService = appService;
        this._searchCriteria = {};
        this.searchRequestState = new BehaviorSubject(null);
        this.custInfoDataHolder = [];
        this.locationTreeData = new Subject();
        this.searchRequestState$ = this.searchRequestState.asObservable();
        this._favoriteSearch = new Subject();
        this.recordCount = new BehaviorSubject(undefined);
        this.recordCount$ = this.recordCount.asObservable();
        this._isFilterOn = false;
        this.pageSize = 50;
        this.PAType = "";
        this.customerIds = [];
        this.customerCVTIds = [];
        this.stateList = [];
        this.gridLoaded = new Subject();
        this.mySearchesNavigation = new BehaviorSubject(false);
        this.windowResizeSubject = new Subject();
    }
    PaService.prototype.setCustomerIds = function (CustomerData) {
        this.customerIds = CustomerData;
    };
    PaService.prototype.getCustomerIds = function () {
        return this.customerIds;
    };
    PaService.prototype.setLocationForEU = function (locData) {
        this.locationData = locData;
    };
    PaService.prototype.getLocationForEU = function () {
        return this.locationData;
    };
    PaService.prototype.setCustInfoDataHolder = function (CustomerData) {
        this.custInfoDataHolder = CustomerData;
    };
    PaService.prototype.getCustInfoDataHolder = function () {
        return this.custInfoDataHolder;
    };
    PaService.prototype.setLocationCount = function (value) {
        this._locationCount = value;
    };
    PaService.prototype.getLocationCount = function () {
        return this._locationCount;
    };
    PaService.prototype.setCVTCustomerIds = function (CustomerData) {
        this.customerCVTIds = CustomerData;
    };
    PaService.prototype.getCVTCustomerIds = function () {
        return this.customerCVTIds;
    };
    PaService.prototype.setStateList = function (CustomerData) {
        this.stateList = CustomerData;
    };
    PaService.prototype.getStateList = function () {
        return this.stateList;
    };
    PaService.prototype.setPAType = function (PATypeData) {
        this.PAType = PATypeData;
    };
    PaService.prototype.getPAType = function () {
        return this.PAType;
    };
    PaService.prototype.loadPatype = function (cvtView) {
        var _this = this;
        if (cvtView === void 0) { cvtView = false; }
        this._http.getPATYpe(cvtView).subscribe(function (data) {
            _this.appService.setPAtype(data);
        });
    };
    PaService.prototype.setLocationData = function (tree) {
        this.locationTreeData = tree;
    };
    PaService.prototype.getLocationData = function () {
        return this.locationTreeData;
    };
    PaService.prototype.setLocationSelectedData = function (tree) {
        this.locationSelectedTreeData = tree;
    };
    PaService.prototype.getLocationSelectedData = function () {
        return this.locationSelectedTreeData;
    };
    PaService.prototype.setGridLoaded = function (value) {
        return this.gridLoaded.next(value);
    };
    PaService.prototype.getGridLoaded = function () {
        return this.gridLoaded.asObservable();
    };
    PaService.prototype.setGridApiContext = function (gridContext) {
        this._gridApiContext = gridContext;
    };
    PaService.prototype.getsearchData = function () {
        return this._searchCriteria;
    };
    PaService.prototype.initPASearch = function (searchObj) {
        this._searchCriteria = searchObj;
    };
    PaService.prototype.getSearchRequest = function () {
        return this.searchRequestState.asObservable();
    };
    PaService.prototype.changeSearchRequest = function (searchObject) {
        this.appService.changeSearchRequest(searchObject);
        this.searchRequestState.next(searchObject);
    };
    PaService.prototype.getResultSet = function () {
        return this.resultSetData;
    };
    PaService.prototype.setResultSet = function (value) {
        if (value) {
            this.resultSetData = value.map(function (item) {
                return item;
            });
        }
    };
    PaService.prototype.getFilterOn = function () {
        return this._isFilterOn;
    };
    PaService.prototype.setFilterOn = function (value) {
        this._isFilterOn = value;
    };
    PaService.prototype.getSortOn = function () {
        return this._isSortOn;
    };
    PaService.prototype.setSortOn = function (value) {
        this._isSortOn = value;
    };
    PaService.prototype.initializCountZero = function () {
        this.recordCount.next(0);
    };
    PaService.prototype.setMySearchesNavigation = function (value) {
        this.mySearchesNavigation.next(value);
    };
    PaService.prototype.getMySearchesNavigation = function () {
        return this.mySearchesNavigation.asObservable();
    };
    PaService.prototype.setWindowResizeInPADetails = function (value) {
        this.windowResizeSubject.next(value);
    };
    PaService.prototype.getWindowResizeInPADetails = function () {
        return this.windowResizeSubject.asObservable();
    };
    // getDataSourceActiveFuture() {
    //   let me = this;
    //   // if (!this.dataSource) {
    //   this.dataSource = {
    //     rowCount: null,
    //     getRows: (params) => {
    //       me._gridApiContext.showLoadingOverlay();
    //       if (!me.getFilterOn() && !(params.sortModel && params.sortModel.length > 0)) {
    //         //this.paSearchRequest = Object.assign({}, this._searchCriteria);
    //         //this.paSearchRequest.currentPage = (((params.startRow / this.paSearchRequest.pageSize) + 1) || 1);
    //         let currentPage = (((params.startRow / this.pageSize) + 1) || 1);
    //         me._http.getGridCoulmnData('1')
    //           .subscribe(data => {
    //             let lastRow = -1;
    //             if (data.count <= this.pageSize)
    //               lastRow = data.count;
    //             else {
    //               if (currentPage * this.pageSize < data.count)
    //                 lastRow = -1;
    //               else
    //                 lastRow = data.count;
    //             }
    //             me.allData = data.result;
    //             me.setResultSet(data.result);
    //             me._gridApiContext.hideOverlay();
    //             params.successCallback(data.result, lastRow);
    //             me._gridApiContext.sizeColumnsToFit();
    //             me.recordCount.next(data.count);
    //           });
    //       }
    //       else {
    //         let dataAfterSortingAndFiltering = me.sortAndFilter(me.allData, params.sortModel, params.filterModel);
    //         let rowsThisPage = dataAfterSortingAndFiltering.slice(params.startRow, params.endRow);
    //         let lastRow = -1;
    //         if (dataAfterSortingAndFiltering.length <= params.endRow) {
    //           lastRow = dataAfterSortingAndFiltering.length;
    //         }
    //         if (params.filterModel && Object.entries(params.filterModel).every(fm => fm[1]["value"] === null)) {
    //           lastRow = -1;
    //           this.setFilterOn(false);
    //         }
    //         params.successCallback(rowsThisPage, lastRow);
    //         me._gridApiContext.hideOverlay();
    //         me._gridApiContext.sizeColumnsToFit();
    //       }
    //     }
    //   }
    //   // };
    //   return this.dataSource;
    // }
    // getDataSourceRecentlyChanged() {
    //   let me = this;
    //   // if (!this.dataSource) {
    //   this.dataSource = {
    //     rowCount: null,
    //     getRows: (params) => {
    //       me._gridApiContext.showLoadingOverlay();
    //       if (!me.getFilterOn() && !(params.sortModel && params.sortModel.length > 0)) {
    //         //this.paSearchRequest = Object.assign({}, this._searchCriteria);
    //         //this.paSearchRequest.currentPage = (((params.startRow / this.paSearchRequest.pageSize) + 1) || 1);
    //         let currentPage = (((params.startRow / this.pageSize) + 1) || 1);
    //         me._http.getGridCoulmnData('2')
    //           .subscribe(data => {
    //             let lastRow = -1;
    //             if (data.count <= this.pageSize)
    //               lastRow = data.count;
    //             else {
    //               if (currentPage * this.pageSize < data.count)
    //                 lastRow = -1;
    //               else
    //                 lastRow = data.count;
    //             }
    //             me.allData = data.result;
    //             me.setResultSet(data.result);
    //             me._gridApiContext.hideOverlay();
    //             params.successCallback(data.result, lastRow);
    //             me._gridApiContext.sizeColumnsToFit();
    //             me.recordCount.next(data.count);
    //           });
    //       }
    //       else {
    //         let dataAfterSortingAndFiltering = me.sortAndFilter(me.allData, params.sortModel, params.filterModel);
    //         let rowsThisPage = dataAfterSortingAndFiltering.slice(params.startRow, params.endRow);
    //         let lastRow = -1;
    //         if (dataAfterSortingAndFiltering.length <= params.endRow) {
    //           lastRow = dataAfterSortingAndFiltering.length;
    //         }
    //         if (params.filterModel && Object.entries(params.filterModel).every(fm => fm[1]["value"] === null)) {
    //           lastRow = -1;
    //           this.setFilterOn(false);
    //         }
    //         params.successCallback(rowsThisPage, lastRow);
    //         me._gridApiContext.hideOverlay();
    //         me._gridApiContext.sizeColumnsToFit();
    //       }
    //     }
    //   }
    //   // };
    //   return this.dataSource;
    // }
    // getDataSourceExpiringSoon() {
    //   let me = this;
    //   // if (!this.dataSource) {
    //   this.dataSource = {
    //     rowCount: null,
    //     getRows: (params) => {
    //       me._gridApiContext.showLoadingOverlay();
    //       if (!me.getFilterOn() && !(params.sortModel && params.sortModel.length > 0)) {
    //         //this.paSearchRequest = Object.assign({}, this._searchCriteria);
    //         //this.paSearchRequest.currentPage = (((params.startRow / this.paSearchRequest.pageSize) + 1) || 1);
    //         let currentPage = (((params.startRow / this.pageSize) + 1) || 1);
    //         me._http.getGridCoulmnData('3')
    //           .subscribe(data => {
    //             let lastRow = -1;
    //             if (data.count <= this.pageSize)
    //               lastRow = data.count;
    //             else {
    //               if (currentPage * this.pageSize < data.count)
    //                 lastRow = -1;
    //               else
    //                 lastRow = data.count;
    //             }
    //             me.allData = data.result;
    //             me.setResultSet(data.result);
    //             me._gridApiContext.hideOverlay();
    //             params.successCallback(data.result, lastRow);
    //             me._gridApiContext.sizeColumnsToFit();
    //             me.recordCount.next(data.count);
    //           });
    //       }
    //       else {
    //         let dataAfterSortingAndFiltering = me.sortAndFilter(me.allData, params.sortModel, params.filterModel);
    //         let rowsThisPage = dataAfterSortingAndFiltering.slice(params.startRow, params.endRow);
    //         let lastRow = -1;
    //         if (dataAfterSortingAndFiltering.length <= params.endRow) {
    //           lastRow = dataAfterSortingAndFiltering.length;
    //         }
    //         if (params.filterModel && Object.entries(params.filterModel).every(fm => fm[1]["value"] === null)) {
    //           lastRow = -1;
    //           this.setFilterOn(false);
    //         }
    //         params.successCallback(rowsThisPage, lastRow);
    //         me._gridApiContext.hideOverlay();
    //         me._gridApiContext.sizeColumnsToFit();
    //       }
    //     }
    //   }
    //   // };
    //   return this.dataSource;
    // }
    // getDataSourceRecentlyExpired() {
    //   let me = this;
    //   //if (!this.dataSource) {
    //   this.dataSource = {
    //     rowCount: null,
    //     getRows: (params) => {
    //       me._gridApiContext.showLoadingOverlay();
    //       if (!me.getFilterOn() && !(params.sortModel && params.sortModel.length > 0)) {
    //         //this.paSearchRequest = Object.assign({}, this._searchCriteria);
    //         //this.paSearchRequest.currentPage = (((params.startRow / this.paSearchRequest.pageSize) + 1) || 1);
    //         let currentPage = (((params.startRow / this.pageSize) + 1) || 1);
    //         me._http.getGridCoulmnData('4')
    //           .subscribe(data => {
    //             let lastRow = -1;
    //             if (data.count <= this.pageSize)
    //               lastRow = data.count;
    //             else {
    //               if (currentPage * this.pageSize < data.count)
    //                 lastRow = -1;
    //               else
    //                 lastRow = data.count;
    //             }
    //             me.allData = data.result;
    //             me.setResultSet(data.result);
    //             me._gridApiContext.hideOverlay();
    //             params.successCallback(data.result, lastRow);
    //             me._gridApiContext.sizeColumnsToFit();
    //             me.recordCount.next(data.count);
    //           });
    //       }
    //       else {
    //         let dataAfterSortingAndFiltering = me.sortAndFilter(me.allData, params.sortModel, params.filterModel);
    //         let rowsThisPage = dataAfterSortingAndFiltering.slice(params.startRow, params.endRow);
    //         let lastRow = -1;
    //         if (dataAfterSortingAndFiltering.length <= params.endRow) {
    //           lastRow = dataAfterSortingAndFiltering.length;
    //         }
    //         if (params.filterModel && Object.entries(params.filterModel).every(fm => fm[1]["value"] === null)) {
    //           lastRow = -1;
    //           this.setFilterOn(false);
    //         }
    //         params.successCallback(rowsThisPage, lastRow);
    //         me._gridApiContext.hideOverlay();
    //         me._gridApiContext.sizeColumnsToFit();
    //       }
    //     }
    //   }
    //   //};
    //   return this.dataSource;
    // }
    // getDataSourceMyPAFavorites() {
    //   let me = this;
    //   // if (!this.dataSource) {
    //   this.dataSource = {
    //     rowCount: null,
    //     getRows: (params) => {
    //       me._gridApiContext.showLoadingOverlay();
    //       if (!me.getFilterOn() && !(params.sortModel && params.sortModel.length > 0)) {
    //         //this.paSearchRequest = Object.assign({}, this._searchCriteria);
    //         //this.paSearchRequest.currentPage = (((params.startRow / this.paSearchRequest.pageSize) + 1) || 1);
    //         let currentPage = (((params.startRow / this.pageSize) + 1) || 1);
    //         me._http.getGridCoulmnData('5')
    //           .subscribe(data => {
    //             let lastRow = -1;
    //             if (data.count <= this.pageSize)
    //               lastRow = data.count;
    //             else {
    //               if (currentPage * this.pageSize < data.count)
    //                 lastRow = -1;
    //               else
    //                 lastRow = data.count;
    //             }
    //             me.allData = data.result;
    //             me.setResultSet(data.result);
    //             me._gridApiContext.hideOverlay();
    //             params.successCallback(data.result, lastRow);
    //             me._gridApiContext.sizeColumnsToFit();
    //             me.recordCount.next(data.count);
    //           });
    //       }
    //       else {
    //         let dataAfterSortingAndFiltering = me.sortAndFilter(me.allData, params.sortModel, params.filterModel);
    //         let rowsThisPage = dataAfterSortingAndFiltering.slice(params.startRow, params.endRow);
    //         let lastRow = -1;
    //         if (dataAfterSortingAndFiltering.length <= params.endRow) {
    //           lastRow = dataAfterSortingAndFiltering.length;
    //         }
    //         if (params.filterModel && Object.entries(params.filterModel).every(fm => fm[1]["value"] === null)) {
    //           lastRow = -1;
    //           this.setFilterOn(false);
    //         }
    //         params.successCallback(rowsThisPage, lastRow);
    //         me._gridApiContext.hideOverlay();
    //         me._gridApiContext.sizeColumnsToFit();
    //       }
    //     }
    //   }
    //   // };
    //   return this.dataSource;
    // }
    PaService.prototype.sortAndFilter = function (allOfTheData, sortModel, filterModel) {
        return this.sortData(sortModel, this.filterData(filterModel, allOfTheData));
    };
    PaService.prototype.sortData = function (sortModel, data) {
        var sortPresent = sortModel && sortModel.length > 0;
        if (!sortPresent) {
            return data;
        }
        var resultOfSort = data.slice();
        resultOfSort.sort(function (a, b) {
            for (var k = 0; k < sortModel.length; k++) {
                var sortColModel = sortModel[k];
                var valueA = a[sortColModel.colId];
                var valueB = b[sortColModel.colId];
                if (valueA == valueB) {
                    continue;
                }
                var sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
                if (valueA > valueB) {
                    return sortDirection;
                }
                else {
                    return sortDirection * -1;
                }
            }
            return 0;
        });
        return resultOfSort;
    };
    PaService.prototype.filterData = function (filterModel, data) {
        var filterPresent = filterModel && Object.keys(filterModel).length > 0;
        if (!filterPresent) {
            return data;
        }
        var resultOfFilter = [];
        var _loop_1 = function (i) {
            var item = data[i];
            if (filterModel.customerNumber && filterModel.customerNumber.value && filterModel.customerNumber.value !== "") {
                if (item.customerNumber.indexOf(filterModel.customerNumber.value) < 0) {
                    return "continue";
                }
            }
            if (filterModel.customerName && filterModel.customerName.value && filterModel.customerName.value !== "") {
                if (item.customerName.toLowerCase().indexOf(filterModel.customerName.value.toLowerCase()) < 0) {
                    return "continue";
                }
            }
            if (filterModel.contractName && filterModel.contractName.value && filterModel.contractName.value !== "") {
                if (item.contractName.toLowerCase().indexOf(filterModel.contractName.value.toLowerCase()) < 0) {
                    return "continue";
                }
            }
            if (filterModel.contractNumber && filterModel.contractNumber.value && filterModel.contractNumber.value !== "") {
                if (item.contractNumber.indexOf(filterModel.contractNumber.value) < 0) {
                    return "continue";
                }
            }
            var hasTypeFilterPass = void 0;
            if (filterModel.type) {
                var selectedTypes = filterModel.type.value.filter(function (filter) {
                    return filter.selected;
                });
                if (selectedTypes && selectedTypes.length > 0) {
                    hasTypeFilterPass = selectedTypes.some(function (filter) {
                        return (item.type
                            .toString()
                            .toLowerCase() === filter.text.toLowerCase());
                    });
                }
                // else {
                //   hasTypeFilterPass = true;
                // }
                if (!hasTypeFilterPass)
                    return "continue";
            }
            if (filterModel.startDate && filterModel.startDate.value && filterModel.startDate.value !== "") {
                var startDateFilterPass = void 0;
                if (item.startDate) {
                    var startDate = new Date(item.startDate).setHours(0, 0, 0, 0);
                    var filterDate = filterModel.startDate.value.setHours(0, 0, 0, 0);
                    if (filterDate === startDate) {
                        startDateFilterPass = true;
                    }
                }
                if (!startDateFilterPass)
                    return "continue";
            }
            if (filterModel.endDate && filterModel.endDate.value && filterModel.endDate.value !== "") {
                var endDateFilterPass = void 0;
                if (item.endDate) {
                    var endDate = new Date(item.endDate).setHours(0, 0, 0, 0);
                    var filterDate = filterModel.endDate.value.setHours(0, 0, 0, 0);
                    if (filterDate === endDate) {
                        endDateFilterPass = true;
                    }
                }
                if (!endDateFilterPass)
                    return "continue";
            }
            if (filterModel.lastChanged && filterModel.lastChanged.value && filterModel.lastChanged.value.from && filterModel.lastChanged.value.to && filterModel.lastChanged.value.from !== "" && filterModel.lastChanged.value.to !== "") {
                var haslastChangedFilterPass = false;
                if (item.lastChanged) {
                    var lastChanged = new Date(item.lastChanged).setHours(0, 0, 0, 0);
                    var filterFromDate = filterModel.lastChanged.value.from.setHours(0, 0, 0, 0);
                    var filterToDate = filterModel.lastChanged.value.to.setHours(0, 0, 0, 0);
                    if (lastChanged >= filterFromDate && lastChanged <= filterToDate) {
                        haslastChangedFilterPass = true;
                    }
                }
                else {
                    return "continue";
                }
                if (!haslastChangedFilterPass)
                    return "continue";
            }
            resultOfFilter.push(item);
        };
        for (var i = 0; i < data.length; i++) {
            _loop_1(i);
        }
        return resultOfFilter;
    };
    PaService.prototype.markSearchFavorite = function (searchItem) {
        // console.log("markSearchFavorite", searchItem);
        this._favoriteSearch.next(searchItem);
    };
    PaService.prototype.getMarkSearchFavorite = function () {
        // console.log("getMarkSearchFavorite", this._favoriteSearch);
        return this._favoriteSearch;
    };
    PaService.prototype.getSortMenus = function (params) {
        return [
            {
                name: "Ascending",
                action: function () {
                    params.context.isSorted = true;
                    // params.context.sortHandler(params, 'asc');
                    params.context.paServ.sortHandler(params, 'asc');
                },
                icon: '<i class="fas fa-sort-alpha-down"></i>',
            },
            {
                name: "Descending",
                action: function () {
                    params.context.isSorted = true;
                    // params.context.sortHandler(params, 'desc');
                    params.context.paServ.sortHandler(params, 'desc');
                },
                icon: '<i class="fas fa-sort-alpha-down-alt"></i>'
            },
            {
                name: "Clear Sort",
                action: function () {
                    params.context.isSorted = false;
                    // params.context.sortHandler(params, null);
                    params.context.paServ.sortHandler(params, null);
                },
                icon: '<img src="/assets/images/reset.png" style="width: 12px;"/>'
            },
        ];
    };
    PaService.prototype.sortHandler = function (params, sortOrder) {
        if (sortOrder)
            this._gridApiContext.setSortModel([{ sort: sortOrder, colId: params.column.colId }]);
        else
            this._gridApiContext.setSortModel(null);
    };
    PaService.prototype.loadCustomerLocations = function (results) {
        console.log("@@ DataToProcess: ", results);
        results = results.map(function (d) {
            var row = Object.assign({}, d);
            row.CustomerNumber = d.customerNumber;
            row.CustomerName = d.name;
            row.City = d.city;
            row.State = d.state;
            return row;
        });
        for (var ctr = 0; ctr <= results.length; ctr++) {
            this.recursiveProcessNode(results[ctr], ctr, results);
        }
        var temp = results.filter(function (n) {
            return n.tree;
        });
        console.log("@@ Temo: ", temp);
        // return temp.map(d => {
        //   let row: any = Object.assign({}, d);
        //   row.CustomerNumber = d.customerNumber;
        //   row.CustomerName = d.name;
        //   row.City = d.city;
        //   row.State = d.state;
        //   // let allDataList: Array<string> = new Array<string>();
        //   // if (row.name)
        //   //   allDataList.push(row.name);
        //   // if (row.customerNumber)
        //   //   allDataList.push('(' + row.customerNumber + ')');
        //   // if (row.city)
        //   //   allDataList.push(row.city);
        //   // if (row.state)
        //   //   allDataList.push(row.state);
        //   // if (allDataList && allDataList.length)
        //   //   row.label = allDataList.join(', ');
        //   // row.data = row.customerNumber;
        //   return row;
        // });
        console.log("gfgcdfgcdf", temp);
        return temp;
    };
    // recursiveProcessNode(CURRENT_NODE, CURRENT_NODE_INDEX, ALL_DATA) {
    //   if (CURRENT_NODE && CURRENT_NODE['parentCustomer']) {
    //     let PREV_NODE_INDEX = CURRENT_NODE_INDEX === 0 ? -1 : CURRENT_NODE_INDEX - 1;
    //     if (PREV_NODE_INDEX >= 0) {
    //       let PREV_NODE = ALL_DATA[PREV_NODE_INDEX];
    //       if (PREV_NODE['customerNumber'] === CURRENT_NODE['parentCustomer']) {
    //         if (!PREV_NODE.children) {
    //           Object.assign(PREV_NODE, { 'children': [] });
    //         }
    //         let currentNode: any = Object.assign({}, CURRENT_NODE);
    //         currentNode.CustomerNumber = CURRENT_NODE.customerNumber;
    //         currentNode.CustomerName = CURRENT_NODE.name;
    //         PREV_NODE.children.push(currentNode);
    //       }
    //       else {
    //         this.recursiveProcessNode(CURRENT_NODE, PREV_NODE_INDEX, ALL_DATA);
    //       }
    //     }
    //   }
    //   else {
    //     if (CURRENT_NODE && !CURRENT_NODE.hasOwnProperty('tree')) {
    //       Object.assign(CURRENT_NODE, { 'tree': true });
    //     }
    //   }
    // }
    PaService.prototype.recursiveProcessNode = function (CURRENT_NODE, CURRENT_NODE_INDEX, ALL_DATA) {
        if (CURRENT_NODE && CURRENT_NODE['parentCustomer']) {
            var PREV_NODE_INDEX = CURRENT_NODE_INDEX === 0 ? -1 : CURRENT_NODE_INDEX - 1;
            if (PREV_NODE_INDEX >= 0) {
                var PREV_NODE = ALL_DATA[PREV_NODE_INDEX];
                if (PREV_NODE['customerNumber'] === CURRENT_NODE['parentCustomer']) {
                    if (!PREV_NODE.children) {
                        Object.assign(PREV_NODE, { 'children': [] });
                    }
                    PREV_NODE.children.push(CURRENT_NODE);
                }
                else {
                    this.recursiveProcessNode(CURRENT_NODE, PREV_NODE_INDEX, ALL_DATA);
                }
            }
        }
        else {
            if (CURRENT_NODE && !CURRENT_NODE.hasOwnProperty('tree')) {
                Object.assign(CURRENT_NODE, { 'tree': true });
            }
        }
    };
    PaService.ngInjectableDef = i0.defineInjectable({ factory: function PaService_Factory() { return new PaService(i0.inject(i1.HttpService), i0.inject(i2.AppService)); }, token: PaService, providedIn: "root" });
    return PaService;
}());
export { PaService };
