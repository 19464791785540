/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cb-pricing-terms.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./cb-pricing-terms.component";
import * as i4 from "../../Services/cb-acceptence-policy.service";
import * as i5 from "@angular/router";
var styles_CBPricingTermsComponent = [i0.styles];
var RenderType_CBPricingTermsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CBPricingTermsComponent, data: {} });
export { RenderType_CBPricingTermsComponent as RenderType_CBPricingTermsComponent };
function View_CBPricingTermsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEnglishClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "current-language": 0 }), (_l()(), i1.ɵted(-1, null, [" English "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.isEnglishActive); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CBPricingTermsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "body-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "content-area__white-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "modal__language-selector"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "ul", [["class", " unlisted inline "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CBPricingTermsComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUserLanguageClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(11, { "current-language": 0 }), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "info-content"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideEnglishTab; _ck(_v, 8, 0, currVal_0); var currVal_1 = _ck(_v, 11, 0, (!_co.isEnglishActive || _co.hideEnglishTab)); _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.userLanguageName; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.policyContent; _ck(_v, 13, 0, currVal_3); }); }
export function View_CBPricingTermsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cb-pricing-terms", [], null, null, null, View_CBPricingTermsComponent_0, RenderType_CBPricingTermsComponent)), i1.ɵdid(1, 245760, null, 0, i3.CBPricingTermsComponent, [i4.CbAcceptencePolicyService, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CBPricingTermsComponentNgFactory = i1.ɵccf("app-cb-pricing-terms", i3.CBPricingTermsComponent, View_CBPricingTermsComponent_Host_0, {}, {}, []);
export { CBPricingTermsComponentNgFactory as CBPricingTermsComponentNgFactory };
