import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalAppService } from './global-app.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpInvoicePriceService {

  constructor(private http: HttpClient,
    private _globalAppService: GlobalAppService) { }

  //fetch region specific url from environment.ts
  getRegionSpecificURL(item: any) {
    let regionURL = "";
    let region = localStorage.getItem('default-region') || this._globalAppService.getDefaultRegionString() || this._globalAppService.selectedRegion;
    if (region) {
      regionURL = "url_" + region.toUpperCase();
    }
    else {
      regionURL = "url_NA";
    }
    return item[regionURL];
  }

  makeHTTPCall(method: string, endPoint: string, additionalHeaders: any = null, requestParams: any = null): Observable<any> {
    let requestBody = {};
    if (additionalHeaders) {
      requestBody = Object.assign({}, requestBody, { headers: additionalHeaders });
    }
    if (requestParams) {
      requestBody = Object.assign({}, requestBody, { body: requestParams });
    }

    return this.http.request(method, endPoint, requestBody);
  }

  GetInvoicePriceStatusByCustomerID(request): Observable<any> {
    return this.makeHTTPCall(environment.GetInvoicePriceStatusByCustomerID.method, this.getRegionSpecificURL(environment.GetInvoicePriceStatusByCustomerID), null, request);
  }

  saveFavoriteInvoiceCategory(request): Observable<any> {
    return this.makeHTTPCall(environment.SaveFavoriteInvoiceCategory.method, this.getRegionSpecificURL(environment.SaveFavoriteInvoiceCategory), null, request);
  }

  getFavoriteInvoiceCategory(request): Observable<any> {
    return this.makeHTTPCall(environment.GetFavoriteCategoryInvoice.method, this.getRegionSpecificURL(environment.GetFavoriteCategoryInvoice), null, request);
  }

  priceRefreshRequest(request): Observable<any> {
    return this.makeHTTPCall(environment.PriceRefreshRequest.method, this.getRegionSpecificURL(environment.PriceRefreshRequest), null, request);
  }

  downloadInvoicePrice(request): Observable<any> {
    return this.makeHTTPCall(environment.DownloadInvoicePrice.method, this.getRegionSpecificURL(environment.DownloadInvoicePrice), null, request);
  }
  
  notifyInvoicePriceRequestByEmail(request): Observable<any> {
    return this.makeHTTPCall(environment.NotifyInvoicePriceRequestByEmail.method, this.getRegionSpecificURL(environment.NotifyInvoicePriceRequestByEmail), null, request);
  }

  GetCategoriesByCustomerNumber(request): Observable<any> {
    return this.makeHTTPCall(environment.GetCategoriesByCustomerNumber.method, this.getRegionSpecificURL(environment.GetCategoriesByCustomerNumber), null, request);
  }
}
