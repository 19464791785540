import { Component, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { siteAdminRoles } from "@app/conf/dataConfigurations";
import { MenuItem } from 'primeng/api';
import { headerLinks, CMTReportsURL} from '@app/conf/appLink';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { ContentAreaService } from '@app/Modules/Shared/Services/content-area.service';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { CustomerInfomation, OktaProfile, saveUpdateUserProfile } from '@app/Modules/Shared/DataModels/SharedDataModel';
import { OktaAuthService } from '@okta/okta-angular';
import { MenuTogglerService } from '@app/Modules/Shared/Services/menu-toggler.service'
import { Router, RouterEvent, NavigationEnd,ActivatedRoute } from '@angular/router';
import { APP_LEGACY_MENU_HOST, cookieSIMID, globalUser, externalAdminRole } from '@env/environment';
import { HttpService } from '../../Services/http.service';
import { LocationLanguageService } from '@app/Modules/Shared/Services/location-language.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { DOCUMENT } from '@angular/common';
import { globalUserLink } from '@app/conf/appLink';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  [x: string]: any;
  salesOrgList: Array<any>;
  salesOrglabel: any;
  appRole: string;
  country: any;
  selecteCountry: any;
  currenRegion: Array<any>;
  myObj: any;
  profileRequest: saveUpdateUserProfile;
  userName: string;
  salesOrg: number;
  siteAdminRoles: any = siteAdminRoles;
  isMyProfilePage: boolean = false;

  @ViewChild('isExternalAdminUser') isExternalAdminUserElm;
  @ViewChild('actionBtnMenu') actionBtnMenu;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private translateServ: TranslateService,
    private languageServ: LanguageSelectionService,
    private contentAreaServ: ContentAreaService,
    private _appServ: AppService,
    public _oktaAuth: OktaAuthService,
    private router: Router,
    private httpServ: HttpService,
    private menuTogglerService: MenuTogglerService,
    private locationLanguageService: LocationLanguageService,
    private _globalAppService: GlobalAppService,
    private activatedRoute: ActivatedRoute) {
    // if (this._document.location.href.indexOf("/priceagreement/") > -1) {
    //   this.headerTextIndex = "priceAgreementHeader";
    // }
    let url = this._document.location.href;
    this.setHeaderText(url, 'constructor');
  }

  visibleSettings: boolean = false;
  visibleSettingsGlobalHomePage: boolean = false;
  visibleSupport: boolean = false;
  visibleLoggedPanel: boolean = false;
  visibleHamburger: boolean = false;
  visiblePreferenceMenu: boolean = false;
  humburger: any;
  items: MenuItem[];
  profession: Array<any>;
  showHamburger: boolean = false;
  imgPath: string = "/assets/images/international-flags/";
  defaultLangIcon = "";
  imgSource: any;
  lan: any;
  applink: any;
  userProfile: CustomerInfomation;
  user: any;
  selectedoption: any;
  parentValue: any;
  oktaProfile: OktaProfile;
  isInternalUser: boolean;
  isExternalUser: boolean;
  isExternalAdminUser: boolean;
  // customerNumber: string;
  customerToolTip: boolean;
  currentURL: string;
  headerTextIndex: string = "primaryHeader";
  isCVT: boolean = false;
  isPriceAggrementRoute: boolean = false;
  myprofileLink: string;
  _simUserId: string;
  _username: string;
  isGlobalUser: boolean = false;
  displayCountry: Array<any>;
  displayLanguage: Array<any>;
  defaultLanguage: any;
  defaultCountry: any;
  preferredCountry: any;
  manageUserMenuLink: any
  preferredLanguage: any;
  region: any;
  lang: any;
  adminApproles: Array<string>;
  isAdminRole: boolean = false;
  isDisabled: boolean;
  countryLangSelctionHidden: boolean = false;
  hideProfileLink: boolean = false;
  isDelegatedPageOpen: boolean = false;
  isGlobalRoleAssigned: boolean = false;
  hideIfDelegatedSearchPage: boolean = false;
  showAllTagForEMEA: boolean = false;
  ngOnInit() {
    this.isDelegatedPageOpen = this._appServ.getisDelegatedUserPageBoolean();
    this.isGlobalRoleAssigned = this._appServ.isGlobalRoleAssigned;
    this._appServ.getIsDelegatedUserPage().subscribe((data) => {
      this.isDelegatedPageOpen = data;
    })
    if (this._appServ.isGlobalUser && this._appServ.getCookie(cookieSIMID)) {
      this.showGlobalUserTag = true;
    }
    else {
      this.showGlobalUserTag = false;
    }
    this.httpServ.getUserInfo("").subscribe((profile: OktaProfile) => {
      this._username = profile.given_name + " " + profile.family_name;
      /** Set the internal user to true here as for first time user this is undefined */
      if (profile.userType == "C" || profile.userType == "E") {
        this.isInternalUser = true;
      } else {
        this.isInternalUser = false;
      }
      if (this._appServ.isGlobalRoleAssigned) {
        this.getGlobalUserDetails(this._username);
      }
    });

    this._globalAppService.getIsCountryLangSelctionHidden().subscribe((value) => {
      this.countryLangSelctionHidden = value;
    })

    this._globalAppService.getSelectedCountryFromProfile().subscribe((countryObject) => {
      this.selecteCountry = countryObject.countryName;
    })

    this._globalAppService.getdefaultProfileLocationUpdated().subscribe((isUpdated) => {
      if (isUpdated) {
        setTimeout(() => {
          this.setMasterDetailsLocations();
          this.httpServ.getUserProfile(this._appServ.getuserId(true)).subscribe((data) => {
            if ((data && data['defaultCustomerInfo'] && data['defaultCustomerInfo'].length)) {
              this._appServ.setuserProfileData(data);
              this.userProfile = data;
              this.getOktaset();
            }
          });
        });
      }
    });
    this.applink = headerLinks;
    // this.isCVT = this._globalAppService.isCVTView();
    console.log("@@@@@@@@@@@ In Heade Is CVT view : ", this.isCVT);

    setTimeout(() => this.setMasterDetailsLocations());
    this._globalAppService.getMasterDataDetails().subscribe(data => {
      if (data) {
        this.httpServ.getUserDetails().subscribe(userDetails => {
          if(userDetails.ErrorType){            
            this.router.navigate(['error'], { state: { message: 'User does not have any roles assigned in eBam' }});
          }
          this._globalAppService.setUserDetails(userDetails.result);
          let userDetailsResult = userDetails.result;
          this.defaultCountry = userDetailsResult.preferredCountry;
          this.defaultLanguage = userDetailsResult.preferredLanguage;
          this._globalAppService.setDefaultLanguage(this.defaultLanguage);
          this.languageServ.setlanguage(this.defaultLanguage);
          console.log("user details", userDetailsResult);
          this._globalAppService.setSelectedCountryLang(userDetailsResult.preferredCountry, userDetailsResult.preferredLanguage, userDetailsResult.preferredSalesOrg);
          this._globalAppService.setUserDetailsLoaded(true);
          /** For External user if there are no loation mapped then show contact admin error message */
          if (this._globalAppService.getNoLocationsAvaiable()) {
            this.defaultCountry = "";
          }
          this._globalAppService.getNoLocationSubscription().subscribe((isNoLocationAvailableSubs) => {
            if (isNoLocationAvailableSubs) {
              this.defaultCountry = "";
            }
          })
          if (this.defaultLanguage) {
            // this.manageUserMenuLink = this.applink.manageUserLink + this.defaultLanguage.toLowerCase();
            this.manageUserMenuLink = this.applink.manageUserLink;
          }
          this.getCountrySalesOrgLanguage();
        });

        this.locationLanguageService.setLocationLanguageData(data.response);


      }
    });
    this._globalAppService.getDefaultRegion().subscribe(region => {
      if (region) {
        this.region = region;
        this._globalAppService.getDefaultLanguage().subscribe(lang => {
          if (!lang) {
            if (region == "LAO") {
              lang = "en-AR";
            } else {
              lang = "en-US";
            }
          }
          if (region && lang) {
            this.lang = lang;
            this.translateServ.setDefaultLang(this.region + '/' + this.languageServ.getLanguageCode(this.region, this.lang).toLowerCase());
            sessionStorage.setItem("KCC.language", lang);
          }
        })
      }
    });
    this.languageServ.getlanguage().subscribe(data => {
      if (data) {
        let currentLanguage = this.region + '/' + this.languageServ.getLanguageCode(this.region,data).toLowerCase();
        this.translateServ.setDefaultLang(currentLanguage);
        // this.manageUserMenuLink = this.applink.manageUserLink + currentLanguage;
        this.manageUserMenuLink = this.applink.manageUserLink;
      }
    });

    this._appServ.getHomeNavigation().subscribe(value => {
      this.closeAllSidebars();
    })



    this.userProfile = this._appServ.userProfileInfo;
    if (this.userProfile) {
      this.getOktaset();
      this.oktaProfile = this._appServ.AvailableOKtaProfile;
      if (this.oktaProfile) {
        this.oktaSubscribe();
      }
    }
    this._appServ.getuserProfileData().subscribe((data) => {
      this.userProfile = data;
      this.oktaProfile = this._appServ.AvailableOKtaProfile;
      if (this.oktaProfile && this.oktaProfile.approles) {
        this.setIsExternalAdminUser();
      }
      this.showHideExternalAdminUserMenu();
      this.isInternalUser = this._appServ.isInternalUser;
      this.getOktaset();
    })

    this._appServ.getOktaProfile().subscribe((data) => {
      this.oktaProfile = data;
      this.oktaSubscribe();
    })
    
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    

    // this.languageServ.currentSelected.subscribe(
    //   (data) => {
    //     this.translateServ.setDefaultLang(data.regionCode);
    //     this.updateImgSRC(data);
    //     this.selectedoption = data;
    //   });
    // this.imgSource = this.imgPath + this.defaultLangIcon;

    //below code is for favicon
    let faviconElement = this._document.getElementById('appFavicon');
    if (faviconElement) {
      faviconElement.setAttribute('href', APP_LEGACY_MENU_HOST + '/assets/images/favicon.ico');
    }
  }

  ngAfterViewInit() {
    this.showHideExternalAdminUserMenu();
  }

  showHideExternalAdminUserMenu(){
    if (this._appServ.isExternalAdminUser()) {
      if(this.isExternalAdminUserElm){
        this.isExternalAdminUserElm.nativeElement.style.display = "block";
      }
    }
    else {
      if(this.isExternalAdminUserElm){
        this.isExternalAdminUserElm.nativeElement.style.display = "none";
      }
    }
  }

  getGlobalUserDetails(username) {
    let userId = this._appServ.getuserId(true);
    this.globalUserName = username + "( " + userId + " )";
  }

  getCountrySalesOrgLanguage() {
    this._appServ.setLoadingStatus(true);
    this.httpServ.getCountrySalesOrgLanguage().subscribe((countriesLangResponse) => {
      if (countriesLangResponse && countriesLangResponse.result) {
        if (countriesLangResponse.result[0] && countriesLangResponse.result[0].country)
          this.getSalesOrgList(countriesLangResponse.result[0].country);
        this.countries = countriesLangResponse.result[0].country.map(country => {
          return {
            label: country.countryName,
            value: country.countryCode,
            salesOrg: country.salesOrg[0].salesOrgId
          }
        });
        this.displayCountry = this.countries && this.countries.length ? this.countries.sort() : null;
        let country = countriesLangResponse.result[0].country.find((item) => {
          return item.countryCode == this.defaultCountry;
        })
        if (country && country.salesOrg) {
          this.displayLanguage = country.salesOrg[0].language.map(language => {
            return {
              label: language.description,
              value: language.languageCode
            }
          });
        }
        this.displayLanguage = this.displayLanguage && this.displayLanguage.length ? this.displayLanguage.sort() : null;
        if (this.defaultCountry) {
          let countries = this.displayCountry;
          this.preferredCountry = countries.find(item => {
            return item.value === this.defaultCountry
          });
          let languages = this.displayLanguage;
          this.preferredLanguage = languages.find(item => {
            return item.value.toLowerCase() === this.defaultLanguage.toLowerCase();
          });
          if (this.preferredLanguage) {
            this.preferredLanguage = this.preferredLanguage.value;
          }
          this.country = this.preferredCountry.value;
          //this.selecteCountry = this.preferredCountry.label;
          this.selecteCountry = this.preferredCountry.value.toLowerCase();
            // this._appServ.setLoaderStatusForHeaderFlagImage(true);
            this._appServ.setLoadingStatus(false);
          //this is to set loader true until flag image in header gets set. Required merging of announcment modal api with above observable
          this.countryLangSelctionHidden = true;
        }
      }
    },(error)=>{
      console.log('error==> ',error);
      this._appServ.setLoadingStatus(false);
    },()=>{
      this._appServ.setLoadingStatus(false);
    });
  }

  getSalesOrgList(countries: Array<any>) {
    let salesOrgList: Array<any> = new Array<any>();
    let salesOrgDetails;
    countries.forEach(country => {
      if (country.salesOrg && country.salesOrg.length)
        salesOrgList = salesOrgList.concat(country.salesOrg);
        
    });
    if (salesOrgList && salesOrgList.length) {
      this.salesOrgList = salesOrgList.filter((item, index) => {
        let indexOfItem: number = salesOrgList.findIndex(c => {
          return c.salesOrgId === item.salesOrgId
        });
        return indexOfItem === index;
      });
      
      if(this.region == 'EMEA'){
        salesOrgDetails = salesOrgList.map(a => {
          a.description = a.description.replace('KCP', '');
          return a.description;
        });
        console.log(salesOrgDetails);
        const uniqueDetails = salesOrgDetails.filter((item,index)=>{
          const foundIndex = salesOrgDetails.findIndex((fitem)=>{
              return fitem.trim() === item.trim();
          });
          if(foundIndex == index)
          {
            return item.trim();
          }
 
        })
        console.log(uniqueDetails);
        salesOrgDetails = uniqueDetails;
        if(this.isInternalUser && salesOrgDetails.length > 3){
            salesOrgDetails = ['ALL'];
            this.showAllTagForEMEA = true;
        }
      } else {
        salesOrgDetails = salesOrgList.map(a =>  a.countryId);
      }
      this.salesOrgLabel = new Object();
      this.salesOrgLabel.value = this.region + ' - ' + salesOrgDetails.join(', ');
      let salesOrgCombo = {
        "listItem": [
          {
            'label': this.salesOrgLabel.text,
            'value': this.salesOrgLabel.text
          }
        ]
      }
      this.profession = salesOrgCombo.listItem;
    }
  }

  setMasterDetailsLocations() {
    this.httpServ.getLocations().subscribe((data) => {
      if (data && data.response && data.response.length) {
        this._globalAppService.setMasterDataDetails(data);
        // this._globalAppService.setSelectedCountryLang(data.preferredCountry, data.preferredLanguage, data.preferredSalesOrg);
      }
    });
    this._appServ.getDisableLink().subscribe(value => {
      this.isDisabled = value;
    });
  }

  oktaSubscribe() {
    if (!this._appServ.isGlobalUser && (this.oktaProfile.userType == "C" || this.oktaProfile.userType == "E")) {
      this.isInternalUser = true;
      this.getOktaset()
      if (this._globalAppService.isCVTView()) {
        this.isCVT = this._globalAppService.isCVTView();

        this.homePageCVT(this.isCVT);
      }

      this._globalAppService.isCVTViewSubject().subscribe((d) => {

        this.homePageCVT(d);
      });

    }
    else {
      if (this.oktaProfile && this.oktaProfile.approles) {
        this.setIsExternalAdminUser();
      }
      else {
        this.getGlobalUserExternal();
      }
      this.isExternalUser = true;
    }
    this.getUserName();
  }

  setIsExternalAdminUser(){
    let userRoles = this._globalAppService.getApprolesArray();
    let matchFound = userRoles.find(element => {
      return externalAdminRole.indexOf(element.toLowerCase()) > -1;
    });
    // matchFound = this.oktaProfile.approles.find(element => {
    //   return externalAdminRole.indexOf(element.toLowerCase()) > -1;
    // });    
    if (matchFound) {
      this.isExternalAdminUser = true;
      this._appServ.setExternalAdminUser(true);
    }
    else {
      this.isExternalAdminUser = false;
      this._appServ.setExternalAdminUser(false);
    }
  }

  homePageCVT(d) {
    if (this.isGlobalUser) {
      this.isCVT = false;
    }
    if (window.location.href.indexOf('http://localhost:34025/home') > -1 || window.location.href.indexOf(APP_LEGACY_MENU_HOST + '/home') > -1) {
      this.isCVT = false;
    }
    else {
      this.isCVT = d;
    }

  }

  checkCVTView(priceaggrement) {
    if (priceaggrement) {
      if (this._globalAppService.isCVTView()) {
        this.isCVT = this._globalAppService.isCVTView();
      }
      this._globalAppService.isCVTViewSubject().subscribe((data) => {
        // this.isCVT = data;
        this.homePageCVT(data);
      });
      this.isCVT = this._globalAppService.isCVTView();
    }
    else
      this.isCVT = false;
  }
  onLangSelect(event) {
    this.languageServ.updatelanguage(event.value);
    // this.translateServ.use(event.value.regionCode);
    this.imgSource = this.imgPath + event.value.imagename;
  }

  updateImgSRC(value: any) {
    this.imgSource = this.imgPath + value.imagename;
  }

  toggleHamburger() {
    this.showHamburger = !this.showHamburger;
  }

  toggleSidebar(sidebar: string): void {
    if (sidebar == 'menu') {
      this.visibleSupport = false;
      this.visibleSettings = false;
      this.visibleSettingsGlobalHomePage = false;
      this.visiblePreferenceMenu = false;
      this.showHamburger = true;
      this.visibleHamburger = !this.visibleHamburger;
      this.menuTogglerService.setMenuState(this.showHamburger);
      // if(this.showHamburger===false)
      //  document.body.className= document.body.className.replace("sub-nav-open","")
    }
    else if (sidebar == 'settings') {
      this.setUcMappingsMenu();
      this.visibleSupport = false;
      this.visibleHamburger = false;
      this.visiblePreferenceMenu = false;
      this.showHamburger = false;
      this.visibleSettingsGlobalHomePage = false;
      this.menuTogglerService.setMenuState(this.showHamburger);
      // document.body.className= document.body.className.replace("sub-nav-open","")
      this.visibleSettings = !this.visibleSettings;
    }
    else if (sidebar == 'support') {
      this.visibleSettings = false;
      this.visibleSettingsGlobalHomePage = false;
      this.visibleHamburger = false;
      this.showHamburger = false;
      this.visiblePreferenceMenu = false;
      this.menuTogglerService.setMenuState(this.showHamburger);
      // document.body.className= document.body.className.replace("sub-nav-open","")
      this.visibleSupport = !this.visibleSupport;
    }
    else if (sidebar == 'loggedPanel') {
      this.visibleLoggedPanel = !this.visibleLoggedPanel;
    }
    else if (sidebar == 'preferenceMenu') {
      this.visibleSupport = false;
      this.visibleHamburger = false;
      this.showHamburger = false;
      this.visibleSettings = false;
      this.visibleSettingsGlobalHomePage = false;
      this.menuTogglerService.setMenuState(this.showHamburger);
      // document.body.className= document.body.className.replace("sub-nav-open","")
      this.visiblePreferenceMenu = !this.visiblePreferenceMenu;
    }
    else if (sidebar == 'settingsGlobalHomePage') {
      this.setUcMappingsMenu();
      this.visibleSupport = false;
      this.visibleHamburger = false;
      this.visiblePreferenceMenu = false;
      this.showHamburger = false;
      this.visibleSettings = false;
      this.visibleSettingsGlobalHomePage = false;
      this.menuTogglerService.setMenuState(this.showHamburger);
      // document.body.className= document.body.className.replace("sub-nav-open","")
      this.visibleSettingsGlobalHomePage = !this.visibleSettingsGlobalHomePage;
    }
  }

  showSidebar(): void {
    setTimeout(() => {
      this.contentAreaServ.updateBlurStatus(true);
    });

  }

  hideSidebar(type?: any): void {
    if (type == 'menu')
      this.showHamburger = false;
    this.menuTogglerService.setMenuState(this.showHamburger);
    setTimeout(() => {
      this.contentAreaServ.updateBlurStatus(false);
    });
  }

  clickedValue(event: object) {
    this.parentValue = event;
    if (this.parentValue == 'settings') {
      this.toggleSidebar('settings')
    }
    else if (this.parentValue == 'support') {
      this.toggleSidebar('support')
    }
    else if (this.parentValue == 'menu') {
      this.toggleSidebar('menu')
    }
  }

  setUcMappingsMenu() {
    let roles: Array<any> = this._globalAppService.getApprolesArray();

    if (this.region) {
      let whitelistedRoles: Array<any> = [];
      switch (this.region) {
        case 'NA':
          whitelistedRoles = this.siteAdminRoles['NA'];
          break;
        case 'LAO':
          whitelistedRoles = this.siteAdminRoles['LAO'];
          break;
        case 'EMEA':
          // whitelistedRoles = this.siteAdminRoles.LAO;
          whitelistedRoles = this.siteAdminRoles['EMEA'];
          break;
      }
      this.isAdminRole = whitelistedRoles.some(wl => {
        return roles.indexOf(wl) !== -1;
      });
    }
  }

  logout() {
    window.sessionStorage.removeItem('paDetailsURL');
    window.localStorage.removeItem("impersonatedUserRoles");
    window.localStorage.removeItem("impersonatedUserRegions");
    window.localStorage.removeItem("newLocationAddedPopupShown");
    window.localStorage.removeItem("recentlyChangedPopupShown");
    window.localStorage.removeItem("profileSavedStatus");
    // this.clearGlobalUserCookie("profileSavedStatus");
    const globalUserCookie = this._appServ.getCookie(cookieSIMID);
    if (this.region === 'NA') {
      // sessionStorage.setItem("KCC.region", this.region);
      // sessionStorage.setItem("KCC.language", this.lang);
      if (this._appServ.isGlobalUser) {
        if (globalUserCookie) {
          this.httpServ.globalUserAuditLog(this.getAuditLogoutRequest()).subscribe((response) => {
            location.href = headerLinks.logoutLink;
          });
        } else {
          location.href = headerLinks.logoutLink;
        }
      }
      else {
        location.href = headerLinks.logoutLink;
      }
    }
    else {
      if (globalUserCookie) {
        this.httpServ.globalUserAuditLog(this.getAuditLogoutRequest()).subscribe((response) => {
          this.router.navigateByUrl("/logout");
        });
      } else {
        this.router.navigateByUrl("/logout");
      }

    }
  }

  closeAllSidebars() {
    this.visibleSettings = false;
    this.visibleSettingsGlobalHomePage = false;
    this.visibleHamburger = false;
    this.showHamburger = false;
    this.visiblePreferenceMenu = false;
    this.visibleSupport = false;
  }

  redirectHome() {
    this.closeAllSidebars();
    if (!(this.router.url.indexOf("/delegatedUserSearch") > -1)) {
      this.router.navigate(["/home"],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
      this.parentValue = 'home';
      if (this.parentValue == 'settings') {
        this.toggleSidebar('settings')
      }
      else if (this.parentValue == 'support') {
        this.toggleSidebar('support')
      }
      else if (this.parentValue == 'menu') {
        this.toggleSidebar('menu')
      }
    }
  }

  closeOpenMenu(menu) {

  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationEnd) {
      this.isMyProfilePage = false;
      let currentURL = this.router.url;
      if (event.urlAfterRedirects == '/') {
        this.isCVT = false;
      }
      else{
        this.setHeaderText(currentURL, 'onInit');
      }
    }
  }

  setHeaderText(currentURL, startPoint){
    if (currentURL.indexOf("/pa/") > -1) {
      this.headerTextIndex = "paDetailsHeader";
      this.checkCVTView(false);
    }
    else if (currentURL.indexOf("/priceagreement/") > -1) {
      this.headerTextIndex = "priceAgreementHeader";
      this.checkCVTView(true);
    }
    else if (currentURL.indexOf("/rebateprice/") > -1) {
      this.headerTextIndex = "priceAgreementHeader";
      this.checkCVTView(true);
    }
    else if (currentURL.indexOf("/profile/") > -1) {
      this.headerTextIndex = "myProfileHeader";
      this.isMyProfilePage = true;
      this.checkCVTView(false);
    }
    else if (currentURL.indexOf("/contactus") > -1) {
      this.headerTextIndex = "contactUsHeader";
      this.checkCVTView(false);
    }
    else if (currentURL.indexOf("/delegatedUserSearch") > -1) {
      this.headerTextIndex = "delegatedHeader";
      this.hideIfDelegatedSearchPage = true;
      // Show internal user tag in header and also show Internal user details on top right corner.
      this.showGlobalUserTag = true;
      this.clearGlobalUserCookie(cookieSIMID);
      this.httpServ.getUserProfile(this._appServ.getuserId(true)).subscribe((data) => {
        if ((data && data['defaultCustomerInfo'] && data['defaultCustomerInfo'].length)) {
          this._appServ.setuserProfileData(data);
          this.userProfile = data;
        }
      });
    }
    else if (currentURL.indexOf("/delegatedUserSearch/(delegatedResults") > -1) {
      this.headerTextIndex = "delegatedHeader";
      this.hideIfDelegatedSearchPage = true;
      // Show internal user tag in header and also show Internal user details on top right corner.
      this.showGlobalUserTag = true;
    }
    else if (currentURL.indexOf("/meetcomps") > -1) {
      this.headerTextIndex = "meetcomps";
      this.checkCVTView(false);
    }
    else if (currentURL.indexOf("/chargeback") > -1) {
      this.headerTextIndex = "chargeback";
      this.checkCVTView(false);
    }
    else if (currentURL.indexOf("/invoice") > -1) {
      this.headerTextIndex = "invoiceHeader";
      this.checkCVTView(false);
    }
    else if ((currentURL.indexOf("/portalusage") > -1) || (currentURL.indexOf("/uc-mapping") > -1) || (currentURL.indexOf("/reports") > -1)){
      this.headerTextIndex = "siteadminHeader";
      this.checkCVTView(false);
    }
    else if (currentURL.indexOf("/home") > -1) {
      this.checkCVTView(false);
      this.headerTextIndex = "primaryHeader";
      if(startPoint === 'onInit'){
        this._appServ.setLoaderStatusForHeaderFlagImage(true);
      }
    }
    else {
      this.headerTextIndex = "primaryHeader";
      this.checkCVTView(false);
    }
  }

  getOktaset() {
    if (this.userProfile) {
      // this.user = this.userProfile["Customer_Information"];
      this.user = this.userProfile['defaultCustomerInfo'] ? this.userProfile['defaultCustomerInfo'][0] : null;
      // if (this.isInternalUser) {
      //   this.myprofileLink = 'https://localhost:34025/profile/efileKcfileAccesslinkmyprofile';
      // } else {
      //   this.myprofileLink = 'https://localhost:34025/profile/myprofile';
      // }

      this.getUserName();
    }
  }

  profileLink($event) {
    this.router.navigate(['profile'],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
    this.visibleSettings = false;
  }

  reportsLink() {
    this.router.navigateByUrl('reports');
    this.visibleSettings = false;
  }

  cmtReportsLink() {
    window.open(CMTReportsURL, "_blank");
    this.visibleSettings = false;
  }
  
  getUserName() {
    this._simUserId = this._appServ.getuserId(false);
    if (this.oktaProfile) {
      if (this._appServ.getCookie(cookieSIMID) && this._appServ.isGlobalUser) {
        this.isGlobalUser = true;
        this._username = this._appServ.getCookie(cookieSIMID).substring(this._appServ.getCookie(cookieSIMID).indexOf('~^') + 2, this._appServ.getCookie(cookieSIMID).indexOf('~*'));
        // this.customerNumber = this._appServ.getCookie(cookieSIMID).substring(0, this._appServ.getCookie(cookieSIMID).indexOf('~^'));
      } else {
        this.isGlobalUser = false;
        this._username = this.oktaProfile.given_name + " " + this.oktaProfile.family_name;
        // this.customerNumber = this.oktaProfile.sAMAccountName;
      }
    }
  }

  getGlobalUserExternal() {
    if (this.oktaProfile) {
      if (this._appServ.getCookie(cookieSIMID) && this._appServ.isGlobalUser) {
        this.appRole = this._appServ.getCookie(cookieSIMID).substring(this._appServ.getCookie(cookieSIMID).indexOf('~$') + 2, this._appServ.getCookie(cookieSIMID).length);
      }
      // (this.appRole === "AdminUser") ? this.isExternalAdminUser = true : this.isExternalAdminUser = false;//NormalUser
      if (this.appRole === "AdminUser") {
        this._appServ.setExternalAdminUser(true);
      }
      else {
        this._appServ.setExternalAdminUser(false);
      }
    }
  }

  onCountryChange(e) {
    this.displayLanguage = this.locationLanguageService.getLanguagesForCountry(this._globalAppService.getDefaultRegionString(), e.value);
    let selectedCountry = [];
    selectedCountry = this.displayCountry.filter(element => {
      return element.value === e.value;
    });
    this._globalAppService.setSalesOrg(selectedCountry[0].salesOrg);
    this.country = selectedCountry[0].value;
    this._globalAppService.setDefaultCountry(this.county);
    this.selecteCountry = selectedCountry[0].value.toLowerCase();
    this.preferredLanguage = 'en-' + e.value;
    sessionStorage.setItem("KCC.language", e.value);
    this._globalAppService.setSelectedCountryLang(this.country, this.preferredLanguage, selectedCountry[0].salesOrg);
    this.languageServ.setlanguage('en-' + e.value.toLowerCase());
    this._globalAppService.setDefaultLanguage('en-' + e.value);
    this._globalAppService.setCountryFromMenu(this.country);
    this._globalAppService.setLangFromMenu('en-' + e.value);
    this.createSaveProfile(this.country, 'en-' + e.value, this.userName, selectedCountry[0].salesOrg);
    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}, 
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  createProfileRequest(country, language, userName, salesOrg) {
    this.profileRequest = new saveUpdateUserProfile();
    this.profileRequest.country = country;
    this.profileRequest.language = language;
    this.profileRequest.userName = userName;
    this.profileRequest.salesOrg = salesOrg;
    return this.profileRequest;
  }
  createSaveProfile(country, language, userName, salesOrg) {
    let profileRequest: saveUpdateUserProfile = this.createProfileRequest(country, language, userName, salesOrg);
    this.httpServ.saveUpdateUserProfile(profileRequest).subscribe(data => {
      this.setMasterDetailsLocations();
    })
  }

  onLanguageChange(e) {
    this._globalAppService.setSelectedCountryLang(this.country, e.value.toLowerCase(), this._globalAppService.getSalesOrg());
    this.languageServ.setlanguage(e.value.toLowerCase());
    sessionStorage.setItem("KCC.language", e.value);
    this._globalAppService.setLangFromMenu(e.value);
    this._globalAppService.setDefaultLanguage(e.value);

    this.createSaveProfile(this.country, e.value, this.userName, this._globalAppService.getSalesOrg());
    this._globalAppService.setDefaultCountry(this.country);
    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}, 
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  walkmeSetUp(e) {
    this._globalAppService.startWalkMe();
  }

  ucMapping() {
    this.router.navigate(["/uc-mapping/dashboard/search"],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
    this.visibleSettings = false;
  }
  portalUsage() {
    this.router.navigate(["/portalusage/dashboard"],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
    this.visibleSettings = false;
  }
  // kcFile(){
  //   this.router.navigateByUrl('/chargeback/kcfile/dashboard');
  //   this.visibleSettings = false;
  // }
  efileKcfileAccesslink() {
    this.router.navigateByUrl('/efilekcfileaccess/dashboard');
    this.visibleSettings = false;
  }
  contactUs() {
    this.router.navigate(["/contactus"],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
    this.visibleSupport = false;
  }
  
  openAccessManagementLink() {
    if(this.region === 'EMEA' && !this.isInternalUser){
      this.router.navigateByUrl("/manageuser/dashboard");
    }
    else if(this.region === 'NA'){
      window.open(this.manageUserMenuLink, "_blank")
    }
    else if(this.region === 'LAO'){
      let link = this.applink.manageUserLinkLAO;
      window.open(link, "_blank")
    }
    this.visibleSettings = false;
  }

  changeGlobalUser() {
    window.localStorage.removeItem("profileSavedStatus");
    // this.clearGlobalUserCookie("profileSavedStatus");
    this.actionBtnMenu.hide();
    const globalUserCookie = this._appServ.getCookie(cookieSIMID);
    /**Clear the cookie and get the logged in user details to reflect the user details section when click on select external user */
    const auditlogoutRequest = this.getAuditLogoutRequest();
    this.clearGlobalUserCookie(cookieSIMID);
    this.setMasterDetailsLocations();

    if (globalUserCookie) {
      this.httpServ.globalUserAuditLog(auditlogoutRequest).subscribe((response) => {
        this.router.navigate(["/delegatedUserSearch"],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
      });
    } else {
      this.router.navigate(["/delegatedUserSearch"],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
    }
  }

  loginExistingUser() {
    window.localStorage.removeItem("impersonatedUserRoles");
    window.localStorage.removeItem("impersonatedUserRegions");
    window.localStorage.removeItem("profileSavedStatus");
    // this.clearGlobalUserCookie("profileSavedStatus");
    this.actionBtnMenu.hide();
    const globalUserCookie = this._appServ.getCookie(cookieSIMID);
    if (globalUserCookie) {
      this.httpServ.globalUserAuditLog(this.getAuditLogoutRequest()).subscribe((response) => {
        if (this.region == 'NA') {
          let oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
          let qsList: Array<string> = [
            'userid=' + this._appServ.getuserId(true),
            "useremail=" + oktaStorage.idToken.claims.email
          ];
          this.clearGlobalUserCookie(cookieSIMID);
          window.open(globalUserLink + qsList.join("&"), '_self');
        } else {
          this.clearGlobalUserCookie(cookieSIMID);
          window.location.href = APP_LEGACY_MENU_HOST;
        }
      });
    } else if (this.isDelegatedPageOpen) {
      this.clearGlobalUserCookie(cookieSIMID);
      window.location.href = APP_LEGACY_MENU_HOST;
    }
  }

  getAuditLogoutRequest() {
    return {
      "globalUserId": this._appServ.getuserId(true),
      "externalUserId": this._appServ.getuserId(false),
      "action": 2
    };
  }

  termsCondition() {
    this.router.navigate(["/pricing"],{queryParams:  {region: localStorage.getItem('default-region'),country: localStorage.getItem('country'), language: localStorage.getItem('country-lang')}});
  }

  clearGlobalUserCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

}


