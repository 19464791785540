import { Component, OnInit, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { AgRendererComponent } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { currency } from '@app/conf/dataConfigurations';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';

@Component({
  selector: 'number-formatter',
  templateUrl: './number-formatter.component.html',
  styleUrls: ['./number-formatter.component.scss']
})
export class NumberFormatterComponent implements AgRendererComponent, OnDestroy {
  params: any;
  currencyCode: string;
  subscription: Subscription = new Subscription();
  formattedValue: boolean;
  numericValue: string;
  LCIDString: any;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private translateServ: TranslateService,
    private languageServ: LanguageSelectionService,
    private gridService: CpAgGridService,
    private globalAppServcie: GlobalAppService
  ) {
    this.translateServ.get(['LCIDString'])
      .subscribe(value => {
        if (value)
          this.LCIDString = value.LCIDString;
      });
  }

  agInit(params: any): void {
    this.setLanguageListener();
    this.params = params;
    this.formatNumber();
  }

  refresh(params: any): boolean {
    return false;
  }

  setLanguageListener() {
    this.subscription.add(this.languageServ.getlanguage().subscribe((language) => {
      this.translateServ.get(['LCIDString'])
        .subscribe(value => {
          if (value) {
            this.LCIDString = value.LCIDString;
            this.formatNumber();
          }
        });
    }));
  }

  formatNumber() {
    if (this.gridService.pageContext.pageName == 'recentDownload') {
      if (this.params && this.params.value) {
        let fieldValue = this.params.value / 1024;
        let fieldValueArray = fieldValue.toString().split('.');
        if (this.params.value >= 1024) {
          // this.numericValue = fieldValueArray[0] + " KB";
          // let str1 = parseFloat(fieldValueArray[0]).toLocaleString("en-US", {
          //   minimumFractionDigits: 2
          // });
          let str1 = parseFloat(fieldValueArray[0]).toLocaleString("en-US");
          if (this.globalAppServcie.getDefaultRegionString() === 'LAO') {
            // CR 1378 - Use . as thousand separator even for Peru and other sales org in LAO            
            let str2 = str1.replace(/\./g, '@');
            let str3 = str2.replace(/,/g, '.');
            let str4 = str3.replace(/@/g, ',');
            this.numericValue = str4 + " KB";
            // let locationLanguage = this.globalAppServcie.getSelectedCountryLang();
            // if (((locationLanguage && locationLanguage.country) || this.globalAppServcie.getDefaultCountryString()) == 'PE') {
            //   let str2 = str1.replace(/,/g, ' ');
            //   this.numericValue = str2.replace(/\./g, ',') + " KB";
            // }
            // else {
            //   let str2 = str1.replace(/\./g, '@');
            //   let str3 = str2.replace(/,/g, '.');
            //   let str4 = str3.replace(/@/g, ',');
            //   this.numericValue = str4 + " KB";
            // }
          }
          else {
            this.numericValue = str1 + " KB";
          }
        }
        else {
          // this.numericValue = this.params.value + " Bytes";
          // let str1 = parseFloat(fieldValueArray[0]).toLocaleString("en-US", {
          //   minimumFractionDigits: 2
          // });
          let str1 = parseFloat(fieldValueArray[0]).toLocaleString("en-US");
          if (this.globalAppServcie.getDefaultRegionString() === 'LAO') {
            // CR 1378 - Use . as thousand separator even for Peru and other sales org in LAO            
            let str2 = str1.replace(/\./g, '@');
            let str3 = str2.replace(/,/g, '.');
            let str4 = str3.replace(/@/g, ',');
            this.numericValue = str4 + " Bytes";
            // let locationLanguage = this.globalAppServcie.getSelectedCountryLang();
            // if (((locationLanguage && locationLanguage.country) || this.globalAppServcie.getDefaultCountryString()) == 'PE') {
            //   let str2 = str1.replace(/,/g, ' ');
            //   this.numericValue = str2.replace(/\./g, ',') + " Bytes";
            // }
            // else {
            //   let str2 = str1.replace(/\./g, '@');
            //   let str3 = str2.replace(/,/g, '.');
            //   let str4 = str3.replace(/@/g, ',');
            //   this.numericValue = str4 + " Bytes";
            // }
          }
          else {
            this.numericValue = str1 + " Bytes";
          }
        }
      }
    }
    else if (this.gridService.pageContext.pageName == 'padetailproduct') {
      if (this.params && this.params.value) {
        let str1 = parseFloat(this.params.value).toLocaleString("en-US");
        // CR 1378 - Use . as thousand separator even for Peru and other sales org in LAO
        let str2 = str1.replace(/\./g, '@');
        let str3 = str2.replace(/,/g, '.');
        let str4 = str3.replace(/@/g, ',');
        this.numericValue = str4;
        // if (this.params.data.currency && this.params.data.currency.trim() === 'PEN') {
        //   let str2 = str1.replace(/,/g, ' ');
        //   this.numericValue = str2.replace(/\./g, ',');
        // }
        // else {
        //   let str2 = str1.replace(/\./g, '@');
        //   let str3 = str2.replace(/,/g, '.');
        //   let str4 = str3.replace(/@/g, ',');
        //   this.numericValue = str4;
        // }
      }
    }
    else {
      if (this.params && this.params.data) {
        // let str1 = parseFloat(this.params.value).toLocaleString("en-US", {
        //   minimumFractionDigits: 2
        // });        
        let str1 = parseFloat(this.params.value).toLocaleString("en-US");

        if (this.globalAppServcie.getDefaultRegionString() === 'LAO') {
          // CR 1378 - Use . as thousand separator even for Peru and other sales org in LAO            
          let str2 = str1.replace(/\./g, '@');
          let str3 = str2.replace(/,/g, '.');
          let str4 = str3.replace(/@/g, ',');
          this.numericValue = str4;
          // let locationLanguage = this.globalAppServcie.getSelectedCountryLang();
          // if (((locationLanguage && locationLanguage.country) || this.globalAppServcie.getDefaultCountryString()) == 'PE') {
          //   let str2 = str1.replace(/,/g, ' ');
          //   this.numericValue = str2.replace(/\./g, ',');
          // }
          // else {
          //   let str2 = str1.replace(/\./g, '@');
          //   let str3 = str2.replace(/,/g, '.');
          //   let str4 = str3.replace(/@/g, ',');
          //   this.numericValue = str4;
          // }
        }
        else {
          this.numericValue = str1;
        }
      }
    }
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }

}
