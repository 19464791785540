/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contextmenu-advanced-invoice.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../../../node_modules/primeng/components/overlaypanel/overlaypanel.ngfactory";
import * as i5 from "primeng/components/overlaypanel/overlaypanel";
import * as i6 from "./contextmenu-advanced-invoice.component";
import * as i7 from "../../../../Shared/Services/http-invoice-price.service";
import * as i8 from "../../../../Shared/Services/app.service";
import * as i9 from "@angular/router";
import * as i10 from "primeng/components/common/messageservice";
import * as i11 from "../../../../in-voice/Services/invoice-price.service";
var styles_ContextmenuAdvancedInvoiceComponent = [i0.styles];
var RenderType_ContextmenuAdvancedInvoiceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContextmenuAdvancedInvoiceComponent, data: {} });
export { RenderType_ContextmenuAdvancedInvoiceComponent as RenderType_ContextmenuAdvancedInvoiceComponent };
function View_ContextmenuAdvancedInvoiceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "custom-table__row-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "i", [["class", "fas fa-ellipsis-h ellipsisbtn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.contextmenuToggle(i1.ɵnov(_v.parent, 4), $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "ellipseSelect": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fas fa-ellipsis-h ellipsisbtn"; var currVal_1 = _ck(_v, 3, 0, _co.ellipseSelect); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ContextmenuAdvancedInvoiceComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadInvoice() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "far fa-arrow-alt-circle-down"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("invoicePrice.contextMenuAdvanced.downloadInvoiceLabel")); _ck(_v, 3, 0, currVal_0); }); }
function View_ContextmenuAdvancedInvoiceComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshInvoice() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-refresh"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("invoicePrice.contextMenuAdvanced.refreshInvoiceLabel")); _ck(_v, 3, 0, currVal_0); }); }
export function View_ContextmenuAdvancedInvoiceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContextmenuAdvancedInvoiceComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 7, "p-overlayPanel", [["appendTo", "body"], ["styleClass", "contextMenu"]], null, [[null, "onHide"], [null, "onShow"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onHide" === en)) {
        var pd_0 = (_co.onContextMenuHide() !== false);
        ad = (pd_0 && ad);
    } if (("onShow" === en)) {
        var pd_1 = (_co.onContextMenuShow() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_OverlayPanel_0, i4.RenderType_OverlayPanel)), i1.ɵdid(4, 180224, [["contextmenu", 4]], 0, i5.OverlayPanel, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.NgZone], { styleClass: [0, "styleClass"], appendTo: [1, "appendTo"] }, { onShow: "onShow", onHide: "onHide" }), (_l()(), i1.ɵeld(5, 0, null, 0, 5, "span", [["class", "custom-table__popup__info custom-table__row-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "ul", [["class", "custom-table__row-menu__content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContextmenuAdvancedInvoiceComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContextmenuAdvancedInvoiceComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showMenu; _ck(_v, 2, 0, currVal_0); var currVal_1 = "contextMenu"; var currVal_2 = "body"; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = (((_co.params.data == null) ? null : _co.params.data.refreshStatus) == 2); _ck(_v, 8, 0, currVal_3); var currVal_4 = (((_co.params.data == null) ? null : _co.params.data.refreshStatus) == 3); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_ContextmenuAdvancedInvoiceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contextmenu-advanced-invoice", [], null, null, null, View_ContextmenuAdvancedInvoiceComponent_0, RenderType_ContextmenuAdvancedInvoiceComponent)), i1.ɵdid(1, 114688, null, 0, i6.ContextmenuAdvancedInvoiceComponent, [i7.HttpInvoicePriceService, i8.AppService, i9.Router, i9.ActivatedRoute, i10.MessageService, i11.InvoicePriceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContextmenuAdvancedInvoiceComponentNgFactory = i1.ɵccf("app-contextmenu-advanced-invoice", i6.ContextmenuAdvancedInvoiceComponent, View_ContextmenuAdvancedInvoiceComponent_Host_0, {}, {}, []);
export { ContextmenuAdvancedInvoiceComponentNgFactory as ContextmenuAdvancedInvoiceComponentNgFactory };
