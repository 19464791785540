// import { LoginComponent } from './login/login.component';
import { oktaConfig } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
export function createTranslateLoader(http) {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/i18n/app-modules/shared/', suffix: ".json" },
        { prefix: "./assets/i18n/errors/", suffix: ".json" },
    ]);
}
var ɵ0 = oktaConfig;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
