import { Component, OnDestroy, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription, ObjectUnsubscribedError } from 'rxjs';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { MyProfileService } from '@app/Modules/my-profile/Components/services/my-profile-service';

@AutoUnsubscribe()

@Component({
  selector: 'app-myLocation-internal-cr',
  templateUrl: './myLocation-internal-cr.component.html',
  styleUrls: ['./myLocation-internal-cr.component.scss']
})
export class LocationCRComponent implements AgRendererComponent, OnInit, OnDestroy {
  
  currentNode: any;
  test: boolean;
  subscription: Subscription = new Subscription();
  checkboxName: string;
  check:boolean = false;
  highlightGroup: boolean = false;
  highlightChild: boolean = false;
  eachNodeStatus: boolean;
  isCheckboxDisabled:boolean = false;

  constructor(
    private agGridService: CpAgGridService,
    private myProfileService: MyProfileService
  ) {  }

  agInit(params: any): void {
    this.currentNode = params;

    if (!this.checkboxName) {
      this.checkboxName = Math.random().toString(36).substring(2, 15);
    }
    if (params && params.data && params.data.isDefault){
      this.check = params.data.isDefault;
      this.isCheckboxDisabled = true;
    } 
    let newlyAddedLocations = this.myProfileService.getNewlyAddedLocation();
    console.log("@@Newlyadded Locatiosn Are: ",newlyAddedLocations);
    console.log("@@currentNode: ",params.data);
    if(newlyAddedLocations){
      for(let index = 0; index<=newlyAddedLocations.length - 1; index++){
        if(newlyAddedLocations[index].parentCustomerNumber == params.data.parentCustomer){
          this.highlightGroup = true;
        }

        if(params.data.customerNumber && newlyAddedLocations[index].childCustomerNumber == params.data.customerNumber ){
          this.highlightChild = true;
        }
      }
    }
    
  }
  ngOnInit(){
  
    this.myProfileService.getDefaultLocation().subscribe((customerNumber)=>{
      if(this.currentNode.data.customerNumber == customerNumber){
        this.isCheckboxDisabled = true;
        this.check = true;
        this.currentNode.data.isDefault = true;
      }else{
        this.isCheckboxDisabled = false;
        this.check = false;
        this.currentNode.data.isDefault = false;
      }

    })

  }

  refresh(params: any): boolean {
    return false;
  }

  checkboxClick(value){
    this.check = !this.check;
    this.currentNode.data.isDefault = value.target.checked;
    this.currentNode.node.setSelected(value.target.checked);
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }

}
