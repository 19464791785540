// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { endPoints_PA_QA } from './endpoints/endpoints-PA/endpointsPA.qa1';
import { endPoints_CB_QA } from './endpoints/endpoints-CB/endpointsCB.qa1';
import { endPoints_IN_QA } from './endpoints/endpoints-IN/endpointsIN.qa1';
import { endPoints_IN_NEW_QA } from './endpoints/endpoints-IN-new/endpointsIN-new.qa1';
import { endpoints_MC_QA } from './endpoints/endpoints-MC/endpointsMC.qa1';
import { endPoints_SiteAdmin_QA } from './endpoints/endpoints-SiteAdmin/endpointsSiteAdmin.qa1';

const environment = {
	...endPoints_PA_QA,
	...endPoints_CB_QA,
	...endpoints_MC_QA,
	...endPoints_IN_NEW_QA,
	...endPoints_IN_QA,
	...endPoints_SiteAdmin_QA,
	getImpersonatedUserInfo:{
		url: 'https://us1.api.kimclark.com/kcc-sls-ang-kcpuser-pxy-api-pdqa/NA/v2/Okta/GetOktaUserRoles?ebamUserId=XXX_EBAMID_XXX',
		method: 'GET'
	},

	production: false,
	appInsightsConfig: {
		instrumentationKey: "aa540c76-6ffa-48a6-8a15-bc4079a193b3"
	},
	GTMpreviewVersion: 0
};


const SPART_HOST = "http://qa1.sfdcpricing.kcc.com";
const APP_LEGACY_MENU_HOST = "https://qa.portal.kcprofessional.com";
// const APP_LEGACY_MENU_HOST_OLD = "https://qa4.na.kccustomerportal.com"
const APP_LEGACY_MENU_HOST_OLD = "https://qa1.na.kccustomerportal.com";
const SPA_LEGACY_MENU_HOST = "/pa/details/";
const contentMangement = "https://qa.nacustomerportal.kcc.com/Customerportal/UI/Site%20Admin%20Center/Home.aspx";

const oktaConfig = {
	// clientId: "0oapy5brqxz6SMNEK0h7",
	clientId: "0oatfsbujmARZH0VD0h7",
	// clientSecret: "bNKlh48kq2YHX3NXB-Rjf2_TeXHsnvBL9pVmKNs6",
	issuer: 'https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7',
	redirectUri: APP_LEGACY_MENU_HOST + '/implicit/callback',
	// responseType: ["token", "id_token"],
	responseType: ["code"],
	scopes: ["openid", "profile", ".kc.samaccountname", "email", "phone"],
	storage: "sessionStorage",
	autoRenew: true,
	pkce: true
};

// const oktaConfig = {
// 	clientId: "0oapy5brqxz6SMNEK0h7",
// 	clientSecret: "bNKlh48kq2YHX3NXB-Rjf2_TeXHsnvBL9pVmKNs6",
// 	issuer: 'https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7',
// 	redirectUri: APP_LEGACY_MENU_HOST + '/implicit/callback',
// 	responseType: ["token", "id_token"],
// 	scopes: ["openid", "profile", ".kc.samaccountname", "email", "phone"],
// 	storage: "sessionStorage",
// 	autoRenew: true,
// 	pkce: false
// };

const IdleTimeOutConfig = {
	setIdle: 6900,
	setTimeout: 300,
	setInterval: 15
}

// const globalUser = "INUSROAP03";
const globalUser = {
	NA: ['K-C_CustomerPortal_Delegated_P','K-C_CustomerPortal_Delegated_QD'],
	LAO: ['K-C_CustomerPortal_Delegated_P_LAO','K-C_CustomerPortal_Delegated_QD_LAO'],
	EMEA: ['K-C_CustomerPortal_Delegated_P_EMEA', 'K-C_CustomerPortal_Delegated_QD_EMEA']
}
// const globalUser = ["C_CustomerPortal_Users_QD"];
const externalAdminRole = ['admins@bp'];
const efileAccessRole = ["NAKCP_E-File", "EMEAKCP_E-File"];
const kcfileAccessRole = ["EMEAKCP_K-Cfile", "K-C_CustomerPortal_Users_QD_EMEA", "K-C_CustomerPortal_Users_P_EMEA"];
const cookieSIMID = 'IntegratedUserId';
const cookieLoggedInUserId = 'loggedInUserId';
const CAPTCHA_SITE_KEY = "6Lck0OAUAAAAAP74bH7BSzlRLVvcMLkfOqEStwQ8";
const efileHistoryLegacyDomain = "https://qa.nacustomerportal.kcc.com";
const efileHistoryLegacyDomainEMEA = "http://qa.eucustomerportal.kcc.com";



/*
 * For easier debugging in qaelopment mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export {
	environment, oktaConfig, APP_LEGACY_MENU_HOST, globalUser, cookieSIMID,
	cookieLoggedInUserId, contentMangement, SPART_HOST, IdleTimeOutConfig, SPA_LEGACY_MENU_HOST,
	CAPTCHA_SITE_KEY, externalAdminRole, efileAccessRole, kcfileAccessRole, APP_LEGACY_MENU_HOST_OLD, efileHistoryLegacyDomain,efileHistoryLegacyDomainEMEA
}