import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { GlobalAppService } from './global-app.service';

@Injectable({
  providedIn: 'root'
})
export class HttpSiteadminService {

  constructor(
    private http: HttpClient,
    private _globalAppService: GlobalAppService
  ) { }

  getReports(request): Observable<any> {
    return this.makeHTTPCall(environment.getReports.method, this.getRegionSpecificURL(environment.getReports), null, request);
  }

  makeReportDownloadCall(request, reportId): Observable<any> {
    let apiUrl;
    switch (reportId) {
      case 1:
      case 2:
      case 8:
      case 10:
        //apiUrl = environment.getEmailNotificationReports;
        //apiUrl = environment.getUserMappingListReports;
        //apiUrl = environment.getViewPAcomment;
        //apiUrl = environment.getExpectedVolumeReport;        
        apiUrl = environment.getReportGeneric;
        break;
      case 3:
        apiUrl = environment.getCustomerPriceHistory;
        break;
      case 4:
        apiUrl = environment.getEfileCGBCompany;
        break;
      case 5:
        apiUrl = environment.getExpectedDateToPay;
        break;
      case 6:
        apiUrl = environment.getCustomerHistory;
        break;
      case 7:
        apiUrl = environment.getDaysToDisplayList;
        break;    
      case 9:
        apiUrl = environment.getCBAcceptancePolicyList;
        break;     
    }
    return this.makeHTTPCall(apiUrl.method, this.getRegionSpecificURL(apiUrl), null, request);
  }

  removeUcmappings(request) {
    return this.makeHTTPCall(environment.removeUcMappings.method, this.getRegionSpecificURL(environment.removeUcMappings), null, request);
  }

  saveUcMappings(request): Observable<any> {
    return this.makeHTTPCall(environment.addUcMappings.method, this.getRegionSpecificURL(environment.addUcMappings), null, request);
  }

  triggerUCMSync(request): Observable<any> {
    return this.makeHTTPCall(environment.UCMSync.method, this.getRegionSpecificURL(environment.UCMSync), null, request);
  }

  getStates(request: any): Observable<any> {
    return this.makeHTTPCall(environment.getStates.method, this.getRegionSpecificURL(environment.getStates), null, request);
  }

  getUcMappings(request): Observable<any> {
    return this.makeHTTPCall(environment.ucMappings.method, this.getRegionSpecificURL(environment.ucMappings), null, request);
  }

  markAsAdmin(request) {
    return this.makeHTTPCall(environment.markAsAdmin.method, this.getRegionSpecificURL(environment.markAsAdmin), null, request);
  }

  getCustomerHierarchy(request): Observable<any> {
    return this.makeHTTPCall(environment.customerMappings.method, this.getRegionSpecificURL(environment.customerMappings), null, request);
  }

  exportPortalUsage(request): Observable<any> {
    return this.makeHTTPCall(environment.exportPortalUsageInternal.method, this.getRegionSpecificURL(environment.exportPortalUsageInternal), null, request);
  }

  exportPortalUsageExternal(request): Observable<any> {
    return this.makeHTTPCall(environment.exportPortalUsageExternal.method, this.getRegionSpecificURL(environment.exportPortalUsageExternal), null, request);
  }

  getAccessManagementList(request) {
    let httpHeaders: HttpHeaders = new HttpHeaders({ 'Accept-Language': request.languageCode });
    delete request.languageCode;
    return this.makeHTTPCall(environment.getAccessManagementList.method, this.getRegionSpecificURL(environment.getAccessManagementList), httpHeaders, request);
  }

  manageUserFeatures(request) {
    return this.makeHTTPCall(environment.getAccessManagementList.method, this.getRegionSpecificURL(environment.getAccessManagementList), null, request);
  }
  
  manageUsersSubmit(request){
    return this.makeHTTPCall(environment.manageUsersSubmit.method, this.getRegionSpecificURL(environment.manageUsersSubmit), null, request);
  }

  makeHTTPCall(method: string, endPoint: string, additionalHeaders: any = null, requestParams: any = null): Observable<any> {
    let requestBody = {};
    if (additionalHeaders) {
      requestBody = Object.assign({}, requestBody, { headers: additionalHeaders });
    }
    if (requestParams) {
      requestBody = Object.assign({}, requestBody, { body: requestParams });
    }

    return this.http.request(method, endPoint, requestBody);
  }

  getRegionSpecificURL(item: any) {
    let regionURL = "";
    let region = localStorage.getItem('default-region') || this._globalAppService.getDefaultRegionString() || this._globalAppService.selectedRegion;
    if (region) {
      regionURL = "url_" + region.toUpperCase();
    }
    else {
      regionURL = "url_LAO";
    }
    return item[regionURL];
  }

  validateCustomerGroupMapping(request): Observable<any> {
    return this.makeHTTPCall(environment.validateCustomerGroupMapping.method, this.getRegionSpecificURL(environment.validateCustomerGroupMapping), null, request);
  }

}
