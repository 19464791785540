import { Component, OnInit, Output, EventEmitter, ViewContainerRef, ViewChild, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HttpChargeBackService } from '@app/Modules/Shared/Services/http-charge-back.service';
import { style, animate, transition, trigger } from '@angular/animations';
import { CbService } from '@app/Modules/chargeback/Services/cb.service';
import { TranslateService } from '@ngx-translate/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';

@Component({
  selector: 'app-type-ahead',
  templateUrl: './type-ahead.component.html',
  styleUrls: ['./type-ahead.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class TypeAheadComponent implements OnInit {
  @Output() selectedCustEU = new EventEmitter<string[]>();
  selectedCustomers = [];
  userInput = new Subject<string>();
  searchText = "";
  returnedCustEUList = [];
  mappedCustEUList = [];
  couponDetailsData: any;
  @ViewChild('input', { read: ViewContainerRef }) public couponNumberInput;
  @Input() optionData: any;
  @Input() placeHolder: any;
  constructor(private httpService: HttpChargeBackService,
    private _cbService: CbService,
    private translateServ: TranslateService) { }

  ngOnInit() {
    this.couponDetailsData = this._cbService.couponDetails;
    this.userInput.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this.getCustomerEU(value);
      });
    this._cbService.getCustEUList().subscribe(data => {
      if (data && data.length) {
        this.selectedCustomers = [];
        data.forEach(element => {
          this.selectedCustomers.push(element);
        });
      } else {
        this.selectedCustomers = [];
      }
      this.mappedCustEUList = [];
      this.searchText = "";
      this.userInput.next("");
      // window.setTimeout(() => {
      //   this.couponNumberInput.element.nativeElement.focus();
      // });
    });
  }

  getCustomerEU(customerEU) {
    this.returnedCustEUList = [];
    if (customerEU != "") {
      if (!this.optionData) {
        let req = {
          customerEUNumber: customerEU,
          couponNumber: this.couponDetailsData[0].couponNumber,
          companyCode: this.couponDetailsData[0].companyCode,
          fiscalYear: this.couponDetailsData[0].fiscalYear,
          salesOrgId: this.couponDetailsData[0].salesOrgId,
          redirectFrom: this.couponDetailsData[0].redirectFrom
        };
        this.httpService.getCustomerEU(req).subscribe(customerEUList => {
          if (customerEUList.customerEu.length) {
            this.returnedCustEUList = customerEUList.customerEu;
            this.mapCheckedValues();
          } else {
            this.mappedCustEUList = [];
          }
        });
      } else {        
        if (this.optionData.length) {
          this.returnedCustEUList = this.optionData.filter(el => { return el.indexOf(customerEU) > -1 });
          this.mapCheckedValues();
        } else {
          this.mappedCustEUList = [];
        }
      }

      //   this.mappedCustEUList = [{customerEU: "7098789", checked: false},{customerEU: "6567877", checked: false},
      // {customerEU: "88999876", checked: false},{customerEU: "6776543", checked: false},{customerEU: "5224324", checked: false},
      // {customerEU: "456776", checked: false},{customerEU: "900876545", checked: false},{customerEU: "455678", checked: false},
      // {customerEU: "8879098", checked: false},{customerEU: "7776567", checked: false},{customerEU: "6887654", checked: false},
      // {customerEU: "556272788", checked: false},{customerEU: "926654768", checked: false},{customerEU: "9980776", checked: false},
      // {customerEU: "677898", checked: false},{customerEU: "8887654", checked: false},{customerEU: "3445267", checked: false}];
    } else {
      this.mappedCustEUList = [];
    }
  }

  mapCheckedValues() {
    this.mappedCustEUList = [];
    this.returnedCustEUList.map(custEU => {
      if (custEU && this.selectedCustomers && this.selectedCustomers.indexOf(custEU) > -1) {
        this.mappedCustEUList.push({
          customerEU: custEU,
          checked: true
        });
      } else if (custEU) {
        this.mappedCustEUList.push({
          customerEU: custEU,
          checked: false
        });
      }
    });
  }

  onCheckboxChange(e) {
    if (e.checked) {
      let customerInList = this.selectedCustomers.indexOf(e.customerEU);
      if (customerInList < 0)
        this.selectedCustomers.push(e.customerEU);
    } else {
      let index = this.selectedCustomers.indexOf(e.customerEU);
      this.selectedCustomers.splice(index, 1);
    }
  }

  removeCustomerEU(customerEU) {
    let index = this.selectedCustomers.indexOf(customerEU);
    this.selectedCustomers.splice(index, 1);
  }

  onApplyClick(event) {
    this.selectedCustEU.emit(this.selectedCustomers);
    // this.mappedCustEUList = [];
    // this.searchText = "";
    // this.userInput.next("");
  }

  onRemoveFilter() {
    this.selectedCustomers = [];
    //this.mapCheckedValues();
    this.selectedCustEU.emit(this.selectedCustomers);
  }

}
