import { Component, OnInit } from '@angular/core';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';

@Component({
  selector: 'app-customcheckbox',
  templateUrl: './customcheckbox.component.html',
  styleUrls: ['./customcheckbox.component.scss']
})
export class CustomcheckboxComponent implements OnInit {
  status: boolean;
  params: any;
  constructor(private gridService: CpAgGridService) { }

  

  refresh(params: any): boolean {
    return true;
  }

  agInit(params) {
    this.params = params;
    
  }

  ngOnInit(){
  }
}
