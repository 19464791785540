/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hyperlink-and-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./hyperlink-and-popup.component";
import * as i4 from "@angular/router";
var styles_HyperlinkAndPopupComponent = [i0.styles];
var RenderType_HyperlinkAndPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HyperlinkAndPopupComponent, data: {} });
export { RenderType_HyperlinkAndPopupComponent as RenderType_HyperlinkAndPopupComponent };
function View_HyperlinkAndPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLinkClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 1, 0, currVal_0); }); }
function View_HyperlinkAndPopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.value; _ck(_v, 1, 0, currVal_0); }); }
export function View_HyperlinkAndPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HyperlinkAndPopupComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HyperlinkAndPopupComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.params.data && _co.params.data.isMultipleChange); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.params.data && !_co.params.data.isMultipleChange); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_HyperlinkAndPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "hyperlink-and-popup", [], null, null, null, View_HyperlinkAndPopupComponent_0, RenderType_HyperlinkAndPopupComponent)), i1.ɵdid(1, 245760, null, 0, i3.HyperlinkAndPopupComponent, [i4.Router, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HyperlinkAndPopupComponentNgFactory = i1.ɵccf("hyperlink-and-popup", i3.HyperlinkAndPopupComponent, View_HyperlinkAndPopupComponent_Host_0, {}, {}, []);
export { HyperlinkAndPopupComponentNgFactory as HyperlinkAndPopupComponentNgFactory };
