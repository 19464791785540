import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { MyProfileService } from '@app/Modules/my-profile/Components/services/my-profile-service';
var MyprofileInternalPAComponent = /** @class */ (function () {
    function MyprofileInternalPAComponent(agGridService, myProfileService) {
        this.agGridService = agGridService;
        this.myProfileService = myProfileService;
        this.subscription = new Subscription();
        this.checkStatusPA = false;
        this.checkStatusMC = false;
        this.checkStatusCB = false;
        this.checkStatusIN = false;
        this.checkStatusOD = false;
        this.totalNumberOfRecord = 0;
        this.numberOfPASelected = 0;
    }
    MyprofileInternalPAComponent.prototype.agInit = function (params) {
        var _this = this;
        this.currentNode = params;
        this.checkboxNamePA = Math.random().toString(36).substring(2, 15);
        this.checkboxNameMC = Math.random().toString(36).substring(2, 15);
        this.checkboxNameCB = Math.random().toString(36).substring(2, 15);
        this.checkboxNameIN = Math.random().toString(36).substring(2, 15);
        this.checkboxNameOD = Math.random().toString(36).substring(2, 15);
        if (params && params.data && params.data.Preferences) {
            params.data.Preferences.forEach(function (prefernce) {
                if (prefernce.isSelected) {
                    switch (prefernce.moduleId) {
                        case 1: // Price Aggrement
                            _this.checkStatusPA = true;
                            _this.myProfileService.setnumberOfSelectedPA(params.data.customerNumber, 'Add');
                            break;
                        case 2: // Meet Comp
                            _this.checkStatusMC = true;
                            _this.myProfileService.setnumberOfSelectedMC(params.data.customerNumber, 'Add');
                            break;
                        case 3: // ChargeBack
                            _this.checkStatusCB = true;
                            _this.myProfileService.setnumberOfSelectedCB(params.data.customerNumber, 'Add');
                            break;
                        case 4: // Invoices
                            _this.checkStatusIN = true;
                            _this.myProfileService.setnumberOfSelectedIN(params.data.customerNumber, 'Add');
                            break;
                        case 5: // Orders
                            _this.checkStatusOD = true;
                            _this.myProfileService.setnumberOfSelectedOD(params.data.customerNumber, 'Add');
                            break;
                    }
                }
            });
        }
        if (params.data) {
            this.myProfileService.setTotalNumberOfPAs(params.data.customerNumber);
        }
    };
    MyprofileInternalPAComponent.prototype.ngOnInit = function () {
        var _this = this;
        //Select All Header Subscription Price Agreement
        this.subscription.add(this.agGridService.getMyProfilePASelectAll().subscribe(function (isSelecAll) {
            _this.updateCheckCount(isSelecAll, 1);
        }));
        //Select All Header Subscription Meet Comp
        this.subscription.add(this.agGridService.getMyProfileMCSelectAll().subscribe(function (isSelecAll) {
            _this.updateCheckCount(isSelecAll, 2);
        }));
        //Select All Header Subscription Price Agreement
        this.subscription.add(this.agGridService.getMyProfileCBSelectAll().subscribe(function (isSelecAll) {
            _this.updateCheckCount(isSelecAll, 3);
        }));
        //Select All Header Subscription Price Agreement
        this.subscription.add(this.agGridService.getMyProfileINSelectAll().subscribe(function (isSelecAll) {
            _this.updateCheckCount(isSelecAll, 4);
        }));
        //Select All Header Subscription Price Agreement
        this.subscription.add(this.agGridService.getMyProfileODSelectAll().subscribe(function (isSelecAll) {
            _this.updateCheckCount(isSelecAll, 5);
        }));
        this.defaultHeaderCheckBoxState();
    };
    MyprofileInternalPAComponent.prototype.updateCheckCount = function (isSelecAll, appModuleId) {
        var _this = this;
        if (this.currentNode.data && this.currentNode.data.Preferences)
            this.currentNode.data.Preferences.forEach(function (Preference) {
                if (Preference.moduleId == appModuleId) {
                    Preference.isSelected = isSelecAll;
                }
            });
        if (isSelecAll) {
            switch (appModuleId) {
                case 1:
                    this.checkStatusPA = true;
                    this.myProfileService.setnumberOfSelectedPA([], 'Reset');
                    if (this.currentNode && this.currentNode.api) {
                        this.currentNode.api.forEachNode(function (node, index) {
                            node.setExpanded(true);
                            if (node.data && node.data.customerNumber) {
                                _this.myProfileService.setnumberOfSelectedPA(node.data.customerNumber, 'Add');
                            }
                        });
                    }
                    break;
                case 2:
                    this.checkStatusMC = true;
                    this.myProfileService.setnumberOfSelectedMC([], 'Reset');
                    if (this.currentNode && this.currentNode.api) {
                        this.currentNode.api.forEachNode(function (node, index) {
                            node.setExpanded(true);
                            if (node.data && node.data.customerNumber) {
                                _this.myProfileService.setnumberOfSelectedMC(node.data.customerNumber, 'Add');
                            }
                        });
                    }
                    break;
                case 3:
                    this.checkStatusCB = true;
                    this.myProfileService.setnumberOfSelectedCB([], 'Reset');
                    if (this.currentNode && this.currentNode.api) {
                        this.currentNode.api.forEachNode(function (node, index) {
                            node.setExpanded(true);
                            if (node.data && node.data.customerNumber) {
                                _this.myProfileService.setnumberOfSelectedCB(node.data.customerNumber, 'Add');
                            }
                        });
                    }
                    break;
                case 4:
                    this.checkStatusIN = true;
                    this.myProfileService.setnumberOfSelectedIN([], 'Reset');
                    if (this.currentNode && this.currentNode.api) {
                        this.currentNode.api.forEachNode(function (node, index) {
                            node.setExpanded(true);
                            if (node.data && node.data.customerNumber) {
                                _this.myProfileService.setnumberOfSelectedIN(node.data.customerNumber, 'Add');
                            }
                        });
                    }
                    break;
                case 5:
                    this.checkStatusOD = true;
                    this.myProfileService.setnumberOfSelectedOD([], 'Reset');
                    if (this.currentNode && this.currentNode.api) {
                        this.currentNode.api.forEachNode(function (node, index) {
                            node.setExpanded(true);
                            if (node.data && node.data.customerNumber) {
                                _this.myProfileService.setnumberOfSelectedOD(node.data.customerNumber, 'Add');
                            }
                        });
                    }
                    break;
            }
        }
        else {
            switch (appModuleId) {
                case 1:
                    this.checkStatusPA = false;
                    this.myProfileService.setnumberOfSelectedPA([], 'Reset');
                    break;
                case 2:
                    this.checkStatusMC = false;
                    this.myProfileService.setnumberOfSelectedMC([], 'Reset');
                    break;
                case 3:
                    this.checkStatusCB = false;
                    this.myProfileService.setnumberOfSelectedCB([], 'Reset');
                    break;
                case 4:
                    this.checkStatusIN = false;
                    this.myProfileService.setnumberOfSelectedIN([], 'Reset');
                    break;
                case 5:
                    this.checkStatusOD = false;
                    this.myProfileService.setnumberOfSelectedOD([], 'Reset');
                    break;
            }
        }
    };
    MyprofileInternalPAComponent.prototype.refresh = function (params) {
        return false;
    };
    MyprofileInternalPAComponent.prototype.checkboxClick = function (event) {
        var _this = this;
        this.currentNode.data.Preferences.forEach(function (Preference) {
            if (Preference.moduleId == _this.currentNode.colDef.appFeatureId) {
                Preference.isSelected = event.target.checked;
            }
        });
        switch (this.currentNode.colDef.appFeatureId) {
            case 1: // Price Aggrement
                this.checkStatusPA = !this.checkStatusPA;
                this.myProfileService.setnumberOfSelectedPA(this.currentNode.data.customerNumber, event.target.checked ? 'Add' : 'Remove');
                break;
            case 2: // Meet Comp
                this.checkStatusMC = !this.checkStatusMC;
                this.myProfileService.setnumberOfSelectedMC(this.currentNode.data.customerNumber, event.target.checked ? 'Add' : 'Remove');
                break;
            case 3: // ChargeBack
                this.checkStatusCB = !this.checkStatusCB;
                this.myProfileService.setnumberOfSelectedCB(this.currentNode.data.customerNumber, event.target.checked ? 'Add' : 'Remove');
                break;
            case 4: // Invoices
                this.checkStatusIN = !this.checkStatusIN;
                this.myProfileService.setnumberOfSelectedIN(this.currentNode.data.customerNumber, event.target.checked ? 'Add' : 'Remove');
                break;
            case 5: // Orders
                this.checkStatusOD = !this.checkStatusOD;
                this.myProfileService.setnumberOfSelectedOD(this.currentNode.data.customerNumber, event.target.checked ? 'Add' : 'Remove');
                break;
        }
        setTimeout(function () {
            _this.defaultHeaderCheckBoxState();
        });
    };
    MyprofileInternalPAComponent.prototype.defaultHeaderCheckBoxState = function () {
        // Total Records
        var totalNumberOfRecord = this.myProfileService.getTotalNumberOfPAs();
        //Price Agreement
        if (this.currentNode.colDef.appFeatureId == 1) {
            var numberOfPASelected = this.myProfileService.getnumberOfSelectedPA();
            if ((numberOfPASelected == totalNumberOfRecord) && (totalNumberOfRecord > 0)) {
                this.agGridService.setnodeMyProfilePASelectAll("ALL");
            }
            else {
                this.agGridService.setnodeMyProfilePASelectAll("NONE");
            }
        }
        // Meet Comp
        if (this.currentNode.colDef.appFeatureId == 2) {
            var numberOfMCSelected = this.myProfileService.getnumberOfSelectedMC();
            if ((numberOfMCSelected == totalNumberOfRecord) && (totalNumberOfRecord > 0)) {
                this.agGridService.setnodeMyProfileMCSelectAll("ALL");
            }
            else {
                this.agGridService.setnodeMyProfileMCSelectAll("NONE");
            }
        }
        // ChargeBack
        if (this.currentNode.colDef.appFeatureId == 3) {
            var numberOfCBSelected = this.myProfileService.getnumberOfSelectedCB();
            if ((numberOfCBSelected == totalNumberOfRecord) && (totalNumberOfRecord > 0)) {
                this.agGridService.setnodeMyProfileCBSelectAll("ALL");
            }
            else {
                this.agGridService.setnodeMyProfileCBSelectAll("NONE");
            }
        }
        // Invoices
        if (this.currentNode.colDef.appFeatureId == 4) {
            var numberOfINSelected = this.myProfileService.getnumberOfSelectedIN();
            if ((numberOfINSelected == totalNumberOfRecord) && (totalNumberOfRecord > 0)) {
                this.agGridService.setnodeMyProfileINSelectAll("ALL");
            }
            else {
                this.agGridService.setnodeMyProfileINSelectAll("NONE");
            }
        }
        // Orders
        if (this.currentNode.colDef.appFeatureId == 5) {
            var numberOfODSelected = this.myProfileService.getnumberOfSelectedOD();
            if ((numberOfODSelected == totalNumberOfRecord) && (totalNumberOfRecord > 0)) {
                this.agGridService.setnodeMyProfileODSelectAll("ALL");
            }
            else {
                this.agGridService.setnodeMyProfileODSelectAll("NONE");
            }
        }
    };
    MyprofileInternalPAComponent.prototype.ngOnDestroy = function () {
        // Required for unsubscribing and destroying the component  
    };
    MyprofileInternalPAComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [CpAgGridService,
            MyProfileService])
    ], MyprofileInternalPAComponent);
    return MyprofileInternalPAComponent;
}());
export { MyprofileInternalPAComponent };
