import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { recentDownloadStatus, pastatus } from '@app/conf/dataConfigurations';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';

@Component({
  selector: 'app-status-column',
  templateUrl: './status-column.component.html',
  styleUrls: ['./status-column.component.scss']
})
export class StatusColumnComponent implements AgRendererComponent, OnDestroy  {

  params: any;
  status: string;

  constructor(
    private gridService: CpAgGridService
  ) { }

  agInit(params: any): void {
    this.params = params;
    // if(params && params.data && params.data.status){
    //   let statusObj; 
    //   if(this.gridService.pageContext.pageName === 'mcSearchResult'){
    //     statusObj = pastatus;
    //   }
    //   else{
    //     statusObj = recentDownloadStatus;
    //   }
    //   this.mapStatus(params.data.status, statusObj);
    // }



    let statusObj; 
    let statusId;
    if(this.gridService.pageContext.pageName === 'mcSearchResult'){
      if(params && params.data && params.data.status){
        statusObj = pastatus;
        statusId = params.data.status;
      }
    }
    else if(this.gridService.pageContext.pageName === 'recentDownload'){
      if(params && params.data && params.data.status){
        statusObj = recentDownloadStatus;
        statusId = params.data.status;
      }
    }
    else if(this.gridService.pageContext.pageName === 'searchResults' || this.gridService.pageContext.pageName === 'padetailproduct' || this.gridService.pageContext.pageName === 'padetailendusers'){
      if(params && params.data && params.data.statusId){
        statusObj = pastatus;
        statusId = params.data.statusId;
      }
    }
    else{
      if(params && params.data && params.data.statusId){
        statusObj = pastatus;
        statusId = params.data.statusId;
      }
    }
    this.mapStatus(statusId, statusObj);
  }

  mapStatus(status, statusObj: Array<any>){
    let statusArr;
    if(statusObj){
      statusArr = statusObj.find(el => {
        return parseInt(status, 10) === parseInt(el.value, 10);
      });
    }    
    this.status = statusArr ? statusArr.label : "";
  }

  refresh(params: any): boolean {
    return false;
  }

  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }

}
