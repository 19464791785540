import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'invoice-new-refresh-status',
  templateUrl: './invoice-new-refresh-status.component.html',
  styleUrls: ['./invoice-new-refresh-status.component.scss']
})
export class InvoiceNewRefreshStatusComponent implements OnInit {
  
  params: any;
  
  constructor() { }

  agInit(params: any) {
    if(params.value == '0'){
      params.value = '3';
    }
    this.params = params;
  }

  ngOnInit() {
  }

}
