/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./type-ahead.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../selected-customer-eu/selected-customer-eu.component.ngfactory";
import * as i3 from "../selected-customer-eu/selected-customer-eu.component";
import * as i4 from "../list-row/list-row.component.ngfactory";
import * as i5 from "../list-row/list-row.component";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "@ngx-translate/core";
import * as i9 from "./type-ahead.component";
import * as i10 from "../../../../Shared/Services/http-charge-back.service";
import * as i11 from "../../../Services/cb.service";
var styles_TypeAheadComponent = [i0.styles];
var RenderType_TypeAheadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TypeAheadComponent, data: { "animation": [{ type: 7, name: "fadeInOut", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: 300 }], options: null }], options: {} }] } });
export { RenderType_TypeAheadComponent as RenderType_TypeAheadComponent };
function View_TypeAheadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "listCont"]], [[24, "@fadeInOut", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-selected-customer-eu", [], null, [[null, "removeValue"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("removeValue" === en)) {
        var pd_0 = (_co.removeCustomerEU($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SelectedCustomerEUComponent_0, i2.RenderType_SelectedCustomerEUComponent)), i1.ɵdid(2, 114688, null, 0, i3.SelectedCustomerEUComponent, [], { selectedCustomerEU: [0, "selectedCustomerEU"] }, { removeValue: "removeValue" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.selectedCustomers; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_TypeAheadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-list-row", [], null, [[null, "checkBoxChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkBoxChanged" === en)) {
        var pd_0 = (_co.onCheckboxChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ListRowComponent_0, i4.RenderType_ListRowComponent)), i1.ɵdid(2, 114688, null, 0, i5.ListRowComponent, [], { rowObj: [0, "rowObj"] }, { checkBoxChanged: "checkBoxChanged" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TypeAheadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "listCont"]], [[24, "@fadeInOut", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TypeAheadComponent_3)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.mappedCustEUList; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_TypeAheadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { couponNumberInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 21, "div", [["class", "innerCont"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "input", [["class", "form-control"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.searchText = $event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = (_co.userInput.next($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(7, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(9, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "listWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TypeAheadComponent_1)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TypeAheadComponent_2)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 8, "div", [["class", "buttonWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "button", [["class", "btn btn-primary btn-primary--disabled btn-primary--icon-left"]], [[8, "disabled", 0], [2, "btn-primary--disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onApplyClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "far fa-check-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(20, 0, null, null, 3, "span", [["class", "clearFilter"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveFilter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "far fa-times-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.searchText; _ck(_v, 7, 0, currVal_8); var currVal_9 = (_co.selectedCustomers.length && !_co.mappedCustEUList.length); _ck(_v, 12, 0, currVal_9); var currVal_10 = (_co.mappedCustEUList && _co.mappedCustEUList.length); _ck(_v, 14, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.placeHolder || "CustomerEU# to search..."); var currVal_1 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 9).ngClassValid; var currVal_6 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_11 = !_co.selectedCustomers.length; var currVal_12 = !_co.selectedCustomers.length; _ck(_v, 16, 0, currVal_11, currVal_12); var currVal_13 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("calender.apply")); _ck(_v, 18, 0, currVal_13); var currVal_14 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform("calender.clear")); _ck(_v, 22, 0, currVal_14); }); }
export function View_TypeAheadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-type-ahead", [], null, null, null, View_TypeAheadComponent_0, RenderType_TypeAheadComponent)), i1.ɵdid(1, 114688, null, 0, i9.TypeAheadComponent, [i10.HttpChargeBackService, i11.CbService, i8.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TypeAheadComponentNgFactory = i1.ɵccf("app-type-ahead", i9.TypeAheadComponent, View_TypeAheadComponent_Host_0, { optionData: "optionData", placeHolder: "placeHolder" }, { selectedCustEU: "selectedCustEU" }, []);
export { TypeAheadComponentNgFactory as TypeAheadComponentNgFactory };
