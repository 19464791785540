/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invoice-default-customer-number.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./invoice-default-customer-number.component";
import * as i3 from "../../../../Shared/Services/app.service";
var styles_InvoiceDefaultCustomerNumberComponent = [i0.styles];
var RenderType_InvoiceDefaultCustomerNumberComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvoiceDefaultCustomerNumberComponent, data: {} });
export { RenderType_InvoiceDefaultCustomerNumberComponent as RenderType_InvoiceDefaultCustomerNumberComponent };
export function View_InvoiceDefaultCustomerNumberComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customerNumber; _ck(_v, 0, 0, currVal_0); }); }
export function View_InvoiceDefaultCustomerNumberComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "invoice-default-customer-number", [], null, null, null, View_InvoiceDefaultCustomerNumberComponent_0, RenderType_InvoiceDefaultCustomerNumberComponent)), i1.ɵdid(1, 180224, null, 0, i2.InvoiceDefaultCustomerNumberComponent, [i3.AppService], null, null)], null, null); }
var InvoiceDefaultCustomerNumberComponentNgFactory = i1.ɵccf("invoice-default-customer-number", i2.InvoiceDefaultCustomerNumberComponent, View_InvoiceDefaultCustomerNumberComponent_Host_0, {}, {}, []);
export { InvoiceDefaultCustomerNumberComponentNgFactory as InvoiceDefaultCustomerNumberComponentNgFactory };
