<div *ngIf="currentNode.data.name">
    <input type="checkbox" [checked]="check" [disabled]="isCheckboxDisabled" 
    (change)="checkboxClick($event)" class="custom-control-input" [id]="checkboxName"
    [ngClass]="{'newly-added-loc': highlightChild}">
    <label class="custom-control-label" [for]="checkboxName" [ngClass]="{'newly-added-loc': highlightChild}">
        {{currentNode.data.name}} ({{currentNode.data.customerNumber}})
    </label>
</div>
<div *ngIf="!currentNode.data.name && currentNode.data.parentCustomer">
    <input type="checkbox" [checked]="check" [disabled]="isCheckboxDisabled" 
        (change)="checkboxClick($event)" class="custom-control-input" 
        [id]="checkboxName" [ngClass]="{'newly-added-loc': highlightGroup}">
    <label class="custom-control-label" [ngClass]="{'newly-added-loc': highlightGroup}" 
        [for]="checkboxName">{{currentNode.data.parentCustomer}}</label>
</div>