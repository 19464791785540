<a href="javascript:void(0)" (click)="onEfileNameClick()" title={{params.value}}>{{params.value}}</a>
<p-toast key="efileDownload" position="center" [style]="{ marginTop : '100px', width: '400px' }" [closable]="true"
    [baseZIndex]="500" [autoZIndex]="100" [hideTransitionOptions]="'250ms'">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center; padding-left: 20px;">
            <h3>{{message.summary | translate}}</h3>
            <p>{{message.detail | translate}}</p>
        </div>
    </ng-template>
</p-toast>
