import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { kcpUpdates } from '@app/conf/dataConfigurations';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';

@Component({
  selector: 'app-commentformatter',
  templateUrl: './commentformatter.component.html',
  styleUrls: ['./commentformatter.component.scss']
})
export class CommentformatterComponent implements AgRendererComponent {

  constructor(
    private gridService: CpAgGridService
  ) { }
  params: any;
  comment: string;
  isMCModule: boolean = false;

  agInit(params: any): void {
    this.params = params;
    if(this.gridService.pageContext.pageName === 'mcProductsDetailsSection'){
      if (params && params.data && params.data.commentId) {
        this.comment = this.mapComments(params.data.commentId, kcpUpdates);
        this.isMCModule = true;
      }
    }
    else{
      if (params && params.data) {
        if (params && params.data && params.data.comments && params.data.comments != null) {
          if (Number.isInteger(+params.data.comments))
            this.params = this.mapComments(params.data.comments, kcpUpdates);
          else {
            if (params && params.data && params.data.commentId) {
              this.comment = this.mapComments(params.data.commentId, kcpUpdates);
              this.isMCModule = true;
            }
            else{
              this.comment = this.params = (params.data.comments && params.data.comments != null ? params.data.comments : '');
            }
          }
  
        }
        else if (params && params.data && params.data.CommentsDescription) {
          this.comment = this.params = (params.data.CommentsDescription && params.data.CommentsDescription != null ? params.data.CommentsDescription : '');
        }
        else if (params && params.data && params.data.comment) {
          this.comment = this.params = (params.data.comment && params.data.comment != null ? params.data.comment : '');
        }
        else {
          this.comment = this.params = '';
        }
      }
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  mapComments(commentCode, referenceList: Array<any>) {
    let comments = referenceList.filter(ku => {
      return parseInt(ku.changeReason, 10) === parseInt(commentCode, 10)
    });
    if (comments && comments.length) {
      return comments[0].value;
    }
    else {
      return '';
    }
  }

}

