import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { MonitoringService } from './monitoring.service';
import { environment } from '@env/environment'

@Injectable()
export class MonitoringErrorHandler extends ErrorHandler {
    constructor(private injector: Injector) {
        super();
    }

    handleError(error: any): void {
        console.log("error:::handler::", error)
        if (environment.production) {
            const monitoringService = this.injector.get(MonitoringService);
            monitoringService.logError(error);
            super.handleError(error);
        }
    }
}