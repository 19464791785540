import { BehaviorSubject, Subject } from 'rxjs';
import { cookieSIMID, globalUser } from '@env/environment';
import { countryOBJ } from '@app/conf/countryURLConfig';
import { currencyArray } from '@app/conf/countryCurrencyConfig';
import * as currency from 'currency.js';
import * as i0 from "@angular/core";
var GlobalAppService = /** @class */ (function () {
    function GlobalAppService() {
        // private userPreferenceData: UserPrefrence;
        this.userPreferenceData = new BehaviorSubject(null);
        this.userRegions = new BehaviorSubject(null);
        this.userDefaultRegion = new BehaviorSubject(null);
        this.userDefaultLanguage = new BehaviorSubject(null);
        this.userDefaultCountry = new BehaviorSubject(null);
        this.countryOnMenuChange = new Subject();
        this.languageOnMenuChange = new Subject();
        this.selectedCountryFromProfile = new Subject();
        this.userSearchSubject = new BehaviorSubject(null);
        this.appStartSubject = new Subject();
        this.noCountriesAvaiableSubject = new Subject();
        this.noLocationsAvaiableSubject = new Subject();
        this.defaultProfileLocationUpdated = new Subject();
        this.masterDataDetails = new Subject();
        this.locationLanguageChanged = new BehaviorSubject(null);
        this.approles = new Subject();
        this.approlesArray = [];
        this.runWalkMe = new Subject();
        this.countryLangSelction = new BehaviorSubject(false);
        this.cvtViewSubject = new Subject();
        this.userDetailsLoaded = new BehaviorSubject(true);
    }
    GlobalAppService.prototype.setUserDetailsLoaded = function (value) {
        this.userDetailsLoaded.next(value);
    };
    GlobalAppService.prototype.getUserDetailsLoaded = function () {
        return this.userDetailsLoaded.asObservable();
    };
    GlobalAppService.prototype.isCVTView = function () {
        return this.cvtView;
    };
    GlobalAppService.prototype.isCVTViewSubject = function () {
        return this.cvtViewSubject.asObservable();
    };
    GlobalAppService.prototype.setCVTView = function (status) {
        if (this.isGlobalUser) {
            status = false;
        }
        this.cvtViewSubject.next(status);
        this.cvtView = status;
    };
    Object.defineProperty(GlobalAppService.prototype, "isGlobalUser", {
        get: function () {
            var _this = this;
            var isGlobalRole = false;
            var region = this.getDefaultRegionString();
            var appRolesList = this.getApprolesArray();
            if (region) {
                globalUser[region.toLocaleUpperCase()].forEach(function (element) {
                    if (appRolesList && appRolesList.indexOf(element) > -1 && _this.getCookie(cookieSIMID)) {
                        isGlobalRole = true;
                    }
                });
            }
            return isGlobalRole;
        },
        enumerable: true,
        configurable: true
    });
    GlobalAppService.prototype.getCookie = function (name) {
        var dc = document.cookie;
        var prefix = name + "=";
        var begin = dc.indexOf("; " + prefix);
        if (begin == -1) {
            begin = dc.indexOf(prefix);
            if (begin != 0)
                return null;
        }
        else {
            begin += 2;
            var end = document.cookie.indexOf(";", begin);
            if (end == -1) {
                end = dc.length;
            }
        }
        // because unescape has been deprecated, replaced with decodeURI
        //return unescape(dc.substring(begin + prefix.length, end));
        return decodeURI(dc.substring(begin + prefix.length, end));
    };
    // set cvtView(value: boolean) {
    //     this._cvtView = value;
    // }
    // get cvtView(): boolean {
    //     return this._cvtView;
    // }
    GlobalAppService.prototype.setLocationLanguage = function (value) {
        this.locationLanguageChanged.next(value);
    };
    GlobalAppService.prototype.getLocationLanguage = function () {
        return this.locationLanguageChanged.asObservable();
    };
    GlobalAppService.prototype.getRegions = function () {
        return this.userRegions.asObservable();
    };
    GlobalAppService.prototype.setRegions = function (regions) {
        this.userRegions.next(regions);
    };
    GlobalAppService.prototype.setUserPreferenceData = function (userPreferenceData) {
        // this.userPreferenceData = Object.assign({}, userPreferenceData);
        var myObj = Object.assign({}, userPreferenceData);
        this.userPreferenceData.next(myObj);
    };
    GlobalAppService.prototype.getUserPreferenceData = function () {
        return this.userPreferenceData;
    };
    GlobalAppService.prototype.setDefaultCustomerInfo = function (defaultCustomerInfo) {
        this.defaultCustomerInfo = defaultCustomerInfo;
    };
    GlobalAppService.prototype.getDefaultCustomerInfo = function () {
        return this.defaultCustomerInfo;
    };
    GlobalAppService.prototype.getDefaultRegion = function () {
        return this.userDefaultRegion.asObservable();
    };
    GlobalAppService.prototype.getDefaultRegionString = function () {
        return this.userDefaultRegionString;
    };
    GlobalAppService.prototype.setDefaultRegion = function (region) {
        var storedRegion = localStorage.getItem('default-region');
        if (region) {
            this.userDefaultRegion.next(region);
            this.userDefaultRegionString = region;
        }
        else if (storedRegion) {
            this.userDefaultRegion.next(storedRegion);
            this.userDefaultRegionString = storedRegion;
        }
    };
    GlobalAppService.prototype.setDefaultLanguage = function (lang) {
        this.userDefaultLanguage.next(lang);
        this.userDefaultLanguageString = lang;
        localStorage.setItem('country-lang', lang);
    };
    GlobalAppService.prototype.getDefaultLanguageString = function () {
        return this.userDefaultLanguageString;
    };
    GlobalAppService.prototype.getDefaultLanguage = function () {
        return this.userDefaultLanguage.asObservable();
    };
    GlobalAppService.prototype.setDefaultCountry = function (country) {
        this.userDefaultCountry.next(country);
        this.userDefaultCountryString = country;
        localStorage.setItem('country', country);
    };
    GlobalAppService.prototype.getDefaultCountryString = function () {
        return this.userDefaultCountryString;
    };
    GlobalAppService.prototype.getDefaultCountry = function () {
        return this.userDefaultCountry.asObservable();
    };
    GlobalAppService.prototype.setUserNameString = function (name) {
        this.userName = name;
    };
    GlobalAppService.prototype.getUserNameString = function () {
        return this.userName;
    };
    GlobalAppService.prototype.setAppStart = function (value) {
        this.appStartSubject.next(value);
    };
    GlobalAppService.prototype.getAppStart = function () {
        return this.appStartSubject.asObservable();
    };
    GlobalAppService.prototype.setdefaultProfileLocationUpdated = function (value) {
        this.defaultProfileLocationUpdated.next(value);
    };
    GlobalAppService.prototype.getdefaultProfileLocationUpdated = function () {
        return this.defaultProfileLocationUpdated.asObservable();
    };
    GlobalAppService.prototype.setCountryFromMenu = function (country) {
        this.countryOnMenuChange.next(country);
        localStorage.setItem('country', country);
    };
    GlobalAppService.prototype.getCountryFromMenu = function () {
        return this.countryOnMenuChange.asObservable();
    };
    GlobalAppService.prototype.setLangFromMenu = function (langauage) {
        this.selectedLanguage = langauage;
        this.languageOnMenuChange.next(langauage);
        localStorage.setItem('country-lang', langauage);
    };
    GlobalAppService.prototype.getLangFromMenu = function () {
        return this.languageOnMenuChange.asObservable();
    };
    GlobalAppService.prototype.getCurrenLanguage = function () {
        return this.selectedLanguage;
    };
    GlobalAppService.prototype.setMasterDataDetails = function (data) {
        this.masterDataDetails.next(data);
    };
    GlobalAppService.prototype.getMasterDataDetails = function () {
        return this.masterDataDetails.asObservable();
    };
    GlobalAppService.prototype.isBlackListedCVTUrl = function () {
        var blacklistCVTurl = [
            '/pa/details',
            '/meetcomps/',
        ];
        var blackListedURL = false;
        for (var ctr = 0; ctr < blacklistCVTurl.length; ctr++) {
            if (location.href.indexOf(blacklistCVTurl[ctr]) > -1) {
                blackListedURL = true;
                break;
            }
        }
        return blackListedURL;
    };
    GlobalAppService.prototype.setUserDetails = function (usseDetails) {
        this.userDetails = usseDetails;
        // this.setCVTView(usseDetails && usseDetails.preferredView ? usseDetails.preferredView.toLowerCase() === 'cvt' ? true : false : false);
        var cvtView = usseDetails && usseDetails.preferredView ? usseDetails.preferredView.toLowerCase() === 'cvt' ? true : false : false;
        this.isBlackListedCVTUrl();
        // if (location.href && location.href.indexOf("/pa/details") > -1) {
        if (location.href && this.isBlackListedCVTUrl()) {
            if (location.href.indexOf("/CVTYes") > -1) {
                if (this.getDefaultRegionString().toUpperCase() == 'LAO') {
                    this.setCVTView(false);
                }
                else {
                    this.setCVTView(true);
                }
                ;
            }
            else {
                this.setCVTView(false);
            }
        }
        else {
            this.setCVTView(cvtView);
        }
        this.setSalesOrg(usseDetails.preferredSalesOrg || usseDetails.salesOrg);
    };
    GlobalAppService.prototype.getUserDetails = function () {
        return this.userDetails;
    };
    GlobalAppService.prototype.defaultDetailsAvailable = function () { };
    GlobalAppService.prototype.startWalkMe = function () {
        this.runWalkMe.next(true);
    };
    GlobalAppService.prototype.notifyWalkMeStart = function () {
        return this.runWalkMe.asObservable();
    };
    GlobalAppService.prototype.setUserProfileDetails = function (userDetails) {
        this.userProfileDetails = userDetails;
    };
    GlobalAppService.prototype.getUserProfileDetails = function () {
        return this.userProfileDetails;
    };
    GlobalAppService.prototype.setApproles = function (approles) {
        this.approlesArray = approles;
        this.approles.next(approles);
    };
    GlobalAppService.prototype.getApproles = function () {
        return this.approles.asObservable();
    };
    GlobalAppService.prototype.getApprolesArray = function () {
        return this.approlesArray;
    };
    GlobalAppService.prototype.setDefaultProfileLocation = function (location) {
        this.defaultProfileLocation = location;
    };
    GlobalAppService.prototype.getDefaultProfileLocation = function () {
        return this.defaultProfileLocation;
    };
    GlobalAppService.prototype.setSalesOrg = function (id) {
        this.userSalesOrdID = id;
    };
    GlobalAppService.prototype.getSalesOrg = function () {
        return this.userSalesOrdID;
    };
    // Please DO nOT user below setters for sales or - it is for future purpose
    GlobalAppService.prototype.setCountrySalesOrg = function (id) {
        this.countrySalesOrg = id;
    };
    GlobalAppService.prototype.getCountrySalesOrg = function () {
        return this.countrySalesOrg;
    };
    Object.defineProperty(GlobalAppService.prototype, "selectedRegion", {
        get: function () {
            return this._selectedRegion;
        },
        set: function (value) {
            this._selectedRegion = value;
        },
        enumerable: true,
        configurable: true
    });
    GlobalAppService.prototype.getIsCountryLangSelctionHidden = function () {
        return this.countryLangSelction.asObservable();
    };
    GlobalAppService.prototype.setIsCountryLangSelctionHidden = function (value) {
        this.countryLangSelction.next(value);
    };
    GlobalAppService.prototype.setSelectedCountryLang = function (country, lang, salesOrg) {
        this.currentCountry = country;
        this.currentLanguage = lang;
        this.salesOrg = salesOrg;
    };
    GlobalAppService.prototype.getSelectedCountryLang = function () {
        return {
            'country': this.currentCountry,
            'language': this.currentLanguage || this.getDefaultLanguageString(),
            'salesOrg': this.salesOrg
        };
    };
    GlobalAppService.prototype.updateDelegatedUserSearch = function (value) {
        this.userSearchSubject.next(value);
        this.userSearchRequest = value;
    };
    GlobalAppService.prototype.getDelegatedUserSearch = function () {
        return this.userSearchSubject.asObservable();
    };
    GlobalAppService.prototype.getDelegatedUserSearchRequest = function () {
        return this.userSearchRequest;
    };
    GlobalAppService.prototype.setSelectedCountryFromProfile = function (country) {
        this.selectedCountryFromProfile.next(country);
    };
    GlobalAppService.prototype.getSelectedCountryFromProfile = function () {
        return this.selectedCountryFromProfile.asObservable();
    };
    GlobalAppService.prototype.setNoCountriesAvaiable = function (value) {
        this.noCountriesAvaiableSubject.next(value);
    };
    GlobalAppService.prototype.getNoCountriesAvaiable = function () {
        return this.noCountriesAvaiableSubject.asObservable();
    };
    GlobalAppService.prototype.setNoLocationsAvaiable = function (value) {
        this.noLocationsAvaiable = value;
        this.noLocationsAvaiableSubject.next(value);
    };
    GlobalAppService.prototype.getNoLocationsAvaiable = function () {
        return this.noLocationsAvaiable;
    };
    GlobalAppService.prototype.getNoLocationSubscription = function () {
        return this.noLocationsAvaiableSubject.asObservable();
    };
    GlobalAppService.prototype.setIsFirstTimeOnProfile = function (value) {
        this.isFirstTimeOnProfile = value;
    };
    GlobalAppService.prototype.getIsFirstTimeOnProfile = function () {
        return this.isFirstTimeOnProfile;
    };
    GlobalAppService.prototype.setCountryURL = function (countryCode) {
        var obj = countryOBJ.find(function (item) {
            return item.countryID === countryCode.toLowerCase();
        });
        return obj.url;
    };
    GlobalAppService.prototype.setIncludeExpiryItemStatus = function (status) {
        this.includeExpiryItemStatus = status;
    };
    GlobalAppService.prototype.getIncludeExpiryItemStatus = function () {
        return this.includeExpiryItemStatus;
    };
    GlobalAppService.prototype.setRecentlyChangedDuration = function (duration) {
        this.recentlyChangedDuration = duration;
    };
    GlobalAppService.prototype.getRecentlyChangedDuration = function () {
        return this.recentlyChangedDuration;
    };
    /*
    ** function use  : getNumberCurrencyFormatted
    parameters:
    languageCode = 'en-US'
    value = value to be formatted
    type = 'NUMBER' or 'CURRENCY'
    precisionValue = 0 by default else pass any numeric precision value required
    */
    GlobalAppService.prototype.getNumberCurrencyFormatted = function (languageCode, value, type, precisionValue) {
        if (precisionValue === void 0) { precisionValue = 0; }
        var country = 'US';
        if (languageCode) {
            if (languageCode.toUpperCase().indexOf('-CA') > -1) {
                country = languageCode;
            }
            else {
                country = languageCode.split('-')[1];
            }
        }
        var currencyConfig = currencyArray.find(function (item) {
            return item.code.toUpperCase() == country.toUpperCase();
        });
        if (currencyConfig && value) {
            // const decimalValue = this.whatIsTheDecimalSeperator(value);
            // const decimalValue = currencyConfig.decimal;
            // if(decimalValue && decimalValue =='.')
            // {
            //     value = value.toString().replace(/,/g,'');
            // }
            // else if(decimalValue && decimalValue ==',') {
            //     value = value.toString().replace(/\./g,'');
            //     value = value.toString().replace(',','.');
            // }
            if (type.toUpperCase() == 'NUMBER') {
                var val = currency(parseFloat(value), { separator: currencyConfig.separator, decimal: currencyConfig.decimal, precision: precisionValue, symbol: '' }).format();
                return val;
            }
            else {
                var val = currency(parseFloat(value), { separator: currencyConfig.separator, decimal: currencyConfig.decimal, precision: precisionValue, symbol: currencyConfig.symbol }).format();
                return val;
            }
        }
        else {
            if (type.toUpperCase() == 'NUMBER') {
                var val = currency(parseFloat(value), { separator: ',', decimal: '.', precision: precisionValue, symbol: '' }).format();
                return val;
            }
            else {
                var val = currency(parseFloat(value), { separator: ',', decimal: '.', precision: precisionValue, symbol: currencyConfig.symbol }).format();
                return val;
            }
        }
    };
    GlobalAppService.prototype.whatIsTheDecimalSeperator = function (value) {
        if (value) {
            var realValue = value.toString().trim();
            if (realValue.indexOf("-") > -1) {
                realValue = realValue.replace('-', '');
            }
            var maxLength = realValue.length;
            if (realValue.lastIndexOf(',') == -1 && realValue.lastIndexOf('.') == -1) {
                return null;
            }
            else if (maxLength - realValue.lastIndexOf(',') == 3) {
                return ',';
            }
            else {
                return '.';
            }
        }
        else {
            return null;
        }
    };
    GlobalAppService.ngInjectableDef = i0.defineInjectable({ factory: function GlobalAppService_Factory() { return new GlobalAppService(); }, token: GlobalAppService, providedIn: "root" });
    return GlobalAppService;
}());
export { GlobalAppService };
