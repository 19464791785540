// Angular imports
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
// import { HttpClientModule } from '@angular/common/http';

// Third party Module imports
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';

// App Component Imports
import { SharedModule } from '@app/Modules/Shared/shared.module';
import { FreetextSearchComponent } from './grid-features/filters/freetext-search/freetext-search.component';
import { HyperlinkAndFavoriteComponent } from './grid-features/cell-renderer/hyperlink-and-favorite/hyperlink-and-favorite.component';
import { TooltipAndFavoriteComponent } from './grid-features/cell-renderer/tooltip-and-favorite/tooltip-and-favorite.component';
import { DateSelectorComponent } from './grid-features/filters/date-selector/date-selector.component';
import { DateSelectorUFNComponent } from './grid-features/filters/date-selector-ufn/date-selectorufn.component';
import { MultiSelectFilterComponent } from './grid-features/filters/multi-select-filter/multi-select-filter.component';
import { DaterangeSelectorComponent } from './grid-features/filters/daterange-selector/daterange-selector.component';
import { ContextmenuComponent } from './grid-features/cell-renderer/contextmenu/contextmenu.component';
import { TooltipComponent } from './grid-features/cell-renderer/tooltip/tooltip.component';
import { HyperlinkComponent } from './grid-features/cell-renderer/hyperlink/hyperlink.component';
import { CpColumnHeaderComponent } from './grid-features/cell-renderer/column-header/cp-column-header.component'
import { RadiocolumnComponent } from './grid-features/cell-renderer/radiocolumn/radiocolumn.component';
import { SortComponent } from './grid-features/filters/sort-component/sort.component';
import { CustomcheckboxComponent } from './grid-features/cell-renderer/customcheckbox/customcheckbox.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ProductcodeHyperlinkComponent } from './grid-features/cell-renderer/productcode-hyperlink/productcode-hyperlink.component';
import { RecentDownloadDocumentComponent } from './grid-features/cell-renderer/recent-download-document/recent-download-document.component';
import { CpGridPaginationComponent } from './components/cp-grid-pagination/cp-grid-pagination.component';
import { CustomNoRowsOverlayComponent } from './components/custom-no-rows-overlay/custom-no-rows-overlay.component';
import { PriceFormatterComponent } from './grid-features/cell-renderer/price-formatter/price-formatter.component';
import { SortonlyComponent } from './grid-features/filters/sortonly/sortonly.component';
import { DateselectoronlyComponent } from './grid-features/filters/dateselectoronly/dateselectoronly.component';
import { OverflowEllipsestextComponent } from './grid-features/cell-renderer/overflow-ellipsestext/overflow-ellipsestext.component';
import { DigitFormatterComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/digit-formatter/digit-formatter.component';
import { CommentformatterComponent } from './grid-features/cell-renderer/commentformatter/commentformatter.component';
import { PaIdHyperlinkOnlyComponent } from './grid-features/cell-renderer/pa-id-hyperlink-only/pa-id-hyperlink-only.component';
import { PadetailsHyperLinkComponent } from './grid-features/cell-renderer/padetails-hyper-link/padetails-hyper-link.component';
import { HyperlinkAndLegendComponent } from './grid-features/cell-renderer/hyperlink-and-legend/hyperlink-and-legend.component';
import { MyprofileAddtoPreferenceComponent } from './grid-features/cell-renderer/myprofile-addto-preference/myprofile-addto-preference.component';
import { MyprofilePriceagreementComponent } from './grid-features/cell-renderer/myprofile-priceagreement/myprofile-priceagreement.component';
import { MyprofileInternalPAComponent } from './grid-features/cell-renderer/myprofile-Internal-pa/myprofile-Internal-pa.component';
import { LocationCRComponent } from './grid-features/cell-renderer/myLocation-Internal/myLocation-Internal-cr.component';
import { TooltipModule } from 'primeng/tooltip';
import { DownloadCustomerListComponent } from './components/download-customer-list/download-customer-list.component';
import { NumberFormatterComponent } from './grid-features/cell-renderer/number-formatter/number-formatter.component';
import { SfdcPaDetailsComponent } from './grid-features/cell-renderer/sfdc-pa-details/sfdc-pa-details.component';
import { CustomLoadingOverlayComponent } from './components/custom-loading-overlay/custom-loading-overlay.component';
import { CbhyperlinkComponent } from './grid-features/cell-renderer/cbhyperlink/cbhyperlink.component';
import { TypeAheadSearchComponent } from './grid-features/filters/type-ahead-search/type-ahead-search/type-ahead-search.component';
import { FullWidthRowComponent } from './grid-features/cell-renderer/full-width-row/full-width-row.component';
import { TypeAheadComponent } from '../chargeback/Components/customer-eu-type-ahead/type-ahead/type-ahead.component';
import { ListRowComponent } from '../chargeback/Components/customer-eu-type-ahead/list-row/list-row.component';
import { SelectedCustomerEUComponent } from '../chargeback/Components/customer-eu-type-ahead/selected-customer-eu/selected-customer-eu.component';
import { ContextmenucbComponent } from './grid-features/cell-renderer/contextmenucb/contextmenucb.component';
import { EfileStatusColumnComponent } from './grid-features/cell-renderer/efile-status-column/efile-status-column.component';
import { EfileErrorColumnComponent } from './grid-features/cell-renderer/efile-error-column/efile-error-column.component';
import { EfileDownnloadComponent } from './grid-features/cell-renderer/efile-downnload/efile-downnload.component';
import { EfileTypeColumnComponent } from './grid-features/cell-renderer/efile-type-column/efile-type-column.component';


import { StatusColumnComponent } from './grid-features/cell-renderer/status-column/status-column.component';

import { EndUserNumberComponent } from './grid-features/cell-renderer/end-user-number/end-user-number.component';
import { ContextmenuManageErrorComponent } from './grid-features/cell-renderer/contextmenu-manage-error/contextmenu-manage-error.component';
import { EfileErrorcellComponent } from './grid-features/cell-renderer/efile-errorcell/efile-errorcell.component';
import { EfileEditErrorLineComponent } from './grid-features/cell-renderer/efile-edit-error-line/efile-edit-error-line.component';
import { EfileHistoryDownloadComponent } from './grid-features/cell-renderer/efile-history-download/efile-history-download.component';
import { TimeFormatComponent } from './grid-features/cell-renderer/time-format/time-format.component';
import { ContextmenumcComponent } from './grid-features/cell-renderer/contextmenumc/contextmenumc.component';
import { ContextmenuAdvancedInvoiceComponent } from './grid-features/cell-renderer/contextmenu-advanced-invoice/contextmenu-advanced-invoice.component';
import { ContextmenuInvoiceNewComponent } from './grid-features/cell-renderer/contextmenu-invoicenew/contextmenu-invoicenew.component';
import { InvoiceAdvancedRefreshStatusComponent } from './grid-features/cell-renderer/invoice-advanced-refresh-status/invoice-advanced-refresh-status.component';
import { InvoiceNewRefreshStatusComponent } from './grid-features/cell-renderer/invoice-new-refresh-status/invoice-new-refresh-status.component';
import { AdvancedInvoiceFavouriteComponent } from './grid-features/cell-renderer/advanced-invoice-favourite/advanced-invoice-favourite.component';
import { ReportsDownloadComponent } from './grid-features/cell-renderer/reports-download/reports-download.component';
import { EaDropdownComponent } from './grid-features/cell-renderer/ea-dropdown/ea-dropdown.component';
import { DropdownModule } from 'primeng/dropdown';
import { HyperlinkAndPopupComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/hyperlink-and-popup/hyperlink-and-popup.component';
import { DelegatedUserHyperlinkComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/delegated-user-hyperlink/delegated-user-hyperlink.component';
import { HyperlinkAndClickComponent } from '@app/Modules/cp-grid/grid-features/cell-renderer/hyperlink-and-click/hyperlink-and-click.component';
import { PaSplitDetailsComponent } from './grid-features/cell-renderer/pa-split-details/pa-split-details.component';
import { ModuleTypeColumnComponent } from './grid-features/cell-renderer/module-type-column/module-type-column.component';
import { InvoiceDefaultCustomerNumberComponent } from './grid-features/cell-renderer/invoice-default-customer-number/invoice-default-customer-number.component';
import { FavoriteComponent } from './grid-features/cell-renderer/favorite/favorite.component';
import { AdvancedInvoiceNewFavouriteComponent } from './grid-features/cell-renderer/advanced-invoicenew-favourite/advanced-invoicenew-favourite.component';
import { PadetailsDecimalValuesComponent } from './grid-features/cell-renderer/padetails-decimal-values/padetails-decimal-values.component';
import { KcfileEditErrorLineComponent } from './grid-features/cell-renderer/kcfile-edit-error-line/kcfile-edit-error-line.component';
import { KcFileContextmenuManageErrorComponent } from './grid-features/cell-renderer/kcfile-contextmenu-manage-error/kcfile-contextmenu-manage-error.component';
import { KcfileDownnloadComponent } from './grid-features/cell-renderer/kcfile-downnload/kcfile-downnload.component';
import { KcFileContextmenucbComponent } from './grid-features/cell-renderer/kcfile-contextmenucb/kcfile-contextmenucb.component';
import { KcfileHistoryDownloadComponent } from './grid-features/cell-renderer/kcfile-history-download/kcfile-history-download.component';
import { KcfileStatusColumnComponent } from './grid-features/cell-renderer/kcfile-status-column/kcfile-status-column.component';

@NgModule({
  declarations: [
    FreetextSearchComponent,
    HyperlinkAndFavoriteComponent,
    TooltipAndFavoriteComponent,
    TooltipComponent,
    DateSelectorComponent,
    DateSelectorUFNComponent,
    MultiSelectFilterComponent,
    DaterangeSelectorComponent,
    ContextmenuComponent,
    CpColumnHeaderComponent,
    RadiocolumnComponent,
    HyperlinkComponent,
    SortComponent,
    CustomcheckboxComponent,
    ProductcodeHyperlinkComponent,
    PadetailsHyperLinkComponent,
    RecentDownloadDocumentComponent,
    ReportsDownloadComponent,
    CpGridPaginationComponent,
    CustomNoRowsOverlayComponent,
    PriceFormatterComponent,
    SortonlyComponent,
    DateselectoronlyComponent,
    OverflowEllipsestextComponent,
    EndUserNumberComponent,
    DigitFormatterComponent,
    CommentformatterComponent,
    PaIdHyperlinkOnlyComponent,
    HyperlinkAndLegendComponent,
    DelegatedUserHyperlinkComponent,
    HyperlinkAndPopupComponent,
    HyperlinkAndClickComponent,
    MyprofileAddtoPreferenceComponent,
    MyprofilePriceagreementComponent,
    MyprofileInternalPAComponent,
    LocationCRComponent,
    DownloadCustomerListComponent,
    NumberFormatterComponent,
    SfdcPaDetailsComponent,
    CustomLoadingOverlayComponent,
    CbhyperlinkComponent,
    TypeAheadSearchComponent,
    FullWidthRowComponent,
    TypeAheadComponent,
    ListRowComponent,
    SelectedCustomerEUComponent,
    ContextmenucbComponent,
    EfileStatusColumnComponent,
    EfileErrorColumnComponent,
    EfileDownnloadComponent,
    EfileTypeColumnComponent,
    StatusColumnComponent,
    ModuleTypeColumnComponent,
    ContextmenuManageErrorComponent,
    EfileErrorcellComponent,
    EfileEditErrorLineComponent,
    EfileHistoryDownloadComponent,
    TimeFormatComponent,
    ContextmenumcComponent,
    ContextmenuAdvancedInvoiceComponent,
    ContextmenuInvoiceNewComponent,
    InvoiceAdvancedRefreshStatusComponent,
    InvoiceNewRefreshStatusComponent,
    AdvancedInvoiceFavouriteComponent,
    AdvancedInvoiceNewFavouriteComponent,
    ReportsDownloadComponent,
    EaDropdownComponent,
    PaSplitDetailsComponent,
    InvoiceDefaultCustomerNumberComponent,
    FavoriteComponent,
    PadetailsDecimalValuesComponent,
    KcfileDownnloadComponent,
    KcFileContextmenucbComponent,
    KcfileHistoryDownloadComponent,
    KcfileEditErrorLineComponent,
    KcFileContextmenuManageErrorComponent,
    KcfileStatusColumnComponent
  ],
  imports: [
  SharedModule,
    // HttpClientModule,
    CalendarModule,
    MultiSelectModule,
    ReactiveFormsModule,
    OverlayPanelModule,
    TooltipModule,
    DropdownModule,
    AgGridModule.withComponents([
      FreetextSearchComponent,
      SortonlyComponent,
      DateselectoronlyComponent,
      HyperlinkAndFavoriteComponent,
      OverflowEllipsestextComponent,
      EndUserNumberComponent,
      PriceFormatterComponent,
      CommentformatterComponent,
      DigitFormatterComponent,
      TooltipAndFavoriteComponent,
      TooltipComponent,
      DateSelectorComponent,
      DateSelectorUFNComponent,
      MultiSelectFilterComponent,
      DaterangeSelectorComponent,
      ContextmenuComponent,
      ContextmenucbComponent,
      CpColumnHeaderComponent,
      HyperlinkComponent,
      PaIdHyperlinkOnlyComponent,
      CustomcheckboxComponent,
      ProductcodeHyperlinkComponent,
      PadetailsHyperLinkComponent,
      RecentDownloadDocumentComponent,
      ReportsDownloadComponent,
      CpGridPaginationComponent,
      CustomNoRowsOverlayComponent,
      HyperlinkAndLegendComponent,
      DelegatedUserHyperlinkComponent,
      HyperlinkAndPopupComponent,
      HyperlinkAndClickComponent,
      CbhyperlinkComponent,
      MyprofileAddtoPreferenceComponent,
      MyprofilePriceagreementComponent,
      MyprofileInternalPAComponent,
      LocationCRComponent,
      NumberFormatterComponent,
      SfdcPaDetailsComponent,
      CustomLoadingOverlayComponent,
      FullWidthRowComponent,
      EfileStatusColumnComponent,
      EfileErrorColumnComponent,
      EfileDownnloadComponent,
      EfileTypeColumnComponent,
      TypeAheadSearchComponent,
      ContextmenuManageErrorComponent,
      EfileErrorcellComponent,
      EfileEditErrorLineComponent,
      EfileHistoryDownloadComponent,
      TimeFormatComponent,
      ContextmenumcComponent,
      StatusColumnComponent,
      ModuleTypeColumnComponent,
      ContextmenuAdvancedInvoiceComponent,
      ContextmenuInvoiceNewComponent,
      InvoiceAdvancedRefreshStatusComponent,
      InvoiceNewRefreshStatusComponent,
      AdvancedInvoiceFavouriteComponent,
      AdvancedInvoiceNewFavouriteComponent,
      EaDropdownComponent,
      PaSplitDetailsComponent,
      InvoiceDefaultCustomerNumberComponent,
      FavoriteComponent,
      PadetailsDecimalValuesComponent,
      KcfileDownnloadComponent,
      KcFileContextmenucbComponent,
      KcfileHistoryDownloadComponent,
      KcfileEditErrorLineComponent,
      KcFileContextmenuManageErrorComponent,
      KcfileStatusColumnComponent
    ])
  ],
  exports: [
    FreetextSearchComponent,
    HyperlinkAndFavoriteComponent,
    OverflowEllipsestextComponent,
    EndUserNumberComponent,
    PriceFormatterComponent,
    CommentformatterComponent,
    DigitFormatterComponent,
    TooltipAndFavoriteComponent,
    TooltipComponent,
    DateSelectorComponent,
    DateSelectorUFNComponent,
    MultiSelectFilterComponent,
    DaterangeSelectorComponent,
    ContextmenuComponent,
    ContextmenucbComponent,
    CpColumnHeaderComponent,
    HyperlinkComponent,
    PaIdHyperlinkOnlyComponent,
    CustomcheckboxComponent,
    ProductcodeHyperlinkComponent,
    PadetailsHyperLinkComponent,
    RecentDownloadDocumentComponent,
    ReportsDownloadComponent,
    CpGridPaginationComponent,
    SortonlyComponent,
    DateselectoronlyComponent,
    CustomNoRowsOverlayComponent,
    CustomLoadingOverlayComponent,
    StatusColumnComponent,
    ModuleTypeColumnComponent,
    HyperlinkAndLegendComponent,
    DelegatedUserHyperlinkComponent,
    HyperlinkAndPopupComponent,
    HyperlinkAndClickComponent,
    CbhyperlinkComponent,
    MyprofileAddtoPreferenceComponent,
    MyprofilePriceagreementComponent,
    MyprofileInternalPAComponent,
    DownloadCustomerListComponent,
    LocationCRComponent,
    NumberFormatterComponent,
    SfdcPaDetailsComponent,
    FullWidthRowComponent,
    SharedModule,
    CalendarModule,
    MultiSelectModule,
    ReactiveFormsModule,
    OverlayPanelModule,
    AgGridModule,
    TypeAheadSearchComponent,
    EfileStatusColumnComponent,
    EfileErrorColumnComponent,
    EfileDownnloadComponent,
    EfileTypeColumnComponent,
    ContextmenuManageErrorComponent,
    EfileErrorcellComponent,
    EfileEditErrorLineComponent,
    EfileHistoryDownloadComponent,
    TimeFormatComponent,
    ContextmenumcComponent,
    ContextmenuAdvancedInvoiceComponent,
    ContextmenuInvoiceNewComponent,
    InvoiceAdvancedRefreshStatusComponent,
    InvoiceNewRefreshStatusComponent,
    AdvancedInvoiceFavouriteComponent,
    AdvancedInvoiceNewFavouriteComponent,
    EaDropdownComponent,
    PaSplitDetailsComponent,
    InvoiceDefaultCustomerNumberComponent,
    FavoriteComponent,
    PadetailsDecimalValuesComponent,
    KcfileDownnloadComponent,
    KcFileContextmenucbComponent,
    KcfileHistoryDownloadComponent,
    KcfileEditErrorLineComponent,
    KcFileContextmenuManageErrorComponent,
    KcfileStatusColumnComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CpGridModule { }
