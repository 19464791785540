import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { SharedModule } from '@app/Modules/Shared/shared.module';
import { WelcomeComponent } from './welcome/welcome.component';
// import { LoginComponent } from './login/login.component';
import { oktaConfig } from '@env/environment';
import { AppHomecomponent } from './AppHome.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MonitoringService } from '@app/Modules/Shared/Services/monitoring.service';
import { RegionCheckService } from '@app/Modules/Shared/Services/region-check.service';
import { MonitoringErrorHandler } from '@app/Modules/Shared/Services/error.handler';
import { EncrDecrService } from '@app/Modules/Shared/Services/encr-decr.service';
import { CpInitComponent } from './cp-init/cp-init.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
// import { DialogModule } from 'primeng/dialog';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HelpComponent } from './help/help.component';
import { ContactusComponent } from './contactus/contactus.component';
import { contactusRightSidebarComponent } from './contactusRightSidebar/contacts.component';
import { DelegatedUserSearchComponent } from './delegated-user/delegated-user-search/delegated-user-search.component';
import { DelegatedUserSearchResultComponent } from './delegated-user/delegated-user-searchresult/delegated-user-searchresult.component';
import { CpGridModule } from './Modules/cp-grid/cp-grid.module';
import { TestPageComponent } from './test/test-page/test-page.component';
import { ToastModule } from 'primeng/toast';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { ManageUserRedirect } from './Modules/Shared/Auth-Gaurds/auth-guard.ManageUserRedirect';

export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/app-modules/shared/', suffix: ".json" },
    { prefix: "./assets/i18n/errors/", suffix: ".json" },
  ]);
}
@NgModule({
  declarations: [
    AppComponent,
    // WelcomeComponent,
    // LoginComponent,
    AppHomecomponent,
    CpInitComponent,
    HelpComponent,
    ContactusComponent,
    contactusRightSidebarComponent,
    DelegatedUserSearchComponent,
    DelegatedUserSearchResultComponent,
    TestPageComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    RouterModule,
    AppRoutingModule,
    OktaAuthModule,
    CpGridModule,
    ToastModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      isolate: true
    }),
    NgIdleKeepaliveModule.forRoot()
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{
    provide: OKTA_CONFIG, useValue: oktaConfig,
  },
    MonitoringService,
    RegionCheckService,
  {
    provide: ErrorHandler,
    useClass: MonitoringErrorHandler
  },
    EncrDecrService,
    ManageUserRedirect
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
