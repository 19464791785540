import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var ContentAreaService = /** @class */ (function () {
    function ContentAreaService() {
        this.contentArea = new BehaviorSubject(false);
        this.currentBlurStatus = this.contentArea.asObservable();
    }
    ContentAreaService.prototype.updateBlurStatus = function (updateBlurStatus) {
        this.contentArea.next(updateBlurStatus);
    };
    ContentAreaService.ngInjectableDef = i0.defineInjectable({ factory: function ContentAreaService_Factory() { return new ContentAreaService(); }, token: ContentAreaService, providedIn: "root" });
    return ContentAreaService;
}());
export { ContentAreaService };
