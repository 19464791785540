<span *ngIf="statusDefault">{{ statusValue | translate }}</span>

<span *ngIf="statusReadyToSubmit">
  <span class="statusReadyToSubmit"
    [ngClass]="{'statusGeneral': isEfileSubmitted || isEfileDeleted}" (click)="submitEfile()" [innerHTML]= "statusValue | translate"> 
  </span>
  <i *ngIf="!isFileSubmittedOrDeleted" class='far fa-arrow-alt-circle-right'></i>
</span>

<span title="{{statusValue | translate}}" *ngIf="statusError" class="tooltip-hover tolltipHoverClass statusError ellipsis" [ngClass]="{'statusGeneral': isEfileDeleted}">
  <i *ngIf="!isEfileDeleted" class="fas fa-exclamation-circle"></i>
  <span pTooltip="{{'chargeback.eFile.errorsFoundTooltip' | translate}}" [escape]="false" tooltipPosition="top">
    <!-- <span class="textunderlined">{{ statusValue | translate }}</span> -->
    <span>{{ statusValue | translate }}</span>
  </span>
</span>


