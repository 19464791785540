/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./test-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./test-page.component";
import * as i3 from "@angular/common/http";
var styles_TestPageComponent = [i0.styles];
var RenderType_TestPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TestPageComponent, data: {} });
export { RenderType_TestPageComponent as RenderType_TestPageComponent };
export function View_TestPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "\ndisplay: flex;\njustify-content: center;\nalign-items: center;\n"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "btn btn-primary btn-primary--small"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.callPardotAPI() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "far fa-check-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Test"]))], null, null); }
export function View_TestPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-test-page", [], null, null, null, View_TestPageComponent_0, RenderType_TestPageComponent)), i1.ɵdid(1, 114688, null, 0, i2.TestPageComponent, [i3.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TestPageComponentNgFactory = i1.ɵccf("app-test-page", i2.TestPageComponent, View_TestPageComponent_Host_0, {}, {}, []);
export { TestPageComponentNgFactory as TestPageComponentNgFactory };
