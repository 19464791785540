import * as tslib_1 from "tslib";
import { OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { HttpService } from '@app/Modules/Shared/Services/http.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { SPA_LEGACY_MENU_HOST } from '@env/environment';
import { CpAgGridService } from '@app/Modules/cp-grid/services/cp-ag-grid-service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
var HyperlinkAndFavoriteComponent = /** @class */ (function () {
    function HyperlinkAndFavoriteComponent(httpServ, appServ, renderer, gridService, _globalAppService) {
        this.httpServ = httpServ;
        this.appServ = appServ;
        this.renderer = renderer;
        this.gridService = gridService;
        this._globalAppService = _globalAppService;
        this.isFavorite = false;
        this.favoriteCSS = "";
        this.subscription = new Subscription();
        this.isPageKCFilePA = false;
        this.customerId = "";
    }
    HyperlinkAndFavoriteComponent.prototype.ngOnInit = function () {
        this.isGlobalUser = this.appServ.isGlobalUser;
        this.oktaProfile = this.appServ.AvailableOKtaProfile;
        //below code is to hide favorite icon from KCfile PA page grid's PA number column
        if (this.gridService.pageContext && this.gridService.pageContext.pageName && this.gridService.pageContext.pageName == 'kcfilePASearchResults') {
            this.isPageKCFilePA = true;
        }
    };
    HyperlinkAndFavoriteComponent.prototype.agInit = function (params) {
        this.params = params;
        var contractIdfavPAList = this.appServ.getFavoritePA();
        if (params && params.data) {
            if (contractIdfavPAList)
                this.alreadyfavorite = contractIdfavPAList.find(function (item) {
                    return (item.customerId == params.data.customerNumber) && (item.contractId == params.data.contractId);
                });
            if (this.alreadyfavorite) {
                // this.favoriteCSS = "fa fa-heart favbtn";
                this.renderer.addClass(this.favicon.nativeElement, 'fa');
                this.renderer.addClass(this.favicon.nativeElement, 'fa-heart');
                this.renderer.addClass(this.favicon.nativeElement, 'favbtn');
                this.isFavorite = true;
            }
            else {
                // this.favoriteCSS = "";
                this.renderer.removeClass(this.favicon.nativeElement, 'fa');
                this.renderer.removeClass(this.favicon.nativeElement, 'fa-heart');
                this.renderer.removeClass(this.favicon.nativeElement, 'favbtn');
                this.isFavorite = false;
            }
        }
    };
    HyperlinkAndFavoriteComponent.prototype.refresh = function (params) {
        return false;
    };
    HyperlinkAndFavoriteComponent.prototype.onLinkClick = function () {
        var qsParams = this.getQsParams();
        var isCVT = "CVTNo";
        if (this._globalAppService.isCVTView()) {
            this.cvtView = this._globalAppService.isCVTView();
            isCVT = "CVTYes";
        }
        else {
            isCVT = "CVTNo";
        }
        if (this.params.data.customerNumber == null) {
            this.params.data.customerNumber = 'All';
        }
        var contractNumber = this.params.data.contractNumber;
        if (this.gridService.pageContext.pageName === 'myNotifications') {
            contractNumber = this.params.data.contractNumberWithZeros;
        }
        if (this.oktaProfile && this.oktaProfile.sAMAccountName) {
            window.open(SPA_LEGACY_MENU_HOST + contractNumber + '/' + this.params.data.customerNumber + '/' + this.params.data.statusId + '/' + isCVT + qsParams);
        }
    };
    HyperlinkAndFavoriteComponent.prototype.getQsParams = function () {
        var qsList = new Array();
        var qsParams;
        var qsTileType = this.params.tileType ? ('tileType=' + this.params.tileType) : "";
        if (qsTileType)
            qsList.push(qsTileType);
        var fullNameList = [];
        var oCustomerName = this.params.data.customerName;
        if (oCustomerName) {
            fullNameList.push(oCustomerName.name1 || '');
            // fullNameList.push(oCustomerName.name2 || '');
            // fullNameList.push(oCustomerName.name3 || '');
            // fullNameList.push(oCustomerName.Fname4 || '');
        }
        var qsCustomerName = fullNameList && fullNameList.length ? ('custName=' + fullNameList.join(' ')) : '';
        if (this.gridService.pageContext.pageName === 'myNotifications')
            qsCustomerName = 'custName=' + this.params.data.customerName;
        if (qsCustomerName)
            qsList.push(qsCustomerName);
        var dataRequest = this.gridService.getSvcRequest();
        ;
        var dataDurationType = dataRequest && dataRequest.query ? dataRequest.query.dataDurationType : 0;
        if (dataDurationType) {
            qsList.push('dataDurationType=' + dataDurationType);
        }
        var lineItemStatus = dataRequest && dataRequest.query ? dataRequest.query.display : 0;
        if (lineItemStatus) {
            qsList.push('display=' + lineItemStatus);
        }
        qsParams = qsList && qsList.length > 0 ? '?' + qsList.join('&') : '';
        return qsParams;
    };
    HyperlinkAndFavoriteComponent.prototype.onFavClick = function (params) {
        var _this = this;
        this.isFavorite = !this.isFavorite;
        if (this.isFavorite) {
            // make service call to mark fav
            this.today = this.getTodaysDate();
            this.subscription.add(this.httpServ.markFavorite(params.data.contractNumber, params.data.customerNumber, this.today).subscribe(function (data) {
            }, function (e) { }, function () { _this.appServ.setFavoritePA(); }));
        }
        else {
            // make service call to mark unfav
            this.today = this.getTodaysDate();
            this.subscription.add(this.httpServ.markUnFavorite(params.data.contractNumber, params.data.customerNumber, this.today).subscribe(function (data) {
            }, function (e) { }, function () { _this.appServ.setFavoritePA(); }));
        }
    };
    HyperlinkAndFavoriteComponent.prototype.onLinkHover = function () {
        if (!this.isFavorite) {
            // this.favoriteCSS = "far fa-heart favbtn";
            this.renderer.removeClass(this.favicon.nativeElement, 'fa');
            this.renderer.addClass(this.favicon.nativeElement, 'far');
            this.renderer.addClass(this.favicon.nativeElement, 'fa-heart');
            this.renderer.addClass(this.favicon.nativeElement, 'favbtn');
        }
        else {
            this.renderer.addClass(this.favicon.nativeElement, 'fa');
            this.renderer.addClass(this.favicon.nativeElement, 'fa-heart');
            this.renderer.addClass(this.favicon.nativeElement, 'favbtn');
        }
    };
    HyperlinkAndFavoriteComponent.prototype.onLinkMouseout = function () {
        this.renderer.removeClass(this.favicon.nativeElement, 'far');
        this.renderer.removeClass(this.favicon.nativeElement, 'fa-heart');
        this.renderer.removeClass(this.favicon.nativeElement, 'favbtn');
        if (this.isFavorite) {
            // this.favoriteCSS = "fa fa-heart favbtn";
            this.renderer.addClass(this.favicon.nativeElement, 'fa');
            this.renderer.addClass(this.favicon.nativeElement, 'fa-heart');
            this.renderer.addClass(this.favicon.nativeElement, 'favbtn');
        }
    };
    HyperlinkAndFavoriteComponent.prototype.getTodaysDate = function () {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        today = dd + '/' + mm + '/' + yyyy;
        return (today);
    };
    HyperlinkAndFavoriteComponent.prototype.ngOnDestroy = function () {
        // Required for unsubscribing and destroying the component
    };
    HyperlinkAndFavoriteComponent = tslib_1.__decorate([
        AutoUnsubscribe(),
        tslib_1.__metadata("design:paramtypes", [HttpService,
            AppService,
            Renderer2,
            CpAgGridService,
            GlobalAppService])
    ], HyperlinkAndFavoriteComponent);
    return HyperlinkAndFavoriteComponent;
}());
export { HyperlinkAndFavoriteComponent };
