import { Component, Inject, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { LOCALE_ID } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-digit-formatter',
  templateUrl: './digit-formatter.component.html',
  styleUrls: ['./digit-formatter.component.scss']
})
export class DigitFormatterComponent implements AgRendererComponent, OnDestroy {
  params: any;
  currencyCode: string;
  subscription: Subscription = new Subscription();

  constructor(@Inject(LOCALE_ID) private locale: string,) {}

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
  ngOnDestroy() {
    // Required for unsubscribing and destroying the component  
  }

}
