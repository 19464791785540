import { ribbonMenuInvoice } from '@app/conf/dataConfigurations';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { GlobalAppService } from '@app/Modules/Shared/Services/global-app.service';
import { BehaviorSubject, Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../Shared/Services/app.service";
import * as i2 from "../../Shared/Services/global-app.service";
var InvoicePriceService = /** @class */ (function () {
    // customerList: Subject<any> = new Subject<any>();
    function InvoicePriceService(appServ, globalAppService) {
        this.appServ = appServ;
        this.globalAppService = globalAppService;
        this.searchRequestState = new BehaviorSubject(null);
        this.gridLoaded = new Subject();
        this.showErrorMessage = new Subject();
        this.reloadDownloadAllPage = new Subject();
        this.menu = new BehaviorSubject(null);
        this._favoriteSearch = new Subject();
        this.InvoiceSearchRequestState = new BehaviorSubject(null);
        this._invoiceSearchCriteria = {};
        this.mySearchesNavigation = new BehaviorSubject(false);
        this.showProcessingPopup = new Subject();
        this.isRedrawRow = new Subject();
        this.showEmailPopup = new Subject();
        this.updateCategoryStatus = new Subject();
        this.status = new Subject();
        this.updateFavHeaderStatus = new Subject();
        this.setInvoiceMenu();
    }
    InvoicePriceService.prototype.loadPatype = function (cvtView) {
        throw new Error('Method not implemented.');
    };
    InvoicePriceService.prototype.initInvoiceSearch = function (searchObj) {
        this._invoiceSearchCriteria = searchObj;
    };
    InvoicePriceService.prototype.getSearchData = function () {
        return this._invoiceSearchCriteria;
    };
    InvoicePriceService.prototype.getSearchRequest = function () {
        return this.searchRequestState.asObservable();
    };
    InvoicePriceService.prototype.changeSearchRequest = function (searchObject) {
        this.appServ.changeSearchRequest(searchObject);
        this.searchRequestState.next(searchObject);
    };
    InvoicePriceService.prototype.setGridLoaded = function (value) {
        return this.gridLoaded.next(value);
    };
    InvoicePriceService.prototype.getGridLoaded = function () {
        return this.gridLoaded.asObservable();
    };
    InvoicePriceService.prototype.setErrorMsg = function (showError) {
        this.showErrorMessage.next(showError);
    };
    InvoicePriceService.prototype.getErrorMsg = function () {
        return this.showErrorMessage.asObservable();
    };
    InvoicePriceService.prototype.setReloadDownloadAllPage = function (isReload) {
        this.reloadDownloadAllPage.next(isReload);
    };
    InvoicePriceService.prototype.getReloadDownloadAllPage = function () {
        return this.reloadDownloadAllPage.asObservable();
    };
    InvoicePriceService.prototype.setInvoiceMenu = function () {
        var _this = this;
        this.globalAppService.getDefaultRegion().subscribe(function (region) {
            _this.menu.next(ribbonMenuInvoice[region]);
            return _this.menu;
        });
    };
    InvoicePriceService.prototype.getInvoiceMenu = function () {
        return this.menu;
    };
    InvoicePriceService.prototype.getMarkSearchFavorite = function () {
        // console.log("getMarkSearchFavorite", this._favoriteSearch);
        return this._favoriteSearch;
    };
    InvoicePriceService.prototype.markSearchFavorite = function (searchItem) {
        // console.log("markSearchFavorite", searchItem);
        this._favoriteSearch.next(searchItem);
    };
    InvoicePriceService.prototype.setRecordCountForMCDownload = function (count) {
        this.recordcountForMCDownload = count;
    };
    InvoicePriceService.prototype.setMySearchesNavigation = function (value) {
        this.mySearchesNavigation.next(value);
    };
    InvoicePriceService.prototype.getMySearchesNavigation = function () {
        return this.mySearchesNavigation.asObservable();
    };
    InvoicePriceService.prototype.setStateList = function (CustomerData) {
        this.stateList = CustomerData;
    };
    InvoicePriceService.prototype.getStateList = function () {
        return this.stateList;
    };
    InvoicePriceService.prototype.setProcessingPopupVisible = function (categoryData) {
        this.showProcessingPopup.next(categoryData);
    };
    InvoicePriceService.prototype.getProcessingPopupVisible = function () {
        return this.showProcessingPopup.asObservable();
    };
    InvoicePriceService.prototype.setRedrawAdvancedGridRow = function (favData) {
        this.isRedrawRow.next(favData);
    };
    InvoicePriceService.prototype.getRedrawAdvancedGridRow = function () {
        return this.isRedrawRow.asObservable();
    };
    InvoicePriceService.prototype.setEmailPopupVisible = function (emailData) {
        this.showEmailPopup.next(emailData);
    };
    InvoicePriceService.prototype.getEmailPopupVisible = function () {
        return this.showEmailPopup.asObservable();
    };
    InvoicePriceService.prototype.setUpdateRefreshStatusRequest = function (statusData) {
        this.updateCategoryStatus.next(statusData);
    };
    InvoicePriceService.prototype.getUpdateRefreshStatusRequest = function () {
        return this.updateCategoryStatus.asObservable();
    };
    InvoicePriceService.prototype.setUpdateFavColHeader = function (updateStatus) {
        this.updateFavHeaderStatus.next(updateStatus);
    };
    InvoicePriceService.prototype.getUpdateFavColHeader = function () {
        return this.updateFavHeaderStatus.asObservable();
    };
    Object.defineProperty(InvoicePriceService.prototype, "favoritecustomerList", {
        get: function () {
            return this.customerList;
        },
        // setFavoritecustomerList(customerNumbers){
        //   this.customerList.next(customerNumbers);
        // }
        // getFavoritecustomerList(): Observable<any>{
        //   return this.customerList.asObservable();
        // }
        set: function (customerNumbers) {
            this.customerList = customerNumbers;
        },
        enumerable: true,
        configurable: true
    });
    InvoicePriceService.prototype.saveFavoriteSearch = function (searchItem) {
        // console.log("markSearchFavorite", searchItem);
        this._favoriteSearch.next(searchItem);
    };
    InvoicePriceService.prototype.setStatus = function (statusData) {
        this.status.next(statusData);
    };
    InvoicePriceService.prototype.getStatus = function () {
        return this.status.asObservable();
    };
    InvoicePriceService.ngInjectableDef = i0.defineInjectable({ factory: function InvoicePriceService_Factory() { return new InvoicePriceService(i0.inject(i1.AppService), i0.inject(i2.GlobalAppService)); }, token: InvoicePriceService, providedIn: "root" });
    return InvoicePriceService;
}());
export { InvoicePriceService };
