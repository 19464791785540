<span *ngIf="statusDefault">{{ statusValue | translate }}</span>

<span *ngIf="statusReadyToSubmit">
  <span class="statusReadyToSubmit"
    [ngClass]="{'statusGeneral': isKcfileSubmitted || isKcfileDeleted}" (click)="submitKcfile()" [innerHTML]= "statusValue | translate"> 
  </span>
<i *ngIf="!isFileSubmittedOrDeleted" class='far fa-arrow-alt-circle-right'></i>
</span>

<span title="{{statusValue | translate}}" *ngIf="statusError" class="tooltip-hover tolltipHoverClass statusError ellipsis" [ngClass]="{'statusGeneral': isKcfileDeleted}">
  <i *ngIf="!isKcfileDeleted" class="fas fa-exclamation-circle"></i>
  <span pTooltip="{{'chargeback.kcFile.errorsFoundTooltip' | translate}}" [escape]="false" tooltipPosition="top">
    <!-- <span class="textunderlined">{{ statusValue | translate }}</span> -->
<span>{{ statusValue | translate }}</span>
</span>
</span>