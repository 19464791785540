import * as tslib_1 from "tslib";
import { Keepalive } from '@ng-idle/keepalive';
import { EventTargetInterruptSource, Idle } from '@ng-idle/core';
import { cookieSIMID, globalUser, IdleTimeOutConfig, environment } from '@env/environment';
import { OnInit, Renderer2, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectionService } from '@app/Modules/Shared/Services/language-selection.service';
import { ContentAreaService } from '@app/Modules/Shared/Services/content-area.service';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';
import { AppService } from '@app/Modules/Shared/Services/app.service';
import { OktaAuthService } from '@okta/okta-angular';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from './Modules/Shared/Services/http.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { MonitoringService } from './Modules/Shared/Services/monitoring.service';
import { Guid } from "guid-typescript";
import { RegionCheckService } from '@app/Modules/Shared/Services/region-check.service';
import { GlobalAppService } from './Modules/Shared/Services/global-app.service';
import { MessageService } from 'primeng/api';
import { SignalRMaster } from './Modules/Shared/Services/SingnalR.master.service';
import { popupSequence } from '@app/conf/regionWiseAppPopupSequnce';
var AppHomecomponent = /** @class */ (function () {
    function AppHomecomponent(translate, languageSelected, contentAreaServ, router, activatedRoute, httpServ, _globalAppService, _appService, _oktaAuth, renderer2, moniterServ, element, idle, keepalive, regionServ, _document, sanitizer, signalRService, messageService) {
        var _this = this;
        this.translate = translate;
        this.languageSelected = languageSelected;
        this.contentAreaServ = contentAreaServ;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.httpServ = httpServ;
        this._globalAppService = _globalAppService;
        this._appService = _appService;
        this._oktaAuth = _oktaAuth;
        this.renderer2 = renderer2;
        this.moniterServ = moniterServ;
        this.element = element;
        this.idle = idle;
        this.keepalive = keepalive;
        this.regionServ = regionServ;
        this._document = _document;
        this.sanitizer = sanitizer;
        this.signalRService = signalRService;
        this.messageService = messageService;
        this.title = 'internatalizationLanguageSelection';
        this.loading = true;
        this.isAuthenticated = false;
        this.showAppHeader = true;
        this.showAppFooter = false;
        this.idleState = 'NOT_STARTED';
        this.timedOut = false;
        this.isGlobalUSer = false;
        this.isAccepted = false;
        this.subscription = new Subscription();
        this.signalRService.getMessageObservable().subscribe(function (response) {
            if (response == 'start') {
                _this.messageService.add({ key: 'information', severity: 'info', detail: 'signalRMessages.dnlStartMsg', life: 10000 });
            }
            else if (response) {
                var fileURL = response.fileUrl ? response.fileUrl : response.FileUrl;
                if (fileURL) {
                    var fileLink = "<a href='" + fileURL + "' target='_blank'>" + response.FileName + "</a>";
                    _this.messageService.add({ key: 'dnlSuccess', severity: 'success', summary: 'signalRMessages.dnlSuccessMsg', detail: fileLink, sticky: true });
                }
                else if (response.fileMessage && response.fileMessage.indexOf("File too large to download") > -1) {
                    _this.messageService.add({ key: 'information', severity: 'error', detail: 'signalRMessages.fileTooLargeMsg', life: 10000 });
                }
                else {
                    _this.messageService.add({ key: 'information', severity: 'error', detail: response.DownloadStatusCode + '.errorMessage', life: 10000 });
                }
            }
        });
        this._oktaAuth.$authenticationState.subscribe(function (isAuthenticated) {
            _this.isAuthenticated = isAuthenticated;
            var me = _this;
            _this.initializeDasboard().subscribe(function (data) {
                if (data) {
                    _this.appStartup(isAuthenticated, "CONSTRUCTOR");
                }
                else {
                    me.setRecentDownloadContext();
                    _this.router.navigate(['select-region']);
                }
            });
            _this._globalAppService.getAppStart().subscribe(function (data) {
                if (data) {
                    _this.appStartup(isAuthenticated, "ONINIT");
                }
            });
        });
    }
    AppHomecomponent.prototype.setRecentDownloadContext = function () {
        var currentNavigation = this.router.getCurrentNavigation();
        if (currentNavigation && currentNavigation.extractedUrl && currentNavigation.extractedUrl.queryParams && currentNavigation.extractedUrl.root && currentNavigation.extractedUrl.root.children.primary) {
            var path = currentNavigation.extractedUrl.root.children.primary.segments[currentNavigation.extractedUrl.root.children.primary.segments.length - 1].path;
            if (path === 'recentDownload') {
                sessionStorage.setItem('recentDownloadUrl', currentNavigation.extractedUrl.root.children.primary.segments[currentNavigation.extractedUrl.root.children.primary.segments.length - 1].path);
                sessionStorage.setItem('recentDownloadParams', JSON.stringify(currentNavigation.extractedUrl.queryParams));
            }
        }
    };
    AppHomecomponent.prototype.isTokenReceived = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var accessToken, idToken;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._oktaAuth.getAccessToken()];
                    case 1:
                        accessToken = _a.sent();
                        return [4 /*yield*/, this._oktaAuth.getIdToken()];
                    case 2:
                        idToken = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppHomecomponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isTokenReceived();
        this._globalAppService.getDefaultRegion().subscribe(function () {
            setTimeout(function () {
                _this.getLocationAndlanguage();
            });
        });
        this._globalAppService.notifyWalkMeStart().subscribe(function (data) {
            _this.walkmeSetup(_this.profileInfo);
        });
        //TODO Add subscription for Delegated User
        this._oktaAuth.isAuthenticated().then(function (isAuthenticated) {
            _this.isAuthenticated = isAuthenticated;
            _this.initializeDasboard().subscribe(function (data) {
                if (data) {
                    _this.appStartup(isAuthenticated, "ONINIT");
                }
                else {
                    _this.router.navigate(['select-region']);
                }
            });
            _this._globalAppService.getAppStart().subscribe(function (data) {
                if (data) {
                    _this.appStartup(isAuthenticated, "ONINIT");
                }
            });
        });
        this.router.events.subscribe(function (event) {
            _this.navigationInterceptor(event);
        });
        this.contentAreaServ.currentBlurStatus.subscribe(function (data) { return _this.blurActive = data; });
        this._appService.getLoadingStatus().subscribe(function (value) {
            setTimeout(function () { return _this.loading = value; });
        });
        // this._appService.getAcceptancePolicyModalVisibility().subscribe(data => {
        //  if (data) {
        //     this.showModal = data.forceShowModal && data.action === "footerLinkClick";
        //     this.makeAcceptancePolicyCall();
        //   }
        // })
        // this.applyUserPrefernceData();
        forkJoin([this._appService.getAcceptancePolicyModalVisibility(), this._appService.getPopUpSequenceStatus()]).subscribe(function (response) {
            var modalVisibilityResponse = response[0];
            var popUpSequenceStatus = response[1];
            _this.showModal = modalVisibilityResponse.forceShowModal && modalVisibilityResponse.action === "footerLinkClick";
            switch (popUpSequenceStatus) {
                case 1:
                    _this.makeAcceptancePolicyCall();
                    break;
                default:
                    break;
            }
        });
    };
    AppHomecomponent.prototype.getLocationAndlanguage = function () {
        this.region = this._globalAppService.getDefaultRegionString();
        var selectedLocation = this._globalAppService.getSelectedCountryLang();
        this.language = selectedLocation && selectedLocation.language ? selectedLocation.language : this._globalAppService.getDefaultLanguageString();
        this.country = selectedLocation && selectedLocation.country ? selectedLocation.country : this._globalAppService.getDefaultCountryString();
        this.getGlobalUserFlag();
    };
    AppHomecomponent.prototype.appStartup = function (UserAuthenticated, StartPoint) {
        var _this = this;
        if (UserAuthenticated) {
            this.httpServ.getUserDetails().subscribe(function (userDetails) {
                _this._globalAppService.setUserDetails(userDetails.result);
                var regionId = (userDetails.result.preferredRegion === null) ?
                    (userDetails.result.regionId ? userDetails.result.regionId.toUpperCase() : null) :
                    (userDetails.result.preferredRegion ? userDetails.result.preferredRegion.toUpperCase() : null);
                ;
                var country = (userDetails.result.preferredCountry === null) ? userDetails.result.country : userDetails.result.preferredCountry;
                var language = (userDetails.result.preferredLanguage === null) ? userDetails.result.defaultLanguage : userDetails.result.preferredLanguage;
                var defaultRegion = _this._globalAppService.getDefaultRegionString();
                defaultRegion = defaultRegion ? defaultRegion.toUpperCase() : null;
                if (regionId === defaultRegion) {
                    _this._globalAppService.setDefaultCountry(country);
                    _this._globalAppService.setDefaultLanguage(language);
                    // setTimeout(() => this.setMasterDetailsLocations() );
                    _this.appPopupModulator(defaultRegion, _this._appService.isInternalUser ? 'internalUser' : 'externalUser');
                }
                else {
                    // setTimeout(() => this.setMasterDetailsLocations());
                    _this._globalAppService.getMasterDataDetails().subscribe(function (data) {
                        if (data && data.response && data.response.length) {
                            var myObj = data.response.find(function (element) {
                                return element.regionId.toUpperCase() == _this._globalAppService.getDefaultRegionString().toUpperCase();
                            });
                            _this._globalAppService.setDefaultCountry(myObj.country[0].countryId);
                            _this._globalAppService.setDefaultLanguage(myObj.country[0].salesOrg[0].language[0].languageCode);
                            _this.appPopupModulator(myObj.regionId, _this._appService.isInternalUser ? 'internalUser' : 'externalUser');
                        }
                    });
                }
            });
            this._appService.getOktaProfile().subscribe(function (data) {
                if (!_this._appService.getCookie(cookieSIMID) && !_this._appService.isGlobalUser) {
                    _this.walkmeSetup(data);
                }
            });
            this._oktaAuth.getAccessToken().then(function (token) {
                _this._appService.navigateToLanding(token);
                _this.sessionSetup();
            });
        }
    };
    AppHomecomponent.prototype.appPopupModulator = function (region, userType) {
        this._appService.setPopupSequenceNumber(popupSequence[region.toUpperCase()][userType]);
        if (this._appService.popupSequenceNumber[0]) {
            this._appService.setPopUpSequenceStatus(this._appService.getPopupSequenceNumber());
        }
    };
    AppHomecomponent.prototype.sessionSetup = function () {
        var _this = this;
        // sets an idle timeout of given time.
        this.idle.setIdle(IdleTimeOutConfig.setIdle);
        // sets a timeout period given time.
        this.idle.setTimeout(IdleTimeOutConfig.setTimeout);
        // sets the interrupts like Keydown, scroll, mouse wheel, mouse down, and etc
        this.idle.setInterrupts([
            new EventTargetInterruptSource(this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')
        ]);
        this.idle.onIdleEnd.subscribe(function () {
            _this.idleState = 'NO_LONGER_IDLE';
        });
        this.idle.onTimeout.subscribe(function () {
            _this.idleState = 'TIMED_OUT';
            _this.timedOut = true;
            // location.href = headerLinks.logoutLink;
            _this.router.navigateByUrl("/logout");
        });
        this.idle.onIdleStart.subscribe(function () {
            _this.idleState = 'IDLE_START';
            // this.visibleDownloadModal = true;
        });
        this.idle.onTimeoutWarning.subscribe(function (countdown) {
            _this.idleState = 'IDLE_TIME_IN_PROGRESS';
            // this.visibleDownloadModal = true
        });
        // sets the ping interval to given time
        this.keepalive.interval(IdleTimeOutConfig.setInterval);
        this.reset();
    };
    AppHomecomponent.prototype.walkmeSetup = function (oktaProfile) {
        var _this = this;
        // console.log("walk me Step 1", oktaProfile && oktaProfile.sAMAccountName);
        if (oktaProfile && oktaProfile.sAMAccountName) {
            // console.log("walk me Step 2");
            var Walkme_variables_1 = { 'appRole': [''], 'userID': '', 'firstTimeLogin': '', userType: '', 'region': '' };
            this.httpServ.getWelcomeFlag(oktaProfile.sAMAccountName).subscribe(function (d) {
                // console.log("walk me Step 3 - inside welcome flag call");
                Walkme_variables_1.appRole = oktaProfile.approles;
                Walkme_variables_1.userID = oktaProfile.sAMAccountName;
                Walkme_variables_1.firstTimeLogin = d.show_welcome_message;
                Walkme_variables_1.userType = (oktaProfile.userType == 'C' || oktaProfile.userType == 'E' ? "internal" : "external");
                Walkme_variables_1.region = _this._globalAppService.getDefaultRegionString();
                window.Walkme_variables = Walkme_variables_1 || {};
                window.region = _this._globalAppService.getDefaultRegionString();
                // console.log("walk me Step 4", window.Walkme_variables);
                // this.walkmeSCript();
            });
        }
    };
    AppHomecomponent.prototype.initializeDasboard = function () {
        var _this = this;
        var loadFlag = new Observable(function (observer) {
            _this._oktaAuth.getAccessToken().then(function (token) {
                if (token) {
                    _this.httpServ.getUserInfo(token).subscribe(function (profile) {
                        if (profile) {
                            // this._appService.setOktaProfile(profile);
                            //setTimeout(() => this._globalAppService.setApproles(this.filterRoles(profile.approles)));
                            _this.showAppHeader = true;
                            // this.forceHideHeader = this.router.url === '/contactus' ? false : true; // Commented as we are updating the state in navigation interceptor
                            // this._globalAppService.setApproles(this.filterRoles(profile.approles));
                            //setting approles from cookies for impersonated user
                            var impersonatedUserRoles = window.localStorage.getItem('impersonatedUserRoles');
                            if (impersonatedUserRoles) {
                                var rolesArray = impersonatedUserRoles.split(",");
                                var globalRole = globalUser[window.localStorage.getItem('default-region')];
                                var impersonatedUserRegions = window.localStorage.getItem('impersonatedUserRegions');
                                var regions = impersonatedUserRegions.split(",");
                                _this._globalAppService.setApproles(rolesArray.concat(globalRole));
                                _this._globalAppService.setRegions(regions);
                            }
                            else {
                                _this._globalAppService.setApproles(profile.approles);
                                _this._globalAppService.setRegions(profile.appregions);
                            }
                            _this._globalAppService.setUserNameString(profile.name);
                            _this._globalAppService.setUserProfileDetails(profile);
                            _this.profileInfo = profile;
                            if (profile.appregions.length > 1 && !localStorage.getItem('default-region')) {
                                //to navigate to region selector page   
                                _this.setRecentDownloadContext();
                                var recentDownloadParams = window.sessionStorage.getItem('recentDownloadParams');
                                var region = recentDownloadParams ? JSON.parse(recentDownloadParams).RegionId : null;
                                if (region) {
                                    _this._globalAppService.setDefaultRegion(region.toUpperCase());
                                    localStorage.setItem('default-region', region.toUpperCase());
                                    return observer.next(true);
                                }
                                return observer.next(false);
                            }
                            else if (profile.appregions.length == 1) {
                                // console.log('equal 1');
                                _this._globalAppService.setDefaultRegion(profile.appregions[0]);
                                localStorage.setItem('default-region', profile.appregions[0]);
                                return observer.next(true);
                            }
                            else {
                                _this._globalAppService.setDefaultRegion(localStorage.getItem('default-region'));
                                return observer.next(true);
                            }
                        }
                        else {
                            return observer.next(false);
                        }
                    });
                }
            });
        });
        return loadFlag;
    };
    AppHomecomponent.prototype.navigationInterceptor = function (event) {
        var _this = this;
        if (event instanceof NavigationStart) {
            if (environment.production)
                this.moniterServ.startNavigationEvent(event.url);
            if (event.url.indexOf('/priceagreement') === -1) {
                this.showAcceptancePolicy = false;
            }
            if (event.navigationTrigger == 'popstate' && (this.router.url == '/welcome' || this.router.url == '/home')) {
                if (this._appService.AvailableOKtaProfile) {
                    this.popstateNavigation(this._appService.AvailableOKtaProfile.sAMAccountName);
                }
                else {
                    this._appService.getOktaProfile().subscribe(function (data) {
                        _this.popstateNavigation(data.sAMAccountName);
                    });
                }
            }
            else if (event.url.indexOf('/delegatedUserSearch') > -1) {
                this._appService.setIsDelegatedUserPage(true);
            }
            if (event.url.indexOf('/efile/manage-errors?eFileID=') > -1 && !this.isAuthenticated) {
                localStorage.setItem('manageErrorDownloadUrl', event.url);
            }
            // if (event.url.indexOf('/login') !== -1 || event.url.indexOf('/register') !== -1 || event.url.indexOf('/select-region') !== -1) {
            //   if (this.isAuthenticated)
            //     this.router.navigate(['home']);
            // }
            /**Adding SetTimeout to avoid expression change error*/
            setTimeout(function () { return _this.loading = true; });
        }
        if (event instanceof NavigationEnd) {
            /** Woraround to load the landing page on IE */
            if (document['documentMode'] || /Edge/.test(navigator.userAgent)) {
                //alert("inIeEdge");
                var isApploaded = sessionStorage.getItem("MS-App-Loaded");
                //alert("inIeEdge" + isApploaded);
                if (!isApploaded && (event.url.indexOf('/implicit/callback') > -1)) {
                    sessionStorage.setItem("MS-App-Loaded", "1");
                    //alert("inIeEdge" + event.url);
                    setTimeout(function () {
                        //alert("Navigating to landing");
                        _this.router.navigateByUrl("/landing");
                    }, 100);
                }
            }
            if (event.url === '/home' || event.url === '/delegatedUserSearch') {
                this._appService.setHomeNavigation(true);
            }
            if (event.url.indexOf('/priceagreement') === -1) {
                this.showAcceptancePolicy = false;
            }
            if (environment.production)
                this.moniterServ.endNavigationEvent(event.url, event.url, Guid.raw());
            // Show different Header logic
            if (event.urlAfterRedirects == "/welcome" || event.urlAfterRedirects.indexOf('/login') !== -1 || event.urlAfterRedirects.indexOf("/pa/details") > -1 || event.urlAfterRedirects.indexOf("/chargeback/coupon/details") > -1 || event.urlAfterRedirects.indexOf("/meetcomps/meetcompsDetails/details") > -1 || event.urlAfterRedirects == "/logout" || event.urlAfterRedirects == '/landing' || event.urlAfterRedirects == '/register' || event.urlAfterRedirects == '/contactus') {
                this._appService.setIsDelegatedUserPage(false);
                this.showAppHeader = false;
            }
            else {
                this.showAppHeader = true;
            }
            // Show footer logic
            if (event.urlAfterRedirects == "/error" || event.urlAfterRedirects.indexOf("/pa/details") || event.urlAfterRedirects.indexOf("/chargeback/coupon/details") || event.urlAfterRedirects.indexOf("/meetcomps/meetcompsDetails/details")) {
                this.showAppFooter = true;
            }
            else {
                this.showAppFooter = false;
            }
            if (event.urlAfterRedirects.indexOf('/login') !== -1 || event.urlAfterRedirects.indexOf('/register') !== -1 || event.urlAfterRedirects.indexOf('/select-region') !== -1 || event.urlAfterRedirects.indexOf('/contactus') !== -1) {
                this.forceHideHeader = true;
                this.forceHideFooter = true;
                if ((event.urlAfterRedirects.indexOf('/contactus') !== -1)) {
                    this._oktaAuth.isAuthenticated().then(function (isAuthenticated) {
                        if (isAuthenticated) {
                            _this.forceHideHeader = false;
                            _this.forceHideFooter = false;
                        }
                        else {
                            _this.forceHideHeader = true;
                            _this.forceHideFooter = true;
                        }
                    });
                }
                if (this.isAuthenticated && !localStorage.getItem('default-region'))
                    this.router.navigate(['home'], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') } });
            }
            else {
                this.forceHideHeader = false;
                this.forceHideFooter = false;
            }
            if (event.urlAfterRedirects.indexOf("(recentlychanged//dialogs:downloadmodal)") > -1) {
                this._appService.forceDownloadCSV(true);
            }
            else {
                this._appService.forceDownloadCSV(false);
            }
            // hide session modal logic for login page
            if (event.urlAfterRedirects == "/login" || event.urlAfterRedirects == "/logout") {
                this.resetTimeOut();
            }
            else {
                this.sessionSetup();
            }
            if (event.urlAfterRedirects.indexOf("/profile/myprofile") > -1) {
                // console.log('my profile ');
                this._appService.setDisableLink(true);
            }
            else {
                this._appService.setDisableLink(false);
            }
            /* Stop show loading after each navigation end except /home as in /home we are setting it to false after anouncment response completed in home component*/
            // && event.urlAfterRedirects != "/profile/myprofile"
            if (event.urlAfterRedirects != "/home") {
                setTimeout(function () {
                    _this.loading = false;
                    _this._appService.setTimeoutLoaderHide(false);
                }, 2000);
            }
        }
        // loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            setTimeout(function () { return _this.loading = false; });
        }
        if (event instanceof NavigationError) {
            setTimeout(function () { return _this.loading = false; });
        }
    };
    AppHomecomponent.prototype.login = function () {
        // this._oktaAuth.loginRedirect('/home');
        this._oktaAuth.loginRedirect('/landing');
    };
    AppHomecomponent.prototype.logout = function () {
        this._oktaAuth.logout('/');
    };
    AppHomecomponent.prototype.walkmeSCript = function () {
        // This script is imported through Google Tag Manager
        // // console.log("walk me Step 5 walkmeSCript");
        // // console.log("walk me Step 6", this.renderer2);
        // // <script
        // // type="text/javascript">(function () { var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/3aa07e499bb9450bbc24c4ccd1905dea/test/walkme_3aa07e499bb9450bbc24c4ccd1905dea_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = { smartLoad: true }; })();</script>
        // const s = this.renderer2.createElement('script');
        // s.type = 'text/javascript';
        // // s.src = 'https://path/to/your/script';
        // s.text = `(function () { var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/3aa07e499bb9450bbc24c4ccd1905dea/test/walkme_3aa07e499bb9450bbc24c4ccd1905dea_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = { smartLoad: true }; })();`;
        // this.renderer2.appendChild(this._document.body, s);
    };
    AppHomecomponent.prototype.popstateNavigation = function (sAMAccountName) {
        var _this = this;
        // console.log('insode popstateNavigation');
        this.httpServ.getWelcomeFlag(sAMAccountName).subscribe(function (d) {
            if (d.show_welcome_message === true) {
                _this.router.navigate(["/welcome"]);
            }
            else {
                _this.router.navigate(["/home"], { queryParams: { region: localStorage.getItem('default-region'), country: localStorage.getItem('country'), language: localStorage.getItem('country-lang') } });
            }
        });
    };
    AppHomecomponent.prototype.reset = function () {
        // this.visibleDownloadModal = false;
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    };
    AppHomecomponent.prototype.resetTimeOut = function () {
        // this.visibleDownloadModal = false;
        this.idle.stop();
        // this.idle.onIdleStart.unsubscribe();
        // this.idle.onTimeoutWarning.unsubscribe();
        // this.idle.onIdleEnd.unsubscribe();
        // this.idle.onIdleEnd.unsubscribe();
    };
    AppHomecomponent.prototype.setDefaultLocationLanguage = function () {
        var selectedLocationLanguage;
        if (this.isAuthenticated) {
        }
        else {
            if (this.activatedRoute.snapshot.queryParams) {
                selectedLocationLanguage = this.readQueryParams();
            }
            else if (typeof (Storage) !== 'undefined' && localStorage.getItem("KCC.CP.selectedLocationLanguage")) {
                selectedLocationLanguage = this.readLocationFromLocalStorage();
            }
            else {
                selectedLocationLanguage = {
                    region: "NA",
                    country: "US",
                    language: 'en-us'
                };
            }
        }
        this._appService.setLocationLanguage(selectedLocationLanguage);
    };
    AppHomecomponent.prototype.readQueryParams = function () {
        var selectedLocationLanguage = {
            region: this.activatedRoute.snapshot.queryParamMap.get('region'),
            country: this.activatedRoute.snapshot.queryParamMap.get('country'),
            language: this.activatedRoute.snapshot.queryParamMap.get('language')
        };
        return selectedLocationLanguage;
    };
    AppHomecomponent.prototype.readLocationFromLocalStorage = function () {
        var parsedPreference = JSON.parse(localStorage.getItem("KCC.CP.selectedLocationLanguage"));
        var selectedLocationLanguage = {
            region: parsedPreference.region,
            country: parsedPreference.country,
            language: parsedPreference.language
        };
        return selectedLocationLanguage;
    };
    AppHomecomponent.prototype.filterRoles = function (roles) {
        var adminroles = roles.filter(function (element) {
            return element.indexOf('SiteAdminModify') > -1 || element.indexOf('SiteAdminReadOnly') > -1;
        });
        return adminroles;
    };
    AppHomecomponent.prototype.makeAcceptancePolicyCall = function () {
        var _this = this;
        this._appService.setLoadingStatus(true);
        if (!this._appService.isInternalUser && this.region === 'LAO') {
            var request = {
                contentId: 1
            };
            this.subscription.add(this.httpServ
                .getAccptancePolicyStatus(request)
                .subscribe(function (data) {
                if (data) {
                    _this.acceptancePolicyResponse = data.result;
                    _this.getLocationAndlanguage();
                    _this.getAccetptancePolicyContent(_this.acceptancePolicyResponse, _this.region, _this.country, _this.language);
                }
            }, function (error) {
                _this._appService.setPopUpSequenceStatus(_this._appService.getPopupSequenceNumber());
                _this._appService.setLoadingStatus(false);
            }, function () {
                _this._appService.setLoadingStatus(false);
            }));
        }
    };
    AppHomecomponent.prototype.onAcceptancePolicyLanguageChanged = function (languageCode) {
        this.showModal = true;
        // this.getLocationAndlanguage();
        this.getAccetptancePolicyContent(this.acceptancePolicyResponse, this.region, this.country, languageCode);
    };
    AppHomecomponent.prototype.getAccetptancePolicyContent = function (statusResponse, region, country, language) {
        var _this = this;
        this._appService.setLoadingStatus(true);
        var KCPContentRequest = this.getKCPContentRequest(region, country, language);
        this.httpServ.getKCPContent(KCPContentRequest).subscribe(function (data) {
            if (data.result.length > 0) {
                _this.policyContent = _this.sanitizer.bypassSecurityTrustHtml(JSON.parse(data.result[0].data).Content);
                _this.disableNotNow = statusResponse.forceAcceptance;
                // this.showAcceptancePolicy = statusResponse.isAccepted == false ? true : false;
                _this.showAcceptancePolicy = _this.showModal || !statusResponse.isAccepted;
                if (!_this.showAcceptancePolicy) {
                    _this._appService.setPopUpSequenceStatus(_this._appService.getPopupSequenceNumber());
                }
                _this.isAccepted = statusResponse.isAccepted;
                _this.showModal = false;
            }
            _this._appService.setLoadingStatus(false);
        }, function (error) {
            _this._appService.setPopUpSequenceStatus(_this._appService.getPopupSequenceNumber());
            _this._appService.setLoadingStatus(false);
        }, function () {
            _this._appService.setLoadingStatus(false);
        });
    };
    AppHomecomponent.prototype.getKCPContentRequest = function (region, country, language) {
        return {
            regionId: region,
            // salesOrgId: this._globalAppService.getSalesOrg(),
            countryId: country,
            languageCode: language,
            contentTypes: [
                {
                    contentType: "AcceptancePolicy"
                }
            ],
            contentSubTypes: [
                {
                    contentSubType: "Popup"
                }
            ]
        };
    };
    AppHomecomponent.prototype.acceptPolicyModal = function (event) {
        if (event) {
            this.makeAPIRequest(true);
        }
    };
    AppHomecomponent.prototype.postPonePolicyModal = function (event) {
        // if (event) {
        //   if (event.action === 'save&Close')
        //     this.makeAPIRequest(event.accept);
        //   if (event.action === 'close') {
        //     this.showAcceptancePolicy = false;
        //   }
        // }
        this.showAcceptancePolicy = false;
        this.translate.use(this.region + '/' + this.language.toLowerCase());
    };
    AppHomecomponent.prototype.makeAPIRequest = function (isAggrementAccepted) {
        var _this = this;
        var req = {
            contentId: 1,
            isAccepted: isAggrementAccepted
        };
        this.httpServ
            .setAccptancePolicyStatus(req)
            .subscribe(function (response) {
            if (response.result)
                _this.isAccepted = isAggrementAccepted;
            _this.showAcceptancePolicy = false;
            // this.announcementModal();
        }, function (err) {
            console.log("Error Occured");
        });
    };
    AppHomecomponent.prototype.getGlobalUserFlag = function () {
        var _this = this;
        if (this._appService.AvailableOKtaProfile)
            this.isGlobalUSer = this._appService.isGlobalUser;
        else
            this.subscription.add(this._appService.getOktaProfile().subscribe(function (data) {
                if (data) {
                    _this.isGlobalUSer = _this._appService.isGlobalUser;
                }
            }));
    };
    AppHomecomponent.prototype.ngOnDestroy = function () {
        this.resetTimeOut();
    };
    return AppHomecomponent;
}());
export { AppHomecomponent };
