/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./efile-type-column.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./efile-type-column.component";
var styles_EfileTypeColumnComponent = [i0.styles];
var RenderType_EfileTypeColumnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EfileTypeColumnComponent, data: {} });
export { RenderType_EfileTypeColumnComponent as RenderType_EfileTypeColumnComponent };
export function View_EfileTypeColumnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.typeValue)); _ck(_v, 1, 0, currVal_0); }); }
export function View_EfileTypeColumnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-efile-type-column", [], null, null, null, View_EfileTypeColumnComponent_0, RenderType_EfileTypeColumnComponent)), i1.ɵdid(1, 180224, null, 0, i3.EfileTypeColumnComponent, [], null, null)], null, null); }
var EfileTypeColumnComponentNgFactory = i1.ɵccf("app-efile-type-column", i3.EfileTypeColumnComponent, View_EfileTypeColumnComponent_Host_0, {}, {}, []);
export { EfileTypeColumnComponentNgFactory as EfileTypeColumnComponentNgFactory };
