import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var MenuTogglerService = /** @class */ (function () {
    function MenuTogglerService() {
        this.menuControls = new Subject();
        this.menuControls$ = this.menuControls.asObservable();
    }
    MenuTogglerService.prototype.setMenuState = function (menuState) {
        this.menuState = menuState;
        this.menuControls.next(menuState);
    };
    Object.defineProperty(MenuTogglerService.prototype, "getMenuState", {
        get: function () {
            return this.menuState;
        },
        enumerable: true,
        configurable: true
    });
    MenuTogglerService.ngInjectableDef = i0.defineInjectable({ factory: function MenuTogglerService_Factory() { return new MenuTogglerService(); }, token: MenuTogglerService, providedIn: "root" });
    return MenuTogglerService;
}());
export { MenuTogglerService };
